import React from "react";
import { Select, Spin, Icon } from "antd";
import { successNotification } from "../Shared/Notifications";
import UserModel from "../../Models/UserModel";
import { errorNotification, errorToaster } from "../Shared/Notifications";
import {
  validateEmail,
  validatePhoneCountry,
} from "../../Helpers/Shared/Validators";
// import $ from 'jquery';
// import backImage from '../../../src/assets/media/users/default.jpg';

import MerchantModel from "../../Models/MerchantModel";
import PartnerModel from "../../Models/PartnerModel";

const { Option } = Select;

export default class EditUser extends React.Component {
  constructor() {
    super();
    this.state = {
      id: null,
      name: "",
      nameError: false,
      email: "",
      emailError: false,
      typeList: [
        {
          name: "Admin",
          value: "1",
          disabled: false,
        },
        {
          name: "Merchant",
          value: "2",
          disabled: false,
        },
        {
          name: "Partner",
          value: "3",
          disabled: false,
        },
        {
          name: "Customer",
          value: "4",
          disabled: true,
        },
        {
          name: "Outlet",
          value: "5",
          disabled: false,
        },
      ],
      type: [],
      typeError: false,
      phone: "",
      phoneError: false,
      password: "",
      passwordError: false,
      repeatPassword: "",
      repeatPasswordError: false,
      loading: false,
      merchantList: [],
      partnerList: [],
      merchant: null,
      partner: null,
      merchantError: false,
      partnerError: false,
      outlet: null,
      outletList: [],
      outletError: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    let it = this;
    it.setState({ loading: true });
    const {
      match: { params },
    } = this.props;
    UserModel.getInstance().getUserDetails(
      params.userId,
      function success(data) {
        // if (data.role_name === 'Admin') {
        //     console.log(data)
        it.setState({
          loading: false,
          id: data.id,
          name: data.full_name,
          email: data.email,
          phone: data.mobile ? data.mobile : " ",
          type: [String(data.role_id)],
          type: data.roles.map((role) => {
            return String(role.id);
          }),
          merchant: data.merchants
            ? data.merchants.map((merch) => {
                return merch.id;
              })
            : [],
          partner: data.partners
            ? data.partners.map((part) => {
                return part.id;
              })
            : [],
          outlet: data.outlets
            ? data.outlets.map((out) => {
                return out.id;
              })
            : [],
          merchantList: data.merchants ? data.merchants : [],
          outletList: data.outlets ? data.outlets : [],
        });
        // let types = [];

        // data.role.map(type => {
        //     types.push(type.id)
        // })
        // it.setState({ type: types })
        // } else {
        //     window.location.href = "/users"
        // }
      },
      function failure(error) {
        console.log(error);
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
    this.getPartnerList();
  }

  sendData() {
    let {
      id,
      name,
      email,
      type,
      phone,
      password,
      repeatPassword,
      merchant,
      partner,
      partner_id,
      merchant_id,
      outlet,
      outlet_id,
    } = this.state;
    const { history } = this.props;
    this.setState({
      nameError: false,
      emailError: false,
      typeError: false,
      phoneError: false,
      passwordError: false,
      repeatPasswordError: false,
      merchantError: false,
      partnerError: false,
      outletError: false,
    });
    let it = this;
    let params = {};

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster("Please Enter Name");
      return;
    }
    params.full_name = name;

    if (!validateEmail(email)) {
      this.setState({ emailError: true });
      errorToaster("Please Enter Valid Email");
      return;
    }
    params.email = email;

    if (type.length < 1) {
      this.setState({ typeError: true });
      errorToaster("Please Choose Type");
      return;
    }
    params.role_id = type;

    // validateMerchant
    if (type.includes("2")) {
      if (merchant.length < 1) {
        this.setState({ merchantError: true });
        errorToaster("Please enter merchant");
        return;
      }
      merchant_id = [merchant_id];
      params.merchant_id = merchant;
    } else params.merchant_id = [];

    // validatePartner

    if (type.includes("3")) {
      if (partner.length < 1) {
        this.setState({ partnerError: true });
        errorToaster("Please enter partner");
        return;
      }
      partner_id = [partner_id];
      params.partner_id = partner;
    } else params.partner_id = [];

    // validateOutlet
    if (type.includes("5")) {
      if (outlet.length < 1) {
        this.setState({ outletError: true });
        errorToaster("Please select outlet");
        return;
      }
      outlet_id = [outlet_id];
      params.outlet_id = outlet;
    } else params.outlet_id = [];

    if (!validatePhoneCountry(phone)) {
      this.setState({ phoneError: true });
      errorToaster("Please Enter Valid Phone number");
      return;
    }
    params.mobile = phone;

    // if (password.length > 0 || repeatPassword.length > 0) {
    //     if (password.length < 6 || password.length > 14) {
    //         this.setState({ password: true });
    //         errorToaster('Password Must not be Less than 6 or greater than 14 characters')
    //         return
    //     }
    //     params.password = password;
    //     if (password !== repeatPassword) {
    //         this.setState({ repeatPassword: true })
    //         errorToaster('Repeat Password Is not matched with Password')
    //         return
    //     }
    // }
    // params.type = "admin";

    this.setState({ loading: true });

    UserModel.getInstance().updateUser(
      id,
      params,
      function success(data) {
        successNotification("User updated Successfully");
        setTimeout(() => {
          history.push("/users");
        }, 500);
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQueryMerchant = this.debounce(function (value, it) {
    it.getMerchantList(value);
  }, 1000);

  getMerchantList(query) {
    let it = this;
    it.setState({ loading: true });
    let params = {
      query,
    };
    MerchantModel.getInstance().getMerchant(
      params,
      function success(data) {
        it.setState({
          merchantList: data.data,
          loading: false,
        });
      },
      function failure(msg) {
        console.log(msg);
      }
    );
  }

  getPartnerList() {
    let it = this;
    it.setState({
      loading: true,
    });
    PartnerModel.getInstance().getPartner(
      function success() {
        it.setState({
          loading: false,
          partnerList: PartnerModel.getInstance().PartnerList,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQueryOutlet = this.debounce(function (value, it) {
    it.getOutletList(value);
  }, 1000);

  getOutletList(name) {
    let it = this;
    it.setState({ loading: true });
    let params = {
      "outlets.name": name.toLowerCase(),
    };
    MerchantModel.getInstance().getSearchOutlet(
      params,
      function success() {
        it.setState({
          loading: false,
          outletList: MerchantModel.getInstance().OutletList,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  render() {
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                <div className="kt-portlet__head kt-portlet__head--lg">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Edit User</h3>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                    <a href="/users" className="btn btn-clean kt-margin-r-10">
                      <i className="la la-arrow-left"></i>
                      <span className="kt-hidden-mobile">Back</span>
                    </a>
                    <div className="btn-group">
                      <button
                        onClick={() => this.sendData()}
                        type="button"
                        className="btn btn-brand"
                      >
                        <i className="la la-check"></i>
                        <span className="kt-hidden-mobile">Save</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* end header */}
                <div className="kt-portlet__body">
                  <form className="kt-form" id="kt_form">
                    <div className="row">
                      <div className="col-xl-2"></div>
                      <div className="col-xl-8">
                        <div className="kt-section kt-section--first">
                          <Spin spinning={this.state.loading}>
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Full Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    disabled={
                                      this.state.type == "4" ? true : false
                                    }
                                    value={this.state.name}
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    Please enter full name
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Email <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    disabled={
                                      this.state.type == "4" ? true : false
                                    }
                                    value={this.state.email}
                                    onChange={(event) =>
                                      this.setState({
                                        email: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="email"
                                  />
                                  <span className="form-text text-muted">
                                    Please enter your email
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="userRole"
                                >
                                  Role <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    mode="multiple"
                                    value={this.state.type}
                                    onChange={(type) => this.setState({ type })}
                                  >
                                    {this.state.typeList.map((type) => (
                                      <Option
                                        disabled={type.disabled}
                                        value={type.value}
                                      >
                                        {type.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    Please choose role
                                  </span>
                                </div>
                              </div>

                              {this.state.type.includes("2") ? (
                                <div className="form-group row">
                                  <label
                                    className="col-3 col-form-label"
                                    htmlFor="userRole"
                                  >
                                    Merchant <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <Select
                                      showSearch
                                      style={{ margin: "10px 0px 10px 0px" }}
                                      mode="multiple"
                                      onChange={(merchant) =>
                                        this.setState({ merchant })
                                      }
                                      value={this.state.merchant}
                                      notFoundContent={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Icon
                                            style={{
                                              fontSize: 16,
                                              marginRight: "10px",
                                              marginBottom: "5px",
                                            }}
                                            type="search"
                                          />
                                          <p style={{ fontSize: 14 }}>
                                            Please Search Merchant by Name
                                          </p>
                                        </div>
                                      }
                                      onSearch={(val) =>
                                        this.searchQueryMerchant(val, this)
                                      }
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {this.state.merchantList.map((merch) => (
                                        <Option key={merch.id} value={merch.id}>
                                          {merch.name}
                                        </Option>
                                      ))}
                                    </Select>

                                    <span className="form-text text-muted">
                                      <p
                                        style={{
                                          color: this.state.merchantError
                                            ? "red"
                                            : "",
                                        }}
                                      >
                                        Please choose Merchant
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              ) : null}

                              {this.state.type.includes("3") ? (
                                <div className="form-group row">
                                  <label
                                    className="col-3 col-form-label"
                                    htmlFor="userRole"
                                  >
                                    Partner <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <Select
                                      showSearch
                                      style={{ margin: "10px 0px 10px 0px" }}
                                      mode="multiple"
                                      onChange={(partner) =>
                                        this.setState({ partner })
                                      }
                                      value={this.state.partner}
                                    >
                                      {this.state.partnerList.map((partner) => (
                                        <Option
                                          key={partner.id}
                                          value={partner.id}
                                        >
                                          {partner.name}
                                        </Option>
                                      ))}
                                    </Select>

                                    <span className="form-text text-muted">
                                      <p
                                        style={{
                                          color: this.state.partnerError
                                            ? "red"
                                            : "",
                                        }}
                                      >
                                        Please choose Partner
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              ) : null}

                              {this.state.type.includes("5") ? (
                                <div className="form-group row">
                                  <label
                                    className="col-3 col-form-label"
                                    htmlFor="userRole"
                                  >
                                    Outlet <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <Select
                                      showSearch
                                      mode="multiple"
                                      style={{ margin: "10px 0px 10px 0px" }}
                                      onChange={(outlet) =>
                                        this.setState({ outlet })
                                      }
                                      value={this.state.outlet}
                                      notFoundContent={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Icon
                                            style={{
                                              fontSize: 16,
                                              marginRight: "10px",
                                              marginBottom: "5px",
                                            }}
                                            type="search"
                                          />
                                          <p style={{ fontSize: 14 }}>
                                            Please Search Outlet by Name
                                          </p>
                                        </div>
                                      }
                                      onSearch={(val) =>
                                        this.searchQueryOutlet(val, this)
                                      }
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {this.state.outletList.map((outlet) => (
                                        <Option
                                          key={outlet.id}
                                          value={outlet.id}
                                        >
                                          {outlet.name}
                                        </Option>
                                      ))}
                                    </Select>

                                    <span className="form-text text-muted">
                                      <p
                                        style={{
                                          color: this.state.outletError
                                            ? "red"
                                            : "",
                                        }}
                                      >
                                        Please choose Outlet
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              ) : null}

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Phone <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    disabled={
                                      this.state.type == "4" ? true : false
                                    }
                                    value={this.state.phone}
                                    onChange={(event) =>
                                      this.setState({
                                        phone: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="name"
                                  />
                                  <span className="form-text text-muted">
                                    Please enter your phone number
                                  </span>
                                </div>
                              </div>

                              {/* <div className="form-group row">
                                                                <label className="col-3 col-form-label">New Password</label>
                                                                <div className="col-9">
                                                                    <input value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })} className="form-control" type="password" />
                                                                    <span className="form-text text-muted">Please enter your new password</span>

                                                                </div>
                                                            </div> */}

                              {/* <div className="form-group row">
                                                                <label className="col-3 col-form-label">Confirm Password</label>
                                                                <div className="col-9">
                                                                    <input value={this.state.repeatPassword} onChange={(event) => this.setState({ repeatPassword: event.target.value })} className="form-control" type="password" />
                                                                    <span className="form-text text-muted">Please enter your Confirm password</span>

                                                                </div>
                                                            </div> */}
                            </div>
                          </Spin>
                        </div>
                      </div>
                      <div className="col-xl-2"></div>
                    </div>
                  </form>
                </div>
                {/* end body */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
