/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import _ from "lodash";
import {
    Select,
    Popover,
    Spin,
    Switch
} from 'antd';

import {
    successNotification,
    errorNotification,
    errorToaster
} from '../Shared/Notifications';

import static_data from "./data.json";

import SharedModel from '../../Models/SharedModel';
import ProgramModel from '../../Models/ProgramModel';
import AppProgramPaymentModel from '../../Models/AppProgramPaymentModel';

const { Option } = Select;


export default function AddAppVisibility(props) {
    const { match: { params } } = props;

    const
        [loading, setLoading] = useState(false),
        [payment_method, set_payment_method] = useState(undefined),
        [is_app, set_is_app] = useState(true),
        [app_id, set_app_id] = useState(null),
        [app_list, set_app_list] = useState([]),
        [program_id, set_program_id] = useState(null),
        [program_list, set_program_list] = useState([]);

    useEffect(() => {
        getAllAppIds()
        getAppProgramPaymentDetail()
    }, []);

    const getAppProgramPaymentDetail = () => {
        setLoading(true);
        AppProgramPaymentModel.getInstance().getAppProgramPaymentDetail(params.id,
            function success(data) {
                set_payment_method(data.payment_method);
                set_is_app(data.app_id ? true : false);
                set_app_id(data.app_id);
                set_program_id(data.program_id);
                set_program_list(data.program_id ? [data.program] : []);
                setLoading(false);
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    const getAllAppIds = () => {
        setLoading(true);
        SharedModel.getInstance().getAllAppIds(
            function success(data) {
                setLoading(false)
                set_app_list(data)
            },
            function failure(error) {
                setLoading(false)
                errorToaster("Request information not found")
            }
        )
    }

    const getSearchPrograms = useCallback(_.debounce(value => getAllPrograms(value), 500), []);
    async function getAllPrograms(value) {
        if (value.length > 0) {
            const _params = {
                'programs.name': encodeURIComponent(value)
            };
            ProgramModel.getInstance().searchProgram(_params,
                function success(data) {
                    set_program_list(data.data);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)

                }
            )
        }
    }


    const updateAppProgramPayment = () => {

        if (!payment_method) {
            errorToaster("Please Select Payment Method")
            return
        }
        if (is_app && !app_id) {
            errorToaster("Please Select App")
            return
        }
        if (!is_app && !program_id) {
            errorToaster("Please Select Program")
            return
        }

        const _params = {
            payment_method,
            app_id,
            program_id,
        };
        AppProgramPaymentModel.getInstance().updateAppProgramPayment(params.id, _params,
            function success() {
                successNotification('Payment Method Saved');
                setTimeout(() => {
                    setLoading(false);
                    window.location.href = '/app-program-payment'
                }, 2000);
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                <Spin spinning={loading} >

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                <div className="kt-portlet__head kt-portlet__head--lg">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">Edit App/Program Payment</h3>
                                    </div>
                                    <div className="kt-portlet__head-toolbar">
                                        <a href="/app-program-payment" className="btn btn-clean kt-margin-r-10">
                                            <i className="la la-arrow-left"></i>
                                            <span className="kt-hidden-mobile">Back</span>
                                        </a>
                                        <div className="btn-group">
                                            <button onClick={() => updateAppProgramPayment()} type="button" className="btn btn-brand">
                                                <i className="la la-check"></i>
                                                <span className="kt-hidden-mobile">Save</span>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                                <div className="kt-portlet__body">
                                    <form className="kt-form" id="kt_form">
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-8">

                                                <div className="kt-section kt-section--first">

                                                    <div className="kt-section__body">


                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Payment Method <b>*</b></label>
                                                            <div className="col-9">
                                                                <Select
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    onChange={(val) => set_payment_method(val)}
                                                                    value={payment_method}
                                                                    filterOption={(input, option) =>
                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                >
                                                                    {static_data.payment_method_list.map(meth => (
                                                                        <Option key={meth.value} value={meth.value} >{meth.name}</Option>
                                                                    ))}
                                                                </Select>
                                                                <span className="form-text text-muted"><p style={{}}>Please Select Payment Method</p></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">{is_app ? 'App' : 'Program'} <b>*</b></label>
                                                            <Popover className="col-2" title={`Toggle to ${is_app ? 'Program' : 'App'}`}>
                                                                <Switch style={{ backgroundColor: is_app ? '#5d78ff' : '#fd397a' }} checkedChildren="App" unCheckedChildren="Program" checked={is_app}
                                                                    onChange={(val) => {
                                                                        set_program_id(undefined)
                                                                        set_app_id(undefined)
                                                                        set_is_app(val)
                                                                    }} />
                                                            </Popover>
                                                            <div className="col-7">

                                                                {
                                                                    is_app ?
                                                                        <Select
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            onChange={(app_id) => set_app_id(app_id)}
                                                                            value={app_id}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {app_list.map(type => (
                                                                                <Option key={type.id} value={type.id} >{type.name}</Option>
                                                                            ))}
                                                                        </Select> :
                                                                        <Select
                                                                            showSearch
                                                                            onSearch={(val) => getSearchPrograms(val)}
                                                                            optionFilterProp="children"
                                                                            onChange={(program_id) => set_program_id(program_id)}
                                                                            value={program_id}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {program_list.map(type => (
                                                                                <Option key={type.id} value={type.id} >{type.name}</Option>
                                                                            ))}
                                                                        </Select>

                                                                }

                                                                <span className="form-text text-muted"><p style={{}}>Please select {is_app ? 'App' : 'Program'}</p></span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div >

    )
}