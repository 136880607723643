import React from "react";

import { Spin, Select, Icon } from "antd";
import { getCompressedImage } from "Helpers/Shared/utils";

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";

import CollectionModel from "../../Models/CollectionModel";

import backImage from "../../../src/assets/media/users/default.jpg";
import SharedModel from "../../Models/SharedModel";
import MerchantModel from "../../Models/MerchantModel";

const { Option } = Select;

export default class AddCollection extends React.Component {
  constructor() {
    super();
    this.state = {
      backgroundImage: null,
      backgroundImageError: false,
      backgroundImageSrc: null,
      name: "",
      nameError: false,
      position: null,
      positionError: false,
      loading: false,
      merchantList: [],
      merchant: [],
    };
  }
  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getMerchants("");
  }

  searchQueryMerchant = this.debounce(function (value, it) {
    it.getMerchants(value);
  }, 1000);

  getMerchants(value) {
    let it = this;
    let params = {};
    if (value.length > 0) {
      params.query = value;
    }
    console.info(params);
    MerchantModel.getInstance().getMerchant(
      params,
      function success(data) {
        it.setState({ merchantList: data.data });
      },
      function failure(error) {
        console.error(error);
      }
    );
  }


  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  saveLogo(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ loading: true });
    getCompressedImage(file)
      .then((compressedImage) => {
        if (compressedImage) {
          this.setState({
            loading: false,
            backgroundImage: compressedImage,
            backgroundImageSrc: URL.createObjectURL(compressedImage),
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        errorNotification(e);
      });
  }

  sendData() {
    const { name, position, backgroundImage, merchant, } = this.state;
    let it = this;
    const text = "Validation Error! Please check fields";
    this.setState({
      backgroundImageError: false,
      nameError: false,
      positionError: false,
    });

    if (!backgroundImage) {
      this.setState({ backgroundImageError: true });
      errorToaster(text);
      return;
    }

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster(text);
      return;
    }
    if (!position) {
      this.setState({ positionError: true });
      errorToaster(text);
      return;
    }
    if (
      parseFloat(position) === parseFloat(position) &&
      parseFloat(position) % 1 !== 0
    ) {
      this.setState({ positionError: true });
      errorToaster(text);
      return;
    }
    if (position < 1 || position > 20) {
      this.setState({ positionError: true });
      errorToaster(text);
      return;
    }

    it.setState({ loading: true });
    CollectionModel.getInstance().addCollection(
      name,
      position,
      merchant,
      function success(data) {
        it.addMedia(backgroundImage, data.collection.id);
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.error(error);
      }
    );
  }

  addMedia(backgroundImage, id) {
    let it = this;
    let params = new FormData();
    params.append("image", backgroundImage);

    SharedModel.getInstance().addMediaInstance(
      id,
      "collection",
      params,
      function success() {
        it.setState({ loading: false });
        successNotification("Collection Created Successfully");
        setTimeout(() => {
          window.location.href = "/collection";
        }, 1000);
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.error(error);
      }
    );
  }

  render() {
    const {
      backgroundImageSrc,
      nameError,
      positionError,
      backgroundImageError,
      loading,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <Spin spinning={loading}>
          <div className="kt-content  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">Add Collection</h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/collection"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                  Upload Background Image <b>*</b>
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <div
                                    className="kt-avatar kt-avatar--outline"
                                    style={{ borderRadius: "10px" }}
                                    id="kt_apps_user_add_avatar"
                                  >
                                    <div
                                      className="kt-avatar__holder"
                                      style={{
                                        backgroundImage: `url(${backgroundImageSrc
                                          ? backgroundImageSrc
                                          : backImage
                                          })`,
                                      }}
                                    ></div>

                                    <label
                                      className="kt-avatar__upload"
                                      data-toggle="kt-tooltip"
                                      title=""
                                      data-original-title="Change avatar"
                                    >
                                      <i className="fa fa-pen"></i>
                                      <input
                                        onChange={(event) =>
                                          this.saveLogo(event)
                                        }
                                        type="file"
                                        name="kt_apps_user_add_user_avatar"
                                      />
                                    </label>
                                    <span
                                      className="kt-avatar__cancel"
                                      data-toggle="kt-tooltip"
                                      title=""
                                      data-original-title="Cancel avatar"
                                    >
                                      <i className="fa fa-times"></i>
                                    </span>
                                    <p
                                      style={{
                                        color: "red",
                                        display: backgroundImageError
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      Please Upload Icon Image
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="70"
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter name
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Position <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="100"
                                    onChange={(event) =>
                                      this.setState({
                                        position: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                  <span
                                    style={{
                                      color: positionError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: positionError ? "red" : "",
                                      }}
                                    >
                                      Please enter position in between 1-20
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Merchants
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    // allowClear
                                    mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    // placeholder="Select a merchant group"
                                    value={this.state.merchant}
                                    onChange={(value) =>
                                      this.setState({ merchant: value })
                                    }
                                    notFoundContent={
                                      // <Tooltip title="Add Type">
                                      //     <Icon onClick={() => this.appendType()} disabled={false} type="plus-circle" theme="filled" style={{ color: '#3758ff', fontSize: '20px' }} />
                                      // </Tooltip>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Icon
                                          style={{
                                            fontSize: 16,
                                            marginRight: "10px",
                                            marginBottom: "5px",
                                          }}
                                          type="search"
                                        />
                                        <p style={{ fontSize: 14 }}>
                                          Please Search Merchants
                                        </p>
                                      </div>
                                    }
                                    onSearch={(val) =>
                                      this.searchQueryMerchant(val, this)
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.state.merchantList.map((merchant) => (
                                      <Option
                                        key={merchant.id}
                                        value={merchant.id}
                                      >
                                        {merchant.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span
                                    style={{
                                      color: positionError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: positionError ? "red" : "",
                                      }}
                                    >
                                      Please enter position in between 1-20
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
