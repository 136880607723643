import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel'



export default class EcommerceOfferModel {
    static myInstance = null;
    static getInstance() {
        if (EcommerceOfferModel.myInstance === null) {
            EcommerceOfferModel.myInstance = new EcommerceOfferModel();
        }
        return this.myInstance;
    }

    static EcommerceOfferList = [];


// Ecommerce offer 
    getEcommerceOffer( successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getEcommerceOffer(token, 
            function resSuccess(data) {
                EcommerceOfferModel.getInstance().EcommerceOfferList = data.offers.data
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    searchEcommerceOffer(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().searchEcommerceOffer(token, params,
            function resSuccess(data) {
                EcommerceOfferModel.getInstance().EcommerceOfferList = data.offers.data;
                successTrigger(data);
            },
            
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    addEcommerceOffer(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addEcommerceOffer(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    getEcommerceOfferDetail(ecommerceOfferId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getEcommerceOfferDetail(token, ecommerceOfferId, 
            function resSuccess(data) {
                successTrigger(data.offer);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateEcommerceOffer(ecommerceOfferId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateEcommerceOffer(token, ecommerceOfferId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    ecommerceOfferActivation(ecommerceOfferId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().ecommerceOfferActivation(token, ecommerceOfferId, params,
            function resSuccess(data) {
                EcommerceOfferModel.getInstance().EcommerceOfferList.forEach((offer, index) => {
                    if (offer.id === ecommerceOfferId) {
                        offer.is_active = params.is_active;
                        successTrigger(data)
                    }
                });
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    removeEcommerceOffer(ecommerceOfferId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeEcommerceOffer(token, ecommerceOfferId,
            function resSuccess() {
                EcommerceOfferModel.getInstance().EcommerceOfferList.forEach((offer, index) => {
                    if (offer.id === ecommerceOfferId) {
                        EcommerceOfferModel.getInstance().EcommerceOfferList.splice(index, 1);
                        successTrigger()
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


}