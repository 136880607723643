import React from "react";
import {
  Input,
  Select,
  Spin,
  // notification
} from "antd";

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";

import UserModel from "../../Models/UserModel";

const { Option } = Select;

export default class EditArea extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      address: undefined,
      countriesList: [],
      provinceList: [],
      citiesList: [],
      countryId: null,
      provinceId: null,
      cityId: null,
      area: "",
      areaId: null,
      areaError: false,
      city: "",
      cityError: false,
      province: "",
      country: "",
      countryError: false,
      arabic_name: "",
      arabic_nameError: false,
      lat: 0,
      lng: 0,
      namError: false,
      tempLat: "",
      tempLng: "",
      loading: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    let it = this;
    this.setState({ loading: true });
    const {
      match: { params },
    } = this.props;
    this.setState({ areaId: params.areaId });
    UserModel.getInstance().getAreaDetail(
      params.areaId,
      function success(data) {
        console.log("ANDR AO RAJA: ", data);
        it.setState({
          areaId: params.areaId,
          name: data.name,
          arabic_name: data.arabic_name ? data.arabic_name : "",
          // address: data.meta.address,
          city: data.city_name,
          cityId: data.city_id,
          province: data.province_name,
          provinceId: data.province_id,
          // country: data.city.country.name,
          countryId: data.country_id,
          lat: data.lat ? data.lat : 0,
          lng: data.lng ? data.lng : 0,
        });
        it.getCountries();
        console.log(data);
      },
      function failure(error) {
        it.setState({ loading: false });
        console.error(error);
      }
    );
  }

  getAddress(completeAddress) {
    this.setState({ address: completeAddress });
    this.setState({
      provinceId: null,
      cityId: null,
    });
    let it = this;
    if (completeAddress) {
      var address = completeAddress.address_components;
      address.forEach(function (component) {
        var types = component.types;

        // if (types.indexOf('sublocality') > -1) {
        //     it.setState({area:component.long_name});
        // }

        if (types.indexOf("locality") > -1) {
          it.setState({ city: component.long_name });
        }

        if (types.indexOf("administrative_area_level_1") > -1) {
          it.setState({ province: component.long_name });
        }

        if (types.indexOf("country") > -1) {
          it.setState({ country: component.long_name });
        }
      });
      this.state.countriesList.forEach((country) => {
        if (country.name === this.state.country) {
          this.setState({ countryId: country.id });
          this.getProvince(country.id);
        }
      });

      this.setState({
        lat: completeAddress.geometry.location.lat(),
        lng: completeAddress.geometry.location.lng(),
      });
    }
  }
  getCountries() {
    let it = this;
    this.setState({ loading: true });
    UserModel.getInstance().getCountries(
      function success() {
        it.setState({
          countriesList: UserModel.getInstance().CountriesList,
          loading: false,
        });
        it.getProvince(it.state.countryId);
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  getProvince(countryId) {
    let it = this;
    it.setState({ loading: true });
    let params = {
      id: countryId,
    };
    UserModel.getInstance().getProvince(
      params,
      function success() {
        it.setState({
          provinceList: UserModel.getInstance().ProvinceList,
          loading: false,
        });
        it.state.provinceList.forEach((province) => {
          if (province.name === it.state.province) {
            it.getCities(province.id);
          }
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  getCities(provinceId) {
    let it = this;
    it.setState({
      loading: true,
      provinceId,
    });
    let params = {
      id: provinceId,
    };
    UserModel.getInstance().getCities(
      params,
      function success() {
        it.setState({
          citiesList: UserModel.getInstance().CitiesList,
          loading: false,
        });
        it.state.citiesList.forEach((city) => {
          if (city.name === it.state.city) {
            it.setState({ cityId: city.id });
          }
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  sendData() {
    const { history } = this.props;
    let it = this;
    const text = "Validation Error! Please check fields";
    this.setState({
      nameError: false,
      areaError: false,
      arabic_nameError: false,
      cityError: false,
      provinceError: false,
      countryError: false,
      tempError: false,
    });
    let {
      name,
      areaId,
      //   area,
      arabic_name,
      cityId,
      provinceId,
      countryId,
      lat,
      lng,
    } = this.state;
    lat = Number(lat);
    lng = Number(lng);
    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster(text);
      return;
    }
    // if (area.length < 1) {
    //     this.setState({ areaError: true });
    //     errorToaster(text);
    //     return;
    // }
    if (!lat || !lng) {
      this.setState({ tempError: true });
      errorToaster("Please Add lat and lng");
      return;
    }
    if (!countryId) {
      this.setState({ countryError: true });
      errorToaster(text);
      return;
    }
    if (!provinceId) {
      this.setState({ provinceError: true });
      errorToaster(text);
      return;
    }
    if (!cityId) {
      this.setState({ cityError: true });
      errorToaster(text);
      return;
    }
    it.setState({ loading: true });
    UserModel.getInstance().updateArea(
      areaId,
      name,
      arabic_name,
      provinceId,
      countryId,
      cityId,
      lat,
      lng,
      function success() {
        successNotification("Area Updated Successfully");
        setTimeout(() => {
          history.push("/area");
        }, 1000);
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  render() {
    const { provinceError, cityError, countryError } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Edit Area</h3>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                    <a href="/area" className="btn btn-clean kt-margin-r-10">
                      <i className="la la-arrow-left"></i>
                      <span className="kt-hidden-mobile">Back</span>
                    </a>
                    <div className="btn-group">
                      <button
                        type="button"
                        onClick={() => this.sendData()}
                        className="btn btn-brand"
                      >
                        <i className="la la-check"></i>
                        <span className="kt-hidden-mobile">Save</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* end header */}
                <Spin spinning={this.state.loading}>
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              {/* <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Name</label>
                                                                    <div className="col-9">
                                                                        <input className="form-control" type="text" defaultValue="Nick" />
                                                                        <span className="form-text text-muted">Please enter name in English</span>

                                                                    </div>
                                                                </div> */}
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Name *
                                </label>
                                <div className="col-9">
                                  {/* <AutoComplete
                                                                        addressString={(addressString) => {
                                                                            let newAddress = addressString.split(",");
                                                                            this.setState({
                                                                                area: newAddress[0],
                                                                                countryId: null,
                                                                                provinceList: [],
                                                                                provinceId: null,
                                                                                citiesList: [],
                                                                                cityId: null
                                                                            })

                                                                        }}
                                                                        address={this.state.area}
                                                                        getAddress={(address) => this.getAddress(address)}
                                                                        types={['(regions)']}
                                                                        setAddress={(area) => this.setState({ area, address: '' })}
                                                                    /> */}
                                  <Input
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    value={this.state.name}
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    {" "}
                                    <p
                                      style={{
                                        color: this.state.nameError
                                          ? "red"
                                          : "",
                                      }}
                                    >
                                      {" "}
                                      Please enter area name{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Arabic Name
                                </label>
                                <div className="col-9">
                                  <Input
                                    onChange={(event) =>
                                      this.setState({
                                        arabic_name: event.target.value,
                                      })
                                    }
                                    value={this.state.arabic_name}
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    {" "}
                                    <p
                                      style={{
                                        color: this.state.arabic_nameError
                                          ? "red"
                                          : "",
                                      }}
                                    >
                                      {" "}
                                      Please enter name in Arabic{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="marchantCategory"
                                >
                                  Country *
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    optionFilterProp="children"
                                    // className="form-control"
                                    onChange={(countryId) => {
                                      this.setState({
                                        countryId,
                                        provinceList: [],
                                        provinceId: null,
                                        citiesList: [],
                                        cityId: null,
                                      });
                                      this.getProvince(countryId);
                                    }}
                                    value={this.state.countryId}
                                    // onFocus={onFocus}
                                    // onBlur={onBlur}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.state.countriesList.map((country) => (
                                      <Option
                                        key={country.id}
                                        value={country.id}
                                      >
                                        {country.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  {/* <Input className="form-control" onChange={(event)=> this.setState({country:event.target.value})} type="text" value={country} /> */}
                                  <span className="form-text text-muted">
                                    {" "}
                                    <p
                                      style={{
                                        color: countryError ? "red" : "",
                                      }}
                                    >
                                      Please Select Country
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="marchantCategory"
                                >
                                  Province *
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    optionFilterProp="children"
                                    // className="form-control"
                                    // disabled
                                    onChange={(province) => {
                                      this.getCities(province);
                                      this.setState({
                                        cityId: null,
                                        city: "",
                                      });
                                    }}
                                    value={this.state.provinceId}
                                    // onFocus={onFocus}
                                    // onBlur={onBlur}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.state.provinceList.map((province) => (
                                      <Option
                                        key={province.id}
                                        value={province.id}
                                      >
                                        {province.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  {/* <Input className="form-control" onChange={(event)=> this.setState({province:event.target.value})} type="text" value={province} /> */}
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: provinceError ? "red" : "",
                                      }}
                                    >
                                      Please enter Province
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="marchantCategory"
                                >
                                  City *
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    optionFilterProp="children"
                                    // className="form-control"
                                    onChange={(value) =>
                                      this.setState({ cityId: value })
                                    }
                                    value={this.state.cityId}
                                    // onFocus={onFocus}
                                    // onBlur={onBlur}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.state.citiesList.map((city) => (
                                      <Option key={city.id} value={city.id}>
                                        {city.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  {/* <Input className="form-control" onChange={(event)=> this.setState({city:event.target.value})} type="text" value={city} /> */}
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: cityError ? "red" : "" }}
                                    >
                                      Please enter City
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  lat & Lng *
                                </label>
                                <div className="col-9">
                                  <div className="row">
                                    <Input
                                      // disabled={!this.state.fromCoordinates}
                                      style={{
                                        margin: "10px 0px 10px 0px",
                                        width: "30%",
                                        marginRight: "5%",
                                        marginLeft: "2%",
                                      }}
                                      value={this.state.lat}
                                      onChange={(event) =>
                                        this.setState({
                                          lat: event.target.value,
                                        })
                                      }
                                    />
                                    <Input
                                      // disabled={!this.state.fromCoordinates}
                                      style={{
                                        margin: "10px 0px 10px 0px",
                                        width: "30%",
                                        marginRight: "5%",
                                      }}
                                      value={this.state.lng}
                                      onChange={(event) =>
                                        this.setState({
                                          lng: event.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <span className="form-text text-muted">
                                    {" "}
                                    <p
                                      style={{
                                        color: this.state.tempError
                                          ? "red"
                                          : "",
                                      }}
                                    >
                                      {" "}
                                      Please enter lat & lng
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
