import React, { useState, useEffect } from "react";

import { Select, Input, Upload, Switch, Spin, Tabs } from "antd";

import ColorPicker from "../Shared/ColorPicker";

import UserModel from "../../Models/UserModel";

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
import SharedModel from "../../Models/SharedModel";
import CollectionModel from "../../Models/CollectionModel";
import OnlineDealModel from "../../Models/OnlineDealModel";
import ProductBookingModel from "../../Models/ProductBookingModel";
import OfferModel from "../../Models/OfferModel";
import MerchantModel from "../../Models/MerchantModel";

import {
  checkRouteValidation,
  refactorParamRoute,
  RenderRouteIdList,
} from "./utils";

import DATA from "./data.json";

const { Option } = Select;
const { TabPane } = Tabs;

export default function EditAppBanner(props) {
  const [loading, setLoading] = useState(false),
    [appId, setAppId] = useState(null),
    [isMainBanner, setIsMainBanner] = useState(true),
    [mainBanner, setMainBanner] = useState(undefined),
    [mainBannerSrc, setMainBannerSrc] = useState(undefined),
    [mainBannerServerSrc, setMainBannerServerSrc] = useState(undefined),
    [mainBannerContent, setMainBannerContent] = useState(""),
    [mainBannerRoute, setMainBannerRoute] = useState(undefined),
    [bannerOccurance, setBannerOccurance] = useState("session"),
    [categoryId, setCategoryId] = useState(null),
    [subCategoryId, setSubCategoryId] = useState(null),
    [collectionId, setCollectionId] = useState(null),
    [merchantId, setMerchantId] = useState(null),
    [ecomMerchantId, setEcomMerchantId] = useState(null),
    [externalUrl, setExternalUrl] = useState(""),
    [videoUrl, setVideoUrl] = useState(""),
    [onlineDealMerchantId, setOnlineDealMerchantId] = useState(null),
    [onlineDealId, setOnlineDealId] = useState(null),
    [productBookingOutletId, setProductBookingOutletId] = useState(null),
    [productBookingId, setProductBookingId] = useState(null),
    [b1g1OutletId, setB1g1OutletId] = useState(null),
    [b1g1OfferId, setB1g1OfferId] = useState(null),
    [appList, setAppList] = useState([]),
    [collectionList, setCollectionList] = useState([]),
    [categoryList, setCategoryList] = useState([]),
    [subCategoryList, setSubCategoryList] = useState([]),
    [merchantList, setMerchantList] = useState([]),
    [ecomMerchantList, setEcomMerchantList] = useState([]),
    [onlineDealMerchantList, setOnlineDealMerchantList] = useState([]),
    [onlineDealList, setOnlineDealList] = useState([]),
    [productBookingOutletList, setProductBookingOutletList] = useState([]),
    [productBookingList, setProductBookingList] = useState([]),
    [b1g1OutletList, setB1g1OutletList] = useState([]),
    [b1g1OfferList, setB1g1OfferList] = useState([]),
    [appAddBannerList, setAppAddBannerList] = useState([]),
    [linkMediaBanner, setLinkMediaBanner] = useState(null),
    [linkMediaURL, setLinkMediaURL] = useState(null),
    [proceedButtonText, setProceedButtonText] = useState(""),
    [proceedButtonTextColor, setProceedButtonTextColor] = useState("#000000"),
    [proceedButtonPlacement, setProceedButtonPlacement] = useState(null),
    [proceedButtonBackgroundColor, setProceedButtonBackgroundColor] =
      useState("#ffffff"),
    [exitButtonTextColor, setExitButtonTextColor] = useState("#000000"),
    [exitButtonBackgroundColor, setExitButtonBackgroundColor] =
      useState("#ffffff");

  useEffect(() => {
    getAllAppIds();
    callMainCategories();
    return () => {};
  }, []);

  useEffect(() => {
    getBannerDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveMainBanner = ({ file }) => {
    setLinkMediaBanner(null);
    setLinkMediaURL(null);
    const isImage =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isImage) {
      errorToaster("You can only upload Image file!");
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorToaster("Image must smaller than 2MB!");
      return;
    }
    setMainBanner(file);
    setMainBannerSrc(URL.createObjectURL(file));
    return isImage && isLt2M;
  };

  const getBannerDetail = () => {
    const {
      match: { params },
    } = props;
    SharedModel.getInstance().getAppBannerDetail(
      params.id,
      function success(data) {
        setAppId(data.app_id);
        setIsMainBanner(data.is_main);
        setMainBannerRoute(data.route);
        setMainBannerServerSrc(data.image_url);
        setMainBannerContent(data.content);
        setCategoryId(data.category_id);
        setCollectionId(data.collection_id);
        setCollectionList(data.collections ? [data.collections] : []);
        setMerchantId(data.merchant_id);
        setExternalUrl(data.external_url);
        setVideoUrl(data.video_url);
        setBannerOccurance(data.banner_occurance);
        setSubCategoryId(data.sub_category_id);
        if (data.categories) callSubCategories(data.categories.id);
        if (data.proceed_button_text)
          setProceedButtonText(data.proceed_button_text);
        if (data.proceed_button_background_color)
          setProceedButtonBackgroundColor(data.proceed_button_background_color);
        if (data.proceed_button_text_color)
          setProceedButtonTextColor(data.proceed_button_text_color);
        if (data.proceed_button_placement)
          setProceedButtonPlacement(data.proceed_button_placement);
        if (data.exit_button_background_color)
          setExitButtonBackgroundColor(data.exit_button_background_color);
        if (data.exit_button_text_color)
          setExitButtonTextColor(data.exit_button_text_color);
        if (data.route === "merchants") setMerchantList([data.merchants]);
        if (data.route === "delivery_merchants") {
          setEcomMerchantId(data.merchant_id);
          getEcommerceMerchants(data.merchants.name);
        }
        if (data.product_online_deals) {
          setOnlineDealId(data.product_online_deals.id);
          setOnlineDealList([data.product_online_deals]);
        }
        if (data.online_deal_merchants) {
          setOnlineDealMerchantId(data.online_deal_merchants.id);
          setOnlineDealMerchantList([data.online_deal_merchants]);
        }
        if (data.product_bookings) {
          setProductBookingId(data.product_bookings.id);
          setOnlineDealMerchantList([data.product_bookings]);
        }
        if (data.product_booking_outlets) {
          setProductBookingOutletId(data.product_booking_outlets.id);
          setProductBookingOutletList([data.product_booking_outlets]);
        }
        if (data.b1g1_offers) {
          setB1g1OfferId(data.b1g1_offers.id);
          setB1g1OfferList([data.b1g1_offers]);
        }
        if (data.b1g1_outlets) {
          setB1g1OutletId(data.b1g1_outlets.id);
          setB1g1OutletList([data.b1g1_outlets]);
        }
      },
      function failure(error) {
        console.error(error);
      }
    );
  };

  const callMainCategories = () => {
    UserModel.getInstance().getCategory(
      { parent: true },
      function success(data) {
        setLoading(false);
        setCategoryList(UserModel.getInstance().CategoryList);
      },
      function failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  };

  const callSubCategories = (id) => {
    UserModel.getInstance().getCategory(
      { parent: true, id },
      function success(data) {
        setLoading(false);
        setSubCategoryList(data.data);
      },
      function failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  };

  const getAllAppIds = () => {
    SharedModel.getInstance().getAllAppIds(
      function success(data) {
        setLoading(false);
        setAppList(data);
      },
      function failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  };

  const getCollectionList = (name) => {
    let params = {
      name,
      app_id: appId ? appId : 1,
    };
    CollectionModel.getInstance().getCollectionByApp(
      params,
      function success(data) {
        setLoading(false);
        setCollectionList(data);
      },
      function failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  };

  const getMerchants = (query) => {
    let params = {
      query,
    };
    MerchantModel.getInstance().getMerchant(
      params,
      function success(data) {
        setLoading(false);
        setMerchantList(data.data);
      },
      function failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  };

  const getOnlineDealMerchants = (name) => {
    let params = {
      name,
    };
    OnlineDealModel.getInstance().getOnlineDealMerchants(
      params,
      function success(data) {
        setLoading(false);
        setOnlineDealMerchantList(data);
      },
      function failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  };
  const getOnlineDealByMerchantId = (merchant_id) => {
    let params = {
      offset: 0,
      limit: 100,
      merchant_id,
    };
    setLoading(true);
    OnlineDealModel.getInstance().getAllOnlineDeals(
      params,
      function success(data) {
        setLoading(false);
        setOnlineDealList(data.records);
      },
      function failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  };

  const getProductBookingOutlets = (name) => {
    let params = {
      name,
    };
    ProductBookingModel.getInstance().getProductBookingOutlets(
      params,
      function success(data) {
        setLoading(false);
        setProductBookingOutletList(data);
      },
      function failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  };
  const getProductBookingByOutletId = (outlet_id) => {
    const params = {
      "$outlet.id$": outlet_id,
    };
    setLoading(true);
    ProductBookingModel.getInstance().getAllProductBookings(
      params,
      function success(data) {
        setLoading(false);
        setProductBookingList(data.records);
      },
      function failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  };

  const getApplicationAdBanner = (name) => {
    let params = {
      content: name,
    };
    SharedModel.getInstance().searchAppBanner(
      params,
      function success(data) {
        setLoading(false);
        setAppAddBannerList(data.records);
      },
      function failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  };

  const getB1G1AssociatedOutlets = (name) => {
    let params = {
      name,
    };
    MerchantModel.getInstance().getB1G1AssociatedOutlets(
      params,
      function success(data) {
        setLoading(false);
        setB1g1OutletList(data);
      },
      function failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  };
  const getB1G1OffersByOutletId = (outlet_id) => {
    let params = {
      "$outlet_offers_association.outlet_id$": outlet_id,
    };
    setLoading(true);
    OfferModel.getInstance().getB1G1Offers(
      params,
      function success(data) {
        setLoading(false);
        setB1g1OfferList(data.records);
      },
      function failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  };

  const getEcommerceMerchants = (name) => {
    let params = {
      "merchants.name": name.toLocaleLowerCase(),
    };
    MerchantModel.getInstance().getEcommerceMerchants(
      params,
      function success(data) {
        setLoading(false);
        setEcomMerchantList(data);
      },
      function failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  };

  const debounce = (callback, wait) => {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  };

  const searchQueryMerchant = debounce(function (value) {
    getMerchants(value);
  }, 1000);

  const searchQueryCollection = debounce(function (value) {
    getCollectionList(value);
  }, 1000);

  const searchQueryEcommerceMerchant = debounce(function (value) {
    getEcommerceMerchants(value);
  }, 1000);

  const searchQueryOnlineDealMerchant = debounce(function (value) {
    getOnlineDealMerchants(value);
  }, 1000);

  const searchQueryProductBookingOutlet = debounce(function (value) {
    getProductBookingOutlets(value);
  }, 1000);

  const searchQueryB1G1Outlets = debounce(function (value) {
    getB1G1AssociatedOutlets(value);
  }, 1000);

  const searchQueryAppAdBanner = debounce(function (value) {
    getApplicationAdBanner(value);
  }, 1000);

  const sendData = async () => {
    if (!appId) {
      errorToaster("Please Enter App Id");
      return;
    }

    if (mainBannerContent.length < 1) {
      errorToaster("Please Enter Banner Name");
      return;
    }

    if (!mainBannerRoute) {
      errorToaster("Please Select Main Banner Route");
      return;
    }
    if (mainBannerRoute !== "youtube" && !mainBannerServerSrc && !mainBanner) {
      errorToaster("Please upload Main Banner Image");
      return;
    }
    const validateRoutes = checkRouteValidation(
      mainBannerRoute,
      categoryId,
      collectionId,
      merchantId,
      ecomMerchantId,
      onlineDealMerchantId,
      onlineDealId,
      productBookingOutletId,
      productBookingId,
      b1g1OutletId,
      b1g1OfferId,
      videoUrl,
      externalUrl
    );
    if (validateRoutes.status === false) {
      errorToaster(validateRoutes.message);
      return;
    }
    if (isMainBanner) {
      if (proceedButtonText.length < 1) {
        errorToaster("Please Enter Proceed Button Text");
        return;
      }
      if (!proceedButtonPlacement) {
        errorToaster("Please Select Placement for Proceed Button");
        return;
      }
    }
    let params = {
      is_main: isMainBanner,
      app_id: appId,
      image: mainBanner,
      server_image_url: mainBannerServerSrc,
      content: mainBannerContent,
      route: mainBannerRoute,
      proceed_button_text: proceedButtonText,
      proceed_button_background_color: proceedButtonBackgroundColor,
      proceed_button_text_color: proceedButtonTextColor,
      proceed_button_placement: proceedButtonPlacement,
      exit_button_background_color: exitButtonBackgroundColor,
      exit_button_text_color: exitButtonTextColor,
      category_id: undefined,
      collection_id: undefined,
      merchant_id: undefined,
      external_url: undefined,
      banner_occurance: bannerOccurance,
    };
    if (linkMediaURL) params["image_url"] = linkMediaURL;
    params = factorParamRoute(params);
    addData(params);
  };

  const factorParamRoute = (params) => {
    return refactorParamRoute(
      params,
      categoryId,
      subCategoryId,
      collectionId,
      merchantId,
      ecomMerchantId,
      onlineDealMerchantId,
      onlineDealId,
      productBookingOutletId,
      productBookingId,
      b1g1OutletId,
      b1g1OfferId,
      videoUrl,
      externalUrl
    );
  };

  const addData = (params) => {
    setLoading(true);
    if (!params.image || linkMediaURL) {
      delete params.image;
      addAppbanner(params);
      return;
    }
    UserModel.getInstance().addMedia(
      params.image,
      function success(url) {
        delete params.image;
        params["image_url"] = url;
        addAppbanner(params);
      },
      function failure(err) {
        errorNotification(JSON.stringify(err));
        setLoading(false);
      }
    );
  };

  const addAppbanner = (data) => {
    const { server_image_url } = data;
    delete data.server_image_url;
    const {
      match: { params },
    } = props;

    SharedModel.getInstance().updateAppBanner(
      params.id,
      data,
      function success() {
        if (params.image_url) {
          removeMedia(server_image_url);
        }
        successNotification("Banner Created Successfully");
        setTimeout(() => {
          setLoading(false);
          window.location.href = "/app-ad-banner";
        }, 2000);
      },
      function failure(err) {
        errorNotification(JSON.stringify(err));
        removeMedia(data.image_url);
        setLoading(false);
      }
    );
  };

  const removeMedia = (urlId) => {
    UserModel.getInstance().removeMedia(
      urlId,
      function success() {
        console.info("success");
      },
      function failure(err) {
        console.error(err);
      }
    );
  };
  const beforeUpload = (file) => {
    return false;
  };

  const uploadButton = (
    <div>
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={loading}>
          <div className="row">
            <div className="col-lg-12">
              <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                <div className="kt-portlet__head kt-portlet__head--lg">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Edit App Banner</h3>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                    <a
                      href="/app-ad-banner"
                      className="btn btn-clean kt-margin-r-10"
                    >
                      <i className="la la-arrow-left"></i>
                      <span className="kt-hidden-mobile">Back</span>
                    </a>
                    <div className="btn-group">
                      <button
                        onClick={() => sendData()}
                        type="button"
                        className="btn btn-brand"
                      >
                        <i className="la la-check"></i>
                        <span className="kt-hidden-mobile">Save</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="kt-portlet__body">
                  <form className="kt-form" id="kt_form">
                    <div className="row">
                      <div className="col-xl-2"></div>
                      <div className="col-xl-8">
                        <div className="kt-section kt-section--first">
                          <div className="kt-section__body">
                            <Tabs defaultActiveKey="overview">
                              <TabPane key="overview" tab="Overview">
                                <div className="form-group row">
                                  <label className="col-3 col-form-label">
                                    App Name <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <Select
                                      showSearch
                                      optionFilterProp="children"
                                      disabled={true}
                                      onChange={(appId) => setAppId(appId)}
                                      value={appId}
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {appList.map((type) => (
                                        <Option key={type.id} value={type.id}>
                                          {type.name}
                                        </Option>
                                      ))}
                                    </Select>
                                    <span className="form-text text-muted">
                                      <p style={{}}>Please select an App</p>
                                    </span>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-3 col-form-label">
                                    Banner Name <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    {/* input here */}
                                    <Input
                                      maxLength={255}
                                      value={mainBannerContent}
                                      onChange={(event) =>
                                        setMainBannerContent(event.target.value)
                                      }
                                      className="form-control"
                                      type="text"
                                    />
                                    <span className="form-text text-muted">
                                      <p style={{}}>
                                        Please Enter Banner content
                                      </p>
                                    </span>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-3 col-form-label">
                                    Main Banner
                                  </label>
                                  <div className="col-9">
                                    <Switch
                                      checkedChildren="Main Banner"
                                      unCheckedChildren="Carousel Banner"
                                      checked={isMainBanner}
                                      onChange={(check) =>
                                        setIsMainBanner(check)
                                      }
                                    />

                                    <span className="form-text text-muted">
                                      <p style={{}}>
                                        Please toggle Banner type
                                      </p>
                                    </span>
                                  </div>
                                </div>

                                <div>
                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Main Banner Route <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={(val) => {
                                          setMainBannerRoute(val);
                                        }}
                                        value={mainBannerRoute}
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {DATA.RouteList.map((route) => (
                                          <Option
                                            disabled={
                                              isMainBanner &&
                                              route.value === "youtube"
                                            }
                                            key={route.value}
                                            value={route.value}
                                          >
                                            {route.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <span className="form-text text-muted">
                                        <p style={{}}>Please Select Route</p>
                                      </span>
                                    </div>
                                  </div>

                                  {!isMainBanner ? null : (
                                    <div className="form-group row">
                                      <label className="col-3 col-form-label">
                                        Main Banner Occurance{" "}
                                      </label>
                                      <div className="col-9">
                                        <Select
                                          showSearch
                                          optionFilterProp="children"
                                          allowClear={false}
                                          onChange={(val) =>
                                            setBannerOccurance(val)
                                          }
                                          value={bannerOccurance}
                                          filterOption={(input, option) =>
                                            option.props.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                        >
                                          {DATA.MainBannerOccurance.map(
                                            (occ) => (
                                              <Option
                                                key={occ.value}
                                                value={occ.value}
                                              >
                                                {occ.name}
                                              </Option>
                                            )
                                          )}
                                        </Select>
                                        <span className="form-text text-muted">
                                          <p style={{}}>
                                            Please choose main banner occurance
                                          </p>
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  <RenderRouteIdList
                                    route={mainBannerRoute}
                                    setCategoryId={setCategoryId}
                                    setSubCategoryList={setSubCategoryList}
                                    setSubCategoryId={setSubCategoryId}
                                    setCollectionId={setCollectionId}
                                    setMerchantId={setMerchantId}
                                    setEcomMerchantId={setEcomMerchantId}
                                    setOnlineDealMerchantId={
                                      setOnlineDealMerchantId
                                    }
                                    setOnlineDealId={setOnlineDealId}
                                    setProductBookingOutletId={
                                      setProductBookingOutletId
                                    }
                                    setProductBookingId={setProductBookingId}
                                    setB1g1OutletId={setB1g1OutletId}
                                    setB1g1OfferId={setB1g1OfferId}
                                    setExternalUrl={setExternalUrl}
                                    setVideoUrl={setVideoUrl}
                                    searchQueryCollection={
                                      searchQueryCollection
                                    }
                                    searchQueryMerchant={searchQueryMerchant}
                                    searchQueryEcommerceMerchant={
                                      searchQueryEcommerceMerchant
                                    }
                                    searchQueryOnlineDealMerchant={
                                      searchQueryOnlineDealMerchant
                                    }
                                    searchQueryProductBookingOutlet={
                                      searchQueryProductBookingOutlet
                                    }
                                    searchQueryB1G1Outlets={
                                      searchQueryB1G1Outlets
                                    }
                                    getOnlineDealByMerchantId={
                                      getOnlineDealByMerchantId
                                    }
                                    getProductBookingByOutletId={
                                      getProductBookingByOutletId
                                    }
                                    getB1G1OffersByOutletId={
                                      getB1G1OffersByOutletId
                                    }
                                    callSubCategories={callSubCategories}
                                    categoryId={categoryId}
                                    categoryList={categoryList}
                                    subCategoryId={subCategoryId}
                                    subCategoryList={subCategoryList}
                                    collectionId={collectionId}
                                    collectionList={collectionList}
                                    merchantId={merchantId}
                                    merchantList={merchantList}
                                    ecomMerchantId={ecomMerchantId}
                                    ecomMerchantList={ecomMerchantList}
                                    onlineDealMerchantId={onlineDealMerchantId}
                                    onlineDealMerchantList={
                                      onlineDealMerchantList
                                    }
                                    onlineDealId={onlineDealId}
                                    onlineDealList={onlineDealList}
                                    productBookingOutletId={
                                      productBookingOutletId
                                    }
                                    productBookingOutletList={
                                      productBookingOutletList
                                    }
                                    productBookingId={productBookingId}
                                    productBookingList={productBookingList}
                                    b1g1OutletId={b1g1OutletId}
                                    b1g1OutletList={b1g1OutletList}
                                    b1g1OfferId={b1g1OfferId}
                                    b1g1OfferList={b1g1OfferList}
                                    externalUrl={externalUrl}
                                    videoUrl={videoUrl}
                                  />

                                  {!isMainBanner ? null : (
                                    <div>
                                      <div className="form-group row">
                                        <label className="col-3 col-form-label">
                                          Proceed Button Text <b>*</b>
                                        </label>
                                        <div className="col-9">
                                          <Input
                                            maxLength={21}
                                            value={proceedButtonText}
                                            onChange={(event) =>
                                              setProceedButtonText(
                                                event.target.value
                                              )
                                            }
                                            className="form-control"
                                            type="text"
                                          />
                                          <span className="form-text text-muted">
                                            <p style={{}}>
                                              Please enter Proceed Button Text
                                            </p>
                                          </span>
                                        </div>
                                      </div>

                                      <div className="form-group row">
                                        <label className="col-3 col-form-label">
                                          Proceed Button Placement <b>*</b>
                                        </label>
                                        <div className="col-9">
                                          <Select
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={(val) => {
                                              setProceedButtonPlacement(val);
                                            }}
                                            value={proceedButtonPlacement}
                                            filterOption={(input, option) =>
                                              option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                              0
                                            }
                                          >
                                            {DATA.ButtonPlacement.map(
                                              (place) => (
                                                <Option
                                                  key={place.value}
                                                  value={place.value}
                                                >
                                                  {place.name}
                                                </Option>
                                              )
                                            )}
                                          </Select>
                                          <span className="form-text text-muted">
                                            <p style={{}}>
                                              Please enter Proceed Button Text
                                            </p>
                                          </span>
                                        </div>
                                      </div>

                                      <div className="form-group row">
                                        <label className="col-3 col-form-label">
                                          Choose Proceed Button Text Color
                                          <b>*</b>
                                        </label>
                                        <div className="col-9">
                                          <ColorPicker
                                            backColor={(color) =>
                                              setProceedButtonTextColor(color)
                                            }
                                            defaultColor={
                                              proceedButtonTextColor
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-group row">
                                        <label className="col-3 col-form-label">
                                          Choose Proceed Button Background Color
                                          <b>*</b>
                                        </label>
                                        <div className="col-9">
                                          <ColorPicker
                                            backColor={(color) =>
                                              setProceedButtonBackgroundColor(
                                                color
                                              )
                                            }
                                            defaultColor={
                                              proceedButtonBackgroundColor
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-group row">
                                        <label className="col-3 col-form-label">
                                          Choose Exit Button Text Color<b>*</b>
                                        </label>
                                        <div className="col-9">
                                          <ColorPicker
                                            backColor={(color) =>
                                              setExitButtonTextColor(color)
                                            }
                                            defaultColor={exitButtonTextColor}
                                          />
                                        </div>
                                      </div>

                                      <div className="form-group row">
                                        <label className="col-3 col-form-label">
                                          Choose Exit Button Text Color<b>*</b>
                                        </label>
                                        <div className="col-9">
                                          <ColorPicker
                                            backColor={(color) =>
                                              setExitButtonBackgroundColor(
                                                color
                                              )
                                            }
                                            defaultColor={
                                              exitButtonBackgroundColor
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </TabPane>
                              {mainBannerRoute !== "youtube" && (
                                <TabPane key="media" tab="Media">
                                  <div className="form-group row">
                                    <label
                                      className="col-3 col-form-label"
                                      htmlFor="marchantGroup"
                                    >
                                      Linked Media
                                    </label>
                                    <div className="col-9">
                                      <Select
                                        showSearch
                                        onSearch={(value) =>
                                          searchQueryAppAdBanner(value)
                                        }
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        value={linkMediaBanner}
                                        onChange={(linkMediaBanner) => {
                                          setLinkMediaBanner(linkMediaBanner);
                                          setLinkMediaURL(
                                            appAddBannerList.find(
                                              (_x) => _x.id === linkMediaBanner
                                            )?.image_url
                                          );
                                          setMainBanner(null);
                                          setMainBannerSrc(null);
                                        }}
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {appAddBannerList.map((val) => (
                                          <Option key={val.id} value={val.id}>
                                            {`${val.content} - ${
                                              val.is_active
                                                ? "Active"
                                                : "De-Active"
                                            } - ${val.id}`}
                                          </Option>
                                        ))}
                                      </Select>
                                      <span className="form-text text-muted">
                                        Please choose linked Media Banner
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Banner Image <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        beforeUpload={beforeUpload}
                                        onChange={saveMainBanner}
                                      >
                                        {linkMediaURL ||
                                        mainBannerSrc ||
                                        mainBannerServerSrc ? (
                                          <img
                                            src={
                                              linkMediaURL
                                                ? linkMediaURL
                                                : mainBannerSrc
                                                ? mainBannerSrc
                                                : mainBannerServerSrc
                                            }
                                            alt="avatar"
                                            style={{ width: "100%" }}
                                          />
                                        ) : (
                                          uploadButton
                                        )}
                                      </Upload>
                                      <span className="form-text text-muted">
                                        <p style={{}}>
                                          Please Upload Banner Image
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                </TabPane>
                              )}
                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
}
