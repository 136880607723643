import React from 'react';
import {
    Icon,
    Spin,
    Switch,
    Popconfirm,
    Select,
    DatePicker
} from 'antd';
import { Link } from 'react-router-dom'
import matchSorter from 'match-sorter';

import ReactTable from 'react-table';
import 'react-table/react-table.css';
import SharedModel from '../../Models/SharedModel';
import { errorNotification, infoToaster } from '../Shared/Notifications';
import moment from 'moment';
import downloadCSV from '../Shared/CsvDownloader'

const { Option } = Select;
const { RangePicker } = DatePicker

export default class PaymentReport extends React.Component {

    constructor() {
        super();
        this.state = {
            data: [],
            defaultProgram: null,
            loading: false,
            startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            currentPage: 1,
            totalPage: 0,
            filters: []
        }
    }

    componentWillMount(){
        const role_id = localStorage.getItem("user_role")
        switch (Number(role_id)) {
            case 1:
                break;
             case 5:
                 window.location.href = '/order-report'
                 break;
             default:
                window.location.href = '/login'
                break;
        }
     }

    componentDidMount() {
        this.getPaymentReport();
    }

    getPaymentType(text) {
        if ('payment'.includes(text)) {
            return 1;
        }
        if ('coupon'.includes(text)) {
            return 2;
        }
        if ('vip'.includes(text)) {
            return 3;
        }
        if ('bin'.includes(text)) {
            return 4;
        }
        if ('coupon+bin'.includes(text)) {
            return 5;
        }
        return 6;
    }

    async getPaymentReport() {
        const it = this;
        let { startDate, endDate, currentPage, filters } = this.state;
        let params = {
            from: startDate,
            to: endDate,
            offset: currentPage,
            type: 'payment'
        };

        filters.forEach(filter => {
            params[filter.id] = filter.value.toLowerCase()
        });
        if (params['payment_logs.type']) {
            params['payment_logs.type'] = this.getPaymentType(params['payment_logs.type'])
        }
        if (params['users.mobile']) {
            params['users.mobile'] = params['users.mobile'].replace('+', '')
        }

        it.setState({ loading: true })
        await SharedModel.getInstance().searchReport(params,
            function success(data) {
                it.setState({
                    data: data.data,
                    loading: false,
                    totalPage: data.last_page,
                })
                if (it.state.data.length < 1) {
                    infoToaster('No Data Found')
                }
            },
            function failure(error) {
                it.setState({ loading: false, data: [] })
                errorNotification("Request information not found")
                console.log(error)
            }
        )
    }

    exportData() {
        let it = this;
        let { startDate, endDate, filters } = this.state;
        let params = {
            type: 'payment',
            from: startDate,
            to: endDate,
        }
        filters.forEach(filter => {
            params[filter.id] = filter.value.toLowerCase();
        });
        if (params['payment_logs.type']) {
            params['payment_logs.type'] = this.getPaymentType(params['payment_logs.type'])
        }
        if (params['users.mobile']) {
            params['users.mobile'] = params['users.mobile'].replace('+', '')
        }
        infoToaster('Please Wait! File will be Downloaded shortly');
        // it.setState({ loading: true });
        SharedModel.getInstance().downloadReport(params,
            function success(data) {
                it.setState({
                    loading: false,

                })
                infoToaster(data)
                // it.exportCSV(data)
            },
            function failure(error) {
                it.setState({ loading: false })
                errorNotification(error)
                console.log(error)
            }
        )
    }

    getData() {
        this.getPaymentReport();
    }

    disabledDate(current) {
        return current > moment().endOf('day');
    }

    exportCSV(data) {
        let { startDate, endDate } = this.state;
        let newArr = []
        data.forEach(log => {
            let newObj = {};
            newObj['Customer Name'] = log.user_name;
            newObj['Customer Email'] = log.user_email;
            newObj['Customer Phone'] = ` \"${log.user_mobile}\"`;
            newObj['Partner'] = log.partners.map((partner) => { return partner.name }).join('|');
            newObj['Program'] = log.program_name;
            newObj['App Name'] = log.app_name;
            newObj['Amount'] = log.amount;
            newObj['Type'] = log.type ? log.type : 'N/A';
            newObj['Code'] = log.code ? log.code : 'Payment';
            newObj['Payment Date'] = moment(log.created_at).format('DD/MMM/YYYY - hh:mm A');
            // newObj['Merchant'] = log.merchant.name;
            // newObj['Outlet'] = log.outlet.name;
            // newObj['Offer'] = log.offer.name;
            // newObj['Category'] = log.merchant_category.name;
            // newObj['Estimated Saving'] = log.estimate_saving ? log.estimate_saving : 'N/A';
            newArr.push(newObj)
        });
        downloadCSV({ filename: 'Payment Logs from ' + startDate + ' to ' + endDate + '.csv' }, newArr)
    }

    debounce(callback, wait) {
        let timeout;
        return (...args) => {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => callback.apply(context, args), wait);
        };
    }

    searchQuery = this.debounce(function (it) {
        it.getPaymentReport()
    }, 1000);

    paymentReportActivation(paymentId, is_active) {
        const it = this;
        it.setState({ loading: true })
        let activation = is_active === true ? false : true;
        let params = {};
        params.is_active = activation
        SharedModel.getInstance().paymentReportActivation(paymentId, params,
            function success() {
                it.setState({
                    loading: false
                })
            },
            function failure(error) {
                it.setState({ loading: false })
                errorNotification(error)
                console.log(error)
            }

        )
    }


    render() {
        let { data } = this.state;
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                    <Spin spinning={this.state.loading}>
                        <div className="kt-portlet kt-portlet--mobile">
                            <div className="kt-portlet__head kt-portlet__head--lg">
                                <div className="kt-portlet__head-label">
                                    <span className="kt-portlet__head-icon">
                                        <i className="kt-font-brand flaticon2-line-chart"></i>
                                    </span>
                                    <h3 className="kt-portlet__head-title">
                                        Payment Report
										</h3>
                                </div>
                                {/* <div className="kt-portlet__head-toolbar">
                                    <div className="kt-portlet__head-wrapper">
                                        <div className="kt-portlet__head-actions">
                                            <a href="/program/add" className="btn btn-brand btn-elevate btn-icon-sm">
                                                <i className="la la-plus"></i>
                                                Add New Program
												</a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="kt-portlet__body">

                                {/* <div className="form-group row">
                                    <label className="col-3 col-form-label" htmlFor="marchantGroup">Default Program</label>
                                    <div className="col-9">

                                        <Select
                                            showSearch
                                            style={{ margin: '10px 0px 10px 0px', width: '35%' }}
                                            onChange={(partner) => this.setDefaultProgram(partner)}
                                            value={this.state.defaultProgram}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {data.map((partner) => (
                                                <Option key={partner.id} value={partner.id} >{partner.name}</Option>
                                            ))}
                                        </Select>

                                    </div>
                                </div> */}

                                <div className="form-group row">
                                    <label className="col-3 col-form-label">Start Date ~ End Date <b>*</b></label>
                                    <div className="col-9">
                                        <RangePicker
                                            disabledDate={this.disabledDate}
                                            className="form-control"
                                            defaultValue={[moment().subtract(1, 'months'), moment()]}
                                            style={{ padding: "0px", border: '0px solid black', width: '35%' }}
                                            showTime={false}
                                            onChange={(value, string) => this.setState({ startDate: string[0], endDate: string[1] })}
                                            format="YYYY-MM-DD"
                                        />
                                    </div>
                                </div>

                                <div style={{ marginLeft: '-10px' }} className="col-3 col-form-label">
                                    <div className="btn-group">
                                        <button onClick={() => this.getData()} type="button" className="btn btn-brand">
                                            <i className="la la-search"></i>
                                            <span className="kt-hidden-mobile">Search</span>
                                        </button>
                                    </div>
                                    <div className="btn-group">
                                        <button disabled={data.length > 0 ? false : true} onClick={() => this.exportData()} style={{ marginLeft: '10px' }} type="button" className="btn btn-brand">
                                            <i className="la la-download"></i>
                                            <span className="kt-hidden-mobile">Export</span>
                                        </button>
                                    </div>
                                </div>

                                {/* react-table */}
                                {
                                    // data.length > 0 &&
                                    <ReactTable
                                        data={data}
                                        filterable
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value

                                        }

                                        pageSize={20}
                                        // showPageJump={false}
                                        showPageSizeOptions={false}
                                        manual
                                        page={this.state.currentPage - 1}
                                        pages={this.state.totalPage}
                                        onPageChange={(pageIndex) => {
                                            this.setState({ currentPage: pageIndex + 1 })
                                            setTimeout(() => {
                                                this.getPaymentReport()
                                            }, 50);
                                        }}
                                        onFilteredChange={(filters) => {
                                            this.setState({ currentPage: 1, filters })
                                            this.searchQuery(this)
                                        }}

                                        columns={[
                                            // {
                                            // Header: "Name",
                                            // columns: [
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Customer Name</p>,
                                                id: "users.full_name",
                                                accessor: d => d.user_name,
                                                style: { whiteSpace: 'unset' },
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["user_name"] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Email</p>,
                                                id: "users.email",
                                                accessor: d => d.user_email,
                                                style: { whiteSpace: 'unset' },
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["user_email"] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Contact Number</p>,
                                                id: "users.mobile",
                                                // accessor: d => d.user_mobile.
                                                accessor: d => d.user_mobile,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["user_mobile"] }),
                                                // filterMethod: (filter, rows) =>
                                                //     matchSorter(rows, filter.value, { keys: ["user_mobile"] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Partner</p>,
                                                id: "partners.name",
                                                accessor: d => d.partners ? d.partners.map((partner, key) => <p className="btn btn-label-info btn-pill" style={{ float: 'left', margin: '2px', padding: '5px 10px', fontSize: '11px' }} key={key}>{partner.name}</p>) : null,
                                                // accessor: d => d.partners.map((partner) => partner.name),
                                                // accessor: d => d.partners.map((partner) => partner.name),
                                                filterMethod: (filter, rows) =>
                                                    // matchSorter(rows, filter.value, { keys: [item => item.partners] }),
                                                    matchSorter(rows, filter.value, { keys: [item => item.partners.map((partner) => partner.props.children)] }),

                                                filterAll: true,
                                                sortable: false,
                                                // filterable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Program</p>,
                                                id: "programs.name",
                                                accessor: d => d.program_name,
                                                // accessor: d => d.partners.map((partner) => partner.name),
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ['program_name'] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >App Name</p>,
                                                id: "apps.name",
                                                accessor: d => d.app_name,
                                                // accessor: d => d.partners.map((partner) => partner.name),
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ['app_name'] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Amount</p>,
                                                id: "payment_logs.amount",
                                                accessor: d => d.amount,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["amount"] }),
                                                filterAll: true,
                                                sortable: false,
                                                // filterable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Type</p>,
                                                id: "payment_logs.type",
                                                style: { whiteSpace: 'unset' },
                                                accessor: d => {
                                                    return d.type ? d.type : "N/A"
                                                    // return d.type == "Payment" ? "Payment" : d.type == "Coupon" ? "Coupon" : d.type == "VIP" ? "VIP" : d.type == "BIN" ? "BIN" : "N/A"
                                                },
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["type"] }),
                                                filterAll: true,
                                                // filterable: false,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Code</p>,
                                                id: "code",
                                                style: { whiteSpace: 'unset' },
                                                accessor: d => {
                                                    return d.code ? d.code : 'Payment'
                                                },
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["code"] }),
                                                filterAll: true,
                                                // filterable: false,
                                                sortable: false
                                            },
                                            // {
                                            //     Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Payment Mode</p>,
                                            //     id: "payment_mode",
                                            //     accessor: d => d.payment_mode.name,
                                            //     // accessor: d => d.partners.map((partner) => partner.name),
                                            //     filterMethod: (filter, rows) =>
                                            //         matchSorter(rows, filter.value, { keys: ["payment_mode"] }),
                                            //     filterAll: true
                                            // },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Date</p>,
                                                id: "payment_logs.created_at",
                                                accessor: d => moment(d.created_at).format('DD/MMM/YYYY - hh:mm A'),
                                                // accessor: d => d.partners.map((partner) => partner.name),
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ['created_at'] }),
                                                filterAll: true,
                                                sortable: false,
                                                filterable: false
                                            },
                                            // {
                                            //     Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Estimated Saving</p>,
                                            //     id: "partners",
                                            //     accessor: d => d.partners.map((partner) => partner.name),
                                            //     // accessor: d => d.partners.map((partner) => partner.name),
                                            //     filterMethod: (filter, rows) =>
                                            //         matchSorter(rows, filter.value, { keys: [item => item.partners] }),
                                            //     filterAll: true
                                            // },
                                            // {
                                            //     Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >App ID</p>,
                                            //     id: "partners",
                                            //     accessor: d => d.partners.map((partner) => partner.name),
                                            //     // accessor: d => d.partners.map((partner) => partner.name),
                                            //     filterMethod: (filter, rows) =>
                                            //         matchSorter(rows, filter.value, { keys: [item => item.partners] }),
                                            //     filterAll: true
                                            // },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Status</p>,
                                                id: "is_active",
                                                accessor: "is_active",
                                                Cell: ({ value }) => (value ? <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">Active</span> : <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">De-Activate</span>),
                                                filterMethod: (filter, row) => {
                                                    if (filter.value === "all") {
                                                        return true;
                                                    }
                                                    if (filter.value === 'true') {

                                                        return row[filter.id] === true;
                                                    }
                                                    return row[filter.id] === false;
                                                },
                                                Filter: ({ filter, onChange }) =>
                                                    <Select
                                                        onChange={value => onChange(value)}
                                                        style={{ width: "100%" }}
                                                        defaultValue="all"
                                                    >
                                                        <Option value="all">Show All</Option>
                                                        <Option value="true">Active</Option>
                                                        <Option value="false">De-Activate</Option>
                                                    </Select>,
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Actions</p>,
                                                id: "actions",
                                                width: 100,
                                                sortable: false,
                                                accessor: d => (
                                                    <div>

                                                        <Popconfirm
                                                            title={d.is_active ? "Are you sure you want to DEACTIVATE this program" : "Are you sure you want to ACTIVATE this program"}
                                                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                           onConfirm={() => this.paymentLogActivation(d.id, d.is_active)}
                                                        >
                                                            <Switch style={{ backgroundColor: d.is_active ? '#5d78ff' : '#fd397a', marginLeft: 15 }} checked={d.is_active} onChange={(val) => console.log(val)} />
                                                        </Popconfirm>
                                                    </div>
                                                ),
                                                filterable: false
                                            },

                                        ]
                                        }
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                    />
                                }


                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }
}