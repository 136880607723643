/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import {
    Icon,
    Popconfirm,
    Switch,
    Select,
} from 'antd';

import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import _ from 'lodash';


const { Option } = Select;

export default function ProductBookingAddonsDataTable({ data, deleteBookingAddon, updateBookingAddonActivation, product_booking_id, getProductBookingAddons, total_count }) {

    const [bookingAddonData, setBookingAddonData] = useState([]),
        [filters, setFilters] = useState([]),
        [pageCount] = useState(20),
        [total_pages, setTotalPages] = useState(1),
        [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setBookingAddonData(data);
    }, [data]);

    useEffect(() => {
        createPagination();
    }, [bookingAddonData]);

    useEffect(() => {
        if (filters.length > 0) getSearchProductBookingAddons(filters);
    }, [filters]);


    function createPagination() {
        setTotalPages(Math.ceil(total_count / pageCount))
    }

    const getSearchProductBookingAddons = useCallback(_.debounce((_filters) => getAllProductBookingAddons(_filters), 500), []);
    function getAllProductBookingAddons(_filters) {
        let params = {
            offset: (currentPage - 1) * pageCount,
            limit: pageCount,
        };
        _filters.forEach((filter) => {
            params[filter.id] = filter.value;
        });
        switch (params["is_active"]) {
            case "all":
                delete params["is_active"];
                break;

            case "true":
                params["is_active"] = true;
                break;

            case "false":
                params["is_active"] = false;
                break;

            default:
                break;
        };
        getProductBookingAddons(params);
    }

    return (
        <ReactTable
            data={bookingAddonData}
            filterable
            showPageSizeOptions={false}
            defaultPageSize={pageCount}
            manual
            page={currentPage - 1}
            pages={total_pages}
            filtered={filters}
            onPageChange={(pageIndex) => {
                setCurrentPage(pageIndex + 1);
                setTimeout(() => {
                    getAllProductBookingAddons();
                }, 50);
            }}
            onFilteredChange={(_filters) => {
                setFilters(_filters);
                setCurrentPage(1)
            }}

            defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value}
            columns={[

                {
                    Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Name</p>,
                    id: "name",
                    accessor: d => d.name,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                    filterable: true
                },
                {
                    Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Arabic Name</p>,
                    id: "arabic_name",
                    accessor: d => d.arabic_name,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["arabic_name"] }),
                    filterAll: true,
                    filterable: true
                },
                {
                    Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Description</p>,
                    id: "description",
                    accessor: d => d.description,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["description"] }),
                    filterAll: true,
                    filterable: true
                },
                {
                    Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Arabic Description</p>,
                    id: "arabic_description",
                    accessor: d => d.arabic_description,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["arabic_description"] }),
                    filterAll: true,
                    filterable: false
                },
                {
                    Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Status</p>,
                    id: "is_active",
                    accessor: "is_active",
                    Cell: ({ value }) => (value ? <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">Active</span> : <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">De-Activate</span>),
                    filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                            return true;
                        }
                        if (filter.value === 'true') {

                            return row[filter.id] === true;
                        }
                        return row[filter.id] === false;
                    },
                    Filter: ({ filter, onChange }) =>
                        <Select
                            onChange={value => onChange(value)}
                            style={{ width: "100%" }}
                            defaultValue="all"
                            value={filter ? filter.value : "all"}
                        >
                            <Option value="all">Show All</Option>
                            <Option value="true">Active</Option>
                            <Option value="false">De-Activate</Option>
                        </Select>,
                },
                {
                    Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Action</p>,
                    id: "actions",
                    width: 150,
                    Cell: ({ row }) => (
                        <div style={{ justifyContent: 'center' }}>
                            <>
                                <Icon onClick={() => {
                                    window.location.href = `/product-booking/addons/edit/${product_booking_id}/${row._original.id}`
                                }} style={{ color: '#5D78FF', fontSize: 16, margin: '0px 10px' }} type="edit" />
                                <Popconfirm
                                    title="Are you sure you want to delete this Addon?"
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={() => deleteBookingAddon(row._index)}
                                >
                                    <Icon style={{ color: '#5D78FF', fontSize: 16 }} type="delete" />
                                </Popconfirm>

                            </>
                            {/* } */}
                            <Popconfirm
                                title={`Are you sure you want to ${row.is_active ? `DEACTIVATE` : `ACTIVATE`} this code`}
                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                onConfirm={() => updateBookingAddonActivation(row._index, !row.is_active)}
                            >
                                <Switch style={{ backgroundColor: row.is_active ? '#5d78ff' : '#fd397a', margin: '0px 10px' }} checked={row.is_active} />
                            </Popconfirm>


                        </div>
                    ),
                    filterable: false
                },
            ]
            }
            className="-striped -highlight"
        />
    )
}