/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Select, Icon, Spin, Popconfirm, Input } from "antd";
import { errorNotification, errorToaster, successNotification } from "../Shared/Notifications";
import MerchantModel from "../../Models/MerchantModel";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import CategoryModel from "../../Models/CategoryModel";

const { Option } = Select;

export default function CategoryMerchantOutlet({ category_id }) {


    const
        [loading, setLoading] = useState(false),
        [isNew, setIsNew] = useState(true),
        [categoryAssociationId, setCategoryAssociationId] = useState(undefined),
        [categoryMerchantOutletData, setCategoryMerchantOutletData] = useState([]),
        [merchantListing, setMerchantListing] = useState([]),
        [merchantId, setMerchantId] = useState(undefined),
        [outletListing, setOutletListing] = useState([]),
        [outletId, setOutletId] = useState(undefined),
        [buyone_getone_offer_count, setBuyone_getone_offer_count] = useState(null),
        [delivery_offer_count, setDelivery_offer_count] = useState(null),
        [online_deal_count, setOnline_deal_count] = useState(null),
        [booking_count, setBooking_count] = useState(null),
        [filters, setFilters] = useState([]),
        [pageCount] = useState(20),
        [total_pages, setTotal_page] = useState(1),
        [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        searchQuery();
    }, [filters]);


    function getAllAssociation() {
        let params = {
            category_id,
            offset: (currentPage - 1) * pageCount,
            limit: pageCount
        };
        filters.forEach((filter, i) => {
            params[filter.id] = filter.value;
        });
        setLoading(true);
        CategoryModel.getInstance().getAllCategoryAssocation(params,
            function success(data) {
                setCategoryMerchantOutletData(CategoryModel.CategoryMerchantOutletListing)
                setLoading(false);
                createPagination(data.count)
            },
            function Failure(error) {
                setLoading(false);
                errorToaster("Request information not found")

            }
        )
    }

    const createPagination = (total_count) => {
        setTotal_page(Math.ceil(total_count / pageCount));
    }

    const debounce = (callback, wait) => {
        let timeout;
        return (...args) => {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => callback.apply(context, args), wait);
        };
    }
    const searchQuery = debounce(function () {
        getAllAssociation();
    }, 1000);


    const searchQueryMerchant = debounce(function (value) {
        getMerchants(value);
    }, 1000);

    function getMerchants(value) {
        if (value.length > 0) {
            let params = {
                query: encodeURIComponent(value)
            }
            setLoading(true);
            MerchantModel.getInstance().getMerchant(params,
                function success(data) {
                    setLoading(false);
                    setMerchantListing(data.data);
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false);
                }
            )
        }

    }

    function getOutlets(merchant) {
        setLoading(true);
        MerchantModel.getInstance().getMerchantOutlet(merchant,
            function success(data) {
                setLoading(false);
                setOutletListing(data)
            },
            function failure(error) {
                setLoading(false);
                errorNotification(error)
                console.log(error)
            }
        )
    }

    function addNewCategoryAssociation() {
        if (!merchantId) {
            errorToaster("Please select Merchant");
            return
        }
        const params = {
            category_id,
            merchant_id: merchantId,
            outlet_id: outletId,
            buyone_getone_offer_count,
            delivery_offer_count,
            online_deal_count,
            booking_count
        };
        setLoading(true);
        CategoryModel.getInstance().addCategoryAssocation(params,
            function success() {
                discardData();
                successNotification("Category Association Saved");
                setLoading(false);
                refreshData();
            },
            function failure(error) {
                errorNotification(error)
                setLoading(false);
            },
        )
    }

    function updateCategoryAssociation() {
        if (!merchantId) {
            errorToaster("Please select Merchant");
            return
        }
        const params = {
            merchant_id: merchantId,
            outlet_id: outletId,
            buyone_getone_offer_count,
            delivery_offer_count,
            online_deal_count,
            booking_count
        };
        setLoading(true);
        CategoryModel.getInstance().updateCategoryAssocation(categoryAssociationId, params,
            function success() {
                discardData();
                successNotification("Category Association Saved");
                setLoading(false);
                refreshData();
            },
            function failure(error) {
                errorNotification(error)
                setLoading(false);
            },
        )
    }

    function removeCategoryAssociation(id) {
        setLoading(true);
        CategoryModel.getInstance().removeCategoryAssocation(id,
            function success() {
                successNotification("Category Association Removed");
                setLoading(false);
                setCategoryMerchantOutletData(CategoryModel.CategoryMerchantOutletListing)
            },
            function failure(error) {
                errorNotification(error)
                setLoading(false);
            },
        )
    }

    function manipulateData(data) {
        setIsNew(false)
        setCategoryAssociationId(data.id)
        setOutletId(data.outlet_id)
        setMerchantId(data.merchant_id)
        setBuyone_getone_offer_count(data.buyone_getone_offer_count)
        setDelivery_offer_count(data.delivery_offer_count)
        setOnline_deal_count(data.online_deal_count)
        setBooking_count(data.booking_count)

        setMerchantListing(data.merchant ? [data.merchant] : [])
        setOutletListing(data.outlet ? [data.outlet] : [])
    }

    function discardData() {
        setIsNew(true)
        setCategoryAssociationId(undefined)
        setOutletId(undefined)
        setMerchantId(undefined)
        setBuyone_getone_offer_count(null)
        setDelivery_offer_count(null)
        setOnline_deal_count(null)
        setBooking_count(null)

        setMerchantListing([])
        setOutletListing([])
    }

    function refreshData() {
        setCurrentPage(1);
        getAllAssociation()
    }



    const CategoryAssociationTable = () => {
        return (
            <ReactTable
                data={categoryMerchantOutletData}
                filterable
                pageSize={pageCount}
                showPageSizeOptions={false}
                manual
                page={currentPage - 1}
                pages={total_pages}
                filtered={filters}
                onPageChange={(pageIndex) => {
                    setCurrentPage(pageIndex + 1)
                    setTimeout(() => { getAllAssociation() }, 50);
                }}
                onFilteredChange={(_filters) => {
                    setCurrentPage(1);
                    setFilters(_filters)
                    console.log("_filters: ", _filters)
                    // searchQuery(_filters)
                }}
                defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value}
                columns={[
                    {
                        Header: <p style={{ fontWeight: "500", borderBottomWidth: "1px", padding: "10px 0px", fontSize: 12 }} >Merchant</p>,
                        id: "$merchant.name$",
                        accessor: d => d.merchant?.name,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["outlets"] }),
                        filterAll: true,
                        filterable: true
                    },
                    {
                        Header: <p style={{ fontWeight: "500", borderBottomWidth: "1px", padding: "10px 0px", fontSize: 12 }} >Outlet</p>,
                        id: "$outlet.name$",
                        accessor: d => d.outlet?.name,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["outlets"] }),
                        filterAll: true,
                        filterable: true
                    },
                    {
                        Header: <p style={{ fontWeight: "500", borderBottomWidth: "1px", padding: "10px 0px", fontSize: 12 }} >Action</p>,
                        id: "actions",
                        width: 100,
                        Cell: ({ row }) => (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                <Icon onClick={() => {
                                    window.scrollTo(0, 0);
                                    manipulateData(row._original)
                                }} style={{ color: "#5D78FF", fontSize: 20 }} type="edit" />
                                <Popconfirm
                                    title="Are you sure you want to delete this association with category?"
                                    icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
                                    onConfirm={() => removeCategoryAssociation(row._original.id)}
                                >
                                    <Icon style={{ color: "#5D78FF", fontSize: 20 }} type="delete" />
                                </Popconfirm>
                            </div>
                        ),
                        filterable: false
                    },
                    {
                        Header: <p style={{ fontWeight: "500", borderBottomWidth: "1px", padding: "10px 0px", fontSize: 12 }} >B1G1 Offer</p>,
                        id: "buyone_getone_offer_count",
                        accessor: d => d.buyone_getone_offer_count,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["buyone_getone_offer_count"] }),
                        filterAll: true,
                        filterable: false
                    },
                    {
                        Header: <p style={{ fontWeight: "500", borderBottomWidth: "1px", padding: "10px 0px", fontSize: 12 }} >Delivery Offer</p>,
                        id: "delivery_offer_count",
                        accessor: d => d.delivery_offer_count,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["delivery_offer_count"] }),
                        filterAll: true,
                        filterable: false
                    },
                    {
                        Header: <p style={{ fontWeight: "500", borderBottomWidth: "1px", padding: "10px 0px", fontSize: 12 }} >Online Deal</p>,
                        id: "online_deal_count",
                        accessor: d => d.online_deal_count,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["online_deal_count"] }),
                        filterAll: true,
                        filterable: false
                    },
                    {
                        Header: <p style={{ fontWeight: "500", borderBottomWidth: "1px", padding: "10px 0px", fontSize: 12 }} >Booking</p>,
                        id: "booking_count",
                        accessor: d => d.booking_count,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["booking_count"] }),
                        filterAll: true,
                        filterable: false
                    },
                ]
                }
                className="-striped -highlight"
            />
        )
    }

    return (
        <div>
            <Spin spinning={loading}>
                <div className="form-group row">
                    <label className="col-2 col-form-label">Merchant <b>*</b></label>
                    <div className="col-10">
                        <Select
                            showSearch
                            style={{ margin: "10px 0px 10px 0px" }}
                            onChange={(value) => {
                                setMerchantId(value)
                                getOutlets(value)
                            }}
                            value={merchantId}
                            onSearch={(val) => searchQueryMerchant(val)}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {merchantListing.map((merchant) => (
                                <Option key={merchant.id} value={merchant.id}>{`${merchant.name} ${merchant.is_active ? " (active)" : " (in-active)"}`}</Option>
                            ))}
                        </Select>
                        <span className="form-text text-muted">Please select Merchant</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-2 col-form-label">Outlet</label>
                    <div className="col-10">
                        <Select
                            showSearch
                            style={{ margin: "10px 0px 10px 0px" }}
                            onChange={(value) => setOutletId(value)}
                            value={outletId}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {outletListing.map((outlet) => (
                                <Option key={outlet.id} value={outlet.id}>{`${outlet.name} ${outlet.is_active ? " (active)" : " (in-active)"}`}</Option>
                            ))}
                        </Select>
                        <span className="form-text text-muted">Please select Outlets</span>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-2 col-form-label">Buy 1 Get 1 Offer count <b>*</b></label>
                    <div className="col-10">
                        <Input maxLength={70} className="form-control" type="number" value={buyone_getone_offer_count}
                            onChange={(event) => setBuyone_getone_offer_count(event.target.value)} />
                        <span className="form-text text-muted">Please select Merchant</span>
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label">Delivery Offer count <b>*</b></label>
                    <div className="col-10">
                        <Input maxLength={70} className="form-control" type="number" value={delivery_offer_count}
                            onChange={(event) => setDelivery_offer_count(event.target.value)} />
                        <span className="form-text text-muted">Please select Merchant</span>
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label">Online Deal count <b>*</b></label>
                    <div className="col-10">
                        <Input maxLength={70} className="form-control" type="number" value={online_deal_count}
                            onChange={(event) => setOnline_deal_count(event.target.value)} />
                        <span className="form-text text-muted">Please select Merchant</span>
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label">Booking count <b>*</b></label>
                    <div className="col-10">
                        <Input maxLength={70} className="form-control" type="number" value={booking_count}
                            onChange={(event) => setBooking_count(event.target.value)} />
                        <span className="form-text text-muted">Please select Merchant</span>
                    </div>
                </div>
                {
                    isNew ?
                        <button style={{ marginBottom: "10px" }} onClick={() => addNewCategoryAssociation()} type="button" className="btn btn-brand">
                            <i className="la la-check"></i>
                            <span className="kt-hidden-mobile">Save</span>
                        </button> :
                        <div>
                            <button style={{ marginBottom: "10px" }} onClick={() => updateCategoryAssociation()} type="button" className="btn btn-brand">
                                <i className="la la-check"></i>
                                <span className="kt-hidden-mobile">Update</span>
                            </button>
                            <button style={{ marginBottom: "10px", marginLeft: "10px" }} onClick={() => discardData()} type="button" className="btn btn-brand">
                                <i className="la la-check"></i>
                                <span className="kt-hidden-mobile">Discard</span>
                            </button>

                        </div>
                }

                {CategoryAssociationTable()}
            </Spin>
        </div>

    )


}