import React, { useEffect, useState } from 'react';

import {
    TimePicker,
    Input,
    Spin,
    DatePicker,
    Select,
    Icon,
    Popconfirm,
    Switch
} from 'antd';

import {
    successNotification,
    errorNotification,
    errorToaster
} from '../Shared/Notifications';

import moment from 'moment';

import matchSorter from 'match-sorter';

import SharedModel from '../../Models/SharedModel';

import DataJson from './data.json'


const { Option } = Select;

let counter = 0;


export default function EditAppVisibility(props) {
    const
        [loading, setLoading] = useState(false),
        [name, setName] = useState(''),
        [description, setDescription] = useState(''),
        [validFrom, setValidFrom] = useState(moment()),
        [validTill, setValidTill] = useState(moment().add(1, 'M')),
        [isDefault, setIsDefault] = useState(false),
        [configList, setConfigList] = useState([]),
        [deletedConfigList, setDeletedConfigList] = useState([]);

    useEffect(() => {
        getVisibilityDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getVisibilityDetail = () => {
        const { match: { params } } = props;
        setLoading(true);
        SharedModel.getInstance().getProductVisibilityDetail(params.id,
            function success(data) {
                setName(data.name);
                setDescription(data.description);
                setValidFrom(moment(data.valid_from));
                setValidTill(moment(data.valid_till));
                getProductVisibilityConfig(data.id);
                setIsDefault(data.is_default);

            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    const getProductVisibilityConfig = (id) => {
        SharedModel.getInstance().getProductVisibilityConfigs(id,
            function success(data) {
                setConfigList(data.records.map(conf => {
                    return {
                        id: conf.id,
                        day: conf.day,
                        start_time: moment(conf.start_time, 'HH:mm:ss').format('HH:mm'),
                        end_time: moment(conf.end_time, 'HH:mm:ss').format('HH:mm'),
                        type: 'old'

                    }
                }))
                setLoading(false);

            },
            function failure(err) {
                console.log(err);

                errorNotification(JSON.stringify(err));
                setLoading(false)
            }

        )
    }

    const updateProductVisibility = () => {
        if (name.length < 1) {
            errorToaster("Please Enter Visibility Name")
            return
        }

        if (description.length < 1) {
            errorToaster("Please Enter Description")
            return
        }

        if (!validFrom) {
            errorToaster("Please choose start date for visibility")
            return
        }

        if (!validTill) {
            errorToaster("Please choose end date for visibility")
            return
        }
        let breakLoop = false;
        configList.forEach(conf => {
            if (!conf.start_time || !conf.end_time) {
                breakLoop = true
            }
        });
        if (breakLoop) {
            errorToaster("Please enter correct visiblity config")
            return
        }
        const _params = {
            name,
            description,
            valid_from: validFrom.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            valid_till: validTill.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            is_default: isDefault,
        };
        const newList = matchSorter(configList, 'new', { keys: ['type'] });
        const updatedList = matchSorter(configList, 'old', { keys: ['type'] });
        setLoading(true)
        const { match: { params } } = props;
        SharedModel.getInstance().updateProductVisibility(params.id, _params,
            function success() {
                if (deletedConfigList.length > 0) deleteConfigs(params.id, newList, updatedList);
                else if (newList.length > 0) addConfigs(params.id, newList, updatedList);
                else if (updatedList.length > 0) updateConfigs(updatedList);
                else {
                    successNotification('Product Visibility Updated Successfully');
                    setTimeout(() => {
                        setLoading(false);
                        window.location.href = '/product-visibility'
                    }, 2000);
                }
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    // Create a function that will create bulk configs

    const addConfigs = (id, newList, updatedList) => {
        const configs = newList.map(conf => {
            return { day: conf.day, start_time: conf.start_time, end_time: conf.end_time, product_visibility_id: id }
        })
        SharedModel.getInstance().addProductVisibilityConfig(configs,
            function success(data) {
                if (updatedList.length > 0) updateConfigs(updatedList);
                else {
                    successNotification('Product Visibility Updated Successfully');
                    setTimeout(() => {
                        setLoading(false);
                        window.location.href = '/product-visibility'
                    }, 2000);
                }
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    const updateConfigs = (updatedList) => {
        setLoading(true);
        if (counter < updatedList.length) {
            SharedModel.getInstance().updateProductVisibilityConfig(
                updatedList[counter].id,
                updatedList[counter],
                function success() {
                    counter++;
                    setTimeout(() => {
                        updateConfigs(updatedList);
                    }, 100);
                },
                function failure() {
                    counter++;
                    setTimeout(() => {
                        updateConfigs(updatedList);
                    }, 100);
                }
            )
        } else {
            successNotification('Product Visibility Updated Successfully');
            setTimeout(() => {
                setLoading(false);
                window.location.href = '/product-visibility'
            }, 2000);
        }

    }



    const deleteConfigs = (id, newList, updatedList) => {
        const params = {
            ids: deletedConfigList.map(conf => {
                return conf
            })
        }
        setLoading(true)
        SharedModel.getInstance().removeProductVisibilityConfig(params,
            function success() {
                if (newList.length > 0) addConfigs(id, newList, updatedList);
                else if (updatedList.length > 0) updateConfigs(updatedList);
                else {
                    successNotification('Product Visibility Updated Successfully');
                    setTimeout(() => {
                        setLoading(false);
                        window.location.href = '/product-visibility'
                    }, 2000);
                }
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }



    const disablePreviousDate = (current) => {
        return current < moment().startOf('day');
    }

    const addConfig = () => {
        let list = [...configList]
        list.push({ ...DataJson.editConfigObject });
        setConfigList(list);
    }

    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                <Spin spinning={loading} >

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                <div className="kt-portlet__head kt-portlet__head--lg">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">Edit Product Visibility</h3>
                                    </div>
                                    <div className="kt-portlet__head-toolbar">
                                        <a href="/product-visibility" className="btn btn-clean kt-margin-r-10">
                                            <i className="la la-arrow-left"></i>
                                            <span className="kt-hidden-mobile">Back</span>
                                        </a>
                                        <div className="btn-group">
                                            <button onClick={() => updateProductVisibility()} type="button" className="btn btn-brand">
                                                <i className="la la-check"></i>
                                                <span className="kt-hidden-mobile">Save</span>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                                <div className="kt-portlet__body">
                                    <form className="kt-form" id="kt_form">
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-8">
                                                <div className="kt-section kt-section--first">
                                                    <div className="kt-section__body">

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Name <b>*</b></label>
                                                            <div className="col-9">
                                                                <Input maxLength={100} value={name} onChange={(event) => setName(event.target.value)} className="form-control" type="text" />
                                                                <span className="form-text text-muted"><p style={{}}>Please Enter Product Visibility Name</p></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Description <b>*</b></label>
                                                            <div className="col-9">
                                                                <textarea maxLength={255} value={description} onChange={(event) => setDescription(event.target.value)} className="form-control" type="text" />
                                                                <span className="form-text text-muted"><p style={{}}>Please Enter Product Visibility description</p></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Make Default</label>
                                                            <div className="col-9">
                                                                <Switch checked={isDefault} checkedChildren="Yes" unCheckedChildren="No" onChange={(checked) => setIsDefault(checked)} />
                                                                <span className="form-text text-muted"><p style={{}}>Please Check either Visibilitiy is Default or Not</p></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Start Date <b>*</b></label>
                                                            <div className="col-9">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    format="DD-MMM-YYYY"
                                                                    disabledDate={disablePreviousDate}
                                                                    value={validFrom}
                                                                    style={{ padding: "0px", border: '0px solid black' }}
                                                                    allowClear={false}
                                                                    onChange={(value) => setValidFrom(value)} />
                                                                <span className="form-text text-muted"><p style={{}}>Please Select start date</p></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">End Date <b>*</b></label>
                                                            <div className="col-9">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    format="DD-MMM-YYYY"
                                                                    disabledDate={disablePreviousDate}
                                                                    value={validTill}
                                                                    style={{ padding: "0px", border: '0px solid black' }}
                                                                    allowClear={false}
                                                                    onChange={(value) => setValidTill(value)} />
                                                                <span className="form-text text-muted"><p style={{}}>Please Select end date</p></span>
                                                            </div>
                                                        </div>


                                                        {
                                                            configList.map((config, i) => (
                                                                <div key={i} className="form-group row">
                                                                    <label className="col-3 col-form-label">Config # {i + 1} </label>
                                                                    <div className="col-3">
                                                                        <TimePicker
                                                                            id="1"
                                                                            minuteStep={1}
                                                                            className="form-control"
                                                                            format="HH:mm"
                                                                            value={moment(config.start_time, 'HH:mm')}
                                                                            style={{ padding: "0px", border: '0px solid black' }}
                                                                            allowClear={false}
                                                                            onChange={(value) => {
                                                                                let val = [...configList];
                                                                                val[i].start_time = moment(value).format('HH:mm');
                                                                                setConfigList(val);
                                                                            }} />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Select start time</p></span>
                                                                    </div>

                                                                    <div className="col-3">
                                                                        <TimePicker
                                                                            id="2"
                                                                            minuteStep={1}
                                                                            className="form-control"
                                                                            format="HH:mm"
                                                                            value={moment(config.end_time, 'HH:mm')}
                                                                            style={{ padding: "0px", border: '0px solid black' }}
                                                                            allowClear={false}
                                                                            onChange={(value) => {
                                                                                let val = [...configList];
                                                                                val[i].end_time = moment(value).format('HH:mm');
                                                                                setConfigList(val);
                                                                            }} />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Select end time</p></span>
                                                                    </div>

                                                                    <div className="col-2">
                                                                        <Select
                                                                            showSearch
                                                                            value={config.day}
                                                                            onChange={(day) => {
                                                                                let val = [...configList];
                                                                                val[i].day = day;
                                                                                setConfigList(val);
                                                                            }}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {DataJson.dayArray.map((day) => (
                                                                                <Option key={day.value} value={day.value} >{day.name}</Option>
                                                                            ))}
                                                                        </Select>

                                                                        <span className="form-text text-muted"><p style={{}}>Please Select day</p></span>
                                                                    </div>
                                                                    <Popconfirm
                                                                        title="Are you sure you want to delete this Config? This Action is ir-reversable!"
                                                                        icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                                        onConfirm={() => {
                                                                            let val = [...configList];
                                                                            val.splice(i, 1)
                                                                            setConfigList(val);
                                                                            let delVal = [...deletedConfigList];
                                                                            delVal.push(config.id)
                                                                            setDeletedConfigList(delVal);
                                                                        }}
                                                                    >
                                                                        <Icon style={{ fontSize: 20, paddingTop: 5, paddingLeft: 5 }} type="delete" />
                                                                    </Popconfirm>
                                                                </div>
                                                            ))
                                                        }



                                                        <div className="form-group row">
                                                            {/* <label className="col-3 col-form-label">End Date <b>*</b></label> */}
                                                            <div className="col-9">
                                                                <div className="btn-group">
                                                                    <button onClick={() => addConfig()} type="button" className="btn btn-brand">
                                                                        <i className="la la-plus"></i>
                                                                        <span className="kt-hidden-mobile">Add Config</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div >

    )
}