import React from "react";
import {
  Select,
  // Spin,
  DatePicker,
  Icon,
} from "antd";

import _ from "lodash";

// import UserModel from '../../Models/UserModel';
import CouponModel from "../../Models/CouponModel";
import PartnerModel from "../../Models/PartnerModel";

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class EditCoupon extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      couponId: null,
      name: "",
      nameError: false,
      description: "",
      descriptionError: false,
      prefix: "",
      prefixError: false,
      type: null,
      typeError: false,
      discountedValue: "",
      discountedValueError: false,
      number_of_coupons: "1",
      number_of_couponsError: false,
      disableOption: false,
      typeList: [
        {
          name: "Coupon",
          value: 1,
        },
        {
          name: "VIP",
          value: 2,
        },
        {
          name: "BIN",
          value: 3,
        },
        {
          name: "BIN + Coupon",
          value: 4,
        },
      ],
      startDate: moment(),
      endDate: moment(),
      dateError: false,
      partnerList: [],
      partner: null,
      partnerError: false,
      programList: [],
      program: null,
      programError: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    let it = this;
    it.setState({
      loading: true,
      couponId: params.couponId,
    });

    CouponModel.getInstance().getCouponDetail(
      params.couponId,
      function success(data) {
        console.log(data);
        it.setState({
          id: data.id,
          name: data.name,
          description: data.description,
          partner: data.partner_name,
          program: data.program_name,
          type: data.type,
          discountedValue: data.discount,
          prefix: data.prefix,
          codes: data.codes.map((codes) => {
            return codes.code;
          }),
          startDate: data.start_date,
          endDate: data.end_date,
        });
      },
      function failure(error) {
        console.log(error);
      }
    );
  }

  getPartnerPrograms(partner) {
    let it = this;
    it.setState({
      loading: true,
      partner,
      programList: [],
      program: null,
    });
    PartnerModel.getInstance().getPartnerDetail(
      partner,
      function success(data) {
        it.setState({
          loading: false,
          programList: data.programs,
        });
        console.log(data);
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  setMasterCard(program) {
    this.setState({ program });
    for (let i = 0; i < this.state.programList.length; i++) {
      if (this.state.programList[i].id === program) {
        if (this.state.programList[i].is_mastercard === true) {
          this.setState({ type: 3, disableOption: true });
        } else {
          this.setState({ disableOption: false });
        }
      }
    }
  }

  sendData() {
    const { id, discountedValue, type, startDate, endDate } = this.state;
    const { history } = this.props;
    let it = this;
    let params = {};
    const text = "Validation Error! Please check fields";
    this.setState({
      dateError: false,
      discountedValueError: false,
    });

    params.type = type;

    switch (type) {
      case 1:
        if (discountedValue.length < 1) {
          this.setState({ discountedValueError: true });
          errorToaster(text);
          return;
        }
        params.discount = discountedValue;

      case 4:
        if (discountedValue.length < 1) {
          this.setState({ discountedValueError: true });
          errorToaster(text);
          return;
        }
        params.discount = discountedValue;
    }

    if (startDate.length < 1 || endDate.length < 1) {
      this.setState({ dateError: true });
      errorToaster(text);
      return;
    }
    params.start_date = startDate;
    params.end_date = endDate;

    this.setState({ loading: true });
    CouponModel.getInstance().updateCoupon(
      id,
      params,
      function success(data) {
        successNotification("Coupon Updated Successfully");
        setTimeout(() => {
          window.location.href = "/coupon";
        }, 1000);
      },
      function failure(error) {
        errorNotification(error);
        console.log(error);
        it.setState({ loading: false });
      }
    );
  }

  searchPartners(name) {
    let it = this;
    it.setState({
      loading: true,
    });
    const params = {
      "partners.name": name,
    };
    PartnerModel.getInstance().searchPartner(
      params,
      function success() {
        it.setState({
          loading: false,
          partnerList: PartnerModel.getInstance().PartnerList,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQueryPartner = this.debounce(function (value, it) {
    it.searchPartners(value);
  }, 1000);

  render() {
    const {
      nameError,
      descriptionError,
      name,
      programList,
      prefixError,
      typeList,
      description,
      codesError,
      startDate,
      endDate,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                <div className="kt-portlet__head kt-portlet__head--lg">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Edit Coupon</h3>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                    <a href="/coupon" className="btn btn-clean kt-margin-r-10">
                      <i className="la la-arrow-left"></i>
                      <span className="kt-hidden-mobile">Back</span>
                    </a>
                    <div className="btn-group">
                      <button
                        onClick={() => this.sendData()}
                        type="button"
                        className="btn btn-brand"
                      >
                        <i className="la la-check"></i>
                        <span className="kt-hidden-mobile">Save</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* end header */}
                <div className="kt-portlet__body">
                  <form className="kt-form" id="kt_form">
                    <div className="row">
                      <div className="col-xl-2"></div>
                      <div className="col-xl-8">
                        <div className="kt-section kt-section--first">
                          <div className="kt-section__body">
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Name <b>*</b>
                              </label>
                              <div className="col-9">
                                <input
                                  disabled
                                  maxLength="70"
                                  value={name}
                                  onChange={(event) =>
                                    this.setState({ name: event.target.value })
                                  }
                                  className="form-control"
                                  type="text"
                                />
                                <span
                                  style={{ color: nameError ? "red" : "" }}
                                  className="form-text text-muted"
                                >
                                  <p style={{ color: nameError ? "red" : "" }}>
                                    Please enter name in English
                                  </p>
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Description <b>*</b>
                              </label>
                              <div className="col-9">
                                <textarea
                                  disabled
                                  maxLength="200"
                                  value={description}
                                  onChange={(event) =>
                                    this.setState({
                                      description: event.target.value,
                                    })
                                  }
                                  className="form-control"
                                  type="text"
                                ></textarea>
                                <span className="form-text text-muted">
                                  <p
                                    style={{
                                      color: descriptionError ? "red" : "",
                                    }}
                                  >
                                    Please enter name in Arabic
                                  </p>
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                className="col-3 col-form-label"
                                htmlFor="marchantGroup"
                              >
                                Partner <b>*</b>
                              </label>
                              <div className="col-9">
                                <Select
                                  showSearch
                                  style={{ margin: "10px 0px 10px 0px" }}
                                  // placeholder="Select a merchant group"
                                  value={this.state.partner}
                                  onChange={(partner) =>
                                    this.getPartnerPrograms(partner)
                                  }
                                  notFoundContent={
                                    // <Tooltip title="Add Type">
                                    //     <Icon onClick={() => this.appendType()} disabled={false} type="plus-circle" theme="filled" style={{ color: '#3758ff', fontSize: '20px' }} />
                                    // </Tooltip>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Icon
                                        style={{
                                          fontSize: 16,
                                          marginRight: "10px",
                                          marginBottom: "5px",
                                        }}
                                        type="search"
                                      />
                                      <p style={{ fontSize: 14 }}>
                                        Please Search Merchant by Name
                                      </p>
                                    </div>
                                  }
                                  onSearch={(val) =>
                                    this.searchQueryPartner(val, this)
                                  }
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.partnerList.map((partner) => (
                                    <Option key={partner.id} value={partner.id}>
                                      {partner.name}
                                    </Option>
                                  ))}
                                </Select>
                                <span className="form-text text-muted">
                                  {" "}
                                  <p
                                    style={{
                                      color: this.state.partnerError
                                        ? "red"
                                        : "",
                                    }}
                                  >
                                    {" "}
                                    Please choose Partner
                                  </p>
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                className="col-3 col-form-label"
                                htmlFor="marchantGroup"
                              >
                                Program <b>*</b>
                              </label>
                              <div className="col-9">
                                <Select
                                  showSearch
                                  style={{ margin: "10px 0px 10px 0px" }}
                                  disabled
                                  // placeholder="Select a merchant group"
                                  value={this.state.program}
                                  onChange={(program) =>
                                    this.setMasterCard(program)
                                  }
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {programList.map((program) => (
                                    <Option key={program.id} value={program.id}>
                                      {program.name}
                                    </Option>
                                  ))}
                                </Select>
                                <span className="form-text text-muted">
                                  <p
                                    style={{
                                      color: this.state.programError
                                        ? "red"
                                        : "",
                                    }}
                                  >
                                    Please choose Program
                                  </p>
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                className="col-3 col-form-label"
                                htmlFor="marchantGroup"
                              >
                                Type <b>*</b>
                              </label>
                              <div className="col-9">
                                <Select
                                  showSearch
                                  disabled
                                  style={{ margin: "10px 0px 10px 0px" }}
                                  value={this.state.type}
                                  // placeholder="Select a merchant group"
                                  onChange={(type) => {
                                    this.setState({ type });
                                  }}
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {typeList.map((type) => (
                                    <Option key={type.value} value={type.value}>
                                      {type.name}
                                    </Option>
                                  ))}
                                </Select>
                                <span className="form-text text-muted">
                                  Please choose Type
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Discount Value <b>*</b>
                              </label>
                              <div className="col-9">
                                <div className="input-group mb-3">
                                  <input
                                    value={this.state.discountedValue}
                                    disabled={
                                      this.state.type === 1 ||
                                      this.state.type === 4
                                        ? false
                                        : true
                                    }
                                    maxLength="20"
                                    onChange={(event) =>
                                      this.setState({
                                        discountedValue: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon2"
                                    >
                                      %
                                    </span>
                                  </div>
                                </div>
                                <span className="form-text text-muted">
                                  <p
                                    style={{
                                      color: this.state.discountedValueError
                                        ? "red"
                                        : "",
                                    }}
                                  >
                                    Please enter discount value
                                  </p>
                                </span>
                              </div>
                            </div>

                            {this.state.type === 3 ||
                            this.state.type === 4 ? null : (
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Prefix <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    disabled
                                    maxLength="3"
                                    value={this.state.prefix}
                                    className="form-control"
                                    type="text"
                                    onChange={(event) =>
                                      this.setState({
                                        prefix:
                                          event.target.value.toUpperCase(),
                                        codes: [],
                                        number_of_coupons: 1,
                                        fileName: null,
                                        fileCode: [],
                                      })
                                    }
                                  />
                                  {/* <input maxLength="3" disabled={this.state.fileName ? false : Number(this.state.number_of_coupons) > 1 ? false : true} value={this.state.prefix} className="form-control" type="text" onChange={(event) => this.setState({ prefix: event.target.value.toUpperCase(), codes: '' })} /> */}
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: prefixError ? "red" : "",
                                      }}
                                    >
                                      Please Enter Prefix
                                    </p>
                                  </span>
                                </div>
                              </div>
                            )}

                            {Number(this.state.number_of_coupons) > 1 &&
                            this.state.prefix.length === 3 ? (
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Codes <b>*</b>
                                </label>
                                <div className="col-9">
                                  <textarea
                                    disabled
                                    value={this.state.codes}
                                    className="form-control"
                                    type="text"
                                    onChange={(event) =>
                                      this.setState({
                                        codes: event.target.value,
                                      })
                                    }
                                  ></textarea>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: codesError ? "red" : "" }}
                                    >
                                      Please Enter Codes Must be comma separated
                                      Value
                                    </p>
                                  </span>
                                </div>
                              </div>
                            ) : this.state.type !== 4 ? (
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Code <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="4"
                                    disabled
                                    value={this.state.codes}
                                    className="form-control"
                                    type="text"
                                    onChange={(event) =>
                                      this.setState({
                                        codes: event.target.value.toUpperCase(),
                                      })
                                    }
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: codesError ? "red" : "" }}
                                    >
                                      Please Enter Code (Must be 4 digit
                                      AlphaNumeric Value)
                                    </p>
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Code <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="7"
                                    disabled
                                    value={this.state.codes}
                                    className="form-control"
                                    type="text"
                                    onChange={(event) =>
                                      this.setState({
                                        codes: event.target.value.toUpperCase(),
                                      })
                                    }
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: codesError ? "red" : "" }}
                                    >
                                      Please Enter Code (Must be 7 digit
                                      AlphaNumeric Value)
                                    </p>
                                  </span>
                                </div>
                              </div>
                            )}

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Start Date ~ End Date <b>*</b>
                              </label>
                              <div className="col-9">
                                <RangePicker
                                  className="form-control"
                                  style={{
                                    padding: "0px",
                                    border: "0px solid black",
                                  }}
                                  showTime={false}
                                  value={[moment(startDate), moment(endDate)]}
                                  onChange={(value, string) =>
                                    this.setState({
                                      startDate: string[0],
                                      endDate: string[1],
                                    })
                                  }
                                  format="YYYY-MM-DD"
                                />
                                <span className="form-text text-muted">
                                  <p
                                    style={{
                                      color: this.state.dateError ? "red" : "",
                                    }}
                                  >
                                    Please enter Start and End Date
                                  </p>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2"></div>
                    </div>
                  </form>
                </div>
                {/* end body */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
