/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import {
    Icon,
    Popconfirm,
    Switch,
    Select,
    Modal,
    Input,
    DatePicker,
} from 'antd';
import _ from 'lodash'

import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import FileUploader from './Uploader'

import moment from "moment";
import { errorNotification, errorToaster, infoToaster } from 'Containers/Shared/Notifications';

const { Option } = Select;
const { RangePicker } = DatePicker
const { TextArea } = Input;

export default function CodeTab({ type, codeData = [], currentData, addNewCodeObject, setDataValues, saveObject, saveUploaderObject, updateObject, cancelObject, updateCodeConfigActivation, deleteCodeConfig, setCurrentCodeConfig, getVouchers, total_count }) {

    const
        [addModalVisible, setAddModalVisible] = useState(false),
        [is_uploader, setIs_uploader] = useState(false),
        [is_uploaded, setIs_uploaded] = useState(false),
        [editType, setEditType] = useState('new'),
        [filters, setFilters] = useState([]),
        [pageCount] = useState(20),
        [total_pages, setTotalPages] = useState(1),
        [currentPage, setCurrentPage] = useState(1),
        [currentIndex, setCurrentIndex] = useState(null);

    useEffect(() => {
        getAllProductVouchers();
    }, []);

    useEffect(() => {
        createPagination();
    }, [codeData]);


    function createPagination() {
        if (total_count) setTotalPages(Math.ceil(total_count / pageCount))
        else setTotalPages(Math.ceil(codeData.length / pageCount))
    }

    const getSearchProductVouchers = useCallback(_.debounce(() => getAllProductVouchers(), 500), []);
    function getAllProductVouchers() {
        let params = {
            offset: (currentPage - 1) * pageCount,
            limit: pageCount,
        };
        filters.forEach((filter, i) => {
            params[filter.id] = filter.value;
        });
        if (params["app_id"] === "all") {
            delete params["app_id"];
        }
        switch (params["is_active"]) {
            case "all":
                delete params["is_active"];
                break;

            case "true":
                params["is_active"] = true;
                break;

            case "false":
                params["is_active"] = false;
                break;

            default:
                break;
        }
        getVouchers(params);
    }


    function disabledDate(current) {
        return current < moment().startOf('day');
    }

    // uploader Validations
    function getCodes(data) {
        if (data.status) {
            if (data.codes.length < 1) {
                errorNotification('Invalid file Please Upload correct file')
                return
            }
            infoToaster('File Uploaded Successfully')
            setIs_uploaded(true)
            setDataValues('code', data.codes.join(','))
        } else {
            errorToaster('Invalid Code Contain Inside Uploaded File! Please check it manually and Re-Upload them');
        }
    }

    function checkActiveCodeExistInCodeData() {
        return codeData.some(_x => _x.is_active === true);
    }

    return (
        <div>
            <div className="kt-portlet__head kt-portlet__head--lg">
                <div className="kt-portlet__head-label" />
                {
                    type === 2 && checkActiveCodeExistInCodeData() ?
                        null :
                        <div className="kt-portlet__head-toolbar">
                            <div className="btn-group">
                                <button onClick={() => {
                                    setIs_uploader(false)
                                    addNewCodeObject();
                                    setAddModalVisible(true);
                                    setEditType('new')
                                }} type="button" className="btn btn-brand">
                                    <i className="la la-plus"></i>
                                    <span className="kt-hidden-mobile">Add</span>
                                </button>
                            </div>
                            <div style={{ margin: '0px 10px' }} />
                            {
                                type !== 2 &&
                                <div className="btn-group">
                                    <button onClick={() => {
                                        addNewCodeObject();
                                        setAddModalVisible(true);
                                        setEditType('new')
                                        setIs_uploader(true)
                                        setIs_uploaded(false)
                                    }} type="button" className="btn btn-brand">
                                        <i className="la la-angle-up"></i>
                                        <span className="kt-hidden-mobile">Upload</span>
                                    </button>

                                </div>
                            }

                        </div>
                }
            </div>
            {/* body */}

            <Modal
                width="80%"
                title={'Code'}
                visible={addModalVisible}
                onOk={async () => {
                    if (editType === 'new') {
                        if (is_uploader) {
                            const is_valid = await saveUploaderObject();
                            if (is_valid) {
                                setAddModalVisible(false)
                                setIs_uploader(false)
                                setIs_uploaded(false)
                            };
                        }
                        else {
                            const is_valid = await saveObject();
                            if (is_valid) {
                                setAddModalVisible(false)
                                setIs_uploader(false)
                                setIs_uploaded(false)
                            };
                        }
                    } else {
                        const is_valid = await updateObject(currentIndex);
                        if (is_valid) {
                            setAddModalVisible(false)
                            setIs_uploader(false)
                            setIs_uploaded(false)
                        };
                    }
                }}
                onCancel={() => {
                    cancelObject();
                    setIs_uploader(false)
                    setAddModalVisible(false)
                    setIs_uploaded(false)
                }}
            >

                <div className="form-group row">
                    <label className="col-3 col-form-label">Valid From ~ Valid Till <b>*</b></label>
                    <div className="col-9">
                        <RangePicker
                            disabledDate={disabledDate}
                            className="form-control"
                            style={{ padding: "0px", border: '0px solid black', width: '100%' }}
                            showTime={false}
                            value={currentData?.validity_range}
                            onChange={(value) => {
                                setDataValues('validity_range', value);
                            }}
                            format="YYYY-MM-DD"
                        />
                        <span className="form-text text-muted"><p>Please Choose Validity Range</p></span>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Validity</label>
                    <div className="col-9">
                        <Input maxLength={100} value={currentData?.validity} onChange={(event) => setDataValues('validity', event.target.value)} className="form-control" type="number" />
                        <span className="form-text text-muted"><p>Please Enter Validity in Number of days</p></span>
                    </div>
                </div>
                {
                    is_uploader ?
                        <>
                            {
                                !is_uploaded &&
                                <FileUploader
                                    getData={(data) => getCodes(data)}
                                />
                            }

                            <div className="form-group row">
                                <label className="col-3 col-form-label">Code</label>
                                <div className="col-9">
                                    <TextArea style={is_uploaded ? {} : { marginTop: 10 }} rows={4} disabled value={currentData?.code} className="form-control" type="text" />
                                    <span className="form-text text-muted"><p>Please Enter Code</p></span>
                                </div>
                            </div>
                        </>
                        :
                        <div className="form-group row">
                            <label className="col-3 col-form-label">Code <b>*</b></label>
                            <div className="col-9">
                                <Input maxLength={100} value={currentData?.code} onChange={(event) => setDataValues('code', event.target.value)} className="form-control" type="text" />
                                <span className="form-text text-muted"><p>Please Enter Code</p></span>
                            </div>
                        </div>
                }

                <div className="form-group row">
                    <label className="col-3 col-form-label">Max Usage</label>
                    <div className="col-9">
                        <Input maxLength={100} value={currentData?.max_usage} onChange={(event) => setDataValues('max_usage', event.target.value)} className="form-control" type="number" />
                        <span className="form-text text-muted"><p>Please Enter Max Usage of Code</p></span>
                    </div>
                </div>


            </Modal>

            <ReactTable
                data={codeData}
                filterable
                showPageSizeOptions={false}
                defaultPageSize={pageCount}
                manual={total_count ? true : false}
                page={currentPage - 1}
                pages={total_pages}
                filtered={filters}
                onPageChange={(pageIndex) => {
                    setCurrentPage(pageIndex + 1);
                    if (total_count) {
                        setTimeout(() => {
                            getAllProductVouchers();
                        }, 50);
                    }
                }}
                onFilteredChange={(_filters) => {
                    setFilters(_filters);
                    if (total_count) {
                        setCurrentPage(1)
                        getSearchProductVouchers();
                    }
                }}

                defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value}
                columns={[

                    {
                        Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Code</p>,
                        id: "code",
                        accessor: d => d.code,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["code"] }),
                        filterAll: true,
                        filterable: true
                    },
                    {
                        Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Max Usage</p>,
                        id: "max_usage",
                        accessor: d => d.max_usage,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["max_usage"] }),
                        filterAll: true,
                        filterable: true
                    },
                    {
                        Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Validity</p>,
                        id: "validity",
                        accessor: d => d.validity,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["validity"] }),
                        filterAll: true,
                        filterable: true
                    },
                    {
                        Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Valid From</p>,
                        id: "valid_from",
                        accessor: d => d.validity_range?.length > 0 ? moment(d.validity_range[0]).format('YYYY-MM-DD HH:mm') : null,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["valid_from"] }),
                        filterAll: true,
                        filterable: false
                    },
                    {
                        Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Valid Till</p>,
                        id: "valid_till",
                        accessor: d => d.validity_range?.length > 0 ? moment(d.validity_range[1]).format('YYYY-MM-DD HH:mm') : null,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["valid_till"] }),
                        filterAll: true,
                        filterable: false
                    },

                    {
                        Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Status</p>,
                        id: "is_active",
                        accessor: "is_active",
                        Cell: ({ value }) => (value ? <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">Active</span> : <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">De-Activate</span>),
                        filterMethod: (filter, row) => {
                            if (filter.value === "all") {
                                return true;
                            }
                            if (filter.value === 'true') {

                                return row[filter.id] === true;
                            }
                            return row[filter.id] === false;
                        },
                        Filter: ({ filter, onChange }) =>
                            <Select
                                onChange={value => onChange(value)}
                                style={{ width: "100%" }}
                                defaultValue="all"
                                value={filter ? filter.value : "all"}
                            >
                                <Option value="all">Show All</Option>
                                <Option value="true">Active</Option>
                                <Option value="false">De-Activate</Option>
                            </Select>,
                    },
                    {
                        Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Action</p>,
                        id: "actions",
                        width: 150,
                        Cell: ({ row }) => (
                            <div style={{ justifyContent: 'center' }}>
                                {row._original.is_new &&
                                    <>
                                        <Icon onClick={() => {
                                            setCurrentCodeConfig(row._index);
                                            setCurrentIndex(row._index);
                                            setAddModalVisible(true);
                                            setEditType('update')

                                        }} style={{ color: '#5D78FF', fontSize: 16, margin: '0px 10px' }} type="edit" />
                                        <Popconfirm
                                            title="Are you sure you want to delete this code?"
                                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                            onConfirm={() => deleteCodeConfig(row._index)}
                                        >
                                            <Icon style={{ color: '#5D78FF', fontSize: 16 }} type="delete" />
                                        </Popconfirm>

                                    </>
                                }
                                <Popconfirm
                                    title={`Are you sure you want to ${row.is_active ? `DEACTIVATE` : `ACTIVATE`} this code`}
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={() => updateCodeConfigActivation(row._index, !row.is_active)}
                                >
                                    <Switch style={{ backgroundColor: row.is_active ? '#5d78ff' : '#fd397a', margin: '0px 10px' }} checked={row.is_active} />
                                </Popconfirm>


                            </div>
                        ),
                        filterable: false
                    },
                ]
                }
                className="-striped -highlight"
            />
        </div>
    )
}