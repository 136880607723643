import React, { useState } from 'react';
import { Popconfirm, Icon, Switch, Spin, Tooltip } from 'antd';
import ProductOfferingModel from '../../Models/ProductOfferingModel';
import { errorToaster, infoToaster, successNotification } from './Notifications';
import { validateEmail, validatePhone } from '../../Helpers/Shared/Validators';

/**
 * 
 * HERE TYPES STANDS FOR CONTACT TYPE BETWEEN 0 to 3
 * 0: Buy One Get One offers (Outlet Restricted)
 * 1: Delivery offers (Outlet Restricted)
 * 2: Online Deal (Merchant Restricted)
 * 3: Online Booking (Outlet Restricted)
 */

export default function ContactPocView({ is_outlet = false, id }) {



    const [shuffle, setShuffle] = useState(false),
        [loading, setLoading] = useState(false);

    const renderMainView = () => {
        return (
            <div>
                {is_outlet &&
                    <div className="form-group" >

                        <div style={{ borderBottom: '1px solid silver' }} className="row">
                            <label className="col-2 col-form-label">Type</label>
                            <div className="col-4">
                                <h6>Buy1Get1</h6>
                            </div>
                        </div>
                        <div style={{ marginTop: '5px' }} className="row">
                            <label className="col-2 col-form-label">Contact Name</label>
                            <div className="col-4">
                                <input placeholder='Contact Name' maxLength="70" value={ProductOfferingModel.Buy1Get1Object.contact_name} onChange={(event) => updateName(event.target.value, 0)} className="form-control" type="text" />
                            </div>
                            <div className="col-3" />
                            <div className="col-3 row">
                                <Tooltip placement="top" title={'Add New POC'}>
                                    <Icon onClick={() => addContactPoc(0)} style={{ color: '#5D78FF', fontSize: 18, margin: '5px' }} type="plus" />
                                </Tooltip>
                                <Popconfirm
                                    title={ProductOfferingModel.Buy1Get1Object.is_active ? "Are you sure you want to DEACTIVATE this Contact" : "Are you sure you want to ACTIVATE this Contact"}
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={() => updateActivation(ProductOfferingModel.Buy1Get1Object.is_active, ProductOfferingModel.Buy1Get1Object.id || null, 0)} // here integer stands for type
                                >
                                    <Switch style={{ backgroundColor: ProductOfferingModel.Buy1Get1Object.is_active ? '#5d78ff' : '#fd397a', margin: '5px' }} checked={ProductOfferingModel.Buy1Get1Object.is_active} />
                                </Popconfirm>

                                {
                                    !ProductOfferingModel.Buy1Get1Object.is_saved ?
                                        <Popconfirm
                                            title="Are you sure you want to save this Contact?"
                                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                            onConfirm={() => saveContact(0)}
                                        >
                                            <Icon style={{ color: 'red', fontSize: 20, margin: '5px' }} type="save" />

                                        </Popconfirm> : null
                                }
                            </div>
                        </div>
                        {
                            ProductOfferingModel.Buy1Get1Object.product_offering_poc_contacts.map((poc, i) => (
                                <div key={i} style={{ marginTop: '5px' }} className="form-group row">
                                    <div className="col-2" >
                                        <Icon style={{ color: '#6E75A0', fontSize: 30, margin: '5px' }} type="swap-right" />
                                    </div>
                                    <div className="col-3" >
                                        <input placeholder="email" maxLength="100" value={poc.email} onChange={(event) => updateEmail(event.target.value, 0, i)} className="form-control" type="text" />
                                    </div>
                                    <div className="col-3" >
                                        <input placeholder="phone" maxLength="19" value={poc.phone} onChange={(event) => updatePhone(event.target.value, 0, i)} className="form-control" type="text" />
                                    </div>
                                    <div className="col-1" />
                                    <div className="col-3 row">
                                        {
                                            poc.un_saved && !ProductOfferingModel.Buy1Get1Object.is_new ?
                                                <Popconfirm
                                                    title="Are you sure you want to save this POC?"
                                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                    onConfirm={() => saveContactPoc(poc.id, i, 0)}
                                                >
                                                    <Icon style={{ color: 'red', fontSize: 16, margin: '5px' }} type="save" />

                                                </Popconfirm> : null
                                        }
                                        {
                                            ProductOfferingModel.Buy1Get1Object['product_offering_poc_contacts'].length > 1 ?
                                                <Popconfirm
                                                    title="Are you sure you want to delete this POC?"
                                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                    onConfirm={() => removeContactPoc(poc.id, i, 0)}
                                                >
                                                    <Icon style={{ color: '#5D78FF', fontSize: 16, margin: '5px' }} type="delete" />
                                                </Popconfirm>
                                                : null
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                }

                {/* TYPE # 2 */}
                {is_outlet &&
                    <div className="form-group" >

                        <div style={{ borderBottom: '1px solid silver' }} className="row">
                            <label className="col-2 col-form-label">Type</label>
                            <div className="col-4">
                                <h6>Delivery Offers</h6>
                            </div>
                        </div>
                        <div style={{ marginTop: '5px' }} className="row">
                            <label className="col-2 col-form-label">Contact Name</label>
                            <div className="col-4">
                                <input placeholder='Contact Name' maxLength="70" value={ProductOfferingModel.DeliveryOffers.contact_name} onChange={(event) => updateName(event.target.value, 1)} className="form-control" type="text" />
                            </div>
                            <div className="col-3" />
                            <div className="col-3 row">
                                <Tooltip placement="top" title={'Add New POC'}>
                                    <Icon onClick={() => addContactPoc(1)} style={{ color: '#5D78FF', fontSize: 18, margin: '5px' }} type="plus" />
                                </Tooltip>
                                <Popconfirm
                                    title={ProductOfferingModel.DeliveryOffers.is_active ? "Are you sure you want to DEACTIVATE this Contact" : "Are you sure you want to ACTIVATE this Contact"}
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={() => updateActivation(ProductOfferingModel.DeliveryOffers.is_active, ProductOfferingModel.DeliveryOffers.id || null, 1)} // here integer stands for type
                                >
                                    <Switch style={{ backgroundColor: ProductOfferingModel.DeliveryOffers.is_active ? '#5d78ff' : '#fd397a', margin: '5px' }} checked={ProductOfferingModel.DeliveryOffers.is_active} />
                                </Popconfirm>

                                {
                                    !ProductOfferingModel.DeliveryOffers.is_saved ?
                                        <Popconfirm
                                            title="Are you sure you want to save this Contact?"
                                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                            onConfirm={() => saveContact(1)}
                                        >
                                            <Icon style={{ color: 'red', fontSize: 20, margin: '5px' }} type="save" />

                                        </Popconfirm> : null
                                }
                            </div>
                        </div>
                        {
                            ProductOfferingModel.DeliveryOffers.product_offering_poc_contacts.map((poc, i) => (
                                <div key={i} style={{ marginTop: '5px' }} className="form-group row">
                                    <div className="col-2" >
                                        <Icon style={{ color: '#6E75A0', fontSize: 30, margin: '5px' }} type="swap-right" />
                                    </div>
                                    <div className="col-3" >
                                        <input placeholder="email" maxLength="100" value={poc.email} onChange={(event) => updateEmail(event.target.value, 1, i)} className="form-control" type="text" />
                                    </div>
                                    <div className="col-3" >
                                        <input placeholder="phone" maxLength="19" value={poc.phone} onChange={(event) => updatePhone(event.target.value, 1, i)} className="form-control" type="text" />
                                    </div>
                                    <div className="col-1" />
                                    <div className="col-3 row">
                                        {
                                            poc.un_saved && !ProductOfferingModel.DeliveryOffers.is_new ?
                                                <Popconfirm
                                                    title="Are you sure you want to save this POC?"
                                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                    onConfirm={() => saveContactPoc(poc.id, i, 1)}
                                                >
                                                    <Icon style={{ color: 'red', fontSize: 16, margin: '5px' }} type="save" />

                                                </Popconfirm> : null
                                        }
                                        {
                                            ProductOfferingModel.DeliveryOffers['product_offering_poc_contacts'].length > 1 ?
                                                <Popconfirm
                                                    title="Are you sure you want to delete this POC?"
                                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                    onConfirm={() => removeContactPoc(poc.id, i, 1)}
                                                >
                                                    <Icon style={{ color: '#5D78FF', fontSize: 16, margin: '5px' }} type="delete" />
                                                </Popconfirm>
                                                : null
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                }

                {/* Type # 3 */}
                {!is_outlet &&
                    <div className="form-group" >

                        <div style={{ borderBottom: '1px solid silver' }} className="row">
                            <label className="col-2 col-form-label">Type</label>
                            <div className="col-4">
                                <h6>Online Deals</h6>
                            </div>
                        </div>
                        <div style={{ marginTop: '5px' }} className="row">
                            <label className="col-2 col-form-label">Contact Name</label>
                            <div className="col-4">
                                <input placeholder='Contact Name' maxLength="70" value={ProductOfferingModel.OnlineDeals.contact_name} onChange={(event) => updateName(event.target.value, 2)} className="form-control" type="text" />
                            </div>
                            <div className="col-3" />
                            <div className="col-3 row">
                                <Tooltip placement="top" title={'Add New POC'}>
                                    <Icon onClick={() => addContactPoc(2)} style={{ color: '#5D78FF', fontSize: 18, margin: '5px' }} type="plus" />
                                </Tooltip>
                                <Popconfirm
                                    title={ProductOfferingModel.OnlineDeals.is_active ? "Are you sure you want to DEACTIVATE this Contact" : "Are you sure you want to ACTIVATE this Contact"}
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={() => updateActivation(ProductOfferingModel.OnlineDeals.is_active, ProductOfferingModel.OnlineDeals.id || null, 2)} // here integer stands for type
                                >
                                    <Switch style={{ backgroundColor: ProductOfferingModel.OnlineDeals.is_active ? '#5d78ff' : '#fd397a', margin: '5px' }} checked={ProductOfferingModel.OnlineDeals.is_active} />
                                </Popconfirm>

                                {
                                    !ProductOfferingModel.OnlineDeals.is_saved ?
                                        <Popconfirm
                                            title="Are you sure you want to save this Contact?"
                                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                            onConfirm={() => saveContact(2)}
                                        >
                                            <Icon style={{ color: 'red', fontSize: 20, margin: '5px' }} type="save" />

                                        </Popconfirm> : null
                                }
                            </div>
                        </div>
                        {
                            ProductOfferingModel.OnlineDeals.product_offering_poc_contacts.map((poc, i) => (
                                <div key={i} style={{ marginTop: '5px' }} className="form-group row">
                                    <div className="col-2" >
                                        <Icon style={{ color: '#6E75A0', fontSize: 30, margin: '5px' }} type="swap-right" />
                                    </div>
                                    <div className="col-3" >
                                        <input placeholder="email" maxLength="100" value={poc.email} onChange={(event) => updateEmail(event.target.value, 2, i)} className="form-control" type="text" />
                                    </div>
                                    <div className="col-3" >
                                        <input placeholder="phone" maxLength="19" value={poc.phone} onChange={(event) => updatePhone(event.target.value, 2, i)} className="form-control" type="text" />
                                    </div>
                                    <div className="col-1" />
                                    <div className="col-3 row">
                                        {
                                            poc.un_saved && !ProductOfferingModel.OnlineDeals.is_new ?
                                                <Popconfirm
                                                    title="Are you sure you want to save this POC?"
                                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                    onConfirm={() => saveContactPoc(poc.id, i, 2)}
                                                >
                                                    <Icon style={{ color: 'red', fontSize: 16, margin: '5px' }} type="save" />

                                                </Popconfirm> : null
                                        }
                                        {
                                            ProductOfferingModel.OnlineDeals['product_offering_poc_contacts'].length > 1 ?
                                                <Popconfirm
                                                    title="Are you sure you want to delete this POC?"
                                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                    onConfirm={() => removeContactPoc(poc.id, i, 2)}
                                                >
                                                    <Icon style={{ color: '#5D78FF', fontSize: 16, margin: '5px' }} type="delete" />
                                                </Popconfirm>
                                                : null
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                }


                {/* TYPE # 4 */}
                {is_outlet &&
                    <div className="form-group" >

                        <div style={{ borderBottom: '1px solid silver' }} className="row">
                            <label className="col-2 col-form-label">Type</label>
                            <div className="col-4">
                                <h6>Online Bookings</h6>
                            </div>
                        </div>
                        <div style={{ marginTop: '5px' }} className="row">
                            <label className="col-2 col-form-label">Contact Name</label>
                            <div className="col-4">
                                <input placeholder='Contact Name' maxLength="70" value={ProductOfferingModel.OnlineBookings.contact_name} onChange={(event) => updateName(event.target.value, 3)} className="form-control" type="text" />
                            </div>
                            <div className="col-3" />
                            <div className="col-3 row">
                                <Tooltip placement="top" title={'Add New POC'}>
                                    <Icon onClick={() => addContactPoc(3)} style={{ color: '#5D78FF', fontSize: 18, margin: '5px' }} type="plus" />
                                </Tooltip>
                                <Popconfirm
                                    title={ProductOfferingModel.OnlineBookings.is_active ? "Are you sure you want to DEACTIVATE this Contact" : "Are you sure you want to ACTIVATE this Contact"}
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={() => updateActivation(ProductOfferingModel.OnlineBookings.is_active, ProductOfferingModel.OnlineBookings.id || null, 3)} // here integer stands for type
                                >
                                    <Switch style={{ backgroundColor: ProductOfferingModel.OnlineBookings.is_active ? '#5d78ff' : '#fd397a', margin: '5px' }} checked={ProductOfferingModel.OnlineBookings.is_active} />
                                </Popconfirm>

                                {
                                    !ProductOfferingModel.OnlineBookings.is_saved ?
                                        <Popconfirm
                                            title="Are you sure you want to save this Contact?"
                                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                            onConfirm={() => saveContact(3)}
                                        >
                                            <Icon style={{ color: 'red', fontSize: 20, margin: '5px' }} type="save" />

                                        </Popconfirm> : null
                                }
                            </div>
                        </div>
                        {
                            ProductOfferingModel.OnlineBookings.product_offering_poc_contacts.map((poc, i) => (
                                <div key={i} style={{ marginTop: '5px' }} className="form-group row">
                                    <div className="col-2" >
                                        <Icon style={{ color: '#6E75A0', fontSize: 30, margin: '5px' }} type="swap-right" />
                                    </div>
                                    <div className="col-3" >
                                        <input placeholder="email" maxLength="100" value={poc.email} onChange={(event) => updateEmail(event.target.value, 3, i)} className="form-control" type="text" />
                                    </div>
                                    <div className="col-3" >
                                        <input placeholder="phone" maxLength="19" value={poc.phone} onChange={(event) => updatePhone(event.target.value, 3, i)} className="form-control" type="text" />
                                    </div>
                                    <div className="col-1" />
                                    <div className="col-3 row">
                                        {
                                            poc.un_saved && !ProductOfferingModel.OnlineBookings.is_new ?
                                                <Popconfirm
                                                    title="Are you sure you want to save this POC?"
                                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                    onConfirm={() => saveContactPoc(poc.id, i, 3)}
                                                >
                                                    <Icon style={{ color: 'red', fontSize: 16, margin: '5px' }} type="save" />

                                                </Popconfirm> : null
                                        }
                                        {
                                            ProductOfferingModel.OnlineBookings['product_offering_poc_contacts'].length > 1 ?
                                                <Popconfirm
                                                    title="Are you sure you want to delete this POC?"
                                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                    onConfirm={() => removeContactPoc(poc.id, i, 3)}
                                                >
                                                    <Icon style={{ color: '#5D78FF', fontSize: 16, margin: '5px' }} type="delete" />
                                                </Popconfirm>
                                                : null
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                }
            </div>


        )
    }

    function updatePhone(value, type, index) {
        ProductOfferingModel.getInstance().updateSubValue('phone', value, type, index);
        setShuffle(!shuffle);
    }

    function updateEmail(value, type, index) {
        ProductOfferingModel.getInstance().updateSubValue('email', value, type, index);
        setShuffle(!shuffle);
    }

    function updateName(value, type) {
        ProductOfferingModel.getInstance().updateMainValue('contact_name', value, type);
        setShuffle(!shuffle);
    }

    function updateActivation(value, id, type) {
        setLoading(true)
        ProductOfferingModel.getInstance().updateActivation(id, !value, type,
            function success(data) {
                setTimeout(() => {
                    setLoading(false)
                }, 100);
            }, function failure(err) {
                setLoading(false)
                errorToaster('Something went wrong')
            }
        );
    }

    function addContactPoc(type) {
        ProductOfferingModel.getInstance().addContactPoc(type);
        setShuffle(!shuffle)
    }

    function saveContactPoc(id, index, type) {
        const params = ProductOfferingModel.getInstance().getNewObject(type);
        if (!validateEmail(params.product_offering_poc_contacts[index].email)) {
            errorToaster('Please enter valid Email');
            return
        }
        if (params.product_offering_poc_contacts[index].phone.length < 8 || !validatePhone(params.product_offering_poc_contacts[index].phone)) {
            errorToaster('Please enter valid phone');
            return
        }
        setLoading(true);
        ProductOfferingModel.getInstance().saveContactPoc(id, index, type,
            function success() {
                infoToaster('Product Poc Contact saved successfully')
                setLoading(false)
            }, function failure(err) {
                setLoading(false)
                errorToaster('Something went wrong')
            }
        )
    }

    function removeContactPoc(id, index, type) {
        setLoading(true)
        ProductOfferingModel.getInstance().removeContactPoc(id, index, type,
            function success() {
                infoToaster('Product Poc Contact removed')
                setLoading(false)
            }, function failure(err) {
                setLoading(false)
                errorToaster('Something went wrong')
            }
        )
    }

    function saveContact(type) {
        const params = ProductOfferingModel.getInstance().getNewObject(type);
        if (params.contact_name.length < 1) {
            errorToaster('Please enter valid name');
            return
        }
        let block = false;
        for (let i = 0; i < params.product_offering_poc_contacts.length; i++) {
            if (!params.is_new && params.product_offering_poc_contacts[i].un_saved) {
                errorToaster('Please Save Product offering Contact first');
                block = true
                break;
            }
            if (!validateEmail(params.product_offering_poc_contacts[i].email) && params.is_new) {
                errorToaster('Please enter valid email');
                block = true
                break;
            }
            if (!validatePhone(params.product_offering_poc_contacts[i].phone) && params.is_new) {
                errorToaster('Please enter valid phone');
                block = true
                break;
            }
        }
        if (block) {
            return
        }
        setLoading(true);
        ProductOfferingModel.getInstance().saveContact(type,
            function success(params) {
                setLoading(false)
                successNotification('Contact Saved Successfully');
            },
            function failure(err) {
                setLoading(false)
                errorToaster('Something went wrong')
            })
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }




    return (
        <div>
            <Spin spinning={loading}>
                {renderMainView()}
            </Spin>
        </div>

    )
}