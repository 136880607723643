import React from "react";
import { Icon, Spin, Popconfirm, Select, Switch } from "antd";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";

import ReactTable from "react-table";
import "react-table/react-table.css";
import PartnerModel from "../../Models/PartnerModel";
import {
  errorNotification,
  successNotification,
  infoNotification,
  errorToaster,
} from "../Shared/Notifications";
import SharedModel from "../../Models/SharedModel";
import moment from "moment";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      totalPage: 0,
      perPage: 0,
      filters: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getNotification();
  }

  getCommunicationType(text) {
    if ("notification".includes(text)) {
      return 1;
    }
    if ("announcement".includes(text)) {
      return 2;
    }
  }

  getNotification() {
    const it = this;
    it.setState({ loading: true });
    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });
    if (params["notifications.communication_id"]) {
      params["notifications.communication_id"] = this.getCommunicationType(
        params["notifications.communication_id"]
      );
    }
    if (params["notifications.is_active"] === "all") {
      delete params["notifications.is_active"];
    }
    SharedModel.getInstance().searchNotification(
      params,
      function success(data) {
        it.setState({
          loading: false,
          data: SharedModel.getInstance().NotificationList,
          totalPage: Number(data.notification.last_page),
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorToaster("Request information not found");
      }
    );
  }

  deletePartner(partnerId) {
    const it = this;
    it.setState({ loading: true });
    PartnerModel.getInstance().removePartner(
      partnerId,
      function success() {
        it.setState({
          data: PartnerModel.getInstance().PartnerList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  sendNotification(notificationId, sent) {
    const it = this;
    if (sent) {
      errorNotification("Notification Cannot be sent! Already sent to users");
      return;
    }
    it.setState({ loading: true });
    SharedModel.getInstance().sendNotification(
      notificationId,
      function success() {
        it.setState({
          loading: false,
        });
        successNotification("Notification Sent Successfully");
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  activatePartner(partnerId) {
    const it = this;
    it.setState({ loading: true });
    PartnerModel.getInstance().activatePartner(
      partnerId,
      function success() {
        it.setState({
          data: PartnerModel.getInstance().PartnerList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }
  deactivatePartner(partnerId) {
    const it = this;
    it.setState({ loading: true });
    PartnerModel.getInstance().deactivatePartner(
      partnerId,
      function success() {
        it.setState({
          data: PartnerModel.getInstance().PartnerList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getNotification();
  }, 1000);

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={this.state.loading}>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              {gridHeaderWithButton(
                "Notification/Announcements",
                "la la-plus",
                "Add Notification/Announcement",
                "/notifications/add"
              )}
            </div>
            <div className="kt-portlet__body">
              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={20}
                showPageSizeOptions={false}
                manual
                page={this.state.currentPage - 1}
                pages={this.state.totalPage}
                filtered={this.state.filters}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getNotification();
                  }, 50);
                }}
                onFilteredChange={(filters) => {
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                columns={[
                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    id: "notifications.name",
                    accessor: "name",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Title</p>,
                    id: "notifications.title",
                    accessor: "title",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["title"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Text</p>,
                    id: "notifications.text",
                    accessor: "text",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["text"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Schedule Time</p>,
                    id: "notifications.scheduled_at",
                    accessor: (d) =>
                      d.scheduled_at
                        ? moment(d.scheduled_at).format("hh:mm A - DD/MMM/YYYY")
                        : "N/A",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: [(item) => item.scheduled_at],
                      }),
                    filterAll: true,
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Type</p>,
                    id: "notifications.communication_id",
                    accessor: (d) =>
                      d.communication_id == "2"
                        ? "Announcement"
                        : "Notification",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["type"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Sent</p>,
                    id: "notifications.is_send",
                    accessor: "is_send",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Sent
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          Not Sent
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "true") {
                        return row[filter.id] === true;
                      }
                      return row[filter.id] === false;
                    },
                    Filter: ({ filter, onChange }) => (
                      <Select
                        onChange={(value) => onChange(value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <Option value="all">Show All</Option>
                        <Option value="true">Sent</Option>
                        <Option value="false">Not Sent</Option>
                      </Select>
                    ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 150,
                    accessor: (d) => (
                      <div style={{ textAlign: "center" }}>
                        <Link
                          to={
                            d.is_send
                              ? "notifications"
                              : `notifications/edit/${d.id}`
                          }
                          style={{ marginRight: 20 }}
                        >
                          <Icon
                            disabled
                            style={{
                              color: d.is_send ? "gray" : "#5D78FF",
                              fontSize: 16,
                            }}
                            type="edit"
                          />
                        </Link>

                        {/* <Popconfirm
                                                    title="Are you sure you want to delete this partner?"
                                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                    onConfirm={() => this.deletePartner(d.id)}
                                                >
                                                    <Icon style={{ color: '#5D78FF', fontSize: 16 }} type="delete" />
                                                </Popconfirm> */}

                        <Popconfirm
                          title="Are you sure you want to Send this Notification/Announcement?"
                          icon={
                            <Icon
                              type="notification"
                              style={{ color: "blue" }}
                            />
                          }
                          onConfirm={() =>
                            this.sendNotification(d.id, d.is_send)
                          }
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="notification"
                          />
                        </Popconfirm>

                        {/* <Popconfirm
                                                    title={d.is_active ? "Are you sure you want to DEACTIVATE this partner" : "Are you sure you want to ACTIVATE this partner"}
                                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                    onConfirm={() => d.is_active ? this.deactivatePartner(d.id) : this.activatePartner(d.id)}
                                                >
                                                    <Switch style={{ backgroundColor: d.is_active ? '#5d78ff' : '#fd397a', marginLeft: 15 }} checked={d.is_active} onChange={(val) => console.log(val)} />
                                                </Popconfirm> */}
                      </div>
                    ),
                    filterable: false,
                    sortable: false,
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
