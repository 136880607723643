import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel'
// import ApiConstants from '../Constants/api';



export default class MerchantModel {
    static myInstance = null;
    static getInstance() {
        if (MerchantModel.myInstance === null) {
            MerchantModel.myInstance = new MerchantModel();
        }
        return this.myInstance;
    }

    static MerchantTypeList = [];
    static OutletList = [];
    static OutletTypeList = [];
    static DeliveryPartnerList = [];
    static MerchantEcommerceList = [];

    getMerchantType(successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getMerchantType(token,
            function resSuccess(data) {
                MerchantModel.getInstance().MerchantTypeList = data
                successTrigger();
            },
            function resFailed(error) {
                failureTrigger(error)
            }

        )
    }

    getMerchant(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getMerchant(token, params,
            function resSuccess(data) {
                successTrigger(data.merchants);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }
    getEcommerceMerchants(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getEcommerceMerchants(token, params,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getSearchMerchant(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getSearchMerchant(token, params,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    addMerchant(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        ApiManager.getInstance().addMerchant(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    updateMerchant(merchantId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        ApiManager.getInstance().updateMerchant(token, merchantId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    getMerchantDetail(merchantId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getMerchantDetail(token, merchantId,
            function resSuccess(data) {
                successTrigger(data.merchant);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getMerchantsByIds(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getMerchantsByIds(token, params,
            function resSuccess(data) {
                successTrigger(data.merchant);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getMerchantOutlet(merchantId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getMerchantOutlet(token, merchantId,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    removeMerchant(merchantId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeMerchant(token, merchantId,
            function resSuccess() {
                successTrigger()
                
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    merchantActivation(merchantId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().merchantActivation(token, merchantId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }
    getOutlet(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        ApiManager.getInstance().getOutlet(token, params,
            function resSuccess(data) {
                MerchantModel.getInstance().OutletList = data.data
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    getSearchOutlet(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        ApiManager.getInstance().getSearchOutlet(token, params,
            function resSuccess(data) {
                MerchantModel.getInstance().OutletList = data.data
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    getB1G1AssociatedOutlets(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getB1G1AssociatedOutlets(token, params,
            function resSuccess(data) {
                successTrigger(data.records)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    addOutlet(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        ApiManager.getInstance().addOutlet(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    getOutletDetail(outletId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOutletDetail(token, outletId,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )

    }

    updateOutlet(outletId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        ApiManager.getInstance().updateOutlet(token, outletId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    sendMerchantPin(outletId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        // let params = {};
        // params.outlet_id = outletId;
        // params.email = email;
        // params.phone = phone;

        ApiManager.getInstance().sendMerchantPin(token, outletId,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    removeOutlet(outletId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeOutlet(token, outletId,
            function resSuccess() {
                MerchantModel.getInstance().OutletList.forEach((outlet, index) => {
                    if (outlet.id === outletId) {
                        MerchantModel.getInstance().OutletList.splice(index, 1);
                        successTrigger()
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    outletActivation(outletId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().outletActivation(token, outletId, params,
            function resSuccess(data) {
                MerchantModel.getInstance().OutletList.forEach((outlet, index) => {
                    if (outlet.id === outletId) {
                        outlet.is_active = params.is_active;
                        successTrigger(data)
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    getOutletOffers(outletId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOutletOffers(token, outletId,
            function resSuccess(data) {
                successTrigger(data.offers)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    getOutletType(successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        ApiManager.getInstance().getOutletType(token,
            function resSuccess(data) {
                MerchantModel.getInstance().OutletTypeList = data.outlet_types
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    // get delivery partners

    getDeliveryPartner(successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getDeliveryPartner(token,
            function resSuccess(data) {
                MerchantModel.getInstance().DeliveryPartnerList = data.delivery_partners
                successTrigger();
            },
            function resFailed(error) {
                failureTrigger(error)
            }

        )
    }

    // get merchant whose outlet type is ecommerce food

    getMerchantEcommerce(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getMerchantEcommerce(token, params,
            function resSuccess(data) {
                MerchantModel.getInstance().MerchantEcommerceList = data.merchants.data;
                successTrigger(data.merchants);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    //  get outlet according to merchant selection whose outlet type is ecommerce food

    getMerchantOutletEcommerce(merchantId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getMerchantOutletEcommerce(token, merchantId,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }
}