import NetworkManger from "./NetworkHandler";
// import ApiKeys from '../../Constants/api';
import ApiKeys from "../../Constants";

export default class Api {
  static myInstance = null;
  static getInstance() {
    if (Api.myInstance === null) {
      Api.myInstance = new Api();
    }
    return this.myInstance;
  }
  Login(username, password, type, successCallback, failureCallback) {
    var params = {};
    params.email = username;
    params.password = password;
    params.type = type;

    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "user/login",
      null,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(text) {
        failureCallback(text);
      }
    );
  }

  Login_2(username, password, type, successCallback, failureCallback) {
    var params = {};
    params.email = username;
    params.password = password;
    params.type = type;
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/v1/auth/login",
      null,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(text) {
        failureCallback(text);
      }
    );
  }

  refreshUserToken(params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/v1/auth/token",
      null,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addUser(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "user",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(text) {
        failureCallback(text);
      }
    );
  }

  getUserDetails(token, userId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "user/" + userId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateUser(token, userId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "user/" + userId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeUser(token, userId, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "user/delete/" + userId,
      token,
      {},
      function reqSuccess() {
        successCallback();
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getUsersList(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "user",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  userActivation(token, userId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "user/active/" + userId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // userDeactivation(token, userId, params, successCallback, failureCallback) {
  //   NetworkManger.getInstance().updateNetworkRequest(ApiKeys.baseURL + "user/active/" + userId, token, params,
  //     function reqSuccess(data) {
  //       successCallback(data)
  //     },
  //     function reqFailed(error) {
  //       failureCallback(error)
  //     }
  //   )
  // }

  downloadUsers(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getDownloadUrl(
      ApiKeys.baseURL + "user/download",
      token,
      params,
      "Users " + params.from + " to " + params.to,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchUsersList(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "user/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  programAssociation(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "user/program",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getPayment(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "payment_mode",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addPayment(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "payment_mode",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getPaymentDetail(token, paymentId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "payment_mode/" + paymentId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updatePayment(token, paymentId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "payment_mode/" + paymentId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  paymentActivation(
    token,
    paymentId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "payment_mode/active/" + paymentId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removePayment(token, paymentId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "payment_mode/" + paymentId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqSuccess(error) {
        failureCallback(error);
      }
    );
  }

  // Start Category
  getCategory(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "category",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getCategoryDetail(token, categoryId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "category/" + categoryId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addCategory(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "category",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateCategory(token, categoryId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "category/" + categoryId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeCategory(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "category/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqSuccess(error) {
        failureCallback(error);
      }
    );
  }

  searchCategoryByName(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "category/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // end Category

  getSubCategory(token, category_id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL +
        "merchant_category/get_all_sub_categories/" +
        category_id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addSubCategory(
    token,
    name,
    arb_name,
    description,
    arb_description,
    backColor,
    categoryId,
    logoImage,
    bannerImage,
    successCallback,
    failureCallback
  ) {
    var params = new FormData();
    params.append("name", name);
    if (arb_name.length > 0) {
      params.append("arabic_name", arb_name);
    }
    if (arb_description.length > 0) {
      params.append("arabic_description", arb_description);
    }
    params.append("description", description);
    params.append("color", backColor);
    params.append("parent_id", categoryId);
    params.append("icon", logoImage);
    params.append("background", bannerImage);
    NetworkManger.getInstance().postFormRequest(
      ApiKeys.baseURL + "merchant_category/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateSubCategory(
    token,
    name,
    arabicName,
    description,
    arabicDescription,
    backColor,
    categoryId,
    logoImage,
    bannerImage,
    successCallback,
    failureCallback
  ) {
    var params = new FormData();
    params.append("name", name);
    if (arabicName.length > 0) {
      params.append("arabic_name", arabicName);
    }
    if (arabicDescription.length > 0) {
      params.append("arabic_description", arabicDescription);
    }
    params.append("description", description);
    params.append("color", backColor);
    params.append("parent_id", categoryId);
    if (logoImage) {
      params.append("icon", logoImage);
    }
    if (bannerImage) {
      params.append("background", bannerImage);
    }

    NetworkManger.getInstance().postFormRequest(
      ApiKeys.baseURL + "merchant_category/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Start Merhant Tag
  getMerchantTag(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "merchant/tag",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getMerchantTagDetail(token, merchantTagId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "merchant/tag/" + merchantTagId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeMerchantTag(token, merchantTagId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "merchant/tag/" + merchantTagId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addMerchantTag(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "merchant/tag",
      token,
      params,
      function reqFailed(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  updateMerchantTag(
    token,
    merchantTagId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "merchant/tag/" + merchantTagId,
      token,
      params,
      function reqFailed(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  merchantTagActivation(
    token,
    merchantTagId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "merchant/tag/active/" + merchantTagId,
      token,
      params,
      function reqFailed(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Start Merhant Group
  getMerchantGroup(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "merchant/group",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getMerchantGroupDetail(
    token,
    merchantGroupId,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "merchant/group/" + merchantGroupId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeMerchantGroup(
    token,
    merchantGroupId,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "merchant/group/" + merchantGroupId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addMerchantGroup(token, name, arabicName, successCallback, failureCallback) {
    let params = {};
    params.name = name;
    if (arabicName.length > 0) {
      params.arabic_name = arabicName;
    }

    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "merchant/group",
      token,
      params,
      function reqFailed(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  updateMerchantGroup(
    token,
    merchantGroupId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "merchant/group/" + merchantGroupId,
      token,
      params,
      function reqFailed(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  merchantGroupActivation(
    token,
    merchantGroupId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "merchant/group/active/" + merchantGroupId,
      token,
      params,
      function reqFailed(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  // Start Merhant Info Tag
  getMerchantInfoTag(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "info-tag",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getMerchantInfoTagDetail(
    token,
    merchantInfoTagId,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "info-tag/" + merchantInfoTagId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeMerchantInfoTag(
    token,
    merchantInfoTagId,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "info-tag/" + merchantInfoTagId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addMerchantInfoTagMedia(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postFormRequest(
      ApiKeys.baseURL + "info-tag/upload/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addMerchantInfoTag(token, name, arb_name, successCallback, failureCallback) {
    var params = {};
    params.name = name;
    params.arabic_name = arb_name;
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "info-tag",
      token,
      params,
      function reqFailed(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateMerchantInfoTag(
    token,
    merchantTagId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "info-tag/" + merchantTagId,
      token,
      params,
      function reqFailed(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  merchantInfoTagActivation(
    token,
    merchantTagId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "info-tag/active/" + merchantTagId,
      token,
      params,
      function reqFailed(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchInfoTagByName(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "info-tag/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Start Area

  getArea(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "location/area",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getCityArea(token, cityId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "area/city/" + cityId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqSuccess(error) {
        failureCallback(error);
      }
    );
  }
  getAreaDetail(token, areaId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "location/area/" + areaId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addArea(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "location/area",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  updateArea(token, params, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "location/area/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  areaActivation(token, params, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "location/area/active/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  removeArea(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "location/area/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Start Landmark

  getLandmark(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "location/landmark",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getAreaLandmark(token, areaId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "landmark/area/" + areaId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getLandmarkTypes(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "location/landmark/type",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getLandmarkDetail(token, landmarkId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "location/landmark/" + landmarkId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addLandmark(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "location/landmark",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  updateLandmark(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "location/landmark/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  landmarkActivation(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "location/landmark/active/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeLandmark(token, landmarkId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "location/landmark/" + landmarkId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  //get geo
  getCountries(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "location/country",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getCuisine(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "cuisines",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getCities(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "location/city",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addCity(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "location/city",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getCityDetail(token, cityId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "location/city/" + cityId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateCity(token, cityId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "location/city/" + cityId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  cityActivation(token, cityId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "location/city/active/" + cityId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeCity(token, cityId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "location/city/" + cityId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProvince(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "location/province",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getProvinceDetail(token, provinceId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "location/province/" + provinceId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function failure(error) {
        failureCallback(error);
      }
    );
  }

  addProvince(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "location/province",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateProvince(token, provinceId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "location/province/" + provinceId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  provinceActivation(
    token,
    provinceId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "location/province/active/" + provinceId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeProvince(token, provinceId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "location/province/" + provinceId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Merchants

  addMerchant(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "merchant",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateMerchant(token, merchantId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "merchant/" + merchantId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getMerchant(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "merchant",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getEcommerceMerchants(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "outlet/merchant/ecommerce/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getSearchMerchant(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "merchant/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getMerchantDetail(token, merchantId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "merchant/" + merchantId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getMerchantsByIds(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "merchant/ids",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getMerchantOutlet(token, merchantId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "outlet/merchant/" + merchantId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeMerchant(token, merchantId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "merchant/" + merchantId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  merchantActivation(
    token,
    merchantId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "merchant/active/" + merchantId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // deactivateMerchant(token, params, successCallback, failureCallback) {
  //   NetworkManger.getInstance().postNetworkRequest(ApiKeys.baseURL + "merchant/deactivate", token, params,
  //     function reqSuccess(data) {
  //       successCallback(data)
  //     },
  //     function reqFailed(error) {
  //       failureCallback(error)
  //     }
  //   )
  // }

  getMerchantType(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "merchant/type",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function failure(error) {
        failureCallback(error);
      }
    );
  }

  // Collection

  getCollection(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "collection",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function failure(error) {
        failureCallback(error);
      }
    );
  }

  getCollectionByApp(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "collection/app",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function failure(error) {
        failureCallback(error);
      }
    );
  }
  addCollection(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "collection",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getCollectionDetail(token, collectionId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "collection/" + collectionId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(msg) {
        failureCallback(msg);
      }
    );
  }

  updateCollection(
    token,
    params,
    collectionId,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "collection/" + collectionId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  collectionActivation(
    token,
    params,
    collectionId,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "collection/active/" + collectionId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  removeCollection(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "collection/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  associateCollection(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "collection/associate",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        console.log(error);
      }
    );
  }

  addMedia(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postFormRequest(
      ApiKeys.sharedURL + "media",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeMedia(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.sharedURL + "media/remove",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Outlet

  getOutlet(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "outlet",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getSearchOutlet(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "outlet/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getB1G1AssociatedOutlets(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/offers/outlet/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getB1G1Offers(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/offers/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addOutlet(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "outlet",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getOutletDetail(token, outletId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "outlet/" + outletId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateOutlet(token, outletId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "outlet/" + outletId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  outletActivation(token, outletId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "outlet/active/" + outletId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  sendMerchantPin(token, outletId, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "outlet/forget/" + outletId,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeOutlet(token, outletId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "outlet/" + outletId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getOutletOffers(token, outletId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "outlet/offers/" + outletId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // partner

  getPartner(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "partner",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchPartner(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "partner/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addPartner(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "partner",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getPartnerDetail(token, partnerId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "partner/" + partnerId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updatePartner(token, params, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "partner/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  partnerActivation(token, params, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "partner/active/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removePartner(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "partner/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getPartnersByApp(token, app_id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "partner/app/" + app_id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Program

  getProgram(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "program",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchProgram(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "program/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addProgram(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "program",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getPartnerCities(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "partner/city",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProgramDetail(token, programId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "program/" + programId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  setDefaultProgram(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "program/default",
      token,
      params,
      function reqSuccess() {
        successCallback();
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateProgram(token, programId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "program/" + programId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  programActivation(
    token,
    programId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "program/active/" + programId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeProgram(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "program/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProgramType(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "program/type",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProgramAssociation(token, programId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "program/get_merchants/" + programId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getMerchantOffer(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "program/get_merchants_offer",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addOfferAssociation(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "program/associate_offers",
      token,
      params,
      function reqSuccess() {
        successCallback();
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProgramMerchant(token, programId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "merchant/program/" + programId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getOutletProgramMerchant(
    token,
    programId,
    merchantId,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "outlet/program/" + programId + "/" + merchantId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getOfferProgramMerchant(
    token,
    programId,
    outletId,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "outlet/offers/" + programId + "/" + outletId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Offers

  getOffer(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "offer",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getSearchOffer(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "offer/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addOffer(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "offer",
      token,
      params,
      function reqSuccess() {
        successCallback();
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getOfferCriteria(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "offer/criteria",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getOfferDetail(token, offerId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "offer/" + offerId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateOffer(token, offerId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "offer/" + offerId,
      token,
      params,
      function reqSuccess() {
        successCallback();
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  offerActivation(token, offerId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "offer/active/" + offerId,
      token,
      params,
      function reqSuccess() {
        successCallback();
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeOffer(token, offerId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "offer/" + offerId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getOfferType(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "offer/type",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Coupon

  getCoupon(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "coupon",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addCoupon(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "coupon",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateCoupon(token, couponId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "coupon/" + couponId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  couponActivation(token, params, couponId, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "coupon/active/" + couponId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getCouponDetail(token, couponId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "coupon/" + couponId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  checkPrefix(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "coupon/check",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  activateCoupon(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "coupon/activate",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getCodes(token, couponId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "coupon/" + couponId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  deactivateCoupon(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "coupon/deactivate",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchCoupon(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "coupon/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // reports
  getRedemptionReport(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "reports/redemption",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchReport(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "reports/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getPaymentReport(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "reports/payment",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  //payment report activation
  paymentReportActivation(
    token,
    paymentId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "reports/payment/" + paymentId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  downloadReport(token, params, successCallback, failureCallback) {
    let fileName = `${
      params.type === "redemption"
        ? "Redemption-Report " + params.from + " to " + params.to
        : params.type === "payment"
        ? "Payment-Report : " + params.from + " to " + params.to
        : params.type === "merchant"
        ? "All-Merchants"
        : params.type === "outlet"
        ? "All-Outlets"
        : params.type === "offer"
        ? "All-Offers"
        : ""
    }.csv`;
    NetworkManger.getInstance().getDownloadUrl(
      ApiKeys.baseURL + "reports/download",
      token,
      params,
      fileName,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Notifications

  getAllNotifications(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "notification",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addNotification(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "notification",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  sendNotification(token, notificationId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "notification/send/" + notificationId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getNotificationDetail(
    token,
    notificationId,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "notification/" + notificationId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateNotification(
    token,
    notificationId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "notification/" + notificationId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchNotification(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "notification/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Static Pages

  getPages(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "page/get_list",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getPageDetail(token, pageId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "page/get/" + pageId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updatePage(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "page/set",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addMediaInstance(
    token,
    id,
    instance,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().postFormRequest(
      ApiKeys.baseURL + instance + "/upload/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getAllCurrency(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "currency",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getAllAppIds(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "app",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // outlet type

  getOutletType(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "outlet-type",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Item-Group

  getItemGroup(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "item_group",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchItemGroup(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "item_group/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addItemGroup(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "item_group",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getItemGroupDetail(token, itemGroupId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "item_group/" + itemGroupId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateItemGroup(
    token,
    itemGroupId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "item_group/" + itemGroupId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  itemGroupActivation(
    token,
    itemGroupId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "item_group/active/" + itemGroupId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeItemGroup(token, itemGroupId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "item_group/" + itemGroupId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqSuccess(error) {
        failureCallback(error);
      }
    );
  }

  // Item

  getItem(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "item",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchItem(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "item/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addItem(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "item",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getItemDetail(token, itemId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "item/" + itemId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateItem(token, itemId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "item/" + itemId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  itemActivation(token, itemId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "item/active/" + itemId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeItem(token, itemId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "item/" + itemId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqSuccess(error) {
        failureCallback(error);
      }
    );
  }

  // Addon Sets

  getAddonSet(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "addon_set",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchAddonSet(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "addon_set/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addAddonSet(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "addon_set",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getAddonSetDetail(token, addonSetId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "addon_set/" + addonSetId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateAddonSet(token, addonSetId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "addon_set/" + addonSetId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addonSetActivation(
    token,
    addonSetId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "addon_set/active/" + addonSetId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeAddonSet(token, addonSetId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "addon_set/" + addonSetId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqSuccess(error) {
        failureCallback(error);
      }
    );
  }

  // Addons

  getAddon(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "addon",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchAddon(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "addon/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addAddon(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "addon",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getAddonDetail(token, addonId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "addon/" + addonId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateAddon(token, addonId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "addon/" + addonId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addonActivation(token, addonId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "addon/active/" + addonId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeAddon(token, addonId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "addon/" + addonId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqSuccess(error) {
        failureCallback(error);
      }
    );
  }

  // item group list according to outlets

  getOutletItemGroup(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "item/outlets",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // addon set list according to outlets

  getOutletAddon(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "addon/outlets",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // item list according to outlets

  getOutletItem(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "item/item-outlet",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // ecommerce offer

  getEcommerceOffer(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "ecommerce_offer",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchEcommerceOffer(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "ecommerce_offer/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addEcommerceOffer(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "ecommerce_offer",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getEcommerceOfferDetail(
    token,
    ecommerceOfferId,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "ecommerce_offer/" + ecommerceOfferId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateEcommerceOffer(
    token,
    ecommerceOfferId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "ecommerce_offer/" + ecommerceOfferId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  ecommerceOfferActivation(
    token,
    ecommerceOfferId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "ecommerce_offer/active/" + ecommerceOfferId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeEcommerceOffer(
    token,
    ecommerceOfferId,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "ecommerce_offer/" + ecommerceOfferId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqSuccess(error) {
        failureCallback(error);
      }
    );
  }

  // order list

  getOrderReport(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "order",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchOrderReport(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "order/order-report",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchDeliveryReport(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "order/delivery-report",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getOrderDetail(token, orderDetailId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "order/" + orderDetailId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // value type

  getValueType(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "value_type",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchValueType(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "value_type/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addValueType(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "value_type",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getValueTypeDetail(token, valueTypeId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "value_type/" + valueTypeId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateValueType(
    token,
    valueTypeId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "value_type/" + valueTypeId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeValueType(token, valueTypeId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "value_type/" + valueTypeId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqSuccess(error) {
        failureCallback(error);
      }
    );
  }

  getType(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "value_type/types",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProgramValueType(token, programTypeId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "program/type/" + programTypeId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // card sets

  getCardSet(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "card",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchCardSet(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "card/search",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  checkNewOrders(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "order/new-orders",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addCardSet(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "card",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateOrderStatus(token, order_id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "order/" + order_id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getCardSetDetail(token, cardSetId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "card/" + cardSetId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateCardSet(token, cardSetId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "card/" + cardSetId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // sale channel
  getSaleChannel(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "item/sales",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeCardSet(token, cardSetId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "card/" + cardSetId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqSuccess(error) {
        failureCallback(error);
      }
    );
  }

  cardSetActivation(
    token,
    cardSetId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "card/active/" + cardSetId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqSuccess(error) {
        failureCallback(error);
      }
    );
  }
  // item type
  getItemType(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "item/itemtype",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // cards

  getCard(token, cardSetId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "card/" + cardSetId + "/card",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // outlist for outlet portal
  getOutletUser(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "outlet/user-outlets",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getCardDetail(token, cardSetId, cardId, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "card/" + cardSetId + "/" + cardId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateCard(
    token,
    cardSetId,
    cardId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "card/" + cardSetId + cardId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeCard(token, cardSetId, cardId, successCallback, failureCallback) {
    NetworkManger.getInstance().deleteNetworkRequest(
      ApiKeys.baseURL + "card/" + cardSetId + "/" + cardId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqSuccess(error) {
        failureCallback(error);
      }
    );
  }

  cardActivation(
    token,
    cardSetId,
    cardId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "card" + cardSetId + "/active/" + cardId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // forgot passwords
  resetEmail(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "user/forgot",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  resetCode(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "user/reset-code",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updatePassword(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "user/update-password",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // delivery partner
  getDeliveryPartner(token, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "delivery_partner",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // manual order pickup

  manualOrder(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL + "order/pickup",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateOutletStatus(
    token,
    outletId,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL + "outlet/status/" + outletId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // get merchant whose outlet type is ecommerce food

  getMerchantEcommerce(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "merchant/ecommerce",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  //  get outlet according to merchant selection whose outlet type is ecommerce food
  getMerchantOutletEcommerce(
    token,
    merchantId,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL + "outlet/merchant/ecommmerce/" + merchantId,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getBankBins(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/bin_coupon",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getEcommerceSections(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app_ecommerce",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addBankBins(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/bin_coupon",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateBankBins(token, bankBinId, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/bin_coupon/" + bankBinId,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  updateEcommerceSection(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app_ecommerce/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  searchAppBanner(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app_ad_banner",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addAppBanner(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app_ad_banner",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addBulkAppBanner(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app_ad_banner/create",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addAppBannerImage(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app_ad_banner/image",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateAppBannerImage(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app_ad_banner/image/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeAppBannerImage(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app_ad_banner/image/delete" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateAppBanner(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app_ad_banner/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  activateAppBanner(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app_ad_banner/activate/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getAppBannerDetail(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app_ad_banner/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getAllAppVisibilities(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/visibility_setting",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addAppVisibility(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/visibility_setting",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateAppVisibility(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/visibility_setting/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Product Visibility setting section

  getAllProductVisibilities(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/visibility/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProductVisibilityDetail(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/visibility/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addProductVisibility(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/visibility/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  updateProductVisibility(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/visibility/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  deleteProductVisibility(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/visibility/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Visibility configs
  addProductVisibilityConfig(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/visibility-config/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getProductVisibilityConfigs(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/visibility-config/" + id + "/get",
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  removeProductVisibilityConfig(
    token,
    params,
    successCallback,
    failureCallback
  ) {
    console.warn("removeProductVisibilityConfig");
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/visibility-config/delete",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  updateProductVisibilityConfig(
    token,
    id,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/visibility-config/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Product Offering POCs APIs

  getProductOfferingPocsByOutlet(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/offering-poc/outlet/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProductOfferingPocsByMerchant(
    token,
    id,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/offering-poc/merchant/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  updateProductOfferingPocsByMerchant(
    token,
    id,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/offering-poc/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addProductOfferingPocs(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/offering-poc/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // product offering contacts

  addProductOfferingContact(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/offering-poc/contact/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateProductOfferingContact(
    token,
    id,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/offering-poc/contact/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  deleteProductOfferingContact(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/offering-poc/contact/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Online Deals

  getAllOnlineDeals(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/online-deal/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addOnlineDeal(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/online-deal/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateOnlineDeal(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/online-deal/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  deleteOnlineDeal(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/online-deal/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getOnlineDeal(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/online-deal/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getOnlineDealMerchants(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/online-deal/merchant/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Online Deal Configs

  addOnlineDealConfig(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/online-deal/config/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateOnlineDealConfig(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/online-deal/config/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  deleteOnlineDealConfig(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/online-deal/config/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // Online Deal Voucher Configs

  getOnlineDealVoucherConfigs(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/online-deal/voucher/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addOnlineDealVoucherConfig(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/online-deal/voucher/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateOnlineDealVoucherConfig(
    token,
    id,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/online-deal/voucher/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  deleteOnlineDealVoucherConfig(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/online-deal/voucher/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  /**
   * Classifications API Section
   */

  searchClassifications(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/classification/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getClassificationDetail(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/classification/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  saveClassification(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/classification/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateClassification(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/classification/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  removeClassification(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/classification/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getClassificationOutletMerchantCount(
    token,
    id,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/classification/data/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  /**
   * Outlet classification association Section
   */

  getAllOutletClassifications(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/classification/outlet/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateOutletClassification(
    token,
    id,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/classification/outlet/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addOutletClassification(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/classification/outlet/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  deleteOutletClassification(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/classification/outlet/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // OPENING HOURS APIS

  addOpeningHour(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/opening-hour/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getAllOpeningHours(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/opening-hour/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getOpeningHourDetail(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/opening-hour/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  updateOpeningHour(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/opening-hour/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  removeOpeningHour(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/opening-hour/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  // OPENING HOUR CONFIGS
  addOpeningHourConfig(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/opening-hour/config/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getAllOpeningHourConfig(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/opening-hour/config/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getOpeningHourConfigDetail(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/opening-hour/config/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  updateOpeningHourConfig(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/opening-hour/config/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  removeOpeningHourConfig(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/opening-hour/config/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // CATEGORY ASSOCIATION APIS SECTION
  addCategoryAssocation(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/category/associate/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getAllCategoryAssocation(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/category/associate/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getCategoryAssocationDetail(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/category/associate/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  updateCategoryAssocation(
    token,
    id,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/category/associate/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  removeCategoryAssocation(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/category/associate/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  /**
   * PRODUCT BOOKINGS API SECTION
   */

  getAllProductBookings(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  addProductBooking(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateProductBooking(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  deleteProductBooking(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProductBooking(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProductBookingReports(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/report/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProductBookingCalendarReports(
    token,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/report/calendar/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProductBookingReportDetail(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/report/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateProductBookingStatus(
    token,
    id,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 +
        "api/admin/product-booking/report/status/update/" +
        id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProductBookingOutlets(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/outlet/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProductBookingByOutlets(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/outlets/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  /**
   * PRODUCT BOOKING CONFIGS
   */

  addProductBookingConfig(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/config/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProductBookingConfigDetail(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/config/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProductBookingConfigList(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/config/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateProductBookingConfig(
    token,
    id,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/config/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  deleteProductBookingConfig(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/config/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  /**
   * PRODUCT BOOKING ADDONS
   */

  addProductBookingAddon(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/addons/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProductBookingAddonDetail(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/addons/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getProductBookingAddonsByProductBooking(
    token,
    id,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/" + id + "/addons/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateProductBookingAddon(
    token,
    id,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/addons/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  deleteProductBookingAddon(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-booking/addons/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  /**
   * PARTNER PRODUCT PRICINGS
   */

  addPartnerProductPricing(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-pricing/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getPartnerProductPricings(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-pricing/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getPartnerProductPricingDetail(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-pricing/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updatePartnerProductPricing(
    token,
    id,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-pricing/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  deletePartnerProductPricing(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/product-pricing/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  /**
   * APP PROGRAM PAYMENT METHOD APIS
   */

  addAppProgramPayment(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app-program-payment/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getAppProgramPayment(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app-program-payment/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  getAppProgramPaymentDetail(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app-program-payment/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateAppProgramPayment(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app-program-payment/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  deleteAppProgramPayment(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/app-program-payment/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // VODAFONE SUPPORT

  getAppUserProfile(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/vodafone-support/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  updateAllVodafoneSupport(
    token,
    id,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/vodafone-support/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }

  // PRODUCT ORDER TIME SLOTS APIS

  addOrderTimeSlot(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/order-time-slot/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getAllOrderTimeSlots(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/order-time-slot/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getOrderTimeSlotDetail(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/order-time-slot/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  updateOrderTimeSlot(token, id, params, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/order-time-slot/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  removeOrderTimeSlot(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/order-time-slot/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  // OPENING HOUR CONFIGS
  addOrderTimeSlotConfig(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().postNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/order-time-slot/config/add",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getAllOrderTimeSlotConfig(token, params, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/order-time-slot/config/get",
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  getOrderTimeSlotConfigDetail(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().getNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/order-time-slot/config/get/" + id,
      token,
      null,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  updateOrderTimeSlotConfig(
    token,
    id,
    params,
    successCallback,
    failureCallback
  ) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/order-time-slot/config/update/" + id,
      token,
      params,
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
  removeOrderTimeSlotConfig(token, id, successCallback, failureCallback) {
    NetworkManger.getInstance().updateNetworkRequest(
      ApiKeys.baseURL_2 + "api/admin/order-time-slot/config/delete/" + id,
      token,
      {},
      function reqSuccess(data) {
        successCallback(data);
      },
      function reqFailed(error) {
        failureCallback(error);
      }
    );
  }
}
