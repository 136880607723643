import React from "react";
import { Icon, Popconfirm, Switch, Select, Spin } from "antd";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { errorNotification, infoNotification } from "../Shared/Notifications";

import { Link } from "react-router-dom";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";
import OfferModel from "../../Models/OfferModel";

const { Option } = Select;

export default class AppEcommerce extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      countryList: [],
      appList: [],
      loading: false,
      filters: [],
      pageCount: 10,
      offset: 0,
      total_pages: 1,
      currentPage: 1,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getOffers();
  }

  getOffers() {
    const it = this;
    let { filters } = this.state;
    it.setState({ loading: true });
    let params = {
      offset: this.state.currentPage,
      limit: this.state.pageCount,
      offer_type: 3
    };
    filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });
    if (params["is_special"] === "all") {
      delete params["is_special"];
    }
    if (params["offers.is_active"] === "all") {
      delete params["offers.is_active"];
    }
    if (this.props.is_outlet) {
      params["outlets.id"] = this.props.outlet_id;
    }
    OfferModel.getInstance().getSearchOffer(
      params,
      function success(data) {
        it.setState({
          data: OfferModel.getInstance().OfferList,
          loading: false,
          total_pages: data.last_page,
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorNotification("Request information not found");
        console.log(error);
      }
    );
  }

  deleteOffer(offerId) {
    const it = this;
    it.setState({ loading: true });
    OfferModel.getInstance().removeOffer(
      offerId,
      function success() {
        it.setState({
          data: OfferModel.getInstance().OfferList,
          loading: false,
        });
        infoNotification("Offer Deleted Successfully");
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  offerActivation(offerId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = !is_active;
    let params = {
      is_active: activation,
    };
    OfferModel.getInstance().offerActivation(
      offerId,
      params,
      function success() {
        it.setState({
          data: OfferModel.getInstance().OfferList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getOffers();
  }, 1000);

  render() {
    const { data } = this.state;

    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              {gridHeaderWithButton(
                "Product Booking Offers",
                "la la-plus",
                "Add New Product Booking Offer",
                "booking-offer/add"
              )}
            </div>
            <div className="kt-portlet__body">
              <Spin spinning={this.state.loading}>
                {/* react-table */}
                <ReactTable
                  data={data}
                  filterable
                  pageSize={this.state.pageCount}
                  showPageSizeOptions={true}
                  pageSizeOptions={[5, 10, 20, 25, 50]}
                  manual
                  page={this.state.currentPage - 1}
                  pages={this.state.total_pages}
                  filtered={this.state.filters}
                  onPageSizeChange={(pageCount) => {
                    this.setState({ currentPage: 1, pageCount });
                    setTimeout(() => {
                      this.getOffers();
                    }, 50);
                  }}
                  onPageChange={(pageIndex) => {
                    this.setState({ currentPage: pageIndex + 1 });
                    setTimeout(() => {
                      this.getOffers();
                    }, 50);
                  }}
                  onFilteredChange={(filters) => {
                    this.setState({ currentPage: 1, filters });
                    this.searchQuery(this);
                  }}
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  columns={[
                    {
                      Header: <p style={tableHeaderStyles()}>Name</p>,
                      id: "offers.name",
                      accessor: (d) => d.name,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["name"] }),
                      filterAll: true,
                      filterable: true,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Arabic Name</p>,
                      id: "offers.arabic_name",
                      accessor: (d) => d.arabic_name,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["arabic_name"] }),
                      filterAll: true,
                      filterable: true,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Merchant</p>,
                      id: "merchants.name",
                      accessor: (d) => d.merchant.merchant_name,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: [(item) => item.merchant.merchant_name],
                        }),
                      filterAll: true,
                      sortable: false,
                      filterable: !this.props.is_outlet,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Programs</p>,
                      id: "programs.name",
                      accessor: (d) =>
                        d.programs.map((program, key) => (
                          <p
                            className="btn btn-label-info btn-pill"
                            style={{
                              float: "left",
                              margin: "2px",
                              padding: "5px 10px",
                              fontSize: "11px",
                            }}
                            key={key}
                          >
                            {program.name}
                          </p>
                        )),
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: [
                            (item) =>
                              item.programs.map(
                                (program) => program.props.children
                              ),
                          ],
                        }),
                      filterAll: true,
                      sortable: false,
                      filterable: true,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Product Visibility</p>,
                      id: "product_visibility",
                      accessor: (d) => d.product_visibility,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["product_visibility"] }),
                      filterAll: true,
                      sortable: false,
                      filterable: true,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Status</p>,
                      id: "is_active",
                      accessor: "is_active",
                      Cell: ({ value }) =>
                        value ? (
                          <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                            Active
                          </span>
                        ) : (
                          <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                            De-Activate
                          </span>
                        ),
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true;
                        }
                        if (filter.value === "true") {
                          return row[filter.id] === true;
                        }
                        return row[filter.id] === false;
                      },
                      Filter: ({ filter, onChange }) => (
                        <Select
                          onChange={(value) => onChange(value)}
                          style={{ width: "100%" }}
                          defaultValue="all"
                          value={filter ? filter.value : "all"}
                        >
                          <Option value="all">Show All</Option>
                          <Option value="true">Active</Option>
                          <Option value="false">De-Activate</Option>
                        </Select>
                      ),
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Action</p>,
                      id: "actions",
                      width: 150,
                      accessor: (d) => (
                        <div>
                          <Link
                            to={`booking-offer/edit/${d.id}`}
                            style={{ marginRight: 20 }}
                          >
                            <Icon
                              style={{ color: "#5D78FF", fontSize: 16 }}
                              type="edit"
                            />
                          </Link>
                          <Popconfirm
                            title={
                              "Are you sure want to Delete this Booking Offer"
                            }
                            icon={
                              <Icon
                                type="question-circle-o"
                                style={{ color: "red" }}
                              />
                            }
                            onConfirm={() => this.deleteOffer(d.id)}
                          >
                            <Icon
                              style={{ color: "#5D78FF", fontSize: 16 }}
                              type="delete"
                            />
                          </Popconfirm>
                          <Popconfirm
                            title={`Are you sure want to ${d.is_active ? "DEACTIVATE" : "ACTIVATE"
                              } this Booking Offer`}
                            icon={
                              <Icon
                                type="question-circle-o"
                                style={{ color: "red" }}
                              />
                            }
                            onConfirm={() =>
                              this.offerActivation(
                                d.id,
                                d.is_active
                              )
                            }
                          >
                            <Switch
                              style={{
                                backgroundColor: d.is_active
                                  ? "#5d78ff"
                                  : "#fd397a",
                                marginLeft: 15,
                              }}
                              checked={d.is_active}
                              onChange={(val) => console.log(val)}
                            />
                          </Popconfirm>
                        </div>
                      ),
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </Spin>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
