import React from 'react';
import AlphaPicker from 'react-color'






export default class ColorPicker extends React.Component {

    render() {

        return (
            <AlphaPicker
                color={this.props.defaultColor}
                onChange={(color) => this.props.backColor(color.hex)}
            />
        )
    }
}