/* eslint-disable no-useless-escape */

export function validateEmail(text) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (re.test(text))
}
// export function validatePhone(text) {
//     var re = /^[0-9-+]{9,16}$/
//     return (re.test(text))
// };
export function validatePhoneCountry(text) {
    var re = /^\+([0-9]{1,20})$/;
    return (re.test(text))
};

export function validatePhone(text) {
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{1,6}$/
    return (re.test(text))
};
export function validateNumeric(text) {
    var re = /^[0-9]$/
    return (re.test(text))
}
export function validateNumber(text) {
    var re = /^[0-9]{0,1000}$/
    return (re.test(text))
}
export function validateNumberLimit(text) {
    var re = /^[0-9]{5,16}$/
    return (re.test(text))
}
export function validateName(text) {
    var re = /^[a-zA-Z ]{3,30}$/
    return (re.test(text))
}
export function validateAddress(text) {
    var re = /^[a-zA-Z0-9+-/,.#':"` ]{6,200}$/
    return (re.test(text))
}
export function validateCNIC(text) {
    var re = /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/
    return (re.test(text))
}
export function validateCRN(text) {
    var re = /^(\d[0-9]{1,30})$/
    return (re.test(text))
}
export function validateRN(text) {
    var re = /^(\d[0-9]{1,30})$/
    return (re.test(text))
}
export function validatePassword(text) {
    var re = /^.{6,}$/
    return (re.test(text))
}
export function validateRequired(text) {
    var re = /^\S*$/
    return (re.test(text))
}
export function validatePostalCode(text) {
    var re = /^\d{5}$/
    return (re.test(text))
}
export function validateDate(text) {
    var re = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
    return (re.test(text))
}
export function validateString(text) {
    var re = /^[A-Za-z]+$/
    return (re.test(text))
}
export function validateURL(text) {
    var re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    return (re.test(text))
}

export function validateYoutubeUrl(text) {
    var re = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    return (re.test(text))
}
export function validateBin(text) {
    var re = /^[0-9]{6}$/
    return (re.test(text))
}
export function validateVoucherCode(text) {
    var min_max = /^.{4,50}$/
    var white_space = /\s/g
    return (min_max.test(text) && !(white_space.test(text)))
}