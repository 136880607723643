import React from "react";
import { Select, Spin, DatePicker, Icon, Checkbox } from "antd";

import _ from "lodash";
import moment from "moment";
import { DetailPageHeaderWithButton } from "styles/detailPageHeader";

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
import MultipleImages from "../Shared/MultipleImages";
import UserModel from "../../Models/UserModel";
import MerchantModel from "../../Models/MerchantModel";
import OfferModel from "../../Models/OfferModel";
import ProgramModel from "../../Models/ProgramModel";
import ItemModel from "../../Models/ItemModel";
import EcommerceOfferModel from "../../Models/EcommerceOfferModel";

import { validateNumber } from "../../Helpers/Shared/Validators";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class EditOffer extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      imageLoading: false,
      name: "",
      nameError: false,
      arb_name: "",
      arb_nameError: false,
      description: "",
      descriptionError: false,
      arabic_description: "",
      arabic_descriptionError: false,
      outlets: [],
      outletsError: false,
      offerType: null,
      offerTypeError: false,
      offerValue: "",
      offerValueError: false,
      imageList: [],
      imageError: false,
      availableTime: "",
      availableTimeError: false,
      startDate: "",
      endDate: "",
      specialStartDate: "",
      specialEndDate: "",
      dateError: false,
      specialDateError: false,
      delivery: false,
      speciality: false,
      merchant: null,
      merchantError: false,
      exclusion: "",
      arabic_exclusion: "",
      offerCriteriaList: [],
      offerCriteria: [],
      selectedPrograms: [],
      selectedProgramsValidity: [],
      merchantList: [],
      outletList: [],
      offerTypeList: [],
      programList: [],
      program: [],
      programError: false,
      spend: "",
      spendError: false,
      save: "",
      saveError: false,
      buyUnit: null,
      buyUnitError: false,
      getUnit: null,
      getUnitError: false,
      excludedItemGroup: [],
      excludedItemGroupError: false,
      excludedItem: [],
      excludedItemError: false,
      buyItemGroup: [],
      getItemGroup: [],
      itemGroupList: [],
      itemGroupError: false,
      buyItem: [],
      getItem: [],
      itemList: [],
      itemError: false,
      specialValidity: [
        {
          day: "monday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "tuesday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "wednesday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "thursday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "friday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "saturday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "sunday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
      ],
      specialCurrentDay: 0,
      offer_end_date: moment().endOf("year").tz("Asia/Qatar"),
      offer_end_date_error: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    let it = this;
    const {
      match: { params },
    } = this.props;
    it.setState({
      loading: true,
      ecommerceOfferId: params.ecommerceOfferId,
    });
    OfferModel.getInstance().getOfferCriteria(
      function success() {
        it.setState({
          offerCriteriaList: OfferModel.getInstance().OfferCriteriaList,
        });
      },
      function failure(error) {
        errorNotification(error);
      }
    );
    OfferModel.getInstance().getOfferType(
      function success() {
        it.setState({
          offerTypeList: OfferModel.getInstance().OfferTypeList,
        });
      },
      function failure(error) {
        errorNotification(error);
        console.error(error);
      }
    );

    ProgramModel.getInstance().getProgram(
      null,
      function success() {
        it.setState({
          programList: ProgramModel.getInstance().ProgramList,
        });
      },
      function failure(error) {
        errorNotification(error);
      }
    );

    EcommerceOfferModel.getInstance().getEcommerceOfferDetail(
      params.ecommerceOfferId,
      function success(data) {
        it.setState({
          loading: false,
          name: data.name,
          arb_name: data.arabic_name,
          description: data.description,
          arabic_description: data.arabic_description,
          offerType: data.ecommerce_offer_type,
          offerValue: data.offer_value,
          availableTime: data.offer_count,
          speciality: data.is_special,
          delievery: data.is_delivery,
          exclusion: data.exclusion,
          arabic_exclusion: data.arabic_exclusion,
          outlets: data.merchant.outlets.map((outlet) => {
            return outlet.id;
          }),
          specialStartDate: data.is_special
            ? data.validity.start_date
              ? data.validity.start_date
              : null
            : null,
          specialEndDate: data.is_special
            ? data.validity.end_date
              ? data.validity.end_date
              : null
            : null,
          offerCriteria: data.criteria
            ? data.criteria.map((criteria) => {
                return criteria.id;
              })
            : [],
          spend: data.min_spend,
          save: data.save,
          buyUnit: data.buy_units,
          buyItemGroup: data.buy_item_groups,
          buyItem: data.buy_items,
          getUnit: data.free_units,
          getItemGroup: data.get_item_groups,
          getItem: data.get_items,
          excludedItemGroup: data.excluded_item_groups,
          excludedItem: data.excluded_items,
          offer_end_date: moment(data.end_date).tz("Asia/Qatar"),
          imageList: data.media
            ? data.media.map((img) => {
                if (img.uid) {
                  return img;
                } else
                  return {
                    uid: img,
                    url: img,
                  };
              })
            : [],
        });
        if (data.merchant.merchant_id) {
          it.getMerchants(data.merchant.merchant_name);
          it.setState({ merchant: data.merchant.merchant_id });
          it.getOutlets(data.merchant.merchant_id);
          it.getOutletItemGroups(it.state.merchant.outlets);
          it.getOutletItems(it.state.merchant.outlets);
        }
        let { selectedPrograms, selectedProgramsValidity } = it.state;
        if (data.is_special && data.special_validity instanceof Array) {
          selectedProgramsValidity = data.special_validity;
          for (let i = 0; i < it.state.programList.length; i++) {
            for (let j = 0; j < selectedProgramsValidity.length; j++) {
              if (
                it.state.programList[i].id ==
                selectedProgramsValidity[j].program_id
              ) {
                selectedPrograms.push(it.state.programList[i]);
              }
            }
          }
        } else {
          if (data.programs) {
            data.programs.map((program) => {
              selectedPrograms.push(program);
              selectedProgramsValidity.push({
                program_id: program.id,
                start_date: program.start_date,
                end_date: program.end_date,
              });
            });
          }
        }
        it.setState({
          selectedPrograms: _.uniqBy(selectedPrograms, "id"),
          selectedProgramsValidity: _.uniqBy(
            selectedProgramsValidity,
            "program_id"
          ),
        });

        if (data.programs) {
          let programs = [];
          data.programs.map((program) => {
            programs.push(program.id);
          });
          it.setState({ program: programs });
        }

        let arr = [];
        data.merchant.outlets.map((outlet) => {
          arr.push(outlet.id);
        });
        it.setState({
          outlets: arr,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.error(error);
      }
    );
  }

  disabledSpecialDate(date, startDate, endDate) {
    if (startDate.length < 1) {
      return date < moment().startOf("day");
    }
    startDate = moment(startDate, "YYYY-MM-DD");
    endDate = moment(endDate, "YYYY-MM-DD");
    return date < startDate.startOf("day") || date > endDate.endOf("day");
  }

  addSpecialTime() {
    let arr = this.state.specialValidity[this.state.specialCurrentDay].times;
    let newTime = { start_time: "12:00 am", end_time: "11:59 pm" };
    arr.push(newTime);
    this.state.specialValidity[this.state.specialCurrentDay].times = arr;
    this.forceUpdate();
  }

  deleteSpecialTime() {
    let arr = this.state.specialValidity[this.state.specialCurrentDay].times;
    arr.splice(1, 1);
    this.state.specialValidity[this.state.specialCurrentDay].times = arr;
    this.forceUpdate();
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQueryMerchant = this.debounce(function (value, it) {
    it.getMerchants(value);
  }, 1000);

  getMerchants(value) {
    if (value.length > 0) {
      let it = this;
      let params = {
        query: encodeURIComponent(value),
      };
      this.setState({ loading: true });
      MerchantModel.getInstance().getMerchant(
        params,
        function success(data) {
          it.setState({
            loading: false,
            merchantList: data.data,
          });
        },
        function failure(error) {
          errorNotification(error);
          it.setState({ loading: false });
        }
      );
    }
  }

  getOutlets(merchant) {
    this.setState({
      loading: true,
      merchant: merchant,
    });
    let it = this;
    MerchantModel.getInstance().getMerchantOutlet(
      merchant,
      function success(data) {
        it.setState({
          outletList: data,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.error(error);
      }
    );
  }

  getOutletItemGroups() {
    this.setState({
      loading: true,
    });
    let it = this;
    let params = {
      outlet_id: this.state.outlets,
    };
    ItemModel.getInstance().getOutletItemGroup(
      params,
      function success(data) {
        it.setState({
          itemGroupList: data,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.error(error);
      }
    );
  }

  getOutletItems() {
    this.setState({
      loading: true,
    });
    let it = this;
    let params = {
      outlet_id: this.state.outlets,
    };
    ItemModel.getInstance().getOutletItem(
      params,
      function success(data) {
        it.setState({
          itemList: data,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.error(error);
      }
    );
  }

  searchQuery = this.debounce(function (it) {
    it.getOutletItemGroups();
    it.getOutletItems();
  }, 2000);

  sendImages(image) {
    const it = this;
    it.setState({ imageLoading: true });
    let arr = this.state.imageList;
    UserModel.getInstance().addMedia(
      image,
      function success(url) {
        let img = {
          uid: url,
          url: url,
        };
        arr.push(img);
        it.setState({
          imageList: arr,
          imageLoading: false,
        });
      },
      function failure() {
        it.setState({ imageLoading: false });
      }
    );
  }

  removeImages(image) {
    const it = this;
    it.setState({ imageLoading: true });
    let arr = this.state.imageList;
    let { url } = image;
    UserModel.getInstance().removeMedia(
      url,
      function success() {
        it.setState({ imageLoading: false });
        arr.forEach((img, key) => {
          if (img.uid === image.uid) {
            arr.splice(key, 1);
          }
        });
        it.setState({ imageList: arr });
      },
      function failure(error) {
        console.log(error);
        it.setState({ imageLoading: false });
      }
    );
  }

  sendData() {
    const {
      ecommerceOfferId,
      name,
      arb_name,
      description,
      arabic_description,
      program,
      merchant,
      outlets,
      exclusion,
      arabic_exclusion,
      offerCriteria,
      offerType,
      offerValue,
      availableTime,
      imageList,
      delivery,
      speciality,
      selectedProgramsValidity,
      excludedItemGroup,
      excludedItem,
      spend,
      save,
      buyUnit,
      buyItemGroup,
      buyItem,
      getItemGroup,
      getItem,
      getUnit,
      offer_end_date,
    } = this.state;
    let it = this;
    let params = {};
    const text = "Validation Error! Please check fields";
    this.setState({
      nameError: false,
      arb_nameError: false,
      priceError: false,
      dateError: false,
      descriptionError: false,
      arabic_descriptionError: false,
      merchantError: false,
      outletsError: false,
      offerTypeError: false,
      offerValueError: false,
      availableTimeError: false,
      imageError: false,
      programError: false,
      excludedItemGroupError: false,
      excludedItemError: false,
      spendError: false,
      saveError: false,
      buyUnitError: false,
      getUnitError: false,
      buyError: false,
      getError: false,
    });

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster("Enter name");
      return;
    }
    params.name = name;

    params.arabic_name = arb_name;

    if (description.length < 1) {
      this.setState({ descriptionError: true });
      errorToaster("Enter description");
      return;
    }
    params.description = description;

    params.arabic_description = arabic_description;

    if (program.length < 1) {
      this.setState({ programError: true });
      errorToaster("Please select program");
      return;
    }
    params.program_id = program;

    if (!merchant) {
      this.setState({ merchantError: true });
      errorToaster("Please select merchant");
      return;
    }

    if (outlets.length < 1) {
      this.setState({ outletsError: true });
      errorToaster("Please select outlet");
      return;
    }
    params.outlet_id = outlets;

    params.excluded_item_id = excludedItem;

    params.excluded_item_group_id = excludedItemGroup;

    params.exclusion = exclusion;

    params.arabic_exclusion = arabic_exclusion;

    params.criteria_id = offerCriteria;

    if (!offerType) {
      this.setState({ offerTypeError: true });
      errorToaster("Please select offer type");
      return;
    }
    params.offer_types = {};
    params.offer_types.offer_type_id = offerType;

    if (offerType == 3) {
      if (!validateNumber(buyUnit)) {
        this.setState({ buyUnit: true });
        errorToaster("Enter buy units for bogo");
        return;
      } else {
        if (Number(buyUnit < 1)) {
          this.setState({ buyUnitError: true });
          errorToaster("Please enter buy unit greater than zero");
          return;
        }
      }
      params.offer_types.buy_units = parseInt(buyUnit);
      if (!offer_end_date) {
        this.setState({ offer_end_date_error: true });
        errorToaster(text);
        return;
      }

      if (buyItem.length < 1 && buyItemGroup.length < 1) {
        this.setState({ buyError: true });
        errorToaster("Select buy item or item group for bogo");
        return;
      }
      params.buy_item_id = buyItem;

      params.buy_item_group_id = buyItemGroup;

      if (!validateNumber(getUnit)) {
        this.setState({ getUnitError: true });
        errorToaster("Enter get units for bogo");
        return;
      } else {
        if (Number(getUnit < 1)) {
          this.setState({ getUnitError: true });
          errorToaster("Please enter get unit greater than zero");
          return;
        }
      }
      params.offer_types.free_units = parseInt(getUnit);

      if (getItem.length < 1 && getItemGroup.length < 1) {
        this.setState({ getError: true });
        errorToaster("Select get item or item group for bogo");
        return;
      }
      params.get_item_id = getItem;

      params.get_item_group_id = getItemGroup;
    }

    if (offerType == 5) {
      if (!validateNumber(spend)) {
        this.setState({ spendError: true });
        errorToaster("Enter spend for cashback only integer");
        return;
      } else {
        if (!spend || Number(spend) <= Number(save)) {
          this.setState({ spendError: true });
          errorToaster("Enter spend or spend must be grater than save");
          return;
        }
      }

      params.offer_types.min_spend = parseInt(spend);

      if (!validateNumber(save)) {
        this.setState({ saveError: true });
        errorToaster("Enter save for cashback");
        return;
      } else {
        if (!save) {
          this.setState({ saveError: true });
          errorToaster("Enter save");
          return;
        }
      }
      params.offer_types.save = parseInt(save);
    }

    if (offerValue.length < 1) {
      this.setState({ offerValueError: true });
      errorToaster("Enter estimated savings");
      return;
    }
    params.offer_value = parseInt(offerValue);

    if (Number(availableTime < 1)) {
      this.setState({ availableTimeError: true });
      errorToaster("Enter redemption count greter than 0");
      return;
    } else {
      if (!validateNumber(availableTime)) {
        this.setState({ availableTimeError: true });
        errorToaster("Enter redemption count only integers");
        return;
      }
    }
    params.offer_count = parseInt(availableTime);

    let media = [];
    imageList.forEach((image) => {
      media.push(image.url);
    });
    params.media = media;

    if (speciality) {
      let varcheck = selectedProgramsValidity.map((val) => {
        return {
          program_id: val.program_id,
          start_date: moment(val.start_date).startOf("day").toISOString(),
          end_date: moment(val.end_date).endOf("day").toISOString(),
        };
      });
      params.special_validity = varcheck;
    }

    params.is_special = speciality;

    params.is_delivery = delivery;

    this.setState({ loading: true });
    EcommerceOfferModel.getInstance().updateEcommerceOffer(
      ecommerceOfferId,
      params,
      function success() {
        successNotification("Ecommerce Offer Updated Successfully");
        setTimeout(() => {
          it.props.history.goBack();
        }, 1000);
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  getSpecialDate(program) {
    let date = [];
    this.state.selectedProgramsValidity.forEach((sp, i) => {
      if (program.id === sp.program_id) {
        date = [
          moment(
            this.state.selectedProgramsValidity[i].start_date,
            "YYYY-MM-DD"
          ),
          moment(this.state.selectedProgramsValidity[i].end_date, "YYYY-MM-DD"),
        ];
      }
    });
    return date;
  }

  render() {
    const {
      loading,
      nameError,
      arb_nameError,
      imageError,
      offer_end_date,
      descriptionError,
      arabic_descriptionError,
      merchantError,
      outletsError,
      offerTypeError,
      offerValueError,
      availableTimeError,
      programError,
      averageRevenueError,
      specialDateError,
      merchantList,
      outletList,
      offerTypeList,
      weekDays,
      offerCriteriaList,
      programList,
      spendError,
      saveError,
      itemGroupList,
      itemList,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <Spin spinning={loading}>
          <div className="kt-content  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <DetailPageHeaderWithButton
                      title={"Edit Ecommerce Offer"}
                      buttonOneIcon={"la la-arrow-left"}
                      buttonOneCallBack={() => {
                        this.props.history.goBack();
                      }}
                      buttonTwoIcon={"la la-check"}
                      buttonTwoCallBack={() => this.sendData()}
                    />
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="70"
                                    value={this.state.name}
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter name in English
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Arabic Name
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="100"
                                    value={this.state.arb_name}
                                    onChange={(event) =>
                                      this.setState({
                                        arb_name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: arb_nameError ? "red" : "",
                                      }}
                                    >
                                      Please enter name in Arabic
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Description <b>*</b>
                                </label>
                                <div className="col-9">
                                  <textarea
                                    maxLength="200"
                                    value={this.state.description}
                                    onChange={(event) =>
                                      this.setState({
                                        description: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  ></textarea>
                                  <span
                                    style={{
                                      color: descriptionError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: descriptionError ? "red" : "",
                                      }}
                                    >
                                      Please enter Description in English
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Arabic Description
                                </label>
                                <div className="col-9">
                                  <textarea
                                    maxLength="200"
                                    value={this.state.arabic_description}
                                    onChange={(event) =>
                                      this.setState({
                                        arabic_description: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  ></textarea>
                                  <span
                                    style={{
                                      color: arabic_descriptionError
                                        ? "red"
                                        : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: arabic_descriptionError
                                          ? "red"
                                          : "",
                                      }}
                                    >
                                      Please enter Description in Arabic
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Offer End Date <b>*</b>
                                </label>
                                <div className="col-9">
                                  <DatePicker
                                    onChange={(date, dateString) =>
                                      this.setState({
                                        offer_end_date: moment(dateString),
                                      })
                                    }
                                    allowClear={true}
                                    className="form-control"
                                    value={offer_end_date}
                                    style={{
                                      padding: "0px",
                                      border: "0px solid black",
                                    }}
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: this.state.offer_end_date_error
                                          ? "red"
                                          : "",
                                      }}
                                    >
                                      Please select offer end date
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Program <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.program}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(program) => {
                                      let {
                                        selectedProgramsValidity,
                                        selectedPrograms,
                                      } = this.state;
                                      let tempProgId = null;
                                      if (
                                        program.length > selectedPrograms.length
                                      ) {
                                        for (
                                          let i = 0;
                                          i < program.length;
                                          i++
                                        ) {
                                          if (
                                            !_.includes(
                                              selectedPrograms,
                                              program[i]
                                            )
                                          ) {
                                            tempProgId = program[i];
                                          }
                                        }
                                        for (
                                          let i = 0;
                                          i < programList.length;
                                          i++
                                        ) {
                                          if (
                                            programList[i].id === tempProgId
                                          ) {
                                            selectedPrograms.push(
                                              programList[i]
                                            );
                                            selectedProgramsValidity.push({
                                              program_id: programList[i].id,
                                              start_date:
                                                programList[i].start_date,
                                              end_date: programList[i].end_date,
                                            });
                                          }
                                        }
                                      } else {
                                        for (
                                          let i = 0;
                                          i < selectedPrograms.length;
                                          i++
                                        ) {
                                          if (
                                            !_.includes(
                                              program,
                                              selectedPrograms[i].id
                                            )
                                          ) {
                                            tempProgId = selectedPrograms[i].id;
                                          }
                                        }

                                        for (
                                          let i = 0;
                                          i < selectedPrograms.length;
                                          i++
                                        ) {
                                          if (
                                            selectedPrograms[i].id ===
                                            tempProgId
                                          ) {
                                            selectedPrograms.splice(i, 1);
                                            selectedProgramsValidity.splice(
                                              i,
                                              1
                                            );
                                          }
                                        }
                                      }
                                      this.setState({
                                        selectedPrograms,
                                        program,
                                        selectedProgramsValidity,
                                      });
                                    }}
                                  >
                                    {programList.map(
                                      (program) =>
                                        program.is_active && (
                                          <Option
                                            key={program.id}
                                            value={program.id}
                                          >
                                            {program.name}
                                          </Option>
                                        )
                                    )}
                                  </Select>
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: programError ? "red" : "",
                                      }}
                                    >
                                      Please Select Program(s)
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Merchant <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.merchant}
                                    notFoundContent={
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Icon
                                          style={{
                                            fontSize: 16,
                                            marginRight: "10px",
                                            marginBottom: "5px",
                                          }}
                                          type="search"
                                        />
                                        <p style={{ fontSize: 14 }}>
                                          Please Search Merchant
                                        </p>
                                      </div>
                                    }
                                    onSearch={(val) =>
                                      this.searchQueryMerchant(val, this)
                                    }
                                    onChange={(merchant) =>
                                      this.getOutlets(merchant)
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {merchantList.map((merchant) => (
                                      <Option
                                        key={merchant.id}
                                        value={merchant.id}
                                      >
                                        {merchant.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: merchantError ? "red" : "",
                                      }}
                                    >
                                      Please Select Merchant
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Outlets <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.outlets}
                                    onChange={(outlets) => {
                                      this.setState({
                                        outlets,
                                        excludedItemGroup: [],
                                        excludedItem: [],
                                        getItem: [],
                                        getItemGroup: [],
                                        buyItem: [],
                                        buyItemGroup: [],
                                      });
                                      this.searchQuery(this);
                                    }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {outletList.map((outlet) => (
                                      <Option key={outlet.id} value={outlet.id}>
                                        {outlet.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: outletsError ? "red" : "",
                                      }}
                                    >
                                      Please Select Outlets
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Exclusion
                                </label>
                                <div className="col-9">
                                  <input
                                    value={this.state.exclusion}
                                    maxLength="100"
                                    onChange={(event) =>
                                      this.setState({
                                        exclusion: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    Please enter Exclusion in English
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Arabic Exclusion
                                </label>
                                <div className="col-9">
                                  <input
                                    value={this.state.arabic_exclusion}
                                    maxLength="100"
                                    onChange={(event) =>
                                      this.setState({
                                        arabic_exclusion: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    Please enter Exclusion in Arabic
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Offer Criteria
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.offerCriteria}
                                    onChange={(offerCriteria) =>
                                      this.setState({ offerCriteria })
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {offerCriteriaList.map((criteria) => (
                                      <Option
                                        key={criteria.id}
                                        value={criteria.id}
                                      >
                                        {criteria.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    Please Select Offer Criteria
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Items Excluded{" "}
                                </label>
                                <div className="col-9">
                                  <div className="row">
                                    <Select
                                      showSearch
                                      mode="multiple"
                                      style={{
                                        margin: "10px 0px 10px 0px",
                                        width: "25%",
                                        marginLeft: "2%",
                                      }}
                                      value={this.state.excludedItemGroup}
                                      onChange={(excludedItemGroup) => {
                                        this.setState({ excludedItemGroup });
                                      }}
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {itemGroupList.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                          {item.name}
                                        </Option>
                                      ))}
                                    </Select>
                                    <p
                                      style={{
                                        margin: "10px 0px 10px 0px",
                                        width: "20%",
                                        marginLeft: "2%",
                                      }}
                                    >
                                      <b>item-groups with</b>
                                    </p>
                                    <Select
                                      showSearch
                                      mode="multiple"
                                      style={{
                                        margin: "10px 0px 10px 0px",
                                        width: "25%",
                                        marginLeft: "2%",
                                      }}
                                      value={this.state.excludedItem}
                                      onChange={(excludedItem) => {
                                        this.setState({ excludedItem });
                                      }}
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {itemList.map((item) => (
                                        <Option
                                          disabled={
                                            this.state.getItem.includes(
                                              item.id
                                            ) ||
                                            this.state.buyItem.includes(item.id)
                                          }
                                          key={item.id}
                                          value={item.id}
                                        >
                                          {item.name}
                                        </Option>
                                      ))}
                                    </Select>
                                    <p
                                      style={{
                                        margin: "10px 0px 10px 0px",
                                        width: "20%",
                                        marginLeft: "2%",
                                      }}
                                    >
                                      <b>items</b>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Offer Type <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.offerType}
                                    onChange={(offerType) =>
                                      this.setState({ offerType })
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {offerTypeList.map((offerType) => (
                                      <Option
                                        key={offerType.id}
                                        value={offerType.id}
                                      >
                                        {offerType.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: offerTypeError ? "red" : "",
                                      }}
                                    >
                                      Please Select Offer Type
                                    </p>
                                  </span>
                                </div>
                              </div>

                              {this.state.offerType == 3 ? (
                                <div className="form-group row">
                                  <label className="col-3 col-form-label"></label>
                                  <div className="col-9">
                                    <div className="row">
                                      <p
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "7%",
                                          marginLeft: "3%",
                                          paddingTop: "9px",
                                        }}
                                      >
                                        <b>Buy*</b>
                                      </p>
                                      <input
                                        value={this.state.buyUnit}
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "10%",
                                          marginLeft: "1%",
                                        }}
                                        maxLength="200"
                                        onChange={(event) =>
                                          this.setState({
                                            buyUnit: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                        type="text"
                                      />
                                      <p
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "10%",
                                          marginLeft: "2%",
                                          paddingTop: "8px",
                                        }}
                                      >
                                        <b>units of</b>{" "}
                                      </p>
                                      <Select
                                        mode="multiple"
                                        showSearch
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "30%",
                                          marginLeft: "2%",
                                        }}
                                        value={this.state.buyItemGroup}
                                        onChange={(buyItemGroup) => {
                                          this.setState({ buyItemGroup });
                                        }}
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {itemGroupList.map((itemGroup) => (
                                          <Option
                                            key={itemGroup.id}
                                            value={itemGroup.id}
                                          >
                                            {itemGroup.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <p
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "20%",
                                          marginLeft: "2%",
                                          paddingTop: "8px",
                                        }}
                                      >
                                        <b>item-groups/</b>
                                      </p>
                                      <Select
                                        mode="multiple"
                                        showSearch
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "30%",
                                          marginLeft: "2%",
                                        }}
                                        value={this.state.buyItem}
                                        onChange={(buyItem) => {
                                          this.setState({ buyItem });
                                        }}
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {itemList.map((item) => (
                                          <Option
                                            disabled={this.state.excludedItem.includes(
                                              item.id
                                            )}
                                            key={item.id}
                                            value={item.id}
                                          >
                                            {item.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <p
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "15%",
                                          marginLeft: "2%",
                                          paddingTop: "8px",
                                        }}
                                      >
                                        <b>items, get*</b>
                                      </p>
                                      <input
                                        value={this.state.getUnit}
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "10%",
                                          marginLeft: "1%",
                                        }}
                                        maxLength="200"
                                        onChange={(event) =>
                                          this.setState({
                                            getUnit: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                        type="text"
                                      />
                                      <p
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "10%",
                                          marginLeft: "2%",
                                          paddingTop: "8px",
                                        }}
                                      >
                                        <b>units of </b>
                                      </p>
                                      <Select
                                        mode="multiple"
                                        showSearch
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "30%",
                                          marginLeft: "2%",
                                        }}
                                        value={this.state.getItemGroup}
                                        onChange={(getItemGroup) => {
                                          this.setState({ getItemGroup });
                                        }}
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {itemGroupList.map((itemGroup) => (
                                          <Option
                                            key={itemGroup.id}
                                            value={itemGroup.id}
                                          >
                                            {itemGroup.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <p
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "18%",
                                          marginLeft: "2%",
                                          paddingTop: "8px",
                                        }}
                                      >
                                        <b>item-groups/</b>
                                      </p>
                                      <Select
                                        mode="multiple"
                                        showSearch
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "30%",
                                          marginLeft: "2%",
                                        }}
                                        value={this.state.getItem}
                                        onChange={(getItem) => {
                                          this.setState({ getItem });
                                        }}
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {itemList.map((item) => (
                                          <Option
                                            disabled={this.state.excludedItem.includes(
                                              item.id
                                            )}
                                            key={item.id}
                                            value={item.id}
                                          >
                                            {item.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <p
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "12%",
                                          marginLeft: "2%",
                                          paddingTop: "8px",
                                        }}
                                      >
                                        <b>items</b>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {this.state.offerType == 5 ? (
                                <div className="form-group row">
                                  <label className="col-3 col-form-label"></label>
                                  <div className="col-9">
                                    <div className="row">
                                      <p
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "10%",
                                          marginLeft: "1%",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <b>Spend *</b>{" "}
                                      </p>
                                      <input
                                        value={this.state.spend}
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "30%",
                                          marginLeft: "1%",
                                        }}
                                        maxLength="200"
                                        onChange={(event) =>
                                          this.setState({
                                            spend: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                        type="text"
                                      />
                                      <p
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "10%",
                                          marginLeft: "3%",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <b>, Save *</b>
                                      </p>
                                      <input
                                        value={this.state.save}
                                        style={{
                                          margin: "10px 0px 10px 0px",
                                          width: "30%",
                                          marginLeft: "1%",
                                        }}
                                        maxLength="200"
                                        onChange={(event) =>
                                          this.setState({
                                            save: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Estimated Savings <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="200"
                                    value={this.state.offerValue}
                                    onChange={(event) =>
                                      this.setState({
                                        offerValue: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: offerValueError ? "red" : "",
                                      }}
                                    >
                                      Please enter Offer value
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Redepmtion Count <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="20"
                                    value={this.state.availableTime}
                                    onChange={(event) =>
                                      this.setState({
                                        availableTime: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: availableTimeError ? "red" : "",
                                      }}
                                    >
                                      Please enter the number times a customer
                                      can avail
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Offer Images
                                </label>
                                <div className="col-9">
                                  <MultipleImages
                                    getImages={(image) =>
                                      this.sendImages(image)
                                    }
                                    removeImage={(image) =>
                                      this.removeImages(image)
                                    }
                                    loading={this.state.imageLoading}
                                    imageList={this.state.imageList}
                                    imageLimit={5}
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: imageError ? "red" : "" }}
                                    >
                                      Please Upload Offer Images
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Delivery
                                </label>
                                <div className="col-9">
                                  <Checkbox
                                    onChange={(event) =>
                                      this.setState({
                                        delivery: event.target.checked,
                                      })
                                    }
                                    className="form-control"
                                    style={{ border: "0px" }}
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    Please check if delivery is available
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Special
                                </label>
                                <div className="col-9">
                                  <Checkbox
                                    checked={this.state.speciality}
                                    onChange={(event) =>
                                      this.setState({
                                        speciality: event.target.checked,
                                      })
                                    }
                                    className="form-control"
                                    style={{ border: "0px" }}
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    Please check if offer is special
                                  </span>
                                </div>
                              </div>
                              {this.state.speciality && (
                                <div>
                                  {this.state.selectedPrograms.map(
                                    (program, index) => (
                                      <div
                                        key={program.id}
                                        className="form-group row"
                                      >
                                        <label className="col-3 col-form-label">
                                          {program.name}
                                        </label>
                                        <div className="col-9">
                                          <RangePicker
                                            disabledDate={(date) =>
                                              this.disabledSpecialDate(
                                                date,
                                                program.start_date,
                                                program.end_date
                                              )
                                            }
                                            allowClear={false}
                                            className="form-control"
                                            value={this.getSpecialDate(program)}
                                            // value={[moment(this.state.selectedProgramsValidity[index].start_date, "YYYY-MM-DD"), moment(this.state.selectedProgramsValidity[index].end_date, "YYYY-MM-DD")]}
                                            style={{
                                              padding: "0px",
                                              border: "0px solid black",
                                            }}
                                            showTime={false}
                                            onChange={(value, string) => {
                                              this.state.selectedProgramsValidity.forEach(
                                                (sp, i) => {
                                                  if (
                                                    program.id === sp.program_id
                                                  ) {
                                                    this.state.selectedProgramsValidity[
                                                      i
                                                    ].start_date = string[0];
                                                    this.state.selectedProgramsValidity[
                                                      i
                                                    ].end_date = string[1];
                                                    this.forceUpdate();
                                                  }
                                                }
                                              );
                                            }}
                                            format="YYYY-MM-DD"
                                          />
                                          {/* <span style={{ color: nameError ? 'red' : '' }} className="form-text text-muted"><p style={{ color: specialDateError ? 'red' : '' }}>Please enter Start and End Date</p></span> */}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
