import { Upload, Icon } from 'antd';
import { validateVoucherCode } from 'Helpers/Shared/Validators';
import React from 'react'

const { Dragger } = Upload;

export default class FileUploader extends React.Component {

    csvJSON(csv) {
        let lines = csv.split("\n"),
            result = [],
            is_passed = true;

        lines.forEach(row => {
            if (!validateVoucherCode(row.replace('\r', ''))) {
                is_passed = false
            }
            result.push(row.replace('\r', ''))
        });
        return {
            status: is_passed,
            codes: result
        }
    }

    render() {
        const { getData } = this.props;
        return (
            <Dragger
                accept=".csv"
                showUploadList={false}
                beforeUpload={file => {
                    const reader = new FileReader();
                    reader.fileName = file.name
                    reader.onload = e => {
                        getData(this.csvJSON(e.target.result), e.target.fileName)
                    };
                    reader.readAsText(file);
                    return false;
                }}
            >
                <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>

            </Dragger>
        )
    }
}