import React from "react";
import { Select, Icon, Popconfirm, Spin, Switch } from "antd";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { infoNotification, errorNotification } from "../Shared/Notifications";

import UserModel from "../../Models/UserModel";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      perPage: 0,
      totalPage: 0,
      filteredName: "",
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getLandmark();
  }
  getLandmark() {
    const it = this;
    it.setState({ loading: true });
    let params = {
      offset: this.state.currentPage,
    };
    if (this.state.filteredName.length > 0) {
      params.query = this.state.filteredName;
    }
    UserModel.getInstance().getLandmark(
      params,
      function success(data) {
        it.setState({
          loading: false,
          data: UserModel.getInstance().LandmarkList,
          perPage: data.per_page,
          totalPage: data.last_page,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
        errorNotification("Request information not found");
      }
    );
  }

  deleteLandmark(landmark_id) {
    const it = this;
    it.setState({ loading: true });
    UserModel.getInstance().removeLandmark(
      landmark_id,
      function success() {
        it.setState({ data: UserModel.getInstance().LandmarkList });
        it.setState({ loading: false });
        infoNotification("Landmark Deleted");
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  landmarkActivation(landmarkId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = !is_active;
    let params = {
      is_active: activation,
    };
    UserModel.getInstance().landmarkActivation(
      landmarkId,
      params,
      function success() {
        it.setState({ data: UserModel.getInstance().LandmarkList });
        it.setState({ loading: false });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getLandmark();
  }, 1000);

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={this.state.loading}>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              {gridHeaderWithButton(
                "Landmarks",
                "la la-plus",
                "Add New Landmark",
                "landmark/add"
              )}
            </div>
            <div className="kt-portlet__body">
              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={20}
                // showPageJump={false}
                showPageSizeOptions={false}
                manual
                page={this.state.currentPage - 1}
                pages={this.state.totalPage}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getLandmark();
                  }, 50);
                }}
                onFilteredChange={(filtered) => {
                  this.setState({
                    currentPage: 1,
                    filteredName: filtered.length < 1 ? "" : filtered[0].value,
                  });
                  this.searchQuery(this);
                }}
                // onFetchData={(state, instance) => {
                // 	if (this.state.currentPage !== state.page + 1) {
                // 		this.setState({ currentPage: state.page + 1 })
                // 		setTimeout(() => {
                // 			this.getLandmark()
                // 		}, 50);
                // 	}
                // }}
                columns={[
                  // {
                  // Header: "Name",
                  // columns: [
                  // {
                  // 	Header: <p style={{fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize:15}} >Icon</p>,
                  // 	id: "icon",
                  // 	accessor: d => (<img src={d.icon} style={{height:50, width:50}}/>),
                  // 	filterMethod: (filter, rows) =>
                  // 		matchSorter(rows, filter.value, { keys: ["id"] }),
                  // 		filterable:false
                  // 	},
                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    id: "name",
                    accessor: (d) => d.name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Arabic Name</p>,
                    id: "arabic_name",
                    accessor: (d) => d.arabic_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["arabic_name"],
                      }),
                    filterAll: true,
                  },
                  // {
                  // 	Header: <p style={{fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize:15}} >Color</p>,
                  // 	id: "arabic_name",
                  // 	accessor: d => d.arabic_name,
                  // 	filterMethod: (filter, rows) =>
                  // 		matchSorter(rows, filter.value, { keys: ["arabic_name"] }),
                  // 	filterAll: true
                  // },
                  {
                    Header: <p style={tableHeaderStyles()}>Address</p>,
                    id: "address",
                    style: { whiteSpace: "unset" },
                    accessor: (d) => d.address,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["address"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Arabic Address</p>,
                    id: "arabic_address",
                    style: { whiteSpace: "unset" },
                    accessor: (d) => d.arabic_address,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["arabic_address"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Sections</p>,
                    id: "sections",
                    accessor: (d) =>
                      d.sections
                        ? d.sections.map((section, index) => (
                            <div key={index}>
                              <p>{section.name}</p>
                              {/* <br/> */}
                            </div>
                          ))
                        : null,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["sections"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Area</p>,
                    id: "area_name",
                    accessor: (d) => d.area_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["area_name"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>City</p>,
                    id: "city_name",
                    accessor: (d) => d.city_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["city_name"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "is_active",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    // filterMethod: (filter, rows) =>
                    // 	matchSorter(rows, filter.value, { keys: ["is_active"] }),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value == "true") {
                        return row[filter.id] == true;
                      }
                      return row[filter.id] == false;
                    },
                    Filter: ({ filter, onChange }) => (
                      <Select
                        onChange={(value) => onChange(value)}
                        style={{ width: "100%" }}
                        defaultValue="all"
                      >
                        <Option value="all">Show All</Option>
                        <Option value="true">Active</Option>
                        <Option value="false">De-Activate</Option>
                      </Select>
                    ),
                    // filterAll: true
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 150,
                    accessor: (d) => (
                      <div>
                        <Link
                          to={`landmark/edit/${d.id}`}
                          style={{ marginRight: 20 }}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="edit"
                          />
                        </Link>
                        <Popconfirm
                          title="Are you sure you want to delete this landmark?"
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() => this.deleteLandmark(d.id)}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="delete"
                          />
                        </Popconfirm>
                        <Popconfirm
                          // style={{}}
                          title={
                            d.is_active
                              ? "Are you sure you want to DEACTIVATE this Landmark"
                              : "Are you sure you want to ACTIVATE this Landmark"
                          }
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.landmarkActivation(d.id, d.is_active)
                          }
                        >
                          <Switch
                            style={{
                              backgroundColor: d.is_active
                                ? "#5d78ff"
                                : "#fd397a",
                              marginLeft: 15,
                            }}
                            checked={d.is_active}
                            onChange={(val) => console.log(val)}
                          />
                        </Popconfirm>
                      </div>
                    ),
                    filterable: false,
                    sortable: false,
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
