import React from "react";
import { Icon, Spin, Button, Popconfirm, Select, Modal, Divider } from "antd";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";

import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  errorNotification,
  infoNotification,
  errorToaster,
  successNotification,
} from "../Shared/Notifications";
import UserModel from "../../Models/UserModel";
import DialogModal from "../Shared/Model";
import OrderStatusChips from "../Shared/OrderStatusChips";
import MerchantModel from "../../Models/MerchantModel";
import SharedModel from "../../Models/SharedModel";

const { Option } = Select;

export default class OrderReport extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      totalPage: 1,
      perPage: 20,
      filters: [],
      orderDetailVisible: false,
      orderDetail: undefined,
      locationDetailVisible: false,
      notificationGranted: false,
      currentOrderId: null,
      showUpdateStatusModal: false,
      orderStatus: null,
      preparationTime: "",
      preparationTimeError: false,
      allowEdit: false,
      currentLat: 0,
      currentLng: 0,
      order_cart: undefined,
      delivery_address: undefined,
      manualOrderVisible: false,
      time: "",
      timeError: false,
      currentStatus: null,
      status: "",
      currentClass: "",
      outlets: null,
      outletList: [],
      outletsError: false,
      outletDetail: undefined,
      is_busy: false,
    };
  }

  componentDidMount() {
    this.checkNotificationSupport();
    this.getOrderReport();
    this.checkForNewOrders();
    this.setState({
      currentClass: window.location.pathname,
    });
    const type = localStorage.getItem("user_role");
    this.setState({ type });
  }

  componentWillUnmount() {
    clearTimeout(this.long_polling);
  }

  getOutletDetail(outlets) {
    let it = this;
    it.setState({ loading: true });
    MerchantModel.getInstance().getOutletDetail(
      outlets,
      function success(outletDetail) {
        it.setState({
          loading: false,
          outletDetail,
          status: outletDetail.status,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(JSON.stringify(error));
        console.log(error);
      }
    );
  }

  checkNotificationSupport() {
    const it = this;
    if (!window.Notification) {
      console.log("Browser does not support notifications.");
    } else {
      // check if permission is already granted
      if (Notification.permission === "granted") {
        this.setState({ notificationGranted: true });
        // show notification here
      } else {
        // request permission from user
        Notification.requestPermission()
          .then(function (p) {
            if (p === "granted") {
              // show notification here
              it.setState({ notificationGranted: true });
            } else {
              console.log("User blocked notifications.");
            }
          })
          .catch(function (err) {
            console.error(err);
          });
      }
    }
  }

  getOrderReport() {
    const it = this;
    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });

    it.setState({ loading: true });
    UserModel.getInstance().searchOrderReport(
      params,
      function success(data) {
        it.setState({
          loading: false,
          data: UserModel.getInstance().OrderReportList,
          totalPage: data.orders.last_page,
          perPage: data.orders.per_page,
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorToaster("Request information not found");
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getOrderReport();
  }, 1000);

  checkForNewOrders() {
    let it = this;
    UserModel.getInstance().checkNewOrders(
      function success(data) {
        it.openNotification(data.pending);
        if (!it.state.loading) {
          it.getOrderReport();
        }
        it.long_polling = setTimeout(() => {
          it.checkForNewOrders();
        }, 20000);
      },
      function failure() {
        console.log("No New Order");
        it.long_polling = setTimeout(() => {
          it.checkForNewOrders();
        }, 20000);
      }
    );
  }

  showOrderDetailModal(order_id) {
    const it = this;
    this.setState({
      loading: true,
    });

    UserModel.getInstance().getOrderDetail(
      order_id,
      function success(orderDetail) {
        it.setState({
          orderDetail,
          loading: false,
          // orderDetailVisible: true,
          manualOrderVisible: true,
        });
      },
      function failure(error) {
        errorToaster("Doesn't open order details");
        it.setState({
          loading: false,
          orderDetail: undefined,
        });
        console.log(error);
      }
    );
  }

  showOrderUpdateStatus(
    order_id,
    orderStatus,
    preparationTime,
    order_cart,
    delivery_address
  ) {
    this.setState({
      currentOrderId: order_id,
      showUpdateStatusModal: true,
      orderStatus,
      preparationTime,
      order_cart,
      delivery_address,
    });
    // if (orderStatus == 1) {
    this.setState({ allowEdit: true });
    // }
  }

  closeOrderStatuModal() {
    this.setState({
      currentOrderId: null,
      showUpdateStatusModal: false,
      orderStatus: null,
      allowEdit: false,
      preparationTime: "",
      order_cart: undefined,
      delivery_address: undefined,
      orderDetail: undefined,
    });
  }

  updateOrderStatus(orderStatus) {
    const { currentOrderId, preparationTime } = this.state,
      it = this;
    let params = {};
    this.setState({
      preparationTimeError: false,
    });
    if (orderStatus === "2") {
      if (!Number.isInteger(Number(preparationTime))) {
        this.setState({ preparationTimeError: true });
        errorToaster("Preparation Time must be Integer value");
        return;
      }
      if (Number(preparationTime) < 1 || Number(preparationTime) > 240) {
        this.setState({ preparationTimeError: true });
        errorToaster("Preparation Time must be inbetween 1 to 240");
        return;
      }
      params.preparation_time = preparationTime;
    }

    params.status = orderStatus;
    it.setState({ loading: true });
    UserModel.getInstance().updateOrderStatus(
      currentOrderId,
      params,
      function success(data) {
        successNotification("Status Updated");
        it.closeOrderStatuModal();
        it.getOrderReport();
        it.setState({ loading: false });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(JSON.stringify(error));
      }
    );
  }

  handleOkCancel() {
    this.setState({
      orderDetailVisible: false,
      // locationDetailVisible: false,
    });
  }

  // locationDetailModal(lat, lng) {
  //     this.setState({
  //         locationDetailVisible: true,
  //         currentLat: lat,
  //         currentLng: lng
  //     })
  // }

  openNotification(count) {
    this.playAudio();
    // document.getElementById("notificationSound").click();
    if (this.state.notificationGranted) {
      let notify = new Notification("You have a new order!", {
        body: "Open My Book",
        // body: `You Have ${count} Pending Orders`,
        icon: "./icon.jpg",
      });
      notify.onclick = function (ev) {
        window.location.href = "/order-report";
      };
    } else {
      infoNotification(`You Have ${count} Pending Orders`, 5);
    }
  }

  playAudio() {
    var audio = new Audio("./sound-notification.mp3");
    audio.play();
  }

  renderStatusUpdateView(orderStatus) {
    switch (orderStatus) {
      case 1:
        return (
          <div>
            <Button
              onClick={() => this.updateOrderStatus(2)}
              style={{
                marginRight: "10px",
                width: "100%",
                height: 40,
                backgroundColor: "#FD397A",
                color: "#FFFFFF",
                fontSize: 17,
              }}
            >
              ACCEPT THIS ORDER
            </Button>
            <Popconfirm
              title="Are you sure you want to reject this order?"
              onConfirm={() => this.updateOrderStatus(6)}
              okText="Yes"
              cancelText="Cancel"
            >
              <div
                style={{
                  marginTop: 15,
                  width: "100%",
                  marginLeft: "180px",
                  fontSize: 17,
                  cursor: "pointer",
                  color: "#6C7293",
                }}
              >
                <u>Reject this order</u>
              </div>
            </Popconfirm>
          </div>
        );

      case 2:
        return (
          <div>
            <Button
              onClick={() => this.updateOrderStatus(7)}
              style={{
                marginRight: "100px",
                width: "100%",
                height: 40,
                backgroundColor: "#FFB822",
                color: "#FFFFFF",
                fontSize: 17,
              }}
            >
              DISPATCH THIS ORDER
            </Button>
          </div>
        );

      case 6:
        return (
          <div>
            <Button
              onClick={() => this.updateOrderStatus(2)}
              style={{
                marginRight: "10px",
                width: "100%",
                height: 40,
                backgroundColor: "#FD397A",
                color: "#FFFFFF",
                fontSize: 17,
              }}
            >
              ACCEPT THIS ORDER
            </Button>
          </div>
        );

      case 7:
        return (
          <div>
            <Button
              onClick={() => this.updateOrderStatus(8)}
              style={{
                marginRight: "10px",
                width: "100%",
                height: 40,
                backgroundColor: "#0ABB87",
                color: "#FFFFFF",
                fontSize: 17,
              }}
            >
              DELIVERED THIS ORDER
            </Button>
          </div>
        );

      default:
        return <div />;
    }
  }

  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          background:
            rowInfo.row._original.status === "Awaiting External Confirmation"
              ? "#FFEBF1"
              : "",
          color:
            rowInfo.row._original.status === "Awaiting External Confirmation"
              ? "#6C7293"
              : "",
        },
      };
    }
    return {};
  };

  handleOk() {
    const { orderDetail, time } = this.state;
    let it = this;
    let params = {
      order_id: orderDetail.id,
    };

    params.time = time;

    it.setState({ loading: true });
    UserModel.getInstance().manualOrder(
      params,
      function success(data) {
        successNotification("Manually created Successfully");
        setTimeout(() => {
          window.location.href = "/order-log";
        }, 500);
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  handleCancel() {
    this.setState({
      manualOrderVisible: false,
    });
  }

  renderAction(order_status_id, payment_method) {
    switch (order_status_id) {
      case 1:
        return (
          <div
            className="kt-badge kt-badge--inline kt-badge--pill"
            style={{
              color: "white",
              fontSize: "15px",
              marginLeft: 10,
              backgroundColor: "#FD397A",
              borderRadius: "2px",
              paddingTop: "10px",
            }}
          >
            ACCEPT
          </div>
        );

      case 2:
        return (
          <div
            className="kt-badge kt-badge--inline kt-badge--pill"
            style={{
              color: "white",
              fontSize: "15px",
              backgroundColor: "#FFB822",
              borderRadius: "2px",
              paddingTop: "10px",
            }}
          >
            DISPATCH
          </div>
        );

      case 6:
        return payment_method === "1" ? (
          <div
            className="kt-badge kt-badge--inline kt-badge--pill"
            style={{
              color: "white",
              fontSize: "15px",
              backgroundColor: "#FD397A",
              marginLeft: 10,
              borderRadius: "2px",
              paddingTop: "10px",
            }}
          >
            ACCEPT
          </div>
        ) : (
          <span
            className="kt-badge kt-badge--inline kt-badge--pill"
            style={{
              color: "#6C7293",
              fontSize: "16px",
              marginLeft: 30,
              paddingTop: "10px",
            }}
          >
            <b>-</b>
          </span>
        );

      case 7:
        return (
          <div
            className="kt-badge kt-badge--inline kt-badge--pill"
            style={{
              color: "white",
              fontSize: "15px",
              backgroundColor: "#0ABB87",
              borderRadius: "2px",
              paddingTop: "10px",
            }}
          >
            DELIVER
          </div>
        );

      default:
        return (
          <span
            className="kt-badge kt-badge--inline kt-badge--pill"
            style={{
              color: "#6C7293",
              fontSize: "16px",
              marginLeft: 30,
              paddingTop: "10px",
            }}
          >
            <b>-</b>
          </span>
        );
    }
  }

  paymentMethod(method) {
    switch (method) {
      case 2:
        return "Credit Card";

      case 3:
        return "Drbit Card";

      default:
        return "Cash on delivery";
    }
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQueryOutlet = this.debounce(function (value, it) {
    it.getOutletList(value);
  }, 1000);

  getOutletList(value) {
    let it = this;
    it.setState({ loading: true });
    let params = {
      value,
    };
    UserModel.getInstance().getOutletUser(
      params,
      function success() {
        it.setState({
          loading: false,
          outletList: UserModel.getInstance().OutletUserList,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  renderStatus() {
    switch (this.state.status) {
      case "Open":
        return (
          <h6 style={{ color: "#0ABB87", fontSize: 25, paddingTop: 5 }}>
            <b>{`OPEN`}</b>
          </h6>
        );

      case "Busy":
        return (
          <h6 style={{ color: "#FD397A", fontSize: 25, paddingTop: 5 }}>
            <b>{`BUSY`}</b>
          </h6>
        );

      case "Closed":
        return (
          <h6 style={{ color: "#A2A3B7", fontSize: 25, paddingTop: 5 }}>
            <b>{"CLOSED"}</b>
          </h6>
        );

      default:
        return (
          <h6 style={{ color: "#A2A3B7", fontSize: 25, paddingTop: 5 }}>
            <b>{"CLOSED"}</b>
          </h6>
        );
    }
  }

  updateOutlet(status) {
    const { outlets } = this.state,
      it = this;
    let params = {};

    params.is_busy = status;

    it.setState({ loading: true });
    SharedModel.getInstance().updateOutletStatus(
      outlets,
      params,
      function success(data) {
        if (data.outlet.is_busy) {
          it.setState({ status: "Busy", loading: false });
        } else {
          it.setState({ status: "Open", loading: false });
        }
        successNotification("Outlet status updated");
      },
      function failure(error) {
        console.log(error);
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  renderChangeStatus() {
    const { status } = this.state;
    switch (status) {
      case "Open":
        return (
          <div>
            <Popconfirm
              title="Do you want to make the outlet busy?"
              onConfirm={() => this.updateOutlet(true)}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button
                type="button"
                style={{
                  display: "flex",
                  paddingTop: 5,
                  fontFamily: "Arial,HelveticaNeue",
                  height: "40px",
                  backgroundColor: "#5D78FF",
                  color: "#FFFFFF",
                  fontSize: 17,
                }}
              >
                Change outlet status
              </Button>
            </Popconfirm>
          </div>
        );

      case "Busy":
        return (
          <div>
            <Popconfirm
              title="Do you want to make the outlet open?"
              onConfirm={() => this.updateOutlet(false)}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button
                type="button"
                style={{
                  display: "flex",
                  paddingTop: 5,
                  height: "40px",
                  fontFamily: "Arial,HelveticaNeue",
                  backgroundColor: "#5D78FF",
                  color: "#FFFFFF",
                  fontSize: 17,
                }}
              >
                Change outlet status
              </Button>
            </Popconfirm>
          </div>
        );

      case "Closed":
        return (
          <div>
            <Button
              type="button"
              style={{
                display: "flex",
                paddingTop: 5,
                height: "40px",
                fontFamily: "Arial,HelveticaNeue",
                backgroundColor: "#A2A3B7",
                color: "#FFFFFF",
                fontSize: 17,
                cursor: "not-allowed",
              }}
            >
              <b>Change outlet status</b>
            </Button>
          </div>
        );
    }
  }

  render() {
    const { data, visible, preparationTimeError, outletDetail } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <Spin spinning={this.state.loading}>
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-portlet__head kt-portlet__head--lg">
                <div className="kt-portlet__head-label">
                  <span className="kt-portlet__head-icon">
                    <i className="kt-font-brand flaticon2-line-chart"></i>
                  </span>
                  <h3 className="kt-portlet__head-title">Order Report</h3>
                </div>

                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-wrapper">
                    {this.state.outlets ? (
                      <div className="row">
                        <h3
                          style={{
                            paddingTop: 15,
                            color: "#6C7293",
                            fontWeight: 530,
                            fontFamily: "Arial,HelveticaNeue",
                            marginRight: "10px",
                          }}
                        >
                          <div className="kt-hidden-mobile">
                            {"Outlet Status :"}
                          </div>
                        </h3>
                        <h3 style={{ paddingTop: 7, marginRight: "10px" }}>
                          <span className="kt-hidden-mobile">
                            <b>
                              {outletDetail
                                ? this.renderStatus(outletDetail.status)
                                : null}
                            </b>
                          </span>
                        </h3>
                        <div style={{ paddingTop: 10, marginRight: "20px" }}>
                          <div className="kt-hidden-mobile">
                            {this.renderChangeStatus()}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="kt-portlet__body">
                {this.state.type === "5" ? (
                  <div className="form-group row">
                    <label
                      style={{ padding: 15 }}
                      className="col-1 col-form-label"
                      htmlFor="userRole"
                    >
                      <h5>Outlet</h5>{" "}
                    </label>
                    <div className="col-9">
                      <Select
                        showSearch
                        style={{ margin: "10px 0px 10px 0px", width: "35%" }}
                        onChange={(outlets) => {
                          this.getOutletDetail(outlets);
                          this.setState({ outlets });
                        }}
                        notFoundContent={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Icon
                              style={{
                                fontSize: 16,
                                marginRight: "10px",
                                marginBottom: "5px",
                              }}
                              type="search"
                            />
                            <p style={{ fontSize: 14 }}>
                              Please Search Outlet by Name
                            </p>
                          </div>
                        }
                        onSearch={(val) => this.searchQueryOutlet(val, this)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.outletList.map((outlet) => (
                          <Option key={outlet.id} value={outlet.id}>
                            {outlet.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                ) : null}
                {
                  <ReactTable
                    data={data}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                      String(row[filter.id]) === filter.value
                    }
                    pageSize={20}
                    showPageSizeOptions={false}
                    manual
                    page={this.state.currentPage - 1}
                    pages={this.state.totalPage}
                    filtered={this.state.filters}
                    onPageChange={(pageIndex) => {
                      this.setState({ currentPage: pageIndex + 1 });
                      setTimeout(() => {
                        this.getOrderReport();
                      }, 50);
                    }}
                    onFilteredChange={(filters) => {
                      this.setState({ currentPage: 1, filters });
                      this.searchQuery(this);
                    }}
                    getTrProps={this.getTrProps}
                    columns={[
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Order No.
                          </p>
                        ),
                        id: "orders.order_number",
                        accessor: (d) => (
                          <Link to={`order/${d.id}`} style={{ marginLeft: 15 }}>
                            <u>{d.order_number}</u>
                          </Link>
                        ),
                        style: {
                          whiteSpace: "unset",
                          color: "#5D78FF",
                          paddingTop: 15,
                        },
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["order_number"],
                          }),
                        filterAll: true,
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Status
                          </p>
                        ),
                        id: "order_statuses.name",
                        accessor: (d) => (
                          <div
                            style={{
                              height: "100%",
                              width: "100%",
                              textAlign: "center",
                              paddingTop: "10px",
                            }}
                          >
                            {OrderStatusChips(d.order_status_id)}
                          </div>
                        ),
                        style: { whiteSpace: "unset" },
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ["status"] }),
                        filterAll: true,
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Action
                          </p>
                        ),
                        id: "statuses",
                        accessor: (d) => (
                          <div
                            style={{
                              cursor: "pointer",
                              marginLeft: "2px",
                              paddingTop: 10,
                            }}
                            onClick={() =>
                              this.showOrderUpdateStatus(
                                d.id,
                                d.order_status_id,
                                d.preparation_time,
                                d.order_cart,
                                d.delivery_address
                              )
                            }
                          >
                            {this.renderAction(
                              d.order_status_id,
                              d.payment_method
                            )}
                          </div>
                        ),
                        style: { whiteSpace: "unset" },
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["order_status_id"],
                          }),
                        filterable: false,
                      },
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Name
                          </p>
                        ),
                        id: "users.full_name",
                        accessor: (d) => (
                          <div style={{ marginLeft: "10px", paddingTop: 10 }}>
                            {d.customer_name}
                          </div>
                        ),
                        style: { whiteSpace: "unset" },
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["customer_name"],
                          }),
                        filterAll: true,
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Mobile no.
                          </p>
                        ),
                        id: "users.mobile",
                        accessor: (d) => (
                          <div style={{ paddingTop: 10 }}>{d.mobile}</div>
                        ),
                        style: { whiteSpace: "unset" },
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ["mobile"] }),
                        filterAll: true,
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Location
                          </p>
                        ),
                        id: "customer_location",
                        width: 80,
                        accessor: (d) => (
                          <a
                            target="_blank"
                            href={`https://maps.google.com/?q=${d.delivery_address.lat},${d.delivery_address.lng}`}
                          >
                            <Icon
                              style={{
                                color: "#5D78FF",
                                fontSize: 16,
                                marginLeft: 30,
                                paddingTop: 10,
                              }}
                              type="environment"
                            />
                          </a>
                        ),
                        filterable: false,
                      },
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Preparation time
                          </p>
                        ),
                        id: "preparation_time",
                        accessor: (d) =>
                          d.preparation_time ? (
                            <div className="row">
                              <div
                                style={{ marginLeft: 8, paddingTop: 10 }}
                              >{`${d.preparation_time} Minutes`}</div>
                              <Link
                                to={`order/${d.id}`}
                                style={{ marginRight: 20 }}
                              >
                                <Icon
                                  style={{
                                    color: "#5D78FF",
                                    fontSize: 11,
                                    marginLeft: 5,
                                    alignItems: "center",
                                    paddingTop: 15,
                                  }}
                                  type="edit"
                                />
                              </Link>
                            </div>
                          ) : (
                            <div
                              style={{
                                color: "grey",
                                fontSize: "16px",
                                marginLeft: 40,
                                paddingTop: 10,
                              }}
                            >
                              <b>-</b>
                            </div>
                          ),
                        style: { whiteSpace: "unset" },
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["preparation_time"],
                          }),
                        filterable: false,
                      },
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Order time
                          </p>
                        ),
                        id: "orders.created_at",
                        accessor: (d) => d.order_time,
                        style: { whiteSpace: "unset" },
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["order_time"],
                          }),
                        filterAll: true,
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Merchant
                          </p>
                        ),
                        id: "merchants.name",
                        accessor: (d) => (
                          <div style={{ paddingTop: 10 }}>{d.merchant}</div>
                        ),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["merchant"],
                          }),
                        filterAll: true,
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Outlet{" "}
                          </p>
                        ),
                        id: "outlets.name",
                        accessor: (d) => (
                          <div style={{ paddingTop: 10 }}>{d.outlet}</div>
                        ),
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ["outlet"] }),
                        filterAll: true,
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Payment Method
                          </p>
                        ),
                        id: "payment_modes.name",
                        accessor: (d) => (
                          <div
                            style={{
                              fontSize: "12px",
                              marginLeft: "20px",
                              paddingTop: 10,
                            }}
                          >
                            {this.paymentMethod(d.payment_method)}
                          </div>
                        ),
                        style: { whiteSpace: "unset" },
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["payment_method"],
                          }),
                        filterAll: true,
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Amount
                          </p>
                        ),
                        id: "orders.total_amount",
                        accessor: (d) => (
                          <div
                            style={{
                              fontSize: "12px",
                              marginLeft: "15px",
                              paddingTop: 10,
                            }}
                          >{`QR ${d.total_amount}.00`}</div>
                        ),
                        style: { whiteSpace: "unset" },
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["total_amount"],
                          }),
                        filterAll: true,
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Delivery Status
                          </p>
                        ),
                        id: "orders.delivery_status",
                        accessor: (d) => (
                          <div style={{ paddingTop: 10 }}>
                            {d.delivery_status}
                          </div>
                        ),
                        style: { whiteSpace: "unset" },
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["delivery_status"],
                          }),
                        filterAll: true,
                        sortable: false,
                        filterable: true,
                      },
                      {
                        Header: (
                          <p
                            style={{
                              fontWeight: "500",
                              borderBottomWidth: "1px",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              fontSize: 15,
                            }}
                          >
                            Actions
                          </p>
                        ),
                        id: "actions",
                        width: 70,
                        accessor: (d) => (
                          <div>
                            <Icon
                              style={{
                                color: "#5D78FF",
                                fontSize: 16,
                                marginLeft: "20px",
                                paddingTop: 10,
                              }}
                              type="form"
                              onClick={() => this.showOrderDetailModal(d.id)}
                            />
                          </div>
                        ),
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                  />
                }

                <Modal
                  title={`New Order`}
                  visible={this.state.showUpdateStatusModal}
                  footer={null}
                  onCancel={() =>
                    this.setState({
                      showUpdateStatusModal: false,
                      preparationTime: "",
                    })
                  }
                >
                  <div
                    style={{
                      width: "450px",
                      height: "120px",
                      overflow: "auto",
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    <table width="430px" height="120px">
                      {this.state.order_cart
                        ? this.state.order_cart.items.map((item, i) => (
                            <div key={i}>
                              <h6
                                style={{
                                  marginLeft: "10px",
                                  paddingTop: "5px",
                                  fontFamily: "Arial,HelveticaNeue",
                                  color: "#6C7293",
                                }}
                              >{`${item.count}x ${item.name}`}</h6>
                              <ul>
                                {item.addons.map((addon, j) => (
                                  <li key={j}>{addon.name}</li>
                                ))}
                              </ul>
                              <Divider />
                            </div>
                          ))
                        : null}
                    </table>
                  </div>
                  <div
                    style={{
                      color: "#6C7293",
                      fontSize: 17,
                      width: 170,
                      height: 19,
                      fontFamily: "Arial,HelveticaNeue",
                    }}
                  >
                    Special Instructions
                  </div>
                  <br></br>
                  <div
                    style={{
                      color: "#6C7293",
                      fontSize: 15,
                      fontFamily: "Arial,HelveticaNeue",
                    }}
                  >
                    {this.state.order_cart ? (
                      <div>{this.state.order_cart.special_instruction}</div>
                    ) : null}
                  </div>
                  <br></br>
                  {this.state.orderStatus === "1" ||
                  this.state.orderStatus === "6" ? (
                    <div className="row">
                      <Icon
                        style={{
                          color: "#6C7293",
                          paddingTop: 10,
                          fontSize: 16,
                          marginLeft: "10px",
                        }}
                        type="history"
                      />
                      <label
                        style={{
                          paddingTop: 10,
                          marginLeft: "10px",
                          fontFamily: "Arial,HelveticaNeue",
                          color: "#6C7293",
                          fontWeight: 400,
                        }}
                      >
                        Preparation Time :{" "}
                      </label>
                      <div className="col-3">
                        <input
                          value={this.state.preparationTime}
                          onChange={(event) =>
                            this.setState({
                              preparationTime: event.target.value,
                            })
                          }
                          maxLength="3"
                          min="1"
                          max="240"
                          className="form-control"
                          type="number"
                        />
                      </div>
                      <div
                        style={{
                          paddingTop: 10,
                          marginLeft: "5px",
                          fontSize: 17,
                          fontFamily: "Arial,HelveticaNeue",
                          color: "#6C7293",
                          fontWeight: 200,
                        }}
                      >{`Min`}</div>
                      <p
                        style={{
                          marginLeft: "15px",
                          fontWeight: 100,
                          paddingTop: 10,
                          color: preparationTimeError ? "red" : "#6C7293",
                        }}
                      >
                        Please enter Preparation time
                      </p>
                    </div>
                  ) : null}
                  {this.renderStatusUpdateView(this.state.orderStatus)}
                </Modal>
              </div>
              <DialogModal
                visible={this.state.orderDetailVisible}
                onOk={() => this.handleOkCancel()}
                onCancel={() => this.handleOkCancel()}
                orderDetail={this.state.orderDetail}
              />

              {/* <Modal
                                title="Location Details"
                                visible={this.state.locationDetailVisible}
                                onOk={() => this.handleOkCancel()}
                                onCancel={() => this.handleOkCancel()}
                            >

                                <br></br><h2><center>Location details</center></h2>
                                <br></br>
                                <h4><b>Latitude: </b>{this.state.currentLat}</h4>
                                <h4><b>Longitude: </b>{this.state.currentLng}</h4>
                                <Map
                                    containerElement={<div style={{ height: '300px', width: '100%' }} />}
                                    mapElement={<div style={{ height: '100%' }} />}
                                    lat={this.state.currentLat}
                                    lng={this.state.currentLng}
                                    isMarkerShown={true}
                                />

                            </Modal> */}

              <Modal
                title="Manual Order"
                visible={this.state.manualOrderVisible}
                onOk={() => this.handleOk()}
                onCancel={() => this.handleCancel()}
              >
                <h1>Manual Order </h1>

                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    Time (in Minutes)
                  </label>
                  <div className="col-9">
                    <input
                      onChange={(event) =>
                        this.setState({ time: event.target.value })
                      }
                      maxLength="3"
                      className="form-control"
                      type="number"
                    />
                    <span className="form-text text-muted">
                      <p style={{ color: this.state.timeError ? "red" : "" }}>
                        Please enter time
                      </p>
                    </span>
                  </div>
                </div>
              </Modal>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
