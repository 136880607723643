import React from "react";
import { Icon, Popconfirm, Switch, Select, Spin } from "antd";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { errorNotification, errorToaster } from "../Shared/Notifications";

import SharedModel from "../../Models/SharedModel";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      countryList: [],
      loading: false,
      filters: [],
      pageCount: 10,
      offset: 0,
      total_pages: 1,
      currentPage: 1,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  createPagination(total_count) {
    this.setState({
      total_pages: Math.ceil(total_count / this.state.pageCount),
    });
  }

  componentDidMount() {
    this.getEcommerceSections();
  }

  getEcommerceSections() {
    const it = this;
    it.setState({ loading: true });
    console.log("filters:", this.state.filters);
    let params = {
      offset: (this.state.currentPage - 1) * this.state.pageCount,
      limit: this.state.pageCount,
    };
    this.state.filters.forEach((filter, i) => {
      params[filter.id] = filter.value.toLowerCase();
    });
    switch (params["is_active"]) {
      case "all":
        delete params["is_active"];
        break;

      case "true":
        params["is_active"] = true;
        break;

      case "false":
        params["is_active"] = false;
        break;

      default:
        break;
    }
    SharedModel.getInstance().getEcommerceSections(
      params,
      function success(data) {
        it.setState({
          data: SharedModel.EcommerceSectionList,
          loading: false,
        });
        it.createPagination(data.count);
      },
      function Failure(error) {
        it.setState({ loading: false });
        errorToaster("Request information not found");
      }
    );
  }

  updateBankBin(id, is_active) {
    const it = this;
    it.setState({ loading: true });
    const params = {
      is_active: !is_active,
    };
    SharedModel.getInstance().updateEcommerceSection(
      id,
      params,
      function success() {
        it.setState({
          data: SharedModel.EcommerceSectionList,
          loading: false,
        });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getEcommerceSections();
  }, 1000);

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon2-line-chart"></i>
              </span>
              <h3 className="kt-portlet__head-title">Feature Visibility</h3>
            </div>
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-wrapper">
                <div className="kt-portlet__head-actions">
                  {/* 
                                    <a href="bank-bin/add" className="btn btn-brand btn-elevate btn-icon-sm">
                                        <i className="la la-plus"></i>
										Add New Bank Bin
												</a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="kt-portlet__body">
            <Spin spinning={this.state.loading}>
              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                pageSize={this.state.pageCount}
                showPageSizeOptions={false}
                manual
                page={this.state.currentPage - 1}
                pages={this.state.total_pages}
                filtered={this.state.filters}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getEcommerceSections();
                  }, 50);
                }}
                onFilteredChange={(filters) => {
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={[
                  {
                    Header: <p style={tableHeaderStyles()}>App Name</p>,
                    id: "apps.name",
                    accessor: (d) => d.apps.name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                    filterable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "is_active",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "true") {
                        return row[filter.id] === true;
                      }
                      return row[filter.id] === false;
                    },
                    Filter: ({ filter, onChange }) => (
                      <Select
                        onChange={(value) => onChange(value)}
                        style={{ width: "100%" }}
                        defaultValue="all"
                        value={filter ? filter.value : "all"}
                      >
                        <Option value="all">Show All</Option>
                        <Option value="true">Active</Option>
                        <Option value="false">De-Activate</Option>
                      </Select>
                    ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Action</p>,
                    id: "actions",
                    width: 150,
                    accessor: (d) => (
                      <div>
                        {/* <Link
                                                    to={`city/edit/${d.id}`}
                                                    style={{ marginRight: 20 }}
                                                >
                                                    <Icon style={{ color: '#5D78FF', fontSize: 16 }} type="edit" />
                                                </Link> */}
                        <Popconfirm
                          title={
                            d.is_active
                              ? `Are you sure you want to DEACTIVATE E-Commerce in ${d.apps.name}`
                              : `Are you sure you want to ACTIVATE E-Commerce in ${d.apps.name}`
                          }
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.updateBankBin(d.id, d.is_active)
                          }
                        >
                          <Switch
                            style={{
                              backgroundColor: d.is_active
                                ? "#5d78ff"
                                : "#fd397a",
                              marginLeft: 15,
                            }}
                            checked={d.is_active}
                            onChange={(val) => console.log(val)}
                          />
                        </Popconfirm>
                      </div>
                    ),
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}
