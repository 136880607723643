import React from "react";
import { Select, Icon, Spin, Popconfirm, Switch, Tabs } from "antd";
import { getCompressedImage } from "Helpers/Shared/utils";
import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";

import CollectionModel from "../../Models/CollectionModel";

import backImage from "../../../src/assets/media/users/default.jpg";
import SharedModel from "../../Models/SharedModel";
import MerchantModel from "../../Models/MerchantModel";
import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import 'react-table/react-table.css';


const { Option } = Select;
const { TabPane } = Tabs;

export default class EditCollection extends React.Component {
  constructor() {
    super();
    this.state = {
      iconImage: null,
      iconImageError: false,
      iconImageSrc: null,
      name: "",
      nameError: false,
      position: 0,
      positionError: false,
      collectionId: null,
      serverImageSrc: null,
      merchantList: [],
      merchant: [],
      appList: [],
      appId: []
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    const it = this;
    const {
      match: { params },
    } = this.props;
    this.getAllAppId();
    it.setState({ loading: true });
    this.setState({ collectionId: params.collectionId });
    CollectionModel.getInstance().getCollectionDetail(
      params.collectionId,
      function success(data) {
        it.setState({
          loading: false,
          name: data.name,
          position: data.order,
          serverImageSrc: data.image,
          appId: data.app_collections.map(_app => (_app.app_id)),
          merchant: data.merchants
            ? data.merchants.map((merchant) => {
              return merchant.id;
            })
            : [],
          merchantList: data.merchants ? data.merchants : [],
        });
      }
    );
  }

  searchQueryMerchant = this.debounce(function (value, it) {
    it.getMerchants(value);
  }, 1000);

  getMerchants(value) {
    let it = this;
    let params = {};
    if (value.length > 0) {
      params.query = value;
    }
    MerchantModel.getInstance().getMerchant(
      params,
      function success(data) {
        it.setState({ merchantList: data.data });
      },
      function failure(error) {
        console.error(error);
      }
    );
  }

  getAllAppId() {
    let it = this;
    SharedModel.getInstance().getAllAppIds(
      function success(data) {
        it.setState({ appList: data });
      },
      function failure(error) {
        console.error(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  saveLogo(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ loading: true });
    getCompressedImage(file)
      .then((compressedImage) => {
        if (compressedImage) {
          this.setState({
            loading: false,
            iconImage: compressedImage,
            iconImageSrc: URL.createObjectURL(compressedImage),
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        errorNotification(e);
      });
  }

  sendData() {
    const { name, position, iconImage, collectionId, merchant } = this.state;
    let it = this;
    const text = "Validation Error! Please check fields";
    this.setState({
      iconImageError: false,
      nameError: false,
      positionError: false,
    });

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster(text);
      return;
    }
    if (!position) {
      this.setState({ positionError: true });
      errorToaster(text);
      return;
    }

    if (position < 1 || position > 20) {
      this.setState({ positionError: true });
      errorToaster(text);
      return;
    }

    CollectionModel.getInstance().updateCollection(
      collectionId,
      name,
      position,
      merchant,
      it.state.appId,
      function success(data) {
        if (iconImage) {
          it.addMedia(iconImage, collectionId);
        } else {
          successNotification("Collection Updated Successfully");
          setTimeout(() => {
            window.location.href = "/collection";
          }, 1000);
        }
      },
      function failure(error) {
        errorNotification(error);
        console.error(error);
      }
    );
  }

  addMedia(iconImage, id) {
    let it = this;
    if (iconImage) {
      let params = new FormData();
      params.append("image", iconImage);

      SharedModel.getInstance().addMediaInstance(
        id,
        "collection",
        params,
        function success() {
          successNotification("Collection Updated Successfully");
          setTimeout(() => {
            window.location.href = "/collection";
          }, 1000);
        },
        function failure(error) {
          errorNotification(error);
          it.setState({ loading: false });
          console.error(error);
        }
      );
    } else {
      it.setState({ loading: false });
      successNotification("Collection Updated Successfully");
      setTimeout(() => {
        window.location.href = "/collection";
      }, 1000);
    }
  }

  checkAppAssociated(app) {
    if (this.state.appId.includes(app)) return true;
    return false;
  }

  updateAssociation(app) {
    const association = this.checkAppAssociated(app);
    const [...apps] = this.state.appId;
    if (association) {
      const index = apps.findIndex(ele => (ele === app));
      apps.splice(index, 1);
      this.setState({ appId: apps });
    } else {
      apps.push(app);
      this.setState({ appId: apps });
    }
  }

  render() {
    const {
      iconImageSrc,
      nameError,
      positionError,
      iconImageError,
      serverImageSrc,
      loading,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <Spin spinning={loading}>
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">
                        Edit Collection
                      </h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/collection"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              {/* start here */}
                              <Tabs
                                defaultActiveKey="1"
                              >
                                <TabPane tab="Overview" key="1">
                                  <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label">
                                      Background Image <b>*</b>
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                      <div
                                        className="kt-avatar kt-avatar--outline kt-avatar--circle"
                                        id="kt_apps_user_add_avatar"
                                      >
                                        <div
                                          className="kt-avatar__holder"
                                          style={{
                                            backgroundImage: `url(${iconImageSrc
                                              ? iconImageSrc
                                              : serverImageSrc
                                                ? serverImageSrc
                                                : backImage
                                              })`,
                                          }}
                                        ></div>

                                        <label
                                          className="kt-avatar__upload"
                                          data-toggle="kt-tooltip"
                                          title=""
                                          data-original-title="Change avatar"
                                        >
                                          <i className="fa fa-pen"></i>
                                          <input
                                            onChange={(event) =>
                                              this.saveLogo(event)
                                            }
                                            type="file"
                                            name="kt_apps_user_add_user_avatar"
                                          />
                                        </label>
                                        <span
                                          className="kt-avatar__cancel"
                                          data-toggle="kt-tooltip"
                                          title=""
                                          data-original-title="Cancel avatar"
                                        >
                                          <i className="fa fa-times"></i>
                                        </span>
                                        <p
                                          style={{
                                            color: "red",
                                            display: iconImageError
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          Please Upload Icon Image
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Name <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <input
                                        maxLength="70"
                                        onChange={(event) =>
                                          this.setState({
                                            name: event.target.value,
                                          })
                                        }
                                        value={this.state.name}
                                        className="form-control"
                                        type="text"
                                      />
                                      <span
                                        style={{ color: nameError ? "red" : "" }}
                                        className="form-text text-muted"
                                      >
                                        <p
                                          style={{ color: nameError ? "red" : "" }}
                                        >
                                          Please enter name
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Position <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <input
                                        maxLength="100"
                                        onChange={(event) =>
                                          this.setState({
                                            position: event.target.value,
                                          })
                                        }
                                        value={this.state.position}
                                        className="form-control"
                                        type="number"
                                      />
                                      <span
                                        style={{
                                          color: positionError ? "red" : "",
                                        }}
                                        className="form-text text-muted"
                                      >
                                        <p
                                          style={{
                                            color: positionError ? "red" : "",
                                          }}
                                        >
                                          Please enter position in between 1-20
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-3 col-form-label"> Merchants</label>
                                    <div className="col-9">
                                      <Select
                                        showSearch
                                        // allowClear
                                        mode="multiple"
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        // placeholder="Select a merchant group"
                                        value={this.state.merchant}
                                        onChange={(value) =>
                                          this.setState({ merchant: value })
                                        }
                                        notFoundContent={
                                          <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          >
                                            <Icon style={{
                                              fontSize: 16,
                                              marginRight: "10px",
                                              marginBottom: "5px",
                                            }}
                                              type="search"
                                            />
                                            <p style={{ fontSize: 14 }}>Please Search Merchants</p>
                                          </div>
                                        }
                                        onSearch={(val) =>
                                          this.searchQueryMerchant(val, this)
                                        }
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {this.state.merchantList.map((merchant) => (
                                          <Option
                                            key={merchant.id}
                                            value={merchant.id}
                                          >
                                            {merchant.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <span
                                        style={{
                                          color: positionError ? "red" : "",
                                        }}
                                        className="form-text text-muted"
                                      >
                                        <p
                                          style={{
                                            color: positionError ? "red" : "",
                                          }}
                                        >
                                          Please enter position in between 1-20
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tab="Apps" key="2">
                                  {/* CREATE DATA GRID HERE */}

                                  <ReactTable
                                    data={this.state.appList}
                                    pageSize={this.state.appList.length}
                                    filterable={false}
                                    defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}
                                    columns={[{
                                      Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Name</p>,
                                      id: "name",
                                      accessor: d => d.name,
                                      filterMethod: (filter, rows) =>
                                        matchSorter(rows, filter.value, { keys: ["name"] }),
                                      filterable: false
                                    },
                                    {
                                      Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Status</p>,
                                      id: "id",
                                      accessor: "id",
                                      filterable: false,
                                      Cell: ({ value, row }) => (
                                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                          <Popconfirm
                                            title={this.checkAppAssociated(value) ? "Are you sure you want to UNLINK this App" : "Are you sure you want to LINK this App"}
                                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                            onConfirm={() => this.updateAssociation(value)}
                                          >
                                            <Switch style={{ backgroundColor: this.checkAppAssociated(value) ? '#5d78ff' : '#fd397a', marginLeft: 15 }} checked={this.checkAppAssociated(value)} />
                                          </Popconfirm>
                                          {this.checkAppAssociated(value) ? <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">LINKED</span> : <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">UN-LINKED</span>}
                                        </div>
                                      ),


                                    },
                                    ]}
                                    className="-striped -highlight"
                                  />
                                </TabPane>
                              </Tabs>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
