import UserModel from "../../Models/UserModel";
import moment from 'moment';

export default class NetworkHandler {
  static myInstance = null;
  static getInstance() {
    if (NetworkHandler.myInstance === null) {
      NetworkHandler.myInstance = new NetworkHandler();
    }
    return this.myInstance;
  }

  timeout(promise) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        reject('Api Taking Too long to respond Please try Again')
      }, 60000)
      promise.then(resolve, reject)
    })
  }

  async getNetworkRequest(url, token, serviceParams, successCallback, failureCallback) {
    if (serviceParams != null) {
      url = url + '?'
      for (const [key, value] of Object.entries(serviceParams)) {
        url = url + key + '=' + value + '&'
      }
      url = url.slice(0, -1)
    }

    checkRefreshToken(url,
      function success(data) {
        let _token = UserModel.getInstance().getToken();
        NetworkHandler.getInstance().timeout(
          fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'Application/json',
              'Authorization': `Bearer ${_token}`,
              "MBQDEBUG": "MyBookQatar",
              'cache-control': 'no-cache'
            },
          }))
          .then((response) => {
            if (response.status === 401) {
              UserModel.getInstance().signOut();
            }
            return response.json()
          })
          .then(responseJson => {
            if (responseJson.status === true) {
              successCallback(responseJson.data);
            } else {
              failureCallback(responseJson.message)
            }
          })
          .catch(error => {

            failureCallback(error);
          });
      }
    )

  }

  async deleteNetworkRequest(url, token, serviceParams, successCallback, failureCallback) {
    if (serviceParams != null) {
      url = url + '?'
      for (const [key, value] of Object.entries(serviceParams)) {
        url = url + key + '=' + value + '&'
      }
      url = url.slice(0, -1)
    }
    checkRefreshToken(url,
      function success(data) {
        let _token = UserModel.getInstance().getToken();
        NetworkHandler.getInstance().timeout(
          fetch(url, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'Application/json',
              'Authorization': `Bearer ${_token}`,
              "MBQDEBUG": "MyBookQatar"
            },
          }))
          .then((response) => {
            if (response.status === 401) {
              UserModel.getInstance().signOut();
            }
            return response.json()
          })
          .then(responseJson => {
            if (responseJson.status === true) {
              successCallback(responseJson.data);
            } else {
              failureCallback(responseJson.message)
            }
          })
          .catch(error => {
            failureCallback(error);
          });
      }
    )

  }

  async updateNetworkRequest(url, token, serviceParams, successCallback, failureCallback) {
    checkRefreshToken(url,
      function success(data) {
        let _token = UserModel.getInstance().getToken();
        NetworkHandler.getInstance().timeout(
          fetch(url, {
            method: 'PUT',
            headers: {
              'Content-Type': 'Application/json',
              'Authorization': `Bearer ${_token}`,
              "MBQDEBUG": "MyBookQatar"
            },
            body: JSON.stringify(serviceParams)
          }))
          .then((response) => {
            if (response.status === 401) {
              UserModel.getInstance().signOut();
            }
            return response.json()
          })
          .then(responseJson => {
            if (responseJson.status === true) {
              successCallback(responseJson.data);
            } else {
              failureCallback(responseJson.message)
            }
          })
          .catch(error => {

            failureCallback(error);
          });
      }
    )

  }

  async postNetworkRequest(url, token, serviceParams, successCallback, failureCallback) {
    checkRefreshToken(url,
      function success(data) {
        let _token = UserModel.getInstance().getToken();
        NetworkHandler.getInstance().timeout(
          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'Application/json',
              'Authorization': token ? `Bearer ${_token}` : null,
              "MBQDEBUG": "MyBookQatar"
            },
            body: JSON.stringify(serviceParams)
          }))
          .then((response) => {
            if (response.status === 401) {
              UserModel.getInstance().signOut();
            }
            return response.json()
          })
          .then(responseJson => {
            if (responseJson.status === true) {
              successCallback(responseJson.data);
            } else {
              failureCallback(responseJson.message)
            }
          })
          .catch(error => {
            failureCallback(error);
          });
      })


  }


  async postFormRequest(url, token, serviceParams, successCallback, failureCallback) {
    checkRefreshToken(url,
      function success(data) {
        let _token = UserModel.getInstance().getToken();
        NetworkHandler.getInstance().timeout(
          fetch(url, {
            method: 'POST',
            headers: {
              'Authorization': token ? `Bearer ${_token}` : null,
              'MBQDEBUG': 'MyBookQatar'
            },
            body: serviceParams
          }))
          .then((response) => {
            if (response.status === 401) {
              UserModel.getInstance().signOut();
            }
            return response.json()
          })
          .then(responseJson => {
            if (responseJson.status === true) {
              successCallback(responseJson.data);
            } else {
              failureCallback(responseJson.message)
            }
          })
          .catch(error => {
            failureCallback(error);
          });
      }
    )

  }

  async getDownloadUrl(url, token, serviceParams, fileName, successCallback, failureCallback) {
    if (serviceParams != null) {
      url = url + '?'
      for (const [key, value] of Object.entries(serviceParams)) {
        url = url + key + '=' + value + '&'
      }
      url = url.slice(0, -1)
    }
    checkRefreshToken(url,
      function success(data) {
        let _token = UserModel.getInstance().getToken();
        fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${_token}`,
            "MBQDEBUG": "MyBookQatar"
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            if (blob.type === 'text/csv') {
              // 2. Create blob link to download
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              // 3. Append to html page
              document.body.appendChild(link);
              // 4. Force download
              link.click();
              // 5. Clean up and remove the link
              link.parentNode.removeChild(link);
              successCallback('Preparing Download');
            } else {
              failureCallback('data not found');
            }


          })
          .catch(error => {
            failureCallback(error);
          });
      }
    )
  }

}

async function checkRefreshToken(url, callback) {
  try {
    let expiry = localStorage.getItem('token-expiry');
    expiry = expiry ? expiry : 0;
    let refresh_token = localStorage.getItem("refresh-token");
    if (refresh_token && !url.includes("/auth/token") && moment().unix() >= Number(expiry)) {
      await UserModel.getInstance().refreshUserToken(
        function success() {
          setTimeout(() => {
            callback("AUTO SUCCESS");
          }, 100);

        },
        function failure() {
          callback("AUTO FAILURE");
        }
      )
    } else {
      callback("AUTO ELSE");
    }
  } catch (err) {
    callback('system error');
  }
}