
// get Header for the Table
// move these to 
export function tableHeaderStyles() {
  return (
    {
      fontWeight: "500",
      borderBottomWidth: "1px",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      fontSize: 15
    }
  );
}
