import React from "react";
// import {
//     Select
// } from 'antd';

import UserModel from "../../Models/UserModel";
// import MerchantModel from '../../Models/MerchantModel';

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
// import backImage from '../../../src/assets/media/users/default.jpg';

// const { Option } = Select;

export default class AddMerchantGroup extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      nameError: false,
      arb_name: "",
      arb_nameError: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  sendData() {
    const { name, arb_name } = this.state;
    let it = this;
    const text = "Validation Error! Please check fields";
    this.setState({ nameError: false, arb_nameError: false });

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster(text);
      return;
    }

    UserModel.getInstance().addMerchantGroup(
      name,
      arb_name,
      function success(data) {
        successNotification("Merchant Group Created Successfully");
        setTimeout(() => {
          window.location.href = "/merchant-group";
        }, 1000);
      },
      function failure(error) {
        errorNotification(error);
        console.log(error);
      }
    );
  }

  render() {
    const { nameError, arb_nameError } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                <div className="kt-portlet__head kt-portlet__head--lg">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">
                      Add Merchant Group
                    </h3>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                    <a
                      href="/merchant-group"
                      className="btn btn-clean kt-margin-r-10"
                    >
                      <i className="la la-arrow-left"></i>
                      <span className="kt-hidden-mobile">Back</span>
                    </a>
                    <div className="btn-group">
                      <button
                        onClick={() => this.sendData()}
                        type="button"
                        className="btn btn-brand"
                      >
                        <i className="la la-check"></i>
                        <span className="kt-hidden-mobile">Save</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* end header */}
                <div className="kt-portlet__body">
                  <form className="kt-form" id="kt_form">
                    <div className="row">
                      <div className="col-xl-2"></div>
                      <div className="col-xl-8">
                        <div className="kt-section kt-section--first">
                          <div className="kt-section__body">
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Name <b>*</b>
                              </label>
                              <div className="col-9">
                                <input
                                  maxLength="70"
                                  onChange={(event) =>
                                    this.setState({ name: event.target.value })
                                  }
                                  className="form-control"
                                  type="text"
                                />
                                <span
                                  style={{ color: nameError ? "red" : "" }}
                                  className="form-text text-muted"
                                >
                                  <p style={{ color: nameError ? "red" : "" }}>
                                    Please enter name in English
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Arabic Name
                              </label>
                              <div className="col-9">
                                <input
                                  maxLength="100"
                                  onChange={(event) =>
                                    this.setState({
                                      arb_name: event.target.value,
                                    })
                                  }
                                  className="form-control"
                                  type="text"
                                />
                                <span
                                  style={{ color: arb_nameError ? "red" : "" }}
                                  className="form-text text-muted"
                                >
                                  <p
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                  >
                                    Please enter name in Arabic
                                  </p>
                                </span>
                              </div>
                            </div>
                            {/* 
                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Merchants</label>
                                                            <div className="col-9">
                                                                <Select
                                                                    showSearch
                                                                    mode="multiple"
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Please select"
                                                                    onChange={(val) => this.setState({ merchants: val })}
                                                                    filterOption={(input, option) =>
                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                >
                                                                    {merchantList.map((merch) => (
                                                                        <Option key={merch.id} value={merch.id}>{merch.name}</Option>
                                                                    ))}
                                                                </Select>
                                                                <span className="form-text text-muted"><p>Please select merchants</p></span>

                                                            </div>
                                                        </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2"></div>
                    </div>
                  </form>
                </div>
                {/* end body */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
