import React from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";

import Dashboard from "Containers/Dashboard";

import Category from "Containers/Category";
import AddCategory from "Containers/Category/AddCategory";
import EditCategory from "Containers/Category/EditCategory";

import Merchant from "Containers/Merchant";
import AddMerchant from "Containers/Merchant/AddMerchant";
import EditMerchant from "Containers/Merchant/EditMerchant";

import Outlet from "Containers/Outlet";
import AddOutlet from "Containers/Outlet/AddOutlet";
import EditOutlet from "Containers/Outlet/EditOutlet";

import MerchantTag from "Containers/MerchantTag";
import EditMerchantTag from "Containers/MerchantTag/EditMerchantTag";
import AddMerchantTag from "Containers/MerchantTag/AddMerchantTag";

import MerchantGroup from "Containers/MerchantGroup";
import EditMerchnatGroup from "Containers/MerchantGroup/EditMerchnatGroup";
import AddMerchantGroup from "Containers/MerchantGroup/AddMerchantGroup";

import MerchantInfoTag from "Containers/MerchantInfoTag";
import AddMerchantInfoTag from "Containers/MerchantInfoTag/AddMerchantInfoTag";
import EditMerchantinfoTag from "Containers/MerchantInfoTag/EditMerchantinfoTag";

import Province from "Containers/Province";
import AddProvince from "Containers/Province/AddProvince";
import EditProvince from "Containers/Province/EditProvince";

import City from "Containers/City";
import AddCity from "Containers/City/AddCity";
import EditCity from "Containers/City/EditCity";

import Area from "Containers/Area";
import AddArea from "Containers/Area/AddArea";
import EditArea from "Containers/Area/EditArea";

import AddLandmark from "Containers/Landmark/AddLandmark";
import EditLandmark from "Containers/Landmark/EditLandmark";
import Landmark from "Containers/Landmark";

import Users from "Containers/Users";
import AddUser from "Containers/Users/AddUser";
import EditUser from "Containers/Users/EditUser";

import Customers from "Containers/Customers";
import EditCustomer from "Containers/Customers/EditCustomer";

import AddPaymentMethods from "Containers/PaymentMethods/AddPaymentMethods";
import PaymentMethods from "Containers/PaymentMethods";
import EditPayment from "Containers/PaymentMethods/EditPayment";

import Partner from "Containers/Partner";
import AddPartner from "Containers/Partner/AddPartner";
import EditPartner from "Containers/Partner/EditPartner";

import Collection from "Containers/Collection";
import AddCollection from "Containers/Collection/AddCollection";
import EditCollection from "Containers/Collection/EditCollection";

import Program from "Containers/Program";
import AddProgram from "Containers/Program/AddProgram";
import EditProgram from "Containers/Program/EditProgram";

import Offer from "Containers/Offer";
import AddOffer from "Containers/Offer/AddOffer";
import EditOffer from "Containers/Offer/EditOffer";

import Coupon from "Containers/Coupon";
import AddCoupon from "Containers/Coupon/AddCoupon";
import EditCoupon from "Containers/Coupon/EditCoupon";

import Notifications from "Containers/Notifications";
import AddNotification from "Containers/Notifications/AddNotification";
import EditNotification from "Containers/Notifications/EditNotification";

import Item from "Containers/Item";
import AddItem from "Containers/Item/AddItem";
import EditItem from "Containers/Item/EditItem";

import ItemGroups from "Containers/ItemGroups";
import AddItemGroups from "Containers/ItemGroups/AddItemGroup";
import EditItemGroups from "Containers/ItemGroups/EditItemGroup";

import Addon from "Containers/Addon";
import AddAddon from "Containers/Addon/AddAddon";
import EditAddon from "Containers/Addon/EditAddon";

import AddonSet from "Containers/AddonSets";
import AddAddonSet from "Containers/AddonSets/AddAddonSet";
import EditAddonSet from "Containers/AddonSets/EditAddonSet";

import EcommerceOffer from "Containers/EcommerceOffer";
import AddEcommerceOffer from "Containers/EcommerceOffer/AddEcommerceOffer";
import EditEcommerceOffer from "Containers/EcommerceOffer/EditEcommerceOffer";

import ValueType from "Containers/ValueTypes";
import AddValueType from "Containers/ValueTypes/AddValueType";
import EditValueType from "Containers/ValueTypes/EditValueType";

import CardSet from "Containers/CardSet";
import AddCardSet from "Containers/CardSet/AddCardSet";
import EditCardSet from "Containers/CardSet/EditCardSet";

import RedemptionReport from "Containers/Report/Redemption";
import PaymentReport from "Containers/Report/Payment";
import OrderReport from "Containers/Report/Order";
import DeliveryReports from "Containers/Report/DeliveryReports";

import Card from "Containers/Card/Card";
//order detail
import OrderDetail from "Containers/Shared/OrderDetail";

import MerchantOutlet from "Containers/MerchantOutlet/Content";
import AddMerchantOutlet from "Containers/MerchantOutlet/AddMerchantOutlet";
import EditMerchantOutlet from "Containers/MerchantOutlet/EditMerchantOutlet";

import OutletItemList from "Containers/OutletItem/Content";
import AddOutletItem from "Containers/OutletItem/AddOutletItem";
import EditOutletItem from "Containers/OutletItem/EditOutletItem";

import BankBin from "Containers/BankBin";
import AddBankBin from "Containers/BankBin/AddBankBin";
import AppEcommerce from "Containers/AppEcommerce";

import AppAdBanner from "Containers/AppAdBanners";
import AddAppBanner from "Containers/AppAdBanners/AddAppBanner";
import EditAppBanner from "Containers/AppAdBanners/EditAppBanner";

import AppVisibilitySetting from "Containers/AppVisibilitySetting";
import AddAppVisibility from "Containers/AppVisibilitySetting/AddAppVisibility";

import ProductVisibility from "Containers/ProductVisibility";
import AddProductVisibility from "Containers/ProductVisibility/AddProductVisibility";
import EditProductVisibility from "Containers/ProductVisibility/EditProductVisibility";

import ProductOnlineDeal from "Containers/ProductOnlineDeals";
import AddProductOnlineDeal from "Containers/ProductOnlineDeals/AddProductOnlineDeals";
import EditProductOnlineDeal from "Containers/ProductOnlineDeals/EditProductOnlineDeals";

import Classifications from "Containers/Classifications";
import AddClassifications from "Containers/Classifications/AddClassifications";
import EditClassifications from "Containers/Classifications/EditClassifications";

import OpeningHour from "Containers/OpeningHour";
import AddOpeningHour from "Containers/OpeningHour/AddOpeningHour";
import EditOpeningHour from "Containers/OpeningHour/EditOpeningHour";

import ProductBooking from "Containers/ProductBooking";
import AddProductBooking from "Containers/ProductBooking/AddProductBooking";
import EditProductBooking from "Containers/ProductBooking/EditProductBooking";
import ProductBookingConfigData from "Containers/ProductBooking/ProductBookingConfigData";
import ProductBookingAddonsDetail from "Containers/ProductBooking/ProductBookingAddonsDetail";
import ProductBookingReport from "Containers/ProductBookingReports/ProductBookingReports";
import BookingDetail from "Containers/ProductBookingReports/BookingDetail";

import AppProgramPayment from "Containers/AppProgramPayment";
import AddAppProgramPayment from "Containers/AppProgramPayment/AddAppProgramPayment";
import EditAppProgramPayment from "Containers/AppProgramPayment/EditAppProgramPayment";

import ProductBookingOffers from "Containers/ProductBookingOffers";
import AddProductBookingOffers from "Containers/ProductBookingOffers/AddProductBookingOffers";
import EditProductBookingOffers from "Containers/ProductBookingOffers/EditProductBookingOffers";

import ProductOrderTimeSlot from "Containers/ProductOrderTimeSlot";
import AddProductOrderTimeSlot from "Containers/ProductOrderTimeSlot/AddProductOrderTimeSlot";
import EditProductOrderTimeSlot from "Containers/ProductOrderTimeSlot/EditProductOrderTimeSlot";

import VodafoneSupport from "Containers/VodafoneSupport"

// PRODUCT PRICING
import ProductPricingDetailData from "./Containers/ProductPricing/ProductPricingDetailData"

// Static Pages
import Static from "Containers/Static";
import EditPage from "Containers/Static/EditPage";

import Login from "Containers/Auth/Login";
import Forgot from "Containers/Auth/Forgot";
import ChangePassword from "Containers/Auth/ChangePassword";
import ResetCode from "Containers/Auth/ResetCode";
import NewPassword from "Containers/Auth/NewPassword";
import NotFound from "Containers/Shared/NotFoundPage";

import Layout from "Containers/Shared/Layout";

import UserModel from "Models/UserModel";

class Routes extends React.Component {
  render() {
    return (
      <Router>
        <Layout isAuth={UserModel.getInstance().isAuth()}>
          <Switch>
            <Route
              exact
              path="/"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Redirect to="/dashboard" />
                )
              }
            />
            <Route
              exact
              path="/dashboard"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Dashboard />
                )
              }
            />
            <Route
              exact
              path="/login"
              render={() =>
                UserModel.getInstance().isAuth() ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <Login />
                )
              }
            />

            <Route
              exact
              path="/forgot"
              render={() =>
                UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Forgot />
                )
              }
            />

            <Route
              exact
              path="/reset-code"
              render={() =>
                UserModel.getInstance().isAuth() ? (
                  <Redirect to="/forgot" />
                ) : (
                  <ResetCode />
                )
              }
            />

            <Route
              exact
              path="/new-password"
              render={() =>
                UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <NewPassword />
                )
              }
            />

            <Route
              exact
              path="/change-password"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ChangePassword />
                )
              }
            />
            <Route
              exact
              path="/category"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Category />
                )
              }
            />
            <Route
              exact
              path="/customer"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Customers />
                )
              }
            />
            <Route
              exact
              path="/customer/edit/:customerId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditCustomer {...props} />
                )
              }
            />
            <Route
              exact
              path="/users"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Users />
                )
              }
            />
            <Route
              exact
              path="/user/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddUser />
                )
              }
            />
            <Route
              exact
              path="/user/edit/:userId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditUser {...props} />
                )
              }
            />
            <Route
              exact
              path="/category/add"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddCategory {...props} />
                )
              }
            />
            <Route
              exact
              path="/category/edit/:categoryId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditCategory {...props} />
                )
              }
            />

            <Route
              exact
              path="/merchant/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddMerchant />
                )
              }
            />

            <Route
              exact
              path="/merchant"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Merchant />
                )
              }
            />
            <Route
              exact
              path="/merchant/edit/:merchantId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditMerchant {...props} />
                )
              }
            />

            <Route
              exact
              path="/outlet/add/:merchantId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddOutlet {...props} />
                )
              }
            />

            <Route
              exact
              path="/outlet"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Outlet />
                )
              }
            />
            <Route
              exact
              path="/outlet/edit/:outletId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditOutlet {...props} />
                )
              }
            />

            <Route
              exact
              path="/merchant-tag"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <MerchantTag />
                )
              }
            />
            <Route
              exact
              path="/merchant-tag/add"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddMerchantTag {...props} />
                )
              }
            />
            <Route
              exact
              path="/merchant-tag/edit/:merchantTagId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditMerchantTag {...props} />
                )
              }
            />
            <Route
              exact
              path="/merchant-group"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <MerchantGroup />
                )
              }
            />
            <Route
              exact
              path="/merchant-group/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddMerchantGroup />
                )
              }
            />

            <Route
              exact
              path="/merchant-group/edit/:merchantGroupId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditMerchnatGroup {...props} />
                )
              }
            />

            <Route
              exact
              path="/merchant-info-tag"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <MerchantInfoTag />
                )
              }
            />
            <Route
              exact
              path="/merchant-info-tag/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddMerchantInfoTag />
                )
              }
            />
            <Route
              exact
              path="/merchant-info-tag/edit/:merchantInfoTagId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditMerchantinfoTag {...props} />
                )
              }
            />

            <Route
              exact
              path="/province"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Province />
                )
              }
            />
            <Route
              exact
              path="/province/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddProvince />
                )
              }
            />
            <Route
              exact
              path="/province/edit/:provinceId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditProvince {...props} />
                )
              }
            />

            <Route
              exact
              path="/city"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <City />
                )
              }
            />
            <Route
              exact
              path="/city/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddCity />
                )
              }
            />
            <Route
              exact
              path="/city/edit/:cityId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditCity {...props} />
                )
              }
            />

            <Route
              exact
              path="/area"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Area />
                )
              }
            />

            <Route
              exact
              path="/area/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddArea />
                )
              }
            />

            <Route
              exact
              path="/area/edit/:areaId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditArea {...props} />
                )
              }
            />

            <Route
              exact
              path="/landmark"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Landmark />
                )
              }
            />
            <Route
              exact
              path="/landmark/add"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddLandmark {...props} />
                )
              }
            />
            <Route
              exact
              path="/landmark/edit/:landmarkId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditLandmark {...props} />
                )
              }
            />

            <Route
              exact
              path="/payment"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <PaymentMethods />
                )
              }
            />
            <Route
              exact
              path="/payment/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddPaymentMethods />
                )
              }
            />
            <Route
              exact
              path="/payment/edit/:paymentId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditPayment {...props} />
                )
              }
            />

            <Route
              exact
              path="/collection"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Collection />
                )
              }
            />
            <Route
              exact
              path="/collection/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddCollection />
                )
              }
            />

            <Route
              exact
              path="/collection/edit/:collectionId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditCollection {...props} />
                )
              }
            />
            {/* <Route exact path="/collection/associate/:associateId"
                            render={(props) => (
                                !UserModel.getInstance().isAuth() ?
                                    (<Redirect to="/login" />) : (<CollectionAssociation {...props} />)
                            )} /> */}

            <Route
              exact
              path="/partner"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Partner />
                )
              }
            />
            <Route
              exact
              path="/partner/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddPartner />
                )
              }
            />

            <Route
              exact
              path="/partner/edit/:partnerId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditPartner {...props} />
                )
              }
            />

            <Route
              exact
              path="/program"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Program />
                )
              }
            />
            <Route
              exact
              path="/program/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddProgram />
                )
              }
            />

            <Route
              exact
              path="/program/edit/:programId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditProgram {...props} />
                )
              }
            />

            {/* <Route exact path="/program/associate/:programId"
                            render={(props) => (
                                !UserModel.getInstance().isAuth() ?
                                    (<Redirect to="/login" />) : (<ProgramAssociate {...props} />)
                            )}
                        /> */}

            <Route
              exact
              path="/offer/:page"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Offer />
                )
              }
            />
            <Route
              exact
              path="/offer/:page/add"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddOffer {...props} />
                )
              }
            />

            <Route
              exact
              path="/offer/:page/edit/:offerId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditOffer {...props} />
                )
              }
            />

            <Route
              exact
              path="/coupon"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Coupon />
                )
              }
            />
            <Route
              exact
              path="/coupon/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddCoupon />
                )
              }
            />

            <Route
              exact
              path="/coupon/edit/:couponId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditCoupon {...props} />
                )
              }
            />

            <Route
              exact
              path="/notifications"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Notifications />
                )
              }
            />
            <Route
              exact
              path="/notifications/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddNotification />
                )
              }
            />

            <Route
              exact
              path="/notifications/edit/:notificationId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditNotification {...props} />
                )
              }
            />

            <Route
              exact
              path="/redemption-report"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <RedemptionReport />
                )
              }
            />

            <Route
              exact
              path="/payment-report"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <PaymentReport />
                )
              }
            />

            <Route
              exact
              path="/order-report"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <OrderReport />
                )
              }
            />

            <Route
              exact
              path="/delivery-report"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <DeliveryReports />
                )
              }
            />

            <Route
              exact
              path="/order/:orderId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <OrderDetail {...props} />
                )
              }
            />

            <Route
              exact
              path="/item"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Item />
                )
              }
            />
            <Route
              exact
              path="/item/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddItem />
                )
              }
            />
            <Route
              exact
              path="/item/edit/:itemId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditItem {...props} />
                )
              }
            />

            <Route
              exact
              path="/item-group"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ItemGroups />
                )
              }
            />
            <Route
              exact
              path="/item-group/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddItemGroups />
                )
              }
            />
            <Route
              exact
              path="/item-group/edit/:itemGroupId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditItemGroups {...props} />
                )
              }
            />

            <Route
              exact
              path="/addon"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Addon />
                )
              }
            />
            <Route
              exact
              path="/addon/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddAddon />
                )
              }
            />
            <Route
              exact
              path="/addon/edit/:addonId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditAddon {...props} />
                )
              }
            />

            <Route
              exact
              path="/addon-set"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddonSet />
                )
              }
            />
            <Route
              exact
              path="/addon-set/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddAddonSet />
                )
              }
            />
            <Route
              exact
              path="/addon-set/edit/:addonSetId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditAddonSet {...props} />
                )
              }
            />

            <Route
              exact
              path="/ecommerce-offer"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EcommerceOffer />
                )
              }
            />
            <Route
              exact
              path="/ecommerce-offer/add"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddEcommerceOffer {...props} />
                )
              }
            />
            <Route
              exact
              path="/ecommerce-offer/edit/:ecommerceOfferId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditEcommerceOffer {...props} />
                )
              }
            />

            <Route
              exact
              path="/value-type"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ValueType />
                )
              }
            />
            <Route
              exact
              path="/value-type/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddValueType />
                )
              }
            />

            <Route
              exact
              path="/value-type/edit/:valueTypeId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditValueType {...props} />
                )
              }
            />

            <Route
              exact
              path="/card-set"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <CardSet />
                )
              }
            />
            <Route
              exact
              path="/card-set/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddCardSet />
                )
              }
            />

            <Route
              exact
              path="/card-set/edit/:cardSetId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditCardSet {...props} />
                )
              }
            />

            <Route
              exact
              path="/card/:cardSetId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Card {...props} />
                )
              }
            />

            <Route
              exact
              path="/merchant/outlet/:merchantId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <MerchantOutlet {...props} />
                )
              }
            />
            <Route
              exact
              path="/merchant/outlet/:merchantId/add"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddMerchantOutlet {...props} />
                )
              }
            />
            <Route
              exact
              path="/merchant/outlet/:merchantId/edit/:outletId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditMerchantOutlet {...props} />
                )
              }
            />

            <Route
              exact
              path="/outlet/item/:outletId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <OutletItemList {...props} />
                )
              }
            />

            <Route
              exact
              path="/outlet/item/:outletId/add"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddOutletItem {...props} />
                )
              }
            />

            <Route
              exact
              path="/outlet/item/:outletId/edit/:itemId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditOutletItem {...props} />
                )
              }
            />

            <Route
              exact
              path="/bank-bin"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <BankBin />
                )
              }
            />
            <Route
              exact
              path="/bank-bin/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddBankBin />
                )
              }
            />

            <Route
              exact
              path="/app-ecommerce"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AppEcommerce />
                )
              }
            />

            <Route
              exact
              path="/app-ad-banner"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AppAdBanner />
                )
              }
            />
            <Route
              exact
              path="/app-ad-banner/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddAppBanner />
                )
              }
            />
            <Route
              exact
              path="/app-ad-banner/edit/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditAppBanner {...props} />
                )
              }
            />

            <Route
              exact
              path="/visibility-setting"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AppVisibilitySetting />
                )
              }
            />
            <Route
              exact
              path="/visibility-setting/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddAppVisibility />
                )
              }
            />

            {/* product visibility section */}

            <Route
              exact
              path="/product-visibility"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ProductVisibility />
                )
              }
            />
            <Route
              exact
              path="/product-visibility/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddProductVisibility />
                )
              }
            />

            <Route
              exact
              path="/product-visibility/edit/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditProductVisibility {...props} />
                )
              }
            />

            {/* product Online Deal section */}

            <Route
              exact
              path="/online-deal"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ProductOnlineDeal />
                )
              }
            />
            <Route
              exact
              path="/online-deal/add"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddProductOnlineDeal {...props} />
                )
              }
            />

            <Route
              exact
              path="/online-deal/edit/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditProductOnlineDeal {...props} />
                )
              }
            />


            {/* Classifications section */}

            <Route
              exact
              path="/classifications"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Classifications />
                )
              }
            />
            <Route
              exact
              path="/classifications/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddClassifications />
                )
              }
            />

            <Route
              exact
              path="/classifications/edit/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditClassifications {...props} />
                )
              }
            />

            {/* OPENING HOUR section */}

            <Route
              exact
              path="/opening-hour"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <OpeningHour />
                )
              }
            />
            <Route
              exact
              path="/opening-hour/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddOpeningHour />
                )
              }
            />

            <Route
              exact
              path="/opening-hour/edit/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditOpeningHour {...props} />
                )
              }
            />


            {/* product Booking section */}

            <Route
              exact
              path="/product-booking"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ProductBooking />
                )
              }
            />
            <Route
              exact
              path="/product-booking/add"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddProductBooking {...props} />
                )
              }
            />

            <Route
              exact
              path="/product-booking/edit/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditProductBooking {...props} />
                )
              }
            />

            <Route
              exact
              path="/booking-report"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ProductBookingReport {...props} />
                )
              }
            />

            <Route
              exact
              path="/booking-report/:bookingId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <BookingDetail {...props} />
                )
              }
            />

            <Route
              exact
              path="/product-booking/section/edit/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ProductBookingConfigData props={props} is_edit={true} />
                )
              }
            />

            <Route
              exact
              path="/product-booking/section/add/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ProductBookingConfigData props={props} is_edit={false} />
                )
              }
            />

            <Route
              exact
              path="/product-booking/pricing/edit/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ProductPricingDetailData props={props} is_edit={true} />
                )
              }
            />

            <Route
              exact
              path="/product-booking/pricing/add/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ProductPricingDetailData props={props} is_edit={false} />
                )
              }
            />

            <Route
              exact
              path="/product-booking/addons/edit/:product_booking_id/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ProductBookingAddonsDetail props={props} is_edit={true} />
                )
              }
            />

            <Route
              exact
              path="/product-booking/addons/add/:product_booking_id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ProductBookingAddonsDetail props={props} is_edit={false} />
                )
              }
            />

            {/* APP PROGRAM PAYMENT */}

            <Route
              exact
              path="/app-program-payment"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AppProgramPayment />
                )
              }
            />
            <Route
              exact
              path="/app-program-payment/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddAppProgramPayment />
                )
              }
            />

            <Route
              exact
              path="/app-program-payment/edit/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditAppProgramPayment {...props} />
                )
              }
            />

            {/* Product Booking Offers */}

            <Route
              exact
              path="/booking-offer"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ProductBookingOffers />
                )
              }
            />
            <Route
              exact
              path="/booking-offer/add"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddProductBookingOffers />
                )
              }
            />

            <Route
              exact
              path="/booking-offer/edit/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditProductBookingOffers {...props} />
                )
              }
            />

            {/* PRODUCT ORDER TIME SLOT */}

            <Route
              exact
              path="/order-time-slot"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <ProductOrderTimeSlot />
                )
              }
            />
            <Route
              exact
              path="/order-time-slot/add"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <AddProductOrderTimeSlot {...props} />
                )
              }
            />

            <Route
              exact
              path="/order-time-slot/edit/:id"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditProductOrderTimeSlot {...props} />
                )
              }
            />


            {/* VODAFONE SUPPORT */}

            <Route
              exact
              path="/vodafone-support"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <VodafoneSupport />
                )
              }
            />


            {/* STATIC PAGES SECTION */}

            <Route
              exact
              path="/static"
              render={() =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <Static />
                )
              }
            />

            <Route
              exact
              path="/static/edit/:pageId"
              render={(props) =>
                !UserModel.getInstance().isAuth() ? (
                  <Redirect to="/login" />
                ) : (
                  <EditPage {...props} />
                )
              }
            />

            <Route component={NotFound} />
          </Switch>
        </Layout>
      </Router>
    );
  }
}

export default Routes;
