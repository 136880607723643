/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";

import {
    Input,
    Spin,
    Select,
    Icon,
    Tabs,
    Switch
} from "antd";

import _ from "lodash"

import {
    successNotification,
    errorNotification,
    errorToaster
} from "../Shared/Notifications";

import BookingConfigData from "./BookingConfigData";
import ProductBookingAddonsDataTable from "./ProductBookingAddonsDataTable";
import { DetailPageHeaderWithButton } from "styles/detailPageHeader";

import SharedModel from "Models/SharedModel";
import MerchantModel from "Models/MerchantModel";
import ProductBookingModel from "Models/ProductBookingModel";
import AppPartnerProductPricingModel from "Models/AppPartnerProductPricingModel";
import { validateProductBookingParams } from "./helperFunctions";

// PRODUCT PRICE
import ProductPricingData from "../ProductPricing/ProductPricingData"

const { Option } = Select;
const { TabPane } = Tabs;




export default function EditProductProductBookings({ match, history }) {
    const { params } = match;
    const
        [loading, setLoading] = useState(false),
        [activeKey, setActiveKey] = useState("1"),
        [name, setName] = useState(""),
        [arabicName, setArabicName] = useState(""),
        [description, setDescription] = useState(""),
        [arabicDescription, setArabicDescription] = useState(""),
        [minimumBookingTime, setMinimumBookingTime] = useState(null),
        [configList, setConfigList] = useState([]),
        [defaultConfig, setDefaultConfig] = useState(undefined),
        [bookingAddonList, setBookingAddonList] = useState([]),
        [productPricingList, setProductPricingList] = useState([]),
        [merchantList, setMerchantList] = useState([]),
        [merchant, setMerchant] = useState(undefined),
        [allOutletSelected, setAllOutletSelected] = useState(false),
        [outletList, setOutletList] = useState([]),
        [outlet, setOutlet] = useState([]),
        [productVisibilityList, setProductVisibilityList] = useState([]),
        [productVisibility, setProductVisibility] = useState(undefined),
        [bookingAddonCount, setBookingAddonCount] = useState(0);


    useEffect(() => {
        const { id } = params;
        getProductBookingDetail(id);
        const filters = { offset: 0, limit: 20 }
        getProductBookingAddonsByProductBooking(filters)
    }, [params]);

    function getProductBookingDetail(id) {
        setLoading(true);
        ProductBookingModel.getInstance().getProductBooking(id,
            function success(data) {
                setName(data.name)
                setArabicName(data.arabic_name)
                setDescription(data.description)
                setArabicDescription(data.arabic_description)
                setProductVisibility(data.product_visibilities?.id)
                setProductVisibilityList(data.product_visibilities ? [data.product_visibilities] : []);
                setMinimumBookingTime(data.minimum_booking_time)
                setConfigList(data.configs.filter(x => x.is_default === false));
                setDefaultConfig(data.configs.find(x => x.is_default === true)?.id);
                setProductPricingList(data.pricings);
                setOutlet(data.outlet.map(_out => (_out.id)));
                setMerchant(data.outlet[0]?.merchant?.id)
                setMerchantList(data.outlet[0] && data.outlet[0].merchant ? [data.outlet[0].merchant] : []);
                setLoading(false);
                if (data.outlet[0]?.merchant?.id) getMerchantOutlets(data.outlet[0].merchant.id)

            },
            function failure(_err) {
                setLoading(false);
                errorNotification(_err)
            }
        )
    }


    function updateProductBooking() {
        const { id } = params;

        const payload = {
            name,
            arabic_name: arabicName,
            description: description,
            arabic_description: arabicDescription,
            minimum_booking_time: minimumBookingTime,
            outlet_id: outlet,
            product_visibility_id: productVisibility ? productVisibility : null,
        };

        const validationResult = validateProductBookingParams(payload);
        if (validationResult.status === false) {
            setActiveKey(validationResult.activeKey)
            errorToaster(validationResult.message);
            return
        }

        ProductBookingModel.getInstance().updateProductBooking(id, payload,
            function success(data) {
                successNotification("Product Booking Saved Successfully");
                setTimeout(() => {
                    setLoading(false);
                    history.goBack()
                }, 2000);
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    function removeConfig(index) {
        deleteProductBookingConfig(index)
    }

    function updateConfigValues(index, key, value) {
        let new_list = [...configList];
        new_list[index][key] = value;
        setConfigList(new_list);
    }


    function removeAddon(index) {
        deleteProductBookingAddon(index)
    }

    function updateAddonValues(index, key, value) {
        let new_list = [...bookingAddonList];
        new_list[index][key] = value;
        setBookingAddonList(new_list);
    }

    const getSearchMerchant = useCallback(_.debounce(value => getMerchants(value), 500), []);
    async function getMerchants(value) {
        setLoading(true);
        if (value.length > 0) {
            let params = {
                query: encodeURIComponent(value)
            }
            MerchantModel.getInstance().getMerchant(params,
                function success(data) {
                    setLoading(false);
                    setMerchantList(data.data)
                },
                function failure(error) {
                    setLoading(false);
                    errorNotification(error)
                }
            )
        }
    }



    const getSearchProducVisibility = useCallback(_.debounce(value => getAllProductVisibilities(value), 500), []);
    async function getAllProductVisibilities(value) {
        if (value.length > 0) {
            const payload = {
                name: encodeURIComponent(value)
            };
            SharedModel.getInstance().getAllProductVisibilities(payload,
                function success() {
                    setProductVisibilityList(SharedModel.ProductVisiblityList);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)

                }
            )
        }
    }


    // CONFIGS APIS
    function deleteProductBookingConfig(index) {
        setLoading(true)
        const { id } = configList[index];
        ProductBookingModel.getInstance().deleteProductBookingConfig(id,
            function success(data) {
                let list = [...configList]
                list.splice(index, 1);
                setConfigList(list);
                successNotification("Product Booking Section Removed")
                setLoading(false)
            },
            function failure(error) {
                errorNotification(JSON.stringify(error))
                setLoading(false)
            }
        )
    }

    function updateConfigActivation(index, is_active) {
        setLoading(true)
        const { id } = configList[index];
        const payload = { is_active };
        ProductBookingModel.getInstance().updateProductBookingConfig(id, payload,
            function success(data) {
                updateConfigValues(index, "is_active", is_active)
                successNotification("Product Booking Section Status Updated")
                setLoading(false)
            },
            function failure(error) {
                errorNotification(JSON.stringify(error))
                setLoading(false)
            }
        )
    }

    // ADDON APIS

    function getProductBookingAddonsByProductBooking(filters) {
        const { params } = match;
        setLoading(true);
        ProductBookingModel.getInstance().getProductBookingAddonsByProductBooking(params.id, filters,
            function success(data) {
                setLoading(false);
                setBookingAddonCount(data.count);
                setBookingAddonList(data.records)

            }, function failure(error) {
                setLoading(false);
            }
        )
    }

    function deleteProductBookingAddon(index) {
        setLoading(true)
        const { id } = bookingAddonList[index];
        ProductBookingModel.getInstance().deleteProductBookingAddon(id,
            function success(data) {
                let list = [...bookingAddonList]
                list.splice(index, 1);
                setBookingAddonList(list);
                successNotification("Product Booking Addon Removed")
                setLoading(false)
            },
            function failure(error) {
                errorNotification(JSON.stringify(error))
                setLoading(false)
            }
        )
    }

    function updateAddonActivation(index, is_active) {
        setLoading(true)
        const { id } = bookingAddonList[index];
        const payload = { is_active };
        ProductBookingModel.getInstance().updateProductBookingAddon(id, payload,
            function success() {
                updateAddonValues(index, "is_active", is_active)
                successNotification("Product Booking Addon Status Updated")
                setLoading(false)
            },
            function failure(error) {
                errorNotification(JSON.stringify(error))
                setLoading(false)
            }
        )
    }


    function removePricing(index) {
        deletePartnerProductPricing(index)
    }

    function updatePricingValues(index, key, value) {
        let new_list = [...productPricingList];
        new_list[index][key] = value;
        setProductPricingList(new_list);
    }

    function deletePartnerProductPricing(index) {
        setLoading(true)
        const { id } = productPricingList[index];
        AppPartnerProductPricingModel.getInstance().deletePartnerProductPricing(id,
            function success(data) {
                let list = [...productPricingList]
                list.splice(index, 1);
                setProductPricingList(list);
                successNotification("Product Booking Section Saved")
                setLoading(false)
            },
            function failure(error) {
                errorNotification(JSON.stringify(error))
                setLoading(false)
            }
        )
    }

    function updatePartnerProductPricingActivation(index, is_active) {
        setLoading(true)
        const { id } = productPricingList[index];
        const payload = { is_active };
        AppPartnerProductPricingModel.getInstance().updatePartnerProductPricing(id, payload,
            function success(data) {
                updatePricingValues(index, "is_active", is_active)
                successNotification("Product Booking Section Status Updated")
                setLoading(false)
            },
            function failure(error) {
                errorNotification(JSON.stringify(error))
                setLoading(false)
            }
        )
    }

    async function getMerchantOutlets(merchant_id) {
        setLoading(true)
        setOutletList([])
        MerchantModel.getInstance().getMerchantOutlet(merchant_id,
            function success(data) {
                setLoading(false);
                setOutletList(data);
                if (allOutletSelected) setOutlet(data.map(_out => (_out.id)))
            },
            function failure(error) {
                setLoading(false);
                errorNotification(error)
                console.log(error)
            }
        )
    }

    async function selectAllOutlets(check) {
        setAllOutletSelected(check);
        if (check) setOutlet(outletList.map(_out => (_out.id)))
    }





    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                <Spin spinning={loading} >

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                <div className="kt-portlet__head kt-portlet__head--lg">
                                    <DetailPageHeaderWithButton
                                        title={"Edit Product Booking"}
                                        buttonOneIcon={"la la-arrow-left"}
                                        buttonOneCallBack={() => {
                                            history.goBack()
                                        }}
                                        buttonTwoIcon={"la la-check"}
                                        buttonTwoCallBack={() => updateProductBooking()}
                                    />
                                </div>

                                <div className="kt-portlet__body">
                                    <form className="kt-form" id="kt_form">
                                        <div className="row">
                                            {/* <div className="col-xl-2"></div> */}
                                            <div className="col-xl-12">
                                                <div className="kt-section kt-section--first">
                                                    <div className="kt-section__body">

                                                        <Tabs onChange={setActiveKey} activeKey={activeKey} defaultActiveKey="1" >
                                                            <TabPane tab="Overview" key="1">
                                                                <div className="form-group row">
                                                                    <label className="col-2 col-form-label">Merchant <b>*</b></label>
                                                                    <div className="col-10">
                                                                        <Select
                                                                            showSearch
                                                                            style={{ margin: '10px 0px 10px 0px' }}
                                                                            disabled
                                                                            value={merchant}
                                                                            notFoundContent={
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                    <p style={{ fontSize: 14 }}>Please Search Merchant</p>
                                                                                </div>
                                                                            }
                                                                            onSearch={(val) => getSearchMerchant(val)}
                                                                            onChange={(_merchant) => {
                                                                                setMerchant(_merchant)
                                                                                getMerchantOutlets(_merchant)
                                                                            }}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {merchantList.map((_merchant) => (
                                                                                <Option key={_merchant.id} value={_merchant.id}>{`${_merchant.name} ${_merchant.is_active ? ' (active)' : ' (in-active)'}`}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted">Please Select Merchant</span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-2 col-form-label">
                                                                        Select All Outlets
                                                                    </label>
                                                                    <div className="col-10">
                                                                        <Switch checked={allOutletSelected} checkedChildren="Yes" unCheckedChildren="No" onChange={(checked) => selectAllOutlets(checked)} />
                                                                        <span className="form-text text-muted">
                                                                            Want to select All outlets from Merchant?
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-2 col-form-label">Outlet <b>*</b></label>
                                                                    <div className="col-10">
                                                                        <Select
                                                                            showSearch
                                                                            style={{ margin: '10px 0px 10px 0px' }}
                                                                            mode="multiple"
                                                                            value={outlet}
                                                                            disabled={allOutletSelected}
                                                                            notFoundContent={
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                    <p style={{ fontSize: 14 }}>Please Search Outlet</p>
                                                                                </div>
                                                                            }
                                                                            onChange={(outlet) => setOutlet(outlet)}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {outletList.map((outlet) => (
                                                                                <Option key={outlet.id} value={outlet.id}>{`${outlet.name} ${outlet.is_active ? ' (active)' : ' (in-active)'}`}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted">Please Select Outlet</span>

                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-2 col-form-label">Name <b>*</b></label>
                                                                    <div className="col-10">
                                                                        <Input maxLength={100} value={name} onChange={(event) => setName(event.target.value)} className="form-control" type="text" />
                                                                        <span className="form-text text-muted"><p>Please Enter Product Booking Name</p></span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-2 col-form-label">Arabic Name</label>
                                                                    <div className="col-10">
                                                                        <Input maxLength={100} value={arabicName} onChange={(event) => setArabicName(event.target.value)} className="form-control" type="text" />
                                                                        <span className="form-text text-muted"><p>Please Enter Arabic Product Booking Name</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-2 col-form-label">Description <b>*</b></label>
                                                                    <div className="col-10">
                                                                        <textarea value={description} maxLength="500"
                                                                            onChange={(event) => setDescription(event.target.value)}
                                                                            className="form-control" rows="3"
                                                                        />
                                                                        <span className="form-text text-muted"><p>Please Enter Product Booking description</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-2 col-form-label">Arabic Description</label>
                                                                    <div className="col-10">
                                                                        <textarea value={arabicDescription} maxLength="500"
                                                                            onChange={(event) => setArabicDescription(event.target.value)}
                                                                            className="form-control" rows="3"
                                                                        />
                                                                        <span className="form-text text-muted"><p>Please Enter Arabic Product Booking description</p></span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-2 col-form-label">Visibility Setting</label>
                                                                    <div className="col-10">
                                                                        <Select
                                                                            allowClear
                                                                            showSearch
                                                                            style={{ margin: "10px 0px 10px 0px" }}
                                                                            value={productVisibility}
                                                                            notFoundContent={
                                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                                    <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                                                                    <p style={{ fontSize: 14 }}>Please search product visibility (Full text search)</p>
                                                                                </div>
                                                                            }
                                                                            onSearch={(val) => getSearchProducVisibility(val)}
                                                                            onChange={(outlet) => setProductVisibility(outlet)}
                                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                                            {productVisibilityList.map((visibility) => (
                                                                                <Option key={visibility.id} value={visibility.id}>{visibility.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted">Please Select Visibility Setting</span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-2 col-form-label">
                                                                        Minimum Booking Time
                                                                    </label>
                                                                    <div className="col-10">
                                                                        <Input
                                                                            maxLength={4}
                                                                            value={minimumBookingTime}
                                                                            onChange={(event) =>
                                                                                setMinimumBookingTime(event.target.value)
                                                                            }
                                                                            className="form-control"
                                                                            type="number"
                                                                        />
                                                                        <span className="form-text text-muted">
                                                                            <p>
                                                                                Please Enter Minimum Booking Time
                                                                            </p>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </TabPane>

                                                            <TabPane tab="Configuration" key="3">
                                                                <div className="form-group row">
                                                                    <div className="col-10">
                                                                        <div className="btn-group">
                                                                            <button onClick={() => window.location.href = `/product-booking/section/add/${params.id}`} type="button" className="btn btn-brand">
                                                                                <i className="la la-plus"></i>
                                                                                <span className="kt-hidden-mobile">Add Configuration</span>
                                                                            </button>
                                                                            {
                                                                                defaultConfig &&
                                                                                <button style={{ marginLeft: 10 }} onClick={() => window.location.href = `/product-booking/section/edit/${defaultConfig}`} type="button" className="btn btn-brand">
                                                                                    <i className="la la-edit"></i>
                                                                                    <span className="kt-hidden-mobile">Edit Primary Config</span>
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <BookingConfigData
                                                                    data={configList}
                                                                    deleteBookingConfig={(index) => removeConfig(index)}
                                                                    updateBookingConfigActivation={(index, value) => updateConfigActivation(index, value)}
                                                                />

                                                            </TabPane>

                                                            <TabPane tab="Addons" key="4">
                                                                <div className="form-group row">
                                                                    <div className="col-10">
                                                                        <div className="btn-group">
                                                                            <button onClick={() => window.location.href = `/product-booking/addons/add/${params.id}`} type="button" className="btn btn-brand">
                                                                                <i className="la la-plus"></i>
                                                                                <span className="kt-hidden-mobile">Add Addon</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <ProductBookingAddonsDataTable
                                                                    data={bookingAddonList}
                                                                    deleteBookingAddon={(index) => removeAddon(index)}
                                                                    updateBookingAddonActivation={(index, value) => updateAddonActivation(index, value)}
                                                                    product_booking_id={params.id}
                                                                    getProductBookingAddons={(filters) => getProductBookingAddonsByProductBooking(filters)}
                                                                    total_count={bookingAddonCount}
                                                                />

                                                            </TabPane>

                                                            <TabPane tab="Price List" key="5">
                                                                <div className="form-group row">
                                                                    <div className="col-10">
                                                                        <div className="btn-group">
                                                                            <button onClick={() => window.location.href = `/product-booking/pricing/add/${params.id}`} type="button" className="btn btn-brand">
                                                                                <i className="la la-plus"></i>
                                                                                <span className="kt-hidden-mobile">Add Product Pricing</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <ProductPricingData
                                                                    data={productPricingList}
                                                                    deleteProductPricing={(index) => removePricing(index)}
                                                                    updateProductBookingActivation={(index, value) => updatePartnerProductPricingActivation(index, value)}
                                                                />

                                                            </TabPane>
                                                        </Tabs>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div >

    )
}
