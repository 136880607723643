const Styles = {
    userDataContainer: {
        // display: 'flex',
        // flexDirection: 'row',
        paddingLeft: 'auto',
        paddingRight: 'auto',
        fontFamily: 'Arial,HelveticaNeue',
    },
    statusCard: {
        width: 200,
        height: 100,
        marginBottom: 20,
        marginRight: 20,
        boxShadow: "0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)",
        float: 'left',
        textAlign: "center",
        padding: 20,
    },
    card: {
        width: 200,
        height: 100,
        marginBottom: 20,
        marginRight: 20,
        boxShadow: "0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)",
        float: 'left',
        // textAlign: "center",
        padding: 20,
        backgroundColor: '#f4f4f4'
    },
    nameCard: {
        width: 200,
        height: 120,
        // marginTop: -50,
        marginBottom: 20,
        marginLeft: 10,
        // boxShadow: "0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)",
        float: 'left',
        // textAlign: "center",
        padding: 10,
        // backgroundColor: '#f4f4f4'
    },
    statusContainer: {
        height: 30,
        width: 180,
        paddingVertical: 10
    },
    cardHeading: {
        color: '#6C7293',
        marginLeft: '10px',
        width :'55%',
        fontFamily: 'Arial,HelveticaNeue',
    },



    // order view
    orderContainer: {
        // marginTop: 10,
        float: 'left',
        width:'70%',
        fontFamily: 'Arial,HelveticaNeue',
        color : '#6C7293'
    }
};


export default Styles;