import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel'



export default class CardModel {
    static myInstance = null;
    static getInstance() {
        if (CardModel.myInstance === null) {
           CardModel.myInstance = new CardModel();
        }
        return this.myInstance;
    }

    static CardSetList = [];
    static CardList = [];
    

// card set
    getCardSet( successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getCardSet(token, 
            function resSuccess(data) {
                CardModel.getInstance().CardSetList = data.data
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    searchCardSet(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().searchCardSet(token, params,
            function resSuccess(data) {
                CardModel.getInstance().CardSetList = data.data
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    addCardSet(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addCardSet(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    getCardSetDetail(cardSetId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getCardSetDetail(token, cardSetId, 
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateCardSet(cardSetId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateCardSet(token, cardSetId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    removeCardSet(cardSetId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeCardSet(token, cardSetId,
            function resSuccess() {
                CardModel.getInstance().CardSetList.forEach((card, index) => {
                    if (card.id === cardSetId) {
                        CardModel.getInstance().CardSetList.splice(index, 1);
                        successTrigger()
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    
    cardSetActivation(cardSetId, params, successTrigger, failureTrigger) {
        let token =  UserModel.getInstance().getToken();
        ApiManager.getInstance().cardSetActivation(token, cardSetId, params,
            function resSuccess(data) {
                CardModel.getInstance().CardSetList.forEach((cardSet, index) => {
                    if (cardSet.id === cardSetId) {
                        cardSet.is_active = params.is_active;
                        successTrigger(data)
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    // cards
    getCard( cardSetId , successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getCard(token, cardSetId,
            function resSuccess(data) {
                CardModel.getInstance().CardList = data.data
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getCardDetail(cardSetId, cardId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getCardDetail(token, cardSetId, cardId,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateCard(cardSetId, cardId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateCardSet(token, cardSetId, cardId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    removeCard(cardSetId, cardId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeCard(token, cardSetId, cardId,
            function resSuccess() {
                CardModel.getInstance().CardList.forEach((card, index) => {
                    if (card.id === cardId) {
                        CardModel.getInstance().CardList.splice(index, 1);
                        successTrigger()
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    cardActivation(cardSetId, cardId, params, successTrigger, failureTrigger) {
        let token =  UserModel.getInstance().getToken();
        ApiManager.getInstance().cardSetActivation(token, cardSetId, cardId, params,
            function resSuccess(data) {
                CardModel.getInstance().CardList.forEach((card, index) => {
                    if (card.id === cardId) {
                        card.is_active = params.is_active;
                        successTrigger(data)
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


}