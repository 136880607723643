import { Upload, Icon, message } from 'antd';
import React from 'react'

const { Dragger } = Upload;

// const props = {
//   name: 'file',
// multiple: false,
//   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
//   onChange(info) {
//     const { status } = info.file;
//     if (status !== 'uploading') {
//       console.log(info.file, info.fileList);
//     }
//     if (status === 'done') {
//       message.success(`${info.file.name} file uploaded successfully.`);
//     } else if (status === 'error') {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
//     accept=".txt, .csv"
//     showUploadList={ false}
//     beforeUpload={ file => {
//     const reader = new FileReader();

//     reader.onload = e => {
//         console.log(e.target.result);
//     };
//     reader.readAsText(file);

//     // Prevent upload
//     return false;
// }}
// };
export default class FileUploader extends React.Component {

    csvJSON(csv, dataType) {
       
        var lines = csv.split("\n");

        var result = [];

        var headers = lines[0].split(",");
        // console.log("csv", headers,lines)
        // return []
        switch(dataType){
            case "cardNumber":
                if((headers.includes("card_number") || headers.includes("card_number\r"))){
                    for (var i = 1; i < lines.length; i++) { 
                        var obj = {};
                        var currentline = lines[i].split(",");
                        for (var j = 0; j < headers.length; j++) {
                            obj[headers[j]] = currentline[j];
                        }
                        console.log("object", obj)
                        result.push(obj);
                        // if (obj.card_number.length > 1) {
                        //     result.push(obj);
                        // }                              
                    }
            
                    return result.pop(); //JSON
                }
                else return []

                default:
                    if ((headers.includes("Code") || headers.includes("Code\r")) && (headers.includes("Usage") || headers.includes("Usage\r"))) {
            
                        for (var i = 1; i < lines.length; i++) {
            
                            var obj = {};
                            var currentline = lines[i].split(",");
            
                            for (var j = 0; j < headers.length; j++) {
                                obj[headers[j]] = currentline[j];
                            }
                            console.log('obj: ', obj)
                            if (obj.Code.length > 1) {
                                result.push(obj);
                            }
            
            
                        }
                        // result.pop();
            
                        //return result; //JavaScript object
                        return JSON.stringify(result); //JSON
                    }
                    else return []


        }
            

    }

    render() {
        const { getData, dataType, fileName } = this.props;
        return (
            <Dragger
                accept=".csv"
                showUploadList={false}
                beforeUpload={file => {
                    const reader = new FileReader();
                    reader.fileName = file.name

                    reader.onload = e => {
                        // fileName(e.target.fileName);
                        getData(this.csvJSON(e.target.result, dataType), e.target.fileName)
                        // console.log(this.csvJSON(e.target.result))
                    };
                    reader.readAsText(file);

                    // Prevent upload
                    return false;
                }}
            >
                <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                {/* <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                    band files
                </p> */}
            </Dragger>
            // <Dragger
            //     accept=".csv"
            //     showUploadList={false}
            //     beforeUpload={file => {
            //         const reader = new FileReader();

            //         reader.onload = e => {
            //             // console.log(e.target.result);
            //             console.log(this.csvJSON(e.target.result))
            //         };
            //         reader.readAsText(file);

            //         // Prevent upload
            //         return false;
            //     }}
            // >
            //     <p className="ant-upload-drag-icon">
            //         <Icon type="inbox" />
            //     </p>
            //     <p className="ant-upload-text">Click or drag file to this area to upload</p>
            //     {/* <p className="ant-upload-hint">
            //         Support for a single or bulk upload. Strictly prohibit from uploading company data or other
            //         band files
            //     </p> */}
            // </Dragger>
        )
    }
}