import React from "react";
import { Select, Icon, Popconfirm, Spin, Switch, Button } from "antd";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import AddonModel from "../../Models/AddonModel";
import {
  infoNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      totalPage: 1,
      perPage: 20,
      filters: [],
      merchantList: [],
      merchant: "",
      merchantError: false,
    };
  }

  componentDidMount() {
    this.getAddon();
    const type = localStorage.getItem("user_role");
    this.setState({ type });
  }

  getAddon() {
    const it = this;
    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });

    if (params["addons.is_active"] === "all") {
      delete params["addons.is_active"];
    }

    it.setState({ loading: true });
    AddonModel.getInstance().searchAddon(
      params,
      function success(data) {
        it.setState({
          loading: false,
          data: AddonModel.getInstance().AddonList,
          totalPage: data.last_page,
          perPage: data.per_page,
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorToaster("Request information not found");
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getAddon();
  }, 1000);

  removeAddon(addonId) {
    const it = this;
    it.setState({ loading: true });
    AddonModel.getInstance().removeAddon(
      addonId,
      function success() {
        it.setState({ data: AddonModel.getInstance().AddonList });
        it.setState({ loading: false });
        infoNotification("Addon Successfully Deleted");
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  addonActivation(addonId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = !is_active;
    let params = {
      is_active: activation,
    };
    AddonModel.getInstance().addonActivation(
      addonId,
      params,
      function success() {
        it.setState({ data: AddonModel.getInstance().AddonList });
        it.setState({ loading: false });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={this.state.loading}>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              {gridHeaderWithButton(
                "Addons",
                "la la-plus",
                "Add Addon",
                "/addon/add"
              )}
            </div>
            <div className="kt-portlet__body">
              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={20}
                showPageSizeOptions={false}
                manual
                page={this.state.currentPage - 1}
                pages={this.state.totalPage}
                filtered={this.state.filters}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getAddon();
                  }, 50);
                }}
                onFilteredChange={(filters) => {
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                columns={[
                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    id: "addons.name",
                    accessor: "name",
                    style: { whiteSpace: "unset" },
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Merchant</p>,
                    id: "merchants.name",
                    accessor: (d) =>
                      d.merchants.map((merchant, key) => (
                        <p
                          className="btn btn-label-info btn-pill"
                          style={{
                            float: "left",
                            margin: "2px",
                            padding: "5px 10px",
                            fontSize: "11px",
                          }}
                          key={key}
                        >
                          {merchant.name}
                        </p>
                      )),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["merchants"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Outlet </p>,
                    id: "outlets.name",
                    accessor: (d) =>
                      d.outlets.map((outlet, key) => (
                        <p
                          className="btn btn-label-info btn-pill"
                          style={{
                            float: "left",
                            margin: "2px",
                            padding: "5px 10px",
                            fontSize: "11px",
                          }}
                          key={key}
                        >
                          {outlet.name}
                        </p>
                      )),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["outlets"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Addon sets</p>,
                    id: "addon_sets.name",
                    accessor: "addon_set",
                    style: { whiteSpace: "unset" },
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["addon_set"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Position</p>,
                    id: "addons.position",
                    accessor: "position",
                    style: { whiteSpace: "unset" },
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["position"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Price </p>,
                    id: "addons.price",
                    accessor: "price",
                    style: { whiteSpace: "unset" },
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["price"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "addons.is_active",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      console.log("VALUE: ", filter.value);
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value == "true") {
                        return row[filter.id] == true;
                      }
                      return row[filter.id] == false;
                    },
                    Filter: ({ filter, onChange }) =>
                      console.log("VALUE: ", filter) || (
                        <Select
                          onChange={(value) => onChange(value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <Option value="all">Show all</Option>
                          <Option value="true">Active</Option>
                          <Option value="false">De-Activate</Option>
                        </Select>
                      ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 150,
                    accessor: (d) => (
                      <div>
                        <Link
                          to={
                            this.state.type === "5"
                              ? `addon`
                              : `addon/edit/${d.id}`
                          }
                          style={{ marginRight: 20 }}
                        >
                          <Icon
                            style={{
                              color:
                                this.state.type === "5" ? "gray" : "#5D78FF",
                              fontSize: 16,
                            }}
                            type="edit"
                          />
                        </Link>

                        {this.state.type === "1" ? (
                          <Popconfirm
                            title="Are you sure you want to delete this addon ?"
                            icon={
                              <Icon
                                type="question-circle-o"
                                style={{ color: "red" }}
                              />
                            }
                            onConfirm={() => this.removeAddon(d.id)}
                          >
                            <Icon
                              style={{ color: "#5D78FF", fontSize: 16 }}
                              type="delete"
                            />
                          </Popconfirm>
                        ) : null}

                        <Popconfirm
                          title={
                            d.is_active
                              ? "Are you sure you want to DEACTIVATE this addon"
                              : "Are you sure you want to ACTIVATE this addon"
                          }
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.addonActivation(d.id, d.is_active)
                          }
                        >
                          <Switch
                            style={{
                              backgroundColor: d.is_active
                                ? "#5d78ff"
                                : "#fd397a",
                              marginLeft: 15,
                            }}
                            checked={d.is_active}
                            onChange={(val) => console.log(val)}
                          />
                        </Popconfirm>
                      </div>
                    ),
                    filterable: false,
                    sortable: false,
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
