import React, { Children } from 'react';


export function header(tableName) {
    return (
        <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon2-line-chart"></i>
            </span>
            <h3 className="kt-portlet__head-title">{tableName}</h3>
        </div>
    )
}
export function buttonName(iconName, tableButtonName, routePath) {
    return (<div className="kt-portlet__head-toolbar">
        <div className="kt-portlet__head-wrapper">
            <div className="kt-portlet__head-actions">
                <a
                    href={routePath}
                    className="btn btn-brand btn-elevate btn-icon-sm"
                >
                    <i className={iconName}></i>
                    {tableButtonName}
                </a>
            </div>
        </div>
    </div>
    )
}
export function gridHeaderWithButton(tableName, iconName, tableButtonName, routePath) {
    return (
        <React.Fragment>
            {header(tableName)}
            {buttonName(iconName, tableButtonName, routePath)}
        </React.Fragment>
    )
}
export function gridHeaderWithOutButton(tableName) {
    return (
        <React.Fragment>
            {header(tableName)}
        </React.Fragment>
    )
}

export function GridHeaderWithChildComponent(props) {
    return (
        <React.Fragment>
            {header(props.tableName)}
            {props.children}
        </React.Fragment>
    )
}
