import React from 'react';
import SideMenu from './SideMenu'
import Header from './Header'
import Footer from './Footer'



const Layout = (props) => {

    if (props.isAuth) {
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <SideMenu />
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                    <Header />
                    {props.children}
                    <Footer />
                </div>
            </div >
        )

    } else {
        return props.children
    }
}

export default Layout;