import React from "react";
import { Select } from "antd";
import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
import UserModel from "../../Models/UserModel";

const { Option } = Select;

export default class AddCity extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      nameError: false,
      arb_name: "",
      arb_nameError: false,
      country: null,
      countryError: false,
      countryList: [],
      province: null,
      provinceError: false,
      provinceList: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    const it = this;
    it.setState({ loading: true });
    UserModel.getInstance().getCountries(
      function success() {
        it.setState({
          countryList: UserModel.getInstance().CountriesList,
          loading: false,
        });
      },
      function failure(error) {
        console.log(error);
      }
    );
  }

  getProvince(country) {
    const it = this;
    it.setState({ loading: true, country });
    let params = {
      id: country,
    };
    UserModel.getInstance().getProvince(
      params,
      function success() {
        it.setState({
          provinceList: UserModel.getInstance().ProvinceList,
          loading: false,
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false });
      }
    );
  }

  sendData() {
    const { country, province, name, arb_name } = this.state;

    let it = this;
    const text = "Validation Error! Please check fields";
    this.setState({
      nameError: false,
      arb_nameError: false,
      provinceError: false,
      countryError: false,
    });
    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster(text);
      return;
    }
    if (!province) {
      this.setState({ provinceError: true });
      errorToaster(text);
      return;
    }
    if (!country) {
      this.setState({ countryError: true });
      errorToaster(text);
      return;
    }
    UserModel.getInstance().addCity(
      name,
      arb_name,
      country,
      province,
      function success() {
        successNotification("City Created Successfully");
        setTimeout(() => {
          window.location.href = "/city";
        }, 1000);
      },
      function failure(error) {
        errorNotification(error);
      }
    );
  }

  render() {
    const { countryError, nameError, arb_nameError, provinceError } =
      this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile"
                id=""
              >
                <div className="kt-portlet__head kt-portlet__head--lg">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Add City</h3>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                    <a href="/city" className="btn btn-clean kt-margin-r-10">
                      <i className="la la-arrow-left"></i>
                      <span className="kt-hidden-mobile">Back</span>
                    </a>
                    <div className="btn-group">
                      <button
                        type="button"
                        onClick={() => this.sendData()}
                        className="btn btn-brand"
                      >
                        <i className="la la-check"></i>
                        <span className="kt-hidden-mobile">Save</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* end header */}
                <div className="kt-portlet__body">
                  <form className="kt-form" id="kt_form">
                    <div className="row">
                      <div className="col-xl-2"></div>
                      <div className="col-xl-8">
                        <div className="kt-section kt-section--first">
                          <div className="kt-section__body">
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Name <b>*</b>
                              </label>
                              <div className="col-9">
                                <input
                                  maxLength="70"
                                  className="form-control"
                                  onChange={(event) =>
                                    this.setState({ name: event.target.value })
                                  }
                                  type="text"
                                />
                                <span
                                  style={{ color: nameError ? "red" : "" }}
                                  className="form-text text-muted"
                                >
                                  <p style={{ color: nameError ? "red" : "" }}>
                                    Please enter name in English
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Arabic Name
                              </label>
                              <div className="col-9">
                                <input
                                  maxLength="100"
                                  className="form-control"
                                  onChange={(event) =>
                                    this.setState({
                                      arb_name: event.target.value,
                                    })
                                  }
                                  type="text"
                                />
                                <span
                                  style={{ color: arb_nameError ? "red" : "" }}
                                  className="form-text text-muted"
                                >
                                  <p
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                  >
                                    Please enter name in Arabic
                                  </p>
                                </span>
                                {/* <span className="form-text text-muted">Please enter name in Arabic</span> */}
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Country <b>*</b>
                              </label>
                              <div className="col-9">
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  // className="form-control"
                                  onChange={(value) => this.getProvince(value)}
                                  value={this.state.country}
                                  // onFocus={onFocus}
                                  // onBlur={onBlur}
                                  // onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.countryList.map((country) => (
                                    <Option key={country.id} value={country.id}>
                                      {country.name}
                                    </Option>
                                  ))}
                                </Select>
                                <span
                                  style={{ color: countryError ? "red" : "" }}
                                  className="form-text text-muted"
                                >
                                  <p
                                    style={{ color: countryError ? "red" : "" }}
                                  >
                                    Please Select Country
                                  </p>
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Province <b>*</b>
                              </label>
                              <div className="col-9">
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  // className="form-control"
                                  onChange={(value) =>
                                    this.setState({ province: value })
                                  }
                                  value={this.state.province}
                                  // onFocus={onFocus}
                                  // onBlur={onBlur}
                                  // onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.provinceList.map((province) => (
                                    <Option
                                      key={province.id}
                                      value={province.id}
                                    >
                                      {province.name}
                                    </Option>
                                  ))}
                                </Select>
                                <span className="form-text text-muted">
                                  <p
                                    style={{
                                      color: provinceError ? "red" : "",
                                    }}
                                  >
                                    Please Select Province
                                  </p>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2"></div>
                    </div>
                  </form>
                </div>
                {/* end body */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
