import React from "react";
import { Input, Spin } from "antd";
import { getCompressedImage } from "Helpers/Shared/utils";

import UserModel from "../../Models/UserModel";

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
import backImage from "../../../src/assets/media/users/default.jpg";
import SharedModel from "../../Models/SharedModel";

export default class EditPayment extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      logoImage: null,
      logoImageError: false,
      name: "",
      nameError: false,
      arb_name: "",
      arb_nameError: false,
      paymentId: null,
      paymentList: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    let it = this;
    const {
      match: { params },
    } = this.props;
    this.setState({ paymentId: params.paymentId });
    UserModel.getInstance().getPaymentDetail(
      params.paymentId,
      function success(data) {
        it.setState({
          name: data.name,
          arb_name: data.arabic_name ? data.arabic_name : "",
          serverLogoSrc: data.logo,
        });
      },
      function failure(error) {
        console.error(error);
      }
    );
    // console.log(params.categoryId)
  }

  saveLogo(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ loading: true });
    getCompressedImage(file)
      .then((compressedImage) => {
        if (compressedImage) {
          this.setState({
            loading: false,
            logoImage: compressedImage,
            logoImageSrc: URL.createObjectURL(compressedImage),
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        errorNotification(e);
      });
  }

  sendData() {
    let { paymentId, name, arb_name, logoImage } = this.state;
    let it = this;
    const text = "Validation Error! Please check fields";
    this.setState({
      nameError: false,
      arb_nameError: false,
    });
    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster(text);
      return;
    }
    // if (arb_name.length < 1) {
    //     arb_name = ' ';
    //     //     this.setState({ arb_nameError: true })
    //     //     errorToaster(text);
    //     //     return
    // }

    UserModel.getInstance().updatePayment(
      paymentId,
      name,
      arb_name,
      function success(data) {
        it.addMediaInstance(logoImage, data.payment_mode.id);
      },
      function failure(error) {
        errorNotification(error);
        console.log(error);
      }
    );
  }

  addMediaInstance(logoImage, id) {
    const { history } = this.props;
    let it = this;
    if (logoImage) {
      let params = new FormData();
      params.append("logo", logoImage);

      SharedModel.getInstance().addMediaInstance(
        id,
        "payment_mode",
        params,
        function success() {
          successNotification("Payment-mode Updated Successfully");
          setTimeout(() => {
            history.push("/payment");
          }, 1000);
        },
        function failure(error) {
          errorNotification(error);
          console.log(error);
        }
      );
    } else {
      successNotification("Payment-mode Updated Successfully");
      setTimeout(() => {
        history.push("/payment");
      }, 1000);
    }
  }

  render() {
    const {
      serverLogoSrc,
      logoImageSrc,
      name,
      nameError,
      arb_name,
      arb_nameError,
      logoImageError,
      loading,
    } = this.state;
    console.log(name);
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <Spin spinning={loading}>
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile"
                  id=""
                >
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">
                        Edit Payment Method
                      </h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/payment"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          type="button"
                          onClick={() => this.sendData()}
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                  Upload icon <b>*</b>
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <div
                                    className="kt-avatar kt-avatar--outline kt-avatar--circle"
                                    id="kt_apps_user_add_avatar"
                                  >
                                    <div
                                      className="kt-avatar__holder"
                                      style={{
                                        backgroundImage: `url(${
                                          logoImageSrc
                                            ? logoImageSrc
                                            : serverLogoSrc
                                            ? serverLogoSrc
                                            : backImage
                                        })`,
                                      }}
                                    ></div>
                                    <label
                                      className="kt-avatar__upload"
                                      data-toggle="kt-tooltip"
                                      title=""
                                      data-original-title="Change avatar"
                                    >
                                      <i className="fa fa-pen"></i>
                                      <input
                                        onChange={(event) =>
                                          this.saveLogo(event)
                                        }
                                        type="file"
                                        name="kt_apps_user_add_user_avatar"
                                      />
                                    </label>
                                    <span
                                      className="kt-avatar__cancel"
                                      data-toggle="kt-tooltip"
                                      title=""
                                      data-original-title="Cancel avatar"
                                    >
                                      <i className="fa fa-times"></i>
                                    </span>
                                    <p
                                      style={{
                                        color: "red",
                                        display: logoImageError
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      Please Upload Icon Image
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  {/* <Input placeholder="Basic usage" /> */}
                                  <Input
                                    maxLength={70}
                                    className="form-control"
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    type="text"
                                    value={name}
                                  />
                                  {/* <input className="form-control" onChange={(event) => this.setState({ name: event.target.value })} type="text" defaultValue={name} /> */}
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter name in English
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Arabic Name
                                </label>
                                <div className="col-9">
                                  <Input
                                    maxLength={100}
                                    className="form-control"
                                    onChange={(event) =>
                                      this.setState({
                                        arb_name: event.target.value,
                                      })
                                    }
                                    type="text"
                                    value={arb_name}
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: arb_nameError ? "red" : "",
                                      }}
                                    >
                                      Please enter name in Arabic
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
