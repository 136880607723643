import React from "react";
import { Icon, Spin, Popconfirm, Select, Switch } from "antd";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";
import MerchantModel from "../../Models/MerchantModel";
import SharedModel from "../../Models/SharedModel";
import {
  successNotification,
  errorNotification,
  infoToaster,
} from "../Shared/Notifications";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      perPage: 0,
      totalPage: 0,
      filters: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getOutlet();
  }
  getOutlet() {
    const it = this;
    it.setState({ loading: true });
    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });
    if (params["outlets.is_active"] === "all") {
      delete params["outlets.is_active"];
    }

    MerchantModel.getInstance().getSearchOutlet(
      params,
      function success(data) {
        it.setState({
          data: MerchantModel.getInstance().OutletList,
          loading: false,
          perPage: data.per_page,
          totalPage: data.last_page,
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorNotification("Request information not found");
        console.log(error);
      }
    );
  }

  sendMerchantPin(outletId) {
    const it = this;
    it.setState({ loading: true });
    MerchantModel.getInstance().sendMerchantPin(
      outletId,
      function success(data) {
        it.setState({ loading: false });
        successNotification("Merchant Pin Sent Successfully");
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  deleteOutlet(outletId) {
    const it = this;
    it.setState({ loading: true });
    MerchantModel.getInstance().removeOutlet(
      outletId,
      function success() {
        it.setState({
          data: MerchantModel.getInstance().OutletList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }
  outletActivation(outletId, is_active) {
    const it = this;
    let activation = !is_active;
    let params = {
      is_active: activation,
    };
    it.setState({ loading: true });
    MerchantModel.getInstance().outletActivation(
      outletId,
      params,
      function success() {
        it.setState({
          data: MerchantModel.getInstance().OutletList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  exportData() {
    let it = this;
    let { filters } = this.state;
    let params = {
      type: "outlet",
    };
    filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });
    infoToaster("Please Wait! File will be Downloaded shortly");
    SharedModel.getInstance().downloadReport(
      params,
      function success(data) {
        it.setState({
          loading: false,
        });
        infoToaster(data);
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getOutlet();
  }, 1000);

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={this.state.loading}>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              <div className="kt-portlet__head-label">
                <span className="kt-portlet__head-icon">
                  <i className="kt-font-brand flaticon2-line-chart"></i>
                </span>
                <h3 className="kt-portlet__head-title">Outlets</h3>
              </div>
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  <div className="kt-portlet__head-actions">
                    <a
                      href="/outlet/add/0"
                      className="btn btn-brand btn-elevate btn-icon-sm"
                    >
                      <i className="la la-plus"></i>
                      Add New Outlet
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div
                style={{ marginLeft: "-10px" }}
                className="col-3 col-form-label"
              >
                <div className="btn-group">
                  <button
                    onClick={() => this.exportData()}
                    style={{ marginLeft: "10px" }}
                    type="button"
                    className="btn btn-brand"
                  >
                    <i className="la la-download"></i>
                    <span className="kt-hidden-mobile">Export</span>
                  </button>
                </div>
              </div>

              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={20}
                // showPageJump={false}
                showPageSizeOptions={false}
                manual
                filtered={this.state.filters}
                page={this.state.currentPage - 1}
                pages={this.state.totalPage}
                // onFetchData={(state, instance) => {
                //     // console.log('state: ', state)
                //     if (this.state.currentPage !== state.page + 1) {
                //         this.setState({ currentPage: state.page + 1 })
                //         setTimeout(() => {
                //             this.getOutlet()
                //         }, 50);
                //     }
                // }}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getOutlet();
                  }, 50);
                }}
                onFilteredChange={(filters) => {
                  console.log("filters: ", filters);
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                // onFilteredChange={(filtered) => {
                //     this.setState({ currentPage: 1, filteredName: filtered.length < 1 ? '' : filtered[0].value })
                //     this.searchQuery(this)
                // }}
                columns={[
                  // {
                  // Header: "Name",
                  // columns: [
                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    id: "outlets.name",
                    accessor: (d) => (
                      <Link to={`outlet/item/${d.id}`}>{d.name}</Link>
                    ),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Address</p>,
                    id: "outlets.address",
                    accessor: (d) => d.address,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["address"] }),
                    filterAll: true,
                    // filterable: false,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Arabic Address</p>,
                    id: "outlets.arabic_address",
                    accessor: (d) => d.arabic_address,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["arabic_address"],
                      }),
                    filterAll: true,
                    // filterable: false,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Merchant</p>,
                    id: "merchants.name",
                    accessor: (d) => d.merchant_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["merchant_name"],
                      }),
                    filterAll: true,
                    // filterable: false,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Phone</p>,
                    id: "outlets.phone",
                    accessor: (d) => d.phone,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["phone"] }),
                    filterAll: true,
                    // filterable: false,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Deliverable</p>,
                    id: "delivery",
                    accessor: (d) => (d.delivery ? "Yes" : "No"),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["delivery"] }),
                    filterAll: true,
                    filterable: false,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Created At</p>,
                    id: "created_at",
                    accessor: (d) =>
                      moment(d.created_at).format("hh:mm A - DD/MMM/YYYY"),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["created_at"] }),
                    filterAll: true,
                    filterable: false,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Updated At</p>,
                    id: "updated_at",
                    accessor: (d) =>
                      moment(d.updated_at).format("hh:mm A - DD/MMM/YYYY"),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["updated_at"] }),
                    filterAll: true,
                    filterable: false,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "outlets.is_active",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "true") {
                        return row[filter.id] === true;
                      }
                      return row[filter.id] === false;
                    },
                    Filter: ({ filter, onChange }) => (
                      <Select
                        onChange={(value) => onChange(value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <Option value="all">Show All</Option>
                        <Option value="true">Active</Option>
                        <Option value="false">De-Activate</Option>
                      </Select>
                    ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 170,
                    Filter: (
                      <span
                        onClick={() => {
                          if (this.state.filters.length === 0) {
                          } else {
                            this.setState({
                              filters: [],
                              currentPage: 1,
                            });
                            setTimeout(() => {
                              this.getOutlet();
                            }, 100);
                          }
                        }}
                        className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill"
                      >
                        Reset-Filters
                      </span>
                    ),
                    accessor: (d) => (
                      <div>
                        <Link
                          to={`outlet/edit/${d.id}`}
                          style={{ marginRight: 20 }}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="edit"
                          />
                        </Link>
                        <Popconfirm
                          title="Are you sure you want to send Merchant PIN to Outlet?"
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() => this.sendMerchantPin(d.id)}
                        >
                          <Icon
                            style={{
                              color: "#5D78FF",
                              fontSize: 16,
                              marginRight: 20,
                            }}
                            type="code"
                          />
                        </Popconfirm>
                        <Popconfirm
                          title="Are you sure you want to delete this outlet?"
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() => this.deleteOutlet(d.id)}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="delete"
                          />
                        </Popconfirm>
                        <Popconfirm
                          title={
                            d.is_active
                              ? "Are you sure you want to DEACTIVATE this outlet"
                              : "Are you sure you want to ACTIVATE this outlet"
                          }
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.outletActivation(d.id, d.is_active)
                          }
                        >
                          <Switch
                            style={{
                              backgroundColor: d.is_active
                                ? "#5d78ff"
                                : "#fd397a",
                              marginLeft: 15,
                            }}
                            checked={d.is_active}
                            onChange={(val) => console.log(val)}
                          />
                        </Popconfirm>
                      </div>
                    ),
                    // filterable: false,
                    sortable: false,
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
