import React, { useState, useEffect } from 'react';

import {
    Select,
    Input,
    Spin
} from 'antd';

import {
    successNotification,
    errorNotification,
    errorToaster
} from '../Shared/Notifications';

import _data from "./data.json";

import SharedModel from '../../Models/SharedModel';

const { Option } = Select;


export default function AddAppVisibility() {
    const
        [loading, setLoading] = useState(false),
        [name, setName] = useState(''),
        [appId, setAppId] = useState(null),
        [appList, setAppList] = useState([]),
        [section, setSection] = useState(undefined);


    useEffect(() => {
        getAllAppIds()
        return () => {

        }
    }, [])

    const getAllAppIds = () => {
        setLoading(true);
        SharedModel.getInstance().getAllAppIds(
            function success(data) {
                setLoading(false)
                setAppList(data)
            },
            function failure(error) {
                setLoading(false)
                errorToaster("Request information not found")
            }
        )
    }


    const addVisibilitySetting = () => {

        if (name.length < 1) {
            errorToaster("Please Enter Visibility Name")
            return
        }
        if (!appId) {
            errorToaster("Please Enter App Id")
            return
        }

        if (!section) {
            errorToaster("Please Select section for specific app")
            return
        }

        let value;
        _data.SectionList.forEach(sec => {
            if (section === sec.section_id) {
                value = sec.value
            }
        })

        const params = {
            name,
            app_id: appId,
            is_active: false,
            section_id: section,
            value

        };
        SharedModel.getInstance().addAppVisibility(params,
            function success() {
                successNotification('App Visibility Created Successfully');
                setTimeout(() => {
                    setLoading(false);
                    window.location.href = '/visibility-setting'
                }, 2000);
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                <Spin spinning={loading} >

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                <div className="kt-portlet__head kt-portlet__head--lg">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">Add Visibility</h3>
                                    </div>
                                    <div className="kt-portlet__head-toolbar">
                                        <a href="/visibility-setting" className="btn btn-clean kt-margin-r-10">
                                            <i className="la la-arrow-left"></i>
                                            <span className="kt-hidden-mobile">Back</span>
                                        </a>
                                        <div className="btn-group">
                                            <button onClick={() => addVisibilitySetting()} type="button" className="btn btn-brand">
                                                <i className="la la-check"></i>
                                                <span className="kt-hidden-mobile">Save</span>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                                <div className="kt-portlet__body">
                                    <form className="kt-form" id="kt_form">
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-8">

                                                <div className="kt-section kt-section--first">

                                                    <div className="kt-section__body">


                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Visibility Name Name <b>*</b></label>
                                                            <div className="col-9">

                                                                {/* input here */}
                                                                <Input maxLength={255} value={name} onChange={(event) => setName(event.target.value)} className="form-control" type="text" />
                                                                <span className="form-text text-muted"><p style={{}}>Please Enter Visibility Name</p></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">App Name <b>*</b></label>
                                                            <div className="col-9">

                                                                <Select
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    onChange={(appId) => setAppId(appId)}
                                                                    value={appId}
                                                                    filterOption={(input, option) =>
                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                >
                                                                    {appList.map(type => (
                                                                        <Option key={type.id} value={type.id} >{type.name}</Option>
                                                                    ))}
                                                                </Select>
                                                                <span className="form-text text-muted"><p style={{}}>Please select an App</p></span>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Section <b>*</b></label>
                                                                <div className="col-9">

                                                                    <Select
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        onChange={(val) => {
                                                                            setSection(val)
                                                                        }}
                                                                        value={section}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {_data.SectionList.map(sec => (
                                                                            <Option key={sec.section_id} value={sec.section_id} >{sec.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                    <span className="form-text text-muted"><p style={{}}>Please Select Route</p></span>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div >

    )
}