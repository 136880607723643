import React, { useState } from 'react';

import {
  Input,
  Spin,
  Button,
  Typography,
  Modal
} from 'antd';

import { DateTime } from 'luxon'

import {
  successNotification,
  errorNotification,
  errorToaster
} from '../Shared/Notifications';


import SharedModel from '../../Models/SharedModel';

import { gridHeaderWithOutButton } from "styles/gridHeader";
import { validatePhone } from "Helpers/Shared/Validators";


const { Search } = Input;
const { Title } = Typography;
const { confirm } = Modal;


export default function AddAppVisibility() {
  const
    [loading, setLoading] = useState(false),
    [phone, set_phone] = useState(''),
    [id, set_id] = useState(null),
    [is_prepaid, set_is_prepaid] = useState(null),
    [last_subscription, set_last_subscription] = useState(null);


  function getVodafoneAppUserProfiles() {
    if (!validatePhone(phone)) {
      errorToaster("Please Enter Valid phone Number")
      return
    }
    setLoading(true);
    const params = { mobile: encodeURIComponent(phone) }
    SharedModel.getInstance().getAppUserProfile(
      params,
      function success(data) {
        setLoading(false);
        set_id(data.id);
        set_is_prepaid(renderAccountType(data.is_prepaid));
        set_last_subscription(data.app_user_subscription ? DateTime.fromISO(data.app_user_subscription.created_at).toLocaleString(DateTime.DATETIME_MED) : 'N/A');
      },
      function Failure(error) {
        setLoading(false);
        errorToaster("Request information not found");
      }
    );
  }

  const renderAccountType = (value) => {
    switch (value) {
      case true:
        return 'PREPAID'

      case false:
        return 'POSTPAID'

      default:
        return 'RESET'
    }
  }

  function showConfirm() {
    confirm({
      title: 'Do you Want to RESET This Phone Number?',
      content: phone,
      onOk() {
        updateAllVodafoneSupport();
      },
    });
  }

  function updateAllVodafoneSupport() {
    setLoading(true);
    const params = {
      is_prepaid: null,
    };
    SharedModel.getInstance().updateAllVodafoneSupport(
      id,
      params,
      function success() {
        successNotification("Account Type Reset Success");
        set_is_prepaid(renderAccountType(null));
        setLoading(false);
      },
      function failure(error) {
        errorNotification(error);
        setLoading(false);
        console.log(error);
      }
    );
  }




  return (
    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
      <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
        <Spin spinning={loading} >

          <div className="row">
            <div className="col-lg-12">
              <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                <div className="kt-portlet__head kt-portlet__head--lg">
                  {gridHeaderWithOutButton(
                    "Vodafone Support",
                  )}
                </div>

                <div className="kt-portlet__body">
                  <div className="kt-form" id="kt_form">
                    <div className="row">
                      <div className="col-xl-2"></div>
                      <div className="col-xl-8">
                        <div className="kt-section kt-section--first">
                          <div className="kt-section__body">

                            <div className="form-group row">
                              <label className="col-3 col-form-label">Phone Number <b>*</b></label>
                              <div className="col-9">
                                <Search
                                  // className="form-control"
                                  onChange={(event) => set_phone(event.target.value)}
                                  placeholder="Enter Phone Number"
                                  enterButton="Search"
                                  size="large"
                                  onPressEnter={getVodafoneAppUserProfiles}
                                  onSearch={getVodafoneAppUserProfiles}
                                />
                                <span className="form-text text-muted"><p style={{}}>Please Search Phone Number</p></span>
                              </div>
                            </div>

                            {
                              is_prepaid &&
                              <div className="form-group row">
                                <label className="col-3 col-form-label">Account Type</label>
                                <div className="col-9">
                                  <Title level={4}>{is_prepaid}</Title>
                                </div>
                              </div>
                            }
                            {
                              last_subscription &&
                              <div className="form-group row">
                                <label className="col-3 col-form-label">Last Subscription</label>
                                <div className="col-9">
                                  <Title level={4}>{last_subscription}</Title>
                                </div>
                              </div>
                            }
                            {
                              is_prepaid && is_prepaid !== "RESET" &&
                              <div className="form-group row">
                                <label className="col-3 col-form-label">Action</label>
                                <div className="col-9">
                                  <Button onClick={showConfirm} type="primary">Reset</Button>
                                </div>
                              </div>
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div >
    </div >

  )
}