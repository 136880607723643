import { validateURL } from "../../Helpers/Shared/Validators"

export function validateOnlineDealParams(params) {
    if (!params.merchant_id) {
        return {
            activeKey: '1',
            status: false,
            message: 'Please Select Merchant'
        }
    }
    if (params.programs.length < 1) {
        return {
            activeKey: '1',
            status: false,
            message: 'Please Select Programs'
        }
    }
    if (params.type === undefined) {
        return {
            activeKey: '1',
            status: false,
            message: 'Please Select Type'
        }
    }
    if (params.type === 3 && (params.user_limit < 1 || params.user_limit > 10)) {
        return {
            activeKey: '1',
            status: false,
            message: 'Please Select User Limit in between 1 to 10'
        }
    }
    if (params.name.length < 1) {
        return {
            activeKey: '1',
            status: false,
            message: 'Please Enter Name'
        }
    }
    if (!params.description || params.description.length < 1) {
        return {
            activeKey: '1',
            status: false,
            message: 'Please Enter Description'
        }
    }
    if (!params.product_visibility_id) {
        return {
            activeKey: '3',
            status: false,
            message: 'Please Select Visibility'
        }
    }
    return {
        status: true
    }
}

export function validateConfigurationParams(params, type) {
    if (type === 0) {
        if (!validateURL(params.video_url)) {
            return {
                activeKey: '3',
                status: false,
                message: 'Please enter valid Video URL in Configuration'
            }
        }
    }
    if (type === 1) {
        if (!validateURL(params.url)) {
            return {
                activeKey: '3',
                status: false,
                message: 'Please enter valid URL in Configuration'
            }
        }
    }
    if (type === 2) {
        // if (params.code.length < 3) {
        //     return {
        //         activeKey: '3',
        //         status: false,
        //         message: 'Please enter valid code in Configuration'
        //     }
        // }
        // if (params.max_usage < 1) {
        //     return {
        //         activeKey: '3',
        //         status: false,
        //         message: 'Max usage must be greater than 0 in Configuration'
        //     }
        // }
        if (!params.url || (params.url.length > 0 && !validateURL(params.url))) {
            return {
                activeKey: '3',
                status: false,
                message: 'Please enter valid URL in Configuration'
            }
        }
    }
    if (!params.category_id) {
        return {
            activeKey: '3',
            status: false,
            message: 'Please Select Category in Configuration'
        }
    }
    return {
        status: true
    }
}