import React from "react";
import { Select, Icon, Popconfirm, Spin, Switch } from "antd";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ValueTypeModel from "../../Models/ValueTypeModel";
import {
  infoNotification,
  errorNotification,
  errorToaster,
  infoToaster,
} from "../Shared/Notifications";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      totalPage: 1,
      perPage: 20,
      filters: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getValueType();
  }

  getType(text) {
    if ("currency".includes(text)) {
      return 1;
    }
    if ("point".includes(text)) {
      return 2;
    }
    if ("custom".includes(text)) {
      return 3;
    }
  }

  getValueType() {
    const it = this;
    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });
    if (params["program_value_types.type"]) {
      params["program_value_types.type"] = this.getType(
        params["program_value_types.type"]
      );
    }

    it.setState({ loading: true });
    ValueTypeModel.getInstance().searchValueType(
      params,
      function success(data) {
        it.setState({
          loading: false,
          data: ValueTypeModel.getInstance().ValueTypeList,
          totalPage: data.last_page,
          perPage: data.per_page,
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorToaster("Request information not found");
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getValueType();
  }, 1000);

  removeValueType(valueTypeId) {
    const it = this;
    it.setState({ loading: true });
    ValueTypeModel.getInstance().removeValueType(
      valueTypeId,
      function success() {
        it.setState({ data: ValueTypeModel.getInstance().ValueTypeList });
        it.setState({ loading: false });
        infoNotification("Value Type Deleted");
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={this.state.loading}>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              {gridHeaderWithButton(
                "Value Type",
                "la la-plus",
                "Add Value Type",
                "value-type/add"
              )}
            </div>
            <div className="kt-portlet__body">
              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={20}
                showPageSizeOptions={false}
                manual
                page={this.state.currentPage - 1}
                pages={this.state.totalPage}
                filtered={this.state.filters}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getValueType();
                  }, 50);
                }}
                onFilteredChange={(filters) => {
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                columns={[
                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    id: "program_value_types.name",
                    accessor: "name",
                    style: { whiteSpace: "unset" },
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Type</p>,
                    id: "program_value_types.type",
                    accessor: "value_type",
                    style: { whiteSpace: "unset" },
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["value_type"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Program</p>,
                    id: "programs.name",
                    accessor: "program",
                    style: { whiteSpace: "unset" },
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["program"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 150,
                    accessor: (d) => (
                      <div>
                        <Link
                          to={`value-type/edit/${d.id}`}
                          style={{ marginRight: 20 }}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="edit"
                          />
                        </Link>

                        <Popconfirm
                          title="Are you sure you want to delete this value type ?"
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() => this.removeValueType(d.id)}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="delete"
                          />
                        </Popconfirm>
                      </div>
                    ),
                    filterable: false,
                    sortable: false,
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
