import React from "react";
import { Input, Select, Spin, Icon, Tooltip } from "antd";
import { getCompressedImage } from "Helpers/Shared/utils";
import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
// import backImage from '../../../src/assets/media/users/default.jpg';
import UserModel from "../../Models/UserModel";
import SharedModel from "../../Models/SharedModel";

const { Option } = Select;

export default class AddLandmark extends React.Component {
  constructor() {
    super();
    this.state = {
      numSections: [1],
      areaList: [],
      areaId: null,
      cityId: null,
      tempLat: false,
      tempLng: false,
      landmarkNameError: false,
      areaError: false,
      addressError: false,
      arabic_nameError: false,
      arabic_addressError: false,
      typeError: false,
      floorError: false,
      sections: [],
      sectionsError: [],
      completeAddress: undefined,
      english_name: "",
      arabic_name: null,
      english_address: "",
      arabic_address: "",
      lat: 0,
      lng: 0,
      type: "",
      floor: 0,
      loading: false,
      landmarkId: null,
      serverImageSrc: null,
      serverBackgroundSrc: null,
      featureImage: null,
      featureImageName: null,
      featureImageError: false,
      backgroudImage: null,
      backgroundImageName: null,
      backgroundImageError: false,
      landmarkTypeList: [],
      nameError: false,
      cityList: [],
      cityError: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    let it = this;
    it.setState({ loading: true });
    const {
      match: { params },
    } = this.props;
    this.setState({ landmarkId: params.landmarkId });
    UserModel.getInstance().getLandmarkDetail(
      params.landmarkId,
      function success(data) {
        // data = data.landmark
        it.setState({
          loading: false,
          english_name: data.name,
          arabic_name: data.arabic_name ? data.arabic_name : null,
          english_address: data.address,
          arabic_address: data.arabic_address ? data.arabic_address : "",
          areaId: data.area_id,
          cityId: data.city_id,
          floor: data.floor,
          type: data.type_id,
          sections: data.sections ? data.sections : [],
          // completeAddress:data.meta.address,
          lat: data.lat,
          lng: data.lng,
          tempLat: data.tempLat,
          tempLng: data.tempLng,
          serverImageSrc: data.meta ? data.meta.image : null,
          serverBackgroundSrc: data.meta ? data.meta.background : null,
        });
        it.createArrays(data.sections);
        it.getCityArea(data.city_id);
      },
      function failure(error) {
        it.setState({ loading: false });
        console.error(error);
      }
    );
    UserModel.getInstance().getCities(
      0,
      function success() {
        it.setState({ cityList: UserModel.getInstance().CitiesList });
        // it.getCityArea(it.state.cityId)
      },
      function failure(error) {
        console.log(error);
        errorNotification(error);
      }
    );
    UserModel.getInstance().getLandmarkTypes(
      function success() {
        it.setState({
          landmarkTypeList: UserModel.getInstance().LandmarkTypeList,
        });
      },
      function failure(error) {
        console.log(error);
      }
    );
  }

  createArrays(sections) {
    const { sectionsError } = this.state;
    const appendSectionError = {
      nameError: false,
      arabic_nameError: false,
      floorError: false,
    };
    sections &&
      sections.map(() => {
        sectionsError.push(appendSectionError);
      });
  }

  saveFeatureImage(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ loading: true });
    getCompressedImage(file)
      .then((compressedImage) => {
        if (compressedImage) {
          this.setState({
            loading: false,
            featureImage: file,
            featureImageName: file.name,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        errorNotification(e);
      });
  }
  saveBackgroundImage(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ loading: true });
    getCompressedImage(file)
      .then((compressedImage) => {
        if (compressedImage) {
          this.setState({
            loading: false,
            backgroudImage: file,
            backgroundImageName: file.name,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        errorNotification(e);
      });
  }

  addSection() {
    const { numSections, sections, sectionsError } = this.state;
    const appendValue = numSections.length + 1;
    const appendSection = { name: "", arabic_name: "", floor: null };
    const appendSectionError = {
      nameError: false,
      arabic_nameError: false,
      floorError: false,
    };
    numSections.push(appendValue);
    sections.push(appendSection);
    sectionsError.push(appendSectionError);
    this.setState({ numSections, sections, sectionsError });
  }

  removeSection() {
    const { numSections, sections, sectionsError } = this.state;
    numSections.splice(-1, 1);
    sections.splice(-1, 1);
    sectionsError.splice(-1, 1);
    this.setState({ numSections, sections, sectionsError });
  }
  appendType() {
    let arr = this.state.landmarkTypeList;
    arr.push({ name: this.state.type });
    this.state.landmarkTypeList = arr;
    this.forceUpdate();
  }
  getAddress(address) {
    this.setState({
      english_address: address.formatted_address,
      // completeAddress: address,
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
    });
  }

  getCityArea(cityId) {
    let it = this;
    this.setState({ loading: true });
    let params = {
      id: cityId,
    };
    UserModel.getInstance().getArea(
      params,
      function success() {
        it.setState({
          areaList: UserModel.getInstance().AreaList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
        errorNotification(error);
      }
    );
  }

  sendData() {
    const it = this;
    const text = "Validation Error! Please check fields";

    let {
      english_name,
      arabic_name,
      english_address,
      arabic_address,
      type,
      floor,
      sections,
      lat,
      lng,
      areaId,
      cityId,
      landmarkId,
      backgroudImage,
      featureImage,
    } = this.state;

    this.setState({
      tempError: false,
      addressError: false,
      arabic_addressError: false,
      typeError: false,
      floorError: false,
      areaError: false,
      nameError: false,
      cityError: false,
    });
    this.state.sectionsError.map((section) => {
      section.nameError = false;
      section.arabic_nameError = false;
      section.floorError = false;
      this.forceUpdate();
    });

    if (english_name.length < 1) {
      this.setState({ nameError: true });
      errorToaster(text);
      return;
    }

    if (!this.state.serverImageSrc && !featureImage) {
      this.setState({ featureImageError: true });
      errorToaster(text);
      return;
    }
    if (!this.state.serverBackgroundSrc && !backgroudImage) {
      this.setState({ backgroundImageError: true });
      errorToaster(text);
      return;
    }

    if (!cityId) {
      this.setState({ cityError: true });
      errorToaster(text);
      return;
    }

    if (!areaId) {
      this.setState({ areaError: true });
      errorToaster(text);
      return;
    }

    if (english_address.length < 1) {
      this.setState({ addressError: true });
      errorToaster(text);
      return;
    }

    lat = Number(lat);
    lng = Number(lng);

    if (!lat || !lng) {
      this.setState({ tempError: true });
      errorToaster("Please Add lat and lng");
      return;
    }

    if (!type) {
      this.setState({ typeError: true });
      errorToaster(text);
      return;
    }
    // if (type.length < 1 || type.length > 21) {

    // }

    floor = Number(floor);

    if (floor < 0 || floor > 99) {
      this.setState({ floorError: true });
      errorToaster(text);
      return;
    }

    if (floor) {
      if (parseInt(floor) <= -1 || parseInt(floor) >= 100) {
        this.setState({ floorError: true });
        errorToaster(text);
        return;
      }
      if (
        parseFloat(floor) === parseFloat(floor) &&
        parseFloat(floor) % 1 !== 0
      ) {
        this.setState({ floorError: true });
        errorToaster(text);
        return;
      }
    }
    try {
      sections.forEach((section, index) => {
        if (section.name < 1) {
          this.state.sectionsError[index].nameError = true;
          this.forceUpdate();
          errorToaster(text);
          throw "break";
        }
        if (section.arabic_name < 1) {
          this.state.sectionsError[index].arabic_nameError = true;
          this.forceUpdate();
          errorToaster(text);
          throw "break";
        }
        if (
          !section.floor ||
          parseInt(section.floor) <= -1 ||
          parseInt(section.floor) >= 100
        ) {
          this.state.sectionsError[index].floorError = true;
          this.forceUpdate();
          errorToaster(text);
          throw "break";
        }
        if (
          parseFloat(section.floor) === parseFloat(section.floor) &&
          parseFloat(section.floor) % 1 !== 0
        ) {
          this.state.sectionsError[index].floorError = true;
          this.forceUpdate();
          errorToaster(text);
          throw "break";
        }
      });
      it.setState({ loading: true });
      var params = {
        name: english_name,
        arabic_name,
        arabic_address,
        address: english_address,
        type_id: type,
        floor,
        sections,
        city_id: cityId,
        area_id: areaId,
        lat,
        lng,

        // image:featureImage,
        // background:bac
      };
      UserModel.getInstance().updateLandmark(
        landmarkId,
        params,
        function success() {
          it.addMedia(featureImage, backgroudImage, landmarkId);
        },
        function failure(error) {
          it.setState({ loading: false });
          errorNotification(error);
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  addMedia(featureImage, backgroundImage, id) {
    const { history } = this.props;
    if (featureImage || backgroundImage) {
      let params = new FormData();
      if (featureImage) {
        params.append("image", featureImage);
      }
      if (backgroundImage) {
        params.append("background", backgroundImage);
      }
      let it = this;

      SharedModel.getInstance().addMediaInstance(
        id,
        "location/landmark",
        params,
        function success() {
          successNotification("Landmark Updated Successfully");
          setTimeout(() => {
            history.push("/landmark");
          }, 1000);
        },
        function failure(error) {
          it.setState({ loading: false });
          errorNotification(error);
          console.log(error);
        }
      );
    } else {
      successNotification("Landmark Updated Successfully");
      setTimeout(() => {
        history.push("/landmark");
      }, 1000);
    }
  }

  render() {
    const {
      areaId,
      areaList,
      numSections,
      sections,
      english_address,
      areaError,
      arabic_nameError,
      addressError,
      arabic_addressError,
      typeError,
      floorError,
      sectionsError,
      featureImageName,
      featureImageError,
      backgroundImageName,
      backgroundImageError,
      serverImageSrc,
      serverBackgroundSrc,
      nameError,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <Spin spinning={this.state.loading}>
          <div className="kt-content  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">Edit Landmark</h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/landmark"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Name
                                </label>
                                <div className="col-9">
                                  {/* <AutoComplete
                                                                        address={english_name}
                                                                        addressString={(english_name) => {
                                                                            let newAddress = english_name.split(",");
                                                                            this.setState({ english_name: newAddress[0] })

                                                                        }}
                                                                        getAddress={(address) => this.getAddress(address)}
                                                                        types={['establishment']}
                                                                        setAddress={(english_name) => this.setState({ english_name, completeAddress: null })}
                                                                    /> */}
                                  <Input
                                    value={this.state.english_name}
                                    onChange={(event) =>
                                      this.setState({
                                        english_name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter name in English
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Arabic Name
                                </label>
                                <div className="col-9">
                                  <input
                                    value={this.state.arabic_name}
                                    onChange={(event) =>
                                      this.setState({
                                        arabic_name: event.target.value,
                                      })
                                    }
                                    maxLength="100"
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: arabic_nameError ? "red" : "",
                                      }}
                                    >
                                      Please enter name in Arabic
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Upload Feature Image <b>*</b>
                                </label>
                                <div className="col-9">
                                  <div></div>
                                  <div>
                                    {" "}
                                    {serverImageSrc ? (
                                      <a href={serverImageSrc} target="_blank">
                                        Click here to see Feature Image
                                      </a>
                                    ) : null}{" "}
                                  </div>
                                  <div className="custom-file">
                                    <input
                                      maxLength="250"
                                      onChange={(event) =>
                                        this.saveFeatureImage(event)
                                      }
                                      type="file"
                                      className="custom-file-input"
                                      id="customFile"
                                    />
                                    <label
                                      className="custom-file-label"
                                      htmlFor="customFile"
                                    >
                                      {featureImageName
                                        ? featureImageName
                                        : "Choose file"}
                                    </label>
                                    <span className="form-text text-muted">
                                      <p
                                        style={{
                                          color: featureImageError ? "red" : "",
                                        }}
                                      >
                                        Please upload Feature Image
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Upload Background Image <b>*</b>
                                </label>
                                <div className="col-9">
                                  <div></div>
                                  <div>
                                    {" "}
                                    {serverBackgroundSrc ? (
                                      <a
                                        href={serverBackgroundSrc}
                                        target="_blank"
                                      >
                                        Click here to see Background Image
                                      </a>
                                    ) : null}{" "}
                                  </div>
                                  <div className="custom-file">
                                    <input
                                      maxLength="250"
                                      onChange={(event) =>
                                        this.saveBackgroundImage(event)
                                      }
                                      type="file"
                                      className="custom-file-input"
                                      id="customFile"
                                    />
                                    <label
                                      className="custom-file-label"
                                      htmlFor="customFile"
                                    >
                                      {backgroundImageName
                                        ? backgroundImageName
                                        : "Choose file"}
                                    </label>
                                    <span className="form-text text-muted">
                                      <p
                                        style={{
                                          color: backgroundImageError
                                            ? "red"
                                            : "",
                                        }}
                                      >
                                        Please upload Background Image
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  City *
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(cityId) => {
                                      this.getCityArea(cityId);
                                      this.setState({ areaId: null, cityId });
                                    }}
                                    value={this.state.cityId}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.state.cityList.map((city, key) => (
                                      <Option key={city.id} value={city.id}>
                                        {city.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: this.state.cityError
                                          ? "red"
                                          : "",
                                      }}
                                    >
                                      Please Select City
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Area
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    optionFilterProp="children"
                                    // className="form-control"
                                    onChange={(areaId) =>
                                      this.setState({ areaId })
                                    }
                                    value={areaId}
                                    // onFocus={onFocus}
                                    // onBlur={onBlur}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {areaList.map((area, key) => (
                                      <Option key={area.id} value={area.id}>
                                        {area.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: areaError ? "red" : "" }}
                                    >
                                      Please Select Area
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="exampleTextarea"
                                >
                                  Address in English
                                </label>
                                <div className="col-9">
                                  {/* <div className="input-group"> */}
                                  <textarea
                                    value={this.state.english_address}
                                    maxLength="100"
                                    onChange={(event) =>
                                      this.setState({
                                        english_address: event.target.value,
                                      })
                                    }
                                    value={english_address}
                                    className="form-control"
                                    rows="3"
                                  ></textarea>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: addressError ? "red" : "",
                                      }}
                                    >
                                      Please enter address in English
                                    </p>
                                  </span>

                                  {/* </div> */}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="exampleTextarea"
                                >
                                  Address in Arabic
                                </label>
                                <div className="col-9">
                                  {/* <div className="input-group"> */}
                                  <textarea
                                    value={this.state.arabic_address}
                                    onChange={(event) =>
                                      this.setState({
                                        arabic_address: event.target.value,
                                      })
                                    }
                                    maxLength="100"
                                    className="form-control"
                                    rows="3"
                                  ></textarea>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: arabic_addressError ? "red" : "",
                                      }}
                                    >
                                      Please enter address in Arabic
                                    </p>
                                  </span>

                                  {/* </div> */}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  lat & Lng
                                </label>
                                <div className="col-9">
                                  <div className="row">
                                    <Input
                                      // disabled={!this.state.fromCoordinates}
                                      style={{
                                        margin: "10px 0px 10px 0px",
                                        width: "30%",
                                        marginRight: "5%",
                                        marginLeft: "2%",
                                      }}
                                      value={this.state.lat}
                                      onChange={(event) =>
                                        this.setState({
                                          lat: event.target.value,
                                        })
                                      }
                                    />
                                    <Input
                                      // disabled={!this.state.fromCoordinates}
                                      style={{
                                        margin: "10px 0px 10px 0px",
                                        width: "30%",
                                        marginRight: "5%",
                                      }}
                                      value={this.state.lng}
                                      onChange={(event) =>
                                        this.setState({
                                          lng: event.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <span className="form-text text-muted">
                                    {" "}
                                    <p
                                      style={{
                                        color: this.state.tempError
                                          ? "red"
                                          : "",
                                      }}
                                    >
                                      {" "}
                                      Please enter lat & lng
                                    </p>
                                  </span>
                                </div>
                              </div>

                              {/* <div className="form-group row">
                                                                <div className="col-12">
                                                                    <div className="input-group">
                                                                        <Map
                                                                            containerElement={<div style={{ height: '300px', width: '100%' }} />}
                                                                            mapElement={<div style={{ height: '100%' }} />}
                                                                            lat={lat}
                                                                            lng={lng}
                                                                            isMarkerShown={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> */}

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="marchantCategory"
                                >
                                  Type *
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    optionFilterProp="children"
                                    // className="form-control"
                                    onChange={(value) =>
                                      this.setState({ type: value })
                                    }
                                    value={this.state.type}
                                    // onFocus={onFocus}
                                    // onBlur={onBlur}
                                    onSearch={(value) =>
                                      this.setState({ type: value })
                                    }
                                    notFoundContent={
                                      <Tooltip title="Add Type">
                                        <Icon
                                          onClick={() => this.appendType()}
                                          disabled={false}
                                          type="plus-circle"
                                          theme="filled"
                                          style={{
                                            color: "#3758ff",
                                            fontSize: "20px",
                                          }}
                                        />
                                      </Tooltip>
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.state.landmarkTypeList.map(
                                      (type, key) => (
                                        <Option key={key} value={type.id}>
                                          {type.name}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                  {/* <input value={this.state.type} onChange={(event) => this.setState({ type: event.target.value })} maxLength="20" className="form-control" type="text" /> */}
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: typeError ? "red" : "" }}
                                    >
                                      Please Enter Landmark Type
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Floors <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    value={
                                      this.state.floor ? this.state.floor : 0
                                    }
                                    onChange={(event) =>
                                      this.setState({
                                        floor: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: floorError ? "red" : "" }}
                                    >
                                      Please enter Floors in between 0 to 99
                                    </p>
                                  </span>
                                </div>
                              </div>

                              {sections.map((section, index) => (
                                <div key={index} className="wrapperApp">
                                  <div className="mydiv">
                                    <h5 className="title m-b-20">
                                      Section {index + 1}
                                    </h5>

                                    <div className="form-group row">
                                      <label className="col-3 col-form-label">
                                        Add Section
                                      </label>
                                      <div className="col-9">
                                        <input
                                          value={section.name}
                                          maxLength="20"
                                          onChange={(event) => {
                                            sections[index].name =
                                              event.target.value;
                                            this.forceUpdate();
                                          }}
                                          className="form-control"
                                        />
                                        <span className="form-text text-muted">
                                          <p
                                            style={{
                                              color: sectionsError[index]
                                                ? sectionsError[index].nameError
                                                  ? "red"
                                                  : ""
                                                : "",
                                            }}
                                          >
                                            Please enter section english
                                          </p>
                                        </span>
                                      </div>
                                    </div>

                                    <div className="form-group row">
                                      <label className="col-3 col-form-label">
                                        Add Section Arabic
                                      </label>
                                      <div className="col-9">
                                        <input
                                          value={section.arabic_name}
                                          maxLength="20"
                                          onChange={(event) => {
                                            sections[index].arabic_name =
                                              event.target.value;
                                            this.forceUpdate();
                                          }}
                                          className="form-control"
                                        />
                                        <span className="form-text text-muted">
                                          <p
                                            style={{
                                              color: sectionsError[index]
                                                ? sectionsError[index]
                                                    .arabic_nameError
                                                  ? "red"
                                                  : ""
                                                : "",
                                            }}
                                          >
                                            Please enter section Arabic
                                          </p>
                                        </span>
                                      </div>
                                    </div>

                                    <div className="form-group row">
                                      <label className="col-3 col-form-label">
                                        Enter Floor
                                      </label>
                                      <div className="col-9">
                                        <input
                                          value={parseInt(section.floor)}
                                          type="number"
                                          onChange={(event) => {
                                            sections[index].floor =
                                              event.target.value;
                                            this.forceUpdate();
                                          }}
                                          className="form-control"
                                        />
                                        <span className="form-text text-muted">
                                          <p
                                            style={{
                                              color: sectionsError[index]
                                                ? sectionsError[index]
                                                    .floorError
                                                  ? "red"
                                                  : ""
                                                : "",
                                            }}
                                          >
                                            Please enter Floor
                                          </p>
                                        </span>
                                      </div>
                                    </div>

                                    <hr></hr>
                                  </div>
                                </div>
                              ))}

                              <div className="form-group row m-t-30">
                                <div className="col-12">
                                  <button
                                    onClick={() => this.addSection()}
                                    type="button"
                                    className="btn btn-primary"
                                  >
                                    Add Section
                                  </button>
                                  <button
                                    disabled={
                                      numSections.length === 1 ? true : false
                                    }
                                    onClick={() => this.removeSection()}
                                    type="button"
                                    className="btn btn-secondary m-l-10"
                                  >
                                    Remove Section
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
