import React from "react";
import { Spin, Popover, Icon } from "antd";
import { DateTime } from "luxon";

import { errorToaster } from "../Shared/Notifications";

import { GridHeaderWithChildComponent } from "styles/gridHeader";
import ProductBookingModel from "../../Models/ProductBookingModel";
import { BookingGrid } from "./BookingGridView";
import { BookingCalendar } from "./CalendarView";

// const { Option } = Select;

export default class ProductBookingReport extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      countryList: [],
      loading: false,
      filters: [],
      pageCount: 10,
      offset: 0,
      total_pages: 1,
      currentPage: 1,
      is_calendar: false,
      calendarData: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  createPagination(total_count) {
    this.setState({
      total_pages: Math.ceil(total_count / this.state.pageCount),
    });
  }

  componentDidMount() {
    this.getProductBookingReports();
  }

  getProductBookingReports() {
    const it = this;
    it.setState({ loading: true });
    let params = {
      offset: (this.state.currentPage - 1) * this.state.pageCount,
      limit: this.state.pageCount,
    };
    this.state.filters.forEach((filter, i) => {
      params[filter.id] = filter.value;
    });

    switch (params["is_active"]) {
      case "all":
        delete params["is_active"];
        break;

      case "true":
        params["is_active"] = true;
        break;

      case "false":
        params["is_active"] = false;
        break;

      default:
        break;
    }

    ProductBookingModel.getInstance().getProductBookingReports(
      params,
      function success(data) {
        it.setState({
          data: data.records,
          loading: false,
        });
        it.createPagination(data.count);
      },
      function Failure(error) {
        it.setState({ loading: false });
        errorToaster("Request information not found");
      }
    );
  }
  getCalendarReportData(startTime, endTime) {
    const it = this;
    const params = {
      start_time: (DateTime.fromJSDate(startTime).setZone("Asia/Qatar").toISO()).replaceAll("+", encodeURIComponent("+")),
      end_time: (DateTime.fromJSDate(endTime).setZone("Asia/Qatar").toISO()).replaceAll("+", encodeURIComponent("+"))
    };
    ProductBookingModel.getInstance().getProductBookingCalendarReports(
      params,
      function success(params) {
        it.setState({ loading: false, calendarData: params.records });
      },
      function failure(error) {
        errorToaster("Request information not found");
        it.setState({ loading: false });
      }
    )
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getProductBookingReports();
  }, 1000);

  render() {
    const { data } = this.state;

    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              <GridHeaderWithChildComponent
                tableName="Product Booking Reports"
              >
                <div className="kt-portlet__head-toolbar">
                  <Popover
                    content={this.state.is_calendar ? "Show Grid" : "Show Calendar"}
                  >
                    <div className="kt-portlet__head-wrapper">
                      <div className="kt-portlet__head-actions">
                        <div
                          onClick={() => this.setState({ is_calendar: !this.state.is_calendar })}
                          className="btn btn-brand btn-elevate btn-icon-sm"
                        >
                          {
                            this.state.is_calendar ?
                              <Icon type="database" /> :
                              <Icon type="calendar" />
                          }
                        </div>
                      </div>
                    </div>
                  </Popover>
                </div>
              </GridHeaderWithChildComponent>
            </div>
            <div className="kt-portlet__body">
              <Spin spinning={this.state.loading}>
                {this.state.is_calendar
                  ?
                  <BookingCalendar
                    calendarData={this.state.calendarData}
                    getCalendarReportData={(start_date, end_date) => this.getCalendarReportData(start_date, end_date)}
                    history={this.props.history}
                  />
                  :
                  <BookingGrid
                    state={this.state}
                    setState={(value) => this.setState(value)}
                    getProductBookingReports={() => this.getProductBookingReports()}
                    searchQuery={() => this.searchQuery(this)}
                  />
                }

              </Spin>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
