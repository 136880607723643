import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel'



export default class CollectionModel {
    static myInstance = null;
    static getInstance() {
        if (CollectionModel.myInstance === null) {
            CollectionModel.myInstance = new CollectionModel();
        }
        return this.myInstance;
    }

    static CollectionList = [];



    getCollection(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getCollection(token, params,
            function resSuccess(data) {
                CollectionModel.getInstance().CollectionList = data.collections.data;
                successTrigger(data.collections);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }
    getCollectionByApp(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getCollectionByApp(token, params,
            function resSuccess(data) {
                successTrigger(data.collections);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    addCollection(name, position, merchant, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        let params = {};
        params.name = name;
        params.order = position;
        params.merchant_id = merchant;
        ApiManager.getInstance().addCollection(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    getCollectionDetail(collectionId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getCollectionDetail(token, collectionId,
            function resSuccess(data) {
                successTrigger(data.collection);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateCollection(collectionId, name, position, merchant, apps, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        let params = {};
        params.name = name;
        params.order = position;
        params.merchant_id = merchant;
        params.apps = apps;
        ApiManager.getInstance().updateCollection(token, params, collectionId,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }
    collectionActivation(collectionId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().collectionActivation(token, params, collectionId,
            function resSuccess(data) {
                CollectionModel.getInstance().CollectionList.forEach((collection, index) => {
                    if (collection.id === collectionId) {
                        collection.is_active = params.is_active;
                        successTrigger(data)
                    }
                });
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    removeCollection(collectionId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeCollection(token, collectionId,
            function resSuccess() {
                CollectionModel.getInstance().CollectionList.forEach((collection, index) => {
                    if (collection.id === collectionId) {
                        CollectionModel.getInstance().CollectionList.splice(index, 1);
                        successTrigger()
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    associateCollection(collectionId, merchants, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        let params = {};
        params.id = collectionId;
        params.merchant_id = merchants;
        ApiManager.getInstance().associateCollection(token, params,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }
}