import React from "react";
import { Select, Icon, Popconfirm, Spin, Switch } from "antd";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";
// import MerchantModel from "../../Models/MerchantModel";
import {
  infoNotification,
  errorNotification,
  errorToaster,
  // infoToaster,
} from "../Shared/Notifications";
// import ItemModel from "../../Models/ItemModel";
import CardModel from "../../Models/CardModel";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      totalPage: 1,
      perPage: 20,
      filters: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getCardSet();
  }

  getCardType(text) {
    if ("single".includes(text)) {
      return 1;
    }
    if ("multiple".includes(text)) {
      return 2;
    }
  }

  getCardSet() {
    const it = this;
    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });

    if (params["card_sets.is_active"] === "all") {
      delete params["card_sets.is_active"];
    }

    if (params["card_sets.reloadable"] === "all") {
      delete params["card_sets.reloadable"];
    }

    if (params["card_sets.card_number_type"]) {
      params["card_sets.card_number_type"] = this.getCardType(
        params["card_sets.card_number_type"]
      );
    }

    it.setState({ loading: true });
    CardModel.getInstance().searchCardSet(
      params,
      function success(data) {
        it.setState({
          loading: false,
          data: CardModel.getInstance().CardSetList,
          totalPage: data.last_page,
          perPage: data.per_page,
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorToaster("Request information not found");
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getCardSet();
  }, 1000);

  removeCardSet(cardSetId) {
    const it = this;
    it.setState({ loading: true });
    CardModel.getInstance().removeCardSet(
      cardSetId,
      function success() {
        it.setState({ data: CardModel.getInstance().CardSetList });
        it.setState({ loading: false });
        infoNotification("Card Set Deleted");
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  cardSetActivation(cardSetId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = !is_active;
    let params = {
      is_active: activation,
    };
    CardModel.getInstance().cardSetActivation(
      cardSetId,
      params,
      function success() {
        it.setState({ data: CardModel.getInstance().CardSetList });
        it.setState({ loading: false });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  getCard(cardSetId) {
    const it = this;
    it.setState({ loading: true });
    CardModel.getInstance().getCard(
      cardSetId,
      function success(data) {
        it.setState({
          loading: false,
          data: CardModel.getInstance().CardList,
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorToaster(JSON.stringify(error));
      }
    );
  }

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={this.state.loading}>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              {gridHeaderWithButton(
                "Card Sets",
                "la la-plus",
                "Add Card Set",
                "card-set/add"
              )}
            </div>
            <div className="kt-portlet__body">
              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={20}
                showPageSizeOptions={false}
                manual
                page={this.state.currentPage - 1}
                pages={this.state.totalPage}
                filtered={this.state.filters}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getCardSet();
                  }, 50);
                }}
                onFilteredChange={(filters) => {
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                columns={[
                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    id: "card_sets.name",
                    accessor: "name",
                    style: { whiteSpace: "unset" },
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Program</p>,
                    id: "programs.name",
                    accessor: "program_name",
                    style: { whiteSpace: "unset" },
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["program_name"],
                      }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>No. of Cards</p>,
                    id: "card_sets.card_number_type",
                    accessor: (d) => d.card_number_type,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["card_number_type"],
                      }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Reloadable</p>,
                    id: "card_sets.reloadable",
                    accessor: "reloadable",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Reloadable
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          Non-Reloadable
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value == "true") {
                        return row[filter.id] == true;
                      }
                      return row[filter.id] == false;
                    },
                    Filter: ({ filter, onChange }) =>
                      console.log("VALUE: ", filter) || (
                        <Select
                          onChange={(value) => onChange(value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <Option value="all">Show all</Option>
                          <Option value="true">Reloadable</Option>
                          <Option value="false">Non-Reloadable</Option>
                        </Select>
                      ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "card_sets.is_active",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value == "true") {
                        return row[filter.id] == true;
                      }
                      return row[filter.id] == false;
                    },
                    Filter: ({ filter, onChange }) =>
                      console.log("VALUE: ", filter) || (
                        <Select
                          onChange={(value) => onChange(value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <Option value="all">Show all</Option>
                          <Option value="true">Active</Option>
                          <Option value="false">De-Activate</Option>
                        </Select>
                      ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 200,
                    accessor: (d) => (
                      <div>
                        <Link
                          to={`card-set/edit/${d.id}`}
                          style={{ marginRight: 20 }}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="edit"
                          />
                        </Link>

                        <Popconfirm
                          title="Are you sure you want to delete this card set ?"
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() => this.removeCardSet(d.id)}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="delete"
                          />
                        </Popconfirm>

                        <Popconfirm
                          title={
                            d.is_active
                              ? "Are you sure you want to DEACTIVATE this card-set"
                              : "Are you sure you want to ACTIVATE this card-set"
                          }
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.cardSetActivation(d.id, d.is_active)
                          }
                        >
                          <Switch
                            style={{
                              backgroundColor: d.is_active
                                ? "#5d78ff"
                                : "#fd397a",
                              marginLeft: 15,
                            }}
                            checked={d.is_active}
                            onChange={(val) => console.log(val)}
                          />
                        </Popconfirm>

                        <Link to={`card/${d.id}`} style={{ marginLeft: 20 }}>
                          <Icon
                            onClick={() => this.getCard(d.id)}
                            style={{
                              color: "#5D78FF",
                              fontSize: 20,
                              marginRight: "10%",
                            }}
                            type="eye"
                          />
                        </Link>
                      </div>
                    ),
                    filterable: false,
                    sortable: false,
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
