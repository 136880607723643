import React from "react";
import { Spin, Divider, Button, Popconfirm, Modal, Icon } from "antd";
import UserModel from "../../Models/UserModel";
import {
  errorToaster,
  errorNotification,
  successNotification,
} from "./Notifications";
import Styles from "./OrderDetailStyles";

import moment from "moment";

export default class OrderDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      orderDetail: undefined,
      preparationTime: "",
      preparationTimeError: false,
      currentStatus: null,
      showUpdateStatusModal: false,
      allowEdit: false,
      orderStatus: null,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.getOrderDetail(params.orderId);
  }

  getOrderDetail(order_id) {
    const it = this;
    it.setState({ loading: true });
    UserModel.getInstance().getOrderDetail(
      order_id,
      function success(orderDetail) {
        it.setState({
          orderDetail,
          preparationTime: orderDetail.preparation_time,
          currentStatus: orderDetail.order_status_id,
          loading: false,
        });
      },
      function failure(error) {
        errorToaster("Doesn't open order details");
        it.setState({
          loading: false,
        });
        console.log(error);
      }
    );
  }

  renderPaymentMethod(method) {
    switch (method) {
      case 2:
        return "CREDIT CARD";

      case 3:
        return "DEBIT CARD";

      default:
        return "CASH ON DELIVERY";
    }
  }

  renderOrderStatus(status_id, status) {
    switch (status_id) {
      case 1:
        return (
          <div
            style={{
              height: "32px",
              padding: "0 15px",
              paddingTop: 8,
              textAlign: "center",
              justifyContent: "center",
              marginLeft: 20,
              borderRadius: 10,
              fontFamily: "Arial,HelveticaNeue",
              fontSize: 17,
            }}
          >
            <h6 style={{ color: "#FFFFFF" }}>{status}</h6>
          </div>
        );

      case 2:
        return (
          <div
            style={{
              height: "32px",
              padding: "0 15px",
              paddingTop: 8,
              textAlign: "center",
              justifyContent: "center",
              marginLeft: 20,
              borderRadius: 10,
              fontFamily: "Arial,HelveticaNeue",
              fontSize: 17,
            }}
          >
            <h6 style={{ color: "#FFFFFF" }}>{status}</h6>
          </div>
        );

      case 3:
        return (
          <div
            style={{
              height: "32px",
              padding: "0 15px",
              paddingTop: 8,
              textAlign: "center",
              justifyContent: "center",
              marginLeft: 20,
              borderRadius: 10,
              fontFamily: "Arial,HelveticaNeue",
              fontSize: 17,
            }}
          >
            <h6 style={{ color: "#FFFFFF" }}>{status}</h6>
          </div>
        );

      case 4:
        return (
          <div
            style={{
              height: "32px",
              padding: "0 15px",
              paddingTop: 8,
              textAlign: "center",
              justifyContent: "center",
              marginLeft: 20,
              borderRadius: 10,
              fontFamily: "Arial,HelveticaNeue",
              fontSize: 17,
            }}
          >
            <h6 style={{ color: "#6C7293" }}>{status}</h6>
          </div>
        );

      case 5:
        return (
          <div
            style={{
              height: "32px",
              padding: "0 15px",
              paddingTop: 4,
              textAlign: "center",
              justifyContent: "center",
              marginLeft: 20,
              borderRadius: 10,
              fontFamily: "Arial,HelveticaNeue",
              fontSize: 17,
            }}
          >
            <h6 style={{ color: "#0ABB87" }}>{status}</h6>
          </div>
        );

      case 6:
        return (
          <div
            style={{
              height: "32px",
              padding: "0 15px",
              paddingTop: 8,
              textAlign: "center",
              justifyContent: "center",
              marginRight: 10,
              borderRadius: 15,
              fontFamily: "Arial,HelveticaNeue",
              fontSize: 17,
            }}
          >
            <h6 style={{ color: "#FFFFFF" }}>{status}</h6>
          </div>
        );

      case 7:
        return (
          <div
            style={{
              height: "32px",
              padding: "0 15px",
              paddingTop: 8,
              textAlign: "center",
              justifyContent: "center",
              marginRight: 10,
              borderRadius: 15,
              fontFamily: "Arial,HelveticaNeue",
              fontSize: 17,
            }}
          >
            <h6 style={{ color: "#FFFFFF" }}>{status}</h6>
          </div>
        );

      case 8:
        return (
          <div
            style={{
              height: "32px",
              padding: "0 15px",
              paddingTop: 8,
              textAlign: "center",
              justifyContent: "center",
              marginRight: 10,
              borderRadius: 15,
              fontFamily: "Arial,HelveticaNeue",
              fontSize: 18,
            }}
          >
            <h6 style={{ color: "#0ABB87" }}>{status}</h6>
          </div>
        );

      default:
        return (
          <div
            style={{
              height: "32px",
              padding: "0 15px",
              paddingTop: 8,
              textAlign: "center",
              justifyContent: "center",
              marginLeft: 20,
              borderRadius: 10,
              fontFamily: "Arial,HelveticaNeue",
              fontSize: 17,
            }}
          >
            <h6 style={{ color: "#FFFFFF" }}>{status}</h6>
          </div>
        );
    }
  }

  updateOrderStatus(orderStatus) {
    const { preparationTime } = this.state,
      it = this;
    const {
      match: { params },
    } = this.props;

    let _params = {};
    this.setState({
      preparationTimeError: false,
    });
    if (orderStatus == 2) {
      if (!Number.isInteger(Number(preparationTime))) {
        this.setState({ preparationTimeError: true });
        errorToaster("Preparation Time must be Integer value");
        return;
      }
      if (Number(preparationTime) < 1 || Number(preparationTime) > 240) {
        this.setState({ preparationTimeError: true });
        errorToaster("Preparation Time must be in between 1 to 240");
        return;
      }
      _params.preparation_time = preparationTime;
    }

    _params.status = orderStatus;
    this.setState({ loading: true });
    UserModel.getInstance().updateOrderStatus(
      params.orderId,
      _params,
      function success(data) {
        successNotification("Status Updated");
        it.getOrderDetail(params.orderId);
        it.setState({ loading: false, showUpdateStatusModal: false });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  renderChangeStatusButton() {
    const { orderDetail } = this.state;
    switch (this.state.currentStatus) {
      case 1:
        return (
          <div
            style={{
              display: "flex",
              margin: "10px",
              paddingTop: 5,
              width: "100%",
              height: "45px",
              backgroundColor: "#FD397A",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            {orderDetail
              ? this.renderOrderStatus(
                  orderDetail.order_status_id,
                  orderDetail.status
                )
              : null}
            <Button
              type="button"
              onClick={() =>
                this.showOrderUpdateStatus(
                  orderDetail.id,
                  orderDetail.order_status_id,
                  orderDetail.preparation_time,
                  orderDetail.order_cart
                )
              }
              style={{
                marginLeft: "auto",
                marginRight: "15px",
                width: "90px",
                backgroundColor: "#FD397A",
                color: "#FFFFFF",
                borderRadius: "2px",
                border: "1px solid #FFFFFF",
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              <b>ACCEPT</b>
            </Button>
          </div>
        );

      case 2:
        return (
          <div
            style={{
              display: "flex",
              margin: "10px",
              paddingTop: 5,
              width: "100%",
              height: "45px",
              backgroundColor: "#FFB822",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            {orderDetail
              ? this.renderOrderStatus(
                  orderDetail.order_status_id,
                  orderDetail.status
                )
              : null}
            <Popconfirm
              title="Are you sure you want to dispatched this order?"
              onConfirm={() => this.updateOrderStatus(7)}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button
                style={{
                  fontFamily: "Arial,HelveticaNeue",
                  marginLeft: "auto",
                  marginRight: "20px",
                  width: "100px",
                  border: "1px solid #FFFFFF",
                  backgroundColor: "#FFB822",
                  color: "#FFFFFF",
                  borderRadius: "2px",
                }}
              >
                <b>DISPATCH</b>
              </Button>
            </Popconfirm>
          </div>
        );

      case 4:
        return (
          <div
            style={{
              display: "flex",
              margin: "10px",
              paddingTop: 5,
              width: "100%",
              height: "45px",
              backgroundColor: "#F0F1F4",
              fontFamily: "Arial,HelveticaNeue",
              fontSize: 17,
            }}
          >
            {orderDetail
              ? this.renderOrderStatus(
                  orderDetail.order_status_id,
                  orderDetail.status
                )
              : null}
          </div>
        );

      case 5:
        return (
          <div
            style={{
              display: "flex",
              margin: "10px",
              paddingTop: 5,
              width: "100%",
              height: "45px",
              backgroundColor: "#F0F1F4",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            {orderDetail
              ? this.renderOrderStatus(
                  orderDetail.order_status_id,
                  orderDetail.status
                )
              : null}
          </div>
        );

      case 6:
        return (
          <div
            style={{
              display: "flex",
              margin: "10px",
              paddingTop: 5,
              width: "100%",
              height: "45px",
              backgroundColor: "#6C7293",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            {orderDetail
              ? this.renderOrderStatus(
                  orderDetail.order_status_id,
                  orderDetail.status
                )
              : null}
            {orderDetail.payment_method == 1 ? (
              <Button
                onClick={() =>
                  this.showOrderUpdateStatus(
                    orderDetail.id,
                    orderDetail.order_status_id,
                    orderDetail.preparation_time,
                    orderDetail.order_cart
                  )
                }
                style={{
                  marginLeft: "auto",
                  marginRight: "15px",
                  width: "90px",
                  backgroundColor: "#6C7293",
                  color: "#FFFFFF",
                  borderRadius: "2px",
                  border: "1px solid #FFFFFF",
                  fontFamily: "Arial,HelveticaNeue",
                }}
              >
                <b>ACCEPT</b>
              </Button>
            ) : null}
          </div>
        );

      case 7:
        return (
          <div
            style={{
              display: "flex",
              margin: "10px",
              paddingTop: 5,
              width: "100%",
              height: "45px",
              backgroundColor: "#0ABB87",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            {orderDetail
              ? this.renderOrderStatus(
                  orderDetail.order_status_id,
                  orderDetail.status
                )
              : null}
            <Popconfirm
              title="Are you sure you want to delivered this order?"
              onConfirm={() => this.updateOrderStatus(8)}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button
                style={{
                  fontFamily: "Arial,HelveticaNeue",
                  marginLeft: "auto",
                  marginRight: "20px",
                  width: "110px",
                  border: "1px solid #FFFFFF",
                  backgroundColor: "#0ABB87",
                  color: "#FFFFFF",
                  borderRadius: "2px",
                }}
              >
                <b>DELIVERED</b>
              </Button>
            </Popconfirm>
          </div>
        );

      case 8:
        return (
          <div
            style={{
              display: "flex",
              margin: "10px",
              paddingTop: 5,
              width: "100%",
              height: "45px",
              backgroundColor: "#F0FFF0",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            {orderDetail
              ? this.renderOrderStatus(
                  orderDetail.order_status_id,
                  orderDetail.status
                )
              : null}
          </div>
        );

      default:
        break;
    }
  }

  openModal() {
    this.setState({ showUpdateStatusModal: true });
  }

  renderButton() {
    const { orderDetail } = this.state;
    switch (this.state.currentStatus) {
      case 1:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            <Button
              style={{
                width: "163",
                marginRight: "10px",
                color: "#A2A3B7",
                backgroundColor: "#FFFFFF",
                fontSize: 17,
                cursor: "not-allowed",
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              CANCEL THIS ORDER
            </Button>
            <Button
              style={{
                width: "193",
                marginRight: "20px",
                backgroundColor: "#A2A3B7",
                color: "#FFFFFF",
                fontSize: 17,
                cursor: "not-allowed",
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              REFUND{" "}
            </Button>
          </div>
        );

      case 2:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            <Popconfirm
              title="Are you sure you want to cancel this order?"
              onConfirm={() => this.updateOrderStatus(4)}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button
                style={{
                  width: "163",
                  marginRight: "10px",
                  color: "#5D78FF",
                  fontSize: 17,
                  backgroundColor: "#FFFFFF",
                  fontFamily: "Arial,HelveticaNeue",
                }}
              >
                CANCEL THIS ORDER
              </Button>
            </Popconfirm>
            <Button
              style={{
                width: "193",
                marginRight: "20px",
                backgroundColor: "#A2A3B7",
                color: "#FFFFFF",
                fontSize: 17,
                cursor: "not-allowed",
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              REFUND{" "}
            </Button>
          </div>
        );

      case 3:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            <Popconfirm
              title="Are you sure you want to cancel this order?"
              onConfirm={() => this.updateOrderStatus(4)}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button
                style={{
                  width: "163",
                  marginRight: "10px",
                  color: "#5D78FF",
                  fontFamily: "Arial,HelveticaNeue",
                  fontSize: 17,
                  backgroundColor: "#FFFFFF",
                }}
              >
                CANCEL THIS ORDER
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure you want to refund this order?"
              onConfirm={() => this.updateOrderStatus(5)}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button
                style={{
                  width: "193",
                  marginRight: "20px",
                  backgroundColor: "#5D78FF",
                  color: "#FFFFFF",
                  fontSize: 17,
                  fontFamily: "Arial,HelveticaNeue",
                }}
              >
                REFUND
              </Button>
            </Popconfirm>
          </div>
        );

      case 4:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            <Button
              style={{
                width: "306",
                marginRight: "10px",
                color: "#A2A3B7",
                backgroundColor: "#FFFFFF",
                fontSize: 17,
                cursor: "not-allowed",
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              CANCEL THIS ORDER
            </Button>
            {orderDetail.payment_method == 1 ? (
              <Button
                style={{
                  width: "193",
                  marginRight: "20px",
                  backgroundColor: "#A2A3B7",
                  color: "#FFFFFF",
                  fontSize: 17,
                  cursor: "not-allowed",
                  fontFamily: "Arial,HelveticaNeue",
                }}
              >
                REFUND{" "}
              </Button>
            ) : (
              <Popconfirm
                title="Are you sure you want to refund this order?"
                onConfirm={() => this.updateOrderStatus(5)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{
                    width: "193",
                    marginRight: "20px",
                    backgroundColor: "#5D78FF",
                    color: "#FFFFFF",
                    fontSize: 17,
                    fontFamily: "Arial,HelveticaNeue",
                  }}
                >
                  REFUND
                </Button>
              </Popconfirm>
            )}
          </div>
        );

      case 5:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            <Button
              style={{
                width: "306",
                marginRight: "10px",
                color: "#A2A3B7",
                backgroundColor: "#FFFFFF",
                fontSize: 17,
                cursor: "not-allowed",
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              CANCEL THIS ORDER
            </Button>
            <Button
              style={{
                width: "193",
                marginRight: "20px",
                backgroundColor: "#A2A3B7",
                color: "#FFFFFF",
                fontSize: 17,
                cursor: "not-allowed",
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              REFUND{" "}
            </Button>
          </div>
        );

      case 6:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            <Button
              style={{
                width: "306",
                marginRight: "10px",
                color: "#A2A3B7",
                backgroundColor: "#FFFFFF",
                fontSize: 17,
                cursor: "not-allowed",
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              CANCEL THIS ORDER
            </Button>
            {orderDetail.payment_method == 1 ? (
              <Button
                style={{
                  width: "193",
                  marginRight: "20px",
                  backgroundColor: "#A2A3B7",
                  color: "#FFFFFF",
                  fontSize: 17,
                  cursor: "not-allowed",
                  fontFamily: "Arial,HelveticaNeue",
                }}
              >
                REFUND{" "}
              </Button>
            ) : (
              <Popconfirm
                title="Are you sure you want to refund this order?"
                onConfirm={() => this.updateOrderStatus(5)}
                okText="Yes"
                cancelText="Cancel"
              >
                <Button
                  style={{
                    width: "193",
                    marginRight: "20px",
                    backgroundColor: "#5D78FF",
                    color: "#FFFFFF",
                    fontSize: 17,
                    fontFamily: "Arial,HelveticaNeue",
                  }}
                >
                  REFUND
                </Button>
              </Popconfirm>
            )}
          </div>
        );

      case 7:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            <Popconfirm
              title="Are you sure you want to cancel this order?"
              onConfirm={() => this.updateOrderStatus(4)}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button
                style={{
                  width: "163",
                  marginRight: "10px",
                  color: "#5D78FF",
                  fontFamily: "Arial,HelveticaNeue",
                  fontSize: 17,
                  backgroundColor: "#FFFFFF",
                }}
              >
                CANCEL THIS ORDER
              </Button>
            </Popconfirm>
            <Button
              style={{
                width: "193",
                marginRight: "20px",
                backgroundColor: "#A2A3B7",
                color: "#FFFFFF",
                fontSize: 17,
                cursor: "not-allowed",
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              REFUND{" "}
            </Button>
          </div>
        );

      case 8:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontFamily: "Arial,HelveticaNeue",
            }}
          >
            <Button
              style={{
                width: "163",
                marginRight: "10px",
                color: "#A2A3B7",
                backgroundColor: "#FFFFFF",
                fontSize: 17,
                cursor: "not-allowed",
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              CANCEL THIS ORDER
            </Button>
            <Button
              style={{
                width: "193",
                marginRight: "20px",
                backgroundColor: "#A2A3B7",
                color: "#FFFFFF",
                fontSize: 17,
                cursor: "not-allowed",
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              REFUND{" "}
            </Button>
          </div>
        );

      default:
        break;
    }
  }

  renderStatusUpdateView(orderStatus) {
    switch (orderStatus) {
      case 1:
        return (
          <div>
            <Button
              onClick={() => this.updateOrderStatus(2)}
              style={{
                marginRight: "10px",
                width: "100%",
                height: 40,
                backgroundColor: "#FD397A",
                color: "#FFFFFF",
                fontSize: 17,
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              ACCEPT THIS ORDER
            </Button>
            <Popconfirm
              title="Are you sure you want to reject this order?"
              onConfirm={() => this.updateOrderStatus(6)}
              okText="Yes"
              cancelText="No"
            >
              <div
                style={{
                  marginTop: 15,
                  width: "100%",
                  marginLeft: "180px",
                  fontSize: 17,
                  cursor: "pointer",
                  fontFamily: "Arial,HelveticaNeue",
                }}
              >
                <u>Reject this order</u>
              </div>
            </Popconfirm>
          </div>
        );

      case 6:
        return (
          <div>
            <Button
              onClick={() => this.updateOrderStatus(2)}
              style={{
                marginRight: "10px",
                width: "100%",
                height: 40,
                backgroundColor: "#FD397A",
                color: "#FFFFFF",
                fontSize: 17,
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              ACCEPT THIS ORDER
            </Button>
          </div>
        );

      default:
        return <div />;
    }
  }
  showOrderUpdateStatus(order_id, orderStatus, preparationTime, order_cart) {
    this.setState({
      currentOrderId: order_id,
      showUpdateStatusModal: true,
      orderStatus,
      preparationTime,
      order_cart,
    });
    this.setState({ allowEdit: true });
  }

  closeOrderStatuModal() {
    this.setState({
      currentOrderId: null,
      showUpdateStatusModal: false,
      orderStatus: null,
      allowEdit: false,
      preparationTime: "",
      order_cart: undefined,
    });
  }

  render() {
    const { orderDetail, preparationTimeError } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={this.state.loading}>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              <div className="kt-portlet__head-label">
                <span className="kt-portlet__head-icon">
                  <i className="kt-font-brand flaticon2-line-chart"></i>
                </span>
                <div
                  style={{
                    color: "#5D78FF",
                    marginLeft: "5px",
                    fontSize: 17,
                    fontFamily: "Arial,HelveticaNeue",
                  }}
                >
                  <b>{"Order Log"}</b>
                </div>
                <div
                  style={{
                    color: "#6C7293",
                    marginLeft: "10px",
                    fontSize: 19,
                    fontFamily: "Arial,HelveticaNeue",
                  }}
                >
                  <b>{">"}</b>
                </div>
                <h3
                  style={{
                    fontFamily: "Arial,HelveticaNeue",
                    color: "#6C7293",
                  }}
                  className="kt-portlet__head-title kt-margin-l-5"
                >
                  {orderDetail ? orderDetail.order_number : null}
                </h3>
                <h3
                  style={{
                    height: "30px",
                    padding: "0 5px",
                    paddingTop: 5,
                    textAlign: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                    marginLeft: 15,
                    borderRadius: "1px",
                    color: "#FD397A",
                    border: "1px solid red",
                    fontSize: 14,
                    fontFamily: "Arial,HelveticaNeue",
                  }}
                >
                  {orderDetail
                    ? this.renderPaymentMethod(orderDetail.payment_method)
                    : null}
                </h3>
              </div>
            </div>
            <div style={Styles.userDataContainer} className="row">
              {this.renderChangeStatusButton()}
            </div>

            {orderDetail ? (
              <div className="kt-portlet__body">
                <div style={Styles.userDataContainer}>
                  <div style={Styles.orderContainer}>
                    <div className="row">
                      <h3 style={Styles.cardHeading}>Order Details</h3>
                      <div
                        style={{
                          margin: "auto",
                          paddingTop: 3,
                          fontFamily: "Arial,HelveticaNeue",
                        }}
                      >{`Date & Time: ${moment(orderDetail.order_time).format(
                        "D/MMM/YYYY,hh:mm A"
                      )}`}</div>
                    </div>
                    <div
                      style={{
                        width: "139%",
                        height: "250px",
                        overflow: "auto",
                        color: "#6C7293",
                      }}
                    >
                      <table
                        className="table"
                        borderCollapse="collapse"
                        border="1"
                        width="930px"
                        height="250px"
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "8px",
                                borderBottom: "1px solid #ddd",
                                fontFamily: "Arial,HelveticaNeue",
                                fontSize: 16,
                                color: "#6C7293",
                              }}
                            >
                              Item Name
                            </th>
                            <th
                              style={{
                                padding: "8px",
                                borderBottom: "1px solid #ddd",
                                width: "50px",
                                fontFamily: "Arial,HelveticaNeue",
                                fontSize: 16,
                                color: "#6C7293",
                              }}
                            >
                              Quantity
                            </th>
                            <th
                              style={{
                                padding: "8px",
                                borderBottom: "1px solid #ddd",
                                width: "100px",
                                fontFamily: "Arial,HelveticaNeue",
                                fontSize: 16,
                                color: "#6C7293",
                              }}
                            >
                              Price
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderDetail.order_cart.items.map((item, i) => (
                            <tr key={i}>
                              <td
                                style={{
                                  padding: "8px",
                                  borderBottom: "1px solid #ddd",
                                  fontSize: 14,
                                  color: "#6C7293",
                                  fontFamily: "Arial,HelveticaNeue",
                                }}
                              >
                                {item.name}
                                <ul>
                                  {item.addons.map((addon, j) => (
                                    <li key={j}>{addon.name}</li>
                                  ))}
                                </ul>
                              </td>
                              <td
                                style={{
                                  padding: "8px",
                                  borderBottom: "1px solid #ddd",
                                  width: "50px",
                                  fontSize: 14,
                                  color: "#6C7293",
                                  fontFamily: "Arial,HelveticaNeue",
                                }}
                              >
                                {item.count}
                              </td>
                              <td
                                style={{
                                  padding: "8px",
                                  borderBottom: "1px solid #ddd",
                                  width: "100px",
                                  fontSize: 14,
                                  color: "#6C7293",
                                  fontFamily: "Arial,HelveticaNeue",
                                }}
                              >
                                {item.count * item.price}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "95.5%",
                    border: "1px solid #EBEDF2",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        width: 90,
                        marginLeft: "15px",
                        paddingTop: 5,
                        float: "left",
                        fontFamily: "Arial,HelveticaNeue",
                        color: "#6C7293",
                      }}
                    >
                      Sub Total
                    </div>
                    <div
                      style={{
                        width: "80px",
                        marginRight: "10px",
                        paddingTop: 5,
                        float: "right",
                        fontSize: 15,
                        fontFamily: "Arial,HelveticaNeue",
                        color: "#6C7293",
                      }}
                    >
                      <p>QR {orderDetail.sub_total}</p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "95.5%",
                    border: "1px solid #EBEDF2",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    color: "#6C7293",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        width: 90,
                        marginLeft: "15px",
                        paddingTop: 5,
                        float: "left",
                        fontFamily: "Arial,HelveticaNeue",
                        color: "#6C7293",
                      }}
                    >
                      Delivery Fee
                    </div>
                    <div
                      style={{
                        width: "80px",
                        marginRight: "10px",
                        paddingTop: 5,
                        float: "right",
                        fontSize: 15,
                        fontFamily: "Arial,HelveticaNeue",
                        color: "#6C7293",
                      }}
                    >
                      <p>QR {orderDetail.delivery_charges}</p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "95.5%",
                    border: "1px solid #EBEDF2",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    color: "#6C7293",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        width: 90,
                        marginLeft: "15px",
                        paddingTop: 5,
                        float: "left",
                        fontFamily: "Arial,HelveticaNeue",
                        color: "#6C7293",
                      }}
                    >
                      <h6 style={{ color: "red" }}>Discount</h6>
                    </div>
                    <div
                      style={{
                        width: "80px",
                        marginRight: "10px",
                        paddingTop: 5,
                        float: "right",
                        fontSize: 15,
                        fontFamily: "Arial,HelveticaNeue",
                        color: "#6C7293",
                      }}
                    >
                      <p style={{ color: "red" }}>-QR {orderDetail.discount}</p>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "95.5%",
                    border: "1px solid #EBEDF2",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    color: "#6C7293",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        width: 150,
                        paddingTop: 5,
                        marginLeft: "15px",
                        float: "left",
                        fontFamily: "Arial,HelveticaNeue",
                        color: "#6C7293",
                      }}
                    >
                      <strong>Grand Total</strong>
                    </div>
                    <div
                      style={{
                        width: "80px",
                        marginRight: "10px",
                        paddingTop: 5,
                        float: "right",
                        fontSize: 15,
                        fontFamily: "Arial,HelveticaNeue",
                        color: "#6C7293",
                      }}
                    >
                      <p>
                        <strong>QR {orderDetail.total_amount}</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <br></br>
                <div
                  style={{
                    color: "#6C7293",
                    fontFamily: "Arial,HelveticaNeue",
                    fontSize: 17,
                    width: 170,
                    height: 19,
                  }}
                >
                  Special Instructions
                </div>
                <br></br>
                <div
                  style={{
                    color: "#6C7293",
                    fontSize: 15,
                    fontFamily: "Arial,HelveticaNeue",
                  }}
                >
                  {orderDetail.order_cart.special_instruction}
                </div>
                <br></br>
                <div className="row">
                  <Icon
                    style={{
                      gcolor: "#6C7293",
                      paddingTop: 15,
                      fontSize: 16,
                      marginLeft: "5px",
                    }}
                    type="history"
                  />
                  <label
                    style={{
                      paddingTop: 10,
                      marginLeft: "10px",
                      fontSize: 17,
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                      fontWeight: 400,
                    }}
                  >
                    Preparation Time :{" "}
                  </label>
                  <div className="col-1">
                    <input
                      style={{ width: "67px" }}
                      value={this.state.preparationTime}
                      onChange={(event) =>
                        this.setState({ preparationTime: event.target.value })
                      }
                      maxLength="3"
                      min="1"
                      max="240"
                      className="form-control"
                      type="number"
                    />
                  </div>
                  <div
                    style={{
                      paddingTop: 10,
                      marginLeft: "5px",
                      fontSize: 17,
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                      fontWeight: 200,
                    }}
                  >{`Min`}</div>
                  <Icon
                    onClick={() =>
                      this.updateOrderStatus(orderDetail.order_status_id)
                    }
                    style={{
                      color: "#5D78FF",
                      paddingTop: 10,
                      fontSize: 23,
                      marginLeft: "10px",
                    }}
                    type="safety"
                  />
                  <p
                    style={{
                      paddingTop: 13,
                      marginLeft: "5px",
                      fontSize: 14,
                      fontFamily: "Arial,HelveticaNeue",
                      color: preparationTimeError ? "red" : "#6C7293",
                    }}
                  >
                    Please enter Preparation time
                  </p>
                </div>
                <Divider />

                <div>
                  <h3 style={{ color: "#6C7293" }}>Delivery Details</h3>
                  <br></br>
                  <div className="row">
                    <Icon
                      style={{
                        color: "#6C7293",
                        fontSize: 16,
                        marginLeft: "10px",
                      }}
                      type="user"
                    />
                    <h6
                      style={{
                        marginLeft: "10px",
                        fontSize: 17,
                        fontFamily: "Arial,HelveticaNeue",
                        color: "#6C7293",
                      }}
                    >
                      {orderDetail.customer_name}
                    </h6>
                  </div>
                  <br></br>
                  <div className="row">
                    <Icon
                      style={{
                        color: "#6C7293",
                        fontSize: 16,
                        marginLeft: "10px",
                      }}
                      type="mobile"
                    />
                    <h6
                      style={{
                        marginLeft: "10px",
                        fontSize: 17,
                        fontFamily: "Arial,HelveticaNeue",
                        color: "#6C7293",
                      }}
                    >
                      {orderDetail.mobile}
                    </h6>
                  </div>
                  <br></br>
                  <div className="row">
                    <Icon
                      style={{
                        color: "#6C7293",
                        fontSize: 16,
                        marginLeft: "10px",
                      }}
                      type="environment"
                    />
                    <h6
                      style={{
                        marginLeft: "10px",
                        fontSize: 17,
                        fontFamily: "Arial,HelveticaNeue",
                        color: "#6C7293",
                      }}
                    >{`${orderDetail.delivery_address.address_name} , ${orderDetail.delivery_address.complete_address} , ${orderDetail.delivery_address.instruction}`}</h6>
                    <a
                      style={{ marginLeft: "10px" }}
                      target="_blank"
                      href={`https://maps.google.com/?q=${orderDetail.delivery_address.lat},${orderDetail.delivery_address.lng}`}
                    >
                      <h6
                        style={{
                          color: "#5D78FF",
                          fontFamily: "Arial,HelveticaNeue",
                          fontSize: 17,
                        }}
                      >
                        <u>{"Map"}</u>
                      </h6>
                    </a>
                  </div>
                </div>

                <Divider />

                {this.renderButton()}

                <Modal
                  title="Order Status"
                  visible={this.state.showUpdateStatusModal}
                  onOk={() => this.updateOrderStatus(2)}
                  onCancel={() =>
                    this.setState({ showUpdateStatusModal: false })
                  }
                >
                  <br></br>
                  <h2>
                    <center>Update Order Status</center>
                  </h2>
                  <br></br>
                  <div className="form-group row">
                    <label className="col-3 col-form-label">
                      Preparation Time (in Minutes)
                    </label>
                    <div className="col-9">
                      <input
                        value={this.state.preparationTime}
                        onChange={(event) =>
                          this.setState({ preparationTime: event.target.value })
                        }
                        maxLength="3"
                        min="1"
                        max="240"
                        className="form-control"
                        type="number"
                      />
                      <span className="form-text text-muted">
                        <p style={{ color: preparationTimeError ? "red" : "" }}>
                          Please enter Preparation time
                        </p>
                      </span>
                    </div>
                  </div>
                </Modal>

                <Modal
                  title={`New Order : ${orderDetail.order_number}`}
                  visible={this.state.showUpdateStatusModal}
                  footer={null}
                  onCancel={() =>
                    this.setState({
                      showUpdateStatusModal: false,
                      preparationTime: "",
                    })
                  }
                >
                  <div
                    style={{
                      width: "450px",
                      height: "120px",
                      overflow: "auto",
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    <table width="430px" height="120px">
                      {this.state.order_cart
                        ? this.state.order_cart.items.map((item, i) => (
                            <div key={i}>
                              <h6
                                style={{
                                  marginLeft: "10px",
                                  paddingTop: "5px",
                                  fontFamily: "Arial,HelveticaNeue",
                                  color: "#6C7293",
                                }}
                              >{`${item.count}x ${item.name}`}</h6>
                              <ul>
                                {item.addons.map((addon, j) => (
                                  <li key={j}>{addon.name}</li>
                                ))}
                              </ul>
                              <Divider />
                            </div>
                          ))
                        : null}
                    </table>
                  </div>
                  <div
                    style={{
                      color: "#6C7293",
                      fontSize: 17,
                      width: 170,
                      height: 19,
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    Special Instructions
                  </div>
                  <br></br>
                  <div
                    style={{
                      color: "#6C7293",
                      fontSize: 15,
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    {orderDetail.order_cart.special_instruction}
                  </div>
                  <br></br>
                  <div className="row">
                    <Icon
                      style={{
                        color: "#6C7293",
                        paddingTop: 10,
                        fontSize: 16,
                        marginLeft: "10px",
                      }}
                      type="history"
                    />
                    <label
                      style={{
                        paddingTop: 10,
                        marginLeft: "10px",
                        fontFamily: "Arial,HelveticaNeue",
                        color: "#6C7293",
                        fontWeight: 400,
                      }}
                    >
                      Preparation Time :{" "}
                    </label>
                    <div className="col-3">
                      <input
                        value={orderDetail.preparation_time}
                        onChange={(event) =>
                          this.setState({ preparationTime: event.target.value })
                        }
                        maxLength="3"
                        min="1"
                        max="240"
                        className="form-control"
                        type="number"
                      />
                    </div>
                    <div
                      style={{
                        paddingTop: 10,
                        marginLeft: "5px",
                        fontSize: 17,
                        fontFamily: "Arial,HelveticaNeue",
                        color: "#6C7293",
                        fontWeight: 200,
                      }}
                    >{`Min`}</div>
                    <p
                      style={{
                        marginLeft: "15px",
                        paddingTop: 10,
                        color: preparationTimeError ? "red" : "#6C7293",
                      }}
                    >
                      Please enter Preparation time
                    </p>
                  </div>
                  {this.renderStatusUpdateView(this.state.orderStatus)}
                </Modal>
              </div>
            ) : null}
          </div>
        </Spin>
      </div>
    );
  }
}
