import React from "react";
import { Select, Spin, DatePicker, Checkbox, Icon } from "antd";
import { getCompressedImage } from "Helpers/Shared/utils";

import _ from "lodash";
import moment from "moment";
import backImage from "../../../src/assets/media/users/default.jpg";
import PartnerModel from "../../Models/PartnerModel";
import { validateNumber } from "../../Helpers/Shared/Validators";
import MerchantModel from "../../Models/MerchantModel";

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
import ProgramModel from "../../Models/ProgramModel";
import SharedModel from "../../Models/SharedModel";
import UserModel from "../../Models/UserModel";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class EditProgram extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      programId: null,
      name: "",
      nameError: false,
      arb_name: "",
      arb_nameError: false,
      currency: "",
      currencyError: false,
      partner: null,
      partnerError: false,
      partnerList: [],
      programType: null,
      programTypeError: false,
      programTypeList: [],
      cityList: [],
      cities: [],
      cityError: false,
      price: "",
      priceError: false,
      discountedPrice: "",
      discountedPriceError: false,
      estimatedSaving: "",
      estimatedSavingError: false,
      startDate: null,
      endDate: null,
      dateError: false,
      redemption: "",
      redemptionError: false,
      is_purchase: false,
      is_mastercard: false,
      currencyList: [],
      apps: [],
      programList: [],
      isFree: null,
      position: "",
      positionError: false,
      card_design: null,
      card_designError: false,
      card_designSrc: null,
      serverImageSrc: null,
      merchant: null,
      merchantError: false,
      merchantList: [],
      outlets: null,
      outletList: [],
      outletsError: false,
      merchantOutlet: [],
      merchant_name: "",
      outlet_name: "",
      value: "",
      is_active: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    let it = this;
    it.setState({
      loading: true,
      programId: params.programId,
    });

    await ProgramModel.getInstance().getProgram(
      null,
      function success() {
        it.setState({
          programList: ProgramModel.getInstance().ProgramList,
        });
      },
      function failure(error) {
        console.log(error);
      }
    );
    await ProgramModel.getInstance().getProgramDetail(
      params.programId,
      function success(data) {
        it.setState({
          name: data.name,
          arb_name: data.arabic_name ? data.arabic_name : "",
          currency: data.currency,
          price: data.price,
          discountedPrice: data.discounted_price ? data.discounted_price : "",
          estimatedSaving: data.estimated_saving,
          startDate: data.start_date,
          endDate: data.end_date,
          programType: data.program_type_id,
          redemption: Number(data.offer_count),
          is_active: data.is_active ? data.is_active : false,
          is_mastercard: data.is_mastercard ? data.is_mastercard : false,
          is_purchase: data.is_purchase ? data.is_purchase : false,
          isFree: data.is_free,
          position: data.position,
          serverImageSrc: data.card_design,
          merchantOutlet: data.mercahntOutletList
            ? data.merchantOutletList.map((values) => {
              return values;
            })
            : [],
        });

        let cities = [];
        let cityList = [];
        data.cities.forEach((city) => {
          cities.push(city.id);
          cityList.push(city);
        });

        it.setState({ cities, cityList });

        let partners = [];
        data.partners.forEach((partner) => {
          partners.push(partner.id);
        });

        it.getPartner(partners, false);
      },
      function failure(error) {
        console.log(error);
        errorNotification(error);
      }
    );

    await ProgramModel.getInstance().getProgramType(function success() {
      it.setState({
        programTypeList: ProgramModel.getInstance().ProgramTypeList,
      });
      function failure(error) {
        console.log(error);
      }
    });

    await SharedModel.getInstance().getAllCurrency(
      function success(data) {
        it.setState({
          currencyList: data,
          loading: false,
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false });
      }
    );
  }

  getPartner(partners, clearCities = true) {
    let it = this;
    PartnerModel.getInstance().searchPartner({},
      function success() {
        it.setState({
          partnerList: PartnerModel.getInstance().PartnerList,
          loading: false,
        });
        it.getPartnerCities(partners, clearCities);
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false });
      }
    );
  }

  disabledDate(current) {
    // Can not select days before today and today
    return current < moment().startOf("day");
  }

  getPartnerCities(partner, clearCities = true) {
    this.setState({ partner });
    let cityList = [];
    let apps = [];
    PartnerModel.getInstance().PartnerList.forEach((partner_all, i) => {
      partner.forEach((partner_selected) => {
        if (partner_all.id === partner_selected) {
          cityList = cityList.concat(partner_all.cities);
          if (partner_all.apps.length > 0) {
            apps.push(partner_all.apps[0].id);
          }
        }
      });
    });
    apps = _.uniq(apps);
    cityList = _.uniqBy(cityList, "id");
    this.setState({ cityList: clearCities ? cityList : this.state.cityList, apps });

    let tempCities = [];
    let { cities } = this.state;

    cities.forEach((i) => {
      cityList.forEach((j) => {
        if (i === j.id) {
          tempCities.push(i);
        }
      });
    });
    if (clearCities) {
      this.setState({ cities: tempCities });
    }

  }

  cardDesign(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ loading: true });
    getCompressedImage(file)
      .then((compressedImage) => {
        if (compressedImage) {
          this.setState({
            loading: false,
            card_design: compressedImage,
            card_designSrc: URL.createObjectURL(compressedImage),
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        errorNotification(e);
      });
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQueryMerchant = this.debounce(function (value, it) {
    it.getMerchants(value);
  }, 1000);

  getMerchants(value) {
    if (value.length > 0) {
      let it = this;
      let params = {
        query: encodeURIComponent(value),
      };
      this.setState({ loading: true });
      MerchantModel.getInstance().getMerchant(
        params,
        function success(data) {
          it.setState({
            loading: false,
            merchantList: data.data,
          });
        },
        function failure(error) {
          errorNotification(error);
          it.setState({ loading: false });
        }
      );
    }
  }

  getOutlets(merchants) {
    this.state.merchantList.forEach((merch, i) => {
      if (merch.id == merchants) {
        this.setState({
          merchant_name: merch.name,
        });
      }
    });
    this.setState({
      loading: true,
      merchant: merchants,
    });
    let it = this;
    MerchantModel.getInstance().getMerchantOutlet(
      merchants,
      function success(data) {
        it.setState({
          outletList: data,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  selectOutlets(outlets) {
    this.state.outletList.forEach((out) => {
      if (out.id == outlets) {
        this.setState({
          outlet_name: out.name,
          outlets,
        });
      }
    });
  }

  addMerchantOutlet() {
    let { merchantOutlet } = this.state;
    merchantOutlet.push({
      merchant_id: this.state.merchant,
      merchant_name: this.state.merchant_name,
      outlet_id: this.state.outlets,
      outlet_name: this.state.outlet_name,
      value: this.state.value,
      is_active: this.state.is_active,
    });
    this.setState({
      merchantOutlet,
      merchant: null,
      merchant_name: "",
      outlets: null,
      outlet_name: "",
    });
  }

  deleteMerchantOutlet(index) {
    let { merchantOutlet } = this.state;
    merchantOutlet.splice(index, 1);
    this.setState({ merchantOutlet });
  }

  sendData() {
    let {
      programId,
      name,
      arb_name,
      currency,
      price,
      discountedPrice,
      estimatedSaving,
      startDate,
      endDate,
      programType,
      partner,
      cities,
      redemption,
      is_purchase,
      is_mastercard,
      apps,
      isFree,
      card_design,
      position,
      merchantOutlet,
    } = this.state;
    const { history } = this.props;
    let it = this;
    const text = "Validation Error! Please check fields";
    this.setState({
      nameError: false,
      arb_nameError: false,
      currencyError: false,
      priceError: false,
      discountedPriceError: false,
      estimatedSavingError: false,
      dateError: false,
      programTypeError: false,
      partnerError: false,
      cityError: false,
      redemptionError: false,
      positionError: false,
    });

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster(text);
      return;
    }
    // if (arb_name.length < 1) {
    //     this.setState({ arb_nameError: true });
    //     errorToaster(text);
    //     return;
    // }
    if (!partner) {
      this.setState({ partnerError: true });
      errorToaster(text);
      return;
    }
    if (cities.length < 1) {
      this.setState({ cityError: true });
      errorToaster(text);
      return;
    }
    if (currency.length < 1) {
      this.setState({ currencyError: true });
      errorToaster(text);
      return;
    }
    if (!programType) {
      this.setState({ programTypeError: true });
      errorToaster(text);
      return;
    }
    if (programType == 7) {
      if (Number(price) < Number(discountedPrice)) {
        errorToaster("Discounted price must be smaller than program price");
        return;
      }
      if (price.length < 1) {
        this.setState({ priceError: true });
        errorToaster(text);
        return;
      }
      if (estimatedSaving.length < 1) {
        this.setState({ estimatedSavingError: true });
        errorToaster(text);
        return;
      }
      if (redemption.length > 0) {
        if (
          parseFloat(redemption) === parseFloat(redemption) &&
          parseFloat(redemption) % 1 !== 0
        ) {
          this.setState({ redemptionError: true });
          errorToaster(text);
          return;
        }
      }
      if (Number(redemption) < 0) {
        this.setState({ redemptionError: true });
        errorToaster("Trial Offer Count must not be smaller than ZERO");
        return;
      }
      if (startDate.length < 1 || endDate.length < 1) {
        this.setState({ dateError: true });
        errorToaster(text);
        return;
      }
    }

    if (programType == 3) {
      if (!validateNumber(position)) {
        this.setState({ positionError: true });
        errorToaster("Enter position with only numbers");
        return;
      } else {
        if (Number(position < 1)) {
          this.setState({ positionError: true });
          errorToaster("Enter position must be greater than zero");
          return;
        }
      }
    }

    let params = {
      name,
      arabic_name: arb_name,
      currency,
      price,
      estimated_saving: estimatedSaving,
      start_date: startDate,
      end_date: endDate,
      type_id: programType,
      partner_id: partner,
      city_id: cities,
      is_purchase,
      is_mastercard,
      app_id: apps,
      position,
      merchantOutletList: merchantOutlet,
    };
    discountedPrice = String(discountedPrice);
    if (discountedPrice.length > 0) {
      params.discounted_price = discountedPrice;
    } else params.discounted_price = null;

    if (redemption.length > 0) {
      params.offer_count = redemption;
    }

    if (isFree) {
      params.is_free = isFree;
    } else params.is_free = null;

    if (programType == 3) {
      this.addMedia(programId, card_design, params);
    } else {
      this.updateProgram(programId, params);
    }
  }

  addMedia(programId, card_design, params) {
    let it = this;
    UserModel.getInstance().addMedia(
      card_design,
      function success(image) {
        params.card_design = image;
        it.updateProgram(programId, params);
      },

      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  updateProgram(programId, params) {
    this.setState({ loading: true });
    let it = this;
    ProgramModel.getInstance().updateProgram(
      programId,
      params,
      function success(data) {
        successNotification("Program Updated Successfully");
        setTimeout(() => {
          window.location.href = "/program";
        }, 1000);
      },

      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  render() {
    const {
      loading,
      nameError,
      arb_nameError,
      currencyError,
      priceError,
      discountedPriceError,
      estimatedSavingError,
      dateError,
      programTypeError,
      partnerError,
      programTypeList,
      partnerList,
      cityList,
      currencyList,
      cityError,
      redemptionError,
      programList,
      card_designSrc,
      card_designError,
      positionError,
      merchantList,
      outletList,
      serverImageSrc,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <Spin spinning={loading}>
          <div className="kt-content  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">Edit Program</h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/program"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    value={this.state.name}
                                    maxLength="70"
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter name in English
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Arabic Name
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="100"
                                    value={this.state.arb_name}
                                    onChange={(event) =>
                                      this.setState({
                                        arb_name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter name in English
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="marchantGroup"
                                >
                                  Partner <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.partner}
                                    onChange={(partner) => {
                                      this.getPartnerCities(partner)
                                    }
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {partnerList.map((partner) => (
                                      <Option
                                        disabled={!partner.is_active}
                                        key={partner.id}
                                        value={partner.id}
                                      >
                                        {partner.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: partnerError ? "red" : "",
                                      }}
                                    >
                                      Please choose Partner
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="marchantGroup"
                                >
                                  Cities <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.cities}
                                    onChange={(cities) => {
                                      this.state.cities = cities;
                                      this.forceUpdate();
                                    }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {cityList.map((city) => (
                                      <Option key={city.id} value={city.id}>
                                        {city.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: cityError ? "red" : "" }}
                                    >
                                      Please choose Cities
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="marchantGroup"
                                >
                                  Currency <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    // mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    // placeholder="Select a merchant group"
                                    value={this.state.currency}
                                    onChange={(currency) =>
                                      this.setState({ currency })
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {currencyList.map((currency) => (
                                      <Option
                                        key={currency.id}
                                        value={currency.id}
                                      >
                                        {currency.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: currencyError ? "red" : "",
                                      }}
                                    >
                                      Please choose Currency
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="marchantGroup"
                                >
                                  Program Type <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    onChange={(programType) =>
                                      this.setState({ programType })
                                    }
                                    value={this.state.programType}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {programTypeList.map((type) => (
                                      <Option key={type.id} value={type.id}>
                                        {type.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: programTypeError ? "red" : "",
                                      }}
                                    >
                                      Please choose Program type
                                    </p>
                                  </span>
                                </div>
                              </div>

                              {this.state.programType == 3 ? (
                                <div>
                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Position <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <input
                                        value={this.state.position}
                                        onChange={(event) =>
                                          this.setState({
                                            position: event.target.value,
                                          })
                                        }
                                        min="1"
                                        className="form-control"
                                        type="number"
                                      />
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: positionError ? "red" : "",
                                          }}
                                        >
                                          Please enter Position
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label">
                                      Card design{" "}
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                      <div
                                        className="kt-avatar kt-avatar--outline"
                                        style={{ borderRadius: "10px" }}
                                        id="kt_apps_user_add_avatar"
                                      >
                                        <div
                                          className="kt-avatar__holder"
                                          style={{
                                            backgroundImage: `url(${this.state.card_designSrc
                                              ? this.state.card_designSrc
                                              : this.state.serverImageSrc
                                                ? this.state.serverImageSrc
                                                : backImage
                                              })`,
                                          }}
                                        ></div>
                                        <label
                                          className="kt-avatar__upload"
                                          data-toggle="kt-tooltip"
                                          title=""
                                          data-original-title="Change avatar"
                                        >
                                          <i className="fa fa-pen"></i>
                                          <input
                                            onChange={(event) =>
                                              this.cardDesign(event)
                                            }
                                            type="file"
                                            name="kt_apps_user_add_user_avatar"
                                          />
                                        </label>
                                        <span
                                          className="kt-avatar__cancel"
                                          data-toggle="kt-tooltip"
                                          title=""
                                          data-original-title="Cancel avatar"
                                        >
                                          <i className="fa fa-times"></i>
                                        </span>
                                        <p
                                          style={{
                                            color: "red",
                                            display: card_designError
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          Please Upload Card design
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Merchant & Outlet
                                    </label>
                                    <div className="col-9">
                                      <div className="row">
                                        <Select
                                          showSearch
                                          style={{
                                            margin: "10px 0px 10px 0px",
                                            width: "30%",
                                            marginRight: "5%",
                                            marginLeft: "2%",
                                          }}
                                          value={this.state.merchant}
                                          notFoundContent={
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Icon
                                                style={{
                                                  fontSize: 16,
                                                  marginRight: "10px",
                                                  marginBottom: "5px",
                                                }}
                                                type="search"
                                              />
                                              <p style={{ fontSize: 14 }}>
                                                Please Search Merchant
                                              </p>
                                            </div>
                                          }
                                          onSearch={(val) =>
                                            this.searchQueryMerchant(val, this)
                                          }
                                          onChange={(merchant) =>
                                            this.getOutlets(merchant)
                                          }
                                          filterOption={(input, option) =>
                                            option.props.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                        >
                                          {merchantList.map((merchant) => (
                                            <Option
                                              key={merchant.id}
                                              value={merchant.id}
                                            >
                                              {merchant.name}
                                            </Option>
                                          ))}
                                        </Select>

                                        <Select
                                          showSearch
                                          style={{
                                            margin: "10px 0px 10px 0px",
                                            width: "50%",
                                            marginRight: "5%",
                                            marginLeft: "2%",
                                          }}
                                          value={this.state.outlets}
                                          onChange={(outlets) =>
                                            this.selectOutlets(outlets)
                                          }
                                          filterOption={(input, option) =>
                                            option.props.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                        >
                                          {outletList.map((outlet) => (
                                            <Option
                                              key={outlet.id}
                                              value={outlet.id}
                                            >
                                              {outlet.name}
                                            </Option>
                                          ))}
                                        </Select>

                                        <button
                                          onClick={() =>
                                            this.addMerchantOutlet()
                                          }
                                          style={{
                                            margin: "10px 0px 10px 0px",
                                            marginLeft: "70%",
                                          }}
                                          type="button"
                                          className="btn btn-brand"
                                        >
                                          <span className="kt-hidden-mobile">
                                            {" "}
                                            Add{" "}
                                          </span>
                                        </button>

                                        <table className="table table-striped">
                                          <thead>
                                            <tr>
                                              <th>Merchant</th>
                                              <th>Outlets</th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {this.state.merchantOutlet.map(
                                              (val, i) => (
                                                <tr key={i}>
                                                  <td>{val.merchant_name}</td>
                                                  <td>{val.outlet_name}</td>
                                                  <td>
                                                    <button
                                                      onClick={() =>
                                                        this.deleteMerchantOutlet(
                                                          i
                                                        )
                                                      }
                                                      style={{
                                                        margin:
                                                          "5px 0px 10px 0px",
                                                        marginLeft: "20%",
                                                      }}
                                                      type="button"
                                                      className="btn btn-brand"
                                                    >
                                                      <span className="kt-hidden-mobile">
                                                        {" "}
                                                        Remove
                                                      </span>
                                                    </button>
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {this.state.programType == 7 ? (
                                <div>
                                  <div className="form-group row">
                                    <label
                                      className="col-3 col-form-label"
                                      htmlFor="marchantGroup"
                                    >
                                      Free Program
                                    </label>
                                    <div className="col-9">
                                      <Select
                                        showSearch
                                        allowClear
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        value={this.state.isFree}
                                        onChange={(isFree) =>
                                          this.setState({ isFree })
                                        }
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {programList.map((program) => (
                                          <Option
                                            key={program.id}
                                            value={program.id}
                                          >
                                            {program.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <span className="form-text text-muted">
                                        Please choose if any Program is free
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Price <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <input
                                        value={this.state.price}
                                        maxLength="20"
                                        onChange={(event) => {
                                          let estimatedSaving =
                                            Number(event.target.value) -
                                            Number(this.state.discountedPrice);
                                          this.setState({
                                            price: event.target.value,
                                            estimatedSaving,
                                          });
                                        }}
                                        className="form-control"
                                        type="number"
                                      />
                                      <span
                                        style={{
                                          color: priceError ? "red" : "",
                                        }}
                                        className="form-text text-muted"
                                      >
                                        <p
                                          style={{
                                            color: priceError ? "red" : "",
                                          }}
                                        >
                                          Please enter price
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Discounted Price
                                    </label>
                                    <div className="col-9">
                                      <input
                                        value={this.state.discountedPrice}
                                        maxLength="20"
                                        onChange={(event) => {
                                          let estimatedSaving =
                                            Number(this.state.price) -
                                            Number(event.target.value);
                                          this.setState({
                                            discountedPrice: event.target.value,
                                            estimatedSaving,
                                          });
                                        }}
                                        className="form-control"
                                        type="number"
                                      />
                                      <span
                                        style={{
                                          color: discountedPriceError
                                            ? "red"
                                            : "",
                                        }}
                                        className="form-text text-muted"
                                      >
                                        <p
                                          style={{
                                            color: discountedPriceError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please enter price
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Estimated Saving <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <input
                                        disabled
                                        value={this.state.estimatedSaving}
                                        maxLength="20"
                                        onChange={(event) =>
                                          this.setState({
                                            estimatedSaving: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                        type="number"
                                      />
                                      <span
                                        style={{
                                          color: estimatedSavingError
                                            ? "red"
                                            : "",
                                        }}
                                        className="form-text text-muted"
                                      >
                                        <p
                                          style={{
                                            color: estimatedSavingError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please enter price
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      For Master Card
                                    </label>
                                    <div className="col-9">
                                      <Checkbox
                                        checked={this.state.is_mastercard}
                                        onChange={(event) => {
                                          this.setState({
                                            is_mastercard: event.target.checked,
                                          });
                                          if (event.target.checked === true) {
                                            this.setState({
                                              is_purchase: false,
                                              redemption: "",
                                            });
                                          }
                                        }}
                                        className="form-control"
                                        style={{
                                          border: "0px",
                                          padding: "0px",
                                        }}
                                      />
                                      <span
                                        style={{ padding: "0px" }}
                                        className="form-text text-muted"
                                      >
                                        Please check if program is for master
                                        card users
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Available for Purchase
                                    </label>
                                    <div className="col-9">
                                      <Checkbox
                                        checked={this.state.is_purchase}
                                        onChange={(event) =>
                                          this.setState({
                                            is_purchase: event.target.checked,
                                          })
                                        }
                                        className="form-control"
                                        style={{
                                          border: "0px",
                                          padding: "0px",
                                        }}
                                      />
                                      <span
                                        style={{ padding: "0px" }}
                                        className="form-text text-muted"
                                      >
                                        Please check if Program is available for
                                        Purchase
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Trial Offer Count
                                    </label>
                                    <div className="col-9">
                                      <input
                                        value={this.state.redemption}
                                        maxLength="20"
                                        onChange={(event) =>
                                          this.setState({
                                            redemption: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                        type="number"
                                      />
                                      <span className="form-text text-muted">
                                        {" "}
                                        {redemptionError ? (
                                          <p
                                            style={{
                                              color: redemptionError
                                                ? "red"
                                                : "",
                                            }}
                                          >
                                            {" "}
                                            Trial Offer Count must be numeric
                                            and not be smaller than ZERO
                                          </p>
                                        ) : (
                                          <p> Please enter Trial Offer Count</p>
                                        )}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Start ~ End Date <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <RangePicker
                                        disabledDate={this.disabledDate}
                                        className="form-control"
                                        style={{
                                          padding: "0px",
                                          border: "0px solid black",
                                        }}
                                        showTime={false}
                                        value={[
                                          this.state.startDate
                                            ? moment(
                                              this.state.startDate,
                                              "YYYY-MM-DD"
                                            )
                                            : null,
                                          this.state.endDate
                                            ? moment(
                                              this.state.endDate,
                                              "YYYY-MM-DD"
                                            )
                                            : null,
                                        ]}
                                        onChange={(value, string) =>
                                          this.setState({
                                            startDate: string[0],
                                            endDate: string[1],
                                          })
                                        }
                                        format="YYYY-MM-DD"
                                      />
                                      <span
                                        style={{
                                          color: nameError ? "red" : "",
                                        }}
                                        className="form-text text-muted"
                                      >
                                        <p
                                          style={{
                                            color: dateError ? "red" : "",
                                          }}
                                        >
                                          Please enter price
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
