import React from "react";
import { Select, Icon, Popconfirm, Spin, Switch } from "antd";

import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { errorNotification, errorToaster } from "../Shared/Notifications";
import ValueTypeModel from "../../Models/ValueTypeModel";
import CardModel from "../../Models/CardModel";
import { tableHeaderStyles } from "styles/tableStyles";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      totalPage: 1,
      perPage: 20,
      filters: [],
      program: null,
      programList: [],
      programError: false,
      card: null,
      cardSetList: [],
      cardSetError: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    const it = this;
    const {
      match: { params },
    } = this.props;
    it.setState({ cardSetId: params.cardSetId });
    this.getCard(params.cardSetId);
    it.setState({ loading: true });

    ValueTypeModel.getInstance().getProgramValueType(
      3,
      function success() {
        it.setState({
          programList: ValueTypeModel.getInstance().ProgramValueTypeList,
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false });
      }
    );

    CardModel.getInstance().getCardSet(
      null,
      function success() {
        it.setState({
          cardSetList: CardModel.getInstance().CardSetList,
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false });
      }
    );
  }

  getCard(cardSetId) {
    const it = this;
    let { program, card } = this.state;
    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });

    if (params["cards.is_active"] === "all") {
      delete params["cards.is_active"];
    }
    if (program !== "all") {
      params.program = program;
    }
    if (card !== "all") {
      params.card_set_id = card;
    }

    it.setState({ loading: true });
    CardModel.getInstance().getCard(
      cardSetId,
      function success(data) {
        it.setState({
          loading: false,
          data: CardModel.getInstance().CardList,
          totalPage: data.last_page,
          perPage: data.per_page,
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorToaster("Request information not found");
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getCard();
  }, 1000);

  // removeCard(cardSetId, cardId) {
  //     const it = this;
  //     it.setState({ loading: true })
  //     CardModel.getInstance().removeCardSet(cardSetId, cardId,
  //         function success() {
  //             it.setState({ data: CardModel.getInstance().CardList });
  //             it.setState({ loading: false })
  //             infoNotification('Card Set Deleted');
  //         },
  //         function failure(error) {
  //             errorNotification(error)
  //             it.setState({ loading: false })
  //         }
  //     )

  // }

  cardDetail() {
    let it = this;
    it.setState({ loading: true });
    const {
      match: { params },
    } = this.props;
    it.setState({ cardId: params.cardId });
    CardModel.getInstance().getCardDetail(
      params.cardSetId,
      params.cardId,
      function success(data) {
        it.setState({
          loading: false,
          data: CardModel.getInstance().CardList,
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorToaster(JSON.stringify(error));
      }
    );
  }

  cardActivation(cardSetId, cardId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = !is_active;
    let params = {
      is_active: activation,
    };
    CardModel.getInstance().cardActivation(
      cardSetId,
      cardId,
      params,
      function success() {
        it.setState({ data: CardModel.getInstance().CardList });
        it.setState({ loading: false });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  render() {
    const { data } = this.state;
    console.log("data", data);
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={this.state.loading}>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              <div className="kt-portlet__head-label">
                <span className="kt-portlet__head-icon">
                  <i className="kt-font-brand flaticon2-line-chart"></i>
                </span>
                <h3 className="kt-portlet__head-title">Card</h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="form-group row">
                <label className="col-3 col-form-label" htmlFor="marchantGroup">
                  Programs
                </label>
                <div className="col-9">
                  <Select
                    showSearch
                    style={{ margin: "10px 0px 10px 0px", width: "35%" }}
                    onChange={(program) => this.setState({ program })}
                    defaultValue={"all"}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={"all"}>All</Option>

                    {this.state.programList.map((program) => (
                      <Option key={program.id} value={program.id}>
                        {program.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label" htmlFor="marchantGroup">
                  Card Sets
                </label>
                <div className="col-9">
                  <Select
                    showSearch
                    style={{ margin: "10px 0px 10px 0px", width: "35%" }}
                    onChange={(card) => this.setState({ card })}
                    defaultValue={"all"}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={"all"}>All</Option>

                    {this.state.cardSetList.map((card) => (
                      <Option key={card.id} value={card.id}>
                        {card.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div
                style={{ marginLeft: "-10px" }}
                className="col-3 col-form-label"
              >
                <div className="btn-group">
                  <button type="button" className="btn btn-brand">
                    <i className="la la-search"></i>
                    <span className="kt-hidden-mobile">Search</span>
                  </button>
                </div>
              </div>

              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={20}
                showPageSizeOptions={false}
                manual
                page={this.state.currentPage - 1}
                pages={this.state.totalPage}
                filtered={this.state.filters}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getCard();
                  }, 50);
                }}
                onFilteredChange={(filters) => {
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                columns={[
                  {
                    Header: <p style={tableHeaderStyles()}>Card No.</p>,
                    id: "number",
                    accessor: (d) => d.number,
                    style: { whiteSpace: "unset" },
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["number"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Program</p>,
                    // id: "",
                    accessor: "program",
                    style: { whiteSpace: "unset" },
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["program"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Card Set</p>,
                    // id: "",
                    accessor: "card_set_name",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["card_set_name"],
                      }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Mobile</p>,
                    // id: "",
                    accessor: "user_mobile",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["user_mobile"],
                      }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    // id: "",
                    accessor: "user_name",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["user_name"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>User</p>,
                    // id: "",
                    accessor: "user_id",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["user_id"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    // id: "",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value == "true") {
                        return row[filter.id] == true;
                      }
                      return row[filter.id] == false;
                    },
                    Filter: ({ filter, onChange }) =>
                      console.log("VALUE: ", filter) || (
                        <Select
                          onChange={(value) => onChange(value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <Option value="all">Show all</Option>
                          <Option value="true">Active</Option>
                          <Option value="false">De-Activate</Option>
                        </Select>
                      ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 200,
                    accessor: (d) => (
                      <div>
                        {/* <Popconfirm
                                                    title="Are you sure you want to delete this card ?"
                                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                    onConfirm={() => this.removeCard(d.id )}
                                                >
                                                    <Icon style={{ color: '#5D78FF', fontSize: 16 }} type="delete" />
                                                </Popconfirm> */}

                        <Popconfirm
                          title={
                            d.is_active
                              ? "Are you sure you want to DEACTIVATE this card"
                              : "Are you sure you want to ACTIVATE this card"
                          }
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.cardActivation(d.id, d.is_active)
                          }
                        >
                          <Switch
                            style={{
                              backgroundColor: d.is_active
                                ? "#5d78ff"
                                : "#fd397a",
                              marginLeft: 15,
                            }}
                            checked={d.is_active}
                            onChange={(val) => console.log(val)}
                          />
                        </Popconfirm>

                        <Icon
                          style={{
                            color: "grey",
                            fontSize: 20,
                            marginLeft: 20,
                          }}
                          type="eye"
                        />
                      </div>
                    ),
                    filterable: false,
                    sortable: false,
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
