import React from "react";
import { Spin } from "antd";
import { successNotification } from "../Shared/Notifications";
import UserModel from "../../Models/UserModel";
import { errorNotification, errorToaster } from "../Shared/Notifications";
import {
  validateEmail,
  validatePhoneCountry,
} from "../../Helpers/Shared/Validators";

export default class EditCustomer extends React.Component {
  constructor() {
    super();
    this.state = {
      id: null,
      name: "",
      nameError: false,
      email: "",
      emailError: false,
      phone: "",
      phoneError: false,
      apps: [],
      appId: null,
      appError: false,
      programList: [],
      program: [],
      programError: false,
      loading: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    let it = this;
    it.setState({ loading: true });
    const {
      match: { params },
    } = this.props;
    console.log(params);
    UserModel.getInstance().getUserDetails(
      params.customerId,
      function success(data) {
        it.setState({
          loading: false,
          id: data.id,
          name: data.full_name,
          email: data.email,
          phone: data.mobile ? data.mobile : "",
        });
      },
      function failure(error) {
        console.log(error);
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
    // this.getAllAppIds();
  }
  // getAllAppIds() {
  //     let it = this;
  //     it.setState({ loading: true })
  //     SharedModel.getInstance().getAllAppIds(
  //         function success(data) {
  //             it.setState({
  //                 apps: data,
  //                 loading: false
  //             })
  //         },
  //         function failure(error) {
  //             console.log(error)
  //             it.setState({ loading: false })
  //         }
  //     )
  // }

  sendData() {
    let { id, name, email, phone } = this.state;
    const { history } = this.props;
    this.setState({
      nameError: false,
      emailError: false,
      phoneError: false,
      appError: false,
      programError: false,
    });
    let it = this;
    let params = {};

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster("Please Enter Name");
      return;
    }
    params.full_name = name;

    if (!validateEmail(email)) {
      this.setState({ emailError: true });
      errorToaster("Please Enter Valid Email");
      return;
    }
    params.email = email;

    if (!validatePhoneCountry(phone)) {
      this.setState({ phoneError: true });
      errorToaster("Please Enter Valid Phone number");
      return;
    }
    params.mobile = phone;

    // if (!appId) {
    //     this.setState({ appError: true })
    //     errorToaster('Please Choose App')
    //     return
    // } params.app_id = appId

    this.setState({ loading: true });

    UserModel.getInstance().updateUser(
      id,
      params,
      function success(data) {
        successNotification("Customer updated Successfully");
        setTimeout(() => {
          history.push("/customer");
        }, 500);
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  render() {
    const {
      nameError,
      emailError,
      phoneError,
      apps,
      appId,
      appError,
      program,
      programError,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                <div className="kt-portlet__head kt-portlet__head--lg">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Edit Customer</h3>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                    <a
                      href="/customer"
                      className="btn btn-clean kt-margin-r-10"
                    >
                      <i className="la la-arrow-left"></i>
                      <span className="kt-hidden-mobile">Back</span>
                    </a>
                    <div className="btn-group">
                      <button
                        onClick={() => this.sendData()}
                        type="button"
                        className="btn btn-brand"
                      >
                        <i className="la la-check"></i>
                        <span className="kt-hidden-mobile">Save</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* end header */}
                <div className="kt-portlet__body">
                  <form className="kt-form" id="kt_form">
                    <div className="row">
                      <div className="col-xl-2"></div>
                      <div className="col-xl-8">
                        <div className="kt-section kt-section--first">
                          <Spin spinning={this.state.loading}>
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Full Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    disabled
                                    value={this.state.name}
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter full name
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Email <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    disabled
                                    value={this.state.email}
                                    onChange={(event) =>
                                      this.setState({
                                        email: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="email"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: emailError ? "red" : "" }}
                                    >
                                      Please enter your email
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Phone <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    disabled
                                    value={this.state.phone}
                                    onChange={(event) =>
                                      this.setState({
                                        phone: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="name"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: phoneError ? "red" : "" }}
                                    >
                                      Please enter your phone number
                                    </p>
                                  </span>
                                </div>
                              </div>

                              {/* <div className="form-group row">
                                                                <label className="col-3 col-form-label" htmlFor="marchantGroup">Apps <b>*</b></label>
                                                                <div className="col-9">

                                                                    <Select
                                                                        showSearch
                                                                        // mode="multiple"
                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                        value={this.state.appId}
                                                                        onChange={(appId) => this.setState({ appId })}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {apps.map((app) => (
                                                                            <Option key={app.id} value={app.id} >{app.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                    <span className="form-text text-muted"><p style={{ color: appError ? 'red' : '' }}>Please choose atlease One App ID</p></span>

                                                                </div>
                                                            </div> */}

                              {/* <div className="form-group row">
                                                                <label className="col-3 col-form-label" htmlFor="marchantGroup">Programs <b>*</b></label>
                                                                <div className="col-9">
                                                                <Select
                                                                        showSearch
                                                                        mode="multiple"
                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                        // value={this.state.appId}
                                                                        // onChange={(appId) => this.setState({ appId })}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                      
                                                                    </Select>
                                                                    <span className="form-text text-muted"><p style={{ color: programError ? 'red' : '' }}>Please select program</p></span>

                                                                </div>
                                                            </div> */}
                            </div>
                          </Spin>
                        </div>
                      </div>
                      <div className="col-xl-2"></div>
                    </div>
                  </form>
                </div>
                {/* end body */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
