import React from 'react';

import Content from './Content';

export default class Coupon extends React.Component {



    render(){

        return(
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <Content />
            </div>
        )
    }
}