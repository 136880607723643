import React from "react";
import { Spin, Radio } from "antd";

import UserModel from "../../../Models/UserModel";
import { validateEmail } from "../../../Helpers/Shared/Validators";

import "../../../assets2/css/login-3.css";
import backImage from "../../../assets2/Images/bg-3.jpg";
import logo from "../../../assets/media/logos/login.png";
import { errorToaster, errorNotification } from "../../Shared/Notifications";
export default class Login extends React.Component {
  state = {
    email: null,
    password: "",
    emailError: null,
    type: 5,
    passwordError: null,
    serverError: null,
    loading: false,
  };

  prevent(event) {
    event.preventDefault();
    localStorage.setItem("user_role", this.state.type);
  }

  tryLogin() {
    this.setState({
      emailError: null,
      passwordError: null,
    });
    const { email, password, type } = this.state;
    let it = this;
    if (!validateEmail(email)) {
      this.setState({
        emailError: "Invalid email.Please enter the correct email",
      });
      errorToaster("Invalid email.Please enter the correct email");
      return;
    }
    if (password.length < 6) {
      this.setState({
        passwordError: "Password Must be at least 6 characters",
      });
      errorToaster("Invalid password.Please enter the correct password");
      return;
    }
    it.setState({ loading: true });
    UserModel.getInstance().Login_2(
      email.toLowerCase(),
      password,
      type,
      function success() {
        if (type == 1) {
          window.location.href = "/dashboard";
          return;
        }
        if (type == 5) {
          window.location.href = "/order-report";
          return;
        }
      },
      function failure(serverError) {
        errorNotification(serverError);
        it.setState({ loading: false });
      }
    );
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { emailError, passwordError, serverError } = this.state;
    return (
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin"
          id="kt_login"
        >
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
            style={{ backgroundImage: `url(${backImage})` }}
          >
            <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
              <div className="kt-login__container">
                <div className="kt-login__logo">
                  {/* <a href="#"> */}
                  <img src={logo} />
                  {/* </a> */}
                </div>
                <div className="kt-login__signin">
                  <div className="kt-login__head">
                    <h3 className="kt-login__title">Sign In To Admin</h3>
                  </div>
                  <Spin spinning={this.state.loading}>
                    <form
                      className="kt-form"
                      onSubmit={this.prevent.bind(this)}
                    >
                      <div className="input-group">
                        <div
                          id="email-error"
                          style={{
                            display: serverError ? "block" : "none",
                            fontSize: "smaller",
                            fontWeight: "600",
                          }}
                          className="error invalid-feedback"
                        >
                          {serverError}
                        </div>
                        <input
                          name="email"
                          onChange={(event) => this.handleChange(event)}
                          className="form-control"
                          type="text"
                          placeholder="Email Address"
                          autoComplete="off"
                        />
                        <div
                          id="email-error"
                          style={{
                            display: emailError ? "block" : "none",
                            fontSize: "smaller",
                            fontWeight: "600",
                          }}
                          className="error invalid-feedback"
                        >
                          Please enter a valid Email.
                        </div>
                      </div>
                      <div className="input-group">
                        <input
                          name="password"
                          onChange={(event) => this.handleChange(event)}
                          className="form-control"
                          type="password"
                          placeholder="Password"
                        />
                        <div
                          id="password-error"
                          style={{
                            display: passwordError ? "block" : "none",
                            fontSize: "smaller",
                            fontWeight: "600",
                          }}
                          className="error invalid-feedback"
                        >
                          This field is required.
                        </div>
                      </div>

                      <div className="input-group">
                        <Radio.Group
                          value={this.state.type}
                          onChange={(event) =>
                            this.setState({ type: event.target.value })
                          }
                        >
                          <Radio value={1}>Admin</Radio>
                          <Radio value={5}>Outlet</Radio>
                        </Radio.Group>
                      </div>

                      {/* <div id="email-error" style={{display:emailError ? 'block': 'none', fontSize:'smaller', fontWeight:'600' }} className="error invalid-feedback">Please enter a valid Email.</div> */}
                      <div className="row kt-login__extra">
                        <div className="col">
                          <label className="kt-checkbox">
                            <input type="checkbox" name="remember" /> Remember
                            me
                            <span></span>
                          </label>
                        </div>
                        <div className="col kt-align-right">
                          <a
                            href="/forgot"
                            id="kt_login_forgot"
                            className="kt-login__link"
                          >
                            Forgot Password ?
                          </a>
                        </div>
                      </div>
                      <div className="kt-login__actions">
                        <button
                          onClick={() => this.tryLogin()}
                          id="kt_login_signin_submit"
                          className="btn btn-brand btn-elevate kt-login__btn-primary"
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  </Spin>
                </div>
                <div className="kt-login__signup">
                  <div className="kt-login__head">
                    <h3 className="kt-login__title">Sign Up</h3>
                    <div className="kt-login__desc">
                      Enter your details to create your account:
                    </div>
                  </div>
                  <form className="kt-form" action="">
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Fullname"
                        name="fullname"
                      />
                    </div>
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Email"
                        name="email"
                        autoComplete="off"
                      />
                    </div>
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        name="password"
                      />
                    </div>
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Confirm Password"
                        name="rpassword"
                      />
                    </div>
                    <div className="row kt-login__extra">
                      <div className="col kt-align-left">
                        <label className="kt-checkbox">
                          <input type="checkbox" name="agree" />I Agree the{" "}
                          <a
                            href="/"
                            className="kt-link kt-login__link kt-font-bold"
                          >
                            terms and conditions
                          </a>
                          .<span></span>
                        </label>
                        <span className="form-text text-muted"></span>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="kt-login__forgot">
                  <div className="kt-login__head">
                    <h3 className="kt-login__title">Forgotten Password ?</h3>
                    <div className="kt-login__desc">
                      Enter your email to reset your password:
                    </div>
                  </div>
                  <form className="kt-form" action="">
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Email"
                        name="email"
                        id="kt_email"
                        autoComplete="off"
                      />
                    </div>
                    <div className="kt-login__actions">
                      <button
                        id="kt_login_forgot_submit"
                        className="btn btn-brand btn-elevate kt-login__btn-primary"
                      >
                        Request
                      </button>
                      &nbsp;&nbsp;
                      <button
                        id="kt_login_forgot_cancel"
                        className="btn btn-light btn-elevate kt-login__btn-secondary"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
