import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import 'material-icons/iconfont/material-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './index.scss';
import Routes from './Routes';
import { Helmet } from "react-helmet";


Sentry.init({ dsn: "https://3dbeb2b32a9744109776ea66624a4989@sentry.io/1785453" });

ReactDOM.render(<><Helmet><meta charSet="utf-8" />
  <style type="text/css">{`
  @font-face {
    font-family: 'MaterialIcons';
    src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
  }

  @font-face {
    font-family: 'MaterialCommunityIcons';
    src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
  }

  @font-face {
    font-family: 'FontAwesome';
    src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
  }
`}</style>
</Helmet><Routes /></>, document.getElementById('root'));