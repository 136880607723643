import React from "react";
import { Link } from "react-router-dom";
import { Icon, Popconfirm, Select, Switch, Spin } from "antd";
import matchSorter from "match-sorter";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { infoNotification, errorNotification } from "../Shared/Notifications";
import UserModel from "../../Models/UserModel";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      totalPages: 0,
      currentPage: 1,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getMerchantInfoTag();
  }

  getMerchantInfoTag = () => {
    const it = this;
    it.setState({ loading: true });
    let params = {
      offset: this.state.currentPage,
    };
    UserModel.getInstance().getMerchantInfoTag(
      params,
      function success(data) {
        it.setState({
          data: UserModel.getInstance().MerchantInfoTagList,
          loading: false,
          totalPages: data.infoTags.last_page,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
        errorNotification("Request information not found");
      }
    );
  };

  deleteMerchantInfoTag(merchantInfoTag_id) {
    const it = this;
    it.setState({ loading: true });
    UserModel.getInstance().removeMerchantInfoTag(
      merchantInfoTag_id,
      function success() {
        it.setState({ data: UserModel.getInstance().MerchantInfoTagList });
        it.setState({ loading: false });
        infoNotification("Merchant Info Tag Deleted");
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  merchantInfoTagActivation(infoTagId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = is_active === true ? false : true;
    let params = {};
    params.is_active = activation;
    UserModel.getInstance().merchantInfoTagActivation(
      infoTagId,
      params,
      function success() {
        it.setState({ data: UserModel.getInstance().MerchantInfoTagList });
        it.setState({ loading: false });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            {gridHeaderWithButton(
              "Merchant Info Tag",
              "la la-plus",
              "Add Merchant Info Tag",
              "merchant-info-tag/add"
            )}
          </div>
          <Spin spinning={this.state.loading}>
            <div className="kt-portlet__body">
              {/* <!--begin: Datatable --> */}
              {/* <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
										<thead>
											<tr>
												<th>Record ID</th>
												<th>Order ID</th>
												<th>Country</th>
												<th>Ship City</th>
												<th>Company Agent</th>
												<th>Ship Date</th>
												<th>Status</th>
												<th>Type</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tfoot>
											<tr>
												<th>Record ID</th>
												<th>Order ID</th>
												<th>Country</th>
												<th>Ship City</th>
												<th>Company Agent</th>
												<th>Ship Date</th>
												<th>Status</th>
												<th>Type</th>
												<th>Actions</th>
											</tr>
										</tfoot>
									</table> */}

              {/* <!--end: Datatable --> */}

              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                page={this.state.currentPage - 1}
                pages={this.state.totalPages}
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={20}
                // showPageJump={false}
                showPageSizeOptions={false}
                manual
                onFetchData={(state) => {
                  if (this.state.currentPage !== state.page + 1) {
                    this.setState({ currentPage: state.page + 1 });
                    setTimeout(() => {
                      this.getMerchantInfoTag();
                    }, 200);
                  }
                }}
                columns={[
                  // {
                  // Header: "Name",
                  // columns: [
                  {
                    Header: <p style={tableHeaderStyles()}>Icon</p>,
                    id: "logo",
                    accessor: (d) => (
                      <img
                        src={d.logo}
                        style={{ height: 50, width: 50 }}
                        alt="logo"
                      />
                    ),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["logo"] }),
                    filterable: false,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    id: "name",
                    accessor: (d) => d.name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Arabic Name</p>,
                    id: "arabic_name",
                    accessor: (d) => d.arabic_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["arabic_name"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "is_active",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    // filterMethod: (filter, rows) =>
                    // 	matchSorter(rows, filter.value, { keys: ["is_active"] }),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "true") {
                        return row[filter.id] === true;
                      }
                      return row[filter.id] === false;
                    },
                    Filter: ({ filter, onChange }) => (
                      <Select
                        onChange={(value) => onChange(value)}
                        style={{ width: "100%" }}
                        defaultValue="all"
                      >
                        <Option value="all">Show All</Option>
                        <Option value="true">Active</Option>
                        <Option value="false">De-Activate</Option>
                      </Select>
                    ),
                    // filterAll: true
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 150,
                    accessor: (d) => (
                      <div>
                        <Link
                          to={`merchant-info-tag/edit/${d.id}`}
                          style={{ marginRight: 20 }}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="edit"
                          />
                        </Link>
                        <Popconfirm
                          title="Are you sure you want to delete this merchant info tag?"
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() => this.deleteMerchantInfoTag(d.id)}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="delete"
                          />
                        </Popconfirm>
                        <Popconfirm
                          // style={{}}
                          title={
                            d.is_active
                              ? "Are you sure you want to DEACTIVATE this Merchant-Info-Tag"
                              : "Are you sure you want to ACTIVATE this Merchant-Info-Tag"
                          }
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.merchantInfoTagActivation(d.id, d.is_active)
                          }
                        >
                          <Switch
                            style={{
                              backgroundColor: d.is_active
                                ? "#5d78ff"
                                : "#fd397a",
                              marginLeft: 15,
                            }}
                            checked={d.is_active}
                            onChange={(val) => console.log(val)}
                          />
                        </Popconfirm>
                      </div>
                    ),
                    filterable: false,
                  },
                  // ]
                  // },
                  // {
                  // Header: "Info",
                  // columns: [
                  // {
                  // Header: "Gender",
                  // accessor: "age"
                  // },
                  // {
                  // Header: "Over 21",
                  // accessor: "age",
                  // id: "over",
                  // Cell: ({ value }) => (value >= 21 ? "Yes" : "No"),
                  // filterMethod: (filter, row) => {
                  // 	if (filter.value === "all") {
                  // 	return true;
                  // 	}
                  // 	if (filter.value === "true") {
                  // 	return row[filter.id] >= 21;
                  // 	}
                  // 	return row[filter.id] < 21;
                  // },
                  // Filter: ({ filter, onChange }) =>
                  // 	<select
                  // 	onChange={event => onChange(event.target.value)}
                  // 	style={{ width: "100%" }}
                  // 	value={filter ? filter.value : "all"}
                  // 	>
                  // 	<option value="all">Show All</option>
                  // 	<option value="true">Can Drink</option>
                  // 	<option value="false">Can't Drink</option>
                  // 	</select>
                  // }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
