import React from 'react';
import {
    Select,
    Icon,
    Spin
} from 'antd';
import { Link } from 'react-router-dom'
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import matchSorter from 'match-sorter';
import {
    infoNotification,
    errorNotification
} from '../Shared/Notifications';
import SharedModel from '../../Models/SharedModel';



const { Option } = Select;

export default class Content extends React.Component {



    constructor() {
        super();
        this.state = {
            data: [],
            loading: false
        }
    }

    componentWillMount(){
        const role_id = localStorage.getItem("user_role")
        switch (Number(role_id)) {
            case 1:
                break;
             case 5:
                 window.location.href = '/order-report'
                 break;
             default:
                window.location.href = '/login'
                break;
        }
     }

    componentDidMount() {
        const it = this;
        it.setState({ loading: true })
        SharedModel.getInstance().getPages(
            function success(data) {
                it.setState({ loading: false, data })
            },
            function failure(error) {
                it.setState({ loading: false })
                errorNotification("Request information not found")
            }
        )


    }



    render() {
        const { data, loading } = this.state;
        return (
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >

                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg">
                        <div className="kt-portlet__head-label">
                            <span className="kt-portlet__head-icon">
                                <i className="kt-font-brand flaticon2-line-chart"></i>
                            </span>
                            <h3 className="kt-portlet__head-title">
                                Static Pages
										</h3>
                        </div>
                    </div>
                    <div className="kt-portlet__body">
                        <Spin spinning={loading} >
                            {/* react-table */}
                            <ReactTable
                                data={data}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}
                                columns={[
                                    // {
                                    // Header: "Name",
                                    // columns: [
                                    {
                                        Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Name</p>,
                                        id: "name",
                                        accessor: d => d.name,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, { keys: ["name"] }),
                                        filterAll: true
                                    },
                                    {
                                        Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Actions</p>,
                                        id: "actions",
                                        // width: '200',
                                        accessor: d => (
                                            <div>
                                                <Link
                                                    to={`static/edit/${d.id}`}
                                                    style={{ marginRight: 20 }}
                                                >
                                                    <Icon style={{ color: '#5D78FF', fontSize: 16 }} type="edit" />
                                                </Link>
                                            </div>
                                        ),
                                        filterable: false
                                    },
                                    // ]
                                    // },
                                    // {
                                    // Header: "Info",
                                    // columns: [
                                    // {
                                    // Header: "Gender",
                                    // accessor: "age"
                                    // },
                                    // {
                                    // Header: "Over 21",
                                    // accessor: "age",
                                    // id: "over",
                                    // Cell: ({ value }) => (value >= 21 ? "Yes" : "No"),
                                    // filterMethod: (filter, row) => {
                                    // 	if (filter.value === "all") {
                                    // 	return true;
                                    // 	}
                                    // 	if (filter.value === "true") {
                                    // 	return row[filter.id] >= 21;
                                    // 	}
                                    // 	return row[filter.id] < 21;
                                    // },
                                    // Filter: ({ filter, onChange }) =>
                                    // 	<select
                                    // 	onChange={event => onChange(event.target.value)}
                                    // 	style={{ width: "100%" }}
                                    // 	value={filter ? filter.value : "all"}
                                    // 	>
                                    // 	<option value="all">Show All</option>
                                    // 	<option value="true">Can Drink</option>
                                    // 	<option value="false">Can't Drink</option>
                                    // 	</select>
                                    // }
                                ]
                                }
                                defaultPageSize={10}
                                className="-striped -highlight"
                            />
                        </Spin>

                    </div>
                </div>
            </div>
        )
    }
}


