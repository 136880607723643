import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel'



export default class PartnerModel {
    static myInstance = null;
    static getInstance() {
        if (PartnerModel.myInstance === null) {
            PartnerModel.myInstance = new PartnerModel();
        }
        return this.myInstance;
    }

    static PartnerList = [];



    getPartner(successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getPartner(token,
            function resSuccess(data) {
                PartnerModel.getInstance().PartnerList = data.partners.data;
                successTrigger(data.partners);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }
    searchPartner(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().searchPartner(token, params,
            function resSuccess(data) {
                PartnerModel.getInstance().PartnerList = data.partners.data;
                successTrigger(data.partners);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    addPartner(name, arabic_name, cities, appId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        let params = {}
        params.name = name;
        params.arabic_name = arabic_name;
        params.city_id = cities;
        params.app_id = appId;

        ApiManager.getInstance().addPartner(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    getPartnerDetail(partnerId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getPartnerDetail(token, partnerId,
            function resSuccess(data) {
                successTrigger(data.partner);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updatePartner(partnerId, name, arabic_name, cities, appId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        let params = {};
        params.name = name;
        params.arabic_name = arabic_name;
        params.city_id = cities;
        params.app_id = appId;
        ApiManager.getInstance().updatePartner(token, params, partnerId,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    removePartner(partnerId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removePartner(token, partnerId,
            function resSuccess() {
                PartnerModel.getInstance().PartnerList.forEach((partner, index) => {
                    if (partner.id === partnerId) {
                        PartnerModel.getInstance().PartnerList.splice(index, 1);
                        successTrigger()
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    partnerActivation(partnerId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().partnerActivation(token, params, partnerId,
            function resSuccess(data) {
                PartnerModel.getInstance().PartnerList.forEach((partner, index) => {
                    if (partner.id === partnerId) {
                        partner.is_active = params.is_active;
                        successTrigger(data)
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )

    }

    getPartnersByApp(app_id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getPartnersByApp(token, app_id,
            function resSuccess(data) {
                successTrigger(data.partners)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )

    }

}