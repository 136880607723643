import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel';



export default class OrderTimeSlotModel {
    static myInstance = null;
    static getInstance() {
        if (OrderTimeSlotModel.myInstance === null) {
            OrderTimeSlotModel.myInstance = new OrderTimeSlotModel();
        }
        return this.myInstance;
    }
    static OrderTimeSlotList = [];
    static OrderTimeSlotConfigsList = [];


    addOrderTimeSlot(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addOrderTimeSlot(token, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    getAllOrderTimeSlots(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getAllOrderTimeSlots(token, params,
            function successCallback(data) {
                OrderTimeSlotModel.OrderTimeSlotList = data.records
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    getOrderTimeSlotDetail(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOrderTimeSlotDetail(token, id,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    updateOrderTimeSlot(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateOrderTimeSlot(token, id, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    activateOrderTimeSlot(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateOrderTimeSlot(token, id, params,
            function successCallback(data) {
                for (let i = 0; i < OrderTimeSlotModel.OrderTimeSlotList.length; i++) {
                    if (OrderTimeSlotModel.OrderTimeSlotList[i].id === id) {
                        OrderTimeSlotModel.OrderTimeSlotList[i].is_active = params.is_active;
                        break;
                    }
                }
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    removeOrderTimeSlot(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeOrderTimeSlot(token, id,
            function successCallback(data) {
                OrderTimeSlotModel.OrderTimeSlotList.forEach((orderTimeSlot, index) => {
                    if (orderTimeSlot.id === id) {
                        OrderTimeSlotModel.OrderTimeSlotList.splice(index, 1);
                        successTrigger(data)
                    }
                });
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }

    addOrderTimeSlotConfig(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addOrderTimeSlotConfig(token, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    updateOrderTimeSlotConfig(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateOrderTimeSlotConfig(token, id, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    removeOrderTimeSlotConfig(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeOrderTimeSlotConfig(token, id,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }

}