import React from "react";
import matchSorter from "match-sorter";
import { Link } from "react-router-dom";
import { Icon, Modal, Select } from "antd";
import { getCompressedImage } from "Helpers/Shared/utils";

import ReactTable from "react-table";
import "react-table/react-table.css";
import backImage from "../../../src/assets/media/users/default.jpg";
import UserModel from "../../Models/UserModel";
import ProgramModel from "../../Models/ProgramModel";
import { errorNotification, errorToaster } from "../Shared/Notifications";
import { successNotification } from "../Shared/Notifications";
import moment from "moment";
import { tableHeaderStyles } from "styles/tableStyles";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      data: [],
      visible: false,
      confirmLoading: false,
      amount: "",
      amountError: false,
      programList: [],
      program: null,
      programError: false,
      image: null,
      imageSrc: null,
      currentPage: 1,
      totalPage: 0,
      perPage: 0,
      filters: [],
      userDetail: {},
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getUsersList();

    let it = this;
    it.setState({
      loading: true,
    });
    ProgramModel.getInstance().getProgram(
      null,
      function success() {
        it.setState({
          programList: ProgramModel.getInstance().ProgramList,
          // loading: false
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false });
      }
    );
  }

  getUsersList() {
    const it = this;
    let params = {
      offset: this.state.currentPage,
      "user_roles.name": "customer",
    };
    this.state.filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });

    it.setState({ loading: true });
    UserModel.getInstance().searchUsersList(
      params,
      function success(data) {
        it.setState({
          data: UserModel.getInstance().UserList,
          totalPage: data.users.last_page,
          perPage: data.users.per_page,
          loading: false,
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false, data: [] });
        errorNotification(error);
        errorToaster("Request information not found");
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getUsersList();
  }, 1000);

  saveImage(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ loading: true });
    getCompressedImage(file)
      .then((compressedImage) => {
        if (compressedImage) {
          this.setState({
            loading: false,
            image: compressedImage,
            imageSrc: URL.createObjectURL(compressedImage),
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        errorNotification(e);
      });
  }

  showModal(user_id) {
    const it = this;
    this.setState({ loading: true });
    UserModel.getInstance().getUserDetails(
      user_id,
      function success(userDetail) {
        it.setState({
          userDetail,
          loading: false,
          visible: true,
        });
      },
      function failure(error) {
        errorToaster("Doesn't open program association");
        it.setState({
          loading: false,
        });
        console.log(error);
      }
    );
  }

  //  This works when click ok button first of all check amount and program validation then data land over the payment page

  handleOk = () => {
    const { program, userDetail } = this.state;
    const { history } = this.props;
    it = this;
    let params = {
      id: userDetail.id,
    };
    // if (!amount) {
    // 	it.setState({ amountError: true });
    // 	errorToaster('Please Enter Amount');
    // 	return
    // }
    // params.amount = amount;

    if (!program) {
      it.setState({ programError: true });
      errorToaster("Please Select program");
      return;
    }
    params.program_id = program;

    it.setState({ loading: true });
    UserModel.getInstance().programAssociation(
      params,
      function success(data) {
        successNotification("Program Associated Successfully");
        setTimeout(() => {
          history.push("/payment-log");
        }, 500);
      },
      function failure(error) {
        errorNotification(error);
        console.log(error);
        it.setState({ loading: false });
      }
    );
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      userDetail: {},
    });
  };

  renderActionView(d) {
    if ((d.role = "Admin")) {
      return (
        <div>
          <Link to={`/customer/edit/${d.id}`} style={{ marginRight: 20 }}>
            <Icon style={{ color: "#5D78FF", fontSize: 16 }} type="edit" />
          </Link>
          <Icon
            style={{ color: "gray", fontSize: 16, marginRight: 20 }}
            type="form"
            onClick={() => this.showModal(d.id)}
          />
          {/* <Icon style={{ color: 'gray', fontSize: 16, marginRight: 20 }} type="form" onClick={this.call_user_details()} /> */}
        </div>
      );
    } else {
      return (
        <div>
          <Icon
            style={{ color: "gray", fontSize: 16, marginRight: 20 }}
            type="edit"
          />
        </div>
      );
    }
  }

  render() {
    const {
      data,
      visible,
      amountError,
      programError,
      programList,
      userDetail,
    } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon2-line-chart"></i>
              </span>
              <h3 className="kt-portlet__head-title">Customers</h3>
            </div>
            {/* <div className="kt-portlet__head-toolbar">
							<div className="kt-portlet__head-wrapper">
								<div className="kt-portlet__head-actions">
									<a href="user/add" className="btn btn-brand btn-elevate btn-icon-sm">
										<i className="la la-plus"></i>
										Add New User
												</a>
								</div>
							</div>
						</div> */}
          </div>
          <div className="kt-portlet__body">
            {/* <!--begin: Datatable --> */}
            {/* <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
										<thead>
											<tr>
												<th>Record ID</th>
												<th>Order ID</th>
												<th>Country</th>
												<th>Ship City</th>
												<th>Company Agent</th>
												<th>Ship Date</th>
												<th>Status</th>
												<th>Type</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tfoot>
											<tr>
												<th>Record ID</th>
												<th>Order ID</th>
												<th>Country</th>
												<th>Ship City</th>
												<th>Company Agent</th>
												<th>Ship Date</th>
												<th>Status</th>
												<th>Type</th>
												<th>Actions</th>
											</tr>
										</tfoot>
									</table> */}

            {/* <!--end: Datatable --> */}

            {/* react-table */}
            <ReactTable
              data={data}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              pageSize={10}
              // showPageJump={false}
              showPageSizeOptions={false}
              manual
              page={this.state.currentPage - 1}
              pages={this.state.totalPage}
              filtered={this.state.filters}
              // onFetchData={(state) => {
              // 	// console.log(state.filtered)
              // 	if (this.state.currentPage !== state.page + 1) {
              // 		this.setState({ currentPage: state.page + 1 })
              // 		setTimeout(() => {
              // 			this.getMerchant()
              // 		}, 50);
              // 	}
              // }}
              onPageChange={(pageIndex) => {
                this.setState({ currentPage: pageIndex + 1 });
                setTimeout(() => {
                  this.getUsersList();
                }, 50);
              }}
              onFilteredChange={(filters) => {
                this.setState({ currentPage: 1, filters });
                this.searchQuery(this);
              }}
              columns={[
                // {
                // Header: "Name",
                // columns: [
                {
                  Header: <p style={tableHeaderStyles()}>Full Name</p>,
                  id: "full_name",
                  accessor: (d) => d.full_name,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["full_name"] }),
                  filterAll: true,
                  sortable: false,
                },
                {
                  Header: <p style={tableHeaderStyles()}>Email</p>,
                  id: "email",
                  accessor: (d) => d.email,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["email"] }),
                  filterAll: true,
                  sortable: false,
                },
                {
                  Header: <p style={tableHeaderStyles()}>Phone Number</p>,
                  id: "mobile",
                  accessor: (d) => d.mobile,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["mobile"] }),
                  filterAll: true,
                  sortable: false,
                },
                // {
                // 	Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Roles</p>,
                // 	id: "user_roles.name",
                // 	accessor: d => d.role,
                // 	filterMethod: (filter, rows) =>
                // 		matchSorter(rows, filter.value, { keys: ["role"] }),
                // 	filterAll: true,
                // 	sortable: false
                // },
                {
                  Header: <p style={tableHeaderStyles()}>Apps</p>,
                  id: "apps.name",
                  accessor: (d) =>
                    d.apps.map((app, key) => (
                      <p
                        className="btn btn-label-info btn-pill"
                        style={{
                          float: "left",
                          margin: "2px",
                          padding: "5px 10px",
                          fontSize: "11px",
                        }}
                        key={key}
                      >
                        {app}
                      </p>
                    )),
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["role"] }),
                  filterAll: true,
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: <p style={tableHeaderStyles()}>Programs</p>,
                  id: "program.name",
                  accessor: (d) =>
                    d.programs.map((program, key) => (
                      <p
                        className="btn btn-label-info btn-pill"
                        style={{
                          float: "left",
                          margin: "2px",
                          padding: "5px 10px",
                          fontSize: "11px",
                        }}
                        key={key}
                      >
                        {program}
                      </p>
                    )),
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["role"] }),
                  filterAll: true,
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: <p style={tableHeaderStyles()}>Created At</p>,
                  id: "created_at",
                  accessor: (d) =>
                    d.created_at
                      ? moment(d.created_at).format("hh:mm A - DD/MMM/YYYY")
                      : "N/A",
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: [(item) => item.created_at],
                    }),
                  filterAll: true,
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: <p style={tableHeaderStyles()}>Actions</p>,
                  id: "actions",
                  width: 150,
                  accessor: (d) => this.renderActionView(d),
                  filterable: false,
                  sortable: false,
                },
                // {
                // 	Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Type</p>,
                // 	id: "user_types",
                // 	accessor: d => d.user_types.map((x) => x.name),
                // 	filterMethod: (filter, rows) =>
                // 		matchSorter(rows, filter.value, { keys: [item => item.user_types] }),
                // 	filterAll: true
                // },
                // {
                // 	Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Created By</p>,
                // 	id: "created_by_user",
                // 	accessor: d => d.created_by_user ? d.created_by_user.full_name : "N/A",
                // 	filterMethod: (filter, rows) =>
                // 		matchSorter(rows, filter.value, { keys: [item => item.created_by_user] }),
                // 	filterAll: true
                // },
                // {
                // 	Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Actions</p>,
                // 	id: "actions",
                // 	width: 150,
                // 	accessor: d => (
                // 		<div>
                // 			<Link
                // 				to={`user/edit/${d.id}`}
                // 				style={{ marginRight: 20 }}
                // 			>
                // 				<Icon style={{ color: '#5D78FF', fontSize: 16 }} type="edit" />
                // 			</Link>
                // 			<Link
                // 			to={`user/edit/${d.id}`}
                // 			>
                // 				<Icon style={{ color: '#5D78FF', fontSize: 16 }} type="delete" />
                // 			</Link>
                // 		</div>
                // 	),
                // 	filterable: false
                // },

                // ]
                // },
                // {
                // Header: "Info",
                // columns: [
                // {
                // Header: "Gender",
                // accessor: "age"
                // },
                // {
                // Header: "Over 21",
                // accessor: "age",
                // id: "over",
                // Cell: ({ value }) => (value >= 21 ? "Yes" : "No"),
                // filterMethod: (filter, row) => {
                // 	if (filter.value === "all") {
                // 	return true;
                // 	}
                // 	if (filter.value === "true") {
                // 	return row[filter.id] >= 21;
                // 	}
                // 	return row[filter.id] < 21;
                // },
                // Filter: ({ filter, onChange }) =>
                // 	<select
                // 	onChange={event => onChange(event.target.value)}
                // 	style={{ width: "100%" }}
                // 	value={filter ? filter.value : "all"}
                // 	>
                // 	<option value="all">Show All</option>
                // 	<option value="true">Can Drink</option>
                // 	<option value="false">Can't Drink</option>
                // 	</select>
                // }
              ]}
              // ]}
              defaultPageSize={10}
              className="-striped -highlight"
            />
          </div>
          <Modal
            title="Program Association"
            visible={visible}
            okText="Create"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <h2>
              <center>Customer details</center>
            </h2>

            <div className="kt-portlet__body">
              <form className="kt-form" id="kt_form">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8">
                    <div className="kt-section kt-section--first">
                      <div className="kt-section__body">
                        <div className="form-group row">
                          <div className="col-xl-3 col-lg-3 col-form-label"></div>
                          <div className="col-lg-9 col-xl-6">
                            <div
                              className="kt-avatar kt-avatar--outline kt-avatar"
                              id="kt_apps_user_add_avatar"
                            >
                              <div
                                className="kt-avatar__holder"
                                style={{
                                  backgroundImage: `url(${
                                    this.state.userDetail.image
                                      ? this.state.userDetail.image
                                      : backImage
                                  })`,
                                }}
                              ></div>
                              <label
                                className="kt-avatar__upload"
                                data-toggle="kt-tooltip"
                                title=""
                                data-original-title="Change avatar"
                              >
                                <i className="fa fa-pen"></i>
                                <input
                                  type="file"
                                  name="kt_apps_user_add_user_avatar"
                                  onChange={(event) => this.saveImage(event)}
                                />
                              </label>
                              <span
                                className="kt-avatar__cancel"
                                data-toggle="kt-tooltip"
                                title=""
                                data-original-title="Cancel avatar"
                              >
                                <i className="fa fa-times"></i>
                              </span>
                              {/* <p style={{ color: 'red', display: imageError ? 'block' : 'none' }}>Please Upload Icon Image</p> */}
                            </div>
                          </div>
                        </div>

                        <h5>
                          Name : <b>{userDetail.full_name}</b>
                        </h5>
                        <br></br>
                        <h5>
                          Phone number : <b>{userDetail.mobile}</b>
                        </h5>
                        <br></br>
                        <h5>
                          Email :<b>{userDetail.email}</b>
                        </h5>
                        <br></br>

                        <div className="form-group row">
                          <label className="col-3 col-form-label">Amount</label>
                          <div className="col-9">
                            <input
                              type="number"
                              name="amount"
                              placeholder="Enter Amount"
                              onChange={(event) =>
                                this.setState({ amount: event.target.value })
                              }
                              className="form-control"
                              type="text"
                            />
                            <span className="form-text text-muted">
                              <p style={{ color: amountError ? "red" : "" }}>
                                Please enter Amount
                              </p>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-3 col-form-label">
                            Program <b>*</b>
                          </label>
                          <div className="col-9">
                            <Select
                              showSearch
                              style={{ margin: "10px 0px 10px 0px" }}
                              placeholder="Select a program"
                              onChange={(program) => this.setState({ program })}
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {programList.map((program) => (
                                <Option key={program.id} value={program.id}>
                                  {program.name}
                                </Option>
                              ))}
                            </Select>
                            <span className="form-text text-muted">
                              <p style={{ color: programError ? "red" : "" }}>
                                Please choose program
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
