import React from "react";
import { Link } from "react-router-dom";
import { Icon, Popconfirm, Switch, Select, Spin } from "antd";
import _ from "lodash";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";

import {
  infoNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";

import UserModel from "../../Models/UserModel";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      totalPages: 0,
      currentPage: 1,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getProvince();
  }

  getProvince() {
    const it = this;
    it.setState({ loading: true });
    let params = {};
    params.offset = this.state.currentPage;
    UserModel.getInstance().getProvince(
      params,
      function success() {
        it.setState({
          data: UserModel.getInstance().ProvinceList,
          loading: false,
        });
      },
      function Failure(error) {
        it.setState({ loading: false });
        console.error("this is error", error);
        errorToaster("Request information not found");
      }
    );
  }

  removeProvince(province_id) {
    const it = this;
    it.setState({ loading: true });
    UserModel.getInstance().removeProvince(
      province_id,
      function success() {
        it.setState({
          data: UserModel.getInstance().ProvinceList,
          loading: false,
        });
        infoNotification("Province Deleted");
      },
      function failure(msg) {
        it.setState({ loading: false });
        errorNotification(msg);
      }
    );
  }

  provinceActivation(provinceId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = is_active === true ? false : true;
    let params = {};
    params.is_active = activation;
    UserModel.getInstance().provinceActivation(
      provinceId,
      params,
      function success() {
        it.setState({ data: UserModel.getInstance().ProvinceList });
        it.setState({ loading: false });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  activateProvince(provinceId) {
    const it = this;
    it.setState({ loading: true });
    UserModel.getInstance().activateProvince(
      provinceId,
      function success() {
        it.setState({ data: UserModel.getInstance().ProvinceList });
        it.setState({ loading: false });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  deactivateProvince(provinceId) {
    const it = this;
    it.setState({ loading: true });
    UserModel.getInstance().deactivateProvince(
      provinceId,
      function success() {
        it.setState({ data: UserModel.getInstance().ProvinceList });
        it.setState({ loading: false });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            {gridHeaderWithButton(
              "Province",
              "la la-plus",
              "Add Province",
              "province/add"
            )}
          </div>
          <div className="kt-portlet__body">
            <Spin spinning={this.state.loading}>
              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                // page={this.state.currentPage - 1}
                // pages={this.state.totalPages}
                pageSize={20}
                // showPageJump={false}
                showPageSizeOptions={false}
                // manual
                // onFetchData={(state, instance) => {
                // 	this.setState({ currentPage: state.page + 1 },
                // 		this.getProvince()
                // 	)
                // }}
                columns={[
                  // {
                  // Header: "Name",
                  // columns: [

                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    id: "name",
                    accessor: (d) => d.name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Arabic Name</p>,
                    id: "arabic_name",
                    accessor: (d) => d.arabic_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["arabic_name"],
                      }),
                    filterAll: true,
                  },

                  // {
                  {
                    Header: <p style={tableHeaderStyles()}>Country</p>,
                    id: "country_name",
                    accessor: (d) => d.country_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["country_name"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "is_active",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    // filterMethod: (filter, rows) =>
                    // 	matchSorter(rows, filter.value, { keys: ["is_active"] }),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "true") {
                        return row[filter.id] === true;
                      }
                      return row[filter.id] === false;
                    },
                    Filter: ({ filter, onChange }) => (
                      <Select
                        onChange={(value) => onChange(value)}
                        style={{ width: "100%" }}
                        defaultValue="all"
                      >
                        <Option value="all">Show All</Option>
                        <Option value="true">Active</Option>
                        <Option value="false">De-Activate</Option>
                      </Select>
                    ),
                    // filterAll: true
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Action</p>,
                    id: "actions",
                    width: 150,
                    accessor: (d) => (
                      <div>
                        <Link
                          to={`province/edit/${d.id}`}
                          style={{ marginRight: 20 }}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="edit"
                          />
                        </Link>
                        <Popconfirm
                          title="Are you sure you want to delete this province?"
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() => this.removeProvince(d.id)}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="delete"
                          />
                        </Popconfirm>
                        <Popconfirm
                          // style={{}}
                          title={
                            d.is_active
                              ? "Are you sure you want to DEACTIVATE this Province"
                              : "Are you sure you want to ACTIVATE this Province"
                          }
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.provinceActivation(d.id, d.is_active)
                          }
                        >
                          <Switch
                            style={{
                              backgroundColor: d.is_active
                                ? "#5d78ff"
                                : "#fd397a",
                              marginLeft: 15,
                            }}
                            checked={d.is_active}
                            onChange={(val) => console.log(val)}
                          />
                        </Popconfirm>
                      </div>
                    ),
                    filterable: false,
                  },
                  // ]
                  // },
                  // {
                  // Header: "Info",
                  // columns: [
                  // {
                  // Header: "Gender",
                  // accessor: "age"
                  // },
                  // {
                  // Header: "Over 21",
                  // accessor: "age",
                  // id: "over",
                  // Cell: ({ value }) => (value >= 21 ? "Yes" : "No"),
                  // filterMethod: (filter, row) => {
                  // 	if (filter.value === "all") {
                  // 	return true;
                  // 	}
                  // 	if (filter.value === "true") {
                  // 	return row[filter.id] >= 21;
                  // 	}
                  // 	return row[filter.id] < 21;
                  // },
                  // Filter: ({ filter, onChange }) =>
                  // 	<select
                  // 	onChange={event => onChange(event.target.value)}
                  // 	style={{ width: "100%" }}
                  // 	value={filter ? filter.value : "all"}
                  // 	>
                  // 	<option value="all">Show All</option>
                  // 	<option value="true">Can Drink</option>
                  // 	<option value="false">Can't Drink</option>
                  // 	</select>
                  // }
                ]}
                className="-striped -highlight"
              />
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}
