import React, { useState } from 'react';

import {
    TimePicker,
    Input,
    Spin,
    DatePicker,
    Select,
    Icon,
    Switch
} from 'antd';

import {
    successNotification,
    errorNotification,
    errorToaster
} from '../Shared/Notifications';

import moment from 'moment';


import SharedModel from '../../Models/SharedModel';

import DataJson from './data.json'

const { Option } = Select;


export default function AddAppVisibility() {
    const
        [loading, setLoading] = useState(false),
        [name, setName] = useState(''),
        [description, setDescription] = useState(''),
        [validFrom, setValidFrom] = useState(moment()),
        [validTill, setValidTill] = useState(moment().add(1, 'M')),
        [isDefault, setIsDefault] = useState(false),
        [configList, setConfigList] = useState([]);

    const addProductVisiblity = () => {

        if (name.length < 1) {
            errorToaster("Please Enter Visibility Name")
            return
        }

        if (description.length < 1) {
            errorToaster("Please Enter Description")
            return
        }

        if (!validFrom) {
            errorToaster("Please choose start date for visibility")
            return
        }

        if (!validTill) {
            errorToaster("Please choose end date for visibility")
            return
        }

        let breakLoop = false;
        configList.forEach(conf => {
            if (!conf.start_time || !conf.end_time) {
                breakLoop = true
            }
        });

        if (breakLoop) {
            errorToaster("Please enter correct visiblity config")
            return
        }

        const params = {
            name,
            description,
            valid_from: validFrom.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            valid_till: validTill.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            is_default: isDefault,
            product_visibility_configs: configList
        };

        SharedModel.getInstance().addProductVisibility(params,
            function success(data) {
                addConfigs(data.id)
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    // Create a function that will create bulk configs

    function addConfigs(product_visibility_id) {
        const configs = configList.map(conf => {
            return { ...conf, start_time: moment(conf.start_time, 'HH:mm').format('HH:mm'), end_time: moment(conf.end_time, 'HH:mm').format('HH:mm'), product_visibility_id }
        })
        SharedModel.getInstance().addProductVisibilityConfig(configs,
            function success(data) {
                successNotification('Product Visibility Created Successfully');
                setTimeout(() => {
                    setLoading(false);
                    window.location.href = '/product-visibility'
                }, 2000);
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }



    function disablePreviousDate(current) {
        return current < moment().startOf('day');
    }

    function addConfig() {
        let list = [...configList]
        list.push({ ...DataJson.addConfigObject });
        setConfigList(list);
    }

    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                <Spin spinning={loading} >

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                <div className="kt-portlet__head kt-portlet__head--lg">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">Add Product Visibility</h3>
                                    </div>
                                    <div className="kt-portlet__head-toolbar">
                                        <a href="/product-visibility" className="btn btn-clean kt-margin-r-10">
                                            <i className="la la-arrow-left"></i>
                                            <span className="kt-hidden-mobile">Back</span>
                                        </a>
                                        <div className="btn-group">
                                            <button onClick={() => addProductVisiblity()} type="button" className="btn btn-brand">
                                                <i className="la la-check"></i>
                                                <span className="kt-hidden-mobile">Save</span>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                                <div className="kt-portlet__body">
                                    <form className="kt-form" id="kt_form">
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-8">
                                                <div className="kt-section kt-section--first">
                                                    <div className="kt-section__body">

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Name <b>*</b></label>
                                                            <div className="col-9">
                                                                <Input maxLength={100} value={name} onChange={(event) => setName(event.target.value)} className="form-control" type="text" />
                                                                <span className="form-text text-muted"><p style={{}}>Please Enter Product Visibility Name</p></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Description <b>*</b></label>
                                                            <div className="col-9">
                                                                <textarea maxLength={255} value={description} onChange={(event) => setDescription(event.target.value)} className="form-control" type="text" />
                                                                <span className="form-text text-muted"><p style={{}}>Please Enter Product Visibility description</p></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Make Default</label>
                                                            <div className="col-9">
                                                                <Switch checked={isDefault} checkedChildren="Yes" unCheckedChildren="No" onChange={(checked) => setIsDefault(checked)} />
                                                                <span className="form-text text-muted"><p style={{}}>Please Check either Visibilitiy is Default or Not</p></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Start Date <b>*</b></label>
                                                            <div className="col-9">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    format="DD-MMM-YYYY"
                                                                    disabledDate={disablePreviousDate}
                                                                    value={validFrom}
                                                                    style={{ padding: "0px", border: '0px solid black' }}
                                                                    allowClear={false}
                                                                    onChange={(value) => setValidFrom(value)} />
                                                                <span className="form-text text-muted"><p style={{}}>Please Select start date</p></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">End Date <b>*</b></label>
                                                            <div className="col-9">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    format="DD-MMM-YYYY"
                                                                    disabledDate={disablePreviousDate}
                                                                    value={validTill}
                                                                    style={{ padding: "0px", border: '0px solid black' }}
                                                                    allowClear={false}
                                                                    onChange={(value) => setValidTill(value)} />
                                                                <span className="form-text text-muted"><p style={{}}>Please Select end date</p></span>
                                                            </div>
                                                        </div>


                                                        {
                                                            configList.map((config, i) => (
                                                                <div key={i} className="form-group row">
                                                                    <label className="col-3 col-form-label">Config # {i + 1} </label>
                                                                    <div className="col-3">
                                                                        <TimePicker
                                                                            id="1"
                                                                            minuteStep={1}
                                                                            className="form-control"
                                                                            format="HH:mm"
                                                                            value={moment(config.start_time, 'HH:mm')}
                                                                            style={{ padding: "0px", border: '0px solid black' }}
                                                                            allowClear={false}
                                                                            onChange={(value) => {
                                                                                let val = [...configList];
                                                                                val[i].start_time = moment(value).format('HH:mm');
                                                                                setConfigList(val);
                                                                            }} />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Select start time</p></span>
                                                                    </div>

                                                                    <div className="col-3">
                                                                        <TimePicker
                                                                            id="2"
                                                                            minuteStep={1}
                                                                            className="form-control"
                                                                            format="HH:mm"
                                                                            value={moment(config.end_time, 'HH:mm')}
                                                                            style={{ padding: "0px", border: '0px solid black' }}
                                                                            allowClear={false}
                                                                            onChange={(value) => {
                                                                                let val = [...configList];
                                                                                val[i].end_time = moment(value).format('HH:mm');
                                                                                setConfigList(val);
                                                                            }} />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Select end time</p></span>
                                                                    </div>

                                                                    <div className="col-2">
                                                                        <Select
                                                                            showSearch
                                                                            value={config.day}
                                                                            onChange={(day) => {
                                                                                let val = [...configList];
                                                                                val[i].day = day;
                                                                                setConfigList(val);
                                                                            }}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {DataJson.dayArray.map((day) => (
                                                                                <Option key={day.value} value={day.value} >{day.name}</Option>
                                                                            ))}
                                                                        </Select>

                                                                        <span className="form-text text-muted"><p style={{}}>Please Select day</p></span>
                                                                    </div>
                                                                    <Icon onClick={() => {
                                                                        let val = [...configList];
                                                                        val.splice(i, 1)
                                                                        setConfigList(val);
                                                                    }} style={{ fontSize: 20, paddingTop: 5, paddingLeft: 5 }} type="delete" />

                                                                </div>
                                                            ))
                                                        }



                                                        <div className="form-group row">
                                                            {/* <label className="col-3 col-form-label">End Date <b>*</b></label> */}
                                                            <div className="col-9">
                                                                <div className="btn-group">
                                                                    <button onClick={() => addConfig()} type="button" className="btn btn-brand">
                                                                        <i className="la la-plus"></i>
                                                                        <span className="kt-hidden-mobile">Add Config</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div >

    )
}