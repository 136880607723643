import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel';



export default class OnlineDealModel {
    static myInstance = null;
    static getInstance() {
        if (OnlineDealModel.myInstance === null) {
            OnlineDealModel.myInstance = new OnlineDealModel();
        }
        return this.myInstance;
    }

    static OnlineDealList = [];


    getAllOnlineDeals(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getAllOnlineDeals(token, params,
            function successCallback(data) {
                OnlineDealModel.OnlineDealList = data.records
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }

        )
    }

    addOnlineDeal(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addOnlineDeal(token, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }

        )
    }

    activateOnlineDeal(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateOnlineDeal(token, id, params,
            function resSuccess(data) {
                for (let i = 0; i < OnlineDealModel.OnlineDealList.length; i++) {
                    if (OnlineDealModel.OnlineDealList[i].id === id) {
                        OnlineDealModel.OnlineDealList[i].is_active = params.is_active;
                        break;
                    }
                }
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateOnlineDeal(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateOnlineDeal(token, id, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    deleteOnlineDeal(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().deleteOnlineDeal(token, id,
            function resSuccess(data) {
                OnlineDealModel.OnlineDealList.forEach((visibility, index) => {
                    if (visibility.id === id) {
                        OnlineDealModel.OnlineDealList.splice(index, 1);
                        successTrigger(data)
                    }
                });
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getOnlineDeal(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOnlineDeal(token, id,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    addOnlineDealConfig(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addOnlineDealConfig(token, params,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateOnlineDealConfig(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateOnlineDealConfig(token, id, params,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    deleteOnlineDealConfig(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().deleteOnlineDealConfig(token, id,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getOnlineDealMerchants(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOnlineDealMerchants(token, params,
            function successCallback(data) {
                successTrigger(data.records)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }

        )
    }


    getOnlineDealVoucherConfigs(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOnlineDealVoucherConfigs(token, params,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    addOnlineDealVoucherConfig(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addOnlineDealVoucherConfig(token, params,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateOnlineDealVoucherConfig(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateOnlineDealVoucherConfig(token, id, params,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    deleteOnlineDealVoucherConfig(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().deleteOnlineDealVoucherConfig(token, id,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

}