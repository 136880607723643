import React from "react";

import backImage from "../../../assets2/Images/bg-3.jpg";
import logo from "../../../assets/media/logos/logo-4.png";
import { validateEmail } from "../../../Helpers/Shared/Validators";
import { errorNotification, errorToaster } from "../../Shared/Notifications";
import UserModel from "../../../Models/UserModel";
// import ResetCode from "../ResetCode";

export default class Forgot extends React.Component {
  state = {
    loading: false,
    email: "",
    emailError: false,
    serverError: null,
    code: "",
    codeError: false,
    isVerifyEmail: false,
    isVerifyCode: true,
    isVerifyPassword: false,
  };

  resetEmail() {
    // const { email, isVerifyPassword, isVerifyCode, isVerifyEmail } = this.state;
    const { email } = this.state;
    let it = this;
    let params = {};
    this.setState({
      emailError: false,
    });

    if (!validateEmail(email)) {
      this.setState({ emailError: "Please enter a valid Email" });
      errorToaster("Please enter a valid Email");
      return;
    }
    params.email = email;

    this.setState({ loading: true });
    UserModel.getInstance().resetEmail(
      params,
      function success() {
        it.setState({ isVerifyEmail: true, isVerifyCode: false });
      },
      function failure(serverError) {
        errorNotification(serverError);
        it.setState({ loading: false });
      }
    );
  }

  resetCode() {
    // const { email, code, isVerifyEmail, isVerifyCode, isVerifyPassword } =
    //   this.state;
    const { email, code } = this.state;
    let it = this;
    let params = {};
    this.setState({
      emailError: false,
      codeError: false,
    });

    params.email = email;

    if (code.length < 1) {
      this.setState({ codeError: "Please enter a valid Code" });
      errorToaster("Please enter a valid Code");
      return;
    }
    params.code = code;

    this.setState({ loading: true });
    UserModel.getInstance().resetCode(
      params,
      function success() {
        it.setState({
          isVerifyEmail: false,
          isVerifyCode: false,
          isVerifyPassword: true,
        });
      },
      function failure(serverError) {
        errorNotification(serverError);
        it.setState({ loading: false });
      }
    );
  }

  resetPassword() {
    const { email, code, password } = this.state;
    let it = this;
    let params = {};
    this.setState({
      emailError: false,
      codeError: false,
      passwordError: false,
    });

    params.email = email;

    params.code = code;

    if (password.length < 6) {
      this.setState({
        passwordError: "Password Must be at least 6 characters",
      });
      errorToaster("Password Must be at least 6 characters");
      return;
    }

    params.password = password;

    this.setState({ loading: true });
    UserModel.getInstance().updatePassword(
      params,
      function success() {
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
      },
      function failure(error) {
        console.log(error);
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v3 kt-login--forgot"
          id="kt_login"
        >
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
            style={{ backgroundImage: `url(${backImage})` }}
          >
            <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
              <div className="kt-login__container">
                <div className="kt-login__logo">
                  <a href="#">
                    <img src={logo} />
                  </a>
                </div>
                <div className="kt-login__signin">
                  <div className="kt-login__head">
                    <h3 className="kt-login__title">Sign In To Admin</h3>
                  </div>
                  <form className="kt-form" onSubmit={this.prevent}>
                    <div className="input-group">
                      <input
                        onChange={(event) =>
                          this.setState({ userName: event.target.value })
                        }
                        className="form-control"
                        type="text"
                        placeholder="Email"
                        name="email"
                        autoComplete="off"
                      />
                    </div>
                    <div className="input-group">
                      <input
                        onChange={(event) =>
                          this.setState({ password: event.target.value })
                        }
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        name="password"
                      />
                    </div>
                    <div className="row kt-login__extra">
                      <div className="col">
                        <label className="kt-checkbox">
                          <input type="checkbox" name="remember" /> Remember me
                          <span></span>
                        </label>
                      </div>
                      <div className="col kt-align-right">
                        <a
                          href="javascript:;"
                          id="kt_login_forgot"
                          className="kt-login__link"
                        >
                          Forget Password ?
                        </a>
                      </div>
                    </div>
                    <div className="kt-login__actions">
                      <button
                        onClick={() => this.tryLogin()}
                        id="kt_login_signin_submit"
                        className="btn btn-brand btn-elevate kt-login__btn-primary"
                      >
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
                <div className="kt-login__signup">
                  <div className="kt-login__head">
                    <h3 className="kt-login__title">Sign Up</h3>
                    <div className="kt-login__desc">
                      Enter your details to create your account:
                    </div>
                  </div>
                  <form className="kt-form" action="">
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Fullname"
                        name="fullname"
                      />
                    </div>
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Email"
                        name="email"
                        autoComplete="off"
                      />
                    </div>
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        name="password"
                      />
                    </div>
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Confirm Password"
                        name="rpassword"
                      />
                    </div>
                    <div className="row kt-login__extra">
                      <div className="col kt-align-left">
                        <label className="kt-checkbox">
                          <input type="checkbox" name="agree" />I Agree the{" "}
                          <a
                            href="#"
                            className="kt-link kt-login__link kt-font-bold"
                          >
                            terms and conditions
                          </a>
                          .<span></span>
                        </label>
                        <span className="form-text text-muted"></span>
                      </div>
                    </div>
                    <div className="kt-login__actions">
                      <button
                        id="kt_login_signup_submit"
                        className="btn btn-brand btn-elevate kt-login__btn-primary"
                      >
                        Sign Up
                      </button>
                      &nbsp;&nbsp;
                      <button
                        id="kt_login_signup_cancel"
                        className="btn btn-light btn-elevate kt-login__btn-secondary"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
                <div className="kt-login__forgot">
                  <div className="kt-login__head">
                    <h3 className="kt-login__title">Forgot Password ?</h3>
                    <div className="kt-login__desc">
                      Please enter your email address. We will send you a link
                      to reset your password.
                    </div>
                  </div>
                  {/* email form */}
                  {this.state.isVerifyCode ? (
                    <div className="kt-form">
                      <div className="input-group">
                        <input
                          name="email"
                          onChange={(event) => this.handleChange(event)}
                          className="form-control"
                          type="text"
                          placeholder="Email Address"
                          name="email"
                          autoComplete="off"
                        />
                        <div
                          id="email-error"
                          style={{
                            display: this.state.emailError ? "block" : "none",
                            fontSize: "smaller",
                            fontWeight: "600",
                          }}
                          className="error invalid-feedback"
                        >
                          Please enter a valid Email.
                        </div>
                        <div
                          id="email-error"
                          style={{
                            display: this.state.serverError ? "block" : "none",
                            fontSize: "smaller",
                            fontWeight: "600",
                          }}
                          className="error invalid-feedback"
                        >
                          {this.state.serverError}
                        </div>
                      </div>
                      <div className="kt-login__actions">
                        <button
                          onClick={() => this.resetEmail()}
                          id="kt_login_forgot_submit"
                          className="btn btn-brand btn-elevate kt-login__btn-primary"
                        >
                          Request Password Reset
                        </button>
                        &nbsp;&nbsp;
                      </div>
                    </div>
                  ) : null}
                  {/* email,code */}
                  {this.state.isVerifyEmail ? (
                    <div className="kt-form">
                      <div className="input-group">
                        <input
                          disabled
                          value={this.state.email}
                          name="email"
                          onChange={(event) => this.handleChange(event)}
                          className="form-control"
                          type="text"
                          placeholder="Email Address"
                          name="email"
                          autoComplete="off"
                        />
                        <div
                          id="email-error"
                          style={{
                            display: this.state.emailError ? "block" : "none",
                            fontSize: "smaller",
                            fontWeight: "600",
                          }}
                          className="error invalid-feedback"
                        >
                          Please enter a valid Email.
                        </div>
                      </div>
                      <div className="input-group">
                        <input
                          name="code"
                          onChange={(event) =>
                            this.setState({ code: event.target.value })
                          }
                          className="form-control"
                          type="text"
                          placeholder="Enter Code"
                          name="code"
                          autoComplete="off"
                        />
                        {/* <span className="form-text text-muted"><p style={{ color: this.state.codeError ? 'red' : '' }}>Please enter code</p></span> */}
                      </div>
                      <div className="kt-login__actions">
                        <button
                          onClick={() => this.resetCode()}
                          id="kt_login_forgot_submit"
                          className="btn btn-brand btn-elevate kt-login__btn-primary"
                        >
                          Reset{" "}
                        </button>
                        &nbsp;&nbsp;
                      </div>
                    </div>
                  ) : null}

                  {this.state.isVerifyPassword ? (
                    <div className="kt-form">
                      <div className="input-group">
                        <input
                          disabled
                          value={this.state.email}
                          name="email"
                          onChange={(event) => this.handleChange(event)}
                          className="form-control"
                          type="text"
                          placeholder="Email Address"
                          name="email"
                          autoComplete="off"
                        />
                      </div>
                      <div className="input-group">
                        <input
                          disabled
                          value={this.state.code}
                          name="code"
                          onChange={(event) =>
                            this.setState({ code: event.target.value })
                          }
                          className="form-control"
                          type="text"
                          placeholder="Enter Code"
                          name="code"
                          autoComplete="off"
                        />
                      </div>
                      <div className="input-group">
                        <input
                          name="password"
                          onChange={(event) => this.handleChange(event)}
                          className="form-control"
                          type="password"
                          placeholder="New Password"
                          name="password"
                        />
                        <div
                          id="password-error"
                          style={{
                            display: this.state.passwordError
                              ? "block"
                              : "none",
                            fontSize: "smaller",
                            fontWeight: "600",
                          }}
                          className="error invalid-feedback"
                        >
                          This field is required.
                        </div>
                      </div>
                      <div className="kt-login__actions">
                        <button
                          onClick={() => this.resetPassword()}
                          id="kt_login_forgot_submit"
                          className="btn btn-brand btn-elevate kt-login__btn-primary"
                        >
                          Set Password
                        </button>
                        &nbsp;&nbsp;
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="kt-login__account">
                  <span className="kt-login__account-msg">Back to Login ?</span>
                  &nbsp;&nbsp;
                  <a href="/login" className="kt-login__account-link">
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
