import React, { useState, useCallback, useEffect } from 'react';

import {
    Popconfirm,
    Input,
    Spin,
    Select,
    Icon,
    Switch,
    Tabs
} from 'antd';
import { DetailPageHeaderWithButton } from "styles/detailPageHeader";

import _ from 'lodash'

import {
    successNotification,
    errorNotification,
    errorToaster,
} from '../Shared/Notifications';

import { getParameterByName } from "Helpers/Shared/Functions";

import ContactPocView from '../Shared/ContactPocView';
import CodeTab from './CodeTab';


import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';

import SharedModel from '../../Models/SharedModel';

import DataJson from './data.json'
import MerchantModel from '../../Models/MerchantModel';
import OnlineDealModel from '../../Models/OnlineDealModel';
import UserModel from '../../Models/UserModel';
import { validateConfigurationParams, validateOnlineDealParams } from './helperFunctions';
import ProductOfferingModel from '../../Models/ProductOfferingModel';
import ProgramModel from '../../Models/ProgramModel';
import { validateVoucherCode } from 'Helpers/Shared/Validators';

const { Option } = Select;
const { TabPane } = Tabs;

const CODE_UPLOAD_LIMIT = 300;
let CODE_OFFSET = 0;

export default function AddProductOnlineDeals(props) {
    const
        [loading, setLoading] = useState(false),
        [activeKey, setActiveKey] = useState('1'),
        [name, setName] = useState(''),
        [arabicName, setArabicName] = useState(''),
        [description, setDescription] = useState(EditorState.createEmpty()),
        [arabicDescription, setArabicDescription] = useState(EditorState.createEmpty()),
        [configList, setConfigList] = useState([]),
        [merchantList, setMerchantList] = useState([]),
        [merchant, setMerchant] = useState(undefined),
        [disableMerchant, setDisableMerchant] = useState(false),
        [productVisibilityList, setProductVisibilityList] = useState([]),
        [productVisibility, setProductVisibility] = useState(undefined),
        [type, setType] = useState(null),
        [userLimit, setUserLimit] = useState(1),
        [categoryList, setCategoryList] = useState([]),
        [infoTagList, setInfoTagList] = useState([]),
        [infoTag, setInfoTag] = useState([]),
        [programList, setProgramList] = useState([]),
        [program, setProgram] = useState([]),
        [codeConfigList, setCodeConfigList] = useState([]),
        [currentEditableData, setCurrentEditableData] = useState(undefined);

    useEffect(() => {
        getDefaultProductVisibility();
        const merchant_id = getParameterByName('merchant_id');
        if (merchant_id) {
            setDisableMerchant(true);
            getMerchantById(merchant_id);
        }
    }, [])

    function addOnlineDeal() {
        let makeCodeBlock = false;
        if (codeConfigList.length > CODE_UPLOAD_LIMIT) makeCodeBlock = true;

        const params = {
            name,
            arabic_name: arabicName,
            description: draftToHtml(convertToRaw(description.getCurrentContent())),
            arabic_description: draftToHtml(convertToRaw(arabicDescription.getCurrentContent())),
            merchant_id: merchant,
            type,
            user_limit: type === 3 ? userLimit : 1,
            product_visibility_id: productVisibility ? productVisibility : null,
            product_online_deal_configs: configList,
            product_online_deal_info_tags: infoTag,
            product_voucher_configs: !makeCodeBlock ? codeConfigList.map(code => {
                return {
                    code: code.code,
                    max_usage: code.max_usage,
                    is_active: code.is_active,
                    valid_from: code.validity_range.length > 0 ? code.validity_range[0] : null,
                    valid_till: code.validity_range.length > 0 ? code.validity_range[1] : null,
                    validity: code.validity,
                }
            }) : [],
            programs: program
        };

        const validationResult = validateOnlineDealParams(params);
        if (validationResult.status === false) {
            setActiveKey(validationResult.activeKey)
            errorToaster(validationResult.message);
            return
        }

        if (type === 3 && userLimit > activeCodeCount()) {
            errorToaster('User Limit Should Not be greater than active codes');
            setActiveKey('1')
            return
        }

        if ((type === 2 || type === 3) && !checkActiveCodeExist()) {
            errorToaster('There should be at least one active code');
            setActiveKey('4')
            return
        }

        let breakLoop = false;
        for (let i = 0; i < configList.length; i++) {
            if (type === 0) {
                delete configList[i].url;
            }
            const configurationValiationResult = validateConfigurationParams(configList[i], type);
            if (configurationValiationResult.status === false) {
                setActiveKey(configurationValiationResult.activeKey)
                errorToaster(configurationValiationResult.message);
                breakLoop = true
                break;
            }
        }
        if (breakLoop) {
            return
        }

        OnlineDealModel.getInstance().addOnlineDeal(params,
            function success(data) {
                if (!makeCodeBlock) {
                    successNotification('Product Online Deal Created Successfully');
                    setTimeout(() => {
                        setLoading(false);
                        props.history.goBack();
                    }, 2000);
                } else {
                    makeBlockAndUploadCodes(data.id);
                }

            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    function makeBlockAndUploadCodes(product_online_deal_id) {
        if (CODE_OFFSET >= codeConfigList.length) {
            successNotification('Product Online Deal Created Successfully');
            setTimeout(() => {
                setLoading(false);
                props.history.goBack();
            }, 2000);
        } else {
            const codeBlock = codeConfigList.slice(CODE_OFFSET, CODE_OFFSET + CODE_UPLOAD_LIMIT);
            const product_voucher_configs_payload = codeBlock.map(_c => ({
                product_online_deal_id,
                code: _c.code,
                max_usage: _c.max_usage,
                is_active: _c.is_active,
                valid_from: _c.validity_range.length > 0 ? _c.validity_range[0] : null,
                valid_till: _c.validity_range.length > 0 ? _c.validity_range[1] : null,
                validity: _c.validity,
            }));
            CODE_OFFSET = CODE_OFFSET + CODE_UPLOAD_LIMIT;
            saveCurrentCodeConfigObjectAPI(product_voucher_configs_payload, product_online_deal_id);
        };
    }

    function saveCurrentCodeConfigObjectAPI(payload, product_online_deal_id) {
        setLoading(true);
        OnlineDealModel.getInstance().addOnlineDealVoucherConfig(payload,
            function success(data) {
                makeBlockAndUploadCodes(product_online_deal_id)
            }, function failure(error) {
                errorToaster(JSON.stringify(error))
                setLoading(false);
            }
        )
    }

    function getProductOfferingPocsByMerchant(merchant_id) {
        setLoading(true);
        setMerchant(merchant_id)
        ProductOfferingModel.getInstance().getProductOfferingPocsByMerchant(merchant_id,
            function success() {
                setLoading(false);
            },
            function failure(err) {
                setLoading(false);
                errorNotification(JSON.stringify(err))
                console.error(err)
            }

        )
    }

    function addConfig() {
        let list = [...configList]
        list.push({ ...DataJson.addConfigObject, url: type === 3 ? configList[0]?.url : '' });
        setConfigList(list);
    }

    function removeConfig(index) {
        let new_list = [...configList]
        new_list.splice(index, 1)
        setConfigList(new_list);
    }

    function updateConfigValues(index, key, value) {
        let new_list = [...configList];
        new_list[index][key] = value;
        setConfigList(new_list);
    }

    function updateURLinAllConfig(value) {
        let new_list = configList.map(_rc => ({ ..._rc, url: value }))
        setConfigList(new_list);
    }

    const getSearchMerchant = useCallback(_.debounce(value => getMerchants(value), 500), []);
    async function getMerchants(value) {
        if (value.length > 0) {
            const params = {
                query: encodeURIComponent(value)
            };
            MerchantModel.getInstance().getMerchant(params,
                function success(data) {
                    setMerchantList(data.data);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)

                }
            )
        }
    }

    async function getMerchantById(merchant_id) {
        const params = {
            'merchants.id': merchant_id
        };
        MerchantModel.getInstance().getSearchMerchant(params,
            function success(data) {
                setMerchant(Number(merchant_id))
                setMerchantList(data.data);
                setLoading(false)
            },
            function failure(error) {
                errorNotification(error)
                setLoading(false)

            }
        )
    }


    async function getDefaultProductVisibility() {
        setLoading(true)
        SharedModel.getInstance().getDefaultProductVisibility(
            function success(data) {
                setProductVisibilityList([data])
                setProductVisibility(data.id)
                setLoading(false)
            },
            function failure(error) {
                errorNotification(error)
                setLoading(false)

            }
        )
    }



    const getSearchProducVisibility = useCallback(_.debounce(value => getAllProductVisibilities(value), 500), []);
    async function getAllProductVisibilities(value) {
        if (value.length > 0) {
            const params = {
                name: encodeURIComponent(value)
            };
            SharedModel.getInstance().getAllProductVisibilities(params,
                function success() {
                    setProductVisibilityList(SharedModel.ProductVisiblityList);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)

                }
            )
        }
    }


    const getSearchCategories = useCallback(_.debounce(value => getAllCategories(value), 500), []);
    async function getAllCategories(value) {
        if (value.length > 0) {
            const params = {
                'categories.name': encodeURIComponent(value)
            };
            UserModel.getInstance().searchCategoryByName(params,
                function success(data) {
                    setCategoryList(data.data);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)

                }
            )
        }
    }


    const getSearchInfoTags = useCallback(_.debounce(value => getAllInfoTags(value), 500), []);
    async function getAllInfoTags(value) {
        if (value.length > 0) {
            const params = {
                query: encodeURIComponent(value)
            };
            UserModel.getInstance().searchInfoTagByName(params,
                function success(data) {
                    setInfoTagList(data.data);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)

                }
            )
        }
    }

    const getSearchPrograms = useCallback(_.debounce(value => getAllPrograms(value), 500), []);
    async function getAllPrograms(value) {
        if (value.length > 0) {
            const params = {
                'programs.name': encodeURIComponent(value)
            };
            ProgramModel.getInstance().searchProgram(params,
                function success(data) {
                    setProgramList(data.data);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)

                }
            )
        }
    }



    // Code Config Logics

    function addNewCodeObject() {
        setCurrentEditableData({ ...DataJson.addCodeConfigObject })
    }

    function setCodeConfigValue(key, value) {
        let new_object = { ...currentEditableData };
        new_object[key] = value
        setCurrentEditableData(new_object);
    }

    async function saveCurrentCodeConfigObject() {
        const params = {
            ...currentEditableData,
            is_active: false
        };
        if (!validateVoucherCode(params.code)) {
            errorToaster('Please Enter Valid Code');
            return false;
        };
        if (params.validity_range.length < 1) {
            errorToaster('Please Choose Validity Range');
            return false;
        }
        let list = [...codeConfigList]
        list.push(params);
        setCodeConfigList(list);
        return true;
    }
    async function saveCurrentCodeConfigUploaderObject() {
        const codeArray = currentEditableData.code.split(',');
        if (currentEditableData.validity_range.length < 1) {
            errorToaster('Please Choose Validity Range');
            return false;
        }
        const payload = codeArray.map(_code => ({
            code: _code,
            max_usage: currentEditableData.max_usage,
            is_active: currentEditableData.is_active,
            validity: currentEditableData.validity,
            is_new: true,
            validity_range: currentEditableData.validity_range
        }));
        let list = [...codeConfigList, ...payload]
        setCodeConfigList(list);
        return true;
    }
    async function updateCurrentCodeConfigObject(index) {
        const params = {
            ...currentEditableData
        };
        if (!validateVoucherCode(params.code)) {
            errorToaster('Please Enter Valid Code');
            return false;
        }
        let list = [...codeConfigList]
        list[index] = params;
        setCodeConfigList(list);
        return true;
    }

    function updateCodeConfigActivation(index, is_active) {
        if (is_active && checkActiveCodeExistInTypeGeneralVouchers()) {
            errorToaster('Cannot activate more than one code in General Vouchers');
            return
        }
        if (codeConfigList[index].is_new) {
            let list = [...codeConfigList]
            list[index].is_active = is_active;
            setCodeConfigList(list);
        }
    }
    function deleteCodeConfig(index) {
        if (codeConfigList[index].is_new) {
            let list = [...codeConfigList]
            list.splice(index, 1)
            setCodeConfigList(list);
        }
    }
    function setCurrentCodeConfig(index) {
        if (codeConfigList[index].is_new) {
            const new_obj = { ...codeConfigList[index] }
            setCurrentEditableData(new_obj)
        }
    }

    function checkActiveCodeExistInTypeGeneralVouchers() {
        return type === 2 && codeConfigList.some(_x => _x.is_active === true);
    }

    function checkActiveCodeExist() {
        return codeConfigList.some(_x => _x.is_active === true);
    }
    function activeCodeCount() {
        return codeConfigList.filter(_x => _x.is_active === true).length;
    }


    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                <Spin spinning={loading} >

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                <div className="kt-portlet__head kt-portlet__head--lg">
                                    <DetailPageHeaderWithButton
                                        title={"Add Product Online Deal"}
                                        buttonOneIcon={"la la-arrow-left"}
                                        buttonOneCallBack={() => props.history.goBack()}
                                        buttonTwoIcon={"la la-check"}
                                        buttonTwoCallBack={() => addOnlineDeal()}
                                    />
                                </div>

                                <div className="kt-portlet__body">
                                    <form className="kt-form" id="kt_form">
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-8">
                                                <div className="kt-section kt-section--first">
                                                    <div className="kt-section__body">

                                                        <Tabs onChange={setActiveKey} activeKey={activeKey} defaultActiveKey="1" >
                                                            <TabPane tab="Overview" key="1">
                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Merchant <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            showSearch
                                                                            style={{ margin: '10px 0px 10px 0px' }}
                                                                            value={merchant}
                                                                            disabled={disableMerchant}
                                                                            notFoundContent={

                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                    <p style={{ fontSize: 14 }}>Please Search Merchant</p>
                                                                                </div>
                                                                            }
                                                                            onSearch={(val) => getSearchMerchant(val)}
                                                                            onChange={(merchant) => getProductOfferingPocsByMerchant(merchant)}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {merchantList.map((merchant) => (
                                                                                <Option key={merchant.id} value={merchant.id}>{`${merchant.name} ${merchant.is_active ? ' (active)' : ' (in-active)'}`}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted">Please Select Merchant</span>

                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Programs <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            mode="multiple"
                                                                            showSearch
                                                                            style={{ margin: '10px 0px 10px 0px' }}
                                                                            value={program}
                                                                            notFoundContent={

                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                    <p style={{ fontSize: 14 }}>Please Search Programs</p>
                                                                                </div>
                                                                            }
                                                                            onSearch={(val) => getSearchPrograms(val)}
                                                                            onChange={(program) => setProgram(program)}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {programList.map((program) => (
                                                                                <Option key={program.id} value={program.id}>{`${program.name} ${program.is_active ? ' (active)' : ' (in-active)'}`}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted">Please Select Programs</span>

                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Types <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            // showSearch
                                                                            style={{ margin: '10px 0px 10px 0px' }}
                                                                            value={type}
                                                                            onChange={(type) => {
                                                                                setType(type)
                                                                                setCodeConfigList([])
                                                                                setConfigList([])
                                                                            }}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {DataJson.typesArray.map((type) => (
                                                                                <Option key={type.value} value={type.value}>{type.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted">Please Select Type</span>

                                                                    </div>
                                                                </div>

                                                                {
                                                                    type === 3 &&
                                                                    <div className="form-group row">
                                                                        <label className="col-3 col-form-label">User Limit <b>*</b></label>
                                                                        <div className="col-9">
                                                                            <Input min={1} max={10} maxLength={10} value={userLimit} onChange={(event) => setUserLimit(event.target.value)} className="form-control" type="number" />
                                                                            <span className="form-text text-muted"><p>Please Enter Product Online Deal Name</p></span>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Name <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Input maxLength={100} value={name} onChange={(event) => setName(event.target.value)} className="form-control" type="text" />
                                                                        <span className="form-text text-muted"><p>Please Enter Product Online Deal Name</p></span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Arabic Name</label>
                                                                    <div className="col-9">
                                                                        <Input maxLength={100} value={arabicName} onChange={(event) => setArabicName(event.target.value)} className="form-control" type="text" />
                                                                        <span className="form-text text-muted"><p>Please Enter Arabic Product Online Deal Name</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Description <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Editor
                                                                            editorState={description}
                                                                            toolbarClassName="toolbarClassName"
                                                                            wrapperClassName="wrapperClassName"
                                                                            editorClassName="editorClassName form-control"
                                                                            editorStyle={{ minHeight: '80px' }}
                                                                            onEditorStateChange={(value) => setDescription(value)}
                                                                        />
                                                                        <span className="form-text text-muted"><p>Please Enter Product Online Deal description</p></span>
                                                                    </div>
                                                                </div>

                                                                <div style={configList.length > 0 ? { borderBottom: '1px dotted black' } : {}} className="form-group row">
                                                                    <label className="col-3 col-form-label">Arabic Description</label>
                                                                    <div className="col-9">
                                                                        <Editor
                                                                            editorState={arabicDescription}
                                                                            toolbarClassName="toolbarClassName"
                                                                            wrapperClassName="wrapperClassName"
                                                                            editorClassName="editorClassName form-control"
                                                                            editorStyle={{ minHeight: '80px' }}
                                                                            onEditorStateChange={(value) => setArabicDescription(value)}
                                                                        />
                                                                        <span className="form-text text-muted"><p>Please Enter Arabic Product Online Deal description</p></span>
                                                                    </div>
                                                                </div>
                                                            </TabPane>

                                                            {merchant &&
                                                                <TabPane tab="Merchant Contact" key="2">
                                                                    <ContactPocView
                                                                        is_outlet={false}
                                                                        id={merchant}
                                                                    />
                                                                </TabPane>
                                                            }

                                                            <TabPane tab="Configuration" key="3">
                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Visibility Setting <b>*</b></label>

                                                                    <div className="col-9">
                                                                        <Select
                                                                            showSearch
                                                                            style={{ margin: '10px 0px 10px 0px' }}
                                                                            value={productVisibility}
                                                                            notFoundContent={
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                    <p style={{ fontSize: 14 }}>Please search product visibility (Full text search)</p>
                                                                                </div>
                                                                            }
                                                                            onSearch={(val) => getSearchProducVisibility(val)}
                                                                            onChange={(merchant) => setProductVisibility(merchant)}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {productVisibilityList.map((visibility) => (
                                                                                <Option key={visibility.id} value={visibility.id}>{visibility.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted">Please Select Visibility Setting</span>

                                                                    </div>

                                                                </div>

                                                                {
                                                                    configList.map((config, i) => (
                                                                        <div key={i} className="form-group">

                                                                            <div className="form-group row">
                                                                                <label className="col-3 col-form-label">Configuration {i + 1} <b>*</b></label>
                                                                                {/* {
                                                                                    type === 2 &&
                                                                                    <div className="col-3">
                                                                                        <input placeholder="Code" maxLength={10} value={config.code} onChange={(event) => updateConfigValues(i, 'code', event.target.value)} className="form-control" type="text" />
                                                                                        <span className="form-text text-muted"><p>Please Enter Deal Code</p></span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    type === 2 &&
                                                                                    <div className="col-3">
                                                                                        <input placeholder="Max Usage" maxLength={5} value={config.max_usage} onChange={(event) => updateConfigValues(i, 'max_usage', event.target.value)} className="form-control" type="number" />
                                                                                        <span className="form-text text-muted"><p>Please Enter Deal Max Usage</p></span>
                                                                                    </div>
                                                                                } */}


                                                                                <div className="col-3 row">
                                                                                    <Popconfirm
                                                                                        title="Are you sure you want to delete this Configuration? This Action is ir-reversable!"
                                                                                        icon={<Icon type="delete" style={{ color: 'red' }} />}
                                                                                        onConfirm={() => removeConfig(i)}
                                                                                    >
                                                                                        <Icon type="delete" style={{ color: 'red', margin: '5px', fontSize: 20 }} />
                                                                                    </Popconfirm>

                                                                                    <Popconfirm
                                                                                        title={`Are you sure you want to ${config.is_active ? `DEACTIVATE` : `ACTIVATE`} this Configuration`}
                                                                                        icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                                                        onConfirm={() => updateConfigValues(i, 'is_active', !config.is_active)}
                                                                                    >
                                                                                        <Switch style={{ margin: '5px', backgroundColor: config.is_active ? '#5d78ff' : '#fd397a', marginLeft: 15 }} checked={config.is_active} />
                                                                                    </Popconfirm>

                                                                                </div>
                                                                            </div>
                                                                            {(type === 1 || type === 2 || type === 3) &&
                                                                                <div className="form-group row">
                                                                                    <label className="col-3 col-form-label">URL <b>*</b></label>
                                                                                    <div className="col-9">
                                                                                        <input disabled={type === 3 && i !== 0} placeholder="URL" maxLength={255} value={config.url} onChange={(event) => updateURLinAllConfig(event.target.value)} className="form-control" type="text" />
                                                                                        <span className="form-text text-muted"><p>Please Enter Deal URL</p></span>
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            {type === 0 &&
                                                                                <div className="form-group row">
                                                                                    <label className="col-3 col-form-label">Video URL <b>*</b></label>
                                                                                    <div className="col-9">
                                                                                        <input placeholder="URL" maxLength={255} value={config.video_url} onChange={(event) => updateConfigValues(i, 'video_url', event.target.value)} className="form-control" type="text" />
                                                                                        <span className="form-text text-muted"><p>Please Enter Video URL</p></span>
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            <div style={{ borderBottom: '1px dotted black' }} className="form-group row">
                                                                                <label className="col-3 col-form-label">Category <b>*</b></label>
                                                                                <div className="col-9">
                                                                                    <Select
                                                                                        showSearch
                                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                                        notFoundContent={
                                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                                <p style={{ fontSize: 14 }}>Please search any other category name</p>
                                                                                            </div>
                                                                                        }
                                                                                        onSearch={(val) => getSearchCategories(val)}
                                                                                        value={config.category_id}
                                                                                        onChange={(value) => updateConfigValues(i, 'category_id', value)}
                                                                                        filterOption={(input, option) =>
                                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                        }
                                                                                    >
                                                                                        {categoryList.map((_cat) => (
                                                                                            <Option key={_cat.id} value={_cat.id}>{_cat.name}</Option>
                                                                                        ))}
                                                                                    </Select>
                                                                                    <span className="form-text text-muted"><p>Please Choose category</p></span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    ))
                                                                }

                                                                {(type === 0 || type === 1 || type === 2) && configList.length > 0 ?
                                                                    null :
                                                                    <div className="form-group row">
                                                                        <div className="col-9">
                                                                            <div className="btn-group">
                                                                                <button onClick={() => addConfig()} type="button" className="btn btn-brand">
                                                                                    <i className="la la-plus"></i>
                                                                                    <span className="kt-hidden-mobile">Add Configuration</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Info Tags</label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            mode="multiple"
                                                                            showSearch
                                                                            style={{ margin: '10px 0px 10px 0px' }}
                                                                            notFoundContent={
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                    <p style={{ fontSize: 14 }}>Please search any other Info Tag name</p>
                                                                                </div>
                                                                            }
                                                                            onSearch={(val) => getSearchInfoTags(val)}
                                                                            value={infoTag}
                                                                            onChange={(value) => setInfoTag(value)}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {infoTagList.map((_tag) => (
                                                                                <Option key={_tag.id} value={_tag.id}>{_tag.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted"><p>Please Choose category</p></span>
                                                                    </div>
                                                                </div>
                                                            </TabPane>


                                                            {(type === 2 || type === 3) &&
                                                                <TabPane tab="Codes" key="4">
                                                                    <CodeTab
                                                                        is_editable={true}
                                                                        codeData={codeConfigList}
                                                                        currentData={currentEditableData}
                                                                        addNewCodeObject={() => addNewCodeObject()}
                                                                        setDataValues={setCodeConfigValue}
                                                                        saveObject={saveCurrentCodeConfigObject}
                                                                        saveUploaderObject={saveCurrentCodeConfigUploaderObject}
                                                                        updateObject={updateCurrentCodeConfigObject}
                                                                        cancelObject={() => setCurrentEditableData(undefined)}
                                                                        updateCodeConfigActivation={updateCodeConfigActivation}
                                                                        deleteCodeConfig={deleteCodeConfig}
                                                                        setCurrentCodeConfig={setCurrentCodeConfig}
                                                                        type={type}
                                                                        getVouchers={(filters) => console.info(filters)}
                                                                    />
                                                                </TabPane>
                                                            }
                                                        </Tabs>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div >

    )
}