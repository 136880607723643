import React, { useState, useCallback, useEffect } from "react";

import { Input, Spin, Select, Icon, Tabs, Button, notification, Switch } from "antd";

import _ from "lodash";

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";

import { DetailPageHeaderWithButton } from "styles/detailPageHeader";

import SharedModel from "Models/SharedModel";
import MerchantModel from "Models/MerchantModel";
import ProductBookingModel from "Models/ProductBookingModel";
import { validateProductBookingParams } from "./helperFunctions";
import { getParameterByName } from "Helpers/Shared/Functions";
import UserModel from "Models/UserModel";

const { Option } = Select;
const { TabPane } = Tabs;

export default function AddProductProductBookings({ history }) {
  const [loading, setLoading] = useState(false),
    [activeKey, setActiveKey] = useState("1"),
    [name, setName] = useState(""),
    [arabicName, setArabicName] = useState(""),
    [description, setDescription] = useState(""),
    [arabicDescription, setArabicDescription] = useState(""),
    [minimumBookingTime, setMinimumBookingTime] = useState(null),
    [merchantList, setMerchantList] = useState([]),
    [merchant, setMerchant] = useState(undefined),
    [outletList, setOutletList] = useState([]),
    [outlet, setOutlet] = useState([]),
    [allOutletSelected, setAllOutletSelected] = useState(false),
    [isOutletDisable, setIsOutletDisable] = useState(undefined),
    [categoryId, setCategoryId] = useState(undefined),
    [categoryList, setCategoryList] = useState([]),
    [productVisibilityList, setProductVisibilityList] = useState([]),
    [productVisibility, setProductVisibility] = useState(undefined);

  useEffect(() => {
    getDefaultProductVisibility();
    const outlet_id = getParameterByName("outlet_id");
    if (outlet_id) {
      getOutletDetail(outlet_id);
    }
  }, []);

  function addProductBooking() {
    const params = {
      name,
      arabic_name: arabicName,
      description: description,
      arabic_description: arabicDescription,
      minimum_booking_time: minimumBookingTime,
      outlet_id: outlet,
      product_visibility_id: productVisibility ? productVisibility : null,
      product_booking_configs: categoryId
        ? [
          {
            section_title: "English Default",
            section_arabic_title: "افتراضي عربي",
            description: "English Description",
            arabic_description: "الوصف العربي",
            category_id: categoryId,
            section_order: 0,
            is_default: true,
            product_visibility_id: null,
          },
        ]
        : [],
      product_booking_pricings: [],
    };

    const validationResult = validateProductBookingParams(params);
    if (validationResult.status === false) {
      setActiveKey(validationResult.activeKey);
      errorToaster(validationResult.message);
      return;
    }
    if (params.product_booking_configs.length < 1) {
      errorToaster("Please Select Category");
      return;
    }
    setLoading(true);
    ProductBookingModel.getInstance().addProductBooking(
      params,
      function success(data) {
        successNotification("Product Booking Created Successfully");
        openNotification(data.id);
      },
      function failure(err) {
        errorNotification(JSON.stringify(err));
        setLoading(false);
      }
    );
  }

  const openNotification = (id) => {
    const key = `open${Date.now()}`;
    const btn = (
      <div>
        <Button
          type="primary"
          onClick={() => (window.location.href = `/product-booking/edit/${id}`)}
        >
          Yes
        </Button>
        <Button
          style={{ margin: "10px" }}
          type="primary"
          onClick={() => history.goBack()}
        >
          Later
        </Button>
      </div>
    );

    notification.open({
      message: "Add Section/Addons",
      description:
        "Do you want to Add Configuration or Addons for this Online Booking",
      btn,
      duration: 0,
      key,
    });
  };

  function getOutletDetail(outlet_id) {
    setLoading(true);
    MerchantModel.getInstance().getOutletDetail(
      outlet_id,
      function success(data) {
        setIsOutletDisable(true);
        setOutlet(data.id);
        setOutletList(data ? [data] : []);
        setMerchant(data.merchant_id);
        setMerchantList(
          data.merchant_id
            ? [
              {
                id: data.merchant_id,
                name: data.merchant_name,
                is_active: true,
              },
            ]
            : []
        );
        setLoading(false);
      },
      function failure(err) {
        setLoading(false);
        errorNotification(JSON.stringify(err));
        console.error(err);
      }
    );
  }

  async function getDefaultProductVisibility() {
    setLoading(true)
    SharedModel.getInstance().getDefaultProductVisibility(
      function success(data) {
        setProductVisibilityList([data])
        setProductVisibility(data.id)
        setLoading(false)
      },
      function failure(error) {
        errorNotification(error)
        setLoading(false)

      }
    )
  }

  const getSearchMerchant = useCallback(
    _.debounce((value) => getMerchants(value), 500),
    []
  );
  async function getMerchants(value) {
    setLoading(true);
    if (value.length > 0) {
      let params = {
        query: encodeURIComponent(value),
      };
      MerchantModel.getInstance().getMerchant(
        params,
        function success(data) {
          setLoading(false);
          setMerchantList(data.data);
        },
        function failure(error) {
          setLoading(false);
          errorNotification(error);
        }
      );
    }
  }

  const getSearchProducVisibility = useCallback(
    _.debounce((value) => getAllProductVisibilities(value), 500),
    []
  );
  async function getAllProductVisibilities(value) {
    if (value.length > 0) {
      const params = {
        name: encodeURIComponent(value),
      };
      SharedModel.getInstance().getAllProductVisibilities(
        params,
        function success() {
          setProductVisibilityList(SharedModel.ProductVisiblityList);
          setLoading(false);
        },
        function failure(error) {
          errorNotification(error);
          setLoading(false);
        }
      );
    }
  }

  const getSearchCategories = useCallback(
    _.debounce((value) => getAllCategories(value), 500),
    []
  );
  async function getAllCategories(value) {
    if (value.length > 0) {
      const payload = {
        "categories.name": encodeURIComponent(value),
      };
      UserModel.getInstance().searchCategoryByName(
        payload,
        function success(data) {
          setCategoryList(data.data);
          setLoading(false);
        },
        function failure(error) {
          errorNotification(error);
          setLoading(false);
        }
      );
    }
  }

  async function getMerchantOutlets(merchant_id) {
    setLoading(true);
    setOutletList([]);
    MerchantModel.getInstance().getMerchantOutlet(
      merchant_id,
      function success(data) {
        setLoading(false);
        setOutletList(data);
        if (allOutletSelected) setOutlet(data.map(_out => (_out.id)))
      },
      function failure(error) {
        setLoading(false);
        errorNotification(error);
        console.log(error);
      }
    );
  }

  async function selectAllOutlets(check) {
    setAllOutletSelected(check);
    if (check) setOutlet(outletList.map(_out => (_out.id)))
  }

  return (
    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={loading}>
          <div className="row">
            <div className="col-lg-12">
              <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                <div className="kt-portlet__head kt-portlet__head--lg">
                  <DetailPageHeaderWithButton
                    title={"Add Product Booking"}
                    buttonOneIcon={"la la-arrow-left"}
                    buttonOneCallBack={() => {
                      history.goBack();
                    }}
                    buttonTwoIcon={"la la-check"}
                    buttonTwoCallBack={() => addProductBooking()}
                  />
                </div>

                <div className="kt-portlet__body">
                  <form className="kt-form" id="kt_form">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="kt-section kt-section--first">
                          <div className="kt-section__body">
                            <Tabs
                              onChange={setActiveKey}
                              activeKey={activeKey}
                              defaultActiveKey="1"
                            >
                              <TabPane tab="Overview" key="1">
                                <div className="form-group row">
                                  <label className="col-2 col-form-label">
                                    Merchant <b>*</b>
                                  </label>
                                  <div className="col-10">
                                    <Select
                                      showSearch
                                      style={{ margin: "10px 0px 10px 0px" }}
                                      disabled={isOutletDisable}
                                      value={merchant}
                                      notFoundContent={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Icon
                                            style={{
                                              fontSize: 16,
                                              marginRight: "10px",
                                              marginBottom: "5px",
                                            }}
                                            type="search"
                                          />
                                          <p style={{ fontSize: 14 }}>
                                            Please Search Merchant
                                          </p>
                                        </div>
                                      }
                                      onSearch={(val) => getSearchMerchant(val)}
                                      onChange={(_merchant) => {
                                        setMerchant(_merchant);
                                        getMerchantOutlets(_merchant);
                                      }}
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {merchantList.map((_merchant) => (
                                        <Option
                                          key={_merchant.id}
                                          value={_merchant.id}
                                        >{`${_merchant.name} ${_merchant.is_active
                                          ? " (active)"
                                          : " (in-active)"
                                          }`}</Option>
                                      ))}
                                    </Select>
                                    <span className="form-text text-muted">
                                      Please Select Merchant
                                    </span>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-2 col-form-label">
                                    Select All Outlets
                                  </label>
                                  <div className="col-10">
                                    <Switch checked={allOutletSelected} checkedChildren="Yes" unCheckedChildren="No" onChange={(checked) => selectAllOutlets(checked)} />
                                    <span className="form-text text-muted">
                                      Want to select All outlets from Merchant?
                                    </span>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-2 col-form-label">
                                    Outlet <b>*</b>
                                  </label>
                                  <div className="col-10">
                                    <Select
                                      showSearch
                                      mode="multiple"
                                      disabled={allOutletSelected}
                                      style={{ margin: "10px 0px 10px 0px" }}
                                      value={outlet}
                                      notFoundContent={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Icon
                                            style={{
                                              fontSize: 16,
                                              marginRight: "10px",
                                              marginBottom: "5px",
                                            }}
                                            type="search"
                                          />
                                          <p style={{ fontSize: 14 }}>
                                            Please Search Outlet
                                          </p>
                                        </div>
                                      }
                                      onChange={(outlet) => setOutlet(outlet)}
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {outletList.map((outlet) => (
                                        <Option
                                          key={outlet.id}
                                          value={outlet.id}
                                        >{`${outlet.name} ${outlet.is_active
                                          ? " (active)"
                                          : " (in-active)"
                                          }`}</Option>
                                      ))}
                                    </Select>
                                    <span className="form-text text-muted">
                                      Please Select Outlet
                                    </span>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-2 col-form-label">
                                    Name <b>*</b>
                                  </label>
                                  <div className="col-10">
                                    <Input
                                      maxLength={100}
                                      value={name}
                                      onChange={(event) =>
                                        setName(event.target.value)
                                      }
                                      className="form-control"
                                      type="text"
                                    />
                                    <span className="form-text text-muted">
                                      <p>Please Enter Product Booking Name</p>
                                    </span>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-2 col-form-label">
                                    Arabic Name
                                  </label>
                                  <div className="col-10">
                                    <Input
                                      maxLength={100}
                                      value={arabicName}
                                      onChange={(event) =>
                                        setArabicName(event.target.value)
                                      }
                                      className="form-control"
                                      type="text"
                                    />
                                    <span className="form-text text-muted">
                                      <p>
                                        Please Enter Arabic Product Booking Name
                                      </p>
                                    </span>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-2 col-form-label">
                                    Description <b>*</b>
                                  </label>
                                  <div className="col-10">
                                    <textarea
                                      value={description}
                                      maxLength="500"
                                      onChange={(event) =>
                                        setDescription(event.target.value)
                                      }
                                      className="form-control"
                                      rows="3"
                                    />
                                    <span className="form-text text-muted">
                                      <p>
                                        Please Enter Product Booking description
                                      </p>
                                    </span>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-2 col-form-label">
                                    Arabic Description
                                  </label>
                                  <div className="col-10">
                                    <textarea
                                      value={arabicDescription}
                                      maxLength="500"
                                      onChange={(event) =>
                                        setArabicDescription(event.target.value)
                                      }
                                      className="form-control"
                                      rows="3"
                                    />
                                    <span className="form-text text-muted">
                                      <p>
                                        Please Enter Arabic Product Booking
                                        description
                                      </p>
                                    </span>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-2 col-form-label">
                                    Visibility Setting
                                  </label>
                                  <div className="col-10">
                                    <Select
                                      allowClear
                                      showSearch
                                      style={{ margin: "10px 0px 10px 0px" }}
                                      value={productVisibility}
                                      notFoundContent={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Icon
                                            style={{
                                              fontSize: 16,
                                              marginRight: "10px",
                                              marginBottom: "5px",
                                            }}
                                            type="search"
                                          />
                                          <p style={{ fontSize: 14 }}>
                                            Please search product visibility
                                            (Full text search)
                                          </p>
                                        </div>
                                      }
                                      onSearch={(val) =>
                                        getSearchProducVisibility(val)
                                      }
                                      onChange={(outlet) =>
                                        setProductVisibility(outlet)
                                      }
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {productVisibilityList.map(
                                        (visibility) => (
                                          <Option
                                            key={visibility.id}
                                            value={visibility.id}
                                          >
                                            {visibility.name}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                    <span className="form-text text-muted">
                                      Please Select Visibility Setting
                                    </span>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-2 col-form-label">
                                    Minimum Booking Time
                                  </label>
                                  <div className="col-10">
                                    <Input
                                      maxLength={4}
                                      value={minimumBookingTime}
                                      onChange={(event) =>
                                        setMinimumBookingTime(event.target.value)
                                      }
                                      className="form-control"
                                      type="number"
                                    />
                                    <span className="form-text text-muted">
                                      <p>
                                        Please Enter Minimum Booking Time
                                      </p>
                                    </span>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-2 col-form-label">
                                    Category
                                  </label>
                                  <div className="col-10">
                                    <Select
                                      allowClear
                                      showSearch
                                      style={{
                                        margin: "10px 0px 10px 0px",
                                        width: "100%",
                                      }}
                                      value={categoryId}
                                      notFoundContent={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Icon
                                            style={{
                                              fontSize: 16,
                                              marginRight: "10px",
                                              marginBottom: "5px",
                                            }}
                                            type="search"
                                          />
                                          <p style={{ fontSize: 14 }}>
                                            Please search product Category
                                          </p>
                                        </div>
                                      }
                                      onSearch={(val) =>
                                        getSearchCategories(val)
                                      }
                                      onChange={(cat) => setCategoryId(cat)}
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {categoryList.map((category) => (
                                        <Option
                                          key={category.id}
                                          value={category.id}
                                        >
                                          {category.name}
                                        </Option>
                                      ))}
                                    </Select>
                                    <span className="form-text text-muted">
                                      Please Select Category
                                    </span>
                                  </div>
                                </div>
                              </TabPane>
                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
}
