import React from "react";
import { getCompressedImage } from "Helpers/Shared/utils";
import { Spin } from "antd";
import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";

import UserModel from "../../Models/UserModel";

import backImage from "../../../src/assets/media/users/default.jpg";
import SharedModel from "../../Models/SharedModel";

export default class AddMerchantInfoTag extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      iconImage: null,
      iconImageError: false,
      iconImageSrc: null,
      name: "",
      nameError: false,
      arb_name: "",
      arb_nameError: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  saveLogo(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ loading: true });
    getCompressedImage(file)
      .then((compressedImage) => {
        if (compressedImage) {
          this.setState({
            loading: false,
            iconImage: compressedImage,
            iconImageSrc: URL.createObjectURL(compressedImage),
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        errorNotification(e);
      });
  }

  sendData() {
    const { name, arb_name, iconImage } = this.state;
    let it = this;
    const text = "Validation Error! Please check fields";
    this.setState({
      iconImageError: false,
      nameError: false,
      arb_nameError: false,
    });

    if (!iconImage) {
      this.setState({ iconImageError: true });
      errorToaster(text);
      return;
    }

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster(text);
      return;
    }

    UserModel.getInstance().addMerchantInfoTag(
      name,
      arb_name,
      function success(data) {
        console.log(data);
        // return
        it.addMedia(iconImage, data.info_tag.id);
      },
      function failure(error) {
        errorNotification(error);
        console.log("error", error);
      }
    );
  }

  addMedia(iconImage, id) {
    let it = this;
    let params = new FormData();
    params.append("logo", iconImage);

    SharedModel.getInstance().addMediaInstance(
      id,
      "info-tag",
      params,
      function success() {
        it.setState({ loading: false });
        successNotification("Merchant Info Tag Created Successfully");
        setTimeout(() => {
          window.location.href = "/merchant-info-tag";
        }, 1000);
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  render() {
    const { iconImageSrc, nameError, arb_nameError, iconImageError, loading } =
      this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <Spin spinning={loading}>
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">
                        Add Merchant Info Tag
                      </h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/merchant-info-tag"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                  Upload Logo <b>*</b>
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <div
                                    className="kt-avatar kt-avatar--outline kt-avatar--circle"
                                    id="kt_apps_user_add_avatar"
                                  >
                                    <div
                                      className="kt-avatar__holder"
                                      style={{
                                        backgroundImage: `url(${
                                          iconImageSrc
                                            ? iconImageSrc
                                            : backImage
                                        })`,
                                      }}
                                    ></div>

                                    <label
                                      className="kt-avatar__upload"
                                      data-toggle="kt-tooltip"
                                      title=""
                                      data-original-title="Change avatar"
                                    >
                                      <i className="fa fa-pen"></i>
                                      <input
                                        onChange={(event) =>
                                          this.saveLogo(event)
                                        }
                                        type="file"
                                        name="kt_apps_user_add_user_avatar"
                                      />
                                    </label>
                                    <span
                                      className="kt-avatar__cancel"
                                      data-toggle="kt-tooltip"
                                      title=""
                                      data-original-title="Cancel avatar"
                                    >
                                      <i className="fa fa-times"></i>
                                    </span>
                                    <p
                                      style={{
                                        color: "red",
                                        display: iconImageError
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      Please Upload Icon Image
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="70"
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter name in English
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Arabic Name
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="100"
                                    onChange={(event) =>
                                      this.setState({
                                        arb_name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: arb_nameError ? "red" : "",
                                      }}
                                    >
                                      Please enter name in Arabic
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
