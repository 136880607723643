import React, { useState } from "react";

import {
    TimePicker,
    Input,
    Spin,
    Select,
    Icon,
} from "antd";
import { DetailPageHeaderWithButton } from "styles/detailPageHeader";

import {
    successNotification,
    errorNotification,
    errorToaster
} from "../Shared/Notifications";

import moment from "moment";

import ProductOrderTimeSlotModel from "Models/ProductOrderTimeSlotModel";

import DataJson from "./data.json"

const { Option } = Select;


export default function AddOrderTimeSlot(props) {
    const
        [loading, setLoading] = useState(false),
        [name, setName] = useState(""),
        [configList, setConfigList] = useState([]);

    const addOrderTimeSlot = () => {
        if (name.length < 1) {
            errorToaster("Please Enter Order Time Slot Name")
            return
        };
        let breakLoop = false;
        configList.forEach(conf => {
            if (!conf.start_time || !conf.end_time) {
                breakLoop = true
            }
        });
        if (breakLoop) {
            errorToaster("Please enter correct config")
            return
        };
        const params = {
            name,
            order_time_slot_configs: configList
        };
        ProductOrderTimeSlotModel.getInstance().addOrderTimeSlot(params,
            function success(data) {
                successNotification("Order Time Slot Created Successfully");
                setTimeout(() => {
                    setLoading(false);
                    window.location.href = "/order-time-slot"
                }, 2000);
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    function addConfig() {
        let list = [...configList]
        list.push({ ...DataJson.addConfigObject });
        setConfigList(list);
    }

    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                <Spin spinning={loading} >

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                <div className="kt-portlet__head kt-portlet__head--lg">
                                    <DetailPageHeaderWithButton
                                        title={"Add Order Time Slot"}
                                        buttonOneIcon={"la la-arrow-left"}
                                        buttonOneCallBack={() => {
                                            props.history.goBack();
                                        }}
                                        buttonTwoIcon={"la la-check"}
                                        buttonTwoCallBack={() => addOrderTimeSlot()}
                                    />
                                </div>

                                <div className="kt-portlet__body">
                                    <form className="kt-form" id="kt_form">
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-8">
                                                <div className="kt-section kt-section--first">
                                                    <div className="kt-section__body">

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Name <b>*</b></label>
                                                            <div className="col-9">
                                                                <Input maxLength={100} value={name} onChange={(event) => setName(event.target.value)} className="form-control" type="text" />
                                                                <span className="form-text text-muted"><p style={{}}>Please Enter Order Time Slot Name</p></span>
                                                            </div>
                                                        </div>

                                                        {
                                                            configList.map((config, i) => (
                                                                <div key={i} className="form-group row">
                                                                    <label className="col-3 col-form-label">Config # {i + 1} </label>
                                                                    <div className="col-3">
                                                                        <TimePicker
                                                                            id="1"
                                                                            minuteStep={1}
                                                                            className="form-control"
                                                                            format="HH:mm"
                                                                            value={moment(config.start_time, "HH:mm")}
                                                                            style={{ padding: "0px", border: "0px solid black" }}
                                                                            allowClear={false}
                                                                            onChange={(value) => {
                                                                                let val = [...configList];
                                                                                val[i].start_time = moment(value).format("HH:mm");
                                                                                setConfigList(val);
                                                                            }} />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Select start time</p></span>
                                                                    </div>

                                                                    <div className="col-3">
                                                                        <TimePicker
                                                                            id="2"
                                                                            minuteStep={1}
                                                                            className="form-control"
                                                                            format="HH:mm"
                                                                            value={moment(config.end_time, "HH:mm")}
                                                                            style={{ padding: "0px", border: "0px solid black" }}
                                                                            allowClear={false}
                                                                            onChange={(value) => {
                                                                                let val = [...configList];
                                                                                val[i].end_time = moment(value).format("HH:mm");
                                                                                setConfigList(val);
                                                                            }} />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Select end time</p></span>
                                                                    </div>

                                                                    <div className="col-2">
                                                                        <Select
                                                                            showSearch
                                                                            value={config.day}
                                                                            onChange={(day) => {
                                                                                let val = [...configList];
                                                                                val[i].day = day;
                                                                                setConfigList(val);
                                                                            }}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {DataJson.dayArray.map((day) => (
                                                                                <Option key={day.value} value={day.value} >{day.name}</Option>
                                                                            ))}
                                                                        </Select>

                                                                        <span className="form-text text-muted"><p style={{}}>Please Select day</p></span>
                                                                    </div>
                                                                    <Icon onClick={() => {
                                                                        let val = [...configList];
                                                                        val.splice(i, 1)
                                                                        setConfigList(val);
                                                                    }} style={{ fontSize: 20, paddingTop: 5, paddingLeft: 5 }} type="delete" />

                                                                </div>
                                                            ))
                                                        }
                                                        <div className="form-group row">
                                                            <div className="col-9">
                                                                <div className="btn-group">
                                                                    <button onClick={() => addConfig()} type="button" className="btn btn-brand">
                                                                        <i className="la la-plus"></i>
                                                                        <span className="kt-hidden-mobile">Add Config</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div >

    )
}