

export function validateProductPriceParams(params, is_edit = true) {
    if (!params.name || params.name.trim().length < 1) {
        return {
            status: false,
            message: "Please enter Name"
        }
    }
    if (!params.description || params.description.trim().length < 1) {
        return {
            status: false,
            message: "Please enter Section Description"
        }
    }
    if (is_edit && !params.app_id) {
        return {
            status: false,
            message: "Please choose an App"
        }
    }
    if (!is_edit && params.app_id.length < 1) {
        return {
            status: false,
            message: "Please choose atleast one App"
        }
    }
    if (params.list_price.length < 1) return {
        status: false,
        message: "Please Enter List Price"
    }
    if (params.list_price.length < 1) return {
        status: false,
        message: "Please Enter Discounted Price"
    }
    if (Number(params.list_price) < Number(params.discounted_price)) return {
        status: false,
        message: "Discounted Price should be Less than List Price"
    };
    return {
        status: true,
    }
}