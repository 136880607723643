import React from 'react';
import { Modal, Icon, Button } from 'antd';
import Geocode from 'react-geocode'
import Map from '../Shared/Map';

export default class DialogModal extends React.Component {

    // getReverseGeo() {
    //     this.setState({
    //         loading: true
    //     })
    //     Geocode.setApiKey("AIzaSyDMKXgTik_BboalQAMEVvHdUBoPqfQ6FTc");
    //     Geocode.setLanguage("en");
    //     Geocode.fromLatLng(this.state.tempLat, this.state.tempLng).then(
    //         response => {
    //             this.setState({
    //                 lat: this.state.tempLat,
    //                 lng: this.state.tempLng,
    //                 address: response.results[0].formatted_address,
    //                 loading: false,
    //                 addressString: response.results[0].formatted_address
    //             })
    //         },
    //         error => {
    //             this.setState({ loading: false })
    //             errorToaster("Location Not Find! Please Enter Valid Coordinates")
    //             console.log(error);
    //         }
    //     )
    //         .catch((err) => {
    //             console.log(err)
    //             errorToaster("Something Went wrong")
    //         })

    // }

    renderPaymentMethod(payment_method) {
        switch (payment_method) {
            case 1:
                return "Credit Card"

            case 2:
                return "Debit Card"

            default:
                return "Cash on delivery"
        }
    }


    render() {
        const { orderDetail } = this.props
        return (
            <div>
                <Modal
                    width={window.innerWidth / 100 * 85}
                    title="Order details"
                    visible={this.props.visible}
                    onOk={this.props.onOk}
                    onCancel={this.props.onCancel}

                >
                    {/* 
                    <div className="kt-portlet__body">
                        <form className="kt-form" id="kt_form">
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8">

                                    <div className="kt-section kt-section--first"> */}

                    {orderDetail ?

                        <div className="kt-section__body">

                            <h5>Order Number: <b>{orderDetail.order_number}</b></h5><br></br>
                            <h5>Status: <b>{orderDetail.status}</b></h5><br></br>
                            <h5>Merchant: <b>{orderDetail.merchant}</b></h5><br></br>
                            <h5>Outlet: <b>{orderDetail.outlet}</b></h5><br></br>
                            <h5>Redemptions:<b /></h5>
                            <ol>
                                {orderDetail.offers.map((offer, i) => (
                                    <li key={i}>
                                        {offer.ecommerce_offer_name}
                                    </li>
                                ))}
                            </ol>

                            <h2>
                                Customer Details
                            </h2>
                            <ul>
                                <li>
                                    <h5>Name: {orderDetail.customer_name}</h5>
                                </li>
                                <li>
                                    <h5>Mobile: {orderDetail.mobile}</h5>
                                </li>
                                <li>
                                    <h5>Email: {orderDetail.email}</h5>
                                </li>
                                <li>
                                    <h5>Address: {orderDetail.delivery_address.complete_address}</h5>
                                </li>
                                <li>
                                    <h5>Latitude: {orderDetail.delivery_address.lat}</h5>
                                </li>
                                <li>
                                    <h5>Longitude: {orderDetail.delivery_address.lng}</h5>
                                </li>
                            </ul>

                            <Map
                                containerElement={<div style={{ height: '300px', width: '100%' }} />}
                                mapElement={<div style={{ height: '100%' }} />}
                                lat={orderDetail.delivery_address.lat}
                                lng={orderDetail.delivery_address.lng}
                                isMarkerShown={true}
                            />

                            <h2>
                                Order Details
                            </h2>
                            <ul>
                                <li>
                                    <h5>Order Created Time: {orderDetail.status_time}</h5>
                                </li>
                                <li>
                                    <h5>Order Status:</h5>
                                    <ol>
                                        {orderDetail.statuses.map((status, i) => (
                                            <li key={i}><h6><b>{status.name}: </b>{status.created_at}</h6></li>
                                        ))}
                                    </ol>
                                </li>

                                <li>
                                    <h5>Payment Method: <b>{this.renderPaymentMethod(orderDetail.payment_method)}</b></h5><br></br>
                                </li>

                                <li>
                                    <h5>Preparation Time: <b>{orderDetail.preparation_time ? `${orderDetail.preparation_time} min` : null}</b></h5><br></br>
                                </li>

                                <li>
                                    <h5>Total Amount: <b>{orderDetail.total_amount}</b></h5><br></br>
                                </li>

                                <li>
                                    <h5>Special Instruction: <b>{orderDetail.instructions}</b></h5><br></br>
                                </li>

                                <li>
                                    <h5>Delivery Instruction: <b>{orderDetail.delivery_address.instruction}</b></h5><br></br>
                                </li>
                            </ul>

                            <h2>
                                Order Items
                            </h2>
                            <ul>
                                {orderDetail.order_cart.items.map((item, i) => (
                                    <li key={i}>
                                        <h5><b>Item: </b>{item.name}</h5>
                                        <p><b>Quantity: </b>{item.count}</p>
                                        <h6>Addons: </h6>
                                        <ul>
                                            {item.addons.map((addon, i) => (
                                                <li key={i}>
                                                    {addon.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>


                        </div>
                        : null
                    }
                    {/* </div>
                                </div>

                            </div>
                        </form>
                    </div> */}



                </Modal>
            </div>
        );
    }
}


