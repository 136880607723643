import React from 'react';
import SideMenu from '../../Shared/SideMenu';
import Header from '../../Shared/Header';
import Footer from '../../Shared/Footer';


export default class ChangePassword extends React.Component {
	render() {
		return (
			<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
				<div className="kt-content  kt-grid__item kt-grid__item--fluid" >

					<div className="row">
						<div className="col-lg-12">
							<div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
								<div className="kt-portlet__head kt-portlet__head--lg">
									<div className="kt-portlet__head-label">
										<h3 className="kt-portlet__head-title">Change Password</h3>
									</div>
									<div className="kt-portlet__head-toolbar">
										<a href="/" className="btn btn-clean kt-margin-r-10">
											<i className="la la-arrow-left"></i>
											<span className="kt-hidden-mobile">Back</span>
										</a>
										<div className="btn-group">
											<button type="button" className="btn btn-brand">
												<i className="la la-check"></i>
												<span className="kt-hidden-mobile">Save</span>
											</button>

										</div>
									</div>
								</div>
								{/* end header */}
								<div className="kt-portlet__body">
									<form className="kt-form" id="kt_form">
										<div className="row">
											<div className="col-xl-2"></div>
											<div className="col-xl-8">
												<div className="kt-section kt-section--first">

													<div className="kt-section__body">
														<div className="form-group row">
															<label className="col-3 col-form-label">Old Password</label>
															<div className="col-9">
																<input className="form-control" type="password" />
																<span className="form-text text-muted">Please enter your old password</span>

															</div>
														</div>
														<div className="form-group row">
															<label className="col-3 col-form-label">New Password</label>
															<div className="col-9">
																<input className="form-control" type="password" />
																<span className="form-text text-muted">Please enter your new password</span>

															</div>
														</div>

														<div className="form-group row">
															<label className="col-3 col-form-label">Confirm Password</label>
															<div className="col-9">
																<input className="form-control" type="password" />
																<span className="form-text text-muted">Please enter password again</span>

															</div>
														</div>

													</div>
												</div>
											</div>
											<div className="col-xl-2"></div>

										</div>
									</form>
								</div>
								{/* end body */}
							</div>
						</div>
					</div>

				</div>
			</div>

		);
	}
}