import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';


const Map = withGoogleMap(props => (
     
    <GoogleMap
        defaultCenter = {{lat: 38.213445, lng: -77.35787}}
        center={{lat: props.lat, lng:props.lng}}
        defaultZoom = { 15 }
        onClick={ props.clickable ? (event) => props.setMarker(event.latLng) : false}
    >
        {props.isMarkerShown && <Marker position={{ lat: props.lat, lng: props.lng }} />}
    </GoogleMap>
))

export default Map