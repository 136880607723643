import React from 'react';
import {
    Select,
    Spin,
    Checkbox,
    DatePicker,
    Icon
} from 'antd';

import moment from 'moment'
import _ from 'lodash';

import CouponModel from '../../Models/CouponModel';

import {
    successNotification,
    errorNotification,
    errorToaster
} from '../Shared/Notifications';
import FileUploader from '../Shared/FileUploader';
import PartnerModel from '../../Models/PartnerModel';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class AddCoupon extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: false,
            name: '',
            nameError: false,
            description: '',
            descriptionError: false,
            programList: [],
            program: null,
            programError: false,
            type: null,
            typeError: false,
            discountedValue: '',
            discountedValueError: false,
            number_of_coupons: '1',
            number_of_couponsError: false,
            prefix: '',
            prefixError: false,
            codes: [],
            codesError: false,
            redemptionLimit: '',
            redemptionLimitError: false,
            unlimited: false,
            startDate: '',
            endDate: '',
            dateError: false,
            disableOption: false,
            typeList: [
                {
                    name: 'Coupon',
                    value: 1,
                },
                {
                    name: 'VIP',
                    value: 2,
                },
                {
                    name: 'BIN',
                    value: 3,
                },
                {
                    name: 'BIN + Coupon',
                    value: 4,
                }
            ],

            fileName: null,
            fileCode: [],
            partnerList: [],
            partner: null,
            partnerError: false,
            bin: [],
            binError: false,
            binList: []


        }
    }

    componentWillMount() {
        const role_id = localStorage.getItem("user_role")
        switch (Number(role_id)) {
            case 1:
                break;
            case 5:
                window.location.href = '/order-report'
                break;
            default:
                window.location.href = '/login'
                break;
        }
    }


    searchPartners(name) {
        let it = this;
        it.setState({
            loading: true,
        })
        const params = {
            'partners.name': name
        }
        PartnerModel.getInstance().searchPartner(params,
            function success() {
                it.setState({
                    loading: false,
                    partnerList: PartnerModel.getInstance().PartnerList
                })
            },
            function failure(error) {
                it.setState({ loading: false })
                errorNotification(error)
            }
        )
    }

    debounce(callback, wait) {
        let timeout;
        return (...args) => {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => callback.apply(context, args), wait);
        };
    }

    searchQueryPartner = this.debounce(function (value, it) {
        it.searchPartners(value);
    }, 1000);

    searchBins(query) {
        const params = {
            bin: query,
            is_active: true,
            offset: 0,
            limit: 10
        },
            it = this;
        CouponModel.getInstance().getBankBins(params,
            function success() {
                it.setState({ binList: CouponModel.BankBinList })
            }, function failure(error) {
                errorToaster('Bin Not found')
            },
        )
    }

    searchQueryBIN = this.debounce(function (value, it) {
        it.searchBins(value);
    }, 1000);

    getPartnerPrograms(partner) {
        let it = this;
        it.setState({
            loading: true,
            partner,
            programList: [],
            program: null
        })
        PartnerModel.getInstance().getPartnerDetail(partner,
            function success(data) {
                it.setState({
                    loading: false,
                    programList: data.programs
                })
                console.log(data)
            },
            function failure(error) {
                it.setState({ loading: false })
                errorNotification(error)
            }
        )
    }

    setMasterCard(program) {
        this.setState({ program })
        for (let i = 0; i < this.state.programList.length; i++) {
            if (this.state.programList[i].id === program) {

                if (this.state.programList[i].is_mastercard === true) {
                    this.setState({ type: 3, disableOption: true })
                } else {
                    this.setState({ disableOption: false })
                }
            }
        }
    }

    generate_random_string(string_length) {
        let random_string = '';
        let random_ascii;
        let ascii_low = 65;
        let ascii_high = 90;
        let num_low = 1;
        let num_high = 9;
        let random_number = null;
        let string = '';

        for (let i = 0; i < string_length; i++) {
            random_ascii = Math.floor((Math.random() * (ascii_high - ascii_low)) + ascii_low)
            random_string = String.fromCharCode(random_ascii)
            random_number = Math.floor((Math.random() * (num_high - num_low)) + num_low);
            var rand = [random_string, random_number]
            var random = rand[Math.floor(Math.random() * 2)]
            string += random
        }
        return string
    }

    generate_random_string_array() {
        let length = Number(this.state.number_of_coupons);
        let arr = [];
        let i = 0;
        while (i < length) {
            let string = this.generate_random_string(4);
            string = this.state.prefix + string;
            if (!arr.includes(string)) {
                arr.push(string);
                i++
            }
        }
        this.setState({ codes: arr.join(', ') })
    }

    disabledDate(current) {
        // Can not select days before today and today
        return current < moment().startOf('day');
    }

    getCodes(data, fileName) {
        let { prefix } = this.state;
        let it = this;
        console.log('data.length: ', data.length)
        if (JSON.parse(data).length < 1) {
            errorNotification('Invalid file Please Upload correct file')
        }
        else {
            if (!this.state.program) {
                errorToaster('Please Select Program')
                return
            }
            // else if (!this.state.type) {
            //     errorToaster('Please Select Type')
            // }
            if (this.state.type === 3) {
                this.getFilesCode(data, fileName)
                // console.log
                return
            }
            if (this.state.type !== 3 && prefix.length !== 3) {
                errorToaster('Please enter Prefix')
                return
            }


            this.setState({ loading: true })
            CouponModel.getInstance().checkPrefix(prefix,
                function success(data) {
                    it.setState({ loading: false })
                    console.log('INNER DATA: ', data)
                    if (data.is_prefix) {
                        errorToaster('Prefix Already Taken')
                    } else {
                        it.getFilesCode(data)
                    }


                },
                function failure(error) {
                    errorToaster(error)
                    it.setState({ loading: false })
                }
            )



            // }
        }
    }

    getFilesCode(data, fileName) {
        let it = this;
        let { prefix } = this.state
        let codes = [];
        data = JSON.parse(data)
        if (it.state.type === 3) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].Code.trim().length === 6) {

                    codes.push(data[i].Code)
                }
                else {
                    it.setState({ fileName: null })
                    errorToaster(`Incorrect data in Line ${i + 2} please check file manually and re-upload it`);
                    break;
                }
            }
        } else {
            for (let i = 0; i < data.length; i++) {
                if (data[i].Code.trim().length === 4) {

                    codes.push(prefix + data[i].Code)
                }
                else {
                    it.setState({ fileName: null })
                    errorToaster(`Incorrect data in Line ${i + 2} please check file manually and re-upload it`);
                    break;
                }
            }
        }

        if (data.length === codes.length) {
            // this.setState({ codes: arr.join(', ') })
            it.setState({ number_of_coupons: data.length, codes: codes.join(', '), fileName, fileCode: data })
        }

    }

    generateCode() {
        let { prefix } = this.state;
        let it = this;
        if (!this.state.program) {
            errorToaster('Please Select Program')
            return
        }
        if (this.state.type === 3) {
            it.generate_random_string_array()
            return;
        }
        if (this.state.type !== 3 && this.state.prefix.length !== 3) {
            errorToaster('Please enter Prefix')
            return
        }
        this.setState({ loading: true })

        CouponModel.getInstance().checkPrefix(prefix,
            function success(data) {
                it.setState({ loading: false })
                console.log('INNER DATA: ', data)
                if (data.is_prefix) {
                    errorToaster('Prefix Already Taken')
                } else {
                    it.generate_random_string_array()
                }
            },
            function failure(error) {
                errorToaster(error)
                console.log(error)
                it.setState({ loading: false })
            }
        )




    }







    sendData() {
        const { name, description, partner, program, type, discountedValue, bin, prefix, codes, unlimited, redemptionLimit, startDate, endDate } = this.state;
        let it = this;

        let params = {};
        let chkprfx = false
        // var validator = /^[A-Za-z]+$/;
        const text = 'Validation Error! Please check fields';
        this.setState({
            nameError: false,
            descriptionError: false,
            programError: false,
            typeError: false,
            discountedValueError: false,
            number_of_couponsError: false,
            prefixError: false,
            codesError: false,
            redemptionLimitError: false,
            dateError: false,
            partnerError: false,
            binError: false
        })





        if (name.length < 1) {
            this.setState({ nameError: true });
            errorToaster(text);
            return;
        }
        params.name = name;

        if (description.length < 1) {
            this.setState({ descriptionError: true });
            errorToaster(text);
            return;
        }
        params.description = description;

        if (!partner) {
            this.setState({ partnerError: true });
            errorToaster(text);
            return;
        }
        params.partner_id = partner;

        if (!program) {
            this.setState({ programError: true });
            errorToaster(text);
            return;
        }
        params.program_id = program;

        if (!type) {
            this.setState({ typeError: true });
            errorToaster(text);
            return;
        }
        params.type = type;

        switch (type) {
            case 1:
                if (discountedValue.length < 1) {
                    this.setState({ discountedValueError: true })
                    errorToaster(text)
                    return
                }
                params.discount = discountedValue;
                if (prefix.length < 3) {
                    this.setState({ prefixError: true })
                    errorToaster(text)
                    return
                }
                params.prefix = prefix;
                if (this.state.fileName) {
                    let newArr = []
                    this.state.fileCode.map(code => {
                        newArr.push({ code: prefix + code.Code, usage: unlimited ? 0 : code.Usage ? code.Usage : code['Usage\r'] })
                    })
                    params.code = newArr
                }
                else {
                    if (codes.length < 1) {
                        this.setState({ codesError: true })
                        errorToaster(text);
                        return
                    }
                    if (!unlimited) {
                        if (redemptionLimit.length < 1) {
                            this.setState({ redemptionLimitError: true })
                            errorToaster(text)
                            return
                        }
                        if (parseFloat(redemptionLimit) === parseFloat(redemptionLimit) && parseFloat(redemptionLimit) % 1 !== 0) {
                            errorToaster('Redemption Limit Must be Non decimal value');
                            return
                        }
                    }

                    var codesArr = codes.split(',');
                    if (codesArr[0].length === 4) {
                        chkprfx = true
                        params.code = [{ code: prefix + codesArr[0], usage: unlimited ? 0 : redemptionLimit }]
                    } else {
                        let newArr = [];
                        codesArr.forEach(code => {
                            let val = code.trim();
                            newArr.push({ code: val, usage: unlimited ? 0 : redemptionLimit })
                        });
                        params.code = newArr;
                    }
                }

                break;
            case 2:
                if (prefix.length < 3) {
                    this.setState({ prefixError: true })
                    errorToaster(text)
                    return
                }
                params.prefix = prefix;
                if (this.state.fileName) {
                    let newArr = []
                    this.state.fileCode.map(code => {
                        console.log(code)
                        newArr.push({ code: prefix + code.Code, usage: unlimited ? 0 : code.Usage ? code.Usage : code['Usage\r'] })
                    })
                    params.code = newArr;
                }
                else {
                    if (codes.length < 4) {
                        this.setState({ codesError: true })
                        errorToaster(text);
                        return
                    }
                    if (!unlimited) {
                        if (redemptionLimit.length < 1) {
                            this.setState({ redemptionLimitError: true })
                            errorToaster(text)
                            return
                        }
                        if (parseFloat(redemptionLimit) === parseFloat(redemptionLimit) && parseFloat(redemptionLimit) % 1 !== 0) {
                            errorToaster('Redemption Limit Must be Non decimal value');
                            return
                        }
                    }

                    var codesArr = codes.split(',');
                    if (codesArr[0].length === 4) {
                        chkprfx = true
                        params.code = [{ code: prefix + codesArr[0], usage: unlimited ? 0 : redemptionLimit }]
                        // params.code = [prefix + codesArr[0]]
                    } else {
                        let newArr = [];
                        codesArr.forEach(code => {
                            let val = code.trim();
                            newArr.push({ code: val, usage: unlimited ? 0 : redemptionLimit })
                        });
                        params.code = newArr;
                    }
                }
                break;
            case 3:
                console.log("STATE>CODE: ", this.state.codes)
                params.code = this.state.codes.map(code => {
                    return {
                        code,
                        usage: this.state.unlimited ? 0 : this.state.redemptionLimit
                    }
                })
                break;

            case 4:
                if (discountedValue.length < 1) {
                    this.setState({ discountedValueError: true })
                    errorToaster(text)
                    return
                }
                params.discount = discountedValue;

                if (codes.length !== 7) {
                    this.setState({ codesError: true })
                    errorToaster(text)
                    return
                }
                // params.code = codes;


                if (bin.length < 1) {
                    this.setState({ binError: true })
                    errorToaster(text)
                    return
                }
                params.code = bin.map(binCode => { return { code: codes, bin_code: binCode.trim() } });
                break;

            default:
                errorToaster('Something Went wrong');
                return;
        }


        params.is_unlimit = type === 4 ? true : unlimited;
        if (startDate.length < 1 || endDate.length < 1) {
            this.setState({ dateError: true });
            errorToaster(text);
            return;
        }
        params.start_date = startDate;
        params.end_date = endDate;

        if (chkprfx) {
            this.setState({ loading: true })
            CouponModel.getInstance().checkPrefix(prefix,
                function success(data) {
                    it.setState({ loading: false })
                    console.log('INNER DATA: ', data)
                    if (data.is_prefix) {
                        errorToaster('Prefix Already Taken')
                    } else {
                        it.createCoupon(params)
                    }
                },
                function failure(error) {
                    errorToaster(error)
                    console.log('Unique Error')
                    it.setState({ loading: false })
                }
            )
        } else {
            this.createCoupon(params)
        }


    }

    createCoupon(params) {
        const { history } = this.props;
        let it = this;
        this.setState({ loading: true })
        CouponModel.getInstance().addCoupon(params,
            function success(data) {
                successNotification('Coupon Created Successfully')
                setTimeout(() => {
                    window.location.href = "/coupon"
                }, 1000);
            },
            function failure(error) {
                errorNotification(error)
                console.log(error)
                it.setState({ loading: false })
            }
        );
    }





    render() {
        const { nameError, descriptionError, discountedValueError, number_of_couponsError, codesError, dateError, redemptionLimitError, prefixError, programList, typeList, binError } = this.state;
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <Spin spinning={this.state.loading} >
                    <div className="kt-content  kt-grid__item kt-grid__item--fluid" >

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                    <div className="kt-portlet__head kt-portlet__head--lg">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">Add Coupon</h3>
                                        </div>
                                        <div className="kt-portlet__head-toolbar">
                                            <a href="/coupon" className="btn btn-clean kt-margin-r-10">
                                                <i className="la la-arrow-left"></i>
                                                <span className="kt-hidden-mobile">Back</span>
                                            </a>
                                            <div className="btn-group">
                                                <button onClick={() => this.sendData()} type="button" className="btn btn-brand">
                                                    <i className="la la-check"></i>
                                                    <span className="kt-hidden-mobile">Save</span>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                    {/* end header */}
                                    <div className="kt-portlet__body">
                                        <form className="kt-form" id="kt_form">
                                            <div className="row">
                                                <div className="col-xl-2"></div>
                                                <div className="col-xl-8">

                                                    <div className="kt-section kt-section--first">

                                                        <div className="kt-section__body">
                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Name <b>*</b></label>
                                                                <div className="col-9">
                                                                    <input maxLength="70" onChange={(event) => this.setState({ name: event.target.value })} className="form-control" type="text" />
                                                                    <span style={{ color: nameError ? 'red' : '' }} className="form-text text-muted"><p style={{ color: nameError ? 'red' : '' }}>Please enter name in English</p></span>

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Description <b>*</b></label>
                                                                <div className="col-9">
                                                                    <textarea maxLength="200" onChange={(event) => this.setState({ description: event.target.value })} className="form-control" type="text"></textarea>
                                                                    <span className="form-text text-muted"><p style={{ color: descriptionError ? 'red' : '' }}>Please enter name in Arabic</p></span>

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label" htmlFor="marchantGroup">Partner <b>*</b></label>
                                                                <div className="col-9">

                                                                    <Select
                                                                        showSearch
                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                        // placeholder="Select a merchant group"
                                                                        value={this.state.partner}
                                                                        onChange={(partner) => this.getPartnerPrograms(partner)}
                                                                        notFoundContent={
                                                                            // <Tooltip title="Add Type">
                                                                            //     <Icon onClick={() => this.appendType()} disabled={false} type="plus-circle" theme="filled" style={{ color: '#3758ff', fontSize: '20px' }} />
                                                                            // </Tooltip>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                <p style={{ fontSize: 14 }}>Please Search Merchant by Name</p>
                                                                            </div>
                                                                        }
                                                                        onSearch={(val) => this.searchQueryPartner(val, this)}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {this.state.partnerList.map((partner) => (
                                                                            <Option key={partner.id} value={partner.id} >{partner.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                    <span className="form-text text-muted"> <p style={{ color: this.state.partnerError ? 'red' : '' }}> Please choose Partner</p></span>

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label" htmlFor="marchantGroup">Program <b>*</b></label>
                                                                <div className="col-9">

                                                                    <Select
                                                                        showSearch
                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                        // placeholder="Select a merchant group"
                                                                        value={this.state.program}
                                                                        onChange={(program) => this.setMasterCard(program)}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {programList.map((program) => (
                                                                            <Option key={program.id} value={program.id} >{program.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                    <span className="form-text text-muted"><p style={{ color: this.state.programError ? 'red' : '' }}>Please choose Program</p></span>

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label" htmlFor="marchantGroup">Type <b>*</b></label>
                                                                <div className="col-9">

                                                                    <Select
                                                                        showSearch
                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                        value={this.state.type}
                                                                        disabled={this.state.disableOption}
                                                                        // placeholder="Select a merchant group"
                                                                        onChange={(type) => {
                                                                            this.setState({ type })
                                                                            if (type === 2) {
                                                                                this.setState({
                                                                                    redemptionLimit: 1,
                                                                                    unlimited: false
                                                                                })
                                                                            }
                                                                        }}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {typeList.map((type) => (
                                                                            <Option key={type.value} value={type.value} >{type.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                    <span className="form-text text-muted">Please choose Type</span>

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Discount Value <b>*</b></label>
                                                                <div className="col-9">
                                                                    <div className="input-group mb-3">
                                                                        <input value={this.state.discountedValue} disabled={this.state.type === 1 || this.state.type === 4 ? false : true} maxLength="20" onChange={(event) => this.setState({ discountedValue: event.target.value })} className="form-control" type="number" />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text" id="basic-addon2">%</span>
                                                                        </div>
                                                                    </div>
                                                                    <span className="form-text text-muted"><p style={{ color: discountedValueError ? 'red' : '' }}>Please enter discount value</p></span>

                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.type === 3 || this.state.type === 4 ?
                                                                    null :
                                                                    <div className="form-group row">
                                                                        <label className="col-3 col-form-label">Prefix <b>*</b></label>
                                                                        <div className="col-9">
                                                                            <input maxLength="3" value={this.state.prefix} className="form-control" type="text" onChange={(event) => this.setState({ prefix: event.target.value.toUpperCase(), codes: [], number_of_coupons: 1, fileName: null, fileCode: [] })} />
                                                                            {/* <input maxLength="3" disabled={this.state.fileName ? false : Number(this.state.number_of_coupons) > 1 ? false : true} value={this.state.prefix} className="form-control" type="text" onChange={(event) => this.setState({ prefix: event.target.value.toUpperCase(), codes: '' })} /> */}
                                                                            <span className="form-text text-muted"><p style={{ color: prefixError ? 'red' : '' }}>Please Enter Prefix</p></span>
                                                                        </div>
                                                                    </div>
                                                            }


                                                            {
                                                                (this.state.type === 1 || this.state.type === 2) ?
                                                                    this.state.fileName ?
                                                                        <div className="form-group row">
                                                                            <label className="col-3 col-form-label">File Uploaded</label>
                                                                            <div className="col-9">
                                                                                <button onClick={() => this.setState({ fileName: null, number_of_coupons: 1, codes: [] })} type="button" className="btn btn-brand">
                                                                                    <span className="kt-hidden-mobile">Remove File</span>
                                                                                </button>
                                                                                <span className="form-text text-muted">{this.state.fileName}</span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div style={{ marginBottom: '20px' }}>
                                                                            <FileUploader
                                                                                getData={(data, fileName) => this.getCodes(data, fileName)}
                                                                            />
                                                                        </div>
                                                                    : null
                                                            }


                                                            {
                                                                (this.state.type === 1 || this.state.type === 2) ?
                                                                    <div className="form-group row">
                                                                        <label className="col-3 col-form-label">Number of Codes <b>*</b></label>
                                                                        <div className="col-9">
                                                                            <input disabled={this.state.fileName ? true : this.state.type === 3 ? true : false} maxLength="20" className="form-control" value={this.state.number_of_coupons} type="number" onChange={(event) => {
                                                                                if (event.target.value > 0) {
                                                                                    this.setState({
                                                                                        number_of_coupons: event.target.value.replace('.', ''),
                                                                                        number_of_couponsError: false,
                                                                                        codes: []
                                                                                    })
                                                                                }
                                                                                else this.setState({ number_of_coupons: event.target.value.replace('.', ''), number_of_couponsError: true })
                                                                            }} />
                                                                            <span className="form-text text-muted"><p style={{ color: number_of_couponsError ? 'red' : '' }}>Number must be greater than zero</p></span>
                                                                        </div>
                                                                    </div> : null
                                                            }



                                                            {
                                                                (this.state.type === 1 || this.state.type === 2) ?
                                                                    <div className="form-group row">

                                                                        <div className="col-9">
                                                                            <button disabled={this.state.fileName ? true : Number(this.state.number_of_coupons) > 1 && this.state.prefix.length === 3 ? false : true} onClick={() => this.generateCode()} type="button" className="btn btn-brand">
                                                                                <span className="kt-hidden-mobile">Generate Code Automatically</span>
                                                                            </button>
                                                                        </div>
                                                                    </div> : null
                                                            }


                                                            {
                                                                this.state.type === 3 ?
                                                                    <div className="form-group row">
                                                                        <label className="col-3 col-form-label">BIN (Bin+Bank) <b>*</b></label>
                                                                        <div className="col-9">

                                                                            <Select
                                                                                showSearch
                                                                                mode="multiple"
                                                                                style={{ margin: '10px 0px 10px 0px' }}
                                                                                value={this.state.codes}
                                                                                onChange={(value) => this.setState({ codes: value })}
                                                                                notFoundContent={
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                        <p style={{ fontSize: 14 }}>Please Search Bins</p>
                                                                                    </div>
                                                                                }
                                                                                onSearch={(val) => this.searchQueryBIN(val, this)}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {this.state.binList.map((bin) => (
                                                                                    <Option key={bin.bin} value={bin.bin} >{bin.bin + ' ' + bin.bank_name}</Option>
                                                                                ))}
                                                                            </Select>
                                                                            <span className="form-text text-muted"><p style={{ color: codesError ? 'red' : '' }}>Please Select BIN Code</p></span>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    this.state.type === 4 ?
                                                                        <div className="form-group row">
                                                                            <label className="col-3 col-form-label">Code <b>*</b></label>
                                                                            <div className="col-9">
                                                                                <input maxLength="7" disabled={this.state.type === 3 ? true : false} value={this.state.codes} className="form-control" type="text" onChange={(event) => this.setState({ codes: event.target.value.toUpperCase() })} />
                                                                                <span className="form-text text-muted"><p style={{ color: codesError ? 'red' : '' }}>Please Enter Code (Must be 7 digit AlphaNumeric Value)</p></span>
                                                                            </div>
                                                                        </div>
                                                                        :

                                                                        Number(this.state.number_of_coupons) > 1 && this.state.prefix.length === 3 ?
                                                                            <div className="form-group row">
                                                                                <label className="col-3 col-form-label">Codes <b>*</b></label>
                                                                                <div className="col-9">
                                                                                    <textarea disabled value={this.state.codes} className="form-control" type="text" onChange={(event) => this.setState({ codes: event.target.value })} ></textarea>
                                                                                    <span className="form-text text-muted"><p style={{ color: codesError ? 'red' : '' }}>Please Enter Codes Must be comma separated Value</p></span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            this.state.type !== 4 ?
                                                                                <div className="form-group row">
                                                                                    <label className="col-3 col-form-label">Code <b>*</b></label>
                                                                                    <div className="col-9">
                                                                                        <input maxLength="4" disabled={this.state.type === 3 ? true : false} value={this.state.codes} className="form-control" type="text" onChange={(event) => this.setState({ codes: event.target.value.toUpperCase() })} />
                                                                                        <span className="form-text text-muted"><p style={{ color: codesError ? 'red' : '' }}>Please Enter Code (Must be 4 digit AlphaNumeric Value)</p></span>
                                                                                    </div>
                                                                                </div>
                                                                                : null

                                                            }

                                                            {
                                                                this.state.type === 4 ?
                                                                    <div className="form-group row">
                                                                        <label className="col-3 col-form-label">BIN (Bin+Bank) <b>*</b></label>
                                                                        <div className="col-9">

                                                                            <Select
                                                                                showSearch
                                                                                mode="multiple"
                                                                                style={{ margin: '10px 0px 10px 0px' }}
                                                                                value={this.state.bin}
                                                                                onChange={(value) => this.setState({ bin: value })}
                                                                                notFoundContent={
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                        <p style={{ fontSize: 14 }}>Please Search Bins</p>
                                                                                    </div>
                                                                                }
                                                                                onSearch={(val) => this.searchQueryBIN(val, this)}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {this.state.binList.map((bin) => (
                                                                                    <Option key={bin.bin} value={bin.bin} >{bin.bin + ' ' + bin.bank_name}</Option>
                                                                                ))}
                                                                            </Select>
                                                                            <span className="form-text text-muted"><p style={{ color: binError ? 'red' : '' }}>Please Enter 6 digit BIN Number(Must be Comma Separated for Multiple BINS)</p></span>
                                                                        </div>
                                                                    </div> : null
                                                            }

                                                            {
                                                                this.state.type !== 4 ?
                                                                    <div className="form-group row">
                                                                        <label className="col-3 col-form-label">Unlimited</label>
                                                                        <div className="col-9">
                                                                            <Checkbox checked={this.state.unlimited} disabled={this.state.type === 2 ? true : false} onChange={(event) => this.setState({ unlimited: event.target.checked })} className="form-control" style={{ border: '0px', padding: '0px' }} />
                                                                            <span style={{ padding: '0px' }} className="form-text text-muted">Please check if coupon can be used unlimited times</span>

                                                                        </div>
                                                                    </div> : null
                                                            }


                                                            {
                                                                this.state.fileName ?
                                                                    null
                                                                    :
                                                                    this.state.type !== 4 ?
                                                                        <div className="form-group row">
                                                                            <label className="col-3 col-form-label">Usage Limit <b>*</b></label>
                                                                            <div className="col-9">
                                                                                <input value={this.state.redemptionLimit} disabled={this.state.unlimited || this.state.type === 2 ? true : false} maxLength="6" value={this.state.redemptionLimit} className="form-control" type="number" onChange={(event) => this.setState({ redemptionLimit: event.target.value })} />
                                                                                <span className="form-text text-muted"><p style={{ color: redemptionLimitError ? 'red' : '' }}>Please Enter Number of Usage limit</p></span>
                                                                            </div>
                                                                        </div> : null
                                                            }

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Start Date ~ End Date <b>*</b></label>
                                                                <div className="col-9">
                                                                    <RangePicker
                                                                        disabledDate={this.disabledDate}
                                                                        className="form-control"
                                                                        style={{ padding: "0px", border: '0px solid black' }}
                                                                        showTime={false}
                                                                        onChange={(value, string) => this.setState({ startDate: string[0], endDate: string[1] })}
                                                                        format="YYYY-MM-DD"
                                                                    />
                                                                    <span className="form-text text-muted"><p style={{ color: dateError ? 'red' : '' }}>Please enter Start and End Date</p></span>

                                                                </div>
                                                            </div>

                                                            {/* <FileUploader/> */}











                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2"></div>

                                            </div>
                                        </form>
                                    </div>
                                    {/* end body */}
                                </div>
                            </div>
                        </div>

                    </div>
                </Spin>
            </div>
        );
    }
}