/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";

import { Input, Spin, Select, Icon } from "antd";

import _ from "lodash";

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";

import { DetailPageHeaderWithButton } from "styles/detailPageHeader";

import { validateProductBookingAddonParams } from "./helperFunctions";
import ProductBookingModel from "../../Models/ProductBookingModel";

const { Option } = Select;

export default function ProductBookingConfigData({ props, is_edit }) {
  const {
    match: { params },
  } = props;
  const [loading, setLoading] = useState(false),
    [name, set_name] = useState(""),
    [arabic_name, set_arabic_name] = useState(""),
    [description, set_description] = useState(""),
    [arabic_description, set_arabic_description] = useState(""),
    [product_booking_config_id, set_product_booking_config_id] =
      useState(undefined),
    [product_booking_configs, set_product_booking_configs] = useState([]),
    [list_price, set_list_price] = useState(null),
    [discounted_price, set_discounted_price] = useState(null);

  useEffect(() => {
    if (is_edit) getProductBookingAddonDetail(params.id);
    getProductBookingConfigs();
  }, []);

  const getSearchProductBookingConfigs = useCallback(
    _.debounce((value) => getProductBookingConfigs(value), 500),
    []
  );
  async function getProductBookingConfigs(value = undefined) {
    setLoading(true);
    let payload = {
      product_booking_id: params.product_booking_id,
      is_default: false,
    };
    if (value?.length > 0) payload.section_title = value;
    ProductBookingModel.getInstance().getProductBookingConfigList(
      payload,
      function success(data) {
        set_product_booking_configs(data.records);
        setLoading(false);
      },
      function failure(error) {
        errorNotification(error);
        setLoading(false);
      }
    );
  }

  function getProductBookingAddonDetail() {
    const { id } = params;
    setLoading(true);
    ProductBookingModel.getInstance().getProductBookingAddonDetail(
      id,
      function success(data) {
        set_name(data.name);
        set_arabic_name(data.arabic_name);
        set_description(data.description);
        set_arabic_description(data.arabic_description);
        set_product_booking_config_id(data.product_booking_config_id);
        set_list_price(data.list_price);
        set_discounted_price(data.discounted_price);
        setLoading(false);
      },
      function failure(error) {
        errorNotification(error);
        setLoading(false);
      }
    );
  }

  const saveAddon = () => {
    const payload = {
      name,
      arabic_name,
      description,
      arabic_description,
      product_booking_config_id,
      list_price,
      discounted_price,
    };
    const validation = validateProductBookingAddonParams(payload);
    if (!validation.status) {
      errorToaster(validation.message);
    } else {
      if (is_edit) updateAddonAPI(payload);
      else createAddonAPI(payload);
    }
  };

  function createAddonAPI(payload) {
    ProductBookingModel.getInstance().addProductBookingAddon(
      payload,
      function success(data) {
        successNotification("Product Booking Addon Saved");
        setTimeout(() => {
          props.history.goBack();
          setLoading(false);
        }, 1000);
      },
      function failure(error) {
        setLoading(false);
        errorNotification(JSON.stringify(error));
      }
    );
  }
  function updateAddonAPI(payload) {
    const { id } = params;
    ProductBookingModel.getInstance().updateProductBookingAddon(
      id,
      payload,
      function success(data) {
        successNotification("Product Booking Addon Saved");
        setTimeout(() => {
          props.history.goBack();
          setLoading(false);
        }, 1000);
      },
      function failure(error) {
        setLoading(false);
        errorNotification(JSON.stringify(error));
      }
    );
  }

  return (
    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={loading}>
          <div className="row">
            <div className="col-lg-12">
              <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                <div className="kt-portlet__head kt-portlet__head--lg">
                  <DetailPageHeaderWithButton
                    title={`${is_edit ? "Edit" : "Add"} Product Booking Addon`}
                    buttonOneIcon={"la la-arrow-left"}
                    buttonOneCallBack={() => props.history.goBack()}
                    buttonTwoIcon={"la la-check"}
                    buttonTwoCallBack={() => saveAddon()}
                  />
                </div>

                <div className="kt-portlet__body">
                  <form className="kt-form" id="kt_form">
                    <div className="row">
                      <div className="col-xl-2"></div>
                      <div className="col-xl-8">
                        <div className="kt-section kt-section--first">
                          <div className="kt-section__body">
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Name
                              </label>
                              <div className="col-9">
                                <Input
                                  maxLength={100}
                                  value={name}
                                  onChange={(event) =>
                                    set_name(event.target.value)
                                  }
                                  className="form-control" /*type="number"*/
                                />
                                <span className="form-text text-muted">
                                  <p>Please Enter Name</p>
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Arabic Name
                              </label>
                              <div className="col-9">
                                <Input
                                  maxLength={100}
                                  value={arabic_name}
                                  onChange={(event) =>
                                    set_arabic_name(event.target.value)
                                  }
                                  className="form-control" /*type="number"*/
                                />
                                <span className="form-text text-muted">
                                  <p>Please Enter Arabic Name</p>
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Description
                              </label>
                              <div className="col-9">
                                <Input.TextArea
                                  maxLength={100}
                                  value={description}
                                  onChange={(event) =>
                                    set_description(event.target.value)
                                  }
                                  className="form-control" /*type="number"*/
                                />
                                <span className="form-text text-muted">
                                  <p>Please Enter Description</p>
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Arabic Description
                              </label>
                              <div className="col-9">
                                <Input.TextArea
                                  maxLength={100}
                                  value={arabic_description}
                                  onChange={(event) =>
                                    set_arabic_description(event.target.value)
                                  }
                                  className="form-control" /*type="number"*/
                                />
                                <span className="form-text text-muted">
                                  <p>Please Enter Arabic Description</p>
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Product Booking Section
                              </label>
                              <div className="col-9">
                                <Select
                                  allowClear
                                  showSearch
                                  style={{
                                    margin: "10px 0px 10px 0px",
                                    width: "100%",
                                  }}
                                  value={product_booking_config_id}
                                  notFoundContent={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Icon
                                        style={{
                                          fontSize: 16,
                                          marginRight: "10px",
                                          marginBottom: "5px",
                                        }}
                                        type="search"
                                      />
                                      <p style={{ fontSize: 14 }}>
                                        Please search product booking Section
                                      </p>
                                    </div>
                                  }
                                  onSearch={(val) =>
                                    getSearchProductBookingConfigs(val)
                                  }
                                  onChange={(conf) =>
                                    set_product_booking_config_id(conf)
                                  }
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {product_booking_configs.map((conf) => (
                                    <Option key={conf.id} value={conf.id}>
                                      {conf.section_title}
                                    </Option>
                                  ))}
                                </Select>
                                <span className="form-text text-muted">
                                  Please Select Booking Config
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                List Price
                              </label>
                              <div className="col-9">
                                <Input
                                  maxLength={3}
                                  value={list_price}
                                  onChange={(event) =>
                                    set_list_price(event.target.value)
                                  }
                                  className="form-control"
                                  type="number"
                                />
                                <span className="form-text text-muted">
                                  <p>Please Enter List Price</p>
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Discounted Price
                              </label>
                              <div className="col-9">
                                <Input
                                  maxLength={3}
                                  value={discounted_price}
                                  onChange={(event) =>
                                    set_discounted_price(event.target.value)
                                  }
                                  className="form-control"
                                  type="number"
                                />
                                <span className="form-text text-muted">
                                  <p>Please Enter Discounted Price</p>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
}
