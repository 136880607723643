import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel'



export default class ItemModel {
    static myInstance = null;
    static getInstance() {
        if (ItemModel.myInstance === null) {
            ItemModel.myInstance = new ItemModel();
        }
        return this.myInstance;
    }

    static ItemList = [];
    static ItemGroupList = [];
    static SaleChannelList = [];
    static ItemTypeList = [];


    // Item Group
    getItemGroup(successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getItemGroup(token,
            function resSuccess(data) {
                ItemModel.getInstance().ItemGroupList = data.data
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    searchItemGroup(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().searchItemGroup(token, params,
            function resSuccess(data) {
                ItemModel.getInstance().ItemGroupList = data.data;
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    addItemGroup(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addItemGroup(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    getItemGroupDetail(itemGroupId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getItemGroupDetail(token, itemGroupId,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateItemGroup(itemGroupId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateItemGroup(token, itemGroupId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    itemGroupActivation(itemGroupId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().itemGroupActivation(token, itemGroupId, params,
            function resSuccess(data) {
                ItemModel.getInstance().ItemGroupList.forEach((group, index) => {
                    if (group.id === itemGroupId) {
                        group.is_active = params.is_active;
                        successTrigger(data)
                    }
                });
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    removeItemGroup(itemGroupId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeItemGroup(token, itemGroupId,
            function resSuccess() {
                ItemModel.getInstance().ItemGroupList.forEach((group, index) => {
                    if (group.id === itemGroupId) {
                        ItemModel.getInstance().ItemGroupList.splice(index, 1);
                        successTrigger()
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    //  Item


    getItem(successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getItem(token,
            function resSuccess(data) {
                ItemModel.getInstance().ItemList = data.data
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    searchItem(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().searchItem(token, params,
            function resSuccess(data) {
                ItemModel.getInstance().ItemList = data.data;
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    addItem(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addItem(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    getItemDetail(itemId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getItemDetail(token, itemId,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateItem(itemId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateItem(token, itemId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    itemActivation(itemId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().itemActivation(token, itemId, params,
            function resSuccess(data) {
                ItemModel.getInstance().ItemList.forEach((item, index) => {
                    if (item.id === itemId) {
                        item.is_active = params.is_active;
                        successTrigger(data)
                    }
                });
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    removeItem(itemId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeItem(token, itemId,
            function resSuccess() {
                ItemModel.getInstance().ItemList.forEach((item, index) => {
                    if (item.id === itemId) {
                        ItemModel.getInstance().ItemList.splice(index, 1);
                        successTrigger()
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    //  item group according to outlet
    getOutletItemGroup(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOutletItemGroup(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    //  item according to outlet
    getOutletItem(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOutletItem(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    // sales channel
    getSaleChannel( successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getSaleChannel(token, 
            function resSuccess(data) {
                ItemModel.getInstance().SaleChannelList = data
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    // item type
    getItemType( successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getItemType(token, 
            function resSuccess(data) {
                ItemModel.getInstance().ItemTypeList = data
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


}