import React from "react";
import { Select, Spin, Icon } from "antd";
import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
import {
  validateEmail,
  validatePhone,
  validatePhoneCountry,
} from "../../Helpers/Shared/Validators";

import UserModel from "../../Models/UserModel";
import { Label } from "reactstrap";
import MerchantModel from "../../Models/MerchantModel";
import PartnerModel from "../../Models/PartnerModel";

const { Option } = Select;

export default class AddUser extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      nameError: false,
      email: "",
      emailError: false,
      typeList: [
        {
          name: "Admin",
          value: "1",
          disabled: false,
        },
        {
          name: "Merchant",
          value: "2",
          disabled: false,
        },
        {
          name: "Partner",
          value: "3",
          disabled: false,
        },
        {
          name: "Customer",
          value: "4",
          disabled: true,
        },
        {
          name: "Outlet",
          value: "5",
          disabled: false,
        },
      ],
      type: [],
      typeError: false,
      phone: "",
      phoneError: false,
      password: "",
      passwordError: false,
      repeatPassword: "",
      repeatPasswordError: false,
      loading: false,
      merchantList: [],
      partnerList: [],
      merchant: null,
      partner: null,
      merchantError: false,
      partnerError: false,
      outlet: null,
      outletList: [],
      outletError: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getPartnerList();
  }

  handleChange(event) {
    let { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleType(value) {
    this.setState({ type: value });
    if (value.length < 1) {
      this.setState({ typeError: true });
    } else this.setState({ typeError: false });
  }

  addNewUser() {
    let { partner_id, merchant_id, outlet_id } = this.state;
    const {
      name,
      email,
      type,
      phone,
      password,
      repeatPassword,
      merchant,
      partner,
      outlet,
    } = this.state;
    const { history } = this.props;
    var params = {};
    this.setState({
      nameError: false,
      emailError: false,
      typeError: false,
      phoneError: false,
      passwordError: false,
      repeatPasswordError: false,
      merchantError: false,
      partnerError: false,
      outletError: false,
    });
    let it = this;
    const text = "Validation Error! Please check fields";
    if (name.length < 3) {
      errorToaster(text);
      this.setState({ nameError: true });
      return;
    }
    if (!validateEmail(email)) {
      errorToaster("Please Enter Valid Email Address");
      this.setState({ emailError: true });
      return;
    }
    if (type.length < 1) {
      errorToaster(text);
      this.setState({ typeError: true });
      return;
    }

    // validateMerchant
    if (type.includes("2")) {
      if (merchant.length < 1) {
        this.setState({ merchantError: true });
        errorToaster(text);
        return;
      }
      merchant_id = [merchant_id];
      params.merchant_id = merchant;
    } else params.merchant_id = [];

    // validatePartner
    if (type.includes("3")) {
      if (partner.length < 1) {
        this.setState({ partnerError: true });
        errorToaster(text);
        return;
      }
      partner_id = [partner_id];
      params.partner_id = partner;
    } else params.partner_id = [];

    // validateOutlet
    if (type.includes("5")) {
      if (outlet.length < 1) {
        this.setState({ outletError: true });
        errorToaster("Please select outlet");
        return;
      }
      outlet_id = [outlet_id];
      params.outlet_id = outlet;
    } else params.outlet_id = [];

    if (!validatePhoneCountry(phone)) {
      errorToaster("Please Enter Proper Phone Number");
      this.setState({ phoneError: true });
      return;
    }

    if (password.length < 6) {
      errorToaster("Password Must be at least 6 characters");
      this.setState({ passwordError: true });
      return;
    }
    if (repeatPassword !== password) {
      errorToaster("Repeat Password does not match");
      this.setState({ repeatPasswordError: true });
      return;
    }

    params.full_name = name;
    params.email = email.toLowerCase();
    params.role_id = type;
    params.mobile = phone;
    params.password = password;
    // params.merchant = merchant;
    // params.partner = partner;
    // params.type = 1;

    this.setState({ loading: true });
    UserModel.getInstance().addUser(
      params,
      function success(data) {
        successNotification("User Created Successfully");
        setTimeout(() => {
          window.location.href = "/users";
        }, 1000);
      },
      function failure(serverError) {
        it.setState({ loading: false });
        console.log(serverError);
        errorNotification(serverError);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQueryMerchant = this.debounce(function (value, it) {
    it.getMerchantList(value);
  }, 1000);

  getMerchantList(query) {
    let it = this;
    it.setState({ loading: true });
    let params = {
      query,
    };
    MerchantModel.getInstance().getMerchant(
      params,
      function success(data) {
        it.setState({
          merchantList: data.data,
          loading: false,
        });
      },
      function failure(msg) {
        console.log(msg);
      }
    );
  }

  getPartnerList() {
    let it = this;
    it.setState({
      loading: true,
    });
    PartnerModel.getInstance().getPartner(
      function success() {
        it.setState({
          loading: false,
          partnerList: PartnerModel.getInstance().PartnerList,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQueryOutlet = this.debounce(function (value, it) {
    it.getOutletList(value);
  }, 1000);

  getOutletList(name) {
    let it = this;
    it.setState({ loading: true });
    let params = {
      "outlets.name": name.toLowerCase(),
    };
    MerchantModel.getInstance().getSearchOutlet(
      params,
      function success() {
        it.setState({
          loading: false,
          outletList: MerchantModel.getInstance().OutletList,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  render() {
    const {
      nameError,
      emailError,
      typeError,
      phoneError,
      passwordError,
      repeatPasswordError,
      loading,
      merchantError,
      partnerError,
      outletError,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <Spin spinning={loading}>
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">Add User</h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a href="/users" className="btn btn-clean kt-margin-r-10">
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.addNewUser()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                        {/* <button type="button" className="btn btn-brand dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <ul className="kt-nav">
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-reload"></i>
                                                                <span className="kt-nav__link-text">Save & continue</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-power"></i>
                                                                <span className="kt-nav__link-text">Save & exit</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-edit-interface-symbol-of-pencil-tool"></i>
                                                                <span className="kt-nav__link-text">Save & edit</span>
                                                            </a>
                                                        </li>
                                                        <li className="kt-nav__item">
                                                            <a href="#" className="kt-nav__link">
                                                                <i className="kt-nav__link-icon flaticon2-add-1"></i>
                                                                <span className="kt-nav__link-text">Save & add new</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Full Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    name="name"
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter full name
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Email <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    autoComplete={false}
                                    name="email"
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                    className="form-control"
                                    type="email"
                                    defaultValue={this.state.email}
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: emailError ? "red" : "" }}
                                    >
                                      Please enter your email
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="userRole"
                                >
                                  Type <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    mode="multiple"
                                    onChange={(value) => this.handleType(value)}
                                  >
                                    {this.state.typeList.map((type) => (
                                      <Option
                                        disabled={type.disabled}
                                        value={type.value}
                                      >
                                        {type.name}
                                      </Option>
                                    ))}
                                  </Select>

                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: typeError ? "red" : "" }}
                                    >
                                      Please choose role
                                    </p>
                                  </span>
                                </div>
                              </div>

                              {this.state.type.includes("2") ? (
                                <div className="form-group row">
                                  <label
                                    className="col-3 col-form-label"
                                    htmlFor="userRole"
                                  >
                                    Merchant <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <Select
                                      showSearch
                                      style={{ margin: "10px 0px 10px 0px" }}
                                      mode="multiple"
                                      onChange={(merchant) =>
                                        this.setState({ merchant })
                                      }
                                      notFoundContent={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Icon
                                            style={{
                                              fontSize: 16,
                                              marginRight: "10px",
                                              marginBottom: "5px",
                                            }}
                                            type="search"
                                          />
                                          <p style={{ fontSize: 14 }}>
                                            Please Search Merchant by Name
                                          </p>
                                        </div>
                                      }
                                      onSearch={(val) =>
                                        this.searchQueryMerchant(val, this)
                                      }
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {this.state.merchantList.map((merch) => (
                                        <Option key={merch.id} value={merch.id}>
                                          {merch.name}
                                        </Option>
                                      ))}
                                    </Select>

                                    <span className="form-text text-muted">
                                      <p
                                        style={{
                                          color: merchantError ? "red" : "",
                                        }}
                                      >
                                        Please choose Merchant
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              ) : null}

                              {this.state.type.includes("3") ? (
                                <div className="form-group row">
                                  <label
                                    className="col-3 col-form-label"
                                    htmlFor="userRole"
                                  >
                                    Partner <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <Select
                                      showSearch
                                      style={{ margin: "10px 0px 10px 0px" }}
                                      mode="multiple"
                                      onChange={(partner) =>
                                        this.setState({ partner })
                                      }
                                    >
                                      {this.state.partnerList.map((partner) => (
                                        <Option
                                          key={partner.id}
                                          value={partner.id}
                                        >
                                          {partner.name}
                                        </Option>
                                      ))}
                                    </Select>

                                    <span className="form-text text-muted">
                                      <p
                                        style={{
                                          color: partnerError ? "red" : "",
                                        }}
                                      >
                                        Please choose Partner
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              ) : null}

                              {this.state.type.includes("5") ? (
                                <div className="form-group row">
                                  <label
                                    className="col-3 col-form-label"
                                    htmlFor="userRole"
                                  >
                                    Outlet <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <Select
                                      showSearch
                                      mode="multiple"
                                      style={{ margin: "10px 0px 10px 0px" }}
                                      onChange={(outlet) =>
                                        this.setState({ outlet })
                                      }
                                      // value={this.state.outlet}
                                      notFoundContent={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Icon
                                            style={{
                                              fontSize: 16,
                                              marginRight: "10px",
                                              marginBottom: "5px",
                                            }}
                                            type="search"
                                          />
                                          <p style={{ fontSize: 14 }}>
                                            Please Search Outlet by Name
                                          </p>
                                        </div>
                                      }
                                      onSearch={(val) =>
                                        this.searchQueryOutlet(val, this)
                                      }
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {this.state.outletList.map((outlet) => (
                                        <Option
                                          key={outlet.id}
                                          value={outlet.id}
                                        >
                                          {outlet.name}
                                        </Option>
                                      ))}
                                    </Select>

                                    <span className="form-text text-muted">
                                      <p
                                        style={{
                                          color: this.state.outletError
                                            ? "red"
                                            : "",
                                        }}
                                      >
                                        Please choose Outlet
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              ) : null}

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Phone <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    name="phone"
                                    className="form-control"
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                    type="phone"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: phoneError ? "red" : "" }}
                                    >
                                      Please enter your phone number
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  New Password <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    name="password"
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                    className="form-control"
                                    type="password"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: passwordError ? "red" : "",
                                      }}
                                    >
                                      Please enter your new password
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Confirm Password <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    name="repeatPassword"
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                    className="form-control"
                                    type="password"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: repeatPasswordError ? "red" : "",
                                      }}
                                    >
                                      Please enter your Confirm password
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
