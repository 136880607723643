import ApiManager from "../Helpers/Network/Api";
import UserModel from "./UserModel";



export default class ClassificationModel {
    static myInstance = null;
    static getInstance() {
        if (ClassificationModel.myInstance === null) {
            ClassificationModel.myInstance = new ClassificationModel();
        }
        return this.myInstance;
    }

    static ClassificationList = [];
    static OutletClassificationList = [];


    searchClassifications(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().searchClassifications(token, params,
            function resSuccess(data) {
                ClassificationModel.ClassificationList = data.records.map(_rc => ({ ..._rc, showPopConfirm: false }));
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    saveClassification(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().saveClassification(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateClassification(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateClassification(token, id, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getClassificationDetail(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getClassificationDetail(token, id,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    activateClassification(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateClassification(token, id, params,
            function resSuccess(data) {
                ClassificationModel.ClassificationList.forEach((record) => {
                    if (record.id === id) {
                        record.is_active = params.is_active
                    }
                })
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    removeClassification(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeClassification(token, id,
            function resSuccess(data) {
                ClassificationModel.ClassificationList.forEach((record, i) => {
                    if (record.id === id) {
                        ClassificationModel.ClassificationList.splice(i, 1);
                    }
                })
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getClassificationOutletMerchantCount(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getClassificationOutletMerchantCount(token, id,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    /**
     * Outlet classification association Section
     */

    getAllOutletClassifications(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getAllOutletClassifications(token, params,
            function resSuccess(data) {
                ClassificationModel.OutletClassificationList = data.records;
                successTrigger(data.records)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    addOutletClassification(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addOutletClassification(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateOutletClassification(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateOutletClassification(token, id, params,
            function resSuccess(data) {
                ClassificationModel.OutletClassificationList.forEach((record) => {
                    if (record.id === id) {
                        record.is_primary = params.is_primary
                    }
                })
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    deleteOutletClassification(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().deleteOutletClassification(token, id,
            function resSuccess(data) {
                ClassificationModel.OutletClassificationList.forEach((record, i) => {
                    if (record.id === id) {
                        ClassificationModel.OutletClassificationList.splice(i, 1);
                    }
                })
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

}