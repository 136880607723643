import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel'



export default class CategoryModel {
    static myInstance = null;
    static getInstance() {
        if (CategoryModel.myInstance === null) {
            CategoryModel.myInstance = new CategoryModel();
        }
        return this.myInstance;
    }

    static CategoryMerchantOutletListing = [];

    addCategoryAssocation(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addCategoryAssocation(token, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    getAllCategoryAssocation(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getAllCategoryAssocation(token, params,
            function successCallback(data) {
                CategoryModel.CategoryMerchantOutletListing = data.records
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    getCategoryAssocationDetail(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getCategoryAssocationDetail(token, id,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    updateCategoryAssocation(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateCategoryAssocation(token, id, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    removeCategoryAssocation(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeCategoryAssocation(token, id,
            function successCallback(data) {
                CategoryModel.CategoryMerchantOutletListing.forEach((openingHour, index) => {
                    if (openingHour.id === id) {
                        CategoryModel.CategoryMerchantOutletListing.splice(index, 1);
                        successTrigger(data)
                    }
                });
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
}