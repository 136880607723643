import React from "react";
import { Select, Spin } from "antd";

// import _ from 'lodash'

import UserModel from "../../Models/UserModel";
import PartnerModel from "../../Models/PartnerModel";

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
import SharedModel from "../../Models/SharedModel";

const { Option } = Select;

export default class AddPartner extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      name: "",
      nameError: false,
      arb_name: "",
      arb_nameError: false,
      countries: [],
      countriesList: [],
      cities: [],
      citiesList: [],
      cityError: false,
      countryError: false,
      apps: [],
      appId: null,
      appError: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    // let it = this;
    // it.setState({
    //     loading: true,
    // })

    this.getCities();
    this.getAllAppIds();

    // await UserModel.getInstance().getCountries(
    //     function success() {
    //         it.setState({
    //             countriesList: UserModel.getInstance().CountriesList,
    //             loading: false
    //         })
    //         it.getCities()
    //     },
    //     function failure(error) {
    //         console.log(error)
    //         it.setState({ loading: false })
    //     }
    // )
  }

  getCities() {
    let it = this;
    it.setState({ loading: true });
    UserModel.getInstance().getCities(
      null,
      function success() {
        it.setState({
          citiesList: UserModel.getInstance().CitiesList,
          // loading: false
        });
      },
      function failure(error) {
        console.log(error);
        // it.setState({ loading: false })
      }
    );
  }

  getAllAppIds() {
    let it = this;
    it.setState({ loading: true });
    SharedModel.getInstance().getAllAppIds(
      function success(data) {
        it.setState({
          apps: data,
          loading: false,
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false });
      }
    );
  }

  // async selectCities(countries) {
  //     let prev = this.state.countries;
  //     let { citiesList, cities } = this.state;
  //     var a = [], diff = [];
  //     for (var i = 0; i < prev.length; i++) {
  //         a[prev[i]] = true;
  //     }

  //     for (var i = 0; i < countries.length; i++) {
  //         if (a[countries[i]]) {
  //             delete a[countries[i]];
  //         } else {
  //             a[countries[i]] = true;
  //         }
  //     }

  //     for (var k in a) {
  //         diff.push(k);

  //     }
  //     if (this.state.countries.length < countries.length) {
  //         await citiesList.forEach((city) => {
  //             if (city.country.id === diff[0]) {
  //                 cities.push(city.id)
  //             }
  //         });
  //         cities = _.uniq(cities)
  //     } else {
  //         await citiesList.forEach((city, index) => {
  //             if (city.country.id === diff[0]) {
  //                 cities.forEach((element, index) => {
  //                     if (element === city.id) {
  //                         cities.splice(index, 1)
  //                     }
  //                 });
  //             }
  //         });

  //     }
  //     await this.setState({ countries, cities })
  // }

  // setCities(value) {
  //     const { citiesList } = this.state;
  //     let countries = []
  //     for (let i = 0; i < citiesList.length; i++) {
  //         for (let j = 0; j < value.length; j++) {
  //             if (citiesList[i].id === value[j]) {
  //                 countries.push(citiesList[i].country.id)
  //             }
  //         }
  //     }
  //     countries = _.uniqBy(countries);
  //     this.setState({ cities: value, countries })
  // }

  sendData() {
    let { name, arb_name, appId, cities } = this.state;
    let it = this;
    const text = "Validation Error! Please check fields";
    this.setState({
      nameError: false,
      arb_nameError: false,
      cityError: false,
      appError: false,
      // countryError: false
    });

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster(text);
      return;
    }
    // if (countries.length < 1) {
    //     this.setState({ countryError: true });
    //     errorToaster(text);
    //     return;
    // }
    if (cities.length < 1) {
      this.setState({ cityError: true });
      errorToaster(text);
      return;
    }
    if (!appId) {
      this.setState({ appError: true });
      errorToaster(text);
      return;
    }
    appId = [appId];
    this.setState({ loading: true });
    PartnerModel.getInstance().addPartner(
      name,
      arb_name,
      cities,
      appId,
      function success(data) {
        successNotification("Partner Created Successfully");
        setTimeout(() => {
          window.location.href = "/partner";
        }, 1000);
      },
      function failure(error) {
        errorNotification(error);
        console.log(error);
        it.setState({ loading: false });
      }
    );
  }

  render() {
    const { nameError, arb_nameError, citiesList, cityError, apps, appError } =
      this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <Spin spinning={this.state.loading}>
          <div className="kt-content  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">Add Partner</h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/partner"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="70"
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter name in English
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Arabic Name
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="100"
                                    onChange={(event) =>
                                      this.setState({
                                        arb_name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: arb_nameError ? "red" : "",
                                      }}
                                    >
                                      Please enter name in Arabic
                                    </p>
                                  </span>
                                </div>
                              </div>

                              {/* <div className="form-group row">
                                                                <label className="col-3 col-form-label" htmlFor="marchantGroup">Countries</label>
                                                                <div className="col-9">

                                                                    <Select
                                                                        showSearch
                                                                        mode="multiple"
                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                        value={this.state.countries}
                                                                        // placeholder="Select a merchant group"
                                                                        onChange={(countries) => this.setState({ countries })}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {countriesList.map((country) => (
                                                                            <Option key={country.id} value={country.id} >{country.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                    <span className="form-text text-muted"><p style={{ color: countryError ? 'red' : '' }}>Please choose Country/Countries</p></span>

                                                                </div>
                                                            </div> */}

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="marchantGroup"
                                >
                                  Cities <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.cities}
                                    onChange={(cities) =>
                                      this.setState({ cities })
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {citiesList.map((city) => (
                                      <Option key={city.id} value={city.id}>
                                        {city.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: cityError ? "red" : "" }}
                                    >
                                      Please choose City/Cities
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="marchantGroup"
                                >
                                  App Id <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    // mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.appId}
                                    onChange={(appId) =>
                                      this.setState({ appId })
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {apps.map((app) => (
                                      <Option key={app.id} value={app.id}>
                                        {app.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p style={{ color: appError ? "red" : "" }}>
                                      Please choose atlease One App ID
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
