export function validateBookingOfferConfigParams(params) {
    if (params.discount_amount <= 0) return {
        status: false,
        message: 'Please Enter Discount Amount and It should be greater than 0'
    };
    if (params.product_booking_id.length < 1) return {
        status: false,
        message: 'Please Select Product Bookings'
    };
    return {
        status: true,
        message: "OK"
    }

}
export function validateOfferParams(params) {
    if (params?.name.length < 1) return {
        status: false,
        message: 'Please Enter Offer Name'
    };
    if (params?.description.length < 1) return {
        status: false,
        message: 'Please Enter Offer Description'
    };
    if (!params?.offer_count || params?.offer_count < 1) return {
        status: false,
        message: 'Please Enter Offer Count and It should be greater than 0'
    };
    if (!params?.end_date) return {
        status: false,
        message: 'Please Enter Offer End Date'
    };
    if (!params?.product_visibility_id) return {
        status: false,
        message: 'Please Enter Offer Product Visibility'
    };
    if (params?.program_id.length < 1) return {
        status: false,
        message: 'Please Enter Program'
    };
    if (!params?.merchant_id) return {
        status: false,
        message: 'Please Enter Merchant'
    };
    if (params?.outlet_id.length < 1) return {
        status: false,
        message: 'Please Enter Outlets'
    };
    return {
        status: true,
        message: "OK"
    };
}