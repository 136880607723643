import React from "react";
import { Icon, Popconfirm, Switch, Select, Spin } from "antd";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { errorNotification, errorToaster } from "../Shared/Notifications";
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import { Link } from "react-router-dom";
import ClassificationModel from "../../Models/ClassificationModel";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class AppEcommerce extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      countryList: [],
      appList: [],
      loading: false,
      filters: [],
      pageCount: 10,
      offset: 0,
      total_pages: 1,
      currentPage: 1,
      classificationMessage: ''
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  createPagination(total_count) {
    this.setState({
      total_pages: Math.ceil(total_count / this.state.pageCount),
    });
  }

  componentDidMount() {
    this.searchClassifications();
  }

  searchClassifications() {
    const it = this;
    it.setState({ loading: true });
    let params = {
      offset: (this.state.currentPage - 1) * this.state.pageCount,
      limit: this.state.pageCount,
    };
    this.state.filters.forEach((filter, i) => {
      params[filter.id] = filter.value;
    });
    switch (params["is_active"]) {
      case "true":
        params["is_active"] = true;
        break;

      case "false":
        params["is_active"] = false;
        break;

      default:
        delete params["is_active"];
        break;
    }
    ClassificationModel.getInstance().searchClassifications(
      params,
      function success(data) {
        it.setState({
          data: ClassificationModel.ClassificationList,
          loading: false,
        });
        it.createPagination(data.count);
      },
      function Failure(error) {
        it.setState({ loading: false });
        errorToaster("Request information not found");
      }
    );
  }

  updateClassificationActivation(id, is_active) {
    const it = this;
    it.setState({ loading: true });
    const params = {
      is_active: !is_active,
    };
    ClassificationModel.getInstance().activateClassification(
      id,
      params,
      function success() {
        it.setState({
          data: ClassificationModel.ClassificationList,
          loading: false,
        });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  removeClassification(id) {
    const it = this;
    it.setState({ loading: true });
    ClassificationModel.getInstance().removeClassification(
      id,
      function success() {
        it.setState({
          data: ClassificationModel.ClassificationList,
          loading: false,
        });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  getClassificationOutletMerchantCount(id, is_active) {
    if (!is_active) {
      this.setState({ classificationMessage: "Are you sure want to activate this Classification?" });
      this.makePopConfirmVisible(id, true)
      return
    }
    const it = this;
    it.setState({ loading: true });
    ClassificationModel.getInstance().getClassificationOutletMerchantCount(
      id,
      function success(data) {
        if (data.outlet_count || data.merchant_count) {
          it.setState({
            classificationMessage: `The system will unlink classification from ${data.outlet_count} outlets belonging to ${data.merchant_count} merchants`,
          });
        } else {
          this.setState({ classificationMessage: "Are you sure want to DE-ACTIVATE this Classification?" });
        }

        it.makePopConfirmVisible(id, true)
      },
      function failure(error) {
        it.makePopConfirmVisible(id, true)
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  makePopConfirmVisible(id, is_visible) {
    ClassificationModel.ClassificationList.forEach(_class => {
      if (_class.id === id) {
        _class.showPopConfirm = is_visible
      }
    });
    this.setState({ data: ClassificationModel.ClassificationList })
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.searchClassifications();
  }, 1000);

  create_icon(row) {
    return row._original.icon_library === "materialIcons" &&
      row._original.icon ? (
      <div className="col-3">
        <span
          style={{ color: row._original.icon_color, fontSize: 24 }}
          className="material-icons"
        >
          {row._original.icon}
        </span>
      </div>
    ) : row._original.icon_library === "materialCommunityIcons" && row._original.icon ? (
      <div className="col-3">
        <MaterialCommunityIcons name={row._original.icon} size={24} color={row._original.icon_color} />
      </div>
    ) : null;
  }

  render() {
    const { data } = this.state;

    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              {gridHeaderWithButton(
                "Classifications",
                "la la-plus",
                "Add Classification",
                "classifications/add"
              )}
            </div>
            <div className="kt-portlet__body">
              <Spin spinning={this.state.loading}>
                {/* react-table */}
                <ReactTable
                  data={data}
                  filterable
                  pageSize={this.state.pageCount}
                  showPageSizeOptions={false}
                  manual
                  page={this.state.currentPage - 1}
                  pages={this.state.total_pages}
                  filtered={this.state.filters}
                  onPageChange={(pageIndex) => {
                    this.setState({ currentPage: pageIndex + 1 });
                    setTimeout(() => {
                      this.searchClassifications();
                    }, 50);
                  }}
                  onFilteredChange={(filters) => {
                    this.setState({ currentPage: 1, filters });
                    this.searchQuery(this);
                  }}
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  columns={[
                    {
                      Header: <p style={tableHeaderStyles()}>Name</p>,
                      id: "name",
                      accessor: (d) => d.name,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["name"] }),
                      filterAll: true,
                      filterable: true,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Arabic Name</p>,
                      id: "arabic_name",
                      accessor: (d) => d.arabic_name,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: ["arabic_name"],
                        }),
                      filterAll: true,
                      filterable: true,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Icon Library</p>,
                      id: "icon_library",
                      accessor: (d) => d.icon_library,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: ["icon_library"],
                        }),
                      filterAll: true,
                      filterable: true,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Icon Name</p>,
                      id: "icon",
                      accessor: (d) => d.icon,
                      Cell: ({ value, row }) => (
                        <div style={{ display: "flex" }}>
                          {this.create_icon(row)}
                        </div>
                      ),
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["icon"] }),
                      filterAll: true,
                      filterable: false,
                    },

                    {
                      Header: <p style={tableHeaderStyles()}>Status</p>,
                      id: "is_active",
                      accessor: "is_active",
                      Cell: ({ value }) =>
                        value ? (
                          <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                            Active
                          </span>
                        ) : (
                          <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                            De-Activate
                          </span>
                        ),
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true;
                        }
                        if (filter.value === "true") {
                          return row[filter.id] === true;
                        }
                        return row[filter.id] === false;
                      },
                      Filter: ({ filter, onChange }) => (
                        <Select
                          onChange={(value) => onChange(value)}
                          style={{ width: "100%" }}
                          defaultValue="all"
                          value={filter ? filter.value : "all"}
                        >
                          <Option value="all">Show All</Option>
                          <Option value="true">Active</Option>
                          <Option value="false">De-Activate</Option>
                        </Select>
                      ),
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Action</p>,
                      id: "actions",
                      width: 150,
                      accessor: (d) => (
                        <div>
                          <Link
                            to={`classifications/edit/${d.id}`}
                            style={{ marginRight: 20 }}
                          >
                            <Icon
                              style={{ color: "#5D78FF", fontSize: 16 }}
                              type="edit"
                            />
                          </Link>
                          <Popconfirm
                            title={`Are you sure you want to delete this classification`}
                            icon={
                              <Icon
                                type="question-circle-o"
                                style={{ color: "red" }}
                              />
                            }
                            onConfirm={() => this.removeClassification(d.id)}
                          >
                            <Icon
                              style={{ color: "#5D78FF", fontSize: 16 }}
                              type="delete"
                            />
                          </Popconfirm>
                          <Popconfirm
                            visible={d.showPopConfirm}
                            onCancel={() => {
                              this.makePopConfirmVisible(d.id, false)
                              this.setState({ loading: false })
                            }
                            }
                            title={this.state.classificationMessage}
                            icon={
                              <Icon
                                type="question-circle-o"
                                style={{ color: "red" }}
                              />
                            }
                            onConfirm={() => {
                              this.makePopConfirmVisible(d.id, false)
                              this.updateClassificationActivation(
                                d.id,
                                d.is_active
                              )
                            }
                            }
                          >
                            <Switch
                              style={{
                                backgroundColor: d.is_active
                                  ? "#5d78ff"
                                  : "#fd397a",
                                marginLeft: 15,
                              }}
                              onChange={() => this.getClassificationOutletMerchantCount(d.id, d.is_active)}
                              checked={d.is_active}
                            />
                          </Popconfirm>
                        </div>
                      ),
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </Spin>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
