import React from "react";

import Content from "./Content";

// import Header from '../Shared/Header';
// import SideMenu from '../Shared/SideMenu';
// import Footer from '../Shared/Footer';
// import UserModel from '../../Models/UserModel';

export default class Merchant extends React.Component {
  render() {
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <Content />
      </div>
    );
  }
}
