import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel';



export default class AppPartnerProductPricingModel {
    static myInstance = null;
    static getInstance() {
        if (AppPartnerProductPricingModel.myInstance === null) {
            AppPartnerProductPricingModel.myInstance = new AppPartnerProductPricingModel();
        }
        return this.myInstance;
    }


    addPartnerProductPricing(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addPartnerProductPricing(token, params,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    getPartnerProductPricings(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getPartnerProductPricings(token, params,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getPartnerProductPricingDetail(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getPartnerProductPricingDetail(token, id,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updatePartnerProductPricing(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updatePartnerProductPricing(token, id, params,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    deletePartnerProductPricing(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().deletePartnerProductPricing(token, id,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

}