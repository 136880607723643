import React from "react";
import { Icon, Spin, Popconfirm, Select, Switch } from "antd";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import { errorToaster } from "../Shared/Notifications";
import ReactTable from "react-table";
import "react-table/react-table.css";
import EcommerceOfferModel from "../../Models/EcommerceOfferModel";
import {
  infoNotification,
  errorNotification,
} from "../Shared/Notifications";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      totalPages: 0,
      filters: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getEcommerceOffers();
  }

  getEcommerceOffers() {
    const it = this;
    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });

    if (params["offers.is_active"] === "all") {
      delete params["offers.is_active"];
    }
    if (this.props.is_outlet) {
      params["outlets.id"] = this.props.outlet_id;
    }
    it.setState({ loading: true });
    EcommerceOfferModel.getInstance().searchEcommerceOffer(
      params,
      function success(data) {
        it.setState({
          loading: false,
          data: EcommerceOfferModel.getInstance().EcommerceOfferList,
          totalPage: data.offers.last_page,
          perPage: data.offers.per_page,
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorToaster("Request information not found");
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getEcommerceOffers();
  }, 1000);

  removeEcommerceOffer(ecommerceOfferId) {
    const it = this;
    it.setState({ loading: true });
    EcommerceOfferModel.getInstance().removeEcommerceOffer(
      ecommerceOfferId,
      function success() {
        it.setState({
          data: EcommerceOfferModel.getInstance().EcommerceOfferList,
        });
        it.setState({ loading: false });
        infoNotification("Ecommerce Offer Successfully Deleted");
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  ecommerceOfferActivation(ecommerceOfferId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = !is_active;
    let params = {
      is_active: activation,
    };
    EcommerceOfferModel.getInstance().ecommerceOfferActivation(
      ecommerceOfferId,
      params,
      function success() {
        it.setState({
          data: EcommerceOfferModel.getInstance().EcommerceOfferList,
        });
        it.setState({ loading: false });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  render() {
    const { data } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <Spin spinning={this.state.loading}>
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-portlet__head kt-portlet__head--lg">
                {gridHeaderWithButton(
                  "Delivery Offers",
                  "la la-plus",
                  "Add Delivery Offer",
                  this.props.is_outlet ? `/ecommerce-offer/add?outlet_id=${this.props.outlet_id}` : "/ecommerce-offer/add"
                )}
              </div>
              <div className="kt-portlet__body">
                {/* react-table */}
                <ReactTable
                  data={data}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  pageSize={20}
                  showPageSizeOptions={false}
                  manual
                  page={this.state.currentPage - 1}
                  pages={this.state.totalPages}
                  filtered={this.state.filters}
                  onPageChange={(pageIndex) => {
                    this.setState({ currentPage: pageIndex + 1 });
                    setTimeout(() => {
                      this.getEcommerceOffers();
                    }, 50);
                  }}
                  onFilteredChange={(filters) => {
                    this.setState({ currentPage: 1, filters });
                    this.searchQuery(this);
                  }}
                  columns={[
                    {
                      Header: <p style={tableHeaderStyles()}>Name</p>,
                      id: "offers.name",
                      accessor: (d) => d.name,
                      style: { whiteSpace: "unset" },
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["name"] }),
                      filterAll: true,
                      sortable: false,
                      filterable: true,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Merchant</p>,
                      id: "merchants.name",
                      accessor: (d) => d.merchant.merchant_name,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: [(item) => item.merchant.merchant_name],
                        }),
                      filterAll: true,
                      sortable: false,
                      filterable: !this.props.is_outlet,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Outlet </p>,
                      id: "outlets.name",
                      accessor: (d) =>
                        d.merchant.outlets.map((outlet, key) => (
                          <p
                            className="btn btn-label-info btn-pill"
                            style={{
                              float: "left",
                              margin: "2px",
                              padding: "5px 10px",
                              fontSize: "11px",
                            }}
                            key={key}
                          >
                            {outlet.name}
                          </p>
                        )),
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["outlets"] }),
                      filterAll: true,
                      sortable: false,
                      filterable: true,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Programs</p>,
                      id: "programs.name",
                      accessor: (d) =>
                        d.programs.map((program, key) => (
                          <p
                            className="btn btn-label-info btn-pill"
                            style={{
                              float: "left",
                              margin: "2px",
                              padding: "5px 10px",
                              fontSize: "11px",
                            }}
                            key={key}
                          >
                            {program.name}
                          </p>
                        )),
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: [
                            (item) =>
                              item.programs.map(
                                (program) => program.props.children
                              ),
                          ],
                        }),
                      filterAll: true,
                      sortable: false,
                      filterable: true,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Exclusion </p>,
                      id: "offers.exclusion",
                      accessor: (d) => d.exclusion,
                      style: { whiteSpace: "unset" },
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["exclusion"] }),
                      filterAll: true,
                      sortable: false,
                      filterable: true,
                    },
                    {
                      Header: (
                        <p style={tableHeaderStyles()}>Estimated savings </p>
                      ),
                      id: "offers.offer_value ",
                      accessor: (d) => d.offer_value,
                      style: { whiteSpace: "unset" },
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: ["offer_value"],
                        }),
                      filterAll: true,
                      sortable: false,
                      filterable: true,
                    },
                    {
                      Header: (
                        <p style={tableHeaderStyles()}>Redemption count </p>
                      ),
                      id: "offers.redemption_count",
                      accessor: "offer_count",
                      style: { whiteSpace: "unset" },
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: ["offer_count"],
                        }),
                      filterAll: true,
                      sortable: false,
                      filterable: true,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Special Offer</p>,
                      width: 120,
                      id: "offers.is_special",
                      accessor: "is_special",
                      Cell: ({ value }) =>
                        value ? (
                          <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                            Special
                          </span>
                        ) : (
                          <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                            Regular
                          </span>
                        ),
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true;
                        }
                        if (filter.value == "true") {
                          return row[filter.id] == true;
                        }
                        return row[filter.id] == false;
                      },
                      Filter: ({ filter, onChange }) =>
                      (
                        <Select
                          onChange={(value) => onChange(value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <Option value="all">Show all</Option>
                          <Option value="true">Special</Option>
                          <Option value="false">Regular</Option>
                        </Select>
                      ),
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Status</p>,
                      id: "offers.is_active",
                      accessor: "is_active",
                      Cell: ({ value }) =>
                        value ? (
                          <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                            Active
                          </span>
                        ) : (
                          <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                            De-Activate
                          </span>
                        ),
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true;
                        }
                        if (filter.value == "true") {
                          return row[filter.id] == true;
                        }
                        return row[filter.id] == false;
                      },
                      Filter: ({ filter, onChange }) =>
                      (
                        <Select
                          onChange={(value) => onChange(value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <Option value="all">Show all</Option>
                          <Option value="true">Active</Option>
                          <Option value="false">De-Activate</Option>
                        </Select>
                      ),
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Actions</p>,
                      id: "actions",
                      width: 150,
                      Filter: (
                        <span
                          onClick={() => {
                            if (this.state.filters.length === 0) {
                            } else {
                              this.setState({
                                filters: [],
                                currentPage: 1,
                              });
                              setTimeout(() => {
                                this.getEcommerceOffers();
                              }, 100);
                            }
                          }}
                          className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill"
                        >
                          Reset-Filters
                        </span>
                      ),
                      accessor: (d) => (
                        <div>
                          <Link
                            to={`/ecommerce-offer/edit/${d.id}`}
                            style={{ marginRight: 20 }}
                          >
                            <Icon
                              style={{ color: "#5D78FF", fontSize: 16 }}
                              type="edit"
                            />
                          </Link>
                          <Popconfirm
                            title="Are you sure you want to delete this ecommerce offer?"
                            icon={
                              <Icon
                                type="question-circle-o"
                                style={{ color: "red" }}
                              />
                            }
                            onConfirm={() => this.removeEcommerceOffer(d.id)}
                          >
                            <Icon
                              style={{ color: "#5D78FF", fontSize: 16 }}
                              type="delete"
                            />
                          </Popconfirm>
                          <Popconfirm
                            title={
                              d.is_active
                                ? "Are you sure you want to DEACTIVATE this ecommerce offer"
                                : "Are you sure you want to ACTIVATE this ecommerce offer"
                            }
                            icon={
                              <Icon
                                type="question-circle-o"
                                style={{ color: "red" }}
                              />
                            }
                            onConfirm={() =>
                              this.ecommerceOfferActivation(d.id, d.is_active)
                            }
                          >
                            <Switch
                              style={{
                                backgroundColor: d.is_active
                                  ? "#5d78ff"
                                  : "#fd397a",
                                marginLeft: 15,
                              }}
                              checked={d.is_active}
                              onChange={(val) => console.log(val)}
                            />
                          </Popconfirm>
                        </div>
                      ),
                      sortable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
