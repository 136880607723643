import ApiManager from "../Helpers/Network/Api";
import UserModel from "./UserModel";

export default class SharedModel {
  static myInstance = null;
  static getInstance() {
    if (SharedModel.myInstance === null) {
      SharedModel.myInstance = new SharedModel();
    }
    return this.myInstance;
  }

  static RedemptionReportList = [];
  static PaymentReportList = [];
  static EcommerceSectionList = [];
  static AppAdBannerList = [];
  static AppVisibilityList = [];
  static ProductVisiblityList = [];

  getRedemptionReport(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getRedemptionReport(
      token,
      params,
      function resSuccess(data) {
        SharedModel.getInstance().RedemptionReportList = data.data;
        successTrigger(data);
      },
      function failure(error) {
        failureTrigger(error);
      }
    );
  }

  downloadReport(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().downloadReport(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function failure(error) {
        failureTrigger(error);
      }
    );
  }

  getPaymentReport(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();

    ApiManager.getInstance().getPaymentReport(
      token,
      params,
      function resSuccess(data) {
        SharedModel.getInstance().PaymentReportList = data.data;
        successTrigger(data);
      },
      function failure(error) {
        failureTrigger(error);
      }
    );
  }

  // for payment report activation
  paymentReportActivation(payment_id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();

    ApiManager.getInstance().paymentReportActivation(
      token,
      payment_id,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function failure(error) {
        failureTrigger(error);
      }
    );
  }

  searchReport(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();

    ApiManager.getInstance().searchReport(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function failure(error) {
        failureTrigger(error);
      }
    );
  }

  getAllNotifications(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();

    ApiManager.getInstance().getAllNotifications(
      token,
      params,
      function resSuccess(data) {
        SharedModel.getInstance().NotificationList = data.notification.data;
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  addNotification(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();

    ApiManager.getInstance().addNotification(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  sendNotification(notificationId, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().sendNotification(
      token,
      notificationId,
      function resSuccess() {
        SharedModel.getInstance().NotificationList.forEach((notification) => {
          if (notification.id === notificationId) {
            notification.is_send = true;
            successTrigger();
          }
        });
      },
      function resFailed(msg) {
        failureTrigger(msg);
      }
    );
  }

  getNotificationDetail(notificationId, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getNotificationDetail(
      token,
      notificationId,
      function resSuccess(data) {
        successTrigger(data.notification);
      },
      function resFailed(msg) {
        failureTrigger(msg);
      }
    );
  }

  updateNotification(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();

    ApiManager.getInstance().updateNotification(
      token,
      id,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  searchNotification(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();

    ApiManager.getInstance().searchNotification(
      token,
      params,
      function resSuccess(data) {
        SharedModel.getInstance().NotificationList = data.notification.data;
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  // Static Pages

  getPages(successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();

    ApiManager.getInstance().getPages(
      token,
      function resSuccess(data) {
        successTrigger(data.pages);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getPageDetail(pageId, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();

    ApiManager.getInstance().getPageDetail(
      token,
      pageId,
      function resSuccess(data) {
        successTrigger(data.page);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  updatePage(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();

    ApiManager.getInstance().updatePage(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  addMediaInstance(id, instance, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().addMediaInstance(
      token,
      id,
      instance,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getAllCurrency(successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getAllCurrency(
      token,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getAllAppIds(successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getAllAppIds(
      token,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  updateOutletStatus(outletId, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateOutletStatus(
      token,
      outletId,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getEcommerceSections(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getEcommerceSections(
      token,
      params,
      function resSuccess(data) {
        SharedModel.EcommerceSectionList = data.records;
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  updateEcommerceSection(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateEcommerceSection(
      token,
      id,
      params,
      function resSuccess(data) {
        for (let i = 0; i < SharedModel.EcommerceSectionList.length; i++) {
          if (SharedModel.EcommerceSectionList[i].id === id) {
            SharedModel.EcommerceSectionList[i].is_active = params.is_active;
            break;
          }
        }
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  searchAppBanner(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().searchAppBanner(
      token,
      params,
      function resSuccess(data) {
        SharedModel.AppAdBannerList = data.records;
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  addAppBanner(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().addAppBanner(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }
  addBulkAppBanner(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().addBulkAppBanner(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }
  addAppBannerImage(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().addAppBannerImage(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  updateAppBannerImage(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateAppBannerImage(
      token,
      id,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  removeAppBannerImage(id, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().removeAppBannerImage(
      token,
      id,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  activateAppBanner(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().activateAppBanner(
      token,
      id,
      params,
      function resSuccess(data) {
        for (let i = 0; i < SharedModel.AppAdBannerList.length; i++) {
          if (SharedModel.AppAdBannerList[i].id === id) {
            SharedModel.AppAdBannerList[i].is_active = params.is_active;
            break;
          }
        }
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  updateAppBanner(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateAppBanner(
      token,
      id,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getAppBannerDetail(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getAppBannerDetail(
      token,
      id,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getAllAppVisibilities(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getAllAppVisibilities(
      token,
      params,
      function resSuccess(data) {
        SharedModel.AppVisibilityList = data.records;
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  activateAppVisibility(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateAppVisibility(
      token,
      id,
      params,
      function resSuccess(data) {
        for (let i = 0; i < SharedModel.AppVisibilityList.length; i++) {
          if (SharedModel.AppVisibilityList[i].id === id) {
            SharedModel.AppVisibilityList[i].is_active = params.is_active;
            break;
          }
        }
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  addAppVisibility(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().addAppVisibility(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  // Product visibility settings

  getAllProductVisibilities(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getAllProductVisibilities(
      token,
      params,
      function resSuccess(data) {
        SharedModel.ProductVisiblityList = data.records;
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getProductVisibilityDetail(id, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getProductVisibilityDetail(
      token,
      id,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  addProductVisibility(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().addProductVisibility(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  updateProductVisibility(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateProductVisibility(
      token,
      id,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  activateProductVisibility(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateProductVisibility(
      token,
      id,
      params,
      function resSuccess(data) {
        for (let i = 0; i < SharedModel.ProductVisiblityList.length; i++) {
          if (SharedModel.ProductVisiblityList[i].id === id) {
            SharedModel.ProductVisiblityList[i].is_active = params.is_active;
            break;
          }
        }
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  deleteProductVisibility(id, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().deleteProductVisibility(
      token,
      id,
      function resSuccess(data) {
        SharedModel.ProductVisiblityList.forEach((visibility, index) => {
          if (visibility.id === id) {
            SharedModel.ProductVisiblityList.splice(index, 1);
            successTrigger(data);
          }
        });
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getDefaultProductVisibility(successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    const params = { is_default: true };
    ApiManager.getInstance().getAllProductVisibilities(
      token,
      params,
      function resSuccess(data) {
        if (data.count > 0) successTrigger(data.records[0]);
        else failureTrigger("No Default Product Visibility Found");
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  //
  addProductVisibilityConfig(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().addProductVisibilityConfig(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }
  getProductVisibilityConfigs(id, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getProductVisibilityConfigs(
      token,
      id,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }
  removeProductVisibilityConfig(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().removeProductVisibilityConfig(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }
  updateProductVisibilityConfig(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateProductVisibilityConfig(
      token,
      id,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  // VODAFONE SUPPORT

  getAppUserProfile(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getAppUserProfile(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  updateAllVodafoneSupport(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateAllVodafoneSupport(
      token,
      id,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }
}
