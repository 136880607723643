import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel';



export default class OpeningHourModel {
    static myInstance = null;
    static getInstance() {
        if (OpeningHourModel.myInstance === null) {
            OpeningHourModel.myInstance = new OpeningHourModel();
        }
        return this.myInstance;
    }
    static OpeningHourList = [];
    static OpeningHourConfigsList = [];


    addOpeningHour(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addOpeningHour(token, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    getAllOpeningHours(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getAllOpeningHours(token, params,
            function successCallback(data) {
                OpeningHourModel.OpeningHourList = data.records
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    getOpeningHourDetail(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOpeningHourDetail(token, id,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    updateOpeningHour(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateOpeningHour(token, id, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    activateOpeningHour(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateOpeningHour(token, id, params,
            function successCallback(data) {
                for (let i = 0; i < OpeningHourModel.OpeningHourList.length; i++) {
                    if (OpeningHourModel.OpeningHourList[i].id === id) {
                        OpeningHourModel.OpeningHourList[i].is_active = params.is_active;
                        break;
                    }
                }
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    removeOpeningHour(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeOpeningHour(token, id,
            function successCallback(data) {
                OpeningHourModel.OpeningHourList.forEach((openingHour, index) => {
                    if (openingHour.id === id) {
                        OpeningHourModel.OpeningHourList.splice(index, 1);
                        successTrigger(data)
                    }
                });
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }

    getDefaultOpeningHour(successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        const params = { is_default: true }
        ApiManager.getInstance().getAllOpeningHours(token, params,
            function resSuccess(data) {
                if (data.count > 0) successTrigger(data.records[0]);
                else failureTrigger("No Default Opening Hour Found");
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    addOpeningHourConfig(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addOpeningHourConfig(token, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    updateOpeningHourConfig(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateOpeningHourConfig(token, id, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    removeOpeningHourConfig(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeOpeningHourConfig(token, id,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }

}