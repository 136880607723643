import React, { useState, useCallback, useEffect } from 'react';
import _ from "lodash";
import {
    Select,
    Spin,
    Tabs,
    DatePicker,
    Input
} from 'antd';

import {
    successNotification,
    errorNotification,
    errorToaster
} from '../Shared/Notifications';

import { DetailPageHeaderWithButton } from "styles/detailPageHeader";


import ProgramModel from 'Models/ProgramModel';
import MerchantModel from "Models/MerchantModel";
import { validateBookingOfferConfigParams, validateOfferParams } from "./helperFunctions";
import OfferModel from "Models/OfferModel";
import SharedModel from "Models/SharedModel";
import ProductBookingModel from "Models/ProductBookingModel";
import moment from "moment";

const { Option } = Select;
const { TabPane } = Tabs;


export default function AddProductBookingOffer() {
    const
        [loading, setLoading] = useState(false),
        [name, setName] = useState(""),
        [arabicName, setArabicName] = useState(""),
        [description, setDescription] = useState(""),
        [arabicDescription, setArabicDescription] = useState(""),
        [offerCount, setOfferCount] = useState(null),
        [averageRevenue, setAverageRevenue] = useState(null),
        [typeId] = useState(5),
        [offerType] = useState(3),
        [endDate, setEndDate] = useState(null),
        [productVisibilityId, setProductVisibilityId] = useState(null),
        [productVisibilityList, setProductVisibilityList] = useState([]),
        [programId, setProgramId] = useState([]),
        [programList, setProgramList] = useState([]),
        [merchantId, setMerchantId] = useState(null),
        [merchantList, setMerchantList] = useState([]),
        [outletId, setOutletId] = useState([]),
        [outletList, setOutletList] = useState([]),
        [offerCriteria, setOfferCriteria] = useState([]),
        [offerCriteriaList, setOfferCriteriaList] = useState([]),

        // PRODUCT BOOKING OFFER CONFIG DATA
        [amountDiscount, setAmountDiscount] = useState(null),
        [productBookingId, setProductBookingId] = useState([]),
        [productBookingList, setProductBookingList] = useState([]);

    useEffect(() => {
        getOfferCriterias()
    }, [])


    async function getOfferCriterias() {
        OfferModel.getInstance().getOfferCriteria(
            function success() {
                setOfferCriteriaList(OfferModel.getInstance().OfferCriteriaList)
            },
            function failure(error) {
                errorNotification(error)
            }
        )
    }

    async function getAllProductBookingsByOutlets(outlet_id) {
        const params = {
            outlet_id
        };
        ProductBookingModel.getInstance().getProductBookingByOutlets(params,
            function success(data) {
                setProductBookingList(data);
                setLoading(false)
            },
            function failure(error) {
                errorNotification(error)
                setLoading(false)

            }
        )
    }

    const getSearchProductVisibility = useCallback(_.debounce(value => getAllProductVisibilities(value), 500), []);
    async function getAllProductVisibilities(value) {
        if (value.length > 0) {
            const params = {
                name: encodeURIComponent(value)
            };
            SharedModel.getInstance().getAllProductVisibilities(params,
                function success() {
                    setProductVisibilityList(SharedModel.ProductVisiblityList);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)
                }
            )
        }
    }



    const getSearchPrograms = useCallback(_.debounce(value => getAllPrograms(value), 500), []);
    async function getAllPrograms(value) {
        if (value.length > 0) {
            const params = {
                'programs.name': encodeURIComponent(value)
            };
            ProgramModel.getInstance().searchProgram(params,
                function success(data) {
                    setProgramList(data.data);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)
                }
            )
        }
    }

    const getSearchMerchant = useCallback(_.debounce(value => getMerchants(value), 500), []);
    async function getMerchants(value) {
        setLoading(true);
        if (value.length > 0) {
            let params = {
                query: encodeURIComponent(value)
            }
            MerchantModel.getInstance().getMerchant(params,
                function success(data) {
                    setLoading(false);
                    setMerchantList(data.data)
                },
                function failure(error) {
                    setLoading(false);
                    errorNotification(error)
                }
            )
        }
    }

    async function getOutlets(merchant) {
        setLoading(true)
        setOutletList([])
        MerchantModel.getInstance().getMerchantOutlet(merchant,
            function success(data) {
                setLoading(false);
                setOutletList(data)
            },
            function failure(error) {
                setLoading(false);
                errorNotification(error)
                console.log(error)
            }
        )
    }


    const addProductBookingOffer = () => {

        const product_booking_offer_config = {
            discount_amount: Number(amountDiscount),
            product_booking_id: productBookingId,
        }

        const configValidateResults = validateBookingOfferConfigParams(product_booking_offer_config);
        if (configValidateResults.status === false) {
            errorToaster(configValidateResults.message)
            return;
        }

        const params = {
            name,
            arabic_name: arabicName,
            description,
            arabic_description: arabicDescription,
            offer_count: offerCount,
            average_revenue: averageRevenue,
            type_id: typeId,
            offer_type: offerType,
            offer_value: Number(amountDiscount),
            end_date: moment(endDate).endOf('day').tz('Asia/Qatar').format('YYYY-MM-DD hh:mm:ss'),
            product_visibility_id: productVisibilityId,
            program_id: programId,
            merchant_id: merchantId,
            outlet_id: outletId,
            criteria_id: offerCriteria,
            product_booking_offer_config: productBookingId.map(_pb => ({
                discount_amount: Number(amountDiscount),
                product_booking_id: _pb
            }))
        };
        const validateResults = validateOfferParams(params);
        if (validateResults.status === false) {
            errorToaster(validateResults.message)
            return;
        }
        OfferModel.getInstance().addOffer(params,
            function success() {
                successNotification('Booking Offer Created');
                setTimeout(() => {
                    setLoading(false);
                    window.location.href = '/booking-offer'
                }, 2000);
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                <Spin spinning={loading} >

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                <div className="kt-portlet__head kt-portlet__head--lg">
                                    <DetailPageHeaderWithButton
                                        title={`Add Product Booking Offer`}
                                        buttonOneIcon={"la la-arrow-left"}
                                        buttonOneCallBack={() => window.location.href = '/booking-offer'}
                                        buttonTwoIcon={"la la-check"}
                                        buttonTwoCallBack={() => addProductBookingOffer()}
                                    />
                                </div>

                                <div className="kt-portlet__body">
                                    <form className="kt-form" id="kt_form">
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-8">

                                                <div className="kt-section kt-section--first">

                                                    <div className="kt-section__body">

                                                        <Tabs defaultActiveKey="overview" >
                                                            <TabPane tab="Overview" key="overview">
                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Name <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Input maxLength={100} value={name} onChange={(event) => setName(event.target.value)} className="form-control" type="text" />
                                                                        <span className="form-text text-muted"><p>Please Enter Product Booking Offer Name</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Arabic Name</label>
                                                                    <div className="col-9">
                                                                        <Input maxLength={100} value={arabicName} onChange={(event) => setArabicName(event.target.value)} className="form-control" type="text" />
                                                                        <span className="form-text text-muted"><p>Please Enter Product Booking Offer Arabic Name</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Description</label>
                                                                    <div className="col-9">
                                                                        <Input.TextArea maxLength={500} value={description} onChange={(event) => setDescription(event.target.value)} className="form-control" type="text" />
                                                                        <span className="form-text text-muted"><p>Please Enter Description</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Arabic Description</label>
                                                                    <div className="col-9">
                                                                        <Input.TextArea maxLength={500} value={arabicDescription} onChange={(event) => setArabicDescription(event.target.value)} className="form-control" type="text" />
                                                                        <span className="form-text text-muted"><p>Please Enter Arabic Description</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Offer Criteria</label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            showSearch
                                                                            mode="multiple"
                                                                            style={{ margin: '10px 0px 10px 0px' }}
                                                                            value={offerCriteria}
                                                                            onChange={(offerCriteria) => setOfferCriteria(offerCriteria)}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {offerCriteriaList.map((criteria) => (
                                                                                <Option key={criteria.id} value={criteria.id} >{criteria.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted">Please Select Offer Criteria</span>

                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Offer Count <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Input maxLength={5} value={offerCount} onChange={(event) => setOfferCount(event.target.value)} className="form-control" type="number" />
                                                                        <span className="form-text text-muted"><p>Please Enter Offer Count</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Average Revenue <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Input maxLength={10} value={averageRevenue} onChange={(event) => setAverageRevenue(event.target.value)} className="form-control" type="number" />
                                                                        <span className="form-text text-muted"><p>Please Enter Offer Average Revenue</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Offer End Date <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <DatePicker
                                                                            onChange={(date) => setEndDate(date)}
                                                                            allowClear={false}
                                                                            className="form-control"
                                                                            value={endDate}
                                                                            style={{ padding: "0px", border: '0px solid black' }}
                                                                        />
                                                                        <span className="form-text text-muted"><p>Please Select Offer End Date</p></span>
                                                                    </div>
                                                                </div>
                                                            </TabPane>

                                                            <TabPane tab="Program Merchants" key="program_merchant">

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Programs <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            showSearch
                                                                            mode="multiple"
                                                                            onSearch={(val) => getSearchPrograms(val)}
                                                                            optionFilterProp="children"
                                                                            onChange={(programId) => setProgramId(programId)}
                                                                            value={programId}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {programList.map(prog => (
                                                                                <Option key={prog.id} value={prog.id} >{prog.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted"><p>Please Select Programs</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Merchant <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            showSearch
                                                                            onSearch={(val) => getSearchMerchant(val)}
                                                                            optionFilterProp="children"
                                                                            onChange={(merchantId) => {
                                                                                setMerchantId(merchantId)
                                                                                getOutlets(merchantId)
                                                                            }}
                                                                            value={merchantId}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {merchantList.map(merch => (
                                                                                <Option key={merch.id} value={merch.id} >{merch.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted"><p>Please Select Merchant</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Outlets <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            showSearch
                                                                            mode="multiple"
                                                                            optionFilterProp="children"
                                                                            onChange={(outletId) => {
                                                                                setOutletId(outletId)
                                                                                getAllProductBookingsByOutlets(outletId)
                                                                            }}
                                                                            value={outletId}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {outletList.map(outlet => (
                                                                                <Option key={outlet.id} value={outlet.id} >{outlet.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted"><p>Please Select Outlets</p></span>
                                                                    </div>
                                                                </div>
                                                            </TabPane>

                                                            <TabPane tab="Product Booking Section" key="product_booking_config">

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Product Visibility</label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            showSearch
                                                                            onSearch={(val) => getSearchProductVisibility(val)}
                                                                            optionFilterProp="children"
                                                                            onChange={(productVisibilityId) => setProductVisibilityId(productVisibilityId)}
                                                                            value={productVisibilityId}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {productVisibilityList.map(_pv => (
                                                                                <Option key={_pv.id} value={_pv.id} >{_pv.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted"><p>Please Select Product Visibility</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Discount Amount <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Input maxLength={10} value={amountDiscount} onChange={(event) => setAmountDiscount(event.target.value)} className="form-control" type="number" />
                                                                        <span className="form-text text-muted"><p>Please Enter Discount Amount</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Product Bookings</label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            showSearch
                                                                            mode="multiple"
                                                                            optionFilterProp="children"
                                                                            onChange={(productBookingId) => setProductBookingId(productBookingId)}
                                                                            value={productBookingId}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {productBookingList.map(_pbl => (
                                                                                <Option key={_pbl.id} value={_pbl.id} >{_pbl.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted"><p>Please Select Product Bookings</p></span>
                                                                    </div>
                                                                </div>

                                                            </TabPane>
                                                        </Tabs>





                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div >

    )
}