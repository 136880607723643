import React from "react";
import { Icon, Spin, Popconfirm, Select, Switch } from "antd";
import matchSorter from "match-sorter";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import CouponModel from "../../Models/CouponModel";
import { errorNotification } from "../Shared/Notifications";
import downloadCSV from "../Shared/CsvDownloader";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      totalPage: 1,
      perPage: 20,
      filters: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getCoupon();
  }
  getCoupon() {
    const it = this;
    it.setState({ loading: true });
    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });
    if (params["coupons.is_active"] === "all") {
      delete params["coupons.is_active"];
    }

    CouponModel.getInstance().searchCoupon(
      params,
      function success(data) {
        it.setState({
          data: CouponModel.getInstance().CouponList,
          loading: false,
          perPage: data.per_page,
          totalPage: data.last_page,
          currentPage: data.current_page,
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorNotification("Request information not found");
        console.log(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getCoupon();
  }, 1000);

  getCodes(couponId, name) {
    let it = this;
    it.setState({ loading: true });
    CouponModel.getInstance().getCouponDetail(
      couponId,
      function success(data) {
        it.setState({ loading: false });
        it.getCSV(data, name);
        console.log(data);
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  getCSV(data, name) {
    let newArr = [];
    data.codes.forEach((code) => {
      let obj = {};
      // obj.Coupon = data.name;
      obj.Code = code.code;
      obj.Usage = code.usage;
      newArr.push(obj);
    });
    downloadCSV({ filename: name }, newArr);
  }

  couponActivation(couponId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = is_active === true ? false : true;
    let params = {};
    params.is_active = activation;
    CouponModel.getInstance().couponActivation(
      couponId,
      params,
      function success() {
        it.setState({
          data: CouponModel.getInstance().CouponList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={this.state.loading}>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              {gridHeaderWithButton(
                "Coupons",
                "la la-plus",
                "Add New Coupon",
                "/coupon/add"
              )}
            </div>
            <div className="kt-portlet__body">
              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={20}
                // showPageJump={false}
                showPageSizeOptions={false}
                manual
                filtered={this.state.filters}
                page={this.state.currentPage - 1}
                pages={this.state.totalPage}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getCoupon();
                  }, 50);
                }}
                onFilteredChange={(filters) => {
                  console.log("filters: ", filters);
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                columns={[
                  {
                    Header: <p style={tableHeaderStyles()}>ID</p>,
                    id: "coupons.id",
                    accessor: (d) => d.id,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["id"] }),
                    filterAll: true,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    id: "coupons.name",
                    accessor: (d) => d.name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Prefix</p>,
                    id: "coupons.prefix",
                    accessor: (d) => d.prefix,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["prefix"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Program</p>,
                    id: "programs.name",
                    accessor: (d) => d.program_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["program_name"],
                      }),
                    filterAll: true,
                  },
                  // {
                  //     Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Cities</p>,
                  //     id: "cities",
                  //     accessor: d => d.cities.map((city, key) => <p style={{marginVertical:'1px'}} key={key}>{city.name}</p>),
                  //     filterMethod: (filter, rows) =>
                  //         matchSorter(rows, filter.value, { keys: [item => item.cities.map((city) => city.props.children)] }),
                  //     filterAll: true
                  // },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "coupons.is_active",
                    accessor: (d) => d.is_active,
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "true") {
                        return row[filter.id] === true;
                      }
                      return row[filter.id] === false;
                    },
                    Filter: ({ filter, onChange }) => (
                      <Select
                        onChange={(value) => onChange(value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <Option value="all">Show All</Option>
                        <Option value="true">Active</Option>
                        <Option value="false">De-Activate</Option>
                      </Select>
                    ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 150,
                    accessor: (d) => (
                      <div>
                        <Link
                          to={`coupon/edit/${d.id}`}
                          style={{ marginRight: 20 }}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="edit"
                          />
                        </Link>
                        <Popconfirm
                          title="Are you sure you want to download coupon codes?"
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.getCodes(
                              d.id,
                              `Program-${d.program_name} Coupon-${d.name}.csv`
                            )
                          }
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="download"
                          />
                        </Popconfirm>
                        <Popconfirm
                          title={
                            d.is_active
                              ? "Are you sure you want to DEACTIVATE this coupon"
                              : "Are you sure you want to ACTIVATE this coupon"
                          }
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.couponActivation(d.id, d.is_active)
                          }
                        >
                          <Switch
                            style={{
                              backgroundColor: d.is_active
                                ? "#5d78ff"
                                : "#fd397a",
                              marginLeft: 15,
                            }}
                            checked={d.is_active}
                            onChange={(val) => console.log(val)}
                          />
                        </Popconfirm>
                      </div>
                    ),
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
