import React from "react";

import {
    Select,
    Input,
    Icon,
} from "antd";
import { validateURL, validateYoutubeUrl } from "../../Helpers/Shared/Validators";


const { Option } = Select;

export const RenderRouteIdList = ({
    route, setCategoryId, callSubCategories, setSubCategoryList,
    setSubCategoryId, categoryId, categoryList, subCategoryId, subCategoryList, searchQueryCollection, setCollectionId, collectionId,
    collectionList, setMerchantId, merchantId, searchQueryMerchant, merchantList, setEcomMerchantId, ecomMerchantId, searchQueryEcommerceMerchant,
    ecomMerchantList, setOnlineDealMerchantId, onlineDealMerchantId, searchQueryOnlineDealMerchant, onlineDealMerchantList,
    getOnlineDealByMerchantId, setOnlineDealId, onlineDealId, onlineDealList, productBookingOutletId, searchQueryProductBookingOutlet,
    productBookingOutletList, setProductBookingOutletId, getProductBookingByOutletId, setProductBookingId, productBookingId, productBookingList,
    setB1g1OutletId, b1g1OutletId, searchQueryB1G1Outlets, b1g1OutletList, getB1G1OffersByOutletId, setB1g1OfferId, b1g1OfferId, b1g1OfferList,
    externalUrl, setExternalUrl, videoUrl, setVideoUrl
}) => {
    switch (route) {
        case "categories":
            return (
                <>
                    <div className="form-group row">
                        <label className="col-3 col-form-label">Category <b>*</b></label>
                        <div className="col-9">

                            <Select
                                showSearch
                                optionFilterProp="children"
                                onChange={(val) => {
                                    setCategoryId(val)
                                    callSubCategories(val)
                                    setSubCategoryId(null)
                                    setSubCategoryList([])
                                }
                                }
                                value={categoryId}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {categoryList.map(cat => (
                                    <Option disabled={!cat.is_active} key={cat.id} value={cat.id} >{`${cat.name} - ${cat.is_active ? 'Active' : 'De-Active'} - ${cat.id}`}</Option>
                                ))}
                            </Select>
                            <span className="form-text text-muted"><p style={{}}>Please Select Category</p></span>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-3 col-form-label">Sub Category</label>
                        <div className="col-9">

                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                onChange={(val) => {
                                    setSubCategoryId(val)
                                }
                                }
                                value={subCategoryId}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {subCategoryList.map(cat => (
                                    <Option disabled={!cat.is_active} key={cat.id} value={cat.id} >{`${cat.name} - ${cat.is_active ? 'Active' : 'De-Active'} - ${cat.id}`}</Option>
                                ))}
                            </Select>
                            <span className="form-text text-muted"><p style={{}}>Please Select Sub Category</p></span>
                        </div>
                    </div>
                </>
            );
        case "collections":
            return (
                <div className="form-group row">
                    <label className="col-3 col-form-label">Collection <b>*</b></label>
                    <div className="col-9">

                        <Select
                            showSearch
                            optionFilterProp="children"
                            onSearch={(val) => searchQueryCollection(val)}
                            onChange={(val) => setCollectionId(val)}
                            value={collectionId}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {collectionList.map(cat => (
                                <Option disabled={!cat.is_active} key={cat.id} value={cat.id} >{`${cat.name} - ${cat.is_active ? 'Active' : 'De-Active'} - ${cat.id}`}</Option>
                            ))}
                        </Select>
                        <span className="form-text text-muted"><p style={{}}>Please Select Collection</p></span>
                    </div>
                </div>
            );

        case "merchants":
            return (
                <div className="form-group row">
                    <label className="col-3 col-form-label">Merchant <b>*</b></label>
                    <div className="col-9">

                        <Select
                            showSearch
                            optionFilterProp="children"
                            onChange={(val) => setMerchantId(val)}
                            value={merchantId}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            notFoundContent={
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                    <p style={{ fontSize: 14 }}>Please Search Merchant by Name</p>
                                </div>
                            }
                            onSearch={(val) => searchQueryMerchant(val)}
                        >
                            {merchantList.map(merch => (
                                <Option disabled={!merch.is_active} key={merch.id} value={merch.id} >{`${merch.name} - ${merch.is_active ? 'Active' : 'De-Active'} - ${merch.id}`}</Option>
                            ))}
                        </Select>
                        <span className="form-text text-muted"><p style={{}}>Please Select Merchant</p></span>
                    </div>
                </div>
            );

        case "delivery_merchants":
            return (
                <div className="form-group row">
                    <label className="col-3 col-form-label">Delivery Merchants <b>*</b></label>
                    <div className="col-9">

                        <Select
                            showSearch
                            optionFilterProp="children"
                            onChange={(val) => setEcomMerchantId(val)}
                            value={ecomMerchantId}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            notFoundContent={
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                    <p style={{ fontSize: 14 }}>Please Search Merchant by Name</p>
                                </div>
                            }
                            onSearch={(val) => searchQueryEcommerceMerchant(val)}
                        >
                            {ecomMerchantList.map(merch => (
                                <Option disabled={!merch.is_active} key={merch.id} value={merch.id} >{`${merch.name} - ${merch.is_active ? 'Active' : 'De-Active'} - ${merch.id}`}</Option>
                            ))}
                        </Select>
                        <span className="form-text text-muted"><p style={{}}>Please Select Merchant</p></span>
                    </div>
                </div>
            );

        case "online_deal_merchant":
            return (
                <div className="form-group row">
                    <label className="col-3 col-form-label">Online Deal Merchants <b>*</b></label>
                    <div className="col-9">

                        <Select
                            showSearch
                            optionFilterProp="children"
                            onChange={(val) => setOnlineDealMerchantId(val)}
                            value={onlineDealMerchantId}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            notFoundContent={
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                    <p style={{ fontSize: 14 }}>Please Search Merchant by Name</p>
                                </div>
                            }
                            onSearch={(val) => searchQueryOnlineDealMerchant(val)}
                        >
                            {onlineDealMerchantList.map(merch => (
                                <Option disabled={!merch.is_active} key={merch.id} value={merch.id} >{`${merch.name} - ${merch.is_active ? 'Active' : 'De-Active'} - ${merch.id}`}</Option>
                            ))}
                        </Select>
                        <span className="form-text text-muted"><p style={{}}>Please Select Merchant</p></span>
                    </div>
                </div>
            );

        case "online_deal_offer":
            return (
                <>
                    <div className="form-group row">
                        <label className="col-3 col-form-label">Online Deal Merchants <b>*</b></label>
                        <div className="col-9">
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onChange={(val) => {
                                    setOnlineDealMerchantId(val)
                                    getOnlineDealByMerchantId(val)
                                }}
                                value={onlineDealMerchantId}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                notFoundContent={
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                        <p style={{ fontSize: 14 }}>Please Search Merchant by Name</p>
                                    </div>
                                }
                                onSearch={(val) => searchQueryOnlineDealMerchant(val)}
                            >
                                {onlineDealMerchantList.map(merch => (
                                    <Option disabled={!merch.is_active} key={merch.id} value={merch.id} >{`${merch.name} - ${merch.is_active ? 'Active' : 'De-Active'} - ${merch.id}`}</Option>
                                ))}
                            </Select>
                            <span className="form-text text-muted"><p style={{}}>Please Select Merchant</p></span>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-3 col-form-label">Online Deals <b>*</b></label>
                        <div className="col-9">
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onChange={(val) => setOnlineDealId(val)}
                                value={onlineDealId}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                notFoundContent={
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                        <p style={{ fontSize: 14 }}>Please Search Merchant by Name</p>
                                    </div>
                                }
                            >
                                {onlineDealList.map(deal => (
                                    <Option disabled={!deal.is_active} key={deal.id} value={deal.id} >{`${deal.name} - ${deal.is_active ? 'Active' : 'De-Active'} - ${deal.id}`}</Option>
                                ))}
                            </Select>
                            <span className="form-text text-muted"><p style={{}}>Please Select Online Deal</p></span>
                        </div>
                    </div>

                </>
            );

        case "product_booking_outlet":
            return (
                <div className="form-group row">
                    <label className="col-3 col-form-label">Product Booking Outlet <b>*</b></label>
                    <div className="col-9">

                        <Select
                            showSearch
                            optionFilterProp="children"
                            onChange={(val) => setProductBookingOutletId(val)}
                            value={productBookingOutletId}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            notFoundContent={
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                    <p style={{ fontSize: 14 }}>Please Search Outlet by Name</p>
                                </div>
                            }
                            onSearch={(val) => searchQueryProductBookingOutlet(val)}
                        >
                            {productBookingOutletList.map(outlet => (
                                <Option disabled={!outlet.is_active} key={outlet.id} value={outlet.id} >{`${outlet.name} - ${outlet.is_active ? 'Active' : 'De-Active'} - ${outlet.id}`}</Option>
                            ))}
                        </Select>
                        <span className="form-text text-muted"><p style={{}}>Please Select Outlet</p></span>
                    </div>
                </div>
            );

        case "product_booking_offer":
            return (
                <>
                    <div className="form-group row">
                        <label className="col-3 col-form-label">Product Booking Outlet <b>*</b></label>
                        <div className="col-9">
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onChange={(val) => {
                                    setProductBookingOutletId(val)
                                    getProductBookingByOutletId(val)
                                }}
                                value={productBookingOutletId}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                notFoundContent={
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                        <p style={{ fontSize: 14 }}>Please Search Outlet by Name</p>
                                    </div>
                                }
                                onSearch={(val) => searchQueryProductBookingOutlet(val)}
                            >
                                {productBookingOutletList.map(outlet => (
                                    <Option disabled={!outlet.is_active} key={outlet.id} value={outlet.id} >{`${outlet.name} - ${outlet.is_active ? 'Active' : 'De-Active'} - ${outlet.id}`}</Option>
                                ))}
                            </Select>
                            <span className="form-text text-muted"><p style={{}}>Please Select Outlet</p></span>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-3 col-form-label">Product Bookings <b>*</b></label>
                        <div className="col-9">
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onChange={(val) => setProductBookingId(val)}
                                value={productBookingId}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                notFoundContent={
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                        <p style={{ fontSize: 14 }}>Please Search Merchant by Name</p>
                                    </div>
                                }
                            >
                                {productBookingList.map(booking => (
                                    <Option disabled={!booking.is_active} key={booking.id} value={booking.id} >{`${booking.name} - ${booking.is_active ? 'Active' : 'De-Active'} - ${booking.id}`}</Option>
                                ))}
                            </Select>
                            <span className="form-text text-muted"><p style={{}}>Please Select Product Booking</p></span>
                        </div>
                    </div>

                </>
            );


        case "b1g1_outlet":
            return (
                <div className="form-group row">
                    <label className="col-3 col-form-label">Outlets <b>*</b></label>
                    <div className="col-9">

                        <Select
                            showSearch
                            optionFilterProp="children"
                            onChange={(val) => setB1g1OutletId(val)}
                            value={b1g1OutletId}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            notFoundContent={
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                    <p style={{ fontSize: 14 }}>Please Search Outlet by Name</p>
                                </div>
                            }
                            onSearch={(val) => searchQueryB1G1Outlets(val)}
                        >
                            {b1g1OutletList.map(outlet => (
                                <Option disabled={!outlet.is_active} key={outlet.id} value={outlet.id} >{`${outlet.name} - ${outlet.is_active ? 'Active' : 'De-Active'} - ${outlet.id}`}</Option>
                            ))}
                        </Select>
                        <span className="form-text text-muted"><p style={{}}>Please Select Outlet</p></span>
                    </div>
                </div>
            );

        case "b1g1_offer":
            return (
                <>
                    <div className="form-group row">
                        <label className="col-3 col-form-label">Outlets <b>*</b></label>
                        <div className="col-9">
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onChange={(val) => {
                                    setB1g1OutletId(val)
                                    getB1G1OffersByOutletId(val)
                                }}
                                value={b1g1OutletId}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                notFoundContent={
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                        <p style={{ fontSize: 14 }}>Please Search Outlet by Name</p>
                                    </div>
                                }
                                onSearch={(val) => searchQueryB1G1Outlets(val)}
                            >
                                {b1g1OutletList.map(outlet => (
                                    <Option disabled={!outlet.is_active} key={outlet.id} value={outlet.id} >{`${outlet.name} - ${outlet.is_active ? 'Active' : 'De-Active'} - ${outlet.id}`}</Option>
                                ))}
                            </Select>
                            <span className="form-text text-muted"><p style={{}}>Please Select Outlet</p></span>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-3 col-form-label">Buy-1 Get-1 Offers <b>*</b></label>
                        <div className="col-9">
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onChange={(val) => setB1g1OfferId(val)}
                                value={b1g1OfferId}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                notFoundContent={
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                        <p style={{ fontSize: 14 }}>Please Search Merchant by Name</p>
                                    </div>
                                }
                            >
                                {b1g1OfferList.map(offer => (
                                    <Option disabled={!offer.is_active} key={offer.id} value={offer.id} >{`${offer.name} - ${offer.is_active ? 'Active' : 'De-Active'} - ${offer.id}`}</Option>
                                ))}
                            </Select>
                            <span className="form-text text-muted"><p style={{}}>Please Select Offer</p></span>
                        </div>
                    </div>

                </>
            );

        case "external":
            return (
                <div className="form-group row">
                    <label className="col-3 col-form-label">External Url <b>*</b></label>
                    <div className="col-9">
                        <Input maxLength={255} value={externalUrl} onChange={(event) => setExternalUrl(event.target.value)} className="form-control" type="text" />
                        <span className="form-text text-muted"><p style={{}}>Please Enter external url</p></span>
                    </div>
                </div>
            )
        case "youtube":
            return (
                <div className="form-group row">
                    <label className="col-3 col-form-label">Youtube URL <b>*</b></label>
                    <div className="col-9">
                        <Input maxLength={500} value={videoUrl} onChange={(event) => setVideoUrl(event.target.value)} className="form-control" type="text" />
                        <span className="form-text text-muted"><p style={{}}>Please Enter valid Youtube url</p></span>
                    </div>
                </div>
            )

        default:
            return null
    }
}

export const checkRouteValidation = (
    mainBannerRoute, categoryId, collectionId, merchantId,
    ecomMerchantId, onlineDealMerchantId, onlineDealId,
    productBookingOutletId, productBookingId,
    b1g1OutletId, b1g1OfferId,
    videoUrl, externalUrl,
) => {
    switch (mainBannerRoute) {
        case "categories":
            if (!categoryId) {
                return {
                    status: false,
                    message: "Please select any Category"
                }
            }
            break;

        case "collections":
            if (!collectionId) {
                return {
                    status: false,
                    message: "Please select any collection"
                }
            }
            break;

        case "merchants":
            if (!merchantId) {
                return {
                    status: false,
                    message: "Please select any merchant"
                }
            }
            break;

        case "delivery_merchants":
            if (!ecomMerchantId) {
                return {
                    status: false,
                    message: "Please select any merchant"
                }
            }
            break;

        case "delivery_offers":
        case "online_deal_section":
        case "program_purchase":
        case "product_booking":
            break;

        case "online_deal_merchant":
            if (!onlineDealMerchantId) {
                return {
                    status: false,
                    message: "Please select Merchant"
                }
            }
            break;

        case "online_deal_offer":
            if (!onlineDealMerchantId || !onlineDealId) {
                return {
                    status: false,
                    message: "Please select Merchant Or Online Deal"
                }
            }
            break;

        case "product_booking_outlet":
            if (!productBookingOutletId) {
                return {
                    status: false,
                    message: "Please select Outlet"
                }
            }
            break;

        case "product_booking_offer":
            if (!productBookingOutletId || !productBookingId) {
                return {
                    status: false,
                    message: "Please select Outlet or Product Booking"
                }
            }
            break;

        case "b1g1_outlet":
            if (!b1g1OutletId) {
                return {
                    status: false,
                    message: "Please select Outlet"
                }
            }
            break;

        case "b1g1_offer":
            if (!b1g1OutletId || !b1g1OfferId) {
                return {
                    status: false,
                    message: "Please select Outlet or Offer"
                }
            }
            break;

        case "youtube":
            if (!validateYoutubeUrl(videoUrl)) {
                return {
                    status: false,
                    message: "Invalid youtube Url"
                }
            }
            break;

        default:
            if (!validateURL(externalUrl)) {
                return {
                    status: false,
                    message: "Please enter valid URL"
                }
            }
            break;
    }
    return {
        status: true,
    }
}

export const refactorParamRoute = (
    params, categoryId, subCategoryId, collectionId,
    merchantId, ecomMerchantId, onlineDealMerchantId,
    onlineDealId, productBookingOutletId, productBookingId,
    b1g1OutletId, b1g1OfferId, videoUrl, externalUrl
) => {
    switch (params.route) {
        case "categories":
            params["category_id"] = categoryId
            if (subCategoryId) params["sub_category_id"] = subCategoryId
            break;

        case "collections":
            params["collection_id"] = collectionId
            break;

        case "merchants":
            params["merchant_id"] = merchantId
            break;

        case "delivery_merchants":
            params["merchant_id"] = ecomMerchantId
            break;

        case "delivery_offers":
        case "online_deal_section":
        case "program_purchase":
        case "product_booking":
            break;

        case "online_deal_merchant":
            params["online_deal_merchant_id"] = onlineDealMerchantId
            break;

        case "online_deal_offer":
            params["online_deal_merchant_id"] = onlineDealMerchantId
            params["online_deal_id"] = onlineDealId
            break;

        case "product_booking_outlet":
            params["product_booking_outlet_id"] = productBookingOutletId
            break;

        case "product_booking_offer":
            params["product_booking_outlet_id"] = productBookingOutletId
            params["product_booking_id"] = productBookingId
            break;

        case "b1g1_outlet":
            params["b1g1_outlet_id"] = b1g1OutletId
            break;

        case "b1g1_offer":
            params["b1g1_outlet_id"] = b1g1OutletId
            params["b1g1_offer_id"] = b1g1OfferId
            break;

        case "youtube":
            params["video_url"] = videoUrl
            break;

        default:
            params["external_url"] = externalUrl
            break;
    }
    return params
}

