import React from "react";
import { Icon, Spin, Popconfirm, Select, Switch } from "antd";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";

import ReactTable from "react-table";
import "react-table/react-table.css";
import PartnerModel from "../../Models/PartnerModel";
import { errorNotification, errorToaster } from "../Shared/Notifications";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      totalPage: 0,
      perPage: 0,
      filters: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getPartner();
  }

  getPartner() {
    const it = this;
    it.setState({ loading: true });
    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter, i) => {
      if (filter.id === "name") {
        params["partners.name"] = filter.value.toLowerCase();
      }
    });
    PartnerModel.getInstance().searchPartner(
      params,
      function success() {
        it.setState({
          data: PartnerModel.getInstance().PartnerList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
        errorToaster("Request information not found");
      }
    );
  }

  deletePartner(partnerId) {
    const it = this;
    it.setState({ loading: true });
    PartnerModel.getInstance().removePartner(
      partnerId,
      function success() {
        it.setState({
          data: PartnerModel.getInstance().PartnerList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }
  partnerActivation(partnerId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = is_active === true ? false : true;
    let params = {};
    params.is_active = activation;
    PartnerModel.getInstance().partnerActivation(
      partnerId,
      params,
      function success() {
        it.setState({
          data: PartnerModel.getInstance().PartnerList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getPartner();
  }, 1000);

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={this.state.loading}>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              {gridHeaderWithButton(
                "Partners",
                "la la-plus",
                "Add Partner",
                "/partner/add"
              )}

            </div>
            <div className="kt-portlet__body">
              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={20}
                showPageSizeOptions={false}
                manual
                // page={this.state.currentPage - 1}
                // pages={this.state.totalPage}
                filtered={this.state.filters}
                // onPageChange={(pageIndex) => {
                //     this.setState({ currentPage: pageIndex + 1 })
                //     setTimeout(() => {
                //         this.getPartner()
                //     }, 50);
                // }}
                onFilteredChange={(filters) => {
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                columns={[
                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    accessor: "name",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Arabic name</p>,
                    accessor: "arabic_name",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["arabic_name"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Cities</p>,
                    id: "cities",
                    accessor: (d) =>
                      d.cities.map((city, key) => (
                        <p
                          className="btn btn-label-info btn-pill"
                          style={{
                            float: "left",
                            margin: "2px",
                            padding: "5px 10px",
                            fontSize: "11px",
                          }}
                          key={key}
                        >
                          {city.name}
                        </p>
                      )),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: [
                          (item) =>
                            item.cities.map((city) => city.props.children),
                        ],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "is_active",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "true") {
                        return row[filter.id] === true;
                      }
                      return row[filter.id] === false;
                    },
                    Filter: ({ filter, onChange }) => (
                      <Select
                        onChange={(value) => onChange(value)}
                        style={{ width: "100%" }}
                        defaultValue="all"
                      >
                        <Option value="all">Show All</Option>
                        <Option value="true">Active</Option>
                        <Option value="false">De-Activate</Option>
                      </Select>
                    ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 150,
                    accessor: (d) => (
                      <div>
                        <Link
                          to={`partner/edit/${d.id}`}
                          style={{ marginRight: 20 }}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="edit"
                          />
                        </Link>
                        <Popconfirm
                          title="Are you sure you want to delete this partner?"
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() => this.deletePartner(d.id)}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="delete"
                          />
                        </Popconfirm>
                        <Popconfirm
                          title={
                            d.is_active
                              ? "Are you sure you want to DEACTIVATE this partner"
                              : "Are you sure you want to ACTIVATE this partner"
                          }
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.partnerActivation(d.id, d.is_active)
                          }
                        >
                          <Switch
                            style={{
                              backgroundColor: d.is_active
                                ? "#5d78ff"
                                : "#fd397a",
                              marginLeft: 15,
                            }}
                            checked={d.is_active}
                            onChange={(val) => console.log(val)}
                          />
                        </Popconfirm>
                      </div>
                    ),
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
