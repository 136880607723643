import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel';



/**
 * 
 * HERE TYPES STANDS FOR CONTACT TYPE BETWEEN 0 to 3
 * 0: Buy One Get One offers (Outlet Restricted)
 * 1: Delivery offers (Outlet Restricted)
 * 2: Online Deal (Merchant Restricted)
 * 3: Online Booking (Outlet Restricted)
 */

export default class ProductOfferingModel {
    static myInstance = null;
    static getInstance() {
        if (ProductOfferingModel.myInstance === null) {
            ProductOfferingModel.myInstance = new ProductOfferingModel();
        }
        return this.myInstance;
    }

    static OutletProductOfferingPocList = [];
    static MerchantProductOfferingPocList = [];
    static NewContactPocObject = {
        email: '', phone: '', un_saved: true, is_new: true
    }
    static Buy1Get1Object = {
        contact_name: '',
        type: 0,
        is_active: false,
        outlet_id: null,
        merchant_id: null,
        product_offering_poc_contacts: [
            // { ...ProductOfferingModel.NewContactPocObject }
        ],
        is_new: true,
        is_saved: false
    }
    static DeliveryOffers = {
        contact_name: '',
        type: 1,
        is_active: false,
        outlet_id: null,
        merchant_id: null,
        product_offering_poc_contacts: [
            // { ...ProductOfferingModel.NewContactPocObject }
        ],
        is_new: true,
        is_saved: false
    }
    static OnlineDeals = {
        contact_name: '',
        type: 2,
        is_active: false,
        outlet_id: null,
        merchant_id: null,
        product_offering_poc_contacts: [
            // { ...ProductOfferingModel.NewContactPocObject }
        ],
        is_new: true,
        is_saved: false
    }
    static OnlineBookings = {
        contact_name: '',
        type: 3,
        is_active: false,
        outlet_id: null,
        merchant_id: null,
        product_offering_poc_contacts: [
            // { ...ProductOfferingModel.NewContactPocObject }
        ],
        is_new: true,
        is_saved: false
    }


    updateMainValue(key, value, type) {
        switch (type) {
            case 0:
                ProductOfferingModel.Buy1Get1Object[key] = value;
                ProductOfferingModel.Buy1Get1Object.is_saved = key === 'is_active' ? true : false;
                break;

            case 1:
                ProductOfferingModel.DeliveryOffers[key] = value;
                ProductOfferingModel.DeliveryOffers.is_saved = key === 'is_active' ? true : false;
                break;

            case 2:
                ProductOfferingModel.OnlineDeals[key] = value;
                ProductOfferingModel.OnlineDeals.is_saved = key === 'is_active' ? true : false;
                break;

            case 3:
                ProductOfferingModel.OnlineBookings[key] = value;
                ProductOfferingModel.OnlineBookings.is_saved = key === 'is_active' ? true : false;
                break;

            default:
                break;
        }
    }

    updateSubValue(key, value, type, index) {
        switch (type) {
            case 0:
                ProductOfferingModel.Buy1Get1Object['product_offering_poc_contacts'][index][key] = value;
                ProductOfferingModel.Buy1Get1Object['product_offering_poc_contacts'][index]['un_saved'] = true;
                break;

            case 1:
                ProductOfferingModel.DeliveryOffers['product_offering_poc_contacts'][index][key] = value;
                ProductOfferingModel.DeliveryOffers['product_offering_poc_contacts'][index]['un_saved'] = true;
                break;

            case 2:
                ProductOfferingModel.OnlineDeals['product_offering_poc_contacts'][index][key] = value;
                ProductOfferingModel.OnlineDeals['product_offering_poc_contacts'][index]['un_saved'] = true;
                break;

            case 3:
                ProductOfferingModel.OnlineBookings['product_offering_poc_contacts'][index][key] = value;
                ProductOfferingModel.OnlineBookings['product_offering_poc_contacts'][index]['un_saved'] = true;
                break;

            default:
                break;
        }
    }

    saveContact(type, successTrigger, failureTrigger) {
        const params = { ...this.getNewObject(type) },
            it = this;
        if (params.is_new) {
            this.addProductOfferingPocs(params,
                function successCallback(params) {
                    it.updateMainValue('is_new', false, type)
                    it.updateMainValue('is_saved', true, type)
                    it.updateMainValue('id', params.id, type)
                    it.updateProductOfferingContactValues(type)
                    successTrigger();
                },
                function failureCallback(err) {
                    failureTrigger();
                }
            )
        } else {
            this.updateProductOffering(params, params.id,
                function successCallback(params) {
                    it.updateMainValue('is_saved', true, type);
                    successTrigger();
                },
                function failureCallback(err) {
                    failureTrigger(err)
                }
            )
        }
    }

    getNewObject(type) {
        switch (type) {
            case 0:
                return ProductOfferingModel.Buy1Get1Object;

            case 1:
                return ProductOfferingModel.DeliveryOffers;

            case 2:
                return ProductOfferingModel.OnlineDeals;

            case 3:
                return ProductOfferingModel.OnlineBookings;

            default:
                return 0
        }
    }

    updateProductOfferingContactValues(type) {
        switch (type) {
            case 0:
                return ProductOfferingModel.Buy1Get1Object.product_offering_poc_contacts.forEach(val => {
                    val.is_new = false;
                    val.un_saved = false;
                });

            case 1:
                return ProductOfferingModel.DeliveryOffers.product_offering_poc_contacts.forEach(val => {
                    val.is_new = false;
                    val.un_saved = false;
                });

            case 2:
                return ProductOfferingModel.OnlineDeals.product_offering_poc_contacts.forEach(val => {
                    val.is_new = false;
                    val.un_saved = false;
                });

            case 3:
                return ProductOfferingModel.OnlineBookings.product_offering_poc_contacts.forEach(val => {
                    val.is_new = false;
                    val.un_saved = false;
                });

            default:
                return 0
        }
    }


    addContactPoc(type) {
        switch (type) {
            case 0:
                ProductOfferingModel.Buy1Get1Object['product_offering_poc_contacts'].push({ ...ProductOfferingModel.NewContactPocObject });
                break;

            case 1:
                ProductOfferingModel.DeliveryOffers['product_offering_poc_contacts'].push({ ...ProductOfferingModel.NewContactPocObject });
                break;

            case 2:
                ProductOfferingModel.OnlineDeals['product_offering_poc_contacts'].push({ ...ProductOfferingModel.NewContactPocObject });
                break;

            case 3:
                ProductOfferingModel.OnlineBookings['product_offering_poc_contacts'].push({ ...ProductOfferingModel.NewContactPocObject });
                break;

            default:
                break;
        }
    }

    removeContactPoc(id, index, type, successTrigger, failureTrigger) {
        const it = this;
        if (!id) {
            this.removeContactPocFromData(index, type);
            setTimeout(() => {
                successTrigger();
            }, 0);
        } else {
            this.deleteProductOfferingContact(id,
                function successCallback(data) {
                    it.removeContactPocFromData(index, type);
                    setTimeout(() => {
                        successTrigger();
                    }, 0);
                },
                function failureCallback(err) {
                    failureTrigger(err)
                }
            )
        }
    }

    saveContactPoc(id, index, type, successTrigger, failureTrigger) {
        const { product_offering_poc_contacts, id: product_offering_poc_id } = this.getNewObject(type),
            params = product_offering_poc_contacts[index],
            it = this;
        if (!id) {
            this.addProductOfferingContact({ ...params, product_offering_poc_id },
                function successCallback(data) {
                    it.updateSubValue('un_saved', false, type, index)
                    it.updateSubValue('is_new', false, type, index)
                    it.updateSubValue('id', data.id, type, index)
                    setTimeout(() => {
                        successTrigger();
                    }, 0);
                },
                function failureCallback(err) {
                    failureTrigger(err)
                }
            )
        } else {
            this.updateProductOfferingContact(id, params,
                function successCallback(data) {
                    it.updateSubValue('un_saved', false, type, index)
                    it.updateSubValue('is_new', false, type, index)
                    setTimeout(() => {
                        successTrigger();
                    }, 0);
                },
                function failureCallback(err) {
                    failureTrigger(err)
                }
            )
        }
    }

    addProductOfferingContact(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addProductOfferingContact(token, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }

        )
    }

    updateProductOfferingContact(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateProductOfferingContact(token, id, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }

        )
    }

    deleteProductOfferingContact(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().deleteProductOfferingContact(token, id,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }

        )
    }

    removeContactPocFromData(index, type) {
        switch (type) {
            case 0:
                ProductOfferingModel.Buy1Get1Object['product_offering_poc_contacts'].splice(index, 1);
                break;

            case 1:
                ProductOfferingModel.DeliveryOffers['product_offering_poc_contacts'].splice(index, 1);
                break;

            case 2:
                ProductOfferingModel.OnlineDeals['product_offering_poc_contacts'].splice(index, 1);
                break;

            case 3:
                ProductOfferingModel.OnlineBookings['product_offering_poc_contacts'].splice(index, 1);
                break;

            default:
                break;
        }
    }


    updateActivation(id, is_active, type, successTrigger, failureTrigger) {
        const it = this;
        if (!id) {
            this.updateMainValue('is_active', is_active, type);
            setTimeout(() => {
                successTrigger();
            }, 0);
        } else {
            const params = { is_active };
            this.updateProductOffering(params, id,
                function successCallback(params) {
                    it.updateMainValue('is_active', is_active, type);
                    successTrigger();
                },
                function failureCallback(err) {
                    failureTrigger(err)
                }
            )


        }
    }

    updateProductOffering(params, id, successCallback, failureCallback) {
        const token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateProductOfferingPocsByMerchant(token, id, params,
            function successTrigger(data) {
                setTimeout(() => {
                    successCallback();
                }, 0);
            },
            function failureTrigger(error) {
                failureCallback(error)
            }
        )
    }


    getProductOfferingPocsByOutlet(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getProductOfferingPocsByOutlet(token, id,
            function resSuccess(data) {
                const buy1get1 = data.records.filter(x => x.type === 0);
                const deliveryOffers = data.records.filter(x => x.type === 1);
                const onlineDeals = data.records.filter(x => x.type === 2);
                const onlineBookings = data.records.filter(x => x.type === 3);
                ProductOfferingModel.Buy1Get1Object = buy1get1.length > 0 ? { ...buy1get1[0], is_saved: true, is_new: false, outlet_id: id } : { ...ProductOfferingModel.Buy1Get1Object, outlet_id: id };
                ProductOfferingModel.DeliveryOffers = deliveryOffers.length > 0 ? { ...deliveryOffers[0], is_saved: true, is_new: false, outlet_id: id } : { ...ProductOfferingModel.DeliveryOffers, outlet_id: id };
                ProductOfferingModel.OnlineDeals = onlineDeals.length > 0 ? { ...onlineDeals[0], is_saved: true, is_new: false, merchant_id: id } : { ...ProductOfferingModel.OnlineDeals, merchant_id: id };
                ProductOfferingModel.OnlineBookings = onlineBookings.length > 0 ? { ...onlineBookings[0], is_saved: true, is_new: false, outlet_id: id } : { ...ProductOfferingModel.OnlineBookings, outlet_id: id };
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }
    getProductOfferingPocsByMerchant(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getProductOfferingPocsByMerchant(token, id,
            function resSuccess(data) {
                const buy1get1 = data.records.filter(x => x.type === 0);
                const deliveryOffers = data.records.filter(x => x.type === 1);
                const onlineDeals = data.records.filter(x => x.type === 2);
                const onlineBookings = data.records.filter(x => x.type === 3);
                ProductOfferingModel.Buy1Get1Object = buy1get1.length > 0 ? { ...buy1get1[0], is_saved: true, is_new: false, merchant_id: id } : { ...ProductOfferingModel.Buy1Get1Object, merchant_id: id };
                ProductOfferingModel.DeliveryOffers = deliveryOffers.length > 0 ? { ...deliveryOffers[0], is_saved: true, is_new: false, outlet_id: id } : { ...ProductOfferingModel.DeliveryOffers, outlet_id: id };
                ProductOfferingModel.OnlineDeals = onlineDeals.length > 0 ? { ...onlineDeals[0], is_saved: true, is_new: false, merchant_id: id } : { ...ProductOfferingModel.OnlineDeals, merchant_id: id };
                ProductOfferingModel.OnlineBookings = onlineBookings.length > 0 ? { ...onlineBookings[0], is_saved: true, is_new: false, outlet_id: id } : { ...ProductOfferingModel.OnlineBookings, outlet_id: id };
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getMerchantProductOfferingPoc(merchant_id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getProductOfferingPocsByMerchant(token, merchant_id,
            function resSuccess(data) {
                successTrigger(data.records)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    addProductOfferingPocs(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addProductOfferingPocs(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

}