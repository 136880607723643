import React from 'react';



export default function OrderStatusChips(status_id) {

    switch (status_id) {
        case 1:
            return (
                <span className="kt-badge kt-badge--inline kt-badge--pill" style={{ color: '#FD397A', fontSize: '14px', fontWeight: 600 }}>PENDING</span>
            );
        case 2:
            return (
                <span className="kt-badge  kt-badge--inline kt-badge--pill" style={{ color: '#6C7293', fontSize: '14px', fontWeight: 400 }}>ACCEPTED</span>
            );
        case 3:
            return (
                <span className="kt-badge  kt-badge--inline kt-badge--pill" style={{ color: '#6C7293', fontSize: '14px', fontWeight: 400 }}>COMPLETED</span>
            );
        case 4:
            return (
                <span className="kt-badge  kt-badge--inline kt-badge--pill" style={{ color: '#B5B8C9', fontSize: '14px', fontWeight: 600 }}>CANCELLED</span>
            );
        case 5:
            return (
                <span className="kt-badge kt-badge--inline kt-badge--pill" style={{ color: '#B5B8C9', fontSize: '14px', fontWeight: 600 }}>REFUNDED</span>
            );
        case 6:
            return (
                <span style={{ color: '#6C7293', fontSize: '14px', fontWeight: 400 }} className="kt-badge  kt-badge--inline kt-badge--pill">REJECTED</span>
            );

        case 7:
            return (
                <span style={{ color: '#6C7293', fontSize: '14px', fontWeight: 400 }} className="kt-badge  kt-badge--inline kt-badge--pill">DISPATCHED</span>
            );

        case 8:
            return (
                <span style={{ color: '#6C7293', fontSize: '14px', fontWeight: 400 }} className="kt-badge  kt-badge--inline kt-badge--pill">DELIVERED</span>
            );

        default:
            return (
                <span className="kt-badge kt-badge--inline kt-badge--pill" style={{ color: '#F02812', fontSize: '14px', fontWeight: 600 }}>PENDING</span>
            );
    }
}