import React from "react";
import { Upload, Icon, Modal, Spin, Popconfirm } from "antd";
import { getCompressedImage } from "Helpers/Shared/utils";
import { errorToaster } from "../Shared/Notifications";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default class MultipleImages extends React.Component {
  state = {
    previewVisible: false,
    previewImage: "",
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ file }) => {
    if (file.name) {
      getCompressedImage(file).then((compressedImage) => {
        if (compressedImage) {
          this.props.getImages(compressedImage);
        }
      });
    } else {
      this.props.imageList.forEach((image) => {
        if (image.status === "removed") {
          this.props.removeImage(image);
        }
      });
    }
  };

  beforeUpload(file) {
    return false;
  }

  render() {
    const { previewVisible, previewImage } = this.state;
    const { imageList, imageLimit } = this.props;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div className="clearfix">
        <Spin spinning={this.props.loading}>
          <Upload
            multiple={this.props.multiple}
            listType="picture-card"
            fileList={imageList}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
            beforeUpload={this.beforeUpload}
          >
            {imageList.length >= imageLimit ? null : uploadButton}
          </Upload>
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={this.handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </Spin>
      </div>
    );
  }
}
