import React, { useEffect, useState } from "react";

import {
    TimePicker,
    Input,
    Spin,
    Select,
    Icon,
    Popconfirm,
    Switch
} from "antd";
import {
    successNotification,
    errorNotification,
    errorToaster
} from "../Shared/Notifications";

import moment from "moment";

import OpeningHourModel from "Models/OpeningHourModel";


import DataJson from "./data.json"


const { Option } = Select;


export default function EditOpeningHour(props) {
    const
        [loading, setLoading] = useState(false),
        [name, setName] = useState(""),
        [isDefault, setIsDefault] = useState(false),
        [configList, setConfigList] = useState([]);

    useEffect(() => {
        getOpeningHourDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getOpeningHourDetail = () => {
        const { match: { params } } = props;
        setLoading(true);
        OpeningHourModel.getInstance().getOpeningHourDetail(params.id,
            function success(data) {
                setName(data.name);
                setIsDefault(data.is_default);
                setConfigList(data.configs.map(conf => ({
                    ...conf,
                    is_new: false, is_saved: true
                })))
                setLoading(false)
            },
            function failure(err) {
                console.error(err)
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    const checkForUnSavedConfigs = () => {
        return configList.some(_d => (_d.is_saved === false));
    }

    const updateOpeningHour = () => {
        const un_saved = checkForUnSavedConfigs();
        if (un_saved) {
            errorToaster("Please Save un-saved Configs first")
            return
        }
        if (name.length < 1) {
            errorToaster("Please Enter Opening Hour Name")
            return
        }
        const _params = {
            name,
            is_default: isDefault,
        };
        setLoading(true)
        const { match: { params } } = props;
        OpeningHourModel.getInstance().updateOpeningHour(params.id, _params,
            function success() {
                successNotification("Opening Hour Updated Successfully");
                setTimeout(() => {
                    setLoading(false);
                    window.location.href = "/opening-hour"
                }, 2000);
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    // Create a function that will create bulk configs
    const addConfigObject = () => {
        let list = [...configList]
        list.push({ ...DataJson.editConfigObject });
        setConfigList(list);
    }

    const validateConfig = (data) => {
        if (!data.start_time || !data.end_time) return false;
        else return true;
    }

    const saveConfig = (index, params) => {
        if (!validateConfig(params)) {
            errorToaster("Please enter correct config");
            return;
        }
        if (params.is_new) addConfigAPI(index, params);
        else updateConfigAPI(index, params);
    }

    const addConfigAPI = (index, _params) => {
        setLoading(true);
        const { match: { params } } = props;
        _params = { ..._params, opening_hour_id: params.id }
        OpeningHourModel.getInstance().addOpeningHourConfig(_params,
            function success(data) {
                let val = [...configList];
                val[index].id = data.id;
                val[index].is_saved = true;
                val[index].is_new = false;
                setConfigList(val);
                setLoading(false);
            },
            function failure(err) {
                errorNotification(JSON.stringify(err))
                setLoading(false);
            }
        )
    }

    const updateConfigAPI = (index, params) => {
        setLoading(true);
        OpeningHourModel.getInstance().updateOpeningHourConfig(params.id, params,
            function success(data) {
                let val = [...configList];
                val[index].is_saved = true;
                setConfigList(val);
                setLoading(false);
            },
            function failure(err) {
                console.error(err)
                errorNotification(JSON.stringify(err))
                setLoading(false);
            }
        )
    }

    const deleteConfigAPI = (index, params) => {
        if (params.is_new) {
            let val = [...configList];
            val.splice(index, 1);
            setConfigList(val);
            return
        } else {
            setLoading(true);
            OpeningHourModel.getInstance().removeOpeningHourConfig(params.id,
                function success(data) {
                    let val = [...configList];
                    val.splice(index, 1);
                    setConfigList(val);
                    setLoading(false);
                },
                function failure(err) {
                    errorNotification(JSON.stringify(err))
                    setLoading(false);
                }
            )
        }
    }

    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                <Spin spinning={loading} >

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                <div className="kt-portlet__head kt-portlet__head--lg">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">Edit Opening Hour</h3>
                                    </div>
                                    <div className="kt-portlet__head-toolbar">
                                        <a href="/opening-hour" className="btn btn-clean kt-margin-r-10">
                                            <i className="la la-arrow-left"></i>
                                            <span className="kt-hidden-mobile">Back</span>
                                        </a>
                                        <div className="btn-group">
                                            <button onClick={() => updateOpeningHour()} type="button" className="btn btn-brand">
                                                <i className="la la-check"></i>
                                                <span className="kt-hidden-mobile">Save</span>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                                <div className="kt-portlet__body">
                                    <form className="kt-form" id="kt_form">
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-8">
                                                <div className="kt-section kt-section--first">
                                                    <div className="kt-section__body">

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Name <b>*</b></label>
                                                            <div className="col-9">
                                                                <Input maxLength={100} value={name} onChange={(event) => setName(event.target.value)} className="form-control" type="text" />
                                                                <span className="form-text text-muted"><p style={{}}>Please Enter Opening Hour Name</p></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Make Default</label>
                                                            <div className="col-9">
                                                                <Switch checked={isDefault} checkedChildren="Yes" unCheckedChildren="No" onChange={(checked) => setIsDefault(checked)} />
                                                                <span className="form-text text-muted"><p style={{}}>Please Check either Opening Hour is Default or Not</p></span>
                                                            </div>
                                                        </div>

                                                        {
                                                            configList.map((config, i) => (
                                                                <div key={i} className="form-group row">
                                                                    <label className="col-3 col-form-label">Config # {i + 1} </label>
                                                                    <div className="col-3">
                                                                        <TimePicker
                                                                            id="1"
                                                                            minuteStep={1}
                                                                            className="form-control"
                                                                            format="HH:mm"
                                                                            value={moment(config.start_time, "HH:mm")}
                                                                            style={{ padding: "0px", border: "0px solid black" }}
                                                                            allowClear={false}
                                                                            onChange={(value) => {
                                                                                let val = [...configList];
                                                                                val[i].start_time = moment(value).format("HH:mm");
                                                                                val[i].is_saved = false;
                                                                                setConfigList(val);
                                                                            }} />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Select start time</p></span>
                                                                    </div>

                                                                    <div className="col-3">
                                                                        <TimePicker
                                                                            id="2"
                                                                            minuteStep={1}
                                                                            className="form-control"
                                                                            format="HH:mm"
                                                                            value={moment(config.end_time, "HH:mm")}
                                                                            style={{ padding: "0px", border: "0px solid black" }}
                                                                            allowClear={false}
                                                                            onChange={(value) => {
                                                                                let val = [...configList];
                                                                                val[i].end_time = moment(value).format("HH:mm");
                                                                                val[i].is_saved = false;
                                                                                setConfigList(val);
                                                                            }} />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Select end time</p></span>
                                                                    </div>

                                                                    <div className="col-2">
                                                                        <Select
                                                                            showSearch
                                                                            value={config.day}
                                                                            onChange={(day) => {
                                                                                let val = [...configList];
                                                                                val[i].day = day;
                                                                                val[i].is_saved = false;
                                                                                setConfigList(val);
                                                                            }}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {DataJson.dayArray.map((day) => (
                                                                                <Option key={day.value} value={day.value} >{day.name}</Option>
                                                                            ))}
                                                                        </Select>

                                                                        <span className="form-text text-muted"><p style={{}}>Please Select day</p></span>
                                                                    </div>
                                                                    {
                                                                        !config.is_saved &&
                                                                        <Popconfirm
                                                                            title="Are you sure you want to Save this Config?"
                                                                            icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
                                                                            onConfirm={() => saveConfig(i, config)}
                                                                        >
                                                                            <Icon style={{ fontSize: 20, paddingTop: 5, paddingLeft: 5 }} type="save" />
                                                                        </Popconfirm>
                                                                    }
                                                                    <Popconfirm
                                                                        title="Are you sure you want to delete this Config? This Action is ir-reversable!"
                                                                        icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
                                                                        onConfirm={() => deleteConfigAPI(i, config)}
                                                                    >
                                                                        <Icon style={{ fontSize: 20, paddingTop: 5, paddingLeft: 5 }} type="delete" />
                                                                    </Popconfirm>
                                                                </div>
                                                            ))
                                                        }



                                                        <div className="form-group row">
                                                            {/* <label className="col-3 col-form-label">End Date <b>*</b></label> */}
                                                            <div className="col-9">
                                                                <div className="btn-group">
                                                                    <button onClick={() => addConfigObject()} type="button" className="btn btn-brand">
                                                                        <i className="la la-plus"></i>
                                                                        <span className="kt-hidden-mobile">Add Config</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div >

    )
}