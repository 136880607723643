import React, { useState, useEffect } from "react";
import { Select, Icon, Switch, Spin, Popconfirm, } from "antd";
import ClassificationModel from "../../Models/ClassificationModel";
import { errorNotification, errorToaster, successNotification } from "../Shared/Notifications";
import MerchantModel from "Models/MerchantModel";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";

const { Option } = Select;

export default function OutletClassifications({ is_outlet = false, outlet_data = undefined, classification_data = undefined, refresh_data, outletClassificationList }) {


    const
        [loading, setLoading] = useState(false),
        [outletClassificationData, setOutletClassificationData] = useState([]),
        [outletListing, setOutletListing] = useState([]),
        [outletId, setOutletId] = useState(undefined),
        [classificationListing, setClassificationListing] = useState([]),
        [classificationId, setClassificationId] = useState(undefined);


    useEffect(() => {
        setOutletClassificationData(ClassificationModel.OutletClassificationList);
    }, [outletClassificationList])


    const debounce = (callback, wait) => {
        let timeout;
        return (...args) => {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => callback.apply(context, args), wait);
        };
    }
    const searchQueryOutlet = debounce(function (value) {
        getOutlets(value);
    }, 1000);
    const searchQueryClassification = debounce(function (value) {
        getClassifications(value);
    }, 1000);

    function getOutlets(name) {
        let params = {}
        if (name.trim().length > 0) {
            params = { "outlets.name": name }
        }
        setLoading(true)
        MerchantModel.getInstance().getSearchOutlet(params,
            function success(data) {
                setLoading(false)
                setOutletListing(data.data)
            },
            function name(error) {
                setLoading(false)
                setOutletListing([])
                errorNotification("Request information not found")
                console.log(error)
            }
        )
    }
    function getClassifications(name) {
        let params = {}
        if (name.trim().length > 0) {
            params = { name }
        }
        setLoading(true)
        ClassificationModel.getInstance().searchClassifications(params,
            function success(data) {
                setLoading(false)
                if (data.count < 1) {
                    errorNotification("Request information not found")
                }
                setClassificationListing(data.records)
            },
            function name(error) {
                setLoading(false)
                setClassificationListing([])
                errorNotification("Request information not found")
                console.log(error)
            }
        )
    }

    function addNewOutletClassification() {
        if (is_outlet && !classificationId) {
            errorToaster("Please select one classification");
            return
        }
        if (!is_outlet && !outletId) {
            errorToaster("Please select one outlet");
            return
        }
        const params = {
            outlet_id: is_outlet ? outlet_data.id : outletId,
            classification_id: is_outlet ? classificationId : classification_data.id,
            is_primary: false
        };
        setLoading(true);
        ClassificationModel.getInstance().addOutletClassification(params,
            function success() {
                successNotification("Outlet Classification Saved");
                setLoading(false);
                refresh_data();
                if (is_outlet) setClassificationId(undefined);
                else setOutletId(undefined);
            },
            function failure(error) {
                errorNotification(error)
                setLoading(false);
            },
        )
    }

    function updateIsPrimary(id, is_primary) {
        setLoading(true);
        ClassificationModel.getInstance().updateOutletClassification(id, { is_primary },
            function success() {
                successNotification("Outlet Classification Saved");
                setLoading(false);
            },
            function failure(error) {
                errorNotification("This outlet already has primary classification")
                setLoading(false);
            },
        )
    }

    function removeOutletClassification(id) {
        setLoading(true);
        ClassificationModel.getInstance().deleteOutletClassification(id,
            function success() {
                successNotification("Outlet Classification Removed");
                setLoading(false);
            },
            function failure(error) {
                errorNotification("This outlet already has primary classification")
                setLoading(false);
            },
        )
    }



    const OutletClassificationTable = () => {
        return (
            <ReactTable
                data={outletClassificationData}
                filterable
                showPageSizeOptions={true}
                defaultPageSize={10}
                defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value}
                columns={[

                    {
                        Header: <p style={{ fontWeight: "500", borderBottomWidth: "1px", padding: "10px 0px", fontSize: 12 }} >Outlets</p>,
                        id: "Outlets",
                        accessor: d => d.outlets.name,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["outlets"] }),
                        filterAll: true,
                        filterable: false
                    },
                    {
                        Header: <p style={{ fontWeight: "500", borderBottomWidth: "1px", padding: "10px 0px", fontSize: 12 }} >Classifications</p>,
                        id: "associated_classifications",
                        accessor: d => d.associated_classifications.name,
                        filterMethod: (filter, rows) => {
                            matchSorter(rows, filter.value, { keys: [item => item.associated_classifications.map((city) => city.props.children)] })
                        },
                        // matchSorter(rows, filter.value, { keys: ["associated_classifications.name"] }),
                        filterAll: true,
                        filterable: false
                    },


                    {
                        Header: <p style={{ fontWeight: "500", borderBottomWidth: "1px", padding: "10px 0px", fontSize: 12 }} >Primary</p>,
                        id: "is_primary",
                        accessor: "is_primary",
                        Cell: ({ row, value }) => (
                            <div>
                                {value ? <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">Primary</span> : <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">Non-Primary</span>}
                                <Popconfirm
                                    title={`Are you sure you want to make this classification ${value ? `NON-PRIMARY` : `PRIMARY`}`}
                                    icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
                                    onConfirm={() => updateIsPrimary(row._original.id, !value)}
                                >
                                    <Switch style={{ backgroundColor: value ? "#5d78ff" : "#fd397a", margin: "0px 10px" }} checked={value} />
                                </Popconfirm>
                            </div>
                        ),
                        filterMethod: (filter, row) => {
                            if (filter.value === "all") {
                                return true;
                            }
                            if (filter.value === "true") {

                                return row[filter.id] === true;
                            }
                            return row[filter.id] === false;
                        },
                        Filter: ({ filter, onChange }) =>
                            <Select
                                onChange={value => onChange(value)}
                                style={{ width: "100%" }}
                                defaultValue="all"
                                value={filter ? filter.value : "all"}
                            >
                                <Option value="all">Show All</Option>
                                <Option value="true">Primary</Option>
                                <Option value="false">Non-Primary</Option>
                            </Select>,
                    },
                    {
                        Header: <p style={{ fontWeight: "500", borderBottomWidth: "1px", padding: "10px 0px", fontSize: 12 }} >Action</p>,
                        id: "actions",
                        width: 100,
                        Cell: ({ row }) => (
                            <Popconfirm
                                title="Are you sure you want to delete this outlet classification?"
                                icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
                                onConfirm={() => removeOutletClassification(row._original.id)}
                            >
                                <Icon style={{ color: "#5D78FF", fontSize: 16 }} type="delete" />
                            </Popconfirm>
                        ),
                        filterable: false
                    },
                ]
                }
                className="-striped -highlight"
            />
        )
    }

    return (
        <div>
            <Spin spinning={loading}>
                {is_outlet ?
                    <div className="form-group row">
                        <label className="col-3 col-form-label">Classification</label>
                        <div className="col-9">
                            <Select
                                showSearch
                                style={{ margin: "10px 0px 10px 0px" }}
                                onChange={(value) => setClassificationId(value)}
                                value={classificationId}
                                notFoundContent={
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                        <p style={{ fontSize: 14 }}>Please Search Classifications</p>
                                    </div>
                                }
                                onSearch={(val) => searchQueryClassification(val, this)}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {classificationListing.map((item) => (
                                    <Option key={item.id} value={item.id}>{`${item.name} ${item.is_active ? " (active)" : " (in-active)"}`}</Option>
                                ))}
                            </Select>
                            <span className="form-text text-muted">Please select Classifications</span>

                        </div>
                    </div> :
                    <div className="form-group row">
                        <label className="col-3 col-form-label">Outlet</label>
                        <div className="col-9">
                            <Select
                                showSearch
                                style={{ margin: "10px 0px 10px 0px" }}
                                onChange={(value) => setOutletId(value)}
                                value={outletId}
                                notFoundContent={
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                        <p style={{ fontSize: 14 }}>Please Search Outlets</p>
                                    </div>
                                }
                                onSearch={(val) => searchQueryOutlet(val, this)}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {outletListing.map((outlet) => (
                                    <Option key={outlet.id} value={outlet.id}>{`${outlet.name} ${outlet.is_active ? " (active)" : " (in-active)"}`}</Option>
                                ))}
                            </Select>
                            <span className="form-text text-muted">Please select Classifications</span>

                        </div>
                    </div>
                }
                <button style={{ marginBottom: "10px" }} onClick={() => addNewOutletClassification()} type="button" className="btn btn-brand">
                    <i className="la la-check"></i>
                    <span className="kt-hidden-mobile">Save</span>
                </button>
                {OutletClassificationTable()}
            </Spin>
        </div>

    )


}