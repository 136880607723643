import imageCompression from "browser-image-compression";
import { errorToaster } from "Containers/Shared/Notifications";

let compressedImage;
export async function getCompressedImage(file) {
  const isImage =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";
  if (!isImage) {
    errorToaster("You can only upload Image file!");
    return;
  }
  const size = getImageSizeInMb(file);
  const isLt2M = size < 5;
  if (!isLt2M) {
    errorToaster("Image must smaller than 5MB!");
    return;
  }

  const width = await getDimension(file);
  const compressedPercentage = getCompressValue(size);

  // if compressedPercentage is 80 then size and quality will be decreased by 80%;
  const options = {
    maxSizeMB: 2,
    maxWidthOrHeight: (width / 100) * (100 - compressedPercentage),
    useWebWorker: true,
  };

  compressedImage = imageCompression(file, options);

  return compressedImage;
}

function getDimension(file) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => resolve(img.naturalWidth);
    img.onerror = reject;
  });
}

function getImageSizeInMb(file) {
  return (file.size / 1000 / 1000).toFixed(1);
}

function getCompressValue(imageSize) {
  let value;
  switch (true) {
    case imageSize >= 4 && imageSize <= 5:
      value = 80;
      break;
    case imageSize >= 3 && imageSize < 4:
      value = 75;
      break;
    case imageSize >= 2 && imageSize < 3:
      value = 70;
      break;
    case imageSize >= 1 && imageSize < 2:
      value = 55;
      break;
    case imageSize >= 0.5 && imageSize < 1:
      value = 40;
      break;
    case imageSize >= 0.2 && imageSize <= 0.5:
      value = 20;
      break;
    default:
      value = 0;
  }
  return value;
}
