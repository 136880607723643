import React from "react";

import UserModel from "../../Models/UserModel";

import ColorPicker from "../Shared/ColorPicker";
import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";

export default class AddMerchantTag extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      nameError: false,
      arb_name: "",
      arb_nameError: false,
      priority: null,
      priorityError: false,
      backColor: "#ffffff",
      backColorError: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  sendData() {
    const { name, arb_name, backColor, priority } = this.state;
    const { history } = this.props;
    // why add this when this is not even being used?
    let it = this;
    const text = "Validation Error! Please check fields";
    this.setState({
      nameError: false,
      arb_nameError: false,
      priorityError: false,
    });

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster(text);
      return;
    }

    if (!priority || parseInt(priority) < 0 || parseInt(priority) > 5) {
      this.setState({ priorityError: true });
      errorToaster(text);
      return;
    }
    if (
      parseFloat(priority) === parseFloat(priority) &&
      parseFloat(priority) % 1 !== 0
    ) {
      this.setState({ priorityError: true });
      errorToaster(text);
      return;
    }

    UserModel.getInstance().addMerchantTag(
      name,
      arb_name,
      backColor,
      priority,
      function success(data) {
        successNotification("Merchant Tag Created Successfully");
        setTimeout(() => {
          history.push("/merchant-tag");
        }, 1000);
      },
      function failure(error) {
        errorNotification(error);
        console.log(error);
      }
    );
  }

  render() {
    const {
      priorityError,
      nameError,
      arb_nameError,
      backColorError,
      backColor,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                <div className="kt-portlet__head kt-portlet__head--lg">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Add Merchant Tag</h3>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                    <a
                      href="/merchant-tag"
                      className="btn btn-clean kt-margin-r-10"
                    >
                      <i className="la la-arrow-left"></i>
                      <span className="kt-hidden-mobile">Back</span>
                    </a>
                    <div className="btn-group">
                      <button
                        onClick={() => this.sendData()}
                        type="button"
                        className="btn btn-brand"
                      >
                        <i className="la la-check"></i>
                        <span className="kt-hidden-mobile">Save</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* end header */}
                <div className="kt-portlet__body">
                  <form className="kt-form" id="kt_form">
                    <div className="row">
                      <div className="col-xl-2"></div>
                      <div className="col-xl-8">
                        <div className="kt-section kt-section--first">
                          <div className="kt-section__body">
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Name <b>*</b>
                              </label>
                              <div className="col-9">
                                <input
                                  maxLength="70"
                                  onChange={(event) =>
                                    this.setState({ name: event.target.value })
                                  }
                                  className="form-control"
                                  type="text"
                                />
                                <span
                                  style={{ color: nameError ? "red" : "" }}
                                  className="form-text text-muted"
                                >
                                  <p style={{ color: nameError ? "red" : "" }}>
                                    Please enter name in English
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Arabic Name
                              </label>
                              <div className="col-9">
                                <input
                                  maxLength="100"
                                  onChange={(event) =>
                                    this.setState({
                                      arb_name: event.target.value,
                                    })
                                  }
                                  className="form-control"
                                  type="text"
                                />
                                <span
                                  style={{ color: arb_nameError ? "red" : "" }}
                                  className="form-text text-muted"
                                >
                                  <p
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                  >
                                    Please enter name in Arabic
                                  </p>
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label">
                                Choose background Color <b>*</b>
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <div
                                  className="kt-avatar kt-avatar--outline kt-avatar--circle"
                                  id="kt_apps_user_add_avatar"
                                >
                                  <ColorPicker
                                    backColor={(color) =>
                                      this.setState({ backColor: color })
                                    }
                                    defaultColor={backColor}
                                  />
                                  <span
                                    style={{
                                      color: backColorError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: backColorError ? "red" : "",
                                      }}
                                    >
                                      Please Pick a color
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Priority <b>*</b>
                              </label>
                              <div className="col-9">
                                <input
                                  onChange={(event) =>
                                    this.setState({
                                      priority: event.target.value,
                                    })
                                  }
                                  className="form-control"
                                  type="number"
                                />
                                <span
                                  style={{ color: priorityError ? "red" : "" }}
                                  className="form-text text-muted"
                                >
                                  <p
                                    style={{
                                      color: priorityError ? "red" : "",
                                    }}
                                  >
                                    Please enter Priority between 0 to 5 and
                                    Must be non-decimal Number
                                  </p>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2"></div>
                    </div>
                  </form>
                </div>
                {/* end body */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
