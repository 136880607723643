import React from "react";
import { Select, Icon, Spin, DatePicker, TimePicker, Input, Tabs } from "antd";
import { getCompressedImage } from "Helpers/Shared/utils";

import ProgramModel from "../../Models/ProgramModel";
import MerchantModel from "../../Models/MerchantModel";
import OnlineDealModel from "Models/OnlineDealModel";
import ProductBookingModel from "Models/ProductBookingModel";
import OfferModel from "Models/OfferModel";
import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";

import backImage from "../../../src/assets/media/users/default.jpg";
import SharedModel from "../../Models/SharedModel";

import CollectionModel from "../../Models/CollectionModel";

import UserModel from "../../Models/UserModel";
import moment from "moment";

import DATA from "./data.json";
import { validateURL } from "../../Helpers/Shared/Validators";

const { Option } = Select;
const { TabPane } = Tabs;

export default class AddNotification extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      apps: [],
      appId: [],
      collectionList: [],
      collection: null,
      programList: [],
      program: null,
      landmarkList: [],
      landmark: null,
      areaList: [],
      area: null,
      targetType: 1,
      communicationType: "1",
      pushMessage: "",
      title: "",
      name: "",
      body: "",
      externalLink: "",
      buttonText: "",
      buttonlinkList: [
        {
          name: "Home",
          value: 1,
        },
        {
          name: "Notification",
          value: 8,
        },
        {
          name: "Outlet(Business_screen)",
          value: 3,
        },
      ],

      merchantList: [],
      merchant: null,
      mmebershipList: [],
      membership: null,
      outletList: [],
      outlet: null,
      offerList: [],
      offer: null,

      image: null,
      imageSrc: null,
      appError: false,
      programError: false,
      collectionError: false,
      landmarkError: false,
      areaError: false,
      pushMessageError: false,

      titleError: false,
      nameError: false,
      bodyError: false,
      buttonTextError: false,
      externalLinkError: false,

      merchantError: false,
      outletError: false,
      membershipError: false,
      offerError: false,

      onlineDealMerchantList: [],
      onlineDealList: [],
      productBookingOutletList: [],
      productBookingList: [],
      b1g1OutletList: [],
      b1g1OfferList: [],
      ecomMerchantList: [],
      categoryList: [],
      subCategoryList: [],

      externalUrl: "",
      onlineDealMerchantId: null,
      onlineDealId: null,
      productBookingOutletId: null,
      productBookingId: null,
      b1g1OutletId: null,
      b1g1OfferId: null,
      ecomMerchantId: null,
      category: null,
      subCategory: null,
      notificationList: [],
      linkMediaNotifcation: null,
      linkMediaURL: null,

      scheduled_date: moment().tz("Asia/Qatar"),
      scheduled_time: moment().tz("Asia/Qatar"),
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  async componentDidMount() {
    let it = this;
    it.setState({
      loading: true,
    });
    this.getCategories(null, true);

    await ProgramModel.getInstance().getProgram(
      null,
      function success() {
        it.setState({
          programList: ProgramModel.getInstance().ProgramList,
          // loading: false
        });
      },
      function failure(error) {
        console.log(error);
        // it.setState({ loading: false })
      }
    );

    await UserModel.getInstance().getLandmark(
      null,
      function success() {
        it.setState({
          // loading: false,
          landmarkList: UserModel.getInstance().LandmarkList,
          // perPage: data.per_page,
          // totalPage: data.last_page
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
      }
    );

    await UserModel.getInstance().getArea(
      null,
      function success() {
        it.setState({
          areaList: UserModel.getInstance().AreaList,
          // loading: false
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
      }
    );

    this.getAllAppIds();
  }

  saveLogo(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({
      loading: true,
      linkMediaURL: null,
      linkMediaNotifcation: null,
    });
    getCompressedImage(file)
      .then((compressedImage) => {
        if (compressedImage) {
          this.setState({
            loading: false,
            image: compressedImage,
            imageSrc: URL.createObjectURL(compressedImage),
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        errorNotification(e);
      });
  }

  getAllAppIds() {
    let it = this;
    it.setState({ loading: true });
    SharedModel.getInstance().getAllAppIds(
      function success(data) {
        it.setState({
          apps: data,
          loading: false,
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false });
      }
    );
  }

  getCollectionList(name) {
    const it = this;
    let params = {
      name,
      app_id: this.state.appId ? this.state.appId : 1,
    };
    CollectionModel.getInstance().getCollectionByApp(
      params,
      function success(data) {
        it.setState({
          collectionList: data,
          // loading: false
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false });
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQueryMerchant = this.debounce(function (value, it) {
    it.getMerchants(value);
  }, 1000);

  searchQueryCollection = this.debounce(function (value, it) {
    it.getCollectionList(value);
  }, 1000);

  searchQueryOnlineDealMerchant = this.debounce(function (value, it) {
    it.getOnlineDealMerchants(value);
  }, 1000);

  searchQueryProductBookingOutlet = this.debounce(function (value, it) {
    it.getProductBookingOutlets(value);
  }, 1000);

  searchQueryB1G1Outlets = this.debounce(function (value, it) {
    it.getB1G1AssociatedOutlets(value);
  }, 1000);

  searchQueryEcommerceMerchant = this.debounce(function (value, it) {
    it.getEcommerceMerchants(value);
  }, 1000);

  searchQueryNotification = this.debounce(function (value, it) {
    it.getNotificationList(value);
  }, 1000);

  getEcommerceMerchants(name) {
    const it = this;
    this.setState({ loading: true });
    let params = {
      "merchants.name": name,
    };
    MerchantModel.getInstance().getEcommerceMerchants(
      params,
      function success(data) {
        it.setState({ loading: false, ecomMerchantList: data });
      },
      function failure(error) {
        it.setState({ loading: true });
        errorToaster("Request information not found");
      }
    );
  }

  getMerchants(value) {
    if (value.length > 0) {
      let it = this;
      let params = {
        query: encodeURIComponent(value),
      };
      this.setState({ loading: true });
      MerchantModel.getInstance().getMerchant(
        params,
        function success(data) {
          it.setState({
            loading: false,
            merchantList: data.data,
          });
        },
        function failure(error) {
          errorNotification(error);
          it.setState({ loading: false });
        }
      );
    }
  }

  getOutlets(merchant) {
    this.setState({
      loading: true,
      outletList: [],
      outlet: null,
      merchant: merchant,
    });
    let it = this;
    MerchantModel.getInstance().getMerchantOutlet(
      merchant,
      function success(data) {
        it.setState({
          outletList: data,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  getOnlineDealMerchants = (name) => {
    let params = {
      name,
    };
    const it = this;
    this.setState({ loading: true });
    OnlineDealModel.getInstance().getOnlineDealMerchants(
      params,
      function success(data) {
        it.setState({
          loading: false,
          onlineDealMerchantList: data,
        });
      },
      function failure(error) {
        it.setState({
          loading: false,
        });
        errorToaster("Request information not found");
      }
    );
  };
  getOnlineDealByMerchantId = (merchant_id) => {
    let params = {
      offset: 0,
      limit: 100,
      merchant_id,
    };
    const it = this;
    this.setState({ loading: true });
    OnlineDealModel.getInstance().getAllOnlineDeals(
      params,
      function success(data) {
        it.setState({
          loading: false,
          onlineDealList: data.records,
        });
      },
      function failure(error) {
        it.setState({
          loading: false,
        });
        errorToaster("Request information not found");
      }
    );
  };

  getProductBookingOutlets = (name) => {
    let params = {
      name,
    };
    const it = this;
    this.setState({ loading: true });
    ProductBookingModel.getInstance().getProductBookingOutlets(
      params,
      function success(data) {
        it.setState({
          loading: false,
          productBookingOutletList: data,
        });
      },
      function failure(error) {
        it.setState({
          loading: false,
        });
        errorToaster("Request information not found");
      }
    );
  };
  getProductBookingByOutletId = (outlet_id) => {
    const params = {
        "$outlet.id$": outlet_id,
      },
      it = this;
    this.setState({ loading: true });
    ProductBookingModel.getInstance().getAllProductBookings(
      params,
      function success(data) {
        it.setState({
          loading: false,
          productBookingList: data.records,
        });
      },
      function failure(error) {
        it.setState({
          loading: false,
        });
        errorToaster("Request information not found");
      }
    );
  };

  getB1G1AssociatedOutlets = (name) => {
    let params = {
      name,
    };
    const it = this;
    this.setState({ loading: true });
    MerchantModel.getInstance().getB1G1AssociatedOutlets(
      params,
      function success(data) {
        it.setState({
          loading: false,
          b1g1OutletList: data,
        });
      },
      function failure(error) {
        it.setState({
          loading: false,
        });
        errorToaster("Request information not found");
      }
    );
  };
  getB1G1OffersByOutletId = (outlet_id) => {
    let params = {
      "$outlet_offers_association.outlet_id$": outlet_id,
    };
    const it = this;
    this.setState({ loading: true });
    OfferModel.getInstance().getB1G1Offers(
      params,
      function success(data) {
        it.setState({
          loading: false,
          b1g1OfferList: data.records,
        });
      },
      function failure(error) {
        it.setState({
          loading: false,
        });
        errorToaster("Request information not found");
      }
    );
  };

  getCategories(id = null, is_parent = true) {
    const it = this;
    it.setState({
      loading: true,
    });
    const params = { parent: true };
    if (!is_parent) params["id"] = id;
    UserModel.getInstance().getCategory(
      params,
      function success(data) {
        if (is_parent) it.setState({ categoryList: data.data });
        else it.setState({ subCategoryList: data.data });
        it.setState({ loading: false });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  getNotificationList(name) {
    console.log({ name });
    const params = {
        "notifications.name": name,
        "notifications.communication_id": 2,
      },
      it = this;
    this.setState({ loading: true });
    SharedModel.getInstance().searchNotification(
      params,
      function success(data) {
        it.setState({
          loading: false,
          notificationList: data.notification.data,
        });
      },
      function failure(error) {
        it.setState({ loading: false, notificationList: [] });
        errorToaster("Request information not found");
      }
    );
  }

  sendData() {
    const {
      appId,
      name,
      title,
      body,
      communicationType,
      targetType,
      merchant,
      landmark,
      area,
      collection,
      image,
      buttonText,
    } = this.state;
    let it = this;
    this.setState({
      appError: false,
      programError: false,
      collectionError: false,
      landmarkError: false,
      areaError: false,
      externalLinkError: false,
      titleError: false,
      nameError: false,
      bodyError: false,
      buttonTextError: false,
      merchantError: false,
      outletError: false,
      offerError: false,
    });
    let params = {};

    if (appId.length < 1) {
      this.setState({ appError: true });
      errorToaster("Please Choose App");
      return;
    }
    params.app_id = appId;

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster("Please enter name");
      return;
    }
    params.name = name;

    if (title.length < 1) {
      this.setState({ titleError: true });
      errorToaster("Please enter title");
      return;
    }
    params.title = title;

    if (body.length < 1) {
      this.setState({ bodyError: true });
      errorToaster("Please enter body");
      return;
    }
    params.text = body;
    params.communication_id = communicationType;
    params.scheduled_at = moment.tz(
      moment(this.state.scheduled_date).format("YYYY-MM-DD") +
        " " +
        moment(this.state.scheduled_time).format("HH:mm:ss"),
      "Asia/Qatar"
    );

    if (communicationType == "2") {
      if (buttonText.length < 1) {
        this.setState({ buttonTextError: true });
        errorToaster("Please Enter Button Text");
        return;
      }
      params.button_text = buttonText;
    }
    if (communicationType == "2" && this.state.linkMediaURL)
      params["image"] = this.state.linkMediaURL;

    if (!targetType) {
      this.setState({ targetTypeError: true });
      errorToaster("Please Choose target type");
      return;
    }

    params.route_type = targetType;

    switch (targetType) {
      case "collections":
        if (!collection) {
          errorToaster("Please Choose collection");
          this.setState({ collectionError: true });
          return;
        }
        break;

      case "landmarks":
        if (!landmark) {
          errorToaster("Please Choose landmark");
          this.setState({ landmarkError: true });
          return;
        }
        break;

      case "merchants":
        if (!merchant) {
          errorToaster("Please Choose merchant");
          this.setState({ merchantError: true });
          return;
        }
        break;

      case "areas":
        if (!area) {
          errorToaster("Please Choose area");
          this.setState({ areaError: true });
          return;
        }
        break;

      case "categories":
        if (!this.state.category) {
          errorToaster("Please Select Category");
          return;
        }
        break;

      case "online_deal_merchant":
        if (!this.state.onlineDealMerchantId) {
          errorToaster("Please select Merchant");
          return;
        }
        break;

      case "online_deal_offer":
        if (!this.state.onlineDealMerchantId || !this.state.onlineDealId) {
          errorToaster("Please select Merchant Or Online Deal");
          return;
        }
        break;

      case "product_booking_outlet":
        if (!this.state.productBookingOutletId) {
          errorToaster("Please select Outlet");
          return;
        }
        break;

      case "product_booking_offer":
        if (
          !this.state.productBookingOutletId ||
          !this.state.productBookingId
        ) {
          errorToaster("Please select Outlet or Product Booking");
          return;
        }
        break;

      case "b1g1_outlet":
        if (!this.state.b1g1OutletId) {
          errorToaster("Please select Outlet");
          return;
        }
        break;

      case "b1g1_offer":
        if (!this.state.b1g1OutletId || !this.state.b1g1OfferId) {
          errorToaster("Please select Outlet or Offer");
          return;
        }
        break;

      case "delivery_merchants":
        if (!this.state.ecomMerchantId) {
          errorToaster("Please select Merchant");
          return;
        }
        break;

      case "external":
        if (!validateURL(this.state.externalUrl)) {
          errorToaster("Please enter valid URL");
          return;
        }
        break;

      default:
        break;
    }

    params.path = this.getPath(targetType);
    this.setState({ loading: true });
    if (communicationType === "2" && image) this.addMedia(params, image);
    else this.createBulkNotification(params);
  }

  createBulkNotification(params) {
    const it = this;
    const payload = params.app_id.map((_app) => ({
      ...params,
      app_id: _app,
    }));
    SharedModel.getInstance().addNotification(
      payload,
      function success(data) {
        successNotification("Notification Created Successfully");
        setTimeout(() => {
          window.location.href = "/notifications";
        }, 1000);
      },

      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }
  addMedia(params, image) {
    const it = this;
    UserModel.getInstance().addMedia(
      image,
      function success(url) {
        params["image"] = url;
        it.createBulkNotification(params);
      },
      function failure(err) {
        errorNotification(JSON.stringify(err));
        this.setState({ loading: false });
      }
    );
  }

  getPath(targetType) {
    if (
      !DATA.targetTypeList.find((val) => val.value === targetType)?.id_available
    )
      return `/${targetType}`;
    switch (targetType) {
      case "collections":
        return `/${targetType}/${this.state.collection}`;

      case "landmarks":
        return `/${targetType}/${this.state.landmark}`;

      case "merchants":
        return `/${targetType}/${this.state.merchant}`;

      case "areas":
        return `/${targetType}/${this.state.area}`;

      case "categories":
        return this.state.subCategory
          ? `/${targetType}/${this.state.category}/${this.state.subCategory}`
          : `/${targetType}/${this.state.category}`;

      case "online_deal_merchant":
        return `/${targetType}/${this.state.onlineDealMerchantId}`;

      case "online_deal_offer":
        return `/${targetType}/${this.state.onlineDealMerchantId}/${this.state.onlineDealId}`;

      case "b1g1_outlet":
        return `/${targetType}/${this.state.b1g1OutletId}`;

      case "b1g1_offer":
        return `/${targetType}/${this.state.b1g1OutletId}/${this.state.b1g1OfferId}`;

      case "product_booking_outlet":
        return `/${targetType}/${this.state.productBookingOutletId}`;

      case "product_booking_offer":
        return `/${targetType}/${this.state.productBookingOutletId}/${this.state.productBookingId}`;

      case "delivery_merchants":
        return `/${targetType}/${this.state.ecomMerchantId}`;

      case "external":
        return `/${targetType}/${this.state.externalUrl}`;

      default:
        return "/home";
    }
  }

  disablePreviousDate(current) {
    return current < moment().startOf("day");
  }

  renderPathId() {
    switch (this.state.targetType) {
      case "collections":
        return (
          <div className="form-group row">
            <label className="col-3 col-form-label" htmlFor="marchantGroup">
              Collection <b>*</b>
            </label>
            <div className="col-9">
              <Select
                showSearch
                onSearch={(val) => this.searchQueryCollection(val, this)}
                style={{ margin: "10px 0px 10px 0px" }}
                value={this.state.collection}
                onChange={(collection) => this.setState({ collection })}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.collectionList.map((val) => (
                  <Option disabled={!val.is_active} key={val.id} value={val.id}>
                    {`${val.name} - ${
                      val.is_active ? "Active" : "De-Active"
                    } - ${val.id}`}
                  </Option>
                ))}
              </Select>
              <span className="form-text text-muted">
                <p
                  style={{
                    color: this.state.collectionError ? "red" : "",
                  }}
                >
                  Please choose Collection
                </p>
              </span>
            </div>
          </div>
        );

      case "landmarks":
        return (
          <div className="form-group row">
            <label className="col-3 col-form-label" htmlFor="marchantGroup">
              Landmark <b>*</b>
            </label>
            <div className="col-9">
              <Select
                showSearch
                style={{ margin: "10px 0px 10px 0px" }}
                value={this.state.landmark}
                onChange={(landmark) => this.setState({ landmark })}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.landmarkList.map((val) => (
                  <Option disabled={!val.is_active} key={val.id} value={val.id}>
                    {`${val.name} - ${
                      val.is_active ? "Active" : "De-Active"
                    } - ${val.id}`}
                  </Option>
                ))}
              </Select>
              <span className="form-text text-muted">
                <p
                  style={{
                    color: this.state.landmarkError ? "red" : "",
                  }}
                >
                  Please choose Landmark
                </p>
              </span>
            </div>
          </div>
        );

      case "merchants":
        return (
          <div className="form-group row">
            <label className="col-3 col-form-label">
              Merchant <b>*</b>
            </label>
            <div className="col-9">
              <Select
                showSearch
                style={{ margin: "10px 0px 10px 0px" }}
                notFoundContent={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      style={{
                        fontSize: 16,
                        marginRight: "10px",
                        marginBottom: "5px",
                      }}
                      type="search"
                    />
                    <p style={{ fontSize: 14 }}>Please Search Merchant</p>
                  </div>
                }
                onSearch={(val) => this.searchQueryMerchant(val, this)}
                onChange={(merchant) => this.getOutlets(merchant)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.merchantList.map((val) => (
                  <Option disabled={!val.is_active} key={val.id} value={val.id}>
                    {`${val.name} - ${
                      val.is_active ? "Active" : "De-Active"
                    } - ${val.id}`}
                  </Option>
                ))}
              </Select>
              <span className="form-text text-muted">
                <p
                  style={{
                    color: this.state.merchantError ? "red" : "",
                  }}
                >
                  Please Select Merchant
                </p>
              </span>
            </div>
          </div>
        );

      case "areas":
        return (
          <div className="form-group row">
            <label className="col-3 col-form-label" htmlFor="marchantGroup">
              Area <b>*</b>
            </label>
            <div className="col-9">
              <Select
                showSearch
                style={{ margin: "10px 0px 10px 0px" }}
                value={this.state.area}
                onChange={(area) => this.setState({ area })}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.areaList.map((val) => (
                  <Option disabled={!val.is_active} key={val.id} value={val.id}>
                    {`${val.name} - ${
                      val.is_active ? "Active" : "De-Active"
                    } - ${val.id}`}
                  </Option>
                ))}
              </Select>
              <span className="form-text text-muted">
                <p
                  style={{
                    color: this.state.areaError ? "red" : "",
                  }}
                >
                  Please choose Area
                </p>
              </span>
            </div>
          </div>
        );

      case "categories":
        return (
          <>
            <div className="form-group row">
              <label className="col-3 col-form-label">
                Category <b>*</b>
              </label>
              <div className="col-9">
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={(category) => {
                    this.setState({
                      category,
                      subCategory: null,
                      subCategoryList: [],
                    });
                    this.getCategories(category, false);
                  }}
                  value={this.state.category}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        style={{
                          fontSize: 16,
                          marginRight: "10px",
                          marginBottom: "5px",
                        }}
                        type="search"
                      />
                      <p style={{ fontSize: 14 }}>
                        Please Search Outlet by Name
                      </p>
                    </div>
                  }
                  onSearch={(val) =>
                    this.searchQueryProductBookingOutlet(val, this)
                  }
                >
                  {this.state.categoryList.map((cat) => (
                    <Option
                      disabled={!cat.is_active}
                      key={cat.id}
                      value={cat.id}
                    >
                      {cat.name}
                    </Option>
                  ))}
                </Select>
                <span className="form-text text-muted">
                  <p style={{}}>Please Select Category</p>
                </span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-3 col-form-label">Sub Category</label>
              <div className="col-9">
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={(subCategory) => this.setState({ subCategory })}
                  value={this.state.subCategory}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        style={{
                          fontSize: 16,
                          marginRight: "10px",
                          marginBottom: "5px",
                        }}
                        type="search"
                      />
                      <p style={{ fontSize: 14 }}>
                        Please Search Merchant by Name
                      </p>
                    </div>
                  }
                >
                  {this.state.subCategoryList.map((val) => (
                    <Option
                      disabled={!val.is_active}
                      key={val.id}
                      value={val.id}
                    >
                      {`${val.name} - ${
                        val.is_active ? "Active" : "De-Active"
                      } - ${val.id}`}
                    </Option>
                  ))}
                </Select>
                <span className="form-text text-muted">
                  <p style={{}}>Please Select Sub Category</p>
                </span>
              </div>
            </div>
          </>
        );

      case "online_deal_merchant":
        return (
          <div className="form-group row">
            <label className="col-3 col-form-label">
              Online Deal Merchants <b>*</b>
            </label>
            <div className="col-9">
              <Select
                showSearch
                optionFilterProp="children"
                onChange={(onlineDealMerchantId) =>
                  this.setState({ onlineDealMerchantId })
                }
                value={this.state.onlineDealMerchantId}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Icon
                      style={{
                        fontSize: 16,
                        marginRight: "10px",
                        marginBottom: "5px",
                      }}
                      type="search"
                    />
                    <p style={{ fontSize: 14 }}>
                      Please Search Merchant by Name
                    </p>
                  </div>
                }
                onSearch={(val) =>
                  this.searchQueryOnlineDealMerchant(val, this)
                }
              >
                {this.state.onlineDealMerchantList.map((val) => (
                  <Option disabled={!val.is_active} key={val.id} value={val.id}>
                    {`${val.name} - ${
                      val.is_active ? "Active" : "De-Active"
                    } - ${val.id}`}
                  </Option>
                ))}
              </Select>
              <span className="form-text text-muted">
                <p style={{}}>Please Select Merchant</p>
              </span>
            </div>
          </div>
        );

      case "online_deal_offer":
        return (
          <>
            <div className="form-group row">
              <label className="col-3 col-form-label">
                Online Deal Merchants <b>*</b>
              </label>
              <div className="col-9">
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={(onlineDealMerchantId) => {
                    this.setState({ onlineDealMerchantId });
                    this.getOnlineDealByMerchantId(onlineDealMerchantId);
                  }}
                  value={this.state.onlineDealMerchantId}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        style={{
                          fontSize: 16,
                          marginRight: "10px",
                          marginBottom: "5px",
                        }}
                        type="search"
                      />
                      <p style={{ fontSize: 14 }}>
                        Please Search Merchant by Name
                      </p>
                    </div>
                  }
                  onSearch={(val) =>
                    this.searchQueryOnlineDealMerchant(val, this)
                  }
                >
                  {this.state.onlineDealMerchantList.map((val) => (
                    <Option
                      disabled={!val.is_active}
                      key={val.id}
                      value={val.id}
                    >
                      {`${val.name} - ${
                        val.is_active ? "Active" : "De-Active"
                      } - ${val.id}`}
                    </Option>
                  ))}
                </Select>
                <span className="form-text text-muted">
                  <p style={{}}>Please Select Merchant</p>
                </span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-3 col-form-label">
                Online Deals <b>*</b>
              </label>
              <div className="col-9">
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={(onlineDealId) => this.setState({ onlineDealId })}
                  value={this.state.onlineDealId}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        style={{
                          fontSize: 16,
                          marginRight: "10px",
                          marginBottom: "5px",
                        }}
                        type="search"
                      />
                      <p style={{ fontSize: 14 }}>
                        Please Search Merchant by Name
                      </p>
                    </div>
                  }
                >
                  {this.state.onlineDealList.map((val) => (
                    <Option
                      disabled={!val.is_active}
                      key={val.id}
                      value={val.id}
                    >
                      {`${val.name} - ${
                        val.is_active ? "Active" : "De-Active"
                      } - ${val.id}`}
                    </Option>
                  ))}
                </Select>
                <span className="form-text text-muted">
                  <p style={{}}>Please Select Online Deal</p>
                </span>
              </div>
            </div>
          </>
        );

      case "product_booking_outlet":
        return (
          <div className="form-group row">
            <label className="col-3 col-form-label">
              Product Booking Outlet <b>*</b>
            </label>
            <div className="col-9">
              <Select
                showSearch
                optionFilterProp="children"
                onChange={(productBookingOutletId) =>
                  this.setState({ productBookingOutletId })
                }
                value={this.state.productBookingOutletId}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Icon
                      style={{
                        fontSize: 16,
                        marginRight: "10px",
                        marginBottom: "5px",
                      }}
                      type="search"
                    />
                    <p style={{ fontSize: 14 }}>Please Search Outlet by Name</p>
                  </div>
                }
                onSearch={(val) =>
                  this.searchQueryProductBookingOutlet(val, this)
                }
              >
                {this.state.productBookingOutletList.map((val) => (
                  <Option disabled={!val.is_active} key={val.id} value={val.id}>
                    {`${val.name} - ${
                      val.is_active ? "Active" : "De-Active"
                    } - ${val.id}`}
                  </Option>
                ))}
              </Select>
              <span className="form-text text-muted">
                <p style={{}}>Please Select Outlet</p>
              </span>
            </div>
          </div>
        );

      case "product_booking_offer":
        return (
          <>
            <div className="form-group row">
              <label className="col-3 col-form-label">
                Product Booking Outlet <b>*</b>
              </label>
              <div className="col-9">
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={(productBookingOutletId) => {
                    this.setState({ productBookingOutletId });
                    this.getProductBookingByOutletId(productBookingOutletId);
                  }}
                  value={this.state.productBookingOutletId}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        style={{
                          fontSize: 16,
                          marginRight: "10px",
                          marginBottom: "5px",
                        }}
                        type="search"
                      />
                      <p style={{ fontSize: 14 }}>
                        Please Search Outlet by Name
                      </p>
                    </div>
                  }
                  onSearch={(val) =>
                    this.searchQueryProductBookingOutlet(val, this)
                  }
                >
                  {this.state.productBookingOutletList.map((val) => (
                    <Option
                      disabled={!val.is_active}
                      key={val.id}
                      value={val.id}
                    >
                      {`${val.name} - ${
                        val.is_active ? "Active" : "De-Active"
                      } - ${val.id}`}
                    </Option>
                  ))}
                </Select>
                <span className="form-text text-muted">
                  <p style={{}}>Please Select Outlet</p>
                </span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-3 col-form-label">
                Product Bookings <b>*</b>
              </label>
              <div className="col-9">
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={(productBookingId) =>
                    this.setState({ productBookingId })
                  }
                  value={this.state.productBookingId}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        style={{
                          fontSize: 16,
                          marginRight: "10px",
                          marginBottom: "5px",
                        }}
                        type="search"
                      />
                      <p style={{ fontSize: 14 }}>
                        Please Search Merchant by Name
                      </p>
                    </div>
                  }
                >
                  {this.state.productBookingList.map((val) => (
                    <Option
                      disabled={!val.is_active}
                      key={val.id}
                      value={val.id}
                    >
                      {`${val.name} - ${
                        val.is_active ? "Active" : "De-Active"
                      } - ${val.id}`}
                    </Option>
                  ))}
                </Select>
                <span className="form-text text-muted">
                  <p style={{}}>Please Select Product Booking</p>
                </span>
              </div>
            </div>
          </>
        );

      case "b1g1_outlet":
        return (
          <div className="form-group row">
            <label className="col-3 col-form-label">
              Outlets <b>*</b>
            </label>
            <div className="col-9">
              <Select
                showSearch
                optionFilterProp="children"
                onChange={(b1g1OutletId) => this.setState({ b1g1OutletId })}
                value={this.state.b1g1OutletId}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Icon
                      style={{
                        fontSize: 16,
                        marginRight: "10px",
                        marginBottom: "5px",
                      }}
                      type="search"
                    />
                    <p style={{ fontSize: 14 }}>Please Search Outlet by Name</p>
                  </div>
                }
                onSearch={(val) => this.searchQueryB1G1Outlets(val, this)}
              >
                {this.state.b1g1OutletList.map((val) => (
                  <Option disabled={!val.is_active} key={val.id} value={val.id}>
                    {`${val.name} - ${
                      val.is_active ? "Active" : "De-Active"
                    } - ${val.id}`}
                  </Option>
                ))}
              </Select>
              <span className="form-text text-muted">
                <p style={{}}>Please Select Outlet</p>
              </span>
            </div>
          </div>
        );

      case "b1g1_offer":
        return (
          <>
            <div className="form-group row">
              <label className="col-3 col-form-label">
                Outlets <b>*</b>
              </label>
              <div className="col-9">
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={(b1g1OutletId) => {
                    this.setState({ b1g1OutletId });
                    this.getB1G1OffersByOutletId(b1g1OutletId);
                  }}
                  value={this.state.b1g1OutletId}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        style={{
                          fontSize: 16,
                          marginRight: "10px",
                          marginBottom: "5px",
                        }}
                        type="search"
                      />
                      <p style={{ fontSize: 14 }}>
                        Please Search Outlet by Name
                      </p>
                    </div>
                  }
                  onSearch={(val) => this.searchQueryB1G1Outlets(val, this)}
                >
                  {this.state.b1g1OutletList.map((val) => (
                    <Option
                      disabled={!val.is_active}
                      key={val.id}
                      value={val.id}
                    >
                      {`${val.name} - ${
                        val.is_active ? "Active" : "De-Active"
                      } - ${val.id}`}
                    </Option>
                  ))}
                </Select>
                <span className="form-text text-muted">
                  <p style={{}}>Please Select Outlet</p>
                </span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-3 col-form-label">
                Buy-1 Get-1 Offers <b>*</b>
              </label>
              <div className="col-9">
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={(b1g1OfferId) => this.setState({ b1g1OfferId })}
                  value={this.state.b1g1OfferId}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        style={{
                          fontSize: 16,
                          marginRight: "10px",
                          marginBottom: "5px",
                        }}
                        type="search"
                      />
                      <p style={{ fontSize: 14 }}>
                        Please Search Merchant by Name
                      </p>
                    </div>
                  }
                >
                  {this.state.b1g1OfferList.map((val) => (
                    <Option
                      disabled={!val.is_active}
                      key={val.id}
                      value={val.id}
                    >
                      {`${val.name} - ${
                        val.is_active ? "Active" : "De-Active"
                      } - ${val.id}`}
                    </Option>
                  ))}
                </Select>
                <span className="form-text text-muted">
                  <p style={{}}>Please Select Offer</p>
                </span>
              </div>
            </div>
          </>
        );

      case "delivery_merchants":
        return (
          <div className="form-group row">
            <label className="col-3 col-form-label">
              Delivery Merchants <b>*</b>
            </label>
            <div className="col-9">
              <Select
                showSearch
                optionFilterProp="children"
                onChange={(ecomMerchantId) => this.setState({ ecomMerchantId })}
                value={this.state.ecomMerchantId}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Icon
                      style={{
                        fontSize: 16,
                        marginRight: "10px",
                        marginBottom: "5px",
                      }}
                      type="search"
                    />
                    <p style={{ fontSize: 14 }}>
                      Please Search Merchant by Name
                    </p>
                  </div>
                }
                onSearch={(val) => this.searchQueryEcommerceMerchant(val, this)}
              >
                {this.state.ecomMerchantList.map((val) => (
                  <Option disabled={!val.is_active} key={val.id} value={val.id}>
                    {`${val.name} - ${
                      val.is_active ? "Active" : "De-Active"
                    } - ${val.id}`}
                  </Option>
                ))}
              </Select>
              <span className="form-text text-muted">
                <p style={{}}>Please Select Merchant</p>
              </span>
            </div>
          </div>
        );

      case "external":
        return (
          <div className="form-group row">
            <label className="col-3 col-form-label">
              External Url <b>*</b>
            </label>
            <div className="col-9">
              <Input
                maxLength={255}
                value={this.state.externalUrl}
                onChange={(event) =>
                  this.setState({ externalUrl: event.target.value })
                }
                className="form-control"
                type="text"
              />
              <span className="form-text text-muted">
                <p style={{}}>Please Enter external url</p>
              </span>
            </div>
          </div>
        );

      default:
        break;
    }
  }

  render() {
    const {
      apps,
      appError,
      nameError,
      titleError,
      bodyError,
      buttonTextError,
      buttonLinkError,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <Spin spinning={this.state.loading}>
          <div className="kt-content  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">
                        Add Notification/Announcement
                      </h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/notifications"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <Tabs defaultActiveKey="overview">
                                <TabPane tab="Overview" key="overview">
                                  <div className="form-group row">
                                    <label
                                      className="col-3 col-form-label"
                                      htmlFor="marchantGroup"
                                    >
                                      Apps <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <Select
                                        showSearch
                                        mode="multiple"
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        value={this.state.appId}
                                        onChange={(appId) =>
                                          this.setState({ appId })
                                        }
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {apps.map((app) => (
                                          <Option key={app.id} value={app.id}>
                                            {app.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: appError ? "red" : "",
                                          }}
                                        >
                                          Please choose atlease One App ID
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-3 col-form-label"
                                      htmlFor="marchantGroup"
                                    >
                                      Communication Type <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <Select
                                        showSearch
                                        // mode="multiple"
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        value={this.state.communicationType}
                                        // placeholder="Select a merchant group"
                                        onChange={(communicationType) =>
                                          this.setState({ communicationType })
                                        }
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        <Option value="1">Notification</Option>
                                        <Option value="2">Announcement</Option>
                                      </Select>
                                      <span className="form-text text-muted">
                                        Please choose Communication Type
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Name <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <input
                                        maxLength="100"
                                        onChange={(event) =>
                                          this.setState({
                                            name: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                        type="text"
                                      />
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: nameError ? "red" : "",
                                          }}
                                        >
                                          Please enter name
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Title <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <input
                                        maxLength="100"
                                        onChange={(event) =>
                                          this.setState({
                                            title: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                        type="text"
                                      />
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: titleError ? "red" : "",
                                          }}
                                        >
                                          Please enter title
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-3 col-form-label"
                                      htmlFor="exampleTextarea"
                                    >
                                      Body <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <div className="input-group">
                                        <textarea
                                          onChange={(event) =>
                                            this.setState({
                                              body: event.target.value,
                                            })
                                          }
                                          className="form-control"
                                          rows="3"
                                        ></textarea>
                                      </div>
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: bodyError ? "red" : "",
                                          }}
                                        >
                                          Please enter Push Message Text
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-3 col-form-label"
                                      htmlFor="exampleTextarea"
                                    >
                                      Scheduled Time <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <div className="input-group">
                                        <DatePicker
                                          className="form-control"
                                          format="DD-MMM-YYYY"
                                          disabledDate={
                                            this.disablePreviousDate
                                          }
                                          onChange={(scheduled_date) =>
                                            this.setState({ scheduled_date })
                                          }
                                          value={this.state.scheduled_date}
                                          style={{
                                            padding: "0px",
                                            border: "0px solid black",
                                          }}
                                          allowClear={false}
                                        />
                                      </div>

                                      <div className="input-group">
                                        <TimePicker
                                          use12Hours
                                          className="form-control"
                                          format="hh:mm a"
                                          onChange={(scheduled_time) =>
                                            this.setState({ scheduled_time })
                                          }
                                          value={this.state.scheduled_time}
                                          style={{
                                            padding: "0px",
                                            border: "0px solid black",
                                          }}
                                          allowClear={false}
                                        />
                                      </div>

                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: bodyError ? "red" : "",
                                          }}
                                        >
                                          Please Select Date/Time to Schedule
                                          Notification
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                  {this.state.communicationType === "2" && (
                                    <div>
                                      <div className="form-group row">
                                        <label className="col-3 col-form-label">
                                          Button Text <b>*</b>
                                        </label>
                                        <div className="col-9">
                                          <input
                                            maxLength="100"
                                            onChange={(event) =>
                                              this.setState({
                                                buttonText: event.target.value,
                                              })
                                            }
                                            className="form-control"
                                            type="text"
                                          />
                                          <span className="form-text text-muted">
                                            <p
                                              style={{
                                                color: buttonTextError
                                                  ? "red"
                                                  : "",
                                              }}
                                            >
                                              Please enter Button Text
                                            </p>
                                          </span>
                                        </div>
                                      </div>

                                      <div className="form-group row">
                                        <label
                                          className="col-3 col-form-label"
                                          htmlFor="marchantGroup"
                                        >
                                          Button Link<b>*</b>
                                        </label>
                                        <div className="col-9">
                                          <Select
                                            style={{
                                              margin: "10px 0px 10px 0px",
                                            }}
                                            onChange={(targetType) =>
                                              this.setState({ targetType })
                                            }
                                          >
                                            {this.state.buttonlinkList.map(
                                              (type) => (
                                                <Option
                                                  disabled={type.disabled}
                                                  value={type.value}
                                                >
                                                  {type.name}
                                                </Option>
                                              )
                                            )}
                                          </Select>
                                          <span className="form-text text-muted">
                                            <p
                                              style={{
                                                color: buttonLinkError
                                                  ? "red"
                                                  : "",
                                              }}
                                            >
                                              Please choose Button Link
                                            </p>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {this.state.communicationType == "1" && (
                                    <div className="form-group row">
                                      <label
                                        className="col-3 col-form-label"
                                        htmlFor="marchantGroup"
                                      >
                                        Target <b>*</b>
                                      </label>
                                      <div className="col-9">
                                        <Select
                                          showSearch
                                          style={{
                                            margin: "10px 0px 10px 0px",
                                          }}
                                          onChange={(targetType) =>
                                            this.setState({ targetType })
                                          }
                                          filterOption={(input, option) =>
                                            option.props.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                        >
                                          {DATA.targetTypeList.map((type) => (
                                            <Option value={type.value}>
                                              {type.name}
                                            </Option>
                                          ))}
                                        </Select>
                                        <span className="form-text text-muted">
                                          Please choose Target Type{" "}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                  {this.renderPathId()}
                                </TabPane>
                                {this.state.communicationType == "2" && (
                                  <TabPane tab="Media" key="media">
                                    <div className="form-group row">
                                      <label
                                        className="col-3 col-form-label"
                                        htmlFor="marchantGroup"
                                      >
                                        Linked Media
                                      </label>
                                      <div className="col-9">
                                        <Select
                                          showSearch
                                          onSearch={(value) =>
                                            this.searchQueryNotification(
                                              value,
                                              this
                                            )
                                          }
                                          style={{
                                            margin: "10px 0px 10px 0px",
                                          }}
                                          value={
                                            this.state.linkMediaNotifcation
                                          }
                                          onChange={(linkMediaNotifcation) =>
                                            this.setState({
                                              linkMediaNotifcation:
                                                linkMediaNotifcation,
                                              linkMediaURL:
                                                this.state.notificationList.find(
                                                  (_x) =>
                                                    _x.id ===
                                                    linkMediaNotifcation
                                                )?.image,
                                              imageSrc: null,
                                              image: null,
                                            })
                                          }
                                          filterOption={(input, option) =>
                                            option.props.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                        >
                                          {this.state.notificationList.map(
                                            (val) => (
                                              <Option
                                                key={val.id}
                                                value={val.id}
                                              >
                                                {`${val.name} - ${
                                                  val.is_active
                                                    ? "Active"
                                                    : "De-Active"
                                                } - ${val.id}`}
                                              </Option>
                                            )
                                          )}
                                        </Select>
                                        <span className="form-text text-muted">
                                          <p
                                            style={{
                                              color: buttonLinkError
                                                ? "red"
                                                : "",
                                            }}
                                          >
                                            Please choose linked Media
                                            Notification
                                          </p>
                                        </span>
                                      </div>
                                    </div>

                                    <div className="form-group row">
                                      <label className="col-xl-3 col-lg-3 col-form-label">
                                        Image
                                      </label>
                                      <div className="col-lg-9 col-xl-6">
                                        <div
                                          className="kt-avatar kt-avatar--outline kt-avatar"
                                          id="kt_apps_user_add_avatar"
                                        >
                                          <div
                                            className="kt-avatar__holder"
                                            style={{
                                              backgroundImage: `url(${
                                                this.state.linkMediaURL
                                                  ? this.state.linkMediaURL
                                                  : this.state.imageSrc
                                                  ? this.state.imageSrc
                                                  : backImage
                                              })`,
                                            }}
                                          ></div>
                                          <label
                                            className="kt-avatar__upload"
                                            data-toggle="kt-tooltip"
                                            title=""
                                            data-original-title="Change avatar"
                                          >
                                            <i className="fa fa-pen"></i>
                                            <input
                                              type="file"
                                              name="kt_apps_user_add_user_avatar"
                                              onChange={(event) =>
                                                this.saveLogo(event)
                                              }
                                            />
                                          </label>
                                          <span
                                            className="kt-avatar__cancel"
                                            data-toggle="kt-tooltip"
                                            title=""
                                            data-original-title="Cancel avatar"
                                          >
                                            <i className="fa fa-times"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </TabPane>
                                )}
                              </Tabs>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
