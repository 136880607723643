import React from "react";
import Constants from "../../Constants";
import moment from "moment";

export default class Footer extends React.Component {
  render() {
    return (
      <div
        className="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop"
        id="kt_footer"
      >
        <div className="kt-footer__copyright">
          {moment().year()}&nbsp;&copy;&nbsp;
          <a href="/order-report" className="kt-link">
            My Book Qatar
          </a>
          &nbsp;&nbsp;{Constants.currentVersion}
        </div>
        <div className="kt-footer__menu">
          <a
            href="/order-report"
            target="blank"
            className="kt-footer__menu-link kt-link"
          >
            Contact
          </a>
        </div>
      </div>
    );
  }
}
