/* eslint-disable eqeqeq */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable array-callback-return */
import {
    Checkbox, Icon, Input, Select,
    Spin, Switch, Tabs, TimePicker, Tooltip
} from "antd";
import ClassificationModel from "Models/ClassificationModel";
import moment from "moment";
import React from "react";
import Geocode from "react-geocode";
import backImage from "../../../src/assets/media/users/default.jpg";
import {
    validateEmail, validateNumber, validatePhone
} from "../../Helpers/Shared/Validators";
import MerchantModel from "../../Models/MerchantModel";
import OpeningHourModel from "../../Models/OpeningHourModel";
import ProductOfferingModel from "../../Models/ProductOfferingModel";
import UserModel from "../../Models/UserModel";
import OutletClassifications from "../Classifications/OutletClassifications";
import ProductBooking from "../ProductBooking";
import Offers from "../Offer";
import EcommerceOffer from "../EcommerceOffer";
import ContactPocView from "../Shared/ContactPocView";
import {
    errorNotification,
    errorToaster
} from "../Shared/Notifications";
import MultipleImages from "./MultipleImages";
// import { useLocation } from "react-router-dom";





// const location = useLocation();

const { Option } = Select;
const { TabPane } = Tabs;
const format = "h:mm a";
const CURRENCY_LIST = [
    'QAR', 'PKR', 'INR', 'USD', 'TRY', 'JOD', 'EUR', 'GBP',
    'SAR', 'AED', 'KWD', 'BHD'
]

export default class EditOutlet extends React.Component {


    constructor() {
        super();
        this.state = {
            loading: false,
            backImageLoading: false,
            menuImageLoading: false,
            outletId: null,
            name: "",
            arabicName: null,
            arabicNameError: false,
            merchant: null,
            merchantList: [],
            landmark: null,
            landMarkList: [],
            payment: [],
            paymentList: [],
            cuisine: [],
            cuisinesList: [],
            country: null,
            countryList: [],
            province: null,
            provinceList: [],
            city: null,
            cityList: [],
            area: null,
            areaList: [],
            lat: 0,
            lng: 0,
            addressString: "",
            address: "",
            arabicAddress: "",
            address_2: "",
            arabicAddress_2: "",
            phone: "",
            phone_2: "",
            phone_3: "",
            email: "",
            averageCost: "",
            infoTag: [],
            infoTagList: [],
            disabledHours: [],
            menusList: [],
            imageList: [],
            is_hours: false,
            operationalHours: [
                { day: "monday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
                { day: "tuesday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
                { day: "wednesday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
                { day: "thursday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
                { day: "friday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
                { day: "saturday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
                { day: "sunday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
            ],
            currentDay: 0,
            weekDays: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
            ],
            // errors
            nameError: false,
            merchantError: false,
            imageListError: false,
            addressError: false,
            arabicAddressError: false,
            areaError: false,
            countryError: false,
            provinceError: false,
            cityError: false,
            locationError: false,
            phoneError: false,
            menusListError: false,
            emailError: false,
            phone_2Error: false,
            phone_3Error: false,
            delivery: false,
            latLngError: false,
            fromCoordinates: false,
            redemptionEmail: "",
            redemptionEmailError: false,
            pin: "",
            pinError: false,
            outletTypeList: [],
            outletType: [],
            outletTypeError: false,
            disabledDelieveryHours: [],
            deliveryHours: [
                { day: "monday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
                { day: "tuesday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
                { day: "wednesday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
                { day: "thursday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
                { day: "friday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
                { day: "saturday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
                { day: "sunday", status: "open", times: [{ start_time: "12:00 am", end_time: "11:59 pm" }] },
            ],
            currentDelieveryDay: 0,
            radius: null,
            radiusError: false,
            charges: null,
            chargeError: false,
            delieveryTime: null,
            delieveryTimeError: false,
            spend: "",
            spendError: false,
            maxOffer: null,
            maxOfferError: false,
            deliveryPaymentMethodList: [
                {
                    name: "Credit-Card",
                    value: 1
                },
                {
                    name: "Debit-Card",
                    value: 2
                },
                {
                    name: "Cash On Delivery",
                    value: 3
                }
            ],
            deliveryPaymentMethod: [],
            deliveryPaymentMethodError: false,
            deliveryPartner: null,
            deliveryPartnerList: [],
            deliveryPartnerError: false,
            is_delivery_automatic: false,
            selfDelivery: false,
            is_active: false,
            deliveryError: false,
            activeKey: "1",

            // OUTLET CONFIGS
            currency: "QAR",
            minimum_booking_time: 0,
            is_booking_open: false,
            booking_opening_hour_id: null,
            is_delivery_open: false,
            delivery_opening_hour_id: null,
            is_instoreoffers_open: true,
            instoreoffers_opening_hour_id: null,
            opening_hour_list: [],

            // Product offering POC

            productOfferingList: [],

            // Outlet Classifications
            outletClassificationList: [],

        }
    }


    componentWillMount() {
        const role_id = localStorage.getItem("user_role")
        switch (Number(role_id)) {
            case 1:
                break;
            case 5:
                window.location.href = "/order-report"
                break;
            default:
                window.location.href = "/login"
                break;
        }
    }

    async componentDidMount() {
        const it = this;
        const { match: { params } } = this.props;
        this.getProductOfferingPocsByOutlet(params.outletId)
        this.getOutletClassifications(params.outletId)
        await MerchantModel.getInstance().getOutletDetail(params.outletId,
            async function success(data) {
                it.setState({
                    loading: false,
                    outletId: params.outletId,
                    merchant: data.merchant_id,
                    name: data.name,
                    arabicName: data.arabic_name ? data.arabic_name : null,
                    landmark: data.landmark_id,
                    arabicAddress: data.arabic_address ? data.arabic_address : "",
                    address: data.address ? data.address : "",
                    address_2: data.address_2 ? data.address_2 : "",
                    arabicAddress_2: data.arabic_address_2 ? data.arabic_address_2 : "",
                    area: data.area_id,
                    country: data.country_id,
                    province: data.province_id,
                    city: data.city_id,
                    addressString: data.address,
                    lat: data.lat,
                    lng: data.lng,
                    phone: data.phone,
                    phone_2: data.phone_2 ? data.phone_2 : "",
                    phone_3: data.phone_3 ? data.phone_3 : "",
                    email: data.email ? data.email : "",
                    pin: data.pin ? data.pin : "",
                    redemptionEmail: data.redemption_email ? data.redemption_email.join(",") : "",
                    infoTag: data.info_tags.length > 0 ? data.info_tags.map(tag => { return tag.id }) : [],
                    payment: data.payment_modes ? data.payment_modes.map(mode => { return mode.id }) : [],
                    cuisine: data.cuisines ? data.cuisines.map(cuis => { return cuis.id }) : [],
                    averageCost: data.average_cost,
                    operationalHours: data.operating_hours.length > 0 ? data.operating_hours : it.state.operationalHours,
                    is_hours: data.operating_hours.length > 0 ? true : false,
                    delivery: data.is_deliver,
                    outletType: data.outlet_types.map(type => { return type.id }),
                    deliveryHours: data.delivery_timings ? data.delivery_timings.length > 0 ? data.delivery_timings : it.state.deliveryHours : it.state.deliveryHours,
                    radius: data.delivery_radius,
                    charges: data.delivery_charges,
                    delieveryTime: data.avg_delivery_time,
                    spend: data.delivery_min_amount,
                    maxOffer: data.max_vouchers,
                    fromCoordinates: data.is_cord ? data.is_cord : false,
                    deliveryPaymentMethod: data.delivery_payment_methods ? data.delivery_payment_methods : [],
                    deliveryPartner: data.delivery_partner_id,
                    is_delivery_automatic: data.is_delivery_automatic,
                    selfDelivery: data.self_delivery,
                    is_active: data.delivery_partner_is_active
                })
                if (data.outlet_config) {
                    let opening_hour_list = [];
                    it.setState({
                        currency: data.outlet_config?.currency,
                        is_instoreoffers_open: data.outlet_config?.is_instoreoffers_open,
                        instoreoffers_opening_hour_id: data.outlet_config?.instoreoffers_opening_hour_id,
                        is_booking_open: data.outlet_config?.is_booking_open,
                        minimum_booking_time: data.outlet_config?.minimum_booking_time,
                        booking_opening_hour_id: data.outlet_config?.booking_opening_hour_id,
                        is_delivery_open: data.outlet_config?.is_delivery_open,
                        delivery_opening_hour_id: data.outlet_config?.delivery_opening_hour_id
                    });
                    if (data.outlet_config?.instoreoffers_opening_hour) opening_hour_list.push(data.outlet_config.instoreoffers_opening_hour);
                    if (data.outlet_config?.delivery_opening_hour) opening_hour_list.push(data.outlet_config.delivery_opening_hour);
                    if (data.outlet_config?.booking_opening_hour) opening_hour_list.push(data.outlet_config.booking_opening_hour);
                    if (!data.outlet_config?.booking_opening_hour_id) it.getDefaultOpeningHour();
                    it.setState({ opening_hour_list })
                }


                it.getMerchantlogo(data.merchant_id)

                it.getProvince(data.country_id)
                it.getCities(data.province_id)
                it.getAreas(data.city_id)
                it.getAreaLandmark(data.area_id)

                let mediaArr = [];
                let menuArr = [];
                await data.media.forEach(img => {
                    let obj = {};
                    obj.url = img;
                    obj.uid = img;
                    obj.deletable = true;
                    mediaArr.push(obj)
                });
                it.setState({ imageList: mediaArr });

                if (data.menus) {
                    data.menus.forEach(img => {
                        let obj = {};
                        obj.url = img;
                        obj.uid = img;
                        obj.deletable = true;
                        menuArr.push(obj)
                    });
                }

                it.setState({ menusList: menuArr });
            },
            function failure(error) {
                it.setState({ loading: false })
                errorNotification(error)
                console.error(error)
            }
        )

        await UserModel.getInstance().getCountries(
            function success() {
                it.setState({
                    countryList: UserModel.getInstance().CountriesList,
                })
            },
            function failure(msg) {
                errorNotification(msg)
                console.log(msg)
            }
        )

        it.setState({ loading: true })
        await UserModel.getInstance().getPayment(

            function success() {
                it.setState({
                    paymentList: UserModel.getInstance().PaymentList,
                })
            },
            function failure(msg) {
                errorNotification(msg)
                console.log(msg)
            }
        )
        await UserModel.getInstance().getMerchantInfoTag(null,
            function success() {
                it.setState({
                    infoTagList: UserModel.getInstance().MerchantInfoTagList,
                    // loading: false
                })
            },
            function failure(msg) {
                errorNotification(msg)
                console.log(msg)
                // it.setState({ loading: false })
            }
        )

        await UserModel.getInstance().getCuisine(
            function success() {
                it.setState({
                    cuisinesList: UserModel.getInstance().CuisinesList,
                    loading: false
                })
            },
            function failure(msg) {
                errorNotification(msg)
                console.log(msg)
                it.setState({ loading: false })
            }
        )

        await MerchantModel.getInstance().getDeliveryPartner(
            function success() {
                it.setState({
                    deliveryPartnerList: MerchantModel.getInstance().DeliveryPartnerList,
                    loading: false
                })
            },
            function failure(msg) {
                console.log(msg)
                it.setState({ loading: false })
            }
        )

        this.getOutletType();
    }

    getDefaultOpeningHour() {
        const it = this;
        OpeningHourModel.getInstance().getDefaultOpeningHour(
            function success(data) {
                it.setState({ loading: false, opening_hour_list: [data], booking_opening_hour_id: data.id })
            },
            function failure(err) {
                errorToaster(JSON.stringify(err))
                it.setState({ loading: false })
            }
        )

    }

    getMerchantlogo(merchantId) {
        let it = this;
        MerchantModel.getInstance().getMerchantDetail(merchantId,
            function success(data) {
                let { menusList, imageList } = it.state
                it.setState({
                    merchant: data.id,
                    logo: data.logo,
                });
                it.getMerchantList(data.name)
                if (data.media) {
                    data.media.forEach(img => {
                        imageList.push({
                            url: img,
                            uid: img,
                            deletable: false
                        })
                    });
                }
                if (data.menus) {
                    data.menus.forEach(img => {
                        menusList.push({
                            url: img,
                            uid: img,
                            deletable: false
                        })
                    });
                }
                it.setState({
                    imageList, menusList
                })
            },
            function failure(error) {
                console.log(error)
                it.setState({ loading: false })
            }
        )
    }

    getProductOfferingPocsByOutlet(outlet_id) {
        const it = this;
        ProductOfferingModel.getInstance().getProductOfferingPocsByOutlet(outlet_id,
            function success(data) {
                it.setState({ productOfferingList: data.records })
            },
            function failure(err) {
                console.error(err)
            }

        )
    }

    getOutletClassifications(outlet_id) {
        const it = this;
        const params = { outlet_id }
        ClassificationModel.getInstance().getAllOutletClassifications(params,
            function success(data) {
                it.setState({ outletClassificationList: data })
            },
            function failure(err) {
                console.error(err)
            }

        )
    }

    debounce(callback, wait) {
        let timeout;
        return (...args) => {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => callback.apply(context, args), wait);
        };
    }

    searchQuery = this.debounce(function (it) {
        it.getOutletType()
    }, 2000);


    getOutletType() {
        let it = this;
        it.setState({ loading: true })
        MerchantModel.getInstance().getOutletType(
            function success(data) {
                it.setState({
                    outletTypeList: MerchantModel.getInstance().OutletTypeList,
                    loading: false
                })
            },

            function failure(error) {
                errorToaster(error)
                console.error(error)
            }
        )
    }

    searchQueryMerchant = this.debounce(function (value, it) {
        it.getMerchantList(value);
    }, 1000);

    getMerchantList(query) {
        let it = this;
        it.setState({ loading: true })
        let params = {
            query
        }
        MerchantModel.getInstance().getMerchant(params,
            function success(data) {
                it.setState({
                    merchantList: data.data,
                    loading: false
                })
            },
            function failure(msg) {
                it.setState({ loading: false })
                console.log(msg)
            }
        )
    }

    getProvince(country) {
        const it = this;
        this.setState({ loading: true })
        let params = {
            id: country
        }
        UserModel.getInstance().getProvince(params,
            function success() {
                it.setState({
                    provinceList: UserModel.getInstance().ProvinceList,
                    loading: false
                })
            },
            function failure(msg) {
                console.log(msg)
                it.setState({ loading: false })
            }
        )
    }

    getCities(province) {
        const it = this;
        this.setState({ loading: true })
        let params = {
            id: province
        }
        UserModel.getInstance().getCities(params,
            function success() {
                it.setState({
                    cityList: UserModel.getInstance().CitiesList,
                    loading: false
                })
            },
            function failure(msg) {
                console.log(msg)
                it.setState({ loading: false })
            }
        )
    }

    getAreas(city) {
        let it = this;
        let params = {
            id: city
        }
        UserModel.getInstance().getArea(params,
            function success(data) {
                it.setState({
                    areaList: data,
                    loading: false
                })
            },
            function failure(msg) {
                it.setState({ loading: false })
                console.log(msg)
            }
        )
    }

    getAreaLandmark(areaId) {
        let it = this;
        it.setState({ loading: true })
        let params = {
            id: areaId
        }
        UserModel.getInstance().getLandmark(params,
            function success(data) {
                it.setState({
                    loading: false,
                    landMarkList: data.data
                })
            },
            function failure(error) {
                it.setState({ loading: false })
                console.log(error)
            }
        )
    }


    disabledHours(pickHour) {
        const allHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
        let disabledHours = []
        allHours.map((hour) => {
            if (hour <= pickHour) {
                disabledHours.push(hour)
            }
        })
        this.setState({ disabledHours })
    }

    checkEndTime() {
        var start = this.state.operationalHours[this.state.currentDay].start_time;
        var close = this.state.operationalHours[this.state.currentDay].end_time;

        if (start >= close) {
            this.state.operationalHours[this.state.currentDay].end_time = "11:59 pm";
            this.forceUpdate();
        }
    }

    addTime() {
        let arr = this.state.operationalHours[this.state.currentDay].times;
        let newTime = { start_time: "12:00 am", end_time: "11:59 pm" }
        arr.push(newTime);
        this.state.operationalHours[this.state.currentDay].times = arr;
        this.forceUpdate();
    }

    deleteTime() {
        let arr = this.state.operationalHours[this.state.currentDay].times;
        arr.splice(1, 1);
        this.state.operationalHours[this.state.currentDay].times = arr;
        this.forceUpdate();
    }

    sendBackImages(image) {
        const it = this;
        it.setState({ backImageLoading: true })
        let arr = this.state.imageList;
        UserModel.getInstance().addMedia(image,
            function success(url) {
                let img = {
                    uid: url,
                    url: url,
                    deletable: true
                }
                arr.push(img);
                it.setState({
                    imageList: arr,
                    backImageLoading: false
                })
            },
            function failure(msg) {
                errorNotification(msg)
                it.setState({ backImageLoading: false })
            }
        )
    }

    removeBackImage(image) {
        const it = this;
        let arr = this.state.imageList;
        let { url, deletable } = image;
        delete image["status"];
        if (!deletable) {
            errorToaster("You cannot delete this Image");
            return;
        }
        it.setState({ backImageLoading: true })
        UserModel.getInstance().removeMedia(url,
            async function success() {
                it.setState({ backImageLoading: false })
                await arr.forEach((img, key) => {
                    if (img.uid === image.uid) {
                        arr.splice(key, 1);
                    }
                });
                await it.setState({ imageList: arr })
            },
            function failure(error) {
                errorNotification(error)
                console.log(error)
                it.setState({ backImageLoading: false })
            }
        )
    }

    sendMenuImages(image) {
        const it = this;
        it.setState({ menuImageLoading: true })
        let arr = this.state.menusList;
        UserModel.getInstance().addMedia(image,
            function success(url) {
                let img = {
                    uid: url,
                    url: url,
                    deletable: true
                }
                arr.push(img);
                it.setState({
                    menusList: arr,
                    menuImageLoading: false
                })
            },
            function failure() {
                it.setState({ menuImageLoading: false })
            }
        )
    }

    getReverseGeo() {
        this.setState({
            loading: true
        })
        Geocode.setApiKey("AIzaSyDMKXgTik_BboalQAMEVvHdUBoPqfQ6FTc");
        Geocode.setLanguage("en");
        Geocode.fromLatLng(this.state.tempLat, this.state.tempLng).then(
            response => {
                this.setState({
                    lat: this.state.tempLat,
                    lng: this.state.tempLng,
                    address: response.results[0].formatted_address,
                    loading: false,
                    addressString: response.results[0].formatted_address
                })
            },
            error => {
                this.setState({ loading: false })
                errorToaster("Location Not Find! Please Enter Valid Coordinates")
                console.log(error);
            }
        )
            .catch((err) => {
                console.log(err)
                errorToaster("Something Went wrong")
            })

    }

    removeMenuImage(image) {
        const it = this;
        let arr = this.state.menusList;
        let { url, deletable } = image;
        delete image["status"];
        if (!deletable) {
            errorToaster("You cannot delete this Image");
            return;
        }
        it.setState({ menuImageLoading: true })
        UserModel.getInstance().removeMedia(url,
            async function success() {
                it.setState({ menuImageLoading: false })
                await arr.forEach((img, key) => {
                    if (img.uid === image.uid) {
                        arr.splice(key, 1);
                    }
                });
                await it.setState({ menusList: arr })
            },
            function failure(error) {
                console.log(error)
                it.setState({ menuImageLoading: false })
            }
        )
    }

    disabledDelieveryHours(pickHour) {
        const allHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
        let disabledDelieveryHours = []
        allHours.map((hour) => {
            if (hour <= pickHour) {
                disabledDelieveryHours.push(hour)
            }
        })
        this.setState({ disabledDelieveryHours })
    }

    checkEndDelieveryTime() {
        var start = this.state.deliveryHours[this.state.currentDelieveryDay].start_time;
        var close = this.state.deliveryHours[this.state.currentDelieveryDay].end_time;

        if (start >= close) {
            this.state.deliveryHours[this.state.currentDelieveryDay].end_time = "11:59 pm";
            this.forceUpdate();
        }
    }

    addDelieveryTime() {
        let arr = this.state.deliveryHours[this.state.currentDelieveryDay].times;
        let newTime = { start_time: "12:00 am", end_time: "11:59 pm" }
        arr.push(newTime);
        this.state.deliveryHours[this.state.currentDelieveryDay].times = arr;
        this.forceUpdate();
    }

    deleteDelieveryTime() {
        let arr = this.state.deliveryHours[this.state.currentDelieveryDay].times;
        arr.splice(1, 1);
        this.state.deliveryHours[this.state.currentDelieveryDay].times = arr;
        this.forceUpdate();
    }

    // SEARCH QUERY FOR FINDING OPENING HOURS
    searchQueryOpeningHour = this.debounce(function (value, it) {
        it.getOpeningHours(value);
    }, 1000);

    getOpeningHours(name) {
        let it = this;
        it.setState({ loading: true })
        let params = {
            name
        }
        OpeningHourModel.getInstance().getAllOpeningHours(params,
            function success(data) {
                it.setState({
                    opening_hour_list: data.records,
                    loading: false
                })
            },
            function failure(msg) {
                console.log(msg)
            }
        )
    }

    sendData() {
        let { outletId, merchant, name, arabicName, imageList, landmark, arabicAddress, address, address_2, arabicAddress_2, area, country, province, city, lat, lng, phone, phone_2, phone_3, email, infoTag, payment, cuisine, averageCost, menusList, operationalHours, delivery, redemptionEmail, pin, is_hours, outletType, delieveryTime, deliveryHours, spend, radius, charges, maxOffer, deliveryPaymentMethod, deliveryPartner, is_delivery_automatic, selfDelivery, is_active } = this.state;
        const { history } = this.props;
        let it = this;
        let params = {};
        it.setState({
            merchantError: false,
            nameError: false,
            arabicNameError: false,
            imageListError: false,
            addressError: false,
            arabicAddressError: false,
            areaError: false,
            latLngError: false,
            locationError: false,
            phoneError: false,
            menusListError: false,
            emailError: false,
            redemptionEmailError: false,
            phone_2Error: false,
            phone_3Error: false,
            countryError: false,
            provinceError: false,
            cityError: false,
            pinError: false,
            outletTypeError: false,
            delieveryTimeError: false,
            radiusError: false,
            chargeError: false,
            spendError: false,
            maxOfferError: false,
            deliveryPaymentMethodError: false,
            deliveryPartnerError: false,
            deliveryError: false,

        })
        if (!merchant) {
            this.setState({ merchantError: true, activeKey: "1" })
            errorToaster("Please select merchant");
            return
        }
        if (name.length < 1) {
            this.setState({ nameError: true, activeKey: "1" })
            errorToaster("Please select name");
            return;
        }
        params.arabic_name = arabicName;

        if (pin.length !== 4) {
            this.setState({ pinError: true, activeKey: "1" })
            errorToaster("Please select pin");
            return;
        }
        params.pin = pin;
        if (imageList.length < 1) {
            this.setState({ imageListError: true, activeKey: "1" })
            errorToaster("Please select image");
            return;
        }
        params.arabic_address = arabicAddress
        params.is_cord = this.state.fromCoordinates
        if (!country) {
            this.setState({ countryError: true, activeKey: "2" })
            errorToaster("Please select country");
            return;
        }
        if (!province) {
            this.setState({ provinceError: true, activeKey: "2" })
            errorToaster("Please select province");
            return;
        }
        if (!city) {
            this.setState({ cityError: true, activeKey: "2" })
            errorToaster("Please select city");
            return;
        }
        if (!area) {
            this.setState({ areaError: true, activeKey: "2" })
            errorToaster("Please select area");
            return;
        }
        lat = Number(lat);
        lng = Number(lng)
        if (!lat || !lng) {
            this.setState({ latLngError: true, activeKey: "2" })
            errorToaster("Please Add lat and lng");
            return;
        }
        if (address.length > 0) {
            params.address = address;
        }
        if (!phone) {
            this.setState({ phoneError: true, activeKey: "1" })
            errorToaster("Please select phone");
            return;
        }
        if (!validatePhone(phone)) {
            this.setState({ phoneError: true, activeKey: "1" })
            errorToaster("Invalid Phone Formate");
            return;
        }
        params.merchant_id = merchant;
        params.name = name;
        params.media = [];
        imageList.map((img) => {
            if (img.deletable) {
                params.media.push(img.url)
            }
        });
        params.address = address;
        params.area_id = area;
        params.lat = lat;
        params.lng = lng;
        params.phone = phone;
        params.country_id = country;
        params.province_id = province;
        params.city_id = city;
        params.menus = [];
        menusList.map((img) => {
            if (img.deletable) {
                params.menus.push(img.url)
            }
        })
        params.operating_hours = is_hours ? operationalHours : [];
        if (outletType.length < 1) {
            this.setState({ outleTypeError: true, activeKey: "1" })
            errorToaster("Please select outlet type");
            return;
        }
        params.outlet_type_id = outletType;


        if (this.state.is_instoreoffers_open && !this.state.instoreoffers_opening_hour_id) {
            this.setState({ activeKey: "config" })
            errorToaster("Please select In Store Opening Hour");
            return;
        }

        if (this.state.is_booking_open && !this.state.booking_opening_hour_id) {
            this.setState({ activeKey: "config" })
            errorToaster("Please select Booking Opening Hour");
            return;
        }

        if (this.state.is_booking_open && !this.state.minimum_booking_time) {
            this.setState({ activeKey: "config" })
            errorToaster("Please Enter Minimum Booking time");
            return;
        }

        if (this.state.is_delivery_open && !this.state.delivery_opening_hour_id) {
            this.setState({ activeKey: "config" })
            errorToaster("Please select Delivery Opening Hour");
            return;
        }

        params.outlet_config = {
            currency: this.state.currency,
            is_instoreoffers_open: this.state.is_instoreoffers_open,
            instoreoffers_opening_hour_id: this.state.instoreoffers_opening_hour_id,
            is_booking_open: this.state.is_booking_open,
            minimum_booking_time: this.state.minimum_booking_time,
            booking_opening_hour_id: this.state.booking_opening_hour_id,
            is_delivery_open: this.state.is_delivery_open,
            delivery_opening_hour_id: this.state.delivery_opening_hour_id
        }


        if (outletType.includes(2)) {
            params.delivery_timings = deliveryHours;
            if (!radius) {
                this.setState({ radiusError: true, activeKey: "3" })
                errorToaster("Please select radius");
                return;
            }
            params.delivery_radius = parseFloat(radius);
            if (!charges) {
                this.setState({ chargeError: true, activeKey: "3" })
                errorToaster("Please select charges");
                return;
            }
            params.delivery_charges = parseFloat(charges);
            if (!delieveryTime) {
                this.setState({ delieveryTimeError: true, activeKey: "3" })
                errorToaster("Please select delivery time");
                return;
            }
            params.avg_delivery_time = parseInt(delieveryTime);
            if (deliveryPaymentMethod.length < 1) {
                this.setState({ deliveryPaymentMethodError: true, activeKey: "3" })
                errorToaster("Please select delivery payment method");
                return;
            }
            params.delivery_payment_methods = deliveryPaymentMethod;
            params.delivery_min_amount = parseFloat(spend);
            if (!validateNumber(maxOffer)) {
                this.setState({ maxOfferError: true, activeKey: "3" });
                errorToaster("Please enter max offer an integer equal to or greater than 1");
                return;
            }
            else {
                if (Number(maxOffer < 1)) {
                    this.setState({ maxOfferError: true, activeKey: "3" });
                    errorToaster("Please enter max offer an integer equal to or greater than 1");
                    return;
                }
            }
            params.max_vouchers = parseInt(maxOffer);
            if (!selfDelivery && !delivery) {
                this.setState({ deliveryError: true, activeKey: "3" })
                errorToaster("Please choose any one from delivery or self delivery fields");
                return;
            }
            params.self_delivery = selfDelivery;
            params.is_deliver = delivery;
            if (delivery) {
                if (!deliveryPartner) {
                    this.setState({ deliveryPartnerError: true, activeKey: "3" })
                    errorToaster("Please select delivery partner");
                    return;
                }
                params.delivery_partner_id = deliveryPartner;
            }
            params.is_delivery_automatic = is_delivery_automatic;
            params.delivery_partner_is_active = is_active;
        }
        params.landmark_id = landmark;
        params.address_2 = address_2;
        params.arabic_address_2 = arabicAddress_2;
        if (!validateEmail(email)) {
            errorToaster("Invalid Email")
            this.setState({ emailError: true, activeKey: "1" })
            return
        } else params.email = email;

        if (phone_2.length > 0) {
            if (!validatePhone(phone_2)) {
                errorToaster("Invalid Phone Formate")
                this.setState({ phone_2Error: true, activeKey: "1" })
                return
            } else params.phone_2 = phone_2
        } else params.phone_2 = null
        if (phone_3.length > 0) {
            if (!validatePhone(phone_3)) {
                errorToaster("Invalid Phone Formate")
                this.setState({ phone_3Error: true, activeKey: "1" })
                return
            } else params.phone_3 = phone_3
        } else params.phone_3 = null;
        let redemptionEmailArray = redemptionEmail.split(",")
        params.redemption_email = [];
        if (redemptionEmailArray[0].length > 0) {
            for (let i = 0; i < redemptionEmailArray.length; i++) {
                let email = redemptionEmailArray[i].trim();
                if (!validateEmail(email)) {
                    this.setState({ redemptionEmailError: true, activeKey: "1" });
                    errorToaster("Invalid Email: " + email)
                    break;
                } else {
                    params.redemption_email.push(email)
                }
            }
        } else {
            this.setState({ redemptionEmailError: true, activeKey: "1" });
            errorToaster("Please Enter Email Address")
            return;
        }
        if (this.state.redemptionEmailError) {
            return;
        }
        params.info_tag_id = infoTag;
        params.payment_mode_id = payment;
        params.cuisines_id = cuisine;
        params.average_cost = averageCost;
        this.setState({ loading: true })
        MerchantModel.getInstance().updateOutlet(outletId, params,
            function success() {
                setTimeout(() => {
                    history.push("/outlet")
                }, 1000);
            },
            function failure(error) {
                console.log(error)
                errorNotification(error)
                it.setState({ loading: false })
            }
        )
    }



    render() {
        const { match: { params } } = this.props;
        const { loading, merchantList, landMarkList, areaList, countryList, provinceList, cityList, infoTagList, paymentList, cuisinesList, weekDays, nameError, arabicNameError, merchantError, imageListError, arabicAddressError, areaError, countryError, provinceError, cityError, phoneError, menusListError, outletTypeError, radiusError, chargeError, delieveryTimeError, spendError, maxOfferError, pinError, outletTypeList } = this.state;
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                    <Spin spinning={loading} >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                    <div className="kt-portlet__head kt-portlet__head--lg">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">Edit Outlet</h3>
                                        </div>
                                        <div className="kt-portlet__head-toolbar">
                                            <a href="/outlet" className="btn btn-clean kt-margin-r-10">
                                                <i className="la la-arrow-left"></i>
                                                <span className="kt-hidden-mobile">Back</span>
                                            </a>
                                            <div className="btn-group">
                                                <button onClick={() => this.sendData()} type="button" className="btn btn-brand">
                                                    <i className="la la-check"></i>
                                                    <span className="kt-hidden-mobile">Save</span>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                    {/* end header */}
                                    <div className="kt-portlet__body">
                                        <form className="kt-form" id="kt_form">
                                            <div className="row">
                                                {/* <div className="col-xl-2"></div> */}
                                                <div className="col-xl-12">

                                                    <div className="kt-section kt-section--first">

                                                        <div className="kt-section__body">


                                                            <Tabs onChange={(activeKey) => this.setState({ activeKey })} activeKey={this.state.activeKey} defaultActiveKey="1" >

                                                                <TabPane tab="Overview" key="1">
                                                                    <div className="form-group row">
                                                                        <label className="col-xl-2 col-lg-3 col-form-label">Company Logo</label>
                                                                        <div className="col-lg-9 col-xl-6">
                                                                            <div className="kt-avatar kt-avatar--outline kt-avatar--circle" id="kt_apps_user_add_avatar">
                                                                                <div className="kt-avatar__holder" style={{ backgroundImage: `url(${this.state.logo ? this.state.logo : backImage})` }}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">Merchant <b>*</b></label>
                                                                        <div className="col-10">
                                                                            <Select
                                                                                disabled
                                                                                showSearch
                                                                                style={{ margin: "10px 0px 10px 0px" }}
                                                                                onChange={(value) => {
                                                                                    this.setState({
                                                                                        merchant: value,
                                                                                    })
                                                                                    this.getMerchantlogo(value)
                                                                                }}
                                                                                value={this.state.merchant}
                                                                                notFoundContent={
                                                                                    // <Tooltip title="Add Type">
                                                                                    //     <Icon onClick={() => this.appendType()} disabled={false} type="plus-circle" theme="filled" style={{ color: "#3758ff", fontSize: "20px" }} />
                                                                                    // </Tooltip>
                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                        <Icon style={{ fontSize: 16, marginRight: "10px", marginBottom: "5px" }} type="search" />
                                                                                        <p style={{ fontSize: 14 }}>Please Search Merchant Group</p>
                                                                                    </div>
                                                                                }
                                                                                onSearch={(val) => this.searchQueryMerchant(val, this)}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {merchantList.map((item) => (
                                                                                    <Option key={item.id} value={item.id}>{`${item.name} ${item.is_active ? " (active)" : " (in-active)"}`}</Option>
                                                                                ))}
                                                                            </Select>
                                                                            <span className="form-text text-muted"><p style={{ color: merchantError ? "red" : "" }}>Please select merchant</p></span>

                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">Outlet Name <b>*</b></label>
                                                                        <div className="col-10">
                                                                            <input maxLength="70" value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} className="form-control" type="text" />
                                                                            <span className="form-text text-muted"><p style={{ color: nameError ? "red" : "" }}>Please enter name in English</p></span>

                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">Arabic Name</label>
                                                                        <div className="col-10">
                                                                            <input maxLength="100" value={this.state.arabicName} onChange={(event) => this.setState({ arabicName: event.target.value })} className="form-control" type="text" />
                                                                            <span className="form-text text-muted"><p style={{ color: arabicNameError ? "red" : "" }}>Please enter name in Arabic</p></span>

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">Pin <b>*</b></label>
                                                                        <div className="col-10">
                                                                            <input maxLength="4" value={this.state.pin} onChange={(event) => this.setState({ pin: event.target.value })} className="form-control" />
                                                                            <span className="form-text text-muted"><p style={{ color: pinError ? "red" : "" }}>Please enter Pin (Must be 4 digits)</p></span>

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">Images <b>*</b></label>
                                                                        <div className="col-10">
                                                                            <MultipleImages
                                                                                getImages={(image) => this.sendBackImages(image)}
                                                                                removeImage={(image) => this.removeBackImage(image)}
                                                                                loading={this.state.backImageLoading}
                                                                                imageList={this.state.imageList}
                                                                                imageLimit={10}
                                                                            />
                                                                            <span className="form-text text-muted"><p style={{ color: imageListError ? "red" : "" }}>Please Upload background Images</p></span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">Phone 1 <b>*</b></label>
                                                                        <div className="col-10">
                                                                            <input maxLength="16" value={this.state.phone} onChange={(event) => this.setState({ phone: event.target.value })} className="form-control" />
                                                                            <span className="form-text text-muted"><p style={{ color: phoneError ? "red" : "" }}>Please enter Phone Number</p></span>

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">Phone 2</label>
                                                                        <div className="col-10">
                                                                            <input maxLength="16" value={this.state.phone_2 ? this.state.phone_2 : ""} onChange={(event) => this.setState({ phone_2: event.target.value })} className="form-control" type="number" />
                                                                            <span className="form-text text-muted"><p style={{ color: this.state.phone_2Error ? "red" : "" }}>Please enter second phone number</p></span>

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">Phone 3</label>
                                                                        <div className="col-10">
                                                                            <input maxLength="16" value={this.state.phone_3 ? this.state.phone_3 : ""} onChange={(event) => this.setState({ phone_3: event.target.value })} className="form-control" type="number" />
                                                                            <span className="form-text text-muted"><p style={{ color: this.state.phone_3Error ? "red" : "" }}>Please enter third phone number</p></span>

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">Email Address <b>*</b></label>
                                                                        <div className="col-10">
                                                                            <input value={this.state.email ? this.state.email : ""} onChange={(event) => this.setState({ email: event.target.value })} className="form-control" type="text" />
                                                                            <span className="form-text text-muted"><p style={{ color: this.state.emailError ? "red" : "" }}>Please enter email address</p></span>

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">Redemption Email <b>*</b></label>
                                                                        <div className="col-10">
                                                                            <input value={this.state.redemptionEmail} onChange={(event) => this.setState({ redemptionEmail: event.target.value })} className="form-control" type="text" />
                                                                            <span className="form-text text-muted"><p style={{ color: this.state.redemptionEmailError ? "red" : "" }}>Please enter Redemption email address(Comma Separated Value)</p></span>

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="marchantGroup">Info Tag</label>
                                                                        <div className="col-10">

                                                                            <Select
                                                                                showSearch
                                                                                mode="multiple"
                                                                                style={{ margin: "10px 0px 10px 0px" }}
                                                                                value={this.state.infoTag}
                                                                                // placeholder="Select a merchant group"
                                                                                onChange={(value) => this.setState({ infoTag: value })}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {infoTagList.map((tag) => (
                                                                                    <Option key={tag.id} value={tag.id} >{tag.name}</Option>
                                                                                ))}
                                                                            </Select>
                                                                            <span className="form-text text-muted">Please choose Info Tag</span>

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="marchantGroup">Payment Modes</label>
                                                                        <div className="col-10">

                                                                            <Select
                                                                                showSearch
                                                                                mode="multiple"
                                                                                style={{ margin: "10px 0px 10px 0px" }}
                                                                                value={this.state.payment}
                                                                                // placeholder="Select a merchant group"
                                                                                onChange={(value) => this.setState({ payment: value })}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {paymentList.map((payment) => (
                                                                                    <Option key={payment.id} value={payment.id} >{payment.name}</Option>
                                                                                ))}
                                                                            </Select>
                                                                            <span className="form-text text-muted">Please choose payment modes</span>

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">Average Cost for two</label>
                                                                        <div className="col-10">
                                                                            <input value={this.state.averageCost ? this.state.averageCost : ""} maxLength="20" onChange={(event) => this.setState({ averageCost: event.target.value })} className="form-control" type="number" />
                                                                            <span className="form-text text-muted">Please enter average cost for two persons</span>

                                                                        </div>
                                                                    </div>


                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="marchantGroup">Outlet Type <b>*</b></label>
                                                                        <div className="col-10">

                                                                            <Select
                                                                                showSearch
                                                                                mode="multiple"
                                                                                style={{ margin: "10px 0px 10px 0px" }}
                                                                                value={this.state.outletType}
                                                                                onChange={(value) => this.setState({ outletType: value })}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {outletTypeList.map((type) => (
                                                                                    <Option key={type.id} value={type.id} >{type.name}</Option>
                                                                                ))}
                                                                            </Select>
                                                                            <span className="form-text text-muted"><p style={{ color: outletTypeError ? "red" : "" }} >Please choose Outlet Type </p></span>

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">Menu</label>
                                                                        <div className="col-10">
                                                                            <MultipleImages
                                                                                getImages={(image) => this.sendMenuImages(image)}
                                                                                removeImage={(image) => this.removeMenuImage(image)}
                                                                                loading={this.state.menuImageLoading}
                                                                                imageList={this.state.menusList}
                                                                                imageLimit={15}
                                                                            />
                                                                            <span className="form-text text-muted"><p style={{ color: menusListError ? "red" : "" }}>Please Upload Menu Images</p></span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">Show Operating Hours</label>
                                                                        <div className="col-10">
                                                                            <Checkbox checked={this.state.is_hours} onChange={(event) => this.setState({ is_hours: event.target.checked })} className="form-control" style={{ border: "0px" }} />
                                                                            <span className="form-text text-muted">Please check if you want to show operating hours</span>

                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        this.state.is_hours ?
                                                                            <div>
                                                                                <div className="form-group row">
                                                                                    <label className="col-2 col-form-label" htmlFor="marchantGroup">Operational Hours</label>
                                                                                    <div className="col-10">
                                                                                        <div className="row">
                                                                                            <Select
                                                                                                style={{ margin: "10px 0px 10px 0px", width: "30%", marginRight: "5%" }}
                                                                                                value={this.state.currentDay}
                                                                                                onChange={(value) => this.setState({ currentDay: value })}
                                                                                            >
                                                                                                {weekDays.map((day, key) => (
                                                                                                    <Option key={key} value={key} >{day}</Option>
                                                                                                ))}
                                                                                            </Select>
                                                                                            <Select
                                                                                                style={{ margin: "10px 0px 10px 0px", width: "30%", marginRight: "5%" }}
                                                                                                value={this.state.operationalHours[this.state.currentDay].status}
                                                                                                onChange={(value) => {
                                                                                                    this.state.operationalHours[this.state.currentDay].status = value;
                                                                                                    this.forceUpdate();
                                                                                                }}
                                                                                            >
                                                                                                <Option value="open" >Open</Option>
                                                                                                <Option value="closed" >Closed</Option>
                                                                                            </Select>
                                                                                            {
                                                                                                this.state.operationalHours[this.state.currentDay].times.length === 1 ?
                                                                                                    <Tooltip title="Add Time">
                                                                                                        <Icon onClick={() => this.addTime()} style={{ margin: "15px 0px 10px 0px", color: "#3758FF", fontSize: "20px" }} type="plus-circle" theme="filled" />
                                                                                                    </Tooltip> :
                                                                                                    <Tooltip title="Delete Time">
                                                                                                        <Icon onClick={() => this.deleteTime()} style={{ margin: "15px 0px 10px 0px", color: "#3758FF", fontSize: "20px" }} type="minus-circle" theme="filled" />
                                                                                                    </Tooltip>
                                                                                            }


                                                                                        </div>
                                                                                        {
                                                                                            this.state.operationalHours[this.state.currentDay].times.map((time, key) =>
                                                                                            (
                                                                                                <div key={key} className="row">
                                                                                                    <div style={{ width: "30%", marginRight: "5%" }}>
                                                                                                        <TimePicker
                                                                                                            allowClear={false}
                                                                                                            label="Start Time"
                                                                                                            disabled={this.state.operationalHours[this.state.currentDay].status === "closed" ? true : false}
                                                                                                            value={moment(time.start_time, format)}
                                                                                                            use12Hours
                                                                                                            style={{ margin: "10px 0px 10px 0px", width: "100%", marginRight: "5%" }}
                                                                                                            onChange={(value, string) => {
                                                                                                                this.state.operationalHours[this.state.currentDay].times[key].start_time = string
                                                                                                                this.forceUpdate();
                                                                                                                this.disabledHours(moment(time.start_time, format).hour())
                                                                                                                this.checkEndTime()
                                                                                                            }}
                                                                                                            format={format} />
                                                                                                        {/* <span className="form-text text-muted">Start Time</span> */}
                                                                                                    </div>

                                                                                                    <div style={{ width: "30%" }}>
                                                                                                        <TimePicker
                                                                                                            allowClear={false}
                                                                                                            disabled={this.state.operationalHours[this.state.currentDay].status === "closed" ? true : false}
                                                                                                            disabledHours={() => this.state.disabledHours}
                                                                                                            value={moment(time.end_time, format)}
                                                                                                            use12Hours
                                                                                                            style={{ margin: "10px 0px 10px 0px", width: "100%" }}
                                                                                                            onChange={(value, string) => {
                                                                                                                this.state.operationalHours[this.state.currentDay].times[key].end_time = string
                                                                                                                this.forceUpdate();
                                                                                                            }}
                                                                                                            format={format} />
                                                                                                        {/* <span className="form-text text-muted">End Time</span> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                            )
                                                                                        }


                                                                                    </div>
                                                                                </div>

                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Day of Week</th>
                                                                                            <th>Status</th>
                                                                                            <th>Timing</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            this.state.operationalHours.map((days, index) => (
                                                                                                <tr key={index}>
                                                                                                    <td>{this.state.weekDays[index]}</td>
                                                                                                    <td>{days.status}</td>

                                                                                                    {
                                                                                                        days.status === "open" ?
                                                                                                            <td>
                                                                                                                <div>
                                                                                                                    {
                                                                                                                        days.times.map((hour, j) => (
                                                                                                                            <p key={j}>
                                                                                                                                {hour.start_time} - {hour.end_time}
                                                                                                                            </p>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                </div>

                                                                                                            </td>
                                                                                                            :
                                                                                                            <td></td>
                                                                                                    }
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>


                                                                            </div>
                                                                            : null
                                                                    }

                                                                </TabPane>

                                                                <TabPane tab="Config" key="config">

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="marchantGroup">Currency <b>*</b></label>
                                                                        <div className="col-10">
                                                                            <Select
                                                                                showSearch
                                                                                style={{ margin: "10px 0px 10px 0px" }}
                                                                                value={this.state.currency}
                                                                                // placeholder="Select a merchant group"
                                                                                onChange={(currency) => {
                                                                                    this.setState({ currency })
                                                                                }}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {CURRENCY_LIST.map((curr) => (
                                                                                    <Option key={curr} value={curr} >{curr}</Option>
                                                                                ))}
                                                                            </Select>
                                                                            <span className="form-text text-muted">Please choose Currency</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="marchantGroup">In Store <b>*</b></label>
                                                                        <div className="col-3"> <Switch checked={this.state.is_instoreoffers_open} onChange={(is_instoreoffers_open) => this.setState({ is_instoreoffers_open })} /></div>
                                                                        {this.state.is_instoreoffers_open &&
                                                                            <div className="col-6">
                                                                                <Select
                                                                                    showSearch
                                                                                    style={{ margin: "10px 0px 10px 0px" }}
                                                                                    // placeholder="Select a merchant group"
                                                                                    value={this.state.instoreoffers_opening_hour_id}
                                                                                    onChange={(instoreoffers_opening_hour_id) => {
                                                                                        this.setState({ instoreoffers_opening_hour_id })
                                                                                    }}
                                                                                    filterOption={(input, option) =>
                                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                    }
                                                                                    onSearch={(val) => this.searchQueryOpeningHour(val, this)}
                                                                                >
                                                                                    {this.state.opening_hour_list.map((_hour) => (
                                                                                        <Option key={_hour.id} value={_hour.id} >{_hour.name}</Option>
                                                                                    ))}
                                                                                </Select>
                                                                                <span className="form-text text-muted">Please Select In store Hour</span>
                                                                            </div>}
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="marchantGroup">Booking <b>*</b></label>
                                                                        <div className="col-3"> <Switch checked={this.state.is_booking_open} onChange={(is_booking_open) => this.setState({ is_booking_open })} /></div>
                                                                        {this.state.is_booking_open &&
                                                                            <div className="col-6">
                                                                                <Select
                                                                                    showSearch
                                                                                    style={{ margin: "10px 0px 10px 0px" }}
                                                                                    value={this.state.booking_opening_hour_id}
                                                                                    onChange={(booking_opening_hour_id) => {
                                                                                        this.setState({ booking_opening_hour_id })
                                                                                    }}
                                                                                    filterOption={(input, option) =>
                                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                    }
                                                                                    onSearch={(val) => this.searchQueryOpeningHour(val, this)}
                                                                                >
                                                                                    {this.state.opening_hour_list.map((_hour) => (
                                                                                        <Option key={_hour.id} value={_hour.id} >{_hour.name}</Option>
                                                                                    ))}
                                                                                </Select>
                                                                                <span className="form-text text-muted">Please Select Booking Hour</span>
                                                                            </div>}
                                                                    </div>

                                                                    {this.state.is_booking_open &&
                                                                        <div className="form-group row">
                                                                            <label className="col-2 col-form-label" htmlFor="marchantGroup">Minimum Booking Time <b>*</b></label>
                                                                            <div className="col-10">
                                                                                <Input
                                                                                    type="number"
                                                                                    style={{ margin: "10px 0px 10px 0px" }}
                                                                                    value={this.state.minimum_booking_time}
                                                                                    onChange={(minimum_booking_time) => {
                                                                                        if (minimum_booking_time.target.value < 0 || minimum_booking_time.target.value === null || minimum_booking_time.target.value === 'undefined') {
                                                                                            this.setState({ minimum_booking_time: 0 })
                                                                                        } else {
                                                                                            this.setState({ minimum_booking_time: minimum_booking_time.target.value })

                                                                                        }

                                                                                    }}
                                                                                />
                                                                                <span className="form-text text-muted">Please Select Minimum Booking Time</span>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="marchantGroup">Delivery <b>*</b></label>
                                                                        <div className="col-3"> <Switch checked={this.state.is_delivery_open} onChange={(is_delivery_open) => this.setState({ is_delivery_open })} /></div>
                                                                        {this.state.is_delivery_open &&
                                                                            <div className="col-6">
                                                                                <Select
                                                                                    showSearch
                                                                                    style={{ margin: "10px 0px 10px 0px" }}
                                                                                    value={this.state.delivery_opening_hour_id}
                                                                                    onChange={(delivery_opening_hour_id) => {
                                                                                        this.setState({ delivery_opening_hour_id })
                                                                                    }}
                                                                                    filterOption={(input, option) =>
                                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                    }
                                                                                    onSearch={(val) => this.searchQueryOpeningHour(val, this)}
                                                                                >
                                                                                    {this.state.opening_hour_list.map((_hour) => (
                                                                                        <Option key={_hour.id} value={_hour.id} >{_hour.name}</Option>
                                                                                    ))}
                                                                                </Select>
                                                                                <span className="form-text text-muted">Please Select Delivery Hour</span>
                                                                            </div>}

                                                                    </div>
                                                                </TabPane>

                                                                <TabPane tab="Location" key="2">
                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">lat & Long <b>*</b></label>
                                                                        <div className="col-10">

                                                                            <div className="row">
                                                                                <Input
                                                                                    // disabled={!this.state.fromCoordinates}
                                                                                    style={{ margin: "10px 0px 10px 0px", width: "30%", marginRight: "5%", marginLeft: "2%" }}
                                                                                    value={this.state.lat}
                                                                                    onChange={(event) => this.setState({ lat: Number(event.target.value) })}
                                                                                />
                                                                                <Input
                                                                                    // disabled={!this.state.fromCoordinates}
                                                                                    style={{ margin: "10px 0px 10px 0px", width: "30%", marginRight: "5%" }}
                                                                                    value={this.state.lng}
                                                                                    onChange={(event) => this.setState({ lng: Number(event.target.value) })}
                                                                                />
                                                                                <span className="kt-hidden-mobile"> <p style={{ color: this.state.latLngError ? "red" : "" }}> Please Enter Latitude and Longitude</p></span>
                                                                                {/* <div className="btn-group">
                                                                            <button disabled={!this.state.fromCoordinates} onClick={() => this.getReverseGeo()} type="button" className="btn btn-brand">
                                                                                {/* <i className="la la-check"></i> */}
                                                                                {/* <span className="kt-hidden-mobile">Get Location</span>
                                                                            </button>

                                                                        </div> */}


                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label">English Address 1 <b>*</b></label>
                                                                        <div className="col-10">
                                                                            {/* <AutoComplete
                                                                        disabled={this.state.fromCoordinates}
                                                                        address={this.state.addressString}
                                                                        addressString={(english_name) => {
                                                                            let newAddress = english_name.split(",");
                                                                            this.setState({ address: newAddress[0] })

                                                                        }}
                                                                        getAddress={(address) => {
                                                                            this.setState({
                                                                                addressString: address.formatted_address,
                                                                                lat: address.geometry.location.lat(),
                                                                                lng: address.geometry.location.lng(),
                                                                                tempLat: address.geometry.location.lat(),
                                                                                tempLng: address.geometry.location.lng(),
                                                                            })
                                                                        }}
                                                                        types={["establishment"]}
                                                                        setAddress={(addressString) => this.setState({ addressString, lat: 0, lng: 0 })}
                                                                    /> */}
                                                                            <div className="input-group">
                                                                                <textarea maxLength="150" value={this.state.address ? this.state.address : ""} onChange={(event) => this.setState({ address: event.target.value })} className="form-control" rows="3"></textarea>
                                                                            </div>
                                                                            <span className="form-text text-muted"> <p style={{ color: this.state.addressError ? "red" : "" }}> Please enter outlet address in English</p></span>

                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="form-group row">
                                                                <div className="col-12">
                                                                    <div className="input-group">
                                                                        <Map
                                                                            containerElement={<div style={{ height: "300px", width: "100%" }} />}
                                                                            mapElement={<div style={{ height: "100%" }} />}
                                                                            lat={this.state.lat}
                                                                            lng={this.state.lng}
                                                                            isMarkerShown={this.state.lat !== 0 && this.state.lng !== 0}
                                                                            clickable={true}
                                                                            setMarker={(location) => {
                                                                                this.setState({
                                                                                    tempLat: location.lat(),
                                                                                    tempLng: location.lng()
                                                                                })
                                                                                this.getReverseGeo();
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div> */}


                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="exampleTextarea">English Address 2</label>
                                                                        <div className="col-10">
                                                                            <div className="input-group">
                                                                                <textarea maxLength="150" value={this.state.address_2 ? this.state.address_2 : ""} onChange={(event) => this.setState({ address_2: event.target.value })} className="form-control" rows="3"></textarea>
                                                                            </div>
                                                                            <span className="form-text text-muted">Please enter outlet address in English</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="exampleTextarea">Arabic Address 1</label>
                                                                        <div className="col-10">
                                                                            <div className="input-group">
                                                                                <textarea maxLength="150" value={this.state.arabicAddress ? this.state.arabicAddress : ""} onChange={(event) => this.setState({ arabicAddress: event.target.value })} className="form-control" rows="3"></textarea>
                                                                            </div>
                                                                            <span className="form-text text-muted"><p style={{ color: arabicAddressError ? "red" : "" }}>Please enter outlet address in Arabic</p></span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="exampleTextarea">Arabic Address 2</label>
                                                                        <div className="col-10">
                                                                            <div className="input-group">
                                                                                <textarea maxLength="150" value={this.state.arabicAddress_2 ? this.state.arabicAddress_2 : ""} onChange={(event) => this.setState({ arabicAddress_2: event.target.value })} className="form-control" rows="3"></textarea>
                                                                            </div>
                                                                            <span className="form-text text-muted">Please enter outlet address in Arabic</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="marchantGroup">Country <b>*</b></label>
                                                                        <div className="col-10">

                                                                            <Select
                                                                                showSearch
                                                                                style={{ margin: "10px 0px 10px 0px" }}
                                                                                // placeholder="Select a merchant group"
                                                                                value={this.state.country}
                                                                                onChange={(value) => {
                                                                                    this.setState({ country: value, province: null, city: null, area: null })
                                                                                    this.getProvince(value)
                                                                                }}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {countryList.map((country) => (
                                                                                    <Option key={country.id} value={country.id} >{country.name}</Option>
                                                                                ))}
                                                                            </Select>
                                                                            <span className="form-text text-muted"><p style={{ color: countryError ? "red" : "" }}>Please choose Country</p></span>

                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="marchantGroup">Province <b>*</b></label>
                                                                        <div className="col-10">

                                                                            <Select
                                                                                showSearch
                                                                                style={{ margin: "10px 0px 10px 0px" }}
                                                                                // placeholder="Select a merchant group"
                                                                                value={this.state.province}
                                                                                onChange={(value) => {
                                                                                    this.setState({ province: value, city: null, area: null })
                                                                                    this.getCities(value)
                                                                                }}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {provinceList.map((province) => (
                                                                                    <Option key={province.id} value={province.id} >{province.name}</Option>
                                                                                ))}
                                                                            </Select>
                                                                            <span className="form-text text-muted"><p style={{ color: provinceError ? "red" : "" }}>Please choose Province</p></span>

                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="marchantGroup">City <b>*</b></label>
                                                                        <div className="col-10">

                                                                            <Select
                                                                                showSearch
                                                                                style={{ margin: "10px 0px 10px 0px" }}
                                                                                value={this.state.city}
                                                                                // placeholder="Select a merchant group"
                                                                                onChange={(value) => {
                                                                                    this.setState({ city: value, area: null })
                                                                                    this.getAreas(value)
                                                                                }}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {cityList.map((city) => (
                                                                                    <Option key={city.id} value={city.id} >{city.name}</Option>
                                                                                ))}
                                                                            </Select>
                                                                            <span className="form-text text-muted"><p style={{ color: cityError ? "red" : "" }}>Please choose City</p></span>

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="marchantGroup">Area <b>*</b></label>
                                                                        <div className="col-10">

                                                                            <Select
                                                                                showSearch
                                                                                style={{ margin: "10px 0px 10px 0px" }}
                                                                                // placeholder="Select a merchant group"
                                                                                value={this.state.area}
                                                                                onChange={(value) => {
                                                                                    this.setState({ area: value, landmark: null, landMarkList: [] })
                                                                                    this.getAreaLandmark(value)
                                                                                }}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {areaList.map((area) => (
                                                                                    <Option key={area.id} value={area.id} >{area.name}</Option>
                                                                                ))}
                                                                            </Select>
                                                                            <span className="form-text text-muted"><p style={{ color: areaError ? "red" : "" }}>Please choose Area</p></span>

                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-2 col-form-label" htmlFor="marchantGroup">Landmark</label>
                                                                        <div className="col-10">

                                                                            <Select
                                                                                showSearch
                                                                                style={{ margin: "10px 0px 10px 0px" }}
                                                                                allowClear
                                                                                // placeholder="Select a merchant group"
                                                                                value={this.state.landmark}
                                                                                onChange={(value) => this.setState({ landmark: value })}
                                                                                filterOption={(input, option) =>
                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {landMarkList.map((landmark) => (
                                                                                    <Option key={landmark.id} value={landmark.id} >{landmark.name}</Option>
                                                                                ))}
                                                                            </Select>
                                                                            <span className="form-text text-muted">Please choose Landmark</span>

                                                                        </div>
                                                                    </div>
                                                                </TabPane>

                                                                {
                                                                    this.state.outletType.includes(2) ?
                                                                        <TabPane tab="Delivery" key="3">
                                                                            <div>
                                                                                <div className="form-group row">
                                                                                    <label className="col-2 col-form-label" htmlFor="marchantGroup">Delivery Timings </label>
                                                                                    <div className="col-10">


                                                                                        <div className="row">
                                                                                            <Select
                                                                                                style={{ margin: "10px 0px 10px 0px", width: "30%", marginRight: "5%" }}
                                                                                                value={this.state.currentDelieveryDay}
                                                                                                onChange={(value) => this.setState({ currentDelieveryDay: value })}
                                                                                            >
                                                                                                {weekDays.map((day, key) => (
                                                                                                    <Option key={key} value={key} >{day}</Option>
                                                                                                ))}
                                                                                            </Select>
                                                                                            <Select
                                                                                                style={{ margin: "10px 0px 10px 0px", width: "30%", marginRight: "5%" }}
                                                                                                value={this.state.deliveryHours[this.state.currentDelieveryDay].status}
                                                                                                onChange={(value) => {
                                                                                                    this.state.deliveryHours[this.state.currentDelieveryDay].status = value;
                                                                                                    this.forceUpdate();
                                                                                                }}
                                                                                            >
                                                                                                <Option value="open" >Open</Option>
                                                                                                <Option value="closed" >Closed</Option>
                                                                                            </Select>
                                                                                            {
                                                                                                this.state.deliveryHours[this.state.currentDelieveryDay].times.length === 1 ?
                                                                                                    <Tooltip title="Add Time">
                                                                                                        <Icon onClick={() => this.addDelieveryTime()} style={{ margin: "15px 0px 10px 0px", color: "#3758FF", fontSize: "20px" }} type="plus-circle" theme="filled" />
                                                                                                    </Tooltip> :
                                                                                                    <Tooltip title="Delete Time">
                                                                                                        <Icon onClick={() => this.deleteDelieveryTime()} style={{ margin: "15px 0px 10px 0px", color: "#3758FF", fontSize: "20px" }} type="minus-circle" theme="filled" />
                                                                                                    </Tooltip>
                                                                                            }


                                                                                        </div>
                                                                                        {
                                                                                            this.state.deliveryHours[this.state.currentDelieveryDay].times.map((time, key) =>
                                                                                            (
                                                                                                <div key={key} className="row">
                                                                                                    <div style={{ width: "30%", marginRight: "5%" }}>
                                                                                                        <TimePicker
                                                                                                            allowClear={false}
                                                                                                            label="Start Time"
                                                                                                            disabled={this.state.deliveryHours[this.state.currentDelieveryDay].status === "closed" ? true : false}
                                                                                                            value={moment(time.start_time, format)}
                                                                                                            use12Hours
                                                                                                            style={{ margin: "10px 0px 10px 0px", width: "100%", marginRight: "5%" }}
                                                                                                            onChange={(value, string) => {
                                                                                                                this.state.deliveryHours[this.state.currentDelieveryDay].times[key].start_time = string
                                                                                                                this.forceUpdate();
                                                                                                                this.disabledDelieveryHours(moment(time.start_time, format).hour())
                                                                                                                this.checkEndDelieveryTime()
                                                                                                            }}
                                                                                                            format={format} />
                                                                                                        <span className="form-text text-muted">Start Time</span>
                                                                                                    </div>

                                                                                                    <div style={{ width: "30%" }}>
                                                                                                        <TimePicker
                                                                                                            allowClear={false}
                                                                                                            disabled={this.state.deliveryHours[this.state.currentDelieveryDay].status === "closed" ? true : false}
                                                                                                            disabledHours={() => this.state.disabledDelieveryHours}
                                                                                                            value={moment(time.end_time, format)}
                                                                                                            use12Hours
                                                                                                            style={{ margin: "10px 0px 10px 0px", width: "100%" }}
                                                                                                            onChange={(value, string) => {
                                                                                                                this.state.deliveryHours[this.state.currentDelieveryDay].times[key].end_time = string
                                                                                                                this.forceUpdate();
                                                                                                            }}
                                                                                                            format={format} />
                                                                                                        {/* <span className="form-text text-muted">End Time</span> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                            )
                                                                                        }

                                                                                    </div>

                                                                                    <table className="table table-striped">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Day of Week</th>
                                                                                                <th>Status</th>
                                                                                                <th>Timing</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                this.state.deliveryHours.map((days, index) => (
                                                                                                    <tr key={index}>
                                                                                                        <td>{this.state.weekDays[index]}</td>
                                                                                                        <td>{days.status}</td>

                                                                                                        {
                                                                                                            days.status === "open" ?
                                                                                                                <td>
                                                                                                                    <div>
                                                                                                                        {
                                                                                                                            days.times.map((hour, j) => (
                                                                                                                                <p key={j}>
                                                                                                                                    {hour.start_time} - {hour.end_time}
                                                                                                                                </p>
                                                                                                                            ))
                                                                                                                        }
                                                                                                                    </div>

                                                                                                                </td>
                                                                                                                :
                                                                                                                <td></td>
                                                                                                        }
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>

                                                                            <div className="form-group row">
                                                                                <label className="col-2 col-form-label" htmlFor="marchantGroup">Cuisine</label>
                                                                                <div className="col-10">

                                                                                    <Select
                                                                                        showSearch
                                                                                        mode="multiple"
                                                                                        style={{ margin: "10px 0px 10px 0px" }}
                                                                                        value={this.state.cuisine}
                                                                                        // placeholder="Select a merchant group"
                                                                                        onChange={(value) => this.setState({ cuisine: value })}
                                                                                        filterOption={(input, option) =>
                                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                        }
                                                                                    >
                                                                                        {cuisinesList.map((cuisine) => (
                                                                                            <Option key={cuisine.id} value={cuisine.id} >{cuisine.name}</Option>
                                                                                        ))}
                                                                                    </Select>
                                                                                    <span className="form-text text-muted">Please choose Cuisine</span>

                                                                                </div>
                                                                            </div>

                                                                            <div className="form-group row">
                                                                                <label className="col-2 col-form-label">Delivery Radius (km) <b>*</b></label>
                                                                                <div className="col-10">
                                                                                    <Input
                                                                                        style={{ margin: "10px 0px 10px 0px" }}
                                                                                        value={this.state.radius}
                                                                                        onChange={(event) => this.setState({ radius: event.target.value })}
                                                                                    />
                                                                                    <span className="form-text text-muted"><p style={{ color: radiusError ? "red" : "" }} >Please enter radius</p></span>

                                                                                </div>
                                                                            </div>

                                                                            <div className="form-group row">
                                                                                <label className="col-2 col-form-label">Delivery Charges <b>*</b></label>
                                                                                <div className="col-10">
                                                                                    <Input
                                                                                        style={{ margin: "10px 0px 10px 0px" }}
                                                                                        value={this.state.charges}
                                                                                        onChange={(event) => this.setState({ charges: event.target.value })}
                                                                                    />
                                                                                    <span className="form-text text-muted"><p style={{ color: chargeError ? "red" : "" }} >Please enter delievery charges</p></span>

                                                                                </div>
                                                                            </div>

                                                                            <div className="form-group row">
                                                                                <label className="col-2 col-form-label">Avg. delievery time (min) <b>*</b></label>
                                                                                <div className="col-10">
                                                                                    <Input
                                                                                        style={{ margin: "10px 0px 10px 0px" }}
                                                                                        value={this.state.delieveryTime}
                                                                                        onChange={(event) => this.setState({ delieveryTime: event.target.value })}
                                                                                    />
                                                                                    <span className="form-text text-muted"><p style={{ color: delieveryTimeError ? "red" : "" }} >Please enter delievery time</p></span>

                                                                                </div>
                                                                            </div>

                                                                            <div className="form-group row">
                                                                                <label className="col-2 col-form-label">Minimum spend </label>
                                                                                <div className="col-10">
                                                                                    <Input
                                                                                        style={{ margin: "10px 0px 10px 0px" }}
                                                                                        value={this.state.spend}
                                                                                        onChange={(event) => this.setState({ spend: event.target.value })}
                                                                                    />
                                                                                    <span className="form-text text-muted"><p style={{ color: spendError ? "red" : "" }} >Please enter minimum spend </p></span>

                                                                                </div>
                                                                            </div>

                                                                            <div className="form-group row">
                                                                                <label className="col-2 col-form-label" htmlFor="marchantGroup">Delivery Payment Method <b>*</b></label>
                                                                                <div className="col-10">

                                                                                    <Select
                                                                                        showSearch
                                                                                        mode="multiple"
                                                                                        style={{ margin: "10px 0px 10px 0px" }}
                                                                                        value={this.state.deliveryPaymentMethod}
                                                                                        onChange={(value) => this.setState({ deliveryPaymentMethod: value })}
                                                                                        filterOption={(input, option) =>
                                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                        }
                                                                                    >
                                                                                        {this.state.deliveryPaymentMethodList.map((type) => (
                                                                                            <Option key={type.value} value={type.value} >{type.name}</Option>
                                                                                        ))}
                                                                                    </Select>
                                                                                    <span className="form-text text-muted"><p style={{ color: this.state.deliveryPaymentMethodError ? "red" : "" }} >Please choose Delivery payment method </p></span>

                                                                                </div>
                                                                            </div>

                                                                            <div className="form-group row">
                                                                                <label className="col-2 col-form-label">Maximum offers<b>*</b> </label>
                                                                                <div className="col-10">
                                                                                    <Input
                                                                                        style={{ margin: "10px 0px 10px 0px" }}
                                                                                        value={this.state.maxOffer}
                                                                                        onChange={(event) => this.setState({ maxOffer: event.target.value })}
                                                                                    />
                                                                                    <span className="form-text text-muted"><p style={{ color: maxOfferError ? "red" : "" }} >Please enter maximum offers </p></span>

                                                                                </div>
                                                                            </div>

                                                                            <div className="form-group row">
                                                                                <label className="col-2 col-form-label">Self Delivery </label>
                                                                                <div className="col-10">
                                                                                    <Checkbox checked={this.state.selfDelivery} onChange={(event) => this.setState({ selfDelivery: event.target.checked })} className="form-control" style={{ border: "0px" }} />
                                                                                    <span className="form-text text-muted">Please check if self delivery </span>

                                                                                </div>
                                                                            </div>

                                                                            <div className="form-group row">
                                                                                <label className="col-2 col-form-label">Delivery</label>
                                                                                <div className="col-10">
                                                                                    <Checkbox checked={this.state.delivery} onChange={(event) => this.setState({ delivery: event.target.checked, deliveryPartner: null })} className="form-control" style={{ border: "0px" }} />
                                                                                    <span className="form-text text-muted">Please check if delivery is available</span>

                                                                                </div>
                                                                            </div>

                                                                            {this.state.delivery ?

                                                                                <div>

                                                                                    <div className="form-group row">
                                                                                        <label className="col-2 col-form-label" htmlFor="marchantGroup">Delivery Partner <b>*</b> </label>
                                                                                        <div className="col-10">

                                                                                            <Select
                                                                                                showSearch
                                                                                                style={{ margin: "10px 0px 10px 0px" }}
                                                                                                value={this.state.deliveryPartner}
                                                                                                onChange={(value) => this.setState({ deliveryPartner: value })}
                                                                                                filterOption={(input, option) =>
                                                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                                }
                                                                                            >
                                                                                                {this.state.deliveryPartnerList.map((delivery) => (
                                                                                                    <Option key={delivery.id} value={delivery.id} >{delivery.name}</Option>
                                                                                                ))}
                                                                                            </Select>
                                                                                            <span className="form-text text-muted">Please choose delivery partner </span>

                                                                                        </div>
                                                                                    </div>

                                                                                    {
                                                                                        this.state.deliveryPartner == 1 ?
                                                                                            <div>

                                                                                                <div className="form-group row">
                                                                                                    <label className="col-2 col-form-label">Delivery Pickup</label>
                                                                                                    <div className="col-10">
                                                                                                        <Checkbox checked={this.state.is_delivery_automatic} onChange={(event) => this.setState({ is_delivery_automatic: event.target.checked })} className="form-control" style={{ border: "0px" }} />
                                                                                                        <span className="form-text text-muted">Please check if delivery pickup is automatic</span>

                                                                                                    </div>
                                                                                                </div>


                                                                                                <div className="form-group row">
                                                                                                    <label className="col-2 col-form-label">Delivery Partner Action </label>
                                                                                                    <div className="col-10">
                                                                                                        <Checkbox checked={this.state.is_active} onChange={(event) => this.setState({ is_active: event.target.checked })} className="form-control" style={{ border: "0px" }} />
                                                                                                        <span className="form-text text-muted">Please check if delivery is active</span>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <table className="table table-striped">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>Delivery Company</th>
                                                                                                            <th>Delivery Pickup(Automatic/Manual)</th>
                                                                                                            <th>Active/Inactive</th>

                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                {`Mr. Delivery`}
                                                                                                            </td>

                                                                                                            {this.state.is_delivery_automatic ?
                                                                                                                <td>
                                                                                                                    {`Automatic`}
                                                                                                                </td>
                                                                                                                :
                                                                                                                <td>
                                                                                                                    {`Manual`}
                                                                                                                </td>
                                                                                                            }
                                                                                                            {this.state.is_active ?
                                                                                                                <td>
                                                                                                                    {`Active`}
                                                                                                                </td>
                                                                                                                :
                                                                                                                <td>
                                                                                                                    {`In-active`}
                                                                                                                </td>
                                                                                                            }
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                                <br></br>
                                                                                                <br></br>
                                                                                                <br></br>

                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                                : null
                                                                            }
                                                                        </TabPane>
                                                                        : null
                                                                }

                                                                <TabPane tab="Contact" key="4">
                                                                    <ContactPocView
                                                                        is_outlet={true}
                                                                        id={params.outletId}
                                                                    />
                                                                </TabPane>

                                                                <TabPane tab="Classifications" key="5">
                                                                    <OutletClassifications
                                                                        is_outlet={true}
                                                                        refresh_data={() => this.getOutletClassifications(params.outletId)}
                                                                        outlet_data={{
                                                                            id: params.outletId,
                                                                            name: this.state.name,
                                                                        }}
                                                                        outletClassificationList={this.state.outletClassificationList}
                                                                    />
                                                                </TabPane>

                                                                <TabPane tab="Bookings" key="6">
                                                                    <ProductBooking
                                                                        is_outlet={true}
                                                                        outlet_id={this.state.outletId}
                                                                    />
                                                                </TabPane>

                                                                <TabPane tab="Offers" key="7">
                                                                    <Offers
                                                                        is_outlet={true}
                                                                        outlet_id={this.state.outletId}
                                                                    />
                                                                </TabPane>

                                                                <TabPane tab="Delivery Offers" key="8">
                                                                    <EcommerceOffer
                                                                        is_outlet={true}
                                                                        outlet_id={this.state.outletId}
                                                                    />
                                                                </TabPane>

                                                            </Tabs>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2"></div>

                                            </div>
                                        </form>
                                    </div>
                                    {/* end body */}
                                </div>
                            </div>
                        </div>
                    </Spin >
                </div >
            </div >
        );
    }
}