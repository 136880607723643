import React from 'react'
import Header from './Header';
import SideMenu from './SideMenu';
import Footer from './Footer';

import backImage from '../../assets/media/bg/bg-3.jpg';


export default class NotFound extends React.Component {



    render() {

        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <div className="kt-grid kt-grid--ver kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v1" style={{ backgroundImage: `url(${backImage})`, textAlign: 'center' }}>
                        <div className="kt-error-v1__container">
                            <center><h6 style={{ fontSize: 30 }} className="kt-error-v1__number"> Requested Information not found</h6></center>
                            <p className="kt-error-v1__desc">
                                {/* OOPS! Page not found that are you are looking for */}
					                </p>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}