import { validateURL } from "../../Helpers/Shared/Validators";

export function validateProductBookingParams(params) {
    if (params.outlet_id.length < 1) {
        return {
            activeKey: "1",
            status: false,
            message: "Please Select Outlet",
        };
    }
    if (!params.name || params.name.length < 1) {
        return {
            activeKey: "1",
            status: false,
            message: "Please Enter Name",
        };
    }
    if (!params.description || params.description.length < 1) {
        return {
            activeKey: "1",
            status: false,
            message: "Please Enter Description",
        };
    }
    if (!params.product_visibility_id) {
        return {
            activeKey: "1",
            status: false,
            message: "Please Select Product Visibility",
        };
    }
    if (!params.minimum_booking_time || params.minimum_booking_time.length < 1) {
        return {
            activeKey: "1",
            status: false,
            message: "Please Add Minimum Booking Time",
        };
    }
    return {
        status: true,
    };
}

export function validateBookingConfigParams(params) {
    if (params.is_default) {
        return {
            status: true,
        };
    }
    if (!params.section_title || params.section_title.trim().length < 1) {
        return {
            status: false,
            message: "Please enter Section Name",
        };
    }
    if (!params.description || params.description.trim().length < 1) {
        return {
            status: false,
            message: "Please enter Section Description",
        };
    }
    if (!params.product_visibility_id) {
        return {
            status: false,
            message: "Please Choose Product Visibility",
        };
    }
    if (!params.category_id) {
        return {
            status: false,
            message: "Please Choose category",
        };
    }
    if (!params.section_order) {
        return {
            status: false,
            message: "Please Enter Section Order",
        };
    }
    if (Number(params.minimum_selection) > Number(params.maximum_selection)) {
        return {
            status: false,
            message: "Maximum Selection Should be Greater than Minimum Selection",
        };
    }
    return {
        status: true,
    };
}

export function validateConfigurationParams(params, type) {
    if (type === 0) {
        if (!validateURL(params.video_url)) {
            return {
                activeKey: "3",
                status: false,
                message: "Please enter valid Video URL in Configuration",
            };
        }
    }
    if (type === 1) {
        if (!validateURL(params.url)) {
            return {
                activeKey: "3",
                status: false,
                message: "Please enter valid URL in Configuration",
            };
        }
    }
    if (type === 2) {
        // if (params.code.length < 3) {
        //     return {
        //         activeKey: '3',
        //         status: false,
        //         message: 'Please enter valid code in Configuration'
        //     }
        // }
        // if (params.max_usage < 1) {
        //     return {
        //         activeKey: '3',
        //         status: false,
        //         message: 'Max usage must be greater than 0 in Configuration'
        //     }
        // }
        if (!params.url || (params.url.length > 0 && !validateURL(params.url))) {
            return {
                activeKey: "3",
                status: false,
                message: "Please enter valid URL in Configuration",
            };
        }
    }
    if (!params.category_id) {
        return {
            activeKey: "3",
            status: false,
            message: "Please Select Category in Configuration",
        };
    }
    return {
        status: true,
    };
}

export function validateProductBookingAddonParams(params) {
    if (!params.name || params.name.trim().length < 1) {
        return {
            status: false,
            message: "Please enter Name",
        };
    }
    if (!params.description || params.description.trim().length < 1) {
        return {
            status: false,
            message: "Please enter Section Description",
        };
    }
    if (!params.product_booking_config_id) {
        return {
            status: false,
            message: "Please Select Booking Config",
        };
    }
    if (params.list_price.length < 1)
        return {
            status: false,
            message: "Please Enter List Price",
        };
    if (params.list_price.length < 1)
        return {
            status: false,
            message: "Please Enter Discounted Price",
        };
    if (Number(params.list_price) < Number(params.discounted_price))
        return {
            status: false,
            message: "Discounted Price should be Less than List Price",
        };
    return {
        status: true,
    };
}
