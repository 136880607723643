import React from 'react';
import {
    Select,
    Spin
} from 'antd'
import {
    successNotification,
    errorNotification,
    errorToaster
} from '../Shared/Notifications'
import CouponModel from '../../Models/CouponModel';
import { validateBin } from '../../Helpers/Shared/Validators';

const { Option } = Select;
const product_type_list = [
    {
        option: 'Credit',
        value: 'credit'
    },
    {
        option: 'Debit',
        value: 'debit'
    },
    {
        option: 'Prepaid',
        value: 'prepaid'
    },
]

export default class AddBankBin extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: false,
            bank_name: '',
            bank_name_error: false,
            bin: '',
            bin_error: false,
            product_name: '',
            product_name_error: null,
            product_type: null,
            product_type_error: false,
        }
    }

    componentWillMount() {
        const role_id = localStorage.getItem("user_role")
        switch (Number(role_id)) {
            case 1:
                break;
            case 5:
                window.location.href = '/order-report'
                break;
            default:
                window.location.href = '/login'
                break;
        }
    }

    sendData() {
        const { bank_name, bin, product_type, product_name } = this.state,
            text = 'Validation Error! Please check fields',
            it = this;
        this.setState({
            nameError: false,
            arb_nameError: false,
            provinceError: false,
            countryError: false
        })
        if (bank_name.length < 1) {
            this.setState({ bank_name_error: true })
            errorToaster(text);
            return
        }
        if (!validateBin(bin)) {
            this.setState({ bin_error: true })
            errorToaster(text);
            return
        }
        if (product_name.length < 1) {
            this.setState({ product_name_error: true })
            errorToaster(text);
            return
        }
        if (!product_type) {
            this.setState({ product_type_error: true })
            errorToaster(text);
            return
        }
        const params = {
            bank_name: bank_name.trim(),
            bin: bin.trim(),
            product_type,
            product_name: product_name.trim()
        }
        this.setState({ loading: true })
        CouponModel.getInstance().addBankBins(params,
            function success() {
                successNotification('Bank Bin Creted')
                setTimeout(() => {
                    window.location.href = "/bank-bin"
                }, 1000);
            },
            function failure(error) {
                it.setState({ loading: false })
                errorNotification(error)
            }
        )
    }

    render() {
        const { bank_name_error, bin_error, product_name_error, product_type_error } = this.state;
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <Spin spinning={this.state.loading} >

                    <div className="kt-content  kt-grid__item kt-grid__item--fluid" >

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" id="">
                                    <div className="kt-portlet__head kt-portlet__head--lg">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">Add Bank Bin</h3>
                                        </div>
                                        <div className="kt-portlet__head-toolbar">
                                            <a href="/bank-bin" className="btn btn-clean kt-margin-r-10">
                                                <i className="la la-arrow-left"></i>
                                                <span className="kt-hidden-mobile">Back</span>
                                            </a>
                                            <div className="btn-group">
                                                <button type="button" onClick={() => this.sendData()} className="btn btn-brand">
                                                    <i className="la la-check"></i>
                                                    <span className="kt-hidden-mobile">Save</span>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                    {/* end header */}
                                    <div className="kt-portlet__body">
                                        <form className="kt-form" id="kt_form">
                                            <div className="row">
                                                <div className="col-xl-2"></div>
                                                <div className="col-xl-8">

                                                    <div className="kt-section kt-section--first">

                                                        <div className="kt-section__body">
                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Bank Name <b>*</b></label>
                                                                <div className="col-9">
                                                                    <input maxLength="70" className="form-control" onChange={(event) => this.setState({ bank_name: event.target.value })} type="text" />
                                                                    <span style={{ color: bank_name_error ? 'red' : '' }} className="form-text text-muted"><p style={{ color: bank_name_error ? 'red' : '' }}>Please enter Bank Name</p></span>
                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">BIN <b>*</b></label>
                                                                <div className="col-9">
                                                                    <input maxLength="100" className="form-control" onChange={(event) => this.setState({ bin: event.target.value })} type="number" />
                                                                    <span style={{ color: bin_error ? 'red' : '' }} className="form-text text-muted"><p style={{ color: bin_error ? 'red' : '' }}>Please enter correct Bin number</p></span>
                                                                    {/* <span className="form-text text-muted">Please enter name in Arabic</span> */}

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Product Name <b>*</b></label>
                                                                <div className="col-9">
                                                                    <input maxLength="100" className="form-control" onChange={(event) => this.setState({ product_name: event.target.value })} type="text" />
                                                                    <span style={{ color: product_name_error ? 'red' : '' }} className="form-text text-muted"><p style={{ color: product_name_error ? 'red' : '' }}>Please enter product name</p></span>
                                                                    {/* <span className="form-text text-muted">Please enter name in Arabic</span> */}

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Product Type <b>*</b></label>
                                                                <div className="col-9">
                                                                    <Select
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        // className="form-control" 
                                                                        onChange={(product_type) => this.setState({ product_type })}
                                                                        value={this.state.product_type}
                                                                        // onFocus={onFocus}
                                                                        // onBlur={onBlur}
                                                                        // onSearch={onSearch}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {product_type_list.map(type => (
                                                                            <Option key={type.value} value={type.value} >{type.option}</Option>
                                                                        ))}
                                                                    </Select>
                                                                    <span style={{ color: product_type_error ? 'red' : '' }} className="form-text text-muted"><p style={{ color: product_type_error ? 'red' : '' }}>Please Select Product Type</p></span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2"></div>

                                            </div>
                                        </form>
                                    </div>
                                    {/* end body */}
                                </div>
                            </div>
                        </div>

                    </div>
                </Spin>
            </div>
        );
    }
}