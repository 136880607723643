import ApiManager from "../Helpers/Network/Api";
import UserModel from "./UserModel";

export default class ProductBookingModel {
  static myInstance = null;
  static getInstance() {
    if (ProductBookingModel.myInstance === null) {
      ProductBookingModel.myInstance = new ProductBookingModel();
    }
    return this.myInstance;
  }

  static ProductBookingList = [];

  getAllProductBookings(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getAllProductBookings(
      token,
      params,
      function successCallback(data) {
        ProductBookingModel.ProductBookingList = data.records;
        successTrigger(data);
      },
      function failureCallback(err) {
        failureTrigger(err);
      }
    );
  }

  addProductBooking(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().addProductBooking(
      token,
      params,
      function successCallback(data) {
        successTrigger(data);
      },
      function failureCallback(err) {
        failureTrigger(err);
      }
    );
  }

  activateProductBooking(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateProductBooking(
      token,
      id,
      params,
      function resSuccess(data) {
        for (
          let i = 0;
          i < ProductBookingModel.ProductBookingList.length;
          i++
        ) {
          if (ProductBookingModel.ProductBookingList[i].id === id) {
            ProductBookingModel.ProductBookingList[i].is_active =
              params.is_active;
            break;
          }
        }
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  updateProductBooking(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateProductBooking(
      token,
      id,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  deleteProductBooking(id, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().deleteProductBooking(
      token,
      id,
      function resSuccess(data) {
        ProductBookingModel.ProductBookingList.forEach((visibility, index) => {
          if (visibility.id === id) {
            ProductBookingModel.ProductBookingList.splice(index, 1);
            successTrigger(data);
          }
        });
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getProductBooking(id, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getProductBooking(
      token,
      id,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getProductBookingByOutlets(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getProductBookingByOutlets(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getProductBookingOutlets(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getProductBookingOutlets(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data.records);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  // CONFIG MODEL SECTION

  addProductBookingConfig(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().addProductBookingConfig(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getProductBookingConfigDetail(id, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getProductBookingConfigDetail(
      token,
      id,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getProductBookingConfigList(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getProductBookingConfigList(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  updateProductBookingConfig(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateProductBookingConfig(
      token,
      id,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  deleteProductBookingConfig(id, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().deleteProductBookingConfig(
      token,
      id,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  // ADDON MODEL SECTION

  addProductBookingAddon(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().addProductBookingAddon(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getProductBookingAddonDetail(id, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getProductBookingAddonDetail(
      token,
      id,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getProductBookingAddonsByProductBooking(
    id,
    params,
    successTrigger,
    failureTrigger
  ) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getProductBookingAddonsByProductBooking(
      token,
      id,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  updateProductBookingAddon(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateProductBookingAddon(
      token,
      id,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  deleteProductBookingAddon(id, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().deleteProductBookingAddon(
      token,
      id,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getProductBookingReports(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getProductBookingReports(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getProductBookingCalendarReports(params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getProductBookingCalendarReports(
      token,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  getProductBookingReportDetail(id, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().getProductBookingReportDetail(
      token,
      id,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }

  updateProductBookingStatus(id, params, successTrigger, failureTrigger) {
    let token = UserModel.getInstance().getToken();
    ApiManager.getInstance().updateProductBookingStatus(
      token,
      id,
      params,
      function resSuccess(data) {
        successTrigger(data);
      },
      function resFailed(error) {
        failureTrigger(error);
      }
    );
  }
}
