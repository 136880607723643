import React from "react";
import { Select, Spin, Input } from "antd";
import { getCompressedImage } from "Helpers/Shared/utils";

import {
  errorNotification,
  errorToaster,
  successNotification,
} from "../Shared/Notifications";

import MerchantModel from "../../Models/MerchantModel";
import ItemModel from "../../Models/ItemModel";
import backImage from "../../../src/assets/media/users/default.jpg";
import { validateNumber } from "../../Helpers/Shared/Validators";
// import UserModel from "../../Models/UserModel";
import SharedModel from "../../Models/SharedModel";
import AddonModel from "../../Models/AddonModel";
import { Link } from "react-router-dom";
const { Option } = Select;

export default class EditOutletItem extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      merchant: null,
      merchantError: false,
      merchantList: [],
      outletList: [],
      outletsError: false,
      itemGroup: [],
      itemGroupList: [],
      itemGroupError: false,
      name: "",
      nameError: false,
      arabic_name: "",
      arabic_nameError: false,
      englishDescription: "",
      englishDescriptionError: false,
      arabicDescription: "",
      arabicDescriptionError: false,
      code: "",
      codeError: false,
      saleChannel: [],
      saleChannelList: [],
      saleChannelError: false,
      itemType: [],
      itemTypeList: [],
      itemTypeError: false,
      position: null,
      positionError: false,
      price: null,
      priceError: false,
      image: null,
      imageError: false,
      imageSrc: null,
      addonSet: [],
      addonSetList: [],
      addonSetError: false,
      outletName: "",
      outlets: "",
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    let it = this;
    const {
      match: { params },
    } = this.props;
    this.setState({ itemId: params.itemId });
    ItemModel.getInstance().getItemDetail(
      params.itemId,
      function success(data) {
        it.setState({
          name: data.name,
          arabic_name: data.arabic_name,
          code: data.code,
          englishDescription: data.description,
          arabicDescription: data.arabic_description,
          outlets: data.outlets.map((outlet) => {
            return outlet.id;
          }),
          itemGroup: data.item_group_id,
          saleChannel: data.sales_channel_id,
          itemType: data.item_type_id,
          position: data.position,
          price: data.price,
          imageSrc: data.image,
          addonSet: data.addon_set_id.map((addonSet) => {
            return addonSet.id;
          }),
        });
        if (data.merchants[0]) {
          it.getMerchants(data.merchants[0].name);
          it.setState({ merchant: data.merchants[0].id });
          it.getOutlets(data.merchants[0].id);
          it.getOutletItemGroups(it.state.outlets);
          it.getOutletAddons(it.state.outlets);
        }
      },
      function failure(error) {
        console.error(error);
      }
    );
    this.getOutletDetail(params.outletId);

    ItemModel.getInstance().getSaleChannel(
      function success() {
        it.setState({
          saleChannelList: ItemModel.getInstance().SaleChannelList,
          loading: false,
        });
      },
      function failure(msg) {
        console.log(msg);
        it.setState({ loading: false });
      }
    );

    ItemModel.getInstance().getItemType(
      function success() {
        it.setState({
          itemTypeList: ItemModel.getInstance().ItemTypeList,
          loading: false,
        });
      },
      function failure(msg) {
        console.log(msg);
        it.setState({ loading: false });
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQueryMerchant = this.debounce(function (value, it) {
    it.getMerchants(value);
  }, 1000);

  getMerchants(value) {
    if (value.length > 0) {
      let it = this;
      let params = {
        query: encodeURIComponent(value),
      };
      this.setState({ loading: true });
      MerchantModel.getInstance().getMerchantEcommerce(
        params,
        function success() {
          it.setState({
            loading: false,
            merchantList: MerchantModel.getInstance().MerchantEcommerceList,
          });
        },
        function failure(error) {
          errorNotification(error);
          it.setState({ loading: false });
        }
      );
    }
  }

  getOutlets(merchants) {
    this.setState({
      loading: true,
      merchant: merchants,
    });
    let it = this;
    MerchantModel.getInstance().getMerchantOutletEcommerce(
      merchants,
      function success(data) {
        it.setState({
          outletList: data,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  getOutletItemGroups() {
    this.setState({
      loading: true,
    });
    let it = this;
    let params = {
      outlet_id: this.state.outlets,
    };
    ItemModel.getInstance().getOutletItemGroup(
      params,
      function success(data) {
        it.setState({
          itemGroupList: data,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  getOutletAddons() {
    this.setState({
      loading: true,
    });
    let it = this;
    let params = {
      outlet_id: this.state.outlets,
    };
    AddonModel.getInstance().getOutletAddon(
      params,
      function success(data) {
        it.setState({
          addonSetList: data,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getOutletItemGroups();
    it.getOutletAddons();
  }, 2000);

  getOutletDetail(outletId) {
    const it = this;
    it.setState({ loading: true });
    MerchantModel.getInstance().getOutletDetail(
      outletId,
      function success(data) {
        it.setState({
          outletName: data.name,
          outlets: data.id,
          merchant: data.merchant_id,
          merchantName: data.merchant_name,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  saveImage(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ loading: true });
    getCompressedImage(file)
      .then((compressedImage) => {
        if (compressedImage) {
          this.setState({
            loading: false,
            image: compressedImage,
            imageSrc: URL.createObjectURL(compressedImage),
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        errorNotification(e);
      });
  }

  sendData() {
    const {
      itemId,
      merchant,
      outlets,
      name,
      arabic_name,
      englishDescription,
      arabicDescription,
      saleChannel,
      itemType,
      position,
      price,
      itemGroup,
      image,
      addonSet,
    } = this.state;
    let it = this;
    let params = {};
    this.setState({
      merchantError: false,
      outletsError: false,
      itemGroupError: false,
      nameError: false,
      arabic_nameError: false,
      englishDescriptionError: false,
      arabicDescriptionError: false,
      saleChannelError: false,
      itemTypeError: false,
      positionError: false,
      priceError: false,
      imageError: false,
    });

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster("Please enter name");
      return;
    }
    params.name = name;

    params.arabic_name = arabic_name;

    params.description = englishDescription;

    params.arabic_description = arabicDescription;

    if (itemGroup.length < 1) {
      this.setState({ itemGroupError: true });
      errorToaster("Please select item group");
      return;
    }
    params.item_group_id = [itemGroup];

    params.addon_set_id = addonSet;

    if (!saleChannel) {
      this.setState({ saleChannelError: true });
      errorToaster("Please select sale channel");
      return;
    }
    params.sales_channel_id = saleChannel;

    if (!itemType) {
      this.setState({ itemTypeError: true });
      errorToaster("Please select item type");
      return;
    }
    params.item_type_id = itemType;

    if (!validateNumber(position)) {
      this.setState({ positionError: true });
      errorToaster("Please enter position with only numbers");
      return;
    } else {
      if (Number(position < 1)) {
        this.setState({ positionError: true });
        errorToaster("Please enter position greater than zero");
        return;
      }
    }
    params.position = position;

    if (!validateNumber(price)) {
      this.setState({ priceError: true });
      errorToaster("Please enter price");
      return;
    }
    params.price = price;

    it.setState({ loading: true });
    ItemModel.getInstance().updateItem(
      itemId,
      params,
      function success() {
        if (image) {
          it.addMedia(image, itemId);
        } else {
          successNotification("Menu Updated Successfully");
          setTimeout(() => {
            window.location.href = `/outlet/item/${outlets}`;
          }, 1000);
        }
      },
      function failure(error) {
        errorNotification(error);
        console.log(error);
      }
    );
  }

  addMedia(image, itemId) {
    let it = this;
    const { outlets } = this.state;
    if (image) {
      let params = new FormData();
      params.append("image", image);

      SharedModel.getInstance().addMediaInstance(
        itemId,
        "item",
        params,
        function success() {
          successNotification("Menu Updated Successfully");
          setTimeout(() => {
            window.location.href = "/item";
          }, 1000);
        },
        function failure(error) {
          errorNotification(error);
          it.setState({ loading: false });
          console.log(error);
        }
      );
    } else {
      it.setState({ loading: false });
      successNotification("Item Updated Successfully");
      setTimeout(() => {
        window.location.href = `/outlet/item/${outlets}`;
      }, 1000);
    }
  }

  render() {
    const {
      outlets,
      outletName,
      merchantList,
      merchantError,
      outletList,
      outletsError,
      itemGroupList,
      itemGroupError,
      nameError,
      arabic_nameError,
      englishDescriptionError,
      arabicDescriptionError,
      codeError,
      saleChannelError,
      itemTypeError,
      positionError,
      priceError,
      saleChannelList,
      itemTypeList,
      imageSrc,
      imageError,
      addonSetError,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <Spin spinning={this.state.loading}>
          <div className="kt-content  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">{`Edit Menu - ${outletName}`}</h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <Link
                        to={`/outlet/item/${outlets}`}
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </Link>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    value={this.state.name}
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    maxLength="100"
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter Name{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Arabic Name
                                </label>
                                <div className="col-9">
                                  <input
                                    value={this.state.arabic_name}
                                    onChange={(event) =>
                                      this.setState({
                                        arabic_name: event.target.value,
                                      })
                                    }
                                    maxLength="100"
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: arabic_nameError ? "red" : "",
                                      }}
                                    >
                                      Please enter name in Arabic
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="exampleTextarea"
                                >
                                  Description{" "}
                                </label>
                                <div className="col-9">
                                  <div className="input-group">
                                    <textarea
                                      value={this.state.englishDescription}
                                      maxLength="500"
                                      onChange={(event) =>
                                        this.setState({
                                          englishDescription:
                                            event.target.value,
                                        })
                                      }
                                      className="form-control"
                                      rows="3"
                                    ></textarea>
                                  </div>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: englishDescriptionError
                                          ? "red"
                                          : "",
                                      }}
                                    >
                                      Please enter description in english{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="exampleTextarea"
                                >
                                  Arabic Description{" "}
                                </label>
                                <div className="col-9">
                                  <div className="input-group">
                                    <textarea
                                      value={this.state.arabicDescription}
                                      maxLength="500"
                                      onChange={(event) =>
                                        this.setState({
                                          arabicDescription: event.target.value,
                                        })
                                      }
                                      className="form-control"
                                      rows="3"
                                    ></textarea>
                                  </div>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: arabicDescriptionError
                                          ? "red"
                                          : "",
                                      }}
                                    >
                                      Please enter description in arabic{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Item Group <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.itemGroup}
                                    onChange={(itemGroup) => {
                                      this.setState({ itemGroup });
                                    }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {itemGroupList.map((item) => (
                                      <Option key={item.id} value={item.id}>
                                        {item.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: itemGroupError ? "red" : "",
                                      }}
                                    >
                                      Please Select Item Group
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Code<b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    value={this.state.code}
                                    disabled
                                    onChange={(event) =>
                                      this.setState({
                                        code: event.target.value,
                                      })
                                    }
                                    value={this.state.code}
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: codeError ? "red" : "" }}
                                    >
                                      Please enter your Code
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Addon Set{" "}
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.addonSet}
                                    onChange={(addonSet) => {
                                      this.setState({ addonSet });
                                    }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.state.addonSetList.map((addonSet) => (
                                      <Option
                                        key={addonSet.id}
                                        value={addonSet.id}
                                      >
                                        {addonSet.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: addonSetError ? "red" : "",
                                      }}
                                    >
                                      Please Select Addon Set
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Sales Channel <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.saleChannel}
                                    onChange={(saleChannel) => {
                                      this.setState({ saleChannel });
                                    }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {saleChannelList.map((sale) => (
                                      <Option key={sale.id} value={sale.id}>
                                        {sale.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: saleChannelError ? "red" : "",
                                      }}
                                    >
                                      Please Select Sales Channel
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Item Type <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.itemType}
                                    onChange={(itemType) => {
                                      this.setState({ itemType });
                                    }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {itemTypeList.map((type) => (
                                      <Option key={type.id} value={type.id}>
                                        {type.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: itemTypeError ? "red" : "",
                                      }}
                                    >
                                      Please Select Item Type
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Position <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    value={this.state.position}
                                    onChange={(event) =>
                                      this.setState({
                                        position: event.target.value,
                                      })
                                    }
                                    min="1"
                                    className="form-control"
                                    type="number"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: positionError ? "red" : "",
                                      }}
                                    >
                                      Please enter position
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Price <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Input
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.price}
                                    onChange={(event) =>
                                      this.setState({
                                        price: event.target.value,
                                      })
                                    }
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: priceError ? "red" : "" }}
                                    >
                                      Please enter price
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                  {" "}
                                  Image{" "}
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <div
                                    className="kt-avatar kt-avatar--outline"
                                    style={{ borderRadius: "10px" }}
                                    id="kt_apps_user_add_avatar"
                                  >
                                    <div
                                      className="kt-avatar__holder"
                                      style={{
                                        backgroundImage: `url(${
                                          imageSrc
                                            ? imageSrc
                                            : imageSrc
                                            ? imageSrc
                                            : backImage
                                        })`,
                                      }}
                                    ></div>
                                    <label
                                      className="kt-avatar__upload"
                                      data-toggle="kt-tooltip"
                                      title=""
                                      data-original-title="Change avatar"
                                    >
                                      <i className="fa fa-pen"></i>
                                      <input
                                        onChange={(event) =>
                                          this.saveImage(event)
                                        }
                                        type="file"
                                        name="kt_apps_user_add_user_avatar"
                                      />
                                    </label>
                                    <span
                                      className="kt-avatar__cancel"
                                      data-toggle="kt-tooltip"
                                      title=""
                                      data-original-title="Cancel avatar"
                                    >
                                      <i className="fa fa-times"></i>
                                    </span>
                                    <p
                                      style={{
                                        color: "red",
                                        display: imageError ? "block" : "none",
                                      }}
                                    >
                                      Please Upload Image
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
