import React from "react";
import {
  Select,
  Spin,
  // Icon,
  // Input,
  Checkbox,
  DatePicker,
} from "antd";
import moment from "moment";
import {
  errorNotification,
  errorToaster,
  successNotification,
} from "../Shared/Notifications";

import CardModel from "../../Models/CardModel";
import { validateNumber } from "../../Helpers/Shared/Validators";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class EditCardSet extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      name: "",
      nameError: false,
      description: "",
      descriptionError: false,
      limitedUses: false,
      maxUses: "",
      maxUsesError: false,
      reloadable: false,
      expiry: null,
      expiryList: [
        {
          name: "Predefined Period",
          id: 1,
        },
        {
          name: "Expiry from first use",
          id: 2,
        },
        {
          name: "No Expiry",
          id: 3,
        },
      ],
      expiryError: false,
      startDate: "",
      startDateError: false,
      endDate: "",
      endDateError: false,
      days: "",
      dayError: false,
      card: null,
      cardList: [
        {
          name: "Single",
          id: 1,
        },
        {
          name: "Multiple",
          id: 2,
        },
      ],
      cardError: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    let it = this;
    it.setState({ loading: true });
    const {
      match: { params },
    } = this.props;
    it.setState({ cardSetId: params.cardSetId });
    CardModel.getInstance().getCardSetDetail(
      params.cardSetId,
      function success(data) {
        it.setState({
          loading: false,
          name: data.name,
          description: data.description,
          limitedUses: data.use_limit,
          maxUses: data.max_use,
          reloadable: data.reloadable,
          expiry: String(data.expiry),
          days: data.number_days,
          card: String(data.card_number_type),
          startDate: data.start_date,
          endDate: data.end_date,
        });
      },
      function failure(error) {
        console.error(error);
      }
    );
  }

  disabledDate(current) {
    return current < moment().endOf("day");
  }

  sendData() {
    const {
      cardSetId,
      name,
      description,
      limitedUses,
      maxUses,
      reloadable,
      expiry,
      startDate,
      endDate,
      days,
      card,
    } = this.state;
    let it = this;
    let params = {};
    this.setState({
      nameError: false,
      descriptionError: false,
      maxUsesError: false,
      expiryError: false,
      startDateError: false,
      endDateError: false,
      dayError: false,
      cardError: false,
    });

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster("Please enter name");
      return;
    }
    params.name = name;

    params.description = description;

    params.use_limit = limitedUses;

    if (limitedUses) {
      if (!validateNumber(maxUses)) {
        this.setState({ maxUsesError: true });
        errorToaster("Please enter max uses with only numbers");
        return;
      } else {
        if (Number(maxUses < 1)) {
          this.setState({ maxUsesError: true });
          errorToaster("Please enter max uses greater than zero");
          return;
        }
      }
      params.max_use = maxUses;
    }

    params.reloadable = reloadable;

    if (!expiry) {
      this.setState({ expiryError: true });
      errorToaster("Please select expiry");
      return;
    }
    params.expiry = expiry;

    if (expiry == 1) {
      if (startDate.length < 1) {
        this.setState({ startDateError: true });
        errorToaster("Please select start date");
        return;
      }
      params.start_date = startDate;
      if (endDate.length < 1) {
        this.setState({ endDateError: true });
        errorToaster("Please select end date");
        return;
      }
      params.end_date = endDate;
    }

    if (expiry == 2) {
      if (!validateNumber(days)) {
        this.setState({ dayError: true });
        errorToaster("Please enter no. of days with only numbers");
        return;
      } else {
        if (Number(days < 1)) {
          this.setState({ dayError: true });
          errorToaster("Please enter no. of days greater than zero");
          return;
        }
      }
      params.number_days = days;
    }

    if (!card) {
      this.setState({ cardError: true });
      errorToaster("Please select no. of cards");
      return;
    }
    params.card_number_type = card;

    it.setState({ loading: true });
    CardModel.getInstance().updateCardSet(
      cardSetId,
      params,
      function success(data) {
        successNotification("Card Set updated Successfully");
        setTimeout(() => {
          window.location.href = "/card-set";
        }, 1000);
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  render() {
    const {
      nameError,
      descriptionError,
      //   maxUses,
      maxUsesError,
      //   programList,
      //   programError,
      //   reloadable,
      expiryList,
      expiryError,
      dayError,
      cardError,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <Spin spinning={this.state.loading}>
          <div className="kt-content  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">Edit Card Set</h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/card-set"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    value={this.state.name}
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    maxLength="100"
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter Name{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="exampleTextarea"
                                >
                                  Description{" "}
                                </label>
                                <div className="col-9">
                                  <div className="input-group">
                                    <textarea
                                      value={this.state.description}
                                      maxLength="150"
                                      onChange={(event) =>
                                        this.setState({
                                          description: event.target.value,
                                        })
                                      }
                                      className="form-control"
                                      rows="3"
                                    ></textarea>
                                  </div>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: descriptionError ? "red" : "",
                                      }}
                                    >
                                      Please enter description{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Limited Uses
                                </label>
                                <div className="col-9">
                                  <Checkbox
                                    checked={this.state.limitedUses}
                                    onChange={(event) =>
                                      this.setState({
                                        limitedUses: event.target.checked,
                                      })
                                    }
                                    className="form-control"
                                    style={{ border: "0px" }}
                                  />
                                  <span className="form-text text-muted">
                                    Please check if limited uses
                                  </span>
                                </div>
                              </div>

                              {this.state.limitedUses ? (
                                <div className="form-group row">
                                  <label className="col-3 col-form-label">
                                    Max Uses <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <input
                                      value={this.state.maxUses}
                                      onChange={(event) =>
                                        this.setState({
                                          maxUses: event.target.value,
                                        })
                                      }
                                      maxLength="100"
                                      className="form-control"
                                      type="text"
                                    />
                                    <span className="form-text text-muted">
                                      <p
                                        style={{
                                          color: maxUsesError ? "red" : "",
                                        }}
                                      >
                                        Please enter Max Uses{" "}
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              ) : null}

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Reloadable{" "}
                                </label>
                                <div className="col-9">
                                  <Checkbox
                                    checked={this.state.reloadable}
                                    onChange={(event) =>
                                      this.setState({
                                        reloadable: event.target.checked,
                                      })
                                    }
                                    className="form-control"
                                    style={{ border: "0px" }}
                                  />
                                  <span className="form-text text-muted">
                                    Please check if reloadable
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Expiry <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.expiry}
                                    onChange={(value) =>
                                      this.setState({ expiry: value })
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {expiryList.map((exp) => (
                                      <Option key={exp.id} id={exp.id}>
                                        {exp.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: expiryError ? "red" : "",
                                      }}
                                    >
                                      Please Select Expiry
                                    </p>
                                  </span>
                                </div>
                              </div>

                              {this.state.expiry == 1 ? (
                                <div className="form-group row">
                                  <label className="col-3 col-form-label">
                                    Start Date ~ End Date <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <RangePicker
                                      disabledDate={this.disabledDate}
                                      className="form-control"
                                      style={{
                                        padding: "0px",
                                        border: "0px solid black",
                                        width: "100%",
                                      }}
                                      showTime={false}
                                      value={[
                                        this.state.startDate
                                          ? moment(
                                              this.state.startDate,
                                              "YYYY-MM-DD"
                                            )
                                          : null,
                                        this.state.endDate
                                          ? moment(
                                              this.state.endDate,
                                              "YYYY-MM-DD"
                                            )
                                          : null,
                                      ]}
                                      onChange={(value, string) =>
                                        this.setState({
                                          startDate: string[0],
                                          endDate: string[1],
                                        })
                                      }
                                      format="YYYY-MM-DD"
                                    />
                                  </div>
                                </div>
                              ) : null}

                              {this.state.expiry == 2 ? (
                                <div className="form-group row">
                                  <label className="col-3 col-form-label">
                                    No. of days <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <input
                                      value={this.state.days}
                                      onChange={(event) =>
                                        this.setState({
                                          days: event.target.value,
                                        })
                                      }
                                      maxLength="100"
                                      className="form-control"
                                      type="text"
                                    />
                                    <span className="form-text text-muted">
                                      <p
                                        style={{ color: dayError ? "red" : "" }}
                                      >
                                        Please enter No. of days{" "}
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              ) : null}

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  No. of cards <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.card}
                                    onChange={(value) =>
                                      this.setState({ card: value })
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {this.state.cardList.map((card) => (
                                      <Option key={card.id} id={card.id}>
                                        {card.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: cardError ? "red" : "" }}
                                    >
                                      Please Select No. of cards
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
