import React from "react";
import { Select, Icon, Popconfirm, Spin, Switch, Tooltip } from "antd";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";

import {
  infoNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";

import CollectionModel from "../../Models/CollectionModel";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      totalPage: 0,
      perPage: 0,
      filters: [],
    };
  }
  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getCollectionsData();
  }

  getCollectionsData() {
    const it = this;
    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter, i) => {
      params[filter.id] = filter.value.toLowerCase();
    });
    if (params["is_active"] === "all") {
      delete params["is_active"];
    }
    it.setState({ loading: true });
    CollectionModel.getInstance().getCollection(
      params,
      function success(data) {
        it.setState({
          data: CollectionModel.getInstance().CollectionList,
          loading: false,
          totalPage: data.last_page,
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false, data: [] });
      }
    );
  }

  deleteCollection(collection_id) {
    const it = this;
    it.setState({ loading: true });
    CollectionModel.getInstance().removeCollection(
      collection_id,
      function success() {
        it.setState({ data: CollectionModel.getInstance().CollectionList });
        it.setState({ loading: false });
        infoNotification("Collection Deleted");
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  collectionActivation(collectionId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = is_active === true ? false : true;
    let params = {};
    params.is_active = activation;
    CollectionModel.getInstance().collectionActivation(
      collectionId,
      params,
      function success() {
        it.setState({ data: CollectionModel.getInstance().CollectionList });
        it.setState({ loading: false });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getCollectionsData();
  }, 1000);

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={this.state.loading}>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              {gridHeaderWithButton(
                "Collections",
                "la la-plus",
                "Add Collection",
                "collection/add"
              )}
            </div>
            <div className="kt-portlet__body">
              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={20}
                showPageSizeOptions={false}
                manual
                page={this.state.currentPage - 1}
                pages={this.state.totalPage}
                filtered={this.state.filters}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getCollectionsData();
                  }, 50);
                }}
                onFilteredChange={(filters) => {
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                columns={[
                  {
                    Header: <p style={tableHeaderStyles()}>Background Image</p>,
                    id: "image",
                    accessor: (d) => (
                      <img alt={'collection_image'} src={d.image} style={{ height: 50, width: 50 }} />
                    ),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["image"] }),
                    filterable: false,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    id: "name",
                    accessor: (d) => d.name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterable: true,
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Position</p>,
                    id: "order",
                    accessor: (d) => d.order,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["order"] }),
                    filterable: false,
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "is_active",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value == "true") {
                        return row[filter.id] == true;
                      }
                      return row[filter.id] == false;
                    },
                    Filter: ({ filter, onChange }) => (
                      <Select
                        onChange={(value) => onChange(value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <Option value="all">Show all</Option>
                        <Option value="true">Active</Option>
                        <Option value="false">De-Activate</Option>
                      </Select>
                    ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 200,
                    accessor: (d) => (
                      <div>
                        {/* <Link
                                                    to={`collection/associate/${d.id}`}
                                                    style={{ marginRight: 20 }}
                                                >
                                                    <Tooltip title="Associate" >
                                                        <Icon style={{ color: '#5D78FF', fontSize: 16 }} type="api" />
                                                    </Tooltip>
                                                </Link> */}
                        <Link
                          to={`collection/edit/${d.id}`}
                          style={{ marginRight: 20 }}
                        >
                          <Tooltip title="Edit">
                            <Icon
                              style={{ color: "#5D78FF", fontSize: 16 }}
                              type="edit"
                            />
                          </Tooltip>
                        </Link>
                        <Popconfirm
                          title="Are you sure you want to delete this collection?"
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() => this.deleteCollection(d.id)}
                        >
                          <Tooltip title="Delete">
                            <Icon
                              style={{ color: "#5D78FF", fontSize: 16 }}
                              type="delete"
                            />
                          </Tooltip>
                        </Popconfirm>
                        <Popconfirm
                          // style={{}}
                          title={
                            d.is_active
                              ? "Are you sure you want to DEACTIVATE this Collection"
                              : "Are you sure you want to ACTIVATE this Collection"
                          }
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.collectionActivation(d.id, d.is_active)
                          }
                        >
                          <Switch
                            style={{
                              backgroundColor: d.is_active
                                ? "#5d78ff"
                                : "#fd397a",
                              marginLeft: 15,
                            }}
                            checked={d.is_active}
                            onChange={(val) => console.log(val)}
                          />
                        </Popconfirm>
                      </div>
                    ),
                    filterable: false,
                    sortable: false,
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
