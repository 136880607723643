import { Icon, Popconfirm, Select, Spin, Switch } from "antd";
import matchSorter from "match-sorter";
import React from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { gridHeaderWithButton } from "styles/gridHeader";
import { tableHeaderStyles } from "styles/tableStyles";
import ProductBookingModel from "../../Models/ProductBookingModel";
import { errorNotification, errorToaster } from "../Shared/Notifications";

const { Option } = Select;

export default class ProductVisibility extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      countryList: [],
      appList: [],
      loading: false,
      filters: [],
      pageCount: 10,
      offset: 0,
      total_pages: 1,
      currentPage: 1,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  createPagination(total_count) {
    this.setState({
      total_pages: Math.ceil(total_count / this.state.pageCount),
    });
  }

  componentDidMount() {
    this.getAllProductBookings();
  }

  getAllProductBookings() {
    const it = this;
    it.setState({ loading: true });
    let params = {
      offset: (this.state.currentPage - 1) * this.state.pageCount,
      limit: this.state.pageCount,
    };
    this.state.filters.forEach((filter, i) => {
      params[filter.id] = filter.value;
      // params[filter.id] = filter.value.toLowerCase();
    });
    if (params["app_id"] === "all") {
      delete params["app_id"];
    }
    if (this.props.is_outlet) {
      params["$outlet.id$"] = this.props.outlet_id;
    }

    switch (params["is_active"]) {
      case "all":
        delete params["is_active"];
        break;

      case "true":
        params["is_active"] = true;
        break;

      case "false":
        params["is_active"] = false;
        break;

      default:
        break;
    }

    ProductBookingModel.getInstance().getAllProductBookings(
      params,
      function success(data) {
        it.setState({
          data: ProductBookingModel.ProductBookingList,
          loading: false,
        });
        it.createPagination(data.count);
      },
      function Failure(error) {
        it.setState({ loading: false });
        errorToaster("Request information not found");
      }
    );
  }

  updateProductBooking(id, is_active) {
    const it = this;
    it.setState({ loading: true });
    const params = {
      is_active: !is_active,
    };
    ProductBookingModel.getInstance().activateProductBooking(
      id,
      params,
      function success() {
        it.setState({
          data: ProductBookingModel.ProductBookingList,
          loading: false,
        });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.error(error);
      }
    );
  }

  deleteProductBooking(id) {
    const it = this;
    it.setState({ loading: true });
    ProductBookingModel.getInstance().deleteProductBooking(
      id,
      function success() {
        it.setState({
          data: ProductBookingModel.ProductBookingList,
          loading: false,
        });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getAllProductBookings();
  }, 1000);

  extractMerchantName(outlets) {
    return outlets[0]?.merchant.name;
  }

  DataColumns() {
    let columns = [
      {
        Header: <p style={tableHeaderStyles()}>Name</p>,
        id: "name",
        accessor: (d) => d.name,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["name"] }),
        filterAll: true,
        filterable: true,
      },
      {
        Header: <p style={tableHeaderStyles()}>Merchant</p>,
        id: "$outlet->merchant.name$",
        accessor: (d) => this.extractMerchantName(d.outlet),
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["value"] }),
        filterAll: true,
        filterable: true,
      },
      {
        Header: <p style={tableHeaderStyles()}>Product Visibility</p>,
        id: "$product_visibilities.name$",
        accessor: (d) => d.product_visibilities?.name,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["value"] }),
        filterAll: true,
        filterable: false,
      },
      {
        Header: <p style={tableHeaderStyles()}>Status</p>,
        id: "is_active",
        accessor: "is_active",
        Cell: ({ value }) =>
          value ? (
            <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
              Active
            </span>
          ) : (
            <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
              De-Activate
            </span>
          ),
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (filter.value === "true") {
            return row[filter.id] === true;
          }
          return row[filter.id] === false;
        },
        Filter: ({ filter, onChange }) => (
          <Select
            onChange={(value) => onChange(value)}
            style={{ width: "100%" }}
            defaultValue="all"
            value={filter ? filter.value : "all"}
          >
            <Option value="all">Show All</Option>
            <Option value="true">Active</Option>
            <Option value="false">De-Activate</Option>
          </Select>
        ),
      },
      {
        Header: <p style={tableHeaderStyles()}>Action</p>,
        id: "actions",
        width: 150,
        accessor: (d) => (
          <div>
            <Link
              to={`/product-booking/edit/${d.id}`}
              style={{ marginRight: 20 }}
            >
              <Icon style={{ color: "#5D78FF", fontSize: 16 }} type="edit" />
            </Link>
            <Popconfirm
              title="Are you sure you want to delete this Product Booking?"
              icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
              onConfirm={() => this.deleteProductBooking(d.id)}
            >
              <Icon style={{ color: "#5D78FF", fontSize: 16 }} type="delete" />
            </Popconfirm>
            <Popconfirm
              title={`Are you sure you want to ${d.is_active ? `DEACTIVATE` : `ACTIVATE`
                } this booking`}
              icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
              onConfirm={() => this.updateProductBooking(d.id, d.is_active)}
            >
              <Switch
                style={{
                  backgroundColor: d.is_active ? "#5d78ff" : "#fd397a",
                  marginLeft: 15,
                }}
                checked={d.is_active}
                onChange={(val) => console.info(val)}
              />
            </Popconfirm>
          </div>
        ),
        filterable: false,
      },
    ];
    if (this.props.is_outlet) {
      const _index = columns.findIndex((_col) => _col.id === "$outlet.name$");
      columns.splice(_index, 1);
    }
    return columns;
  }

  render() {
    const { data } = this.state;
    let { is_outlet, outlet_id } = this.props;
    let route = is_outlet
      ? `/product-booking/add?outlet_id=${outlet_id}`
      : "/product-booking/add";
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              {gridHeaderWithButton(
                "Product Bookings",
                "la la-plus",
                "Add Booking",
                route
              )}
            </div>
            <div className="kt-portlet__body">
              <Spin spinning={this.state.loading}>
                {/* react-table */}
                <ReactTable
                  data={data}
                  filterable
                  pageSize={this.state.pageCount}
                  showPageSizeOptions={true}
                  pageSizeOptions={[5, 10, 20, 25, 50]}
                  manual
                  page={this.state.currentPage - 1}
                  pages={this.state.total_pages}
                  filtered={this.state.filters}
                  onPageSizeChange={(pageCount) => {
                    this.setState({ currentPage: 1, pageCount });
                    setTimeout(() => {
                      this.getAllProductBookings();
                    }, 50);
                  }}
                  onPageChange={(pageIndex) => {
                    this.setState({ currentPage: pageIndex + 1 });
                    setTimeout(() => {
                      this.getAllProductBookings();
                    }, 50);
                  }}
                  onFilteredChange={(filters) => {
                    this.setState({ currentPage: 1, filters });
                    this.searchQuery(this);
                  }}
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  columns={this.DataColumns()}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </Spin>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
