const { version } = require("../../package.json");
const baseURL = process.env.REACT_APP_URL || 'http://localhost:4001/';
const baseURLWithDev = `${baseURL}development/`;
const baseURLWithDashboard = `${baseURLWithDev}dashboard/`;
module.exports = {
  currentVersion: `v${version}`,
  sharedURL: baseURLWithDev,
  baseURL: baseURLWithDashboard,
  baseURL_2: baseURL,
};
