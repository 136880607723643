import React, { useState, useEffect } from "react";
import {
  Spin,
  Divider,
  Icon,
  Button,
  Popconfirm,
  Modal,
  DatePicker,
  TimePicker,
} from "antd";
import {
  errorNotification,
  errorToaster,
  successNotification,
} from "../Shared/Notifications";
import Styles from "../Shared/OrderDetailStyles";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import ProductBookingModel from "Models/ProductBookingModel";

import BOOKINGDATA from "./BookingDetailStaticData.json";

export default function BookingDetail({ match: { params } }) {
  const [loading, setLoading] = useState(false),
    [orderCurrentStatus, setOrderCurrentStatus] = useState(undefined),
    [showModal, setShowModal] = useState(false),
    [updateOrderStatus, setUpdateOrderStatus] = useState(null),
    [reason, setReason] = useState(""),
    [buttonSets, setButtonSets] = useState([]),
    [isDateTime, setIsDateTime] = useState(false),
    [rescheduledDate, setRescheduleDate] = useState(undefined),
    [rescheduledTime, setRescheduleTime] = useState(undefined),
    [bookingDetail, setBookingDetail] = useState(undefined);

  useEffect(() => {
    getProductBookingReportDetail(params.bookingId);
  }, [params]);

  useEffect(() => {
    if (BOOKINGDATA.statusButtons.some((x) => x.id === orderCurrentStatus?.id))
      setButtonSets(
        BOOKINGDATA.statusButtons.find((x) => x.id === orderCurrentStatus?.id)
          .buttons
      );
    else setButtonSets([]);
  }, [orderCurrentStatus]);

  function getProductBookingReportDetail(bookingId) {
    setLoading(true);
    ProductBookingModel.getInstance().getProductBookingReportDetail(
      bookingId,
      function success(data) {
        setLoading(false);
        setBookingDetail(data);
        setOrderCurrentStatus(data?.order_status);
      },
      function error(err) {
        setLoading(false);
        errorNotification(JSON.stringify(err));
      }
    );
  }

  function updateStatusForBooking(button_status) {
    if (button_status.showDialog) {
      setShowModal(true);
      setUpdateOrderStatus(button_status.id);
      if (button_status.showDateTime) setIsDateTime(true);
    } else {
      const payload = {
        order_status_id: button_status.id,
        from_status: orderCurrentStatus.id,
        user_product_booking_id: Number(params.bookingId),
        reason: "",
      };
      updateProductBookingStatus(payload);
    }
  }

  function updateOrderStatusWithReason() {
    if (reason.trim().length < 1) {
      errorToaster("Please Enter Reason for Updating Booking Status");
      return;
    }
    if (isDateTime && (!rescheduledDate || !rescheduledTime)) {
      errorToaster("Please Enter Rescheduling Date and Time");
      return;
    }
    const payload = {
      order_status_id: updateOrderStatus,
      from_status: orderCurrentStatus.id,
      user_product_booking_id: Number(params.bookingId),
      reason,
    };
    if (isDateTime)
      payload["rescheduled_at"] = moment.tz(
        moment(rescheduledDate).format("YYYY-MM-DD") +
          " " +
          moment(rescheduledTime).format("HH:mm:ss"),
        "Asia/Qatar"
      );
    updateProductBookingStatus(payload);
  }

  function updateProductBookingStatus(payload) {
    setLoading(true);
    ProductBookingModel.getInstance().updateProductBookingStatus(
      params.bookingId,
      payload,
      function success(data) {
        setLoading(false);
        setShowModal(false);
        setUpdateOrderStatus(null);
        setReason("");
        successNotification("Order Status Updated");
        setIsDateTime(false);
        setRescheduleTime(undefined);
        setRescheduleDate(undefined);
        getProductBookingReportDetail(params.bookingId);
      },
      function failure(err) {
        setLoading(false);
        errorNotification(JSON.stringify(err));
      }
    );
  }

  const disablePreviousDate = (current) => {
    return current.valueOf() < DateTime.now().startOf("day").valueOf();
  };

  return (
    <div className="kt-content  kt-grid__item kt-grid__item--fluid">
      <Spin spinning={loading}>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            <div className="kt-portlet__head-label">
              <span className="kt-portlet__head-icon">
                <i className="kt-font-brand flaticon2-line-chart"></i>
              </span>
              <div
                style={{
                  color: "#5D78FF",
                  marginLeft: "5px",
                  fontSize: 17,
                  fontFamily: "Arial,HelveticaNeue",
                }}
              >
                <b>{"Booking"}</b>
              </div>
              <div
                style={{
                  color: "#6C7293",
                  marginLeft: "10px",
                  fontSize: 19,
                  fontFamily: "Arial,HelveticaNeue",
                }}
              >
                <b>{">"}</b>
              </div>
              <h3
                style={{ fontFamily: "Arial,HelveticaNeue", color: "#6C7293" }}
                className="kt-portlet__head-title kt-margin-l-5"
              >
                {bookingDetail?.id}
              </h3>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              backgroundColor: "#FD397A",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <p
              style={{
                height: "30px",
                padding: "0 5px",
                textAlign: "center",
                justifyContent: "center",
                marginTop: "12px",
                marginLeft: 15,
                borderRadius: "1px",
                color: "#FFF",
                fontSize: 18,
                fontWeight: "700",
                fontFamily: "Arial,HelveticaNeue",
              }}
            >
              {orderCurrentStatus?.name}
            </p>

            <div
              style={{
                float: "left",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {buttonSets.map((_x) => (
                <Popconfirm
                  disabled={_x.showDialog}
                  key={_x.id}
                  title="Are you sure you want to change status for this order?"
                  onConfirm={() => updateStatusForBooking(_x)}
                  okText="Yes"
                  cancelText="Cancel"
                >
                  <Button
                    type="button"
                    onClick={() => _x.showDialog && updateStatusForBooking(_x)}
                    style={{
                      margin: "10px",
                      backgroundColor: "transparent",
                      color: "#FFF",
                      borderRadius: "2px",
                      border: "1px solid #FFF",
                      fontFamily: "Arial,HelveticaNeue",
                    }}
                  >
                    <b>{_x.name}</b>
                  </Button>
                </Popconfirm>
              ))}
            </div>
          </div>

          {bookingDetail ? (
            <div className="kt-portlet__body">
              <div style={Styles.userDataContainer}>
                <div style={Styles.orderContainer}>
                  <div className="row">
                    <h3 style={Styles.cardHeading}>Booking Details</h3>
                    <div
                      style={{
                        margin: "auto",
                        paddingTop: 3,
                        fontFamily: "Arial,HelveticaNeue",
                      }}
                    >{`Date & Time: ${DateTime.fromISO(
                      bookingDetail.created_at
                    ).toLocaleString(DateTime.DATETIME_MED)}`}</div>
                  </div>
                  <div
                    style={{
                      width: "139%",
                      height: "250px",
                      overflow: "auto",
                      color: "#6C7293",
                    }}
                  >
                    <table
                      className="table"
                      borderCollapse="collapse"
                      border="1"
                      width="930px"
                      height="250px"
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              padding: "8px",
                              borderBottom: "1px solid #ddd",
                              fontFamily: "Arial,HelveticaNeue",
                              fontSize: 16,
                              color: "#6C7293",
                            }}
                          >
                            Item Name
                          </th>
                          <th
                            style={{
                              padding: "8px",
                              borderBottom: "1px solid #ddd",
                              width: "100px",
                              fontFamily: "Arial,HelveticaNeue",
                              fontSize: 16,
                              color: "#6C7293",
                            }}
                          >
                            Price
                          </th>
                          <th
                            style={{
                              padding: "8px",
                              borderBottom: "1px solid #ddd",
                              width: "100px",
                              fontFamily: "Arial,HelveticaNeue",
                              fontSize: 16,
                              color: "#6C7293",
                            }}
                          >
                            Discounted Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingDetail.cart.product_booking_cart_items.map(
                          (item, i) => (
                            <tr key={i}>
                              <td
                                style={{
                                  padding: "8px",
                                  borderBottom: "1px solid #ddd",
                                  fontSize: 14,
                                  color: "#6C7293",
                                  fontFamily: "Arial,HelveticaNeue",
                                }}
                              >
                                {item.product_booking.name}
                                <ul>
                                  {item.item_addons.map((addon, j) => (
                                    <li key={j}>{addon.cart_addon.name}</li>
                                  ))}
                                </ul>
                              </td>
                              <td
                                style={{
                                  padding: "8px",
                                  borderBottom: "1px solid #ddd",
                                  width: "100px",
                                  fontSize: 14,
                                  color: "#6C7293",
                                  fontFamily: "Arial,HelveticaNeue",
                                }}
                              >
                                {
                                  item.product_booking.pricings?.find(
                                    (_x) => _x.app === bookingDetail.app_id
                                  )?.pri
                                }
                              </td>
                              <td
                                style={{
                                  padding: "8px",
                                  borderBottom: "1px solid #ddd",
                                  width: "100px",
                                  fontSize: 14,
                                  color: "#6C7293",
                                  fontFamily: "Arial,HelveticaNeue",
                                }}
                              >
                                {
                                  item.product_booking.pricings?.find(
                                    (_x) => _x.app === bookingDetail.app_id
                                  )?.disc
                                }
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "95.5%",
                  border: "1px solid #EBEDF2",
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                }}
              >
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: 90,
                      marginLeft: "15px",
                      paddingTop: 5,
                      float: "left",
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    Sub Total
                  </div>
                  <div
                    style={{
                      width: "80px",
                      marginRight: "10px",
                      paddingTop: 5,
                      float: "right",
                      fontSize: 15,
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    <p>QR {bookingDetail.list_price}</p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "95.5%",
                  border: "1px solid #EBEDF2",
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                  color: "#6C7293",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: 90,
                      marginLeft: "15px",
                      paddingTop: 5,
                      float: "left",
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    <h6 style={{ color: "red" }}>Discount</h6>
                  </div>
                  <div
                    style={{
                      width: "80px",
                      marginRight: "10px",
                      paddingTop: 5,
                      float: "right",
                      fontSize: 15,
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    <p style={{ color: "red" }}>
                      -QR{" "}
                      {bookingDetail.list_price -
                        bookingDetail.discounted_price}
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "95.5%",
                  border: "1px solid #EBEDF2",
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                  color: "#6C7293",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: 150,
                      paddingTop: 5,
                      marginLeft: "15px",
                      float: "left",
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    <strong>Grand Total</strong>
                  </div>
                  <div
                    style={{
                      width: "80px",
                      marginRight: "10px",
                      paddingTop: 5,
                      float: "right",
                      fontSize: 15,
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    <p>
                      <strong>QR {bookingDetail.discounted_price}</strong>
                    </p>
                  </div>
                </div>
              </div>
              {bookingDetail.order_logs[0] &&
                bookingDetail.order_logs[0].reason &&
                bookingDetail.order_logs[0].reason.length > 0 && (
                  <>
                    <br></br>
                    <div
                      style={{
                        color: "#6C7293",
                        fontFamily: "Arial,HelveticaNeue",
                        fontSize: 17,
                        width: 170,
                        height: 19,
                      }}
                    >
                      Reason
                    </div>
                    <div
                      style={{
                        color: "#6C7293",
                        fontSize: 15,
                        fontFamily: "Arial,HelveticaNeue",
                      }}
                    >
                      {bookingDetail.order_logs[0]?.reason}
                    </div>
                  </>
                )}

              {bookingDetail.cart.instruction &&
                bookingDetail.cart.instruction.length > 0 && (
                  <>
                    <br></br>
                    <div
                      style={{
                        color: "#6C7293",
                        fontFamily: "Arial,HelveticaNeue",
                        fontSize: 17,
                        width: 170,
                        height: 19,
                      }}
                    >
                      Special Instructions
                    </div>
                    <div
                      style={{
                        color: "#6C7293",
                        fontSize: 15,
                        fontFamily: "Arial,HelveticaNeue",
                      }}
                    >
                      {bookingDetail.cart.instruction}
                    </div>
                  </>
                )}
              <br />
              <div className="row">
                <label
                  style={{
                    paddingTop: 8,
                    marginLeft: "10px",
                    fontSize: 17,
                    fontFamily: "Arial,HelveticaNeue",
                    color: "#6C7293",
                    fontWeight: 400,
                  }}
                >
                  Scheduled At:{" "}
                </label>
                <p
                  style={{
                    paddingTop: 8,
                    marginLeft: "5px",
                    fontSize: 17,
                    fontFamily: "Arial,HelveticaNeue",
                    color: "#6C7293",
                  }}
                >
                  {DateTime.fromISO(
                    bookingDetail.cart.start_time
                  ).toLocaleString(DateTime.DATETIME_MED)}
                </p>
              </div>

              <Divider />

              <div>
                <h3 style={{ color: "#6C7293" }}>User Details</h3>
                <br></br>
                <div className="row">
                  <Icon
                    style={{
                      color: "#6C7293",
                      fontSize: 16,
                      marginLeft: "10px",
                    }}
                    type="user"
                  />
                  <h6
                    style={{
                      marginLeft: "10px",
                      fontSize: 17,
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    {bookingDetail.user.full_name}
                  </h6>
                </div>
                <br></br>
                <div className="row">
                  <Icon
                    style={{
                      color: "#6C7293",
                      fontSize: 16,
                      marginLeft: "10px",
                    }}
                    type="mobile"
                  />
                  <h6
                    style={{
                      marginLeft: "10px",
                      fontSize: 17,
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    {bookingDetail.user.mobile}
                  </h6>
                </div>
                <br></br>
                <div className="row">
                  <Icon
                    style={{
                      color: "#6C7293",
                      fontSize: 16,
                      marginLeft: "10px",
                    }}
                    type="mail"
                  />
                  <h6
                    style={{
                      marginLeft: "10px",
                      fontSize: 17,
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    {bookingDetail.user.email}
                  </h6>
                </div>
              </div>
              <Divider />

              <div>
                <h3 style={{ color: "#6C7293" }}>Outlet Detail</h3>
                <br></br>
                <div className="row">
                  <Icon
                    style={{
                      color: "#6C7293",
                      fontSize: 16,
                      marginLeft: "10px",
                    }}
                    type="shop"
                  />
                  <h6
                    style={{
                      marginLeft: "10px",
                      fontSize: 17,
                      fontFamily: "Arial,HelveticaNeue",
                      color: "#6C7293",
                    }}
                  >
                    {bookingDetail.cart.outlet.name}
                  </h6>
                </div>
              </div>
            </div>
          ) : null}
          <Modal
            // className="col-10"
            width={"70%"}
            title="Order Status"
            visible={showModal}
            onOk={() => updateOrderStatusWithReason()}
            onCancel={() => {
              setShowModal(false);
              setUpdateOrderStatus(null);
              setReason("");
              setIsDateTime(false);
              setRescheduleTime(undefined);
              setRescheduleDate(undefined);
            }}
          >
            <br></br>
            <h2>
              <center>
                Update Booking Status to{" "}
                <b>
                  "
                  {
                    BOOKINGDATA.orderStatuses.find(
                      (_x) => _x.id === updateOrderStatus
                    )?.name
                  }
                  "
                </b>
              </center>
            </h2>
            <br></br>
            <div className="form-group row">
              <label className="col-3 col-form-label">Reason</label>
              <div className="col-9">
                <input
                  value={reason}
                  onChange={(event) => setReason(event.target.value)}
                  className="form-control"
                  maxLength="1000"
                />
                <span className="form-text text-muted">
                  Please enter Reason to change status
                </span>
              </div>
            </div>
            {isDateTime && (
              <div className="form-group row">
                <label
                  className="col-3 col-form-label"
                  htmlFor="exampleTextarea"
                >
                  Scheduled Time <b>*</b>
                </label>
                <div className="col-9">
                  <div className="input-group">
                    <DatePicker
                      className="form-control"
                      format="DD-MMM-YYYY"
                      disabledDate={disablePreviousDate}
                      onChange={(scheduled_date) =>
                        setRescheduleDate(scheduled_date)
                      }
                      value={rescheduledDate}
                      style={{
                        padding: "0px",
                        border: "0px solid black",
                      }}
                      allowClear={false}
                    />
                  </div>

                  <div className="input-group">
                    <TimePicker
                      use12Hours
                      className="form-control"
                      format="hh:mm a"
                      onChange={(scheduled_time) =>
                        setRescheduleTime(scheduled_time)
                      }
                      value={rescheduledTime}
                      style={{
                        padding: "0px",
                        border: "0px solid black",
                      }}
                      allowClear={false}
                    />
                  </div>

                  <span className="form-text text-muted">
                    Please Select Date/Time for rescheduling
                  </span>
                </div>
              </div>
            )}
          </Modal>
        </div>
      </Spin>
    </div>
  );
}
