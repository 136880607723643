import React from 'react';


export function Header({ title }) {
    return (
        <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">{title}</h3>
        </div>
    )
}
export function ButtonName({ buttonOneIcon, buttonOneCallBack, buttonTwoIcon, buttonTwoCallBack }) {
    return (
        <div className="kt-portlet__head-toolbar">
            <div onClick={() => buttonOneCallBack()} className="btn btn-clean kt-margin-r-10">
                <i className={buttonOneIcon}></i>
                <span className="kt-hidden-mobile">Back</span>
            </div>
            <div className="btn-group">
                <button onClick={() => buttonTwoCallBack()} type="button" className="btn btn-brand">
                    <i className={buttonTwoIcon}></i>
                    <span className="kt-hidden-mobile">Save</span>
                </button>

            </div>
        </div>
    )
}
export function DetailPageHeaderWithButton({ title, buttonOneIcon, buttonOneCallBack, buttonTwoIcon, buttonTwoCallBack }) {
    return (
        <>
            <Header
                title={title}
            />
            <ButtonName
                buttonOneIcon={buttonOneIcon}
                buttonOneCallBack={buttonOneCallBack}
                buttonTwoIcon={buttonTwoIcon}
                buttonTwoCallBack={buttonTwoCallBack}
            />
        </>
    )
}
export function DetailPageHeaderWithOutButton({ title }) {
    return (
        <Header
            title={title}
        />
    )
}
