import React from 'react';
import {
    Select,
    Spin,
    Icon,
    Input,
} from 'antd'

import { errorNotification, errorToaster, successNotification } from '../Shared/Notifications'

import MerchantModel from '../../Models/MerchantModel';
import AddonModel from '../../Models/AddonModel';
import { validateNumber } from '../../Helpers/Shared/Validators';

const { Option } = Select;

export default class AddAddon extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: false,
            merchant: null,
            merchantError: false,
            merchantList: [],
            outlets: [],
            outletList: [],
            outletsError: false,
            addonType: [] ,
            addonTypeList: [],
            addonTypeError: false,
            name: '',
            nameError: false,
            arabic_name: '',
            arabic_nameError: false,
            position: '',
            positionError: false,
            price: '',
            priceError: false,

        }
    }

    componentWillMount(){
        const role_id = localStorage.getItem("user_role")
        switch (Number(role_id)) {
            case 1:
                break;
             case 5:
                 window.location.href = '/order-report'
                 break;
             default:
                window.location.href = '/login'
                break;
        }
     }

    componentDidMount() {
        let it = this;
    }

    debounce(callback, wait) {
        let timeout;
        return (...args) => {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => callback.apply(context, args), wait);
        };
    }

    searchQueryMerchant = this.debounce(function (value, it) {
        it.getMerchants(value);
    }, 1000);


    getMerchants(value) {
        if (value.length > 0) {
            let it = this;
            let params = {
                query: encodeURIComponent(value)
            }
            this.setState({ loading: true })
            MerchantModel.getInstance().getMerchant(params,
                function success(data) {
                    it.setState({
                        loading: false,
                        merchantList: data.data
                    })
                },
                function failure(error) {
                    errorNotification(error)
                    it.setState({ loading: false })

                }
            )
        }

    }
    getOutlets(merchant) {
        this.setState({
            loading: true,
            outlets: [],
            addonType : [],
            merchant: merchant
        })
        let it = this;
        MerchantModel.getInstance().getMerchantOutlet(merchant,
            function success(data) {
                it.setState({
                    outletList: data,
                    loading: false
                })
            },
            function failure(error) {
                it.setState({ loading: false })
                errorNotification(error)
                console.log(error)
            }
        )
    }

    getOutletAddons() {
        this.setState({
            loading: true,
            addonType : [],
        })
        let it = this;
        let params = {
            outlet_id : this.state.outlets 
        };
        AddonModel.getInstance().getOutletAddon(params,
            function success(data) {
                it.setState({
                    addonTypeList : data,
                    loading: false,

                })

            },
            function failure(error) {
                it.setState({ loading: false })
                errorNotification(error)
                console.log(error)
            }
        )
    }


    debounce(callback, wait) {
        let timeout;
        return (...args) => {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => callback.apply(context, args), wait);
        };
    }

    searchQuery = this.debounce(function (it) {
        it.getOutletAddons();
    }, 2000);

   
    sendData() {
        const { merchant, outlets, name, arabic_name, position, price, addonType } = this.state;
        let { history } = this.props;
        let params = {};
        let it = this;
        this.setState({
            merchantError: false,
            outletsError: false,
            addonTypeError: false,
            nameError: false,
            arabic_nameError: false,
            positionError: false,
            priceError: false,
            addonTypeError: false
        })

        if (name.length < 1) {
            this.setState({ nameError: true });
            errorToaster("Please enter name");
            return;
        }
        params.name = name;
        params.arabic_name = arabic_name;

        if (!merchant) {
            this.setState({ merchantError: true });
            errorToaster("Please select merchant");
            return;
        }

        if (outlets.length < 1) {
            this.setState({ outletsError: true });
            errorToaster("Please select outlets");
            return;
        }

        if (addonType.length < 1) {
            this.setState({ addonTypeError: true });
            errorToaster("Select addon type");
            return;
        }
        params.addon_set_id = [addonType];


        if (!validateNumber(position)) {
            this.setState({ positionError: true });
            errorToaster("Enter position with only numbers");
            return;
        }
        else {
            if (Number(position < 1)) {
                this.setState({ positionError: true });
                errorToaster("Enter position must be greater than zero");
                return;
            }
        }
        params.position = parseInt(position);

        if (!validateNumber(price)) {
            this.setState({ priceError: true });
            errorToaster("Enter price with only numbers");
            return;
        }
        params.price = parseInt(price);


        it.setState({ loading: true })
        AddonModel.getInstance().addAddon(params,
            function success(data) {
                successNotification('Addon Created Successfully')
                setTimeout(() => {
                    window.location.href = "/addon"
                }, 1000);

            },
            function failure(error) {
                it.setState({ loading: false })
                errorNotification(error)
            }
        )

    }


    render() {
        const { merchantList, merchantError, outletList, outletsError, nameError, arabic_nameError, addonTypeError, positionError, priceError, addonTypeList } = this.state
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <Spin spinning={this.state.loading}>
                    <div className="kt-content  kt-grid__item kt-grid__item--fluid" >

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                    <div className="kt-portlet__head kt-portlet__head--lg">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">Add Addons </h3>
                                        </div>
                                        <div className="kt-portlet__head-toolbar">
                                            <a href="/addon" className="btn btn-clean kt-margin-r-10">
                                                <i className="la la-arrow-left"></i>
                                                <span className="kt-hidden-mobile">Back</span>
                                            </a>
                                            <div className="btn-group">
                                                <button onClick={() => this.sendData()} type="button" className="btn btn-brand">
                                                    <i className="la la-check"></i>
                                                    <span className="kt-hidden-mobile">Save</span>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                    {/* end header */}
                                    <div className="kt-portlet__body">
                                        <form className="kt-form" id="kt_form">
                                            <div className="row">
                                                <div className="col-xl-2"></div>
                                                <div className="col-xl-8">

                                                    <div className="kt-section kt-section--first">

                                                        <div className="kt-section__body">

                                                        <div className="form-group row">
                                                                <label className="col-3 col-form-label">Name <b>*</b></label>
                                                                <div className="col-9">
                                                                    <input onChange={(event) => this.setState({ name: event.target.value })} maxLength="100" className="form-control" type="text" />
                                                                    <span className="form-text text-muted"><p style={{ color: nameError ? 'red' : '' }} >Please enter Name </p></span>

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Arabic Name</label>
                                                                <div className="col-9">
                                                                    <input onChange={(event) => this.setState({ arabic_name: event.target.value })} maxLength="100" className="form-control" type="text" />
                                                                    <span className="form-text text-muted"><p style={{ color: arabic_nameError ? 'red' : '' }} >Please enter name in Arabic</p></span>

                                                                </div>
                                                            </div>


                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Merchant <b>*</b></label>
                                                                <div className="col-9">
                                                                    <Select
                                                                        showSearch
                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                        notFoundContent={
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                <p style={{ fontSize: 14 }}>Please Search Merchant</p>
                                                                            </div>
                                                                        }
                                                                        onSearch={(val) => this.searchQueryMerchant(val, this)}
                                                                        onChange={(merchant) => this.getOutlets(merchant)}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {merchantList.map((merchant) => (
                                                                            <Option key={merchant.id} value={merchant.id} >{merchant.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                    <span className="form-text text-muted"><p style={{ color: merchantError ? 'red' : '' }}>Please Select Merchant</p></span>

                                                                </div>
                                                            </div>
                                                            
                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Outlets <b>*</b></label>
                                                                <div className="col-9">
                                                                    <Select
                                                                        showSearch
                                                                        mode="multiple"
                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                        value={this.state.outlets}
                                                                        onChange={(outlets) => {
                                                                            this.setState({ outlets, addonType : [] })
                                                                            this.searchQuery(this);

                                                                        }}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {outletList.map((outlet) => (
                                                                            <Option key={outlet.id} value={outlet.id} >{outlet.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                    <span className="form-text text-muted"><p style={{ color: outletsError ? 'red' : '' }}>Please Select Outlets</p></span>

                                                                </div>
                                                            </div> 

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Addon Set <b>*</b></label>
                                                                <div className="col-9">
                                                                    <Select
                                                                        showSearch
                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                        value={this.state.addonType}
                                                                        onChange={(addonType) => {
                                                                            this.setState({ addonType })
                                                                        }}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {addonTypeList.map((type) => (
                                                                            <Option key={type.id} value={type.id} >{type.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                    <span className="form-text text-muted"><p style={{ color: addonTypeError ? 'red' : '' }}>Please Select Addon Type</p></span>

                                                                </div>
                                                            </div>

                            
                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Position <b>*</b></label>
                                                                <div className="col-9">
                                                                    <input onChange={(event) => this.setState({ position: event.target.value })} min="1" className="form-control" type="number" />
                                                                    <span className="form-text text-muted"><p style={{ color: positionError ? 'red' : '' }} >Please enter position</p></span>

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Price <b>*</b></label>
                                                                <div className="col-9">
                                                                    <Input
                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                        value={this.state.price}
                                                                        onChange={(event) => this.setState({ price: event.target.value })}
                                                                    />
                                                                    <span className="form-text text-muted"><p style={{ color: priceError ? 'red' : '' }} >Please enter price</p></span>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2"></div>

                                            </div>
                                        </form>
                                    </div>
                                    {/* end body */}
                                </div>
                            </div>
                        </div>

                    </div>
                </Spin>
            </div>

        );
    }
}