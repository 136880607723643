import React from "react";
import {
  // Input,
  Spin,
} from "antd";

// import UserModel from '../../Models/UserModel';

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
import {
  EditorState,
  convertToRaw,
  //   convertFromHTML,
  //   CompositeDecorator,
  ContentState,
  //   convertToHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import SharedModel from "../../Models/SharedModel";

export default class EditSubCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      contentError: false,
      id: null,
      editorState: EditorState.createEmpty(),
      convertedState: "<p></p>",
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  async componentDidMount() {
    let it = this;
    const {
      match: { params },
    } = this.props;
    this.setState({ id: params.pageId, loading: true });
    await SharedModel.getInstance().getPageDetail(
      params.pageId,
      function success(data) {
        it.setState({
          loading: false,
          name: data.title,
          convertedState: data.content ? data.content : "<p></p>",
        });
        const html = data.content ? data.content : "<p></p>";
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          it.setState({ editorState });
        }
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  sendData() {
    const { convertedState, id } = this.state;
    let it = this;
    this.setState({
      contentError: false,
    });

    if (convertedState.length < 10) {
      this.setState({ contentError: true });
      errorToaster("Please Check content Field");
      return;
    }
    let params = {};
    params.id = id;
    params.content = convertedState;
    this.setState({ loading: true });

    SharedModel.getInstance().updatePage(
      params,
      function success() {
        successNotification("Page Updated Successfully");
        window.location.href = "/static";
      },
      function failure(error) {
        errorNotification(error);
        console.log(error);
        it.setState({ loading: false });
      }
    );

    // UserModel.getInstance().updateCategory(parentId, categoryId, name, arb_name, description, arb_description, backColor, logoImage, bannerImage,
    //     function success(data) {
    //         successNotification('Page Updated Successfully')
    //         setTimeout(() => {
    //             window.location.href = '/static';
    //         }, 1000);
    //     },
    //     function failure(error) {
    //         errorNotification(error)
    //         console.log(error)
    //     }
    // );
  }

  render() {
    const { name, contentError, loading } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <Spin spinning={loading}>
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">Edit {name}</h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/static"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Editor
                                </label>
                                <div className="col-9">
                                  <div
                                    style={{
                                      border: "1px solid #f5f5f5",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <Editor
                                      editorState={this.state.editorState}
                                      wrapperClassName="demo-wrapper"
                                      editorClassName="demo-editor"
                                      onEditorStateChange={(editorState) => {
                                        this.setState({
                                          editorState,
                                          convertedState: draftToHtml(
                                            convertToRaw(
                                              editorState.getCurrentContent()
                                            )
                                          ),
                                        });
                                      }}
                                    />
                                  </div>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: contentError ? "red" : "",
                                      }}
                                    >
                                      Please enter Text
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
