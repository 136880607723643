import React, { useState, useCallback, useEffect } from 'react';

import {
    Input,
    Spin,
    Select,
    Icon,
    Popconfirm,
    Switch,
    Tabs
} from 'antd';
import { DetailPageHeaderWithButton } from "styles/detailPageHeader";

import _ from 'lodash'

import {
    successNotification,
    errorNotification,
    errorToaster,
    infoToaster
} from '../Shared/Notifications';
import { DateTime } from 'luxon'
import ContactPocView from '../Shared/ContactPocView';
import CodeTab from './CodeTab';

import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import SharedModel from '../../Models/SharedModel';

import DataJson from './data.json'
import MerchantModel from '../../Models/MerchantModel';
import OnlineDealModel from '../../Models/OnlineDealModel';
import UserModel from '../../Models/UserModel';
import { validateConfigurationParams, validateOnlineDealParams } from './helperFunctions';
import ProductOfferingModel from '../../Models/ProductOfferingModel';
import ProgramModel from '../../Models/ProgramModel';
import { validateVoucherCode } from 'Helpers/Shared/Validators';

const { Option } = Select;
const { TabPane } = Tabs;

const CODE_UPLOAD_LIMIT = 300;
let CODE_OFFSET = 0;

export default function UpdateProductOnlineDeals(props) {
    const
        [loading, setLoading] = useState(false),
        [activeKey, setActiveKey] = useState('1'),
        [name, setName] = useState(''),
        [arabicName, setArabicName] = useState(''),
        [description, setDescription] = useState(EditorState.createEmpty()),
        [arabicDescription, setArabicDescription] = useState(EditorState.createEmpty()),
        [configList, setConfigList] = useState([]),
        [merchantList, setMerchantList] = useState([]),
        [merchant, setMerchant] = useState(undefined),
        [productVisibilityList, setProductVisibilityList] = useState([]),
        [productVisibility, setProductVisibility] = useState(undefined),
        [type, setType] = useState(undefined),
        [userLimit, setUserLimit] = useState(1),
        [categoryList, setCategoryList] = useState([]),
        [infoTagList, setInfoTagList] = useState([]),
        [infoTag, setInfoTag] = useState([]),
        [programList, setProgramList] = useState([]),
        [program, setProgram] = useState([]),
        [codeConfigList, setCodeConfigList] = useState([]),
        [codeConfigCount, setCodeConfigCount] = useState(0),
        [isCodeFetched, setIsCodeFetched] = useState(false),
        [activeCodes, setActiveCodes] = useState(0),
        [currentEditableData, setCurrentEditableData] = useState(undefined);



    useEffect(() => {
        getOnlineDealById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const convertDateIntoJS = (date) => {
        return DateTime.fromISO(date).toJSDate();
    }

    function getOnlineDealById() {
        setLoading(true);
        const { match: { params } } = props;
        getActiveCodes();
        OnlineDealModel.getInstance().getOnlineDeal(params.id,
            function success(data) {
                setName(data.name)
                setArabicName(data.arabic_name)
                setDescription(convertRawHTMLToDraft(data.description))
                setArabicDescription(convertRawHTMLToDraft(data.arabic_description))
                setMerchant(data.merchants ? data.merchant_id : null)
                setMerchantList([...merchantList, data.merchants])
                setProductVisibility(data.product_visibilities ? data.product_visibility_id : null)
                setProductVisibilityList(data.product_visibility_id && data.product_visibilities ? [...productVisibilityList, data.product_visibilities] : [])
                setType(data.type)
                setUserLimit(data.user_limit)
                setConfigList(data.product_online_deal_configs.map(_v => ({ ..._v, is_new: false, is_saved: true })))
                setCategoryList(data.product_online_deal_configs.map(_v => (_v.categories)))
                setInfoTag(data.info_tags.map(_v => (_v.id)))
                setInfoTagList(data.info_tags)
                setProgram(data.programs.map(_v => (_v.id)))
                setProgramList(data.programs)
                setLoading(false)
                getProductOfferingPocsByMerchant(data.merchant_id);
            },
            function failure(err) {
                console.error(err)
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    function getActiveCodes() {
        const { match: { params } } = props;
        setLoading(true);
        OnlineDealModel.getInstance().getOnlineDealVoucherConfigs({ is_active: true, product_online_deal_id: params.id },
            function success(data) {
                setLoading(false);
                setActiveCodes(data.count)
            }, function failure(error) {
                setLoading(false);
            }
        )
    }

    function updateOnlineDeal() {

        const payload = {
            name,
            arabic_name: arabicName,
            description: draftToHtml(convertToRaw(description.getCurrentContent())),
            arabic_description: draftToHtml(convertToRaw(arabicDescription.getCurrentContent())),
            merchant_id: merchant,
            type,
            user_limit: type === 3 ? userLimit : 1,
            product_visibility_id: productVisibility ? productVisibility : null,
            product_online_deal_info_tags: infoTag,
            programs: program
        };
        const validationResult = validateOnlineDealParams(payload);
        if (validationResult.status === false) {
            setActiveKey(validationResult.activeKey)
            errorToaster(validationResult.message);
            return
        }

        if (type === 3 && ((!isCodeFetched && userLimit > activeCodes) || userLimit > activeCodeCount())) {
            errorToaster('User Limit Should Not be greater than active codes');
            setActiveKey('1')
            return
        }

        if ((type === 2 || type === 3) && ((!isCodeFetched && activeCodes < 1) || !checkActiveCodeExist())) {
            errorToaster('There should be at least one active code');
            setActiveKey('4')
            return
        }

        let breakLoop = false;
        for (let i = 0; i < configList.length; i++) {
            if (!configList[i].is_saved) {
                breakLoop = true;
                errorToaster(`Please Save all the configs before updating`);
                break
            }
        }
        if (breakLoop) {
            return
        }


        const { match: { params } } = props;
        OnlineDealModel.getInstance().updateOnlineDeal(params.id, payload,
            function success(data) {
                successNotification('Product Online Deal Updated Successfully');
                setTimeout(() => {
                    setLoading(false);
                    props.history.goBack();
                }, 2000);
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    function getProductOfferingPocsByMerchant(merchant_id) {
        setLoading(true);
        setMerchant(merchant_id)
        ProductOfferingModel.getInstance().getProductOfferingPocsByMerchant(merchant_id,
            function success() {
                setLoading(false);
            },
            function failure(err) {
                setLoading(false);
                errorNotification(JSON.stringify(err))
                console.error(err)
            }

        )
    }

    function convertRawHTMLToDraft(html) {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState);

    }


    function addConfigValues() {
        let list = [...configList]
        list.push({ ...DataJson.addConfigObject, url: type === 3 ? configList[0]?.url : '' });
        setConfigList(list);
    }

    function removeConfigValues(index) {
        let new_list = [...configList]
        if (new_list[index].is_new) {
            new_list.splice(index, 1)
            setConfigList(new_list);
            infoToaster('Configuration Removed')
        } else {
            removeConfigAPI(new_list[index].id, index)
        }

    }

    function updateConfigValues(index, key, value) {
        let new_list = [...configList];
        new_list[index][key] = value;
        if (key !== 'is_saved') {
            new_list[index]['is_saved'] = false;
        }
        setConfigList(new_list);
    }

    function updateURLinAllConfig(value) {
        configList.forEach((_rc, i) => {
            updateConfigValues(i, 'url', value)
        })
    }

    function updateActivation(param, index) {
        if (param.is_new) {
            updateConfigValues(index, 'is_active', !param.is_active);
        } else {
            const payload = { is_active: !param.is_active }
            updateConfigAPI(param.id, payload, index, true)
        }
    }

    function saveConfigValues(payload, index) {
        const configurationValiationResult = validateConfigurationParams(payload, type);
        if (configurationValiationResult.status === false) {
            setActiveKey(configurationValiationResult.activeKey)
            errorToaster(configurationValiationResult.message);
            return
        }
        if (payload.url === '') {
            delete payload.url
        }
        if (payload.video_url === '') {
            delete payload.video_url
        }
        if (payload.is_new) {
            addConfigAPI(payload, index)
        } else {
            updateConfigAPI(payload.id, payload, index)
        }
    }

    function updateConfigAPI(id, payload, index, activation = false) {
        setLoading(true)
        OnlineDealModel.getInstance().updateOnlineDealConfig(id, payload,
            function success(data) {
                if (activation) {
                    let new_list = [...configList];
                    new_list[index]['is_active'] = payload.is_active;
                    setConfigList(new_list);
                } else {
                    updateConfigValues(index, 'is_saved', true);
                }
                infoToaster("Configuration Saved")
                setLoading(false)
            }, function failure(_err) {
                errorNotification(JSON.stringify(_err));
                setLoading(false)
            }
        )
    }

    function addConfigAPI(value, index) {
        const { match: { params } } = props;
        const payload = { ...value, product_online_deal_id: params.id };
        setLoading(true)
        OnlineDealModel.getInstance().addOnlineDealConfig(payload,
            function success(data) {
                updateConfigValues(index, 'is_new', false);
                updateConfigValues(index, 'id', data.id);
                updateConfigValues(index, 'is_saved', true);
                infoToaster("Configuration Saved")
                setLoading(false)
            }, function failure(_err) {
                errorNotification(JSON.stringify(_err));
                setLoading(false)
            }
        )
    }

    function removeConfigAPI(id, index) {
        setLoading(true)
        OnlineDealModel.getInstance().deleteOnlineDealConfig(id,
            function success() {
                let new_list = [...configList]
                new_list.splice(index, 1)
                setConfigList(new_list);
                infoToaster("Configuration Removed")
                setLoading(false)
            }, function failure(_err) {
                errorNotification(JSON.stringify(_err));
                setLoading(false)
            }
        )
    }

    const getSearchMerchant = useCallback(_.debounce(value => getMerchants(value), 500), []);
    async function getMerchants(value) {
        if (value.length > 0) {
            const params = {
                query: encodeURIComponent(value)
            };
            MerchantModel.getInstance().getMerchant(params,
                function success(data) {
                    setMerchantList(data.data);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)

                }
            )
        }
    }



    const getSearchProducVisibility = useCallback(_.debounce(value => getAllProductVisibilities(value), 500), []);
    async function getAllProductVisibilities(value) {
        if (value.length > 0) {
            const params = {
                name: encodeURIComponent(value)
            };
            SharedModel.getInstance().getAllProductVisibilities(params,
                function success() {
                    setProductVisibilityList(SharedModel.ProductVisiblityList);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)

                }
            )
        }
    }


    const getSearchCategories = useCallback(_.debounce(value => getAllCategories(value), 500), []);
    async function getAllCategories(value) {
        if (value.length > 0) {
            const params = {
                'categories.name': encodeURIComponent(value)
            };
            UserModel.getInstance().searchCategoryByName(params,
                function success(data) {
                    setCategoryList(data.data);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)

                }
            )
        }
    }


    const getSearchInfoTags = useCallback(_.debounce(value => getAllInfoTags(value), 500), []);
    async function getAllInfoTags(value) {
        if (value.length > 0) {
            const params = {
                query: encodeURIComponent(value)
            };
            UserModel.getInstance().searchInfoTagByName(params,
                function success(data) {
                    setInfoTagList(data.data);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)

                }
            )
        }
    }

    const getSearchPrograms = useCallback(_.debounce(value => getAllPrograms(value), 500), []);
    async function getAllPrograms(value) {
        if (value.length > 0) {
            const params = {
                'programs.name': encodeURIComponent(value)
            };
            ProgramModel.getInstance().searchProgram(params,
                function success(data) {
                    setProgramList(data.data);
                    setLoading(false)
                },
                function failure(error) {
                    errorNotification(error)
                    setLoading(false)

                }
            )
        }
    }


    // Code Config Logics

    function addNewCodeObject() {
        setCurrentEditableData({ ...DataJson.addCodeConfigObject })
    }

    function setCodeConfigValue(key, value) {
        let new_object = { ...currentEditableData };
        new_object[key] = value
        setCurrentEditableData(new_object);
    }

    async function saveCurrentCodeConfigObject() {
        const { match: { params } } = props;
        const payload = {
            ...currentEditableData,
            is_active: false,
            product_online_deal_id: params.id,
            valid_from: currentEditableData.validity_range.length > 0 ? currentEditableData.validity_range[0] : null,
            valid_till: currentEditableData.validity_range.length > 0 ? currentEditableData.validity_range[1] : null
        }
        if (!validateVoucherCode(payload.code)) {
            errorToaster('Please Enter Valid Code');
            return false;
        };
        saveCurrentCodeConfigObjectAPI([payload])
        return true;
    }
    async function saveCurrentCodeConfigUploaderObject() {
        const { match: { params } } = props;
        const codeArray = currentEditableData.code.split(',');
        let makeCodeBlock = false;
        const payload = codeArray.map(_code => ({
            code: _code,
            max_usage: currentEditableData.max_usage,
            is_active: currentEditableData.is_active,
            validity: currentEditableData.validity,
            is_new: true,
            product_online_deal_id: params.id,
            valid_from: currentEditableData.validity_range.length > 0 ? currentEditableData.validity_range[0] : null,
            valid_till: currentEditableData.validity_range.length > 0 ? currentEditableData.validity_range[1] : null
        }));
        if (payload.length > CODE_UPLOAD_LIMIT) makeCodeBlock = true;
        if (!makeCodeBlock) saveCurrentCodeConfigObjectAPI(payload);
        else makeBlockAndUploadCodes(payload)
        return true;
    }
    function saveCurrentCodeConfigObjectAPI(payload) {
        setLoading(true);
        OnlineDealModel.getInstance().addOnlineDealVoucherConfig(payload,
            function success(data) {
                infoToaster('Voucher Code Saved')
                setLoading(false);
                const filters = { offset: 0, limit: 20 };
                getOnlineDealVoucherConfigs(filters);
            }, function failure(error) {
                errorToaster(JSON.stringify(error))
                setLoading(false);
            }
        )
    }

    function getOnlineDealVoucherConfigs(filters) {
        const { match: { params } } = props;
        setLoading(true);
        OnlineDealModel.getInstance().getOnlineDealVoucherConfigs({ ...filters, product_online_deal_id: params.id },
            function success(data) {
                setIsCodeFetched(true)
                setLoading(false);
                setCodeConfigCount(data.count);
                setCodeConfigList(data.records.map(_v => ({ ..._v, is_new: false, validity_range: _v.valid_till ? [convertDateIntoJS(_v.valid_from), convertDateIntoJS(_v.valid_till)] : [] })))

            }, function failure(error) {
                setLoading(false);
            }
        )
    }

    function makeBlockAndUploadCodes(code_config_data) {
        if (CODE_OFFSET >= code_config_data.length) {
            setLoading(false);
            infoToaster('Voucher Code Saved')
        } else {
            const codeBlock = [...code_config_data].slice(CODE_OFFSET, CODE_OFFSET + CODE_UPLOAD_LIMIT);
            CODE_OFFSET = CODE_OFFSET + CODE_UPLOAD_LIMIT;
            saveCodeConfigBlock(codeBlock, code_config_data);
        };
    }

    function saveCodeConfigBlock(payload, code_config_data) {
        setLoading(true);
        OnlineDealModel.getInstance().addOnlineDealVoucherConfig(payload,
            function success(data) {
                const filters = { offset: 0, limit: 20 };
                getOnlineDealVoucherConfigs(filters);
                makeBlockAndUploadCodes(code_config_data)
            }, function failure(error) {
                errorToaster(JSON.stringify(error))
                setLoading(false);
            }
        )
    }

    async function updateCurrentCodeConfigObject(index) {
        const payload = {
            ...currentEditableData,
            valid_from: currentEditableData.validity_range.length > 0 ? currentEditableData.validity_range[0] : null,
            valid_till: currentEditableData.validity_range.length > 0 ? currentEditableData.validity_range[1] : null
        }
        if (payload.code.length < 3) {
            errorToaster('Please Enter Valid Code');
            return false;
        }
        setLoading(true);
        OnlineDealModel.getInstance().updateOnlineDealVoucherConfig(payload.id, payload,
            function success() {
                infoToaster('Voucher Code Saved')
                setLoading(false);
                const filters = { offset: 0, limit: 20 };
                getOnlineDealVoucherConfigs(filters);
            }, function failure(error) {
                errorToaster(JSON.stringify(error))
                setLoading(false);
            }
        )
        return true;
    }

    function updateCodeConfigActivation(index, is_active) {
        if (is_active && checkActiveCodeExistInTypeGeneralVouchers()) {
            errorToaster('Cannot activate more than one code in General Vouchers');
            return
        }
        const payload = {
            is_active
        }
        const id = codeConfigList[index].id;
        setLoading(true);
        OnlineDealModel.getInstance().updateOnlineDealVoucherConfig(id, payload,
            function success() {
                let list = [...codeConfigList]
                list[index].is_active = is_active;
                setCodeConfigList(list);
                setLoading(false);
            }, function failure(error) {
                errorToaster(JSON.stringify(error))
                setLoading(false);
            }
        )
    }
    function deleteCodeConfig(index) {
        const id = codeConfigList[index].id;
        OnlineDealModel.getInstance().deleteOnlineDealVoucherConfig(id,
            function success() {
                infoToaster('Voucher Code Deleted')
                let list = [...codeConfigList]
                list.splice(index, 1)
                setCodeConfigList(list);
                setLoading(false);
            }, function failure(error) {
                errorToaster(JSON.stringify(error))
                setLoading(false);
            }
        )
    }
    function setCurrentCodeConfig(index) {
        if (codeConfigList[index].is_new) {
            const new_obj = { ...codeConfigList[index] }
            setCurrentEditableData(new_obj)
        }
    }
    function checkActiveCodeExistInTypeGeneralVouchers() {
        return type === 2 && codeConfigList.some(_x => _x.is_active === true);
    }

    function checkActiveCodeExist() {
        return codeConfigList.some(_x => _x.is_active === true);
    }

    function activeCodeCount() {
        return codeConfigList.filter(_x => _x.is_active === true).length;
    }

    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                <Spin spinning={loading} >

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                <div className="kt-portlet__head kt-portlet__head--lg">
                                    <DetailPageHeaderWithButton
                                        title={"Edit Product Online Deal"}
                                        buttonOneIcon={"la la-arrow-left"}
                                        buttonOneCallBack={() => props.history.goBack()}
                                        buttonTwoIcon={"la la-check"}
                                        buttonTwoCallBack={() => updateOnlineDeal()}
                                    />
                                </div>

                                <div className="kt-portlet__body">
                                    <form className="kt-form" id="kt_form">
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-8">
                                                <div className="kt-section kt-section--first">
                                                    <div className="kt-section__body">

                                                        <Tabs onChange={setActiveKey} activeKey={activeKey} defaultActiveKey="1" >
                                                            <TabPane tab="Overview" key="1">
                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Merchant <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            disabled
                                                                            showSearch
                                                                            style={{ margin: '10px 0px 10px 0px' }}
                                                                            value={merchant}
                                                                            notFoundContent={

                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                    <p style={{ fontSize: 14 }}>Please Search Merchant</p>
                                                                                </div>
                                                                            }
                                                                            onSearch={(val) => getSearchMerchant(val)}
                                                                            // onChange={(merchant) => setMerchant(merchant)}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {merchantList.map((merchant) => (
                                                                                <Option key={merchant.id} value={merchant.id}>{`${merchant.name} ${merchant.is_active ? ' (active)' : ' (in-active)'}`}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted">Please Select Merchant</span>

                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Programs <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            mode="multiple"
                                                                            showSearch
                                                                            style={{ margin: '10px 0px 10px 0px' }}
                                                                            value={program}
                                                                            notFoundContent={

                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                    <p style={{ fontSize: 14 }}>Please Search Programs</p>
                                                                                </div>
                                                                            }
                                                                            onSearch={(val) => getSearchPrograms(val)}
                                                                            onChange={(program) => setProgram(program)}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {programList.map((program) => (
                                                                                <Option key={program.id} value={program.id}>{`${program.name} ${program.is_active ? ' (active)' : ' (in-active)'}`}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted">Please Select Programs</span>

                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Types <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            disabled
                                                                            // showSearch
                                                                            style={{ margin: '10px 0px 10px 0px' }}
                                                                            value={type}
                                                                            // onChange={(type) => setType(type)}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {DataJson.typesArray.map((type) => (
                                                                                <Option key={type.value} value={type.value}>{type.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted">Please Select Type</span>

                                                                    </div>
                                                                </div>

                                                                {
                                                                    type === 3 &&
                                                                    <div className="form-group row">
                                                                        <label className="col-3 col-form-label">User Limit <b>*</b></label>
                                                                        <div className="col-9">
                                                                            <Input min={1} max={10} maxLength={2} value={userLimit} onChange={(event) => setUserLimit(event.target.value)} className="form-control" type="number" />
                                                                            <span className="form-text text-muted"><p>Please Enter Product Online Deal Name</p></span>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Name <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Input maxLength={100} value={name} onChange={(event) => setName(event.target.value)} className="form-control" type="text" />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Enter Product Online Deal Name</p></span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Arabic Name</label>
                                                                    <div className="col-9">
                                                                        <Input maxLength={100} value={arabicName} onChange={(event) => setArabicName(event.target.value)} className="form-control" type="text" />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Enter Arabic Product Online Deal Name</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Description <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Editor
                                                                            editorState={description}
                                                                            toolbarClassName="toolbarClassName"
                                                                            wrapperClassName="wrapperClassName"
                                                                            editorClassName="editorClassName form-control"
                                                                            editorStyle={{ minHeight: '80px' }}
                                                                            onEditorStateChange={(value) => setDescription(value)}
                                                                        />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Enter Product Online Deal description</p></span>
                                                                    </div>
                                                                </div>

                                                                <div style={configList.length > 0 ? { borderBottom: '1px dotted black' } : {}} className="form-group row">
                                                                    <label className="col-3 col-form-label">Arabic Description</label>
                                                                    <div className="col-9">
                                                                        <Editor
                                                                            editorState={arabicDescription}
                                                                            toolbarClassName="toolbarClassName"
                                                                            wrapperClassName="wrapperClassName"
                                                                            editorClassName="editorClassName form-control"
                                                                            editorStyle={{ minHeight: '80px' }}
                                                                            onEditorStateChange={(value) => setArabicDescription(value)}
                                                                        />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Enter Arabic Product Online Deal description</p></span>
                                                                    </div>
                                                                </div>
                                                            </TabPane>

                                                            <TabPane tab="Merchant Contact" key="2">
                                                                <ContactPocView
                                                                    is_outlet={false}
                                                                    id={merchant}
                                                                />
                                                            </TabPane>


                                                            <TabPane tab="Configuration" key="3">
                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Product Visibility <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            showSearch
                                                                            style={{ margin: '10px 0px 10px 0px' }}
                                                                            value={productVisibility}
                                                                            notFoundContent={
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                    <p style={{ fontSize: 14 }}>Please search product visibility (Full text search)</p>
                                                                                </div>
                                                                            }
                                                                            onSearch={(val) => getSearchProducVisibility(val)}
                                                                            onChange={(merchant) => setProductVisibility(merchant)}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {productVisibilityList.map((visibility) => (
                                                                                <Option key={visibility.id} value={visibility.id}>{visibility.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted">Please Product Visibility</span>

                                                                    </div>
                                                                </div>


                                                                {
                                                                    configList.map((config, i) => (
                                                                        <div key={i} className="form-group">

                                                                            <div className="form-group row">
                                                                                <label className="col-3 col-form-label">Configuration {i + 1} <b>*</b></label>
                                                                                {/* {
                                                                                    type === 2 &&
                                                                                    <div className="col-3">
                                                                                        <input placeholder="Code" maxLength={10} value={config.code} onChange={(event) => updateConfigValues(i, 'code', event.target.value)} className="form-control" type="text" />
                                                                                        <span className="form-text text-muted"><p>Please Enter Deal Code</p></span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    type === 2 &&
                                                                                    <div className="col-3">
                                                                                        <input placeholder="Max Usage" maxLength={5} value={config.max_usage} onChange={(event) => updateConfigValues(i, 'max_usage', event.target.value)} className="form-control" type="number" />
                                                                                        <span className="form-text text-muted"><p>Please Enter Deal Max Usage</p></span>
                                                                                    </div>
                                                                                } */}
                                                                                <div className="col-3 row">
                                                                                    <Popconfirm
                                                                                        title={`Are you sure you want to ${config.is_active ? `DEACTIVATE` : `ACTIVATE`} this Configuration`}
                                                                                        icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                                                        onConfirm={() => updateActivation(config, i)}
                                                                                    >
                                                                                        <Switch style={{ margin: '5px', backgroundColor: config.is_active ? '#5d78ff' : '#fd397a', marginLeft: 15 }} checked={config.is_active} />
                                                                                    </Popconfirm>

                                                                                    {
                                                                                        !config.is_saved &&
                                                                                        <Popconfirm
                                                                                            title={`Are you sure you want to save this Configuration`}
                                                                                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                                                            onConfirm={() => saveConfigValues(config, i)}
                                                                                        >
                                                                                            <Icon type="save" style={{ color: 'red', margin: '5px', fontSize: 20 }} />
                                                                                        </Popconfirm>
                                                                                    }

                                                                                    <Popconfirm
                                                                                        title="Are you sure you want to delete this Configuration? This Action is ir-reversable!"
                                                                                        icon={<Icon type="delete" style={{ color: 'red' }} />}
                                                                                        onConfirm={() => removeConfigValues(i)}
                                                                                    >
                                                                                        <Icon type="delete" style={{ color: 'red', margin: '5px', fontSize: 20 }} />
                                                                                    </Popconfirm>

                                                                                </div>
                                                                            </div>
                                                                            {(type === 1 || type === 2 || type === 3) &&
                                                                                <div className="form-group row">
                                                                                    <label className="col-3 col-form-label">URL <b>*</b></label>
                                                                                    <div className="col-9">
                                                                                        <input disabled={type === 3 && i !== 0} placeholder="URL" maxLength={255} value={config.url} onChange={(event) => updateURLinAllConfig(event.target.value)} className="form-control" type="text" />
                                                                                        <span className="form-text text-muted"><p>Please Enter Deal URL</p></span>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {type === 0 &&
                                                                                <div className="form-group row">
                                                                                    <label className="col-3 col-form-label">Video URL <b>*</b></label>
                                                                                    <div className="col-9">
                                                                                        <input placeholder="URL" maxLength={255} value={config.video_url} onChange={(event) => updateConfigValues(i, 'video_url', event.target.value)} className="form-control" type="text" />
                                                                                        <span className="form-text text-muted"><p>Please Enter Deal URL</p></span>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            <div style={{ borderBottom: '1px dotted black' }} className="form-group row">
                                                                                <label className="col-3 col-form-label">Category <b>*</b></label>
                                                                                <div className="col-9">
                                                                                    <Select
                                                                                        showSearch
                                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                                        notFoundContent={
                                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                                <p style={{ fontSize: 14 }}>Please search any other category name</p>
                                                                                            </div>
                                                                                        }
                                                                                        onSearch={(val) => getSearchCategories(val)}
                                                                                        value={config.category_id}
                                                                                        onChange={(value) => updateConfigValues(i, 'category_id', value)}
                                                                                        filterOption={(input, option) =>
                                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                        }
                                                                                    >
                                                                                        {categoryList.map((_cat) => (
                                                                                            <Option key={_cat.id} value={_cat.id}>{_cat.name}</Option>
                                                                                        ))}
                                                                                    </Select>
                                                                                    <span className="form-text text-muted"><p>Please Choose category</p></span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    ))
                                                                }

                                                                {(type === 0 || type === 1 || type === 2) && configList.length > 0 ?
                                                                    null :
                                                                    <div className="form-group row">
                                                                        <div className="col-9">
                                                                            <div className="btn-group">
                                                                                <button onClick={() => addConfigValues()} type="button" className="btn btn-brand">
                                                                                    <i className="la la-plus"></i>
                                                                                    <span className="kt-hidden-mobile">Add Configuration</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Info Tags</label>
                                                                    <div className="col-9">
                                                                        <Select
                                                                            mode="multiple"
                                                                            showSearch
                                                                            style={{ margin: '10px 0px 10px 0px' }}
                                                                            notFoundContent={
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                    <p style={{ fontSize: 14 }}>Please search any other Info Tag name</p>
                                                                                </div>
                                                                            }
                                                                            onSearch={(val) => getSearchInfoTags(val)}
                                                                            value={infoTag}
                                                                            onChange={(value) => setInfoTag(value)}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {infoTagList.map((_tag) => (
                                                                                <Option key={_tag.id} value={_tag.id}>{_tag.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted"><p>Please Choose category</p></span>
                                                                    </div>
                                                                </div>
                                                            </TabPane>

                                                            {(type === 2 || type === 3) &&
                                                                <TabPane tab="Codes" key="4">
                                                                    <CodeTab
                                                                        is_editable={false}
                                                                        codeData={codeConfigList}
                                                                        currentData={currentEditableData}
                                                                        addNewCodeObject={() => addNewCodeObject()}
                                                                        setDataValues={setCodeConfigValue}
                                                                        saveObject={saveCurrentCodeConfigObject}
                                                                        saveUploaderObject={saveCurrentCodeConfigUploaderObject}
                                                                        updateObject={updateCurrentCodeConfigObject}
                                                                        cancelObject={() => setCurrentEditableData(undefined)}
                                                                        updateCodeConfigActivation={updateCodeConfigActivation}
                                                                        deleteCodeConfig={deleteCodeConfig}
                                                                        setCurrentCodeConfig={setCurrentCodeConfig}
                                                                        type={type}
                                                                        getVouchers={(filters) => getOnlineDealVoucherConfigs(filters)}
                                                                        total_count={codeConfigCount}
                                                                    />
                                                                </TabPane>
                                                            }

                                                        </Tabs>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div >

    )
}