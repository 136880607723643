import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel'



export default class ProgramModel {
    static myInstance = null;
    static getInstance() {
        if (ProgramModel.myInstance === null) {
            ProgramModel.myInstance = new ProgramModel();
        }
        return this.myInstance;
    }

    static ProgramList = [];
    static ProgramTypeList = [];



    getProgram(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getProgram(token, params,
            function resSuccess(data) {
                ProgramModel.getInstance().ProgramList = data.programs.data;
                successTrigger(data.programs);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    searchProgram(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().searchProgram(token, params,
            function resSuccess(data) {
                ProgramModel.getInstance().ProgramList = data.programs.data;
                successTrigger(data.programs);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    addProgram(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addProgram(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    getProgramDetail(programId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getProgramDetail(token, programId,
            function resSuccess(data) {
                successTrigger(data.program);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    setDefaultProgram(programId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        let params = {};
        params.id = programId;

        ApiManager.getInstance().setDefaultProgram(token, params,
            function resSuccess() {
                successTrigger();
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateProgram(programId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        // let params = {};
        // params.id = programId;
        // params.name = name;
        // if (arb_name.length > 0) {
        //     params.arabic_name = arb_name;
        // }
        // params.currency = currency;
        // params.price = price;
        // if (discountedPrice.length > 0) {
        //     params.discounted_price = discountedPrice;
        // }
        // else if (discountedPrice) {
        //     params.discounted_price = discountedPrice;
        // } else params.discounted_price = null;
        // params.estimated_saving = estimatedSaving;
        // params.start_date = startDate;
        // params.end_date = endDate;
        // params.program_type_id = programType;
        // params.partner_id = partner;
        // params.city_id = cities;
        // params.is_purchase = is_purchase;
        // params.is_mastercard = is_mastercard;
        // if (redemption.length > 0) {
        //     params.number_of_redemption = redemption;
        // }
        ApiManager.getInstance().updateProgram(token, programId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    removeProgram(programId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeProgram(token, programId,
            function resSuccess() {
                ProgramModel.getInstance().ProgramList.forEach((program, index) => {
                    if (program.id === programId) {
                        ProgramModel.getInstance().ProgramList.splice(index, 1);
                        successTrigger()
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    programActivation(programId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        ApiManager.getInstance().programActivation(token, programId, params,
            function resSuccess(data) {
                ProgramModel.getInstance().ProgramList.forEach((program, index) => {
                    if (program.id === programId) {
                        program.is_active = params.is_active;
                        successTrigger(data)
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    getProgramType(successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getProgramType(token,
            function resSuccess(data) {
                ProgramModel.getInstance().ProgramTypeList = data
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getPartnerCities(partners, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        let params = {};
        params.id = partners
        ApiManager.getInstance().getPartnerCities(token, params,
            function resSuccess(data) {
                ProgramModel.getInstance().PartnerCitiesList = data.cities;
                successTrigger()
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getProgramAssociation(programId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getProgramAssociation(token, programId,
            function resSuccess(data) {
                successTrigger(data.program)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getMerchantOffer(merchants, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        let params = {};
        params.merchant_id = merchants
        ApiManager.getInstance().getMerchantOffer(token, params,
            function resSuccess(data) {
                successTrigger(data.offers)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    addOfferAssociation(programId, offers, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        let params = {};
        params.program_id = programId;
        params.offer_id = offers;
        ApiManager.getInstance().addOfferAssociation(token, params,
            function resSuccess() {
                successTrigger()
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getProgramMerchant(programId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getProgramMerchant(token, programId,
            function resSuccess(data) {
                successTrigger(data.merchant)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getOutletProgramMerchant(programId, merchantId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOutletProgramMerchant(token, programId, merchantId,
            function resSuccess(data) {
                successTrigger(data.outlets)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getOfferProgramMerchant(programId, outletId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOfferProgramMerchant(token, programId, outletId,
            function resSuccess(data) {
                successTrigger(data.offers)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }
}