import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { DateTime } from "luxon";

export const BookingCalendar = ({ calendarData, getCalendarReportData, history }) => {


    return (
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,listWeek'
            }}
            events={calendarData.map(_ev => ({
                id: _ev.id,
                title: _ev.user.full_name,
                date: DateTime.fromISO(_ev.scheduled_at).setZone("Asia/Qatar").toJSDate()
            }))}
            datesSet={(arg) => getCalendarReportData(arg.view.activeStart, arg.view.activeEnd)}
            eventClick={({ event }) => history.push(`/booking-report/${event.id}`)}
        />
    )
}