import React from "react";
import { Link } from "react-router-dom";
import { Icon, Popconfirm, notification, Select, Switch, Spin } from "antd";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  infoNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
import UserModel from "../../Models/UserModel";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      perPage: 0,
      totalPage: 0,
      filters: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getCategory();
  }


  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getCategory();
  }, 1000);

  getCategory() {
    const it = this;
    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter, i) => {
      params[filter.id] = filter.value.toLowerCase();
    });
    if (params["is_active"] === "all") {
      delete params["is_active"];
    }
    UserModel.getInstance().searchCategoryByName(
      params,
      function success(data) {
        console.log(data);
        it.setState({
          data: UserModel.getInstance().CategoryList,
          loading: false,
          // current_page: data.curre,
          perPage: data.per_page,
          totalPage: data.last_page,
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false });
        errorToaster("Request information not found");
      }
    );
  }

  deleteCategory(category_id) {
    const it = this;
    it.setState({ loading: true });
    UserModel.getInstance().removeCategory(
      category_id,
      function success() {
        it.setState({
          data: UserModel.getInstance().CategoryList,
          loading: false,
        });
        infoNotification("Category Deleted");
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            {gridHeaderWithButton(
              "Categories",
              "la la-plus",
              "Add New Category",
              "category/add"
            )}
          </div>
          <Spin spinning={this.state.loading}>
            <div className="kt-portlet__body">
              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={20}
                showPageSizeOptions={false}
                manual
                page={this.state.currentPage - 1}
                pages={this.state.totalPage}
                filtered={this.state.filters}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getCategory();
                  }, 50);
                }}
                onFilteredChange={(filters) => {
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                columns={[
                  // {
                  // Header: "Name",
                  // columns: [
                  {
                    Header: <p style={tableHeaderStyles()}>Icon</p>,
                    id: "icon",
                    accessor: (d) => (
                      <img
                        src={d.icon}
                        style={{ height: 50, width: 50, objectFit: "contain" }}
                        alt="logo"
                      />
                    ),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["id"] }),
                    filterable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    id: "categories.name",
                    accessor: (d) => d.name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Arabic Name</p>,
                    id: "categories.arabic_name",
                    accessor: (d) => d.arabic_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["arabic_name"],
                      }),
                    filterAll: true,
                  },

                  {
                    Header: <p style={tableHeaderStyles()}>Parent Category</p>,
                    id: "parent_name",
                    accessor: (d) => (d.parent_name ? d.parent_name : null),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["parent_name"],
                      }),
                    filterable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "categories.is_active",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value == "true") {
                        return row[filter.id] == true;
                      }
                      return row[filter.id] == false;
                    },
                    Filter: ({ filter, onChange }) => (
                      <Select
                        onChange={(value) => onChange(value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <Option value="all">Show all</Option>
                        <Option value="true">Active</Option>
                        <Option value="false">De-Activate</Option>
                      </Select>
                    ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 150,
                    accessor: (d) => (
                      <div>
                        <Link
                          to={`category/edit/${d.id}`}
                          style={{ marginRight: 20 }}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="edit"
                          />
                        </Link>
                        <Popconfirm
                          title="Are you sure you want to delete this category?"
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() => this.deleteCategory(d.id)}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="delete"
                          />
                        </Popconfirm>
                        {/* <Popconfirm
																	// style={{}}
																	title={d.is_active ? "Are you sure you want to DEACTIVATE this category" : "Are you sure you want to ACTIVATE this category"}
																	icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
																	onConfirm={()=> d.is_active ? this.deactivateCategory(d.id) : this.activateCategory(d.id)}
																> */}
                        <Switch
                          disabled
                          style={{
                            backgroundColor: d.is_active
                              ? "#5d78ff"
                              : "#fd397a",
                            marginLeft: 15,
                          }}
                          checked={d.is_active}
                          onChange={(val) => console.log(val)}
                        />
                        {/* </Popconfirm> */}
                      </div>
                    ),
                    filterable: false,
                  },
                ]}
                defaultPageSize={20}
                pageSizeOptions={[20]}
                className="-striped -highlight"
              />
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
