import React from "react";
import { Icon } from "antd";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Link } from "react-router-dom";
import { tableHeaderStyles } from "styles/tableStyles";
import { DateTime } from "luxon";

export const BookingGrid = ({ setState, state, getProductBookingReports, searchQuery }) => {
    return (
        <ReactTable
            data={state.data}
            filterable
            pageSize={state.pageCount}
            showPageSizeOptions={true}
            pageSizeOptions={[5, 10, 20, 25, 50]}
            manual
            page={state.currentPage - 1}
            pages={state.total_pages}
            filtered={state.filters}
            onPageSizeChange={(pageCount) => {
                setState({ currentPage: 1, pageCount });
                setTimeout(() => {
                    getProductBookingReports();
                }, 50);
            }}
            onPageChange={(pageIndex) => {
                setState({ currentPage: pageIndex + 1 });
                setTimeout(() => {
                    getProductBookingReports();
                }, 50);
            }}
            onFilteredChange={(filters) => {
                setState({ currentPage: 1, filters });
                searchQuery();
            }}
            defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
            }
            columns={[
                {
                    Header: <p style={tableHeaderStyles()}>Booking Id</p>,
                    id: "id",
                    accessor: (d) => d.id,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["id"] }),
                    filterAll: true,
                    filterable: true,
                },
                {
                    Header: <p style={tableHeaderStyles()}>Customer Name</p>,
                    id: "$user.full_name$",
                    accessor: (d) => d.user?.full_name,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                            keys: ["full_name"],
                        }),
                    filterAll: true,
                    filterable: true,
                },
                {
                    Header: <p style={tableHeaderStyles()}>Customer Phone</p>,
                    id: "$user.mobile$",
                    accessor: (d) => d.user?.mobile,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["mobile"] }),
                    filterAll: true,
                    filterable: true,
                },
                {
                    Header: <p style={tableHeaderStyles()}>Customer Email</p>,
                    id: "$user.email$",
                    accessor: (d) => d.user?.email,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["email"] }),
                    filterAll: true,
                    filterable: true,
                },
                {
                    Header: <p style={tableHeaderStyles()}>Booking Status</p>,
                    id: "$order_status.name$",
                    accessor: (d) => d.order_status?.name,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                    filterable: true,
                },
                {
                    Header: (
                        <p style={tableHeaderStyles()}>Booking Created On</p>
                    ),
                    id: "created_at",
                    accessor: (d) =>
                        DateTime.fromISO(d.created_at).toFormat("ff"),
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                            keys: ["created_at"],
                        }),
                    filterAll: true,
                    filterable: false,
                },
                {
                    Header: <p style={tableHeaderStyles()}>Base Price</p>,
                    id: "list_price",
                    accessor: (d) => d.list_price,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                            keys: ["list_price"],
                        }),
                    filterAll: true,
                    filterable: false,
                },
                {
                    Header: <p style={tableHeaderStyles()}>Final Price</p>,
                    id: "discounted_price",
                    accessor: (d) => d.discounted_price,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                            keys: ["discounted_price"],
                        }),
                    filterAll: true,
                    filterable: false,
                },

                {
                    Header: <p style={tableHeaderStyles()}>Action</p>,
                    id: "actions",
                    width: 50,
                    accessor: (d) => (
                        <div>
                            <Link
                                to={`/booking-report/${d.id}`}
                                style={{ marginRight: 20 }}
                            >
                                <Icon
                                    style={{ color: "#5D78FF", fontSize: 16 }}
                                    type="eye"
                                />
                            </Link>
                        </div>
                    ),
                    filterable: false,
                },
            ]}
            defaultPageSize={10}
            className="-striped -highlight"
        />
    )
}