import React from "react";
import {
  Select,
  Spin,
  // Icon,
  // Input
} from "antd";

import {
  errorNotification,
  errorToaster,
  successNotification,
} from "../Shared/Notifications";

import ValueTypeModel from "../../Models/ValueTypeModel";
// import ProgramModel from '../../Models/ProgramModel';

const { Option } = Select;

export default class EditValueType extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      name: "",
      nameError: false,
      type: null,
      typeList: [],
      typeError: false,
      program: null,
      programList: [],
      programError: false,
      visible: false,
      visibleError: false,
      valueTypeId: null,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    let it = this;
    it.setState({ loading: true });
    const {
      match: { params },
    } = this.props;
    it.setState({ valueTypeId: params.valueTypeId });
    ValueTypeModel.getInstance().getValueTypeDetail(
      params.valueTypeId,
      function success(data) {
        it.setState({
          loading: false,
          name: data.name,
          program: data.program_id,
          type: data.value_type_id,
          visible: data.visible,
        });
      },
      function failure(error) {
        console.error(error);
      }
    );

    ValueTypeModel.getInstance().getType(
      function success() {
        it.setState({
          typeList: ValueTypeModel.getInstance().TypeList,
          loading: false,
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false });
      }
    );

    ValueTypeModel.getInstance().getProgramValueType(
      3,
      function success() {
        it.setState({
          programList: ValueTypeModel.getInstance().ProgramValueTypeList,
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false });
      }
    );
  }

  sendData() {
    const { valueTypeId, name, visible } = this.state;
    let it = this;
    let params = {};
    this.setState({
      nameError: false,
      visibleError: false,
    });

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster("Please enter name");
      return;
    }
    params.name = name;

    params.visible = visible;

    console.log("check", valueTypeId);
    it.setState({ loading: true });
    ValueTypeModel.getInstance().updateValueType(
      valueTypeId,
      params,
      function success(data) {
        successNotification("Value Type Updated Successfully");
        setTimeout(() => {
          window.location.href = "/value-type";
        }, 1000);
      },
      function failure(error) {
        errorNotification(error);
        console.log(error);
      }
    );
  }

  render() {
    const {
      nameError,
      typeList,
      typeError,
      programList,
      programError,
      visibleError,
      visibleList,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <Spin spinning={this.state.loading}>
          <div className="kt-content  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">
                        Edit Value Types
                      </h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/value-type"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    value={this.state.name}
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    maxLength="100"
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter Name{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Type <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    disabled
                                    value={this.state.type}
                                    onChange={(type) => {
                                      this.setState({ type });
                                    }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {typeList.map((type) => (
                                      <Option key={type.id} value={type.id}>
                                        {type.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: typeError ? "red" : "" }}
                                    >
                                      Please Select Types
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Program <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    disabled
                                    value={this.state.program}
                                    onChange={(program) => {
                                      this.setState({ program });
                                    }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {programList.map((program) => (
                                      <Option
                                        key={program.id}
                                        value={program.id}
                                      >
                                        {program.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: programError ? "red" : "",
                                      }}
                                    >
                                      Please Select Program
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Visible <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.visible}
                                    onChange={(visible) => {
                                      this.setState({ visible });
                                    }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: visibleError ? "red" : "",
                                      }}
                                    >
                                      Please Select Visible
                                    </p>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
