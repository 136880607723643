import React from "react";

import backImage from "../../../assets2/Images/bg-3.jpg";
import logo from "../../../assets/media/logos/logo-4.png";
import { validateEmail } from "../../../Helpers/Shared/Validators";
import { errorNotification, errorToaster } from "../../Shared/Notifications";
import UserModel from "../../../Models/UserModel";

export default class NewPassword extends React.Component {
  state = {
    loading: false,
    email: "",
    emailError: false,
    code: "",
    codeError: false,
    password: "",
    passwordError: false,
  };

  resetCode() {
    const { email, code, password } = this.state;
    let it = this;
    let params = {};
    this.setState({
      emailError: false,
      codeError: false,
      passwordError: false,
    });

    if (!validateEmail(email)) {
      this.setState({ emailError: "Please enter a valid Email" });
      errorToaster("Please enter a valid Email");
      return;
    }
    params.email = email;

    // if (!code) {
    // 	this.setState({ codeError: 'Please enter a valid Code' });
    // 	errorToaster('Please enter a valid Code');
    // 	return;
    // }
    params.code = code;

    if (password.length < 6) {
      this.setState({
        passwordError: "Password Must be at least 6 characters",
      });
      errorToaster("Password Must be at least 6 characters");
      return;
    }

    params.password = password;

    this.setState({ loading: true });
    UserModel.getInstance().updatePassword(
      params,
      function success() {
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
      },
      function failure(error) {
        console.log(error);
        errorNotification(error);
        it.setState({ loading: false });
      }
    );
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v3 kt-login--forgot"
          id="kt_login"
        >
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
            style={{ backgroundImage: `url(${backImage})` }}
          >
            <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
              <div className="kt-login__container">
                <div className="kt-login__logo">
                  <a href="/">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <div className="kt-login__forgot">
                  <div className="kt-login__head">
                    <h3 className="kt-login__title">New Password</h3>
                    <div className="kt-login__desc">
                      Please enter your New Password.
                    </div>
                  </div>
                  <div className="kt-form">
                    <div className="input-group">
                      <input
                        name="email"
                        onChange={(event) => this.handleChange(event)}
                        className="form-control"
                        type="text"
                        placeholder="Email Address"
                        autoComplete="off"
                      />
                      <div
                        id="email-error"
                        style={{
                          display: this.state.emailError ? "block" : "none",
                          fontSize: "smaller",
                          fontWeight: "600",
                        }}
                        className="error invalid-feedback"
                      >
                        Please enter a valid Email.
                      </div>
                      {/* <input className="form-control" type="text" placeholder="123456" name="password" id="kt_newPassword" autoComplete="off" /> */}
                    </div>
                    <div className="input-group">
                      <input
                        name="code"
                        onChange={(event) =>
                          this.setState({ code: event.target.value })
                        }
                        className="form-control"
                        type="text"
                        placeholder="Enter Code"
                        autoComplete="off"
                      />
                      {/* <div id="code-error" style={{ display: this.state.code ? 'block' : 'none', fontSize: 'smaller', fontWeight: '600' }} className="error invalid-feedback">Please enter a valid code.</div> */}
                      {/* <input className="form-control" type="text" placeholder="123456" name="password" id="kt_CPassword" autoComplete="off" /> */}
                    </div>
                    <div className="input-group">
                      <input
                        name="password"
                        onChange={(event) => this.handleChange(event)}
                        className="form-control"
                        type="password"
                        placeholder="New Password"
                      />
                      <div
                        id="password-error"
                        style={{
                          display: this.state.passwordError ? "block" : "none",
                          fontSize: "smaller",
                          fontWeight: "600",
                        }}
                        className="error invalid-feedback"
                      >
                        This field is required.
                      </div>
                    </div>
                    <div className="kt-login__actions">
                      <button
                        id="kt_login_forgot_submit"
                        className="btn btn-brand btn-elevate kt-login__btn-primary"
                      >
                        Set Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
