import React, { useEffect, useState } from "react";

import {
    TimePicker,
    Input,
    Spin,
    Select,
    Icon,
    Popconfirm,
} from "antd";
import { DetailPageHeaderWithButton } from "styles/detailPageHeader";
import {
    successNotification,
    errorNotification,
    errorToaster
} from "../Shared/Notifications";

import moment from "moment";

import ProductOrderTimeSlotModel from "Models/ProductOrderTimeSlotModel";


import DataJson from "./data.json"


const { Option } = Select;


export default function EditOrderTimeSlot(props) {
    const
        [loading, setLoading] = useState(false),
        [name, setName] = useState(""),
        [configList, setConfigList] = useState([]);

    useEffect(() => {
        getOrderTimeSlotDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getOrderTimeSlotDetail = () => {
        const { match: { params } } = props;
        setLoading(true);
        ProductOrderTimeSlotModel.getInstance().getOrderTimeSlotDetail(params.id,
            function success(data) {
                setName(data.name);
                setConfigList(data.configs.map(conf => ({
                    ...conf,
                    is_new: false, is_saved: true
                })))
                setLoading(false)
            },
            function failure(err) {
                console.error(err)
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    const checkForUnSavedConfigs = () => {
        return configList.some(_d => (_d.is_saved === false));
    }

    const updateOrderTimeSlot = () => {
        const un_saved = checkForUnSavedConfigs();
        if (un_saved) {
            errorToaster("Please Save un-saved Configs first")
            return
        }
        if (name.length < 1) {
            errorToaster("Please Enter Order Time Slot Name")
            return
        }
        const _params = {
            name,
        };
        setLoading(true)
        const { match: { params } } = props;
        ProductOrderTimeSlotModel.getInstance().updateOrderTimeSlot(params.id, _params,
            function success() {
                successNotification("Order Time Slot Updated Successfully");
                setTimeout(() => {
                    setLoading(false);
                    window.location.href = "/order-time-slot"
                }, 2000);
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    // Create a function that will create bulk configs
    const addConfigObject = () => {
        let list = [...configList]
        list.push({ ...DataJson.editConfigObject });
        setConfigList(list);
    }

    const validateConfig = (data) => {
        if (!data.start_time || !data.end_time) return false;
        else return true;
    }

    const saveConfig = (index, params) => {
        if (!validateConfig(params)) {
            errorToaster("Please enter correct config");
            return;
        }
        if (params.is_new) addConfigAPI(index, params);
        else updateConfigAPI(index, params);
    }

    const addConfigAPI = (index, _params) => {
        setLoading(true);
        const { match: { params } } = props;
        _params = { ..._params, product_order_time_slot_id: params.id }
        ProductOrderTimeSlotModel.getInstance().addOrderTimeSlotConfig(_params,
            function success(data) {
                let val = [...configList];
                val[index].id = data.id;
                val[index].is_saved = true;
                val[index].is_new = false;
                setConfigList(val);
                setLoading(false);
            },
            function failure(err) {
                errorNotification(JSON.stringify(err))
                setLoading(false);
            }
        )
    }

    const updateConfigAPI = (index, params) => {
        setLoading(true);
        ProductOrderTimeSlotModel.getInstance().updateOrderTimeSlotConfig(params.id, params,
            function success(data) {
                let val = [...configList];
                val[index].is_saved = true;
                setConfigList(val);
                setLoading(false);
            },
            function failure(err) {
                console.error(err)
                errorNotification(JSON.stringify(err))
                setLoading(false);
            }
        )
    }

    const deleteConfigAPI = (index, params) => {
        if (params.is_new) {
            let val = [...configList];
            val.splice(index, 1);
            setConfigList(val);
            return
        } else {
            setLoading(true);
            ProductOrderTimeSlotModel.getInstance().removeOrderTimeSlotConfig(params.id,
                function success(data) {
                    let val = [...configList];
                    val.splice(index, 1);
                    setConfigList(val);
                    setLoading(false);
                },
                function failure(err) {
                    errorNotification(JSON.stringify(err))
                    setLoading(false);
                }
            )
        }
    }

    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                <Spin spinning={loading} >

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                <div className="kt-portlet__head kt-portlet__head--lg">
                                    <DetailPageHeaderWithButton
                                        title={"Edit Order Time Slot"}
                                        buttonOneIcon={"la la-arrow-left"}
                                        buttonOneCallBack={() => {
                                            props.history.goBack();
                                        }}
                                        buttonTwoIcon={"la la-check"}
                                        buttonTwoCallBack={() => updateOrderTimeSlot()}
                                    />
                                </div>

                                <div className="kt-portlet__body">
                                    <form className="kt-form" id="kt_form">
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-8">
                                                <div className="kt-section kt-section--first">
                                                    <div className="kt-section__body">

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Name <b>*</b></label>
                                                            <div className="col-9">
                                                                <Input maxLength={100} value={name} onChange={(event) => setName(event.target.value)} className="form-control" type="text" />
                                                                <span className="form-text text-muted"><p style={{}}>Please Enter Order Time Slot Name</p></span>
                                                            </div>
                                                        </div>

                                                        {
                                                            configList.map((config, i) => (
                                                                <div key={i} className="form-group row">
                                                                    <label className="col-3 col-form-label">Config # {i + 1} </label>
                                                                    <div className="col-3">
                                                                        <TimePicker
                                                                            id="1"
                                                                            minuteStep={1}
                                                                            className="form-control"
                                                                            format="HH:mm"
                                                                            value={moment(config.start_time, "HH:mm")}
                                                                            style={{ padding: "0px", border: "0px solid black" }}
                                                                            allowClear={false}
                                                                            onChange={(value) => {
                                                                                let val = [...configList];
                                                                                val[i].start_time = moment(value).format("HH:mm");
                                                                                val[i].is_saved = false;
                                                                                setConfigList(val);
                                                                            }} />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Select start time</p></span>
                                                                    </div>

                                                                    <div className="col-3">
                                                                        <TimePicker
                                                                            id="2"
                                                                            minuteStep={1}
                                                                            className="form-control"
                                                                            format="HH:mm"
                                                                            value={moment(config.end_time, "HH:mm")}
                                                                            style={{ padding: "0px", border: "0px solid black" }}
                                                                            allowClear={false}
                                                                            onChange={(value) => {
                                                                                let val = [...configList];
                                                                                val[i].end_time = moment(value).format("HH:mm");
                                                                                val[i].is_saved = false;
                                                                                setConfigList(val);
                                                                            }} />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Select end time</p></span>
                                                                    </div>

                                                                    <div className="col-2">
                                                                        <Select
                                                                            showSearch
                                                                            value={config.day}
                                                                            onChange={(day) => {
                                                                                let val = [...configList];
                                                                                val[i].day = day;
                                                                                val[i].is_saved = false;
                                                                                setConfigList(val);
                                                                            }}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {DataJson.dayArray.map((day) => (
                                                                                <Option key={day.value} value={day.value} >{day.name}</Option>
                                                                            ))}
                                                                        </Select>

                                                                        <span className="form-text text-muted"><p style={{}}>Please Select day</p></span>
                                                                    </div>
                                                                    {
                                                                        !config.is_saved &&
                                                                        <Popconfirm
                                                                            title="Are you sure you want to Save this Config?"
                                                                            icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
                                                                            onConfirm={() => saveConfig(i, config)}
                                                                        >
                                                                            <Icon style={{ fontSize: 20, paddingTop: 5, paddingLeft: 5 }} type="save" />
                                                                        </Popconfirm>
                                                                    }
                                                                    <Popconfirm
                                                                        title="Are you sure you want to delete this Config? This Action is ir-reversable!"
                                                                        icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
                                                                        onConfirm={() => deleteConfigAPI(i, config)}
                                                                    >
                                                                        <Icon style={{ fontSize: 20, paddingTop: 5, paddingLeft: 5 }} type="delete" />
                                                                    </Popconfirm>
                                                                </div>
                                                            ))
                                                        }



                                                        <div className="form-group row">
                                                            {/* <label className="col-3 col-form-label">End Date <b>*</b></label> */}
                                                            <div className="col-9">
                                                                <div className="btn-group">
                                                                    <button onClick={() => addConfigObject()} type="button" className="btn btn-brand">
                                                                        <i className="la la-plus"></i>
                                                                        <span className="kt-hidden-mobile">Add Config</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div >

    )
}