import React from "react";
import { Link } from "react-router-dom";
import { Icon, Popconfirm, Switch, Select } from "antd";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";

import {
  infoNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";

import UserModel from "../../Models/UserModel";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      // data:[
      // 	{"firstName":"twist","lastName":"donkey","age":5,"visits":7,"progress":9,"status":"complicated"},
      // 	{"firstName":"asdasd","lastName":"donkey","age":5,"visits":7,"progress":9,"status":"complicated"},
      // 	{"firstName":"fdg","lastName":"donkey","age":5,"visits":7,"progress":9,"status":"complicated"},
      // 	{"firstName":"werwe","lastName":"donkey","age":5,"visits":7,"progress":9,"status":"complicated"},
      // 	{"firstName":"xvbcmnc","lastName":"donkey","age":5,"visits":7,"progress":9,"status":"complicated"},
      // 	{"firstName":"Muhammad ","lastName":"donkey","age":5,"visits":7,"progress":9,"status":"complicated"},
      // 	{"firstName":"ali","lastName":"donkey","age":5,"visits":7,"progress":9,"status":"complicated"},
      // 	{"firstName":"naveen","lastName":"donkey","age":5,"visits":7,"progress":9,"status":"complicated"},
      // 	{"firstName":"test","lastName":"donkey","age":5,"visits":7,"progress":9,"status":"complicated"},
      // 	{"firstName":"moeed","lastName":"donkey","age":5,"visits":7,"progress":9,"status":"complicated"},
      // 	{"firstName":"zeeshan","lastName":"donkey","age":5,"visits":7,"progress":9,"status":"complicated"},
      // 	{"firstName":"maaz","lastName":"donkey","age":5,"visits":7,"progress":9,"status":"complicated"},
      // ]
      data: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    const it = this;
    UserModel.getInstance().getPayment(
      function success(data) {
        it.setState({
          data: UserModel.getInstance().PaymentList,
        });
        // console.log('view payment', data);
      },
      function Failure(error) {
        console.error("this is error", error);
        errorToaster("Request information not found");
      }
    );
  }

  removePayment(payment_id) {
    const it = this;
    UserModel.getInstance().removePayment(
      payment_id,
      function success() {
        it.setState({
          data: UserModel.getInstance().PaymentList,
        });
        infoNotification("Payment-mode Deleted");
      },
      function failure(msg) {
        errorNotification(msg);
      }
    );
  }

  paymentActivation(paymentId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = is_active === true ? false : true;
    let params = {};
    params.is_active = activation;
    UserModel.getInstance().paymentActivation(
      paymentId,
      params,
      function success() {
        it.setState({ data: UserModel.getInstance().PaymentList });
        it.setState({ loading: false });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            {gridHeaderWithButton(
              "Payment Modes",
              "la la-plus",
              "Add New Payment Mode",
              "payment/add"
            )}
          </div>
          <div className="kt-portlet__body">
            {/* <!--begin: Datatable --> */}
            {/* <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
										<thead>
											<tr>
												<th>Record ID</th>
												<th>Order ID</th>
												<th>Country</th>
												<th>Ship City</th>
												<th>Company Agent</th>
												<th>Ship Date</th>
												<th>Status</th>
												<th>Type</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tfoot>
											<tr>
												<th>Record ID</th>
												<th>Order ID</th>
												<th>Country</th>
												<th>Ship City</th>
												<th>Company Agent</th>
												<th>Ship Date</th>
												<th>Status</th>
												<th>Type</th>
												<th>Actions</th>
											</tr>
										</tfoot>
									</table> */}

            {/* <!--end: Datatable --> */}

            {/* react-table */}
            <ReactTable
              data={data}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                // {
                // Header: "Name",
                // columns: [
                {
                  Header: <p style={tableHeaderStyles()}>Icon</p>,
                  id: "logo",
                  sortable: false,
                  // accessor: d => d.icon,
                  accessor: (d) => (
                    <img
                      src={d.logo}
                      style={{ height: 50, width: 50 }}
                      alt="logo"
                    />
                  ),
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["logo"] }),
                  filterable: false,
                },
                {
                  Header: <p style={tableHeaderStyles()}>Name</p>,
                  id: "name",
                  accessor: (d) => d.name,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["name"] }),
                  filterAll: true,
                },
                {
                  Header: <p style={tableHeaderStyles()}>Arabic Name</p>,
                  id: "arabic_name",
                  accessor: (d) => d.arabic_name,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["arabic_name"] }),
                  filterAll: true,
                },
                {
                  Header: <p style={tableHeaderStyles()}>Status</p>,
                  id: "is_active",
                  accessor: "is_active",
                  Cell: ({ value }) =>
                    value ? (
                      <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                        Active
                      </span>
                    ) : (
                      <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                        De-Activate
                      </span>
                    ),
                  // filterMethod: (filter, rows) =>
                  // 	matchSorter(rows, filter.value, { keys: ["is_active"] }),
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "true") {
                      return row[filter.id] === true;
                    }
                    return row[filter.id] === false;
                  },
                  Filter: ({ filter, onChange }) => (
                    <Select
                      onChange={(value) => onChange(value)}
                      style={{ width: "100%" }}
                      defaultValue="all"
                    >
                      <Option value="all">Show All</Option>
                      <Option value="true">Active</Option>
                      <Option value="false">De-Activate</Option>
                    </Select>
                  ),
                  // filterAll: true
                },
                {
                  Header: <p style={tableHeaderStyles()}>Action</p>,
                  id: "actions",
                  width: 150,
                  accessor: (d) => (
                    <div>
                      <Link
                        to={`payment/edit/${d.id}`}
                        style={{ marginRight: 20 }}
                      >
                        <Icon
                          style={{ color: "#5D78FF", fontSize: 16 }}
                          type="edit"
                        />
                      </Link>
                      <Popconfirm
                        title="Are you sure you want to delete this payment mode?"
                        icon={
                          <Icon
                            type="question-circle-o"
                            style={{ color: "red" }}
                          />
                        }
                        onConfirm={() => this.removePayment(d.id)}
                      >
                        <Icon
                          style={{ color: "#5D78FF", fontSize: 16 }}
                          type="delete"
                        />
                      </Popconfirm>
                      <Popconfirm
                        // style={{}}
                        title={
                          d.is_active
                            ? "Are you sure you want to DEACTIVATE this Payment-Mode"
                            : "Are you sure you want to ACTIVATE this Payment-Mode"
                        }
                        icon={
                          <Icon
                            type="question-circle-o"
                            style={{ color: "red" }}
                          />
                        }
                        onConfirm={() =>
                          this.paymentActivation(d.id, d.is_active)
                        }
                      >
                        <Switch
                          style={{
                            backgroundColor: d.is_active
                              ? "#5d78ff"
                              : "#fd397a",
                            marginLeft: 15,
                          }}
                          checked={d.is_active}
                          onChange={(val) => console.log(val)}
                        />
                      </Popconfirm>
                    </div>
                  ),
                  filterable: false,
                },
                // ]
                // },
                // {
                // Header: "Info",
                // columns: [
                // {
                // Header: "Gender",
                // accessor: "age"
                // },
                // {
                // Header: "Over 21",
                // accessor: "age",
                // id: "over",
                // Cell: ({ value }) => (value >= 21 ? "Yes" : "No"),
                // filterMethod: (filter, row) => {
                // 	if (filter.value === "all") {
                // 	return true;
                // 	}
                // 	if (filter.value === "true") {
                // 	return row[filter.id] >= 21;
                // 	}
                // 	return row[filter.id] < 21;
                // },
                // Filter: ({ filter, onChange }) =>
                // 	<select
                // 	onChange={event => onChange(event.target.value)}
                // 	style={{ width: "100%" }}
                // 	value={filter ? filter.value : "all"}
                // 	>
                // 	<option value="all">Show All</option>
                // 	<option value="true">Can Drink</option>
                // 	<option value="false">Can't Drink</option>
                // 	</select>
                // }
              ]}
              defaultPageSize={10}
              className="-striped -highlight"
            />
          </div>
        </div>
      </div>
    );
  }
}
