import React from 'react';

import Content from './Content';
import AddLandmark from './AddLandmark';
// import '../../assets/js/demo1/pages/crud/datatables/search-options/column-search.js';


export default class Landmark extends React.Component {




    render() {

        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <Content />
            </div>
        )
    }
}