import React from "react";
import { Icon, Spin, Popconfirm, Select, Switch, Tooltip } from "antd";
import { Link } from "react-router-dom";
import matchSorter from "match-sorter";

import ReactTable from "react-table";
import "react-table/react-table.css";
import ProgramModel from "../../Models/ProgramModel";
import { errorNotification, infoNotification } from "../Shared/Notifications";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      defaultProgram: null,
      loading: false,
      currentPage: 1,
      totalPage: 1,
      perPage: 20,
      filters: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getProgram();
  }

  getProgram() {
    const it = this;

    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });
    if (params["programs.is_active"] === "all") {
      delete params["programs.is_active"];
    }
    it.setState({ loading: true });
    ProgramModel.getInstance().searchProgram(
      params,
      function success(data) {
        it.setState({
          data: ProgramModel.getInstance().ProgramList,
          loading: false,
          totalPage: data.last_page,
          perPage: data.per_page,
        });
        it.state.data.forEach((program) => {
          if (program.is_default) {
            it.setState({
              defaultProgram: program.id,
            });
          }
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorNotification("Request information not found");
        console.log(error);
      }
    );
  }

  setDefaultProgram(programId) {
    const it = this;
    it.setState({ loading: true });
    ProgramModel.getInstance().setDefaultProgram(
      programId,
      function success() {
        it.setState({
          defaultProgram: programId,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  deleteProgram(programId) {
    const it = this;
    it.setState({ loading: true });
    ProgramModel.getInstance().removeProgram(
      programId,
      function success() {
        infoNotification("Program Deleted Successfully");
        it.setState({
          data: ProgramModel.getInstance().ProgramList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  programActivation(programId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = is_active === true ? false : true;
    let params = {};
    params.is_active = activation;
    ProgramModel.getInstance().programActivation(
      programId,
      params,
      function success() {
        it.setState({
          data: ProgramModel.getInstance().ProgramList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getProgram();
  }, 1000);

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={this.state.loading}>
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg">
              {gridHeaderWithButton(
                "Programs",
                "la la-plus",
                "Add Program",
                "/program/add"
              )}
            </div>
            <div className="kt-portlet__body">
              {/* <div className="form-group row">
                                <label className="col-3 col-form-label" htmlFor="marchantGroup">Default Program</label>
                                <div className="col-9">

                                    <Select
                                        showSearch
                                        style={{ margin: '10px 0px 10px 0px', width: '35%' }}
                                        onChange={(partner) => this.setDefaultProgram(partner)}
                                        value={this.state.defaultProgram}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {data.map((partner) => (
                                            <Option key={partner.id} value={partner.id} >{partner.name}</Option>
                                        ))}
                                    </Select>

                                </div>
                            </div> */}

              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={20}
                // showPageJump={false}
                showPageSizeOptions={false}
                manual
                page={this.state.currentPage - 1}
                pages={this.state.totalPage}
                filtered={this.state.filters}
                // onFetchData={(state) => {
                //     // console.log(state.filtered)
                //     if (this.state.currentPage !== state.page + 1) {
                //         this.setState({ currentPage: state.page + 1 })
                //         setTimeout(() => {
                //             this.getProgram()
                //         }, 50);
                //     }
                // }}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getProgram();
                  }, 50);
                }}
                onFilteredChange={(filters) => {
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                columns={[
                  // {
                  // Header: "Name",
                  // columns: [
                  {
                    Header: <p style={tableHeaderStyles()}>Name</p>,
                    id: "programs.name",
                    accessor: (d) => d.name,
                    style: { whiteSpace: "unset" },
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Arabic name</p>,
                    id: "programs.arabic_name",
                    accessor: (d) => d.arabic_name,
                    style: { whiteSpace: "unset" },
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["arabic_name"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Discounted Price</p>,
                    id: "programs.discounted_price",
                    accessor: (d) => d.discounted_price,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["discounted_price"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Estimated Saving</p>,
                    id: "programs.estimated_saving",
                    accessor: (d) => d.estimated_saving,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["estimated_saving"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Program Type</p>,
                    id: "program_types.name",
                    style: { whiteSpace: "unset" },
                    accessor: (d) => d.program_type_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["program_type_name"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Partner</p>,
                    id: "partners.name",
                    accessor: (d) =>
                      d.partners.map((partner, key) => (
                        <p
                          className="btn btn-label-info btn-pill"
                          style={{
                            float: "left",
                            margin: "2px",
                            padding: "5px 10px",
                            fontSize: "11px",
                          }}
                          key={key}
                        >
                          {partner.name}
                        </p>
                      )),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: [
                          (item) =>
                            item.partners.map(
                              (partner) => partner.props.children
                            ),
                        ],
                      }),

                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "programs.is_active",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "true") {
                        return row[filter.id] === true;
                      }
                      return row[filter.id] === false;
                    },
                    Filter: ({ filter, onChange }) => (
                      <Select
                        onChange={(value) => onChange(value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <Option value="all">Show All</Option>
                        <Option value="true">Active</Option>
                        <Option value="false">De-Activate</Option>
                      </Select>
                    ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 100,
                    accessor: (d) => (
                      <div>
                        {/* <Link
                                                    to={`program/associate/${d.id}`}
                                                    style={{ marginRight: 20 }}
                                                >
                                                    <Tooltip title="Associate" >
                                                        <Icon style={{ color: '#5D78FF', fontSize: 16 }} type="api" />
                                                    </Tooltip>
                                                </Link> */}
                        <Link
                          to={`program/edit/${d.id}`}
                          style={{ marginRight: 10 }}
                        >
                          <Icon
                            style={{ color: "#5D78FF", fontSize: 16 }}
                            type="edit"
                          />
                        </Link>
                        {/* <Popconfirm
                                                    title="Are you sure you want to delete this program?"
                                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                    onConfirm={() => this.deleteProgram(d.id)}
                                                >
                                                    <Icon style={{ color: '#5D78FF', fontSize: 16 }} type="delete" />
                                                </Popconfirm> */}
                        <Popconfirm
                          title={
                            d.is_active
                              ? "Are you sure you want to DEACTIVATE this program"
                              : "Are you sure you want to ACTIVATE this program"
                          }
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.programActivation(d.id, d.is_active)
                          }
                        >
                          <Switch
                            style={{
                              backgroundColor: d.is_active
                                ? "#5d78ff"
                                : "#fd397a",
                              marginLeft: 15,
                            }}
                            checked={d.is_active}
                            onChange={(val) => console.log(val)}
                          />
                        </Popconfirm>
                      </div>
                    ),
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
