import React from 'react';
import {
    Spin,
    Select,
    DatePicker,
    Icon,
    Button
} from 'antd';
import moment from 'moment';
import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { errorNotification, infoToaster, errorToaster } from '../Shared/Notifications';
import downloadCSV from '../Shared/CsvDownloader'
import DialogModal from '../Shared/Model';
import ProgramModel from '../../Models/ProgramModel';
import MerchantModel from '../../Models/MerchantModel';
import SharedModel from '../../Models/SharedModel';
import UserModel from '../../Models/UserModel';


const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

export default class RedemptionReport extends React.Component {

    constructor() {
        super();
        this.state = {
            data: [],
            loading: false,
            programList: [],
            merchantList: [],
            startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            program: 'all',
            merchant: 'all',
            currentPage: 1,
            totalPage: 0,
            filters: [],
            orderDetailVisible : false,
            orderDetail: undefined,
        }
    }

    componentWillMount(){
        const role_id = localStorage.getItem("user_role")
        switch (Number(role_id)) {
            case 1:
                break;
             case 5:
                 window.location.href = '/order-report'
                 break;
             default:
                window.location.href = '/login'
                break;
        }
     }

    async componentDidMount() {
        const it = this;
        it.setState({ loading: true })

        await ProgramModel.getInstance().getProgram(null,
            function success() {
                it.setState({
                    programList: ProgramModel.getInstance().ProgramList,
                })
            },
            function failure(error) {
                console.log(error)
            }
        )
        this.getMerchantList(null);
        this.getRedemptionReport();

    }

    getMerchantList(query) {
        let it = this;

        let params = query ? { query } : null;
        MerchantModel.getInstance().getMerchant(params,
            function success(data) {
                it.setState({
                    merchantList: data.data
                })
            },
            function failure(error) {
                console.log(error)
            }
        )
    }

    getRedemptionReport() {
        let it = this;
        let { startDate, endDate, merchant, program, currentPage, filters } = this.state;
        let params = {
            from: startDate,
            to: endDate,
            offset: currentPage,
            type: 'redemption'
        }

        filters.forEach(filter => {
            params[filter.id] = filter.value.toLowerCase()
        });
        if (merchant !== 'all') {
            params.merchant = merchant
        }
        if (program !== 'all') {
            params.program = program
        }
        if (params['users.mobile']) {
            params['users.mobile'] = params['users.mobile'].replace('+', '')
        }
        this.setState({ loading: true })
        SharedModel.getInstance().searchReport(params,
            function success(data) {
                console.log('data: ', data)
                it.setState({
                    data: data.data,
                    loading: false,
                    totalPage: data.last_page,
                })
                if (it.state.data.length < 1) {
                    infoToaster('No Data Found')
                }
            },
            function failure(error) {
                it.setState({ loading: false, data: [] })
                errorNotification("Request information not found")
                console.log(error)
            }
        )
    }



    exportData() {
        let it = this;
        let { startDate, endDate, merchant, program, filters } = this.state;
        let params = {
            from: startDate,
            to: endDate,
            type: 'redemption'
        }

        filters.forEach(filter => {
            params[filter.id] = filter.value.toLowerCase()
        });
        if (merchant !== 'all') {
            params.merchant = merchant
        }
        if (program !== 'all') {
            params.program = program
        }
        if (params['users.mobile']) {
            params['users.mobile'] = params['users.mobile'].replace('+', '')
        }
        infoToaster('Please Wait! File will be Downloaded shortly');
        // it.setState({ loading: true });
        SharedModel.getInstance().downloadReport(params,
            function success(data) {
                it.setState({
                    loading: false,

                })
                infoToaster(data)
                // it.exportCSV(data)                
            },
            function failure(error) {
                it.setState({ loading: false })
                errorNotification(error)
                console.log(error)
            }
        )
    }



    getData() {
        this.getRedemptionReport();
    }

    disabledDate(current) {
        // Can not select days after today
        return current > moment().endOf('day');
    }

    debounce(callback, wait) {
        let timeout;
        return (...args) => {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => callback.apply(context, args), wait);
        };
    }

    searchQueryMerchant = this.debounce(function (value, it) {
        it.getMerchantList(value);
    }, 1000);

    exportCSV(data) {
        let { startDate, endDate } = this.state;
        let newArr = []
        data.forEach(log => {
            let newObj = {};
            newObj['Customer Name'] = log.user_name;
            newObj['Customer Email'] = log.user_email;
            newObj['Redeem Date'] = moment(log.created_at).format('DD/MMM/YYYY - hh:mm A');
            newObj['App Code'] = log.app_name;
            newObj['Redeem Code'] = log.redeem_code;
            newObj['Merchant'] = log.merchant_name;
            newObj['Outlet'] = log.outlet_name;
            newObj['Partners'] = log.partners.map(part => { return part.name });
            newObj['Program'] = log.program_name;
            newObj['Offer'] = log.offer_name;
            newObj['Category'] = log.category_name;
            newObj['Estimated Saving'] = log.estimated_savings;
            newArr.push(newObj)
        });
        downloadCSV({ filename: 'Redemption Logs from ' + startDate + ' to ' + endDate + '.csv' }, newArr)
    }

    debounce(callback, wait) {
        let timeout;
        return (...args) => {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => callback.apply(context, args), wait);
        };
    }

    searchQuery = this.debounce(function (it) {
        it.getRedemptionReport()
    }, 1000);

    showOrderDetailModal(order_id) {
        const it = this;
        this.setState({
            loading: true,
        })

        UserModel.getInstance().getOrderDetail(order_id,
            function success(orderDetail) {    
                it.setState({
                    orderDetail,
                    loading: false,
                    orderDetailVisible : true,
                })
            },
            function failure(error) {
                errorToaster("Order doesn't exists")
                it.setState({
                    loading: false,
                })
                console.log(error)

            }
        )
    };

    handleOkCancel(){
        this.setState({
			orderDetailVisible : false,
		});
    }



    render() {
        let { data, programList, merchantList } = this.state;
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                    {/* <Spin spinning={false}> */}
                    <Spin spinning={this.state.loading}>
                        <div className="kt-portlet kt-portlet--mobile">
                            <div className="kt-portlet__head kt-portlet__head--lg">
                                <div className="kt-portlet__head-label">
                                    <span className="kt-portlet__head-icon">
                                        <i className="kt-font-brand flaticon2-line-chart"></i>
                                    </span>
                                    <h3 className="kt-portlet__head-title">
                                        Redemption Report
										</h3>
                                </div>
                                {/* <div className="kt-portlet__head-toolbar">
                                    <div className="kt-portlet__head-wrapper">
                                        <div className="kt-portlet__head-actions">
                                            <a href="/program/add" className="btn btn-brand btn-elevate btn-icon-sm">
                                                <i className="la la-plus"></i>
                                                Add New Program
												</a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="kt-portlet__body">

                                <div className="form-group row">
                                    <label className="col-3 col-form-label" htmlFor="marchantGroup">Programs</label>
                                    <div className="col-9">

                                        <Select
                                            showSearch
                                            // mode="multiple"
                                            style={{ margin: '10px 0px 10px 0px', width: '35%' }}
                                            onChange={(program) => this.setState({ program })}
                                            defaultValue={'all'}
                                            // value={this.state.defaultProgram}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value={'all'} >All</Option>

                                            {programList.map((program) => (
                                                <Option key={program.id} value={program.id} >{program.name}</Option>
                                            ))}
                                        </Select>

                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-form-label" htmlFor="marchantGroup">Merchants</label>
                                    <div className="col-9">

                                        <Select
                                            showSearch
                                            // mode="multiple"
                                            style={{ margin: '10px 0px 10px 0px', width: '35%' }}
                                            onChange={(merchant) => this.setState({ merchant })}
                                            defaultValue={'all'}
                                            notFoundContent={
                                                // <Tooltip title="Add Type">
                                                //     <Icon onClick={() => this.appendType()} disabled={false} type="plus-circle" theme="filled" style={{ color: '#3758ff', fontSize: '20px' }} />
                                                // </Tooltip>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                    <p style={{ fontSize: 14 }}>Please Search Merchant by Name</p>
                                                </div>
                                            }
                                            onSearch={(val) => this.searchQueryMerchant(val, this)}
                                            // value={this.state.defaultProgram}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value={'all'} >All</Option>

                                            {merchantList.map((merchant) => (
                                                <Option key={merchant.id} value={merchant.id} >{merchant.name}</Option>
                                            ))}
                                        </Select>
                                        {/* <span className="form-text text-muted">Please choose Partner</span> */}

                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-form-label">Start Date ~ End Date <b>*</b></label>
                                    <div className="col-9">
                                        <RangePicker
                                            disabledDate={this.disabledDate}
                                            className="form-control"
                                            defaultValue={[moment().subtract(1, 'months'), moment()]}
                                            style={{ padding: "0px", border: '0px solid black', width: '35%' }}
                                            showTime={false}
                                            onChange={(value, string) => this.setState({ startDate: string[0], endDate: string[1] })}
                                            format="YYYY-MM-DD"
                                        />
                                    </div>
                                </div>

                                <div style={{ marginLeft: '-10px' }} className="col-3 col-form-label">
                                    <div className="btn-group">
                                        <button onClick={() => this.getData()} type="button" className="btn btn-brand">
                                            <i className="la la-search"></i>
                                            <span className="kt-hidden-mobile">Search</span>
                                        </button>
                                    </div>
                                    <div className="btn-group">
                                        <button disabled={data.length > 0 ? false : true} onClick={() => this.exportData()} style={{ marginLeft: '10px' }} type="button" className="btn btn-brand">
                                            <i className="la la-download"></i>
                                            <span className="kt-hidden-mobile">Export</span>
                                        </button>
                                    </div>
                                </div>

                                {/* react-table */}
                                {
                                    // data.length > 0 &&
                                    <ReactTable
                                        data={data}
                                        filterable
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value
                                        }
                                        pageSize={20}
                                        // showPageJump={false}
                                        showPageSizeOptions={false}
                                        manual
                                        page={this.state.currentPage - 1}
                                        pages={this.state.totalPage}
                                        onPageChange={(pageIndex) => {
                                            this.setState({ currentPage: pageIndex + 1 })
                                            setTimeout(() => {
                                                this.getRedemptionReport()
                                            }, 50);
                                        }}
                                        onFilteredChange={(filters) => {
                                            console.log('filters: ', filters)
                                            this.setState({ currentPage: 1, filters })
                                            this.searchQuery(this)
                                        }}

                                        columns={[
                                            // {
                                            // Header: "Name",
                                            // columns: [
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Customer Name</p>,
                                                id: "users.full_name",
                                                accessor: d => d.user_name,
                                                style: { whiteSpace: 'unset' },
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["user_name"] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Email</p>,
                                                id: "users.email",
                                                accessor: d => d.user_email,
                                                style: { whiteSpace: 'unset' },
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["user_email"] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Contact Number</p>,
                                                id: "users.mobile",
                                                accessor: d => d.user_mobile,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["user_mobile"] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Date of Redemption</p>,
                                                id: "created_at",
                                                accessor: d => moment(d.created_at).format('DD/MMM/YYYY - hh:mm A'),
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["created_at"] }),
                                                filterAll: true,
                                                filterable: false,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >App Name</p>,
                                                id: "apps.name",
                                                accessor: d => d.app_name,
                                                // accessor: d => d.partners.map((partner) => partner.name),
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ['app_name'] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Redemption Code</p>,
                                                id: "redeem_code",
                                                accessor: d => d.redeem_code,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["redeem_code"] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Merchant</p>,
                                                id: "merchants.name",
                                                accessor: d => d.merchant_name,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["merchant_name"] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Outlet</p>,
                                                id: "outlets.name",
                                                accessor: d => d.outlet_name,
                                                style: { whiteSpace: 'unset' },
                                                // accessor: d => d.program_type.name,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["outlet_name"] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Partner</p>,
                                                id: "partners.name",
                                                accessor: d => d.partners ? d.partners.map((partner, key) => <p className="btn btn-label-info btn-pill" style={{ float: 'left', margin: '2px', padding: '5px 10px', fontSize: '11px' }} key={key}>{partner.name}</p>) : null,
                                                // accessor: d => d.partners.map((partner) => partner.name),
                                                // accessor: d => d.partners.map((partner) => partner.name),
                                                filterMethod: (filter, rows) =>
                                                    // matchSorter(rows, filter.value, { keys: [item => item.partners] }),
                                                    matchSorter(rows, filter.value, { keys: [item => item.partners.map((partner) => partner.props.children)] }),

                                                filterAll: true,
                                                sortable: false,
                                                filterable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Program</p>,
                                                id: "programs.name",
                                                accessor: d => d.program_name,
                                                // accessor: d => d.partners.map((partner) => partner.name),
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["program_name"] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Offer</p>,
                                                id: "offers.name",
                                                accessor: d => d.offer_name,
                                                // accessor: d => d.partners.map((partner) => partner.name),
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["offer_name"] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Offer type</p>,
                                                id: "offer_type",
                                                accessor: d => d.offer_type,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["offer_type"] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Category</p>,
                                                id: "categories.name",
                                                accessor: d => d.category_name,
                                                // accessor: d => d.partners.map((partner) => partner.name),
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["category_name"] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Estimated Saving</p>,
                                                id: "estimated_savings",
                                                accessor: d => d.estimated_savings ? d.estimated_savings : 'N/A',
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ['estimated_savings'] }),
                                                filterAll: true,
                                                sortable: false
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Order Ref </p>,
                                                id: "actions",
                                                width: 90,
                                                accessor: d => (
                                                    <div>
                                                        <Button style={{ color: 'blue', fontSize: 20, marginRight: 20 }}  onClick={() => this.showOrderDetailModal(d.order_id)}>{d.order_id}</Button>

                                                    </div>
                                                ),
                                                filterable: false
                                            },

                                        ]
                                        }
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                    />
                                }

                            </div>
                            <DialogModal
                                visible={this.state.orderDetailVisible}
                                onOk ={() => this.handleOkCancel()}
                                onCancel = {() => this.handleOkCancel()}
                                orderDetail={this.state.orderDetail}
                            />
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }
}