/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
    Select,
    Input,
    Spin,
    Tabs
} from "antd";
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import ClassificationModel from "../../Models/ClassificationModel";

import {
    successNotification,
    errorNotification,
    errorToaster
} from "../Shared/Notifications";

import ColorPicker from "../Shared/ColorPicker";
import OutletClassifications from "./OutletClassifications";
import MATERIAL_ICONS from "../Shared/MaterialIcons.json";
import MATERIAL_COMMUNITY_ICONS from "../Shared/MaterialCommunityIcons.json";
import MESSAGES from "./Messages.json";


const { Option } = Select;
const { TabPane } = Tabs;

const IconLibraryList = [
    {
        value: "materialIcons",
    },
    {
        value: "materialCommunityIcons",
    }
]



export default function AddClassification(props) {
    const { match: { params } } = props;
    const
        [loading, setLoading] = useState(false),
        [outletClassificationList, setOutletClassificationList] = useState([]),
        [name, setName] = useState(""),
        [arabic_name, setArabic_name] = useState(""),
        [icon, setIcon] = useState(""),
        [icon_library, setIcon_library] = useState(undefined),
        [icon_color, setIcon_color] = useState("#000");



    useEffect(() => {
        getClassificationDetail();
        getOutletClassifications(params.id)
        return () => {

        }
    }, [])

    function getClassificationDetail() {
        setLoading(true)
        ClassificationModel.getInstance().getClassificationDetail(params.id,
            function success(data) {
                setName(data.name);
                setArabic_name(data.arabic_name);
                setIcon(data.icon);
                setIcon_library(data.icon_library);
                setIcon_color(data.icon_color);
                setLoading(false);
            }, function failure(error) {
                setLoading(false);
                console.error(error)
            }

        )
    }

    function getOutletClassifications(classification_id) {
        ClassificationModel.getInstance().getAllOutletClassifications({ classification_id },
            function success(data) {
                setOutletClassificationList(data)
            },
            function failure(err) {
                console.error(err)
            }

        )
    }

    const sendData = async () => {
        if (name.length < 3) {
            errorToaster(MESSAGES.INVALID_NAME)
            return
        }
        if (!icon) {
            errorToaster(MESSAGES.INVALID_ICON)
            return
        }
        if (!icon_library) {
            errorToaster(MESSAGES.INVALID_ICON_LIBRARY)
            return
        }
        if (!icon_color) {
            errorToaster(MESSAGES.INVALID_ICON_COLOR)
            return
        }
        const request_params = {
            name,
            arabic_name,
            icon,
            icon_library,
            icon_color
        };
        ClassificationModel.getInstance().updateClassification(params.id, request_params,
            function success() {
                successNotification(MESSAGES.CLASSIFICATION_SAVED_MESSAGE);
                setTimeout(() => {
                    setLoading(false);
                    window.location.href = "/classifications"
                }, 2000);
            },
            function failure(err) {
                errorNotification(JSON.stringify(err));
                setLoading(false)
            }
        )
    }

    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                <Spin spinning={loading} >

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                <div className="kt-portlet__head kt-portlet__head--lg">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">Edit Classification</h3>
                                    </div>
                                    <div className="kt-portlet__head-toolbar">
                                        <a href="/classifications" className="btn btn-clean kt-margin-r-10">
                                            <i className="la la-arrow-left"></i>
                                            <span className="kt-hidden-mobile">Back</span>
                                        </a>
                                        <div className="btn-group">
                                            <button onClick={() => sendData()} type="button" className="btn btn-brand">
                                                <i className="la la-check"></i>
                                                <span className="kt-hidden-mobile">Save</span>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                                <div className="kt-portlet__body">
                                    <form className="kt-form" id="kt_form">
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-8">

                                                <div className="kt-section kt-section--first">

                                                    <div className="kt-section__body">

                                                        <Tabs defaultActiveKey="1" >

                                                            <TabPane tab="Overview" key="1">
                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Name <b>*</b></label>
                                                                    <div className="col-9">
                                                                        <Input maxLength={255} value={name} onChange={(event) => setName(event.target.value)} className="form-control" type="text" />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Enter Classification Name</p></span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Arabic Name</label>
                                                                    <div className="col-9">
                                                                        <Input maxLength={255} value={arabic_name} onChange={(event) => setArabic_name(event.target.value)} className="form-control" type="text" />
                                                                        <span className="form-text text-muted"><p style={{}}>Please Enter Classification Arabic Name</p></span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Icon Library <b>*</b></label>
                                                                    <div className="col-9">

                                                                        <Select
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            onChange={(icon_library) => {
                                                                                setIcon(undefined)
                                                                                setIcon_library(icon_library)
                                                                            }}
                                                                            value={icon_library}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {IconLibraryList.map(lib => (
                                                                                <Option key={lib.value} value={lib.value} >{lib.value}</Option>
                                                                            ))}
                                                                        </Select>
                                                                        <span className="form-text text-muted"><p style={{}}>Please select a Library</p></span>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Icon Name <b>*</b></label>
                                                                    <div className="col-9">

                                                                        <Select
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            onChange={(icon) => setIcon(icon)}
                                                                            value={icon}
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {
                                                                                icon_library === "materialIcons" ?
                                                                                    MATERIAL_ICONS.map(_icon => (
                                                                                        <Option key={_icon} value={_icon} >{_icon}</Option>
                                                                                    ))
                                                                                    :
                                                                                    icon_library === "materialCommunityIcons" ?
                                                                                        MATERIAL_COMMUNITY_ICONS.map(_icon => (
                                                                                            <Option key={_icon} value={_icon} >{_icon}</Option>
                                                                                        ))
                                                                                        : null
                                                                            }
                                                                        </Select>
                                                                        <span className="form-text text-muted"><p style={{}}>Please select an Icon</p></span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-3 col-form-label">Choose Icon Color <b>*</b></label>
                                                                    <div className="col-6">
                                                                        <ColorPicker
                                                                            backColor={(color) => setIcon_color(color)}
                                                                            defaultColor={icon_color}
                                                                        />
                                                                    </div>
                                                                    {
                                                                        icon_library === "materialIcons" && icon ?
                                                                            <div className="col-3">
                                                                                <span style={{ color: icon_color, fontSize: 50 }} className="material-icons">{icon}</span>
                                                                            </div> :
                                                                            icon_library === "materialCommunityIcons" && icon ?
                                                                                <div className="col-3">
                                                                                    <MaterialCommunityIcons name={icon} size={50} color={icon_color} />
                                                                                </div> : null
                                                                    }
                                                                </div>

                                                            </TabPane>
                                                            <TabPane tab="Classifications" key="2">
                                                                <OutletClassifications
                                                                    is_outlet={false}
                                                                    refresh_data={() => getOutletClassifications(params.id)}
                                                                    classification_data={{
                                                                        id: params.id,
                                                                        name,
                                                                    }}
                                                                    outletClassificationList={outletClassificationList}
                                                                />
                                                            </TabPane>
                                                        </Tabs>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div >

    )
}