import React from "react";
import {
  Select,
  Spin,
  DatePicker,
  Tooltip,
  Icon,
  TimePicker,
  Checkbox,
} from "antd";

import _ from "lodash";
import moment from "moment-timezone";
import { DetailPageHeaderWithButton } from "styles/detailPageHeader";

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
import MultipleImages from "../Shared/MultipleImages";
import UserModel from "../../Models/UserModel";
import MerchantModel from "../../Models/MerchantModel";
import OfferModel from "../../Models/OfferModel";
import ProgramModel from "../../Models/ProgramModel";

const { Option } = Select;
const { RangePicker } = DatePicker;
const format = "h:mm a";

export default class EditOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offerId: null,
      loading: false,
      imageLoading: false,
      name: "",
      nameError: false,
      arb_name: null,
      arb_nameError: false,
      description: "",
      descriptionError: false,
      arabic_description: "",
      arabic_descriptionError: false,
      outlets: [],
      outletsError: false,
      offerType: "",
      offerTypeError: false,
      offerValue: "",
      offerValueError: false,
      imageList: [],
      imageError: false,
      availableTime: "",
      availableTimeError: false,
      startDate: "",
      endDate: "",
      specialStartDate: "",
      specialEndDate: "",
      dateError: false,
      specialDateError: false,
      averageRevenue: "",
      averageRevenueError: false,
      delivery: false,
      speciality: false,
      merchant: null,
      merchantError: false,
      exclusion: "",
      arabic_exclusion: "",
      offerCriteriaList: [],
      offerCriteria: [],
      selectedPrograms: [],
      selectedProgramsValidity: [],
      offer_end_date: moment().endOf("year").tz("Asia/Qatar"),
      offer_end_date_error: false,

      merchantList: [],
      outletList: [],
      offerTypeList: [],
      programList: [],
      program: [],
      programError: false,

      validity: [
        {
          day: "monday",
          status: "open",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "tuesday",
          status: "open",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "wednesday",
          status: "open",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "thursday",
          status: "open",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "friday",
          status: "open",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "saturday",
          status: "open",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "sunday",
          status: "open",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
      ],
      specialValidity: [
        {
          day: "monday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "tuesday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "wednesday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "thursday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "friday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "saturday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
        {
          day: "sunday",
          status: "closed",
          times: [{ start_time: "12:00 am", end_time: "11:59 pm" }],
        },
      ],
      validityCurrentDay: 0,
      specialCurrentDay: 0,
      weekDays: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      pgNum: props.match.params.page,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  async componentDidMount() {
    let it = this;
    const {
      match: { params },
    } = this.props;
    it.setState({
      loading: true,
      offerId: params.offerId,
    });
    await OfferModel.getInstance().getOfferCriteria(
      function success() {
        it.setState({
          offerCriteriaList: OfferModel.getInstance().OfferCriteriaList,
        });
      },
      function failure(error) {
        errorNotification(error);
      }
    );
    await OfferModel.getInstance().getOfferType(
      function success() {
        it.setState({
          offerTypeList: OfferModel.getInstance().OfferTypeList,
        });
      },
      function failure(error) {
        errorNotification(error);
        console.log(error);
      }
    );
    await ProgramModel.getInstance().getProgram(
      null,
      function success() {
        it.setState({
          programList: ProgramModel.getInstance().ProgramList,
        });
        it.getOfferDetail(params.offerId);
      },
      function failure(error) {
        errorNotification(error);
      }
    );
  }

  getOfferDetail(offerId) {
    let it = this;
    OfferModel.getInstance().getOfferDetail(
      offerId,
      function success(data) {
        it.setState({
          loading: false,
          name: data.name,
          arb_name: data.arabic_name ? data.arabic_name : null,
          description: data.description ? data.description : "",
          arabic_description: data.arabic_description
            ? data.arabic_description
            : "",
          offerType: data.type_id,
          offerValue: data.offer_value,
          availableTime: data.offer_count ? data.offer_count : "",
          averageRevenue: data.average_revenue ? data.average_revenue : "",
          // imageList: data.images ? data.images : [],
          validity: data.validity.days ? data.validity.days : it.state.validity,
          delivery: data.delivery,
          speciality: data.is_special,
          exclusion: data.exclusion ? data.exclusion : "",
          arabic_exclusion: data.arabic_exclusion ? data.arabic_exclusion : "",
          specialStartDate: data.is_special
            ? data.validity.start_date
              ? data.validity.start_date
              : null
            : null,
          specialEndDate: data.is_special
            ? data.validity.end_date
              ? data.validity.end_date
              : null
            : null,
          offer_end_date: moment(data.end_date).tz("Asia/Qatar"),
          offerCriteria: data.criteria
            ? data.criteria.map((criteria) => {
                return criteria.id;
              })
            : [],
          imageList: data.media
            ? data.media.map((img) => {
                if (img.uid) {
                  return img;
                } else
                  return {
                    uid: img,
                    url: img,
                  };
              })
            : [],
        });
        if (data.merchant.merchant_id) {
          it.getMerchants(data.merchant.merchant_name);
          it.setState({ merchant: data.merchant.merchant_id });
          it.getOutletById(data.merchant.merchant_id);
        }
        let { selectedPrograms, selectedProgramsValidity } = it.state;
        if (data.is_special && data.special_validity instanceof Array) {
          selectedProgramsValidity = data.special_validity;
          for (let i = 0; i < it.state.programList.length; i++) {
            for (let j = 0; j < selectedProgramsValidity.length; j++) {
              if (
                it.state.programList[i].id ==
                selectedProgramsValidity[j].program_id
              ) {
                selectedPrograms.push(it.state.programList[i]);
              }
            }
          }
        } else {
          if (data.programs) {
            data.programs.map((program) => {
              selectedPrograms.push(program);
              selectedProgramsValidity.push({
                program_id: program.id,
                start_date: program.start_date,
                end_date: program.end_date,
              });
            });
          }
        }
        it.setState({
          selectedPrograms: _.uniqBy(selectedPrograms, "id"),
          selectedProgramsValidity: _.uniqBy(
            selectedProgramsValidity,
            "program_id"
          ),
        });
        setTimeout(() => {}, 50);
        if (data.programs) {
          let programs = [];
          data.programs.map((program) => {
            programs.push(program.id);
          });
          it.setState({ program: programs });
        }

        let arr = [];
        data.merchant.outlets.map((outlet) => {
          arr.push(outlet.id);
          console.log(outlet);
        });
        it.setState({
          outlets: arr,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  getOutletById(merchant) {
    this.setState({
      loading: true,
      outlets: [],
      merchant: merchant,
    });
    let it = this;
    MerchantModel.getInstance().getMerchantOutlet(
      merchant,
      function success(data) {
        it.setState({
          outletList: data,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQueryMerchant = this.debounce(function (value, it) {
    it.getMerchants(value);
  }, 1000);

  getMerchants(value) {
    if (value.length > 0) {
      let it = this;
      let params = {
        query: encodeURIComponent(value),
      };
      this.setState({ loading: true });
      MerchantModel.getInstance().getMerchant(
        params,
        function success(data) {
          it.setState({
            loading: false,
            merchantList: data.data,
          });
        },
        function failure(error) {
          errorNotification(error);
          it.setState({ loading: false });
        }
      );
    }
  }

  getOutlets(merchant) {
    this.setState({
      loading: true,
      outlets: [],
      merchant: merchant.merchant,
    });
    let it = this;
    MerchantModel.getInstance().getMerchantOutlet(
      merchant.merchant,
      function success(data) {
        it.setState({
          outletList: data,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  sendImages(image) {
    const it = this;
    it.setState({ imageLoading: true });
    let arr = this.state.imageList;
    UserModel.getInstance().addMedia(
      image,
      function success(url) {
        let img = {
          uid: url,
          url: url,
        };
        arr.push(img);
        it.setState({
          imageList: arr,
          imageLoading: false,
        });
      },
      function failure() {
        it.setState({ imageLoading: false });
      }
    );
  }

  removeImages(image) {
    const it = this;
    it.setState({ imageLoading: true });
    let arr = this.state.imageList;
    let { url } = image;
    UserModel.getInstance().removeMedia(
      url,
      function success() {
        it.setState({ imageLoading: false });
        arr.forEach((img, key) => {
          if (img.uid === image.uid) {
            arr.splice(key, 1);
          }
        });
        it.setState({ imageList: arr });
      },
      function failure(error) {
        console.log(error);
        it.setState({ imageLoading: false });
      }
    );
  }

  disabledDate(current) {
    // Can not select days before today and today
    return current < moment().endOf("day");
  }

  disabledSpecialDate(date, startDate, endDate) {
    if (startDate.length < 1) {
      return date < moment().startOf("day");
    }
    startDate = moment(startDate, "YYYY-MM-DD");
    endDate = moment(endDate, "YYYY-MM-DD");
    return date < startDate.startOf("day") || date > endDate.endOf("day");
  }

  disabledHours(pickHour) {
    const allHours = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ];
    let disabledHours = [];
    allHours.map((hour) => {
      if (hour <= pickHour) {
        disabledHours.push(hour);
      }
    });
    this.setState({ disabledHours });
  }

  checkEndTime() {
    var start = this.state.validity[this.state.validityCurrentDay].start_time;
    var close = this.state.validity[this.state.validityCurrentDay].end_time;

    if (start >= close) {
      this.state.validity[this.state.validityCurrentDay].end_time = "11:59 pm";
      this.forceUpdate();
    }
  }

  addValidityTime() {
    let arr = this.state.validity[this.state.validityCurrentDay].times;
    let newTime = { start_time: "12:00 am", end_time: "11:59 pm" };
    arr.push(newTime);
    this.state.validity[this.state.validityCurrentDay].times = arr;
    this.forceUpdate();
  }

  addSpecialTime() {
    let arr = this.state.specialValidity[this.state.specialCurrentDay].times;
    let newTime = { start_time: "12:00 am", end_time: "11:59 pm" };
    arr.push(newTime);
    this.state.specialValidity[this.state.specialCurrentDay].times = arr;
    this.forceUpdate();
  }

  deleteValildityTime() {
    let arr = this.state.validity[this.state.validityCurrentDay].times;
    arr.splice(1, 1);
    this.state.validity[this.state.validityCurrentDay].times = arr;
    this.forceUpdate();
  }

  deleteSpecialTime() {
    let arr = this.state.specialValidity[this.state.specialCurrentDay].times;
    arr.splice(1, 1);
    this.state.specialValidity[this.state.specialCurrentDay].times = arr;
    this.forceUpdate();
  }

  sendData() {
    const {
      offerId,
      name,
      arb_name,
      description,
      arabic_description,
      program,
      merchant,
      outlets,
      exclusion,
      arabic_exclusion,
      offerCriteria,
      offerType,
      offerValue,
      availableTime,
      averageRevenue,
      imageList,
      validity,
      delivery,
      speciality,
      selectedProgramsValidity,
      offer_end_date,
    } = this.state;

    let it = this;
    const text = "Validation Error! Please check fields";
    this.setState({
      nameError: false,
      arb_nameError: false,
      priceError: false,
      descriptionError: false,
      arabic_descriptionError: false,
      merchantError: false,
      outletsError: false,
      offerTypeError: false,
      offerValueError: false,
      availableTimeError: false,
      averageRevenueError: false,
      imageError: false,
      programError: false,
      offer_end_date_error: false,
    });

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster(text);
      return;
    }
    // if (arb_name.length < 1) {
    //     this.setState({ arb_nameError: true });
    //     errorToaster(text);
    //     return;
    // }
    if (description.length < 1) {
      this.setState({ descriptionError: true });
      errorToaster(text);
      return;
    }
    // if (arabic_description.length < 1) {
    //     this.setState({ arabic_descriptionError: true });
    //     errorToaster(text);
    //     return;
    // }
    // if (program.length < 1) {
    //     this.setState({ programError: true });
    //     errorToaster(text);
    //     return;
    // }
    if (!offer_end_date) {
      this.setState({ offer_end_date_error: true });
      errorToaster(text);
      return;
    }
    if (!merchant) {
      this.setState({ merchantError: true });
      errorToaster(text);
      return;
    }
    if (outlets.length < 1) {
      this.setState({ outletsError: true });
      errorToaster(text);
      return;
    }
    if (!offerType) {
      this.setState({ offerTypeError: true });
      errorToaster(text);
      return;
    }
    if (offerValue.length < 1) {
      this.setState({ offerValueError: true });
      errorToaster(text);
      return;
    }
    // if (availableTime.length < 1) {
    //     this.setState({ availableTimeError: true });
    //     errorToaster(text);
    //     return;
    // }
    if (
      parseFloat(availableTime) === parseFloat(availableTime) &&
      parseFloat(availableTime) % 1 !== 0
    ) {
      this.setState({ availableTimeError: true });
      errorToaster(text);
      return;
    }
    // if (averageRevenue.length < 1) {
    //     this.setState({ averageRevenueError: true });
    //     errorToaster(text);
    //     return;
    // }
    // if (imageList.length < 1) {
    //     this.setState({ imageError: true });
    //     errorToaster(text);
    //     return;
    // }
    // if (speciality) {
    //     if (specialStartDate.length < 1 || specialEndDate.length < 1) {
    //         this.setState({ specialDateError: true });
    //         errorToaster(text);
    //         return;
    //     }
    // }
    let media = [];
    imageList.forEach((image) => {
      media.push(image.url);
    });

    let params = {
      name,
      description,
      arabic_name: arb_name,
      arabic_description,
      merchant_id: merchant,
      outlet_id: outlets,
      exclusion,
      arabic_exclusion,
      type_id: offerType,
      offer_value: offerValue,
      average_revenue: Number(averageRevenue),
      media,
      program_id: program,
      end_date: moment(offer_end_date)
        .endOf("day")
        .tz("Asia/Qatar")
        .format("YYYY-MM-DD hh:mm:ss"),
      validity: {
        days: validity,
      },
      is_delivery: delivery,
      is_special: speciality,
      offer_count: availableTime ? availableTime : null,
      criteria_id: offerCriteria,
    };
    if (speciality) {
      let varcheck = selectedProgramsValidity.map((val) => {
        return {
          program_id: val.program_id,
          start_date: moment(val.start_date).startOf("day").toISOString(),
          end_date: moment(val.end_date).endOf("day").toISOString(),
        };
      });
      params.special_validity = varcheck;
    }
    this.setState({ loading: true });
    OfferModel.getInstance().updateOffer(
      offerId,
      params,
      function success(data) {
        successNotification("Offer Updated Successfully");
        setTimeout(() => {
          it.props.history.goBack();
        }, 1000);
      },
      function failure(error) {
        errorNotification(error);
        console.log(error);
        it.setState({ loading: false });
      }
    );
  }

  getSpecialDate(program) {
    let date = [];
    this.state.selectedProgramsValidity.forEach((sp, i) => {
      if (program.id === sp.program_id) {
        date = [
          moment(
            this.state.selectedProgramsValidity[i].start_date,
            "YYYY-MM-DD"
          ),
          moment(this.state.selectedProgramsValidity[i].end_date, "YYYY-MM-DD"),
        ];
      }
    });
    return date;
  }

  render() {
    const {
      loading,
      nameError,
      arb_nameError,
      imageError,
      //   dateError,
      descriptionError,
      arabic_descriptionError,
      merchantError,
      outletsError,
      offerTypeError,
      offerValueError,
      availableTimeError,
      averageRevenueError,
      programError,
      offer_end_date,
      merchantList,
      outletList,
      offerTypeList,
      weekDays,
      offerCriteriaList,
      programList,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <Spin spinning={loading}>
          <div className="kt-content  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <DetailPageHeaderWithButton
                      title={"Edit Offer"}
                      buttonOneIcon={"la la-arrow-left"}
                      buttonOneCallBack={() => {
                        this.props.history.goBack();
                      }}
                      buttonTwoIcon={"la la-check"}
                      buttonTwoCallBack={() => this.sendData()}
                    />
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="70"
                                    value={this.state.name}
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter name in English
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Arabic Name
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="100"
                                    value={this.state.arb_name}
                                    onChange={(event) =>
                                      this.setState({
                                        arb_name: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: arb_nameError ? "red" : "",
                                      }}
                                    >
                                      Please enter name in Arabic
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Description <b>*</b>
                                </label>
                                <div className="col-9">
                                  <textarea
                                    maxLength="200"
                                    value={this.state.description}
                                    onChange={(event) =>
                                      this.setState({
                                        description: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  ></textarea>
                                  <span
                                    style={{
                                      color: descriptionError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: descriptionError ? "red" : "",
                                      }}
                                    >
                                      Please enter Description in English
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Arabic Description
                                </label>
                                <div className="col-9">
                                  <textarea
                                    maxLength="200"
                                    value={this.state.arabic_description}
                                    onChange={(event) =>
                                      this.setState({
                                        arabic_description: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  ></textarea>
                                  <span
                                    style={{
                                      color: arabic_descriptionError
                                        ? "red"
                                        : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: arabic_descriptionError
                                          ? "red"
                                          : "",
                                      }}
                                    >
                                      Please enter Description in Arabic
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Offer End Date <b>*</b>
                                </label>
                                <div className="col-9">
                                  <DatePicker
                                    onChange={(date, dateString) =>
                                      this.setState({
                                        offer_end_date: moment(dateString),
                                      })
                                    }
                                    allowClear={true}
                                    className="form-control"
                                    value={offer_end_date}
                                    style={{
                                      padding: "0px",
                                      border: "0px solid black",
                                    }}
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: this.state.offer_end_date_error
                                          ? "red"
                                          : "",
                                      }}
                                    >
                                      Please select offer end date
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Program <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    // placeholder="Select a merchant group"
                                    value={this.state.program}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(program) => {
                                      let {
                                        selectedProgramsValidity,
                                        selectedPrograms,
                                      } = this.state;
                                      let tempProgId = null;
                                      if (
                                        program.length > selectedPrograms.length
                                      ) {
                                        for (
                                          let i = 0;
                                          i < program.length;
                                          i++
                                        ) {
                                          if (
                                            !_.includes(
                                              selectedPrograms,
                                              program[i]
                                            )
                                          ) {
                                            tempProgId = program[i];
                                          }
                                        }
                                        for (
                                          let i = 0;
                                          i < programList.length;
                                          i++
                                        ) {
                                          if (
                                            programList[i].id === tempProgId
                                          ) {
                                            selectedPrograms.push(
                                              programList[i]
                                            );
                                            selectedProgramsValidity.push({
                                              program_id: programList[i].id,
                                              start_date:
                                                programList[i].start_date,
                                              end_date: programList[i].end_date,
                                            });
                                          }
                                        }
                                      } else {
                                        for (
                                          let i = 0;
                                          i < selectedPrograms.length;
                                          i++
                                        ) {
                                          if (
                                            !_.includes(
                                              program,
                                              selectedPrograms[i].id
                                            )
                                          ) {
                                            tempProgId = selectedPrograms[i].id;
                                          }
                                        }

                                        for (
                                          let i = 0;
                                          i < selectedPrograms.length;
                                          i++
                                        ) {
                                          if (
                                            selectedPrograms[i].id ===
                                            tempProgId
                                          ) {
                                            selectedPrograms.splice(i, 1);
                                            selectedProgramsValidity.splice(
                                              i,
                                              1
                                            );
                                          }
                                        }
                                      }
                                      this.setState({
                                        selectedPrograms,
                                        program,
                                        selectedProgramsValidity,
                                      });
                                    }}
                                  >
                                    {programList.map(
                                      (program) =>
                                        program.is_active && (
                                          <Option
                                            key={program.id}
                                            value={program.id}
                                          >
                                            {program.name}
                                          </Option>
                                        )
                                    )}
                                  </Select>
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: programError ? "red" : "",
                                      }}
                                    >
                                      Please Select Program(s)
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Merchant <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    // mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.merchant}
                                    notFoundContent={
                                      // <Tooltip title="Add Type">
                                      //     <Icon onClick={() => this.appendType()} disabled={false} type="plus-circle" theme="filled" style={{ color: '#3758ff', fontSize: '20px' }} />
                                      // </Tooltip>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Icon
                                          style={{
                                            fontSize: 16,
                                            marginRight: "10px",
                                            marginBottom: "5px",
                                          }}
                                          type="search"
                                        />
                                        <p style={{ fontSize: 14 }}>
                                          Please Search Merchant
                                        </p>
                                      </div>
                                    }
                                    // placeholder="Select a merchant group"
                                    onSearch={(val) =>
                                      this.searchQueryMerchant(val, this)
                                    }
                                    onChange={(merchant) =>
                                      this.getOutlets({ merchant })
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {merchantList.map((merchant) => (
                                      <Option
                                        key={merchant.id}
                                        value={merchant.id}
                                      >{`${merchant.name} ${
                                        merchant.is_active
                                          ? " (active)"
                                          : " (in-active)"
                                      }`}</Option>
                                    ))}
                                  </Select>
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: merchantError ? "red" : "",
                                      }}
                                    >
                                      Please Select Merchant
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Outlets <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    // placeholder="Select a merchant group"
                                    value={this.state.outlets}
                                    onChange={(outlets) => {
                                      this.setState({ outlets });
                                    }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {outletList.map((outlet) => (
                                      <Option
                                        key={outlet.id}
                                        value={outlet.id}
                                      >{`${outlet.name} ${
                                        outlet.is_active
                                          ? " (active)"
                                          : " (in-active)"
                                      }`}</Option>
                                    ))}
                                  </Select>
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: outletsError ? "red" : "",
                                      }}
                                    >
                                      Please Select Outlets
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Exclusion
                                </label>
                                <div className="col-9">
                                  <input
                                    value={this.state.exclusion}
                                    maxLength="100"
                                    onChange={(event) =>
                                      this.setState({
                                        exclusion: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    Please enter Exclusion in English
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Arabic Exclusion
                                </label>
                                <div className="col-9">
                                  <input
                                    value={this.state.arabic_exclusion}
                                    maxLength="100"
                                    onChange={(event) =>
                                      this.setState({
                                        arabic_exclusion: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    Please enter Exclusion in Arabic
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Offer Criteria
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.offerCriteria}
                                    onChange={(offerCriteria) =>
                                      this.setState({ offerCriteria })
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {offerCriteriaList.map((criteria) => (
                                      <Option
                                        key={criteria.id}
                                        value={criteria.id}
                                      >
                                        {criteria.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    Please Select Offer Criteria
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Offer Type <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    // mode="multiple"
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    // placeholder="Select a merchant group"
                                    value={this.state.offerType}
                                    onChange={(offerType) =>
                                      this.setState({ offerType })
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {offerTypeList.map((offerType) => (
                                      <Option
                                        key={offerType.id}
                                        value={offerType.id}
                                      >
                                        {offerType.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span
                                    style={{ color: nameError ? "red" : "" }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: offerTypeError ? "red" : "",
                                      }}
                                    >
                                      Please Select Offer Type
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Estimated Savings <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="200"
                                    value={this.state.offerValue}
                                    onChange={(event) =>
                                      this.setState({
                                        offerValue: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="text"
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: offerValueError ? "red" : "",
                                      }}
                                    >
                                      Please enter Offer value
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Redepmtion Count <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="20"
                                    value={this.state.availableTime}
                                    onChange={(event) =>
                                      this.setState({
                                        availableTime: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: availableTimeError ? "red" : "",
                                      }}
                                    >
                                      Please enter the number times a customer
                                      can avail
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Average Revenue
                                </label>
                                <div className="col-9">
                                  <input
                                    maxLength="20"
                                    value={this.state.averageRevenue}
                                    onChange={(event) =>
                                      this.setState({
                                        averageRevenue: event.target.value,
                                      })
                                    }
                                    className="form-control"
                                    type="number"
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    <p
                                      style={{
                                        color: averageRevenueError ? "red" : "",
                                      }}
                                    >
                                      Please enter average revenue for this
                                      offer
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Offer Images
                                </label>
                                <div className="col-9">
                                  <MultipleImages
                                    getImages={(image) =>
                                      this.sendImages(image)
                                    }
                                    removeImage={(image) =>
                                      this.removeImages(image)
                                    }
                                    loading={this.state.imageLoading}
                                    imageList={this.state.imageList}
                                    imageLimit={5}
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: imageError ? "red" : "" }}
                                    >
                                      Please Upload Offer Images
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="marchantGroup"
                                >
                                  Offer Hours
                                </label>
                                <div className="col-9">
                                  <div className="row">
                                    <Select
                                      style={{
                                        margin: "10px 0px 10px 0px",
                                        width: "30%",
                                        marginRight: "5%",
                                      }}
                                      value={this.state.validityCurrentDay}
                                      onChange={(value) =>
                                        this.setState({
                                          validityCurrentDay: value,
                                        })
                                      }
                                    >
                                      {weekDays.map((day, key) => (
                                        <Option key={key} value={key}>
                                          {day}
                                        </Option>
                                      ))}
                                    </Select>
                                    <Select
                                      style={{
                                        margin: "10px 0px 10px 0px",
                                        width: "30%",
                                        marginRight: "5%",
                                      }}
                                      value={
                                        this.state.validity[
                                          this.state.validityCurrentDay
                                        ].status
                                      }
                                      onChange={(value) => {
                                        this.state.validity[
                                          this.state.validityCurrentDay
                                        ].status = value;
                                        this.forceUpdate();
                                      }}
                                    >
                                      <Option value="open">Open</Option>
                                      <Option value="closed">Closed</Option>
                                    </Select>
                                    {this.state.validity[
                                      this.state.validityCurrentDay
                                    ].times.length === 1 ? (
                                      <Tooltip title="Add Time">
                                        <Icon
                                          onClick={() => this.addValidityTime()}
                                          style={{
                                            margin: "15px 0px 10px 0px",
                                            color: "#3758FF",
                                            fontSize: "20px",
                                          }}
                                          type="plus-circle"
                                          theme="filled"
                                        />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Delete Time">
                                        <Icon
                                          onClick={() =>
                                            this.deleteValildityTime()
                                          }
                                          style={{
                                            margin: "15px 0px 10px 0px",
                                            color: "#3758FF",
                                            fontSize: "20px",
                                          }}
                                          type="minus-circle"
                                          theme="filled"
                                        />
                                      </Tooltip>
                                    )}
                                  </div>
                                  {this.state.validity[
                                    this.state.validityCurrentDay
                                  ].times.map((time, key) => (
                                    <div key={key} className="row">
                                      <div
                                        style={{
                                          width: "30%",
                                          marginRight: "5%",
                                        }}
                                      >
                                        <TimePicker
                                          allowClear={false}
                                          label="Start Time"
                                          disabled={
                                            this.state.validity[
                                              this.state.validityCurrentDay
                                            ].status === "closed"
                                              ? true
                                              : false
                                          }
                                          value={moment(
                                            time.start_time,
                                            format
                                          )}
                                          use12Hours
                                          style={{
                                            margin: "10px 0px 10px 0px",
                                            width: "100%",
                                            marginRight: "5%",
                                          }}
                                          onChange={(value, string) => {
                                            this.state.validity[
                                              this.state.validityCurrentDay
                                            ].times[key].start_time = string;
                                            this.forceUpdate();
                                            this.disabledHours(
                                              moment(
                                                time.start_time,
                                                format
                                              ).hour()
                                            );
                                            this.checkEndTime();
                                          }}
                                          format={format}
                                        />
                                        {/* <span className="form-text text-muted">Start Time</span> */}
                                      </div>

                                      <div style={{ width: "30%" }}>
                                        <TimePicker
                                          allowClear={false}
                                          disabled={
                                            this.state.validity[
                                              this.state.validityCurrentDay
                                            ].status === "closed"
                                              ? true
                                              : false
                                          }
                                          disabledHours={() =>
                                            this.state.disabledHours
                                          }
                                          value={moment(time.end_time, format)}
                                          use12Hours
                                          style={{
                                            margin: "10px 0px 10px 0px",
                                            width: "100%",
                                          }}
                                          onChange={(value, string) => {
                                            this.state.validity[
                                              this.state.validityCurrentDay
                                            ].times[key].end_time = string;
                                            this.forceUpdate();
                                          }}
                                          format={format}
                                        />
                                        {/* <span className="form-text text-muted">End Time</span> */}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th>Day of Week</th>
                                      <th>Status</th>
                                      <th>Timing</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.validity.map((days, index) => (
                                      <tr key={index}>
                                        <td>{this.state.weekDays[index]}</td>
                                        <td>{days.status}</td>

                                        {days.status === "open" ? (
                                          <td>
                                            <div>
                                              {days.times.map((hour, j) => (
                                                <p key={j}>
                                                  {hour.start_time} -{" "}
                                                  {hour.end_time}
                                                </p>
                                              ))}
                                            </div>
                                          </td>
                                        ) : (
                                          <td></td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Delivery
                                </label>
                                <div className="col-9">
                                  <Checkbox
                                    checked={this.state.delivery}
                                    onChange={(event) =>
                                      this.setState({
                                        delivery: event.target.checked,
                                      })
                                    }
                                    className="form-control"
                                    style={{ border: "0px" }}
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    Please check if delivery is available
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Special
                                </label>
                                <div className="col-9">
                                  <Checkbox
                                    checked={this.state.speciality}
                                    onChange={(event) =>
                                      this.setState({
                                        speciality: event.target.checked,
                                      })
                                    }
                                    className="form-control"
                                    style={{ border: "0px" }}
                                  />
                                  <span
                                    style={{
                                      color: arb_nameError ? "red" : "",
                                    }}
                                    className="form-text text-muted"
                                  >
                                    Please check if offer is special
                                  </span>
                                </div>
                              </div>
                              {this.state.speciality && (
                                <div>
                                  {this.state.selectedPrograms.map(
                                    (program, index) => (
                                      <div
                                        key={program.id}
                                        className="form-group row"
                                      >
                                        <label className="col-3 col-form-label">
                                          {program.name}
                                        </label>
                                        <div className="col-9">
                                          <RangePicker
                                            disabledDate={(date) =>
                                              this.disabledSpecialDate(
                                                date,
                                                program.start_date,
                                                program.end_date
                                              )
                                            }
                                            allowClear={false}
                                            className="form-control"
                                            value={this.getSpecialDate(program)}
                                            // value={[moment(this.state.selectedProgramsValidity[index].start_date, "YYYY-MM-DD"), moment(this.state.selectedProgramsValidity[index].end_date, "YYYY-MM-DD")]}
                                            style={{
                                              padding: "0px",
                                              border: "0px solid black",
                                            }}
                                            showTime={false}
                                            onChange={(value, string) => {
                                              this.state.selectedProgramsValidity.forEach(
                                                (sp, i) => {
                                                  if (
                                                    program.id === sp.program_id
                                                  ) {
                                                    this.state.selectedProgramsValidity[
                                                      i
                                                    ].start_date = string[0];
                                                    this.state.selectedProgramsValidity[
                                                      i
                                                    ].end_date = string[1];
                                                    this.forceUpdate();
                                                  }
                                                }
                                              );
                                            }}
                                            format="YYYY-MM-DD"
                                          />
                                          {/* <span style={{ color: nameError ? 'red' : '' }} className="form-text text-muted"><p style={{ color: specialDateError ? 'red' : '' }}>Please enter Start and End Date</p></span> */}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
