import React, { useEffect, useState } from 'react';
import {
    Icon,
    Popconfirm,
    Switch,
    Select,
} from 'antd';

import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import 'react-table/react-table.css';


const { Option } = Select;

export default function BookingConfigData({ data, deleteBookingConfig, updateBookingConfigActivation }) {

    const [bookingConfigData, setBoookingConfigData] = useState([]);

    useEffect(() => {
        setBoookingConfigData(data);
    }, [data]);

    return (
        <ReactTable
            data={bookingConfigData}
            filterable
            showPageSizeOptions={true}
            defaultPageSize={10}

            defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value}
            columns={[

                {
                    Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Section Title</p>,
                    id: "section_title",
                    accessor: d => d.section_title,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["section_title"] }),
                    filterAll: true,
                    filterable: true
                },
                {
                    Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Section Order</p>,
                    id: "section_order",
                    accessor: d => d.section_order,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["section_order"] }),
                    filterAll: true,
                    filterable: true
                },
                {
                    Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Minimum Selection</p>,
                    id: "minimum_selection",
                    accessor: d => d.minimum_selection,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["minimum_selection"] }),
                    filterAll: true,
                    filterable: true
                },
                {
                    Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Maximum Selection</p>,
                    id: "maximum_selection",
                    accessor: d => d.maximum_selection,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["maximum_selection"] }),
                    filterAll: true,
                    filterable: false
                },
                {
                    Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Status</p>,
                    id: "is_active",
                    accessor: "is_active",
                    Cell: ({ value }) => (value ? <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">Active</span> : <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">De-Activate</span>),
                    filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                            return true;
                        }
                        if (filter.value === 'true') {

                            return row[filter.id] === true;
                        }
                        return row[filter.id] === false;
                    },
                    Filter: ({ filter, onChange }) =>
                        <Select
                            onChange={value => onChange(value)}
                            style={{ width: "100%" }}
                            defaultValue="all"
                            value={filter ? filter.value : "all"}
                        >
                            <Option value="all">Show All</Option>
                            <Option value="true">Active</Option>
                            <Option value="false">De-Activate</Option>
                        </Select>,
                },
                {
                    Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', padding: '10px 0px', fontSize: 12 }} >Action</p>,
                    id: "actions",
                    width: 150,
                    Cell: ({ row }) => (
                        <div style={{ justifyContent: 'center' }}>
                            {/* {row._original.is_new && */}
                            <>
                                <Icon onClick={() => {
                                    window.location.href = `/product-booking/section/edit/${row._original.id}`
                                }} style={{ color: '#5D78FF', fontSize: 16, margin: '0px 10px' }} type="edit" />
                                <Popconfirm
                                    title="Are you sure you want to delete this code?"
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={() => deleteBookingConfig(row._index)}
                                >
                                    <Icon style={{ color: '#5D78FF', fontSize: 16 }} type="delete" />
                                </Popconfirm>

                            </>
                            {/* } */}
                            <Popconfirm
                                title={`Are you sure you want to ${row.is_active ? `DEACTIVATE` : `ACTIVATE`} this code`}
                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                onConfirm={() => updateBookingConfigActivation(row._index, !row.is_active)}
                            >
                                <Switch style={{ backgroundColor: row.is_active ? '#5d78ff' : '#fd397a', margin: '0px 10px' }} checked={row.is_active} />
                            </Popconfirm>


                        </div>
                    ),
                    filterable: false
                },
            ]
            }
            className="-striped -highlight"
        />
    )
}