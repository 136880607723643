import React from "react";
import { Link } from "react-router-dom";
import { Icon, Popconfirm, Switch, Select, Spin, DatePicker } from "antd";
import _ from "lodash";
import matchSorter from "match-sorter";

import ReactTable from "react-table";
import "react-table/react-table.css";
import UserModel from "../../Models/UserModel";
import { errorNotification, infoToaster } from "../Shared/Notifications";
import moment from "moment";
import downloadCSV from "../Shared/CsvDownloader";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      data: [],
      currentPage: 1,
      totalPage: 1,
      perPage: 20,
      filters: [],
      startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getUsersList();
  }

  getUsersList() {
    const it = this;
    let params = {
      offset: this.state.currentPage,
    };
    this.state.filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });

    if (params["users.is_active"] === "all") {
      delete params["users.is_active"];
    }

    it.setState({ loading: true });
    UserModel.getInstance().searchUsersList(
      params,
      function success(data) {
        it.setState({
          data: UserModel.getInstance().UserList,
          totalPage: data.users.last_page,
          perPage: data.users.per_page,
          loading: false,
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false, data: [] });
        errorNotification("Request information not found");
      }
    );
  }

  deleteAdmin(id) {
    const it = this;
    UserModel.getInstance().removeUser(id,
      function success() {
        it.setState({
          data: UserModel.getInstance().UserList,
          loading: false
        })
      },
      function failure(error) {
        console.log(error)
        it.setState({ loading: false })
        errorNotification(error)
      }

    )
  }

  userActivation(userId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = !is_active;
    let params = {};
    params.is_active = activation;
    UserModel.getInstance().userActivation(
      userId,
      params,
      function success() {
        it.setState({ data: UserModel.getInstance().UserList });
        it.setState({ loading: false });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  userDeactivation(userId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let deactivation = !is_active;
    let params = {};
    params.is_active = deactivation;
    UserModel.getInstance().userDeactivation(
      userId,
      params,
      function success() {
        it.setState({ data: UserModel.getInstance().UserList });
        it.setState({ loading: false });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getUsersList();
  }, 1000);

  renderActionView(d) {
    if ((d.role = "Admin")) {
      return (
        <div>
          <Link to={`/user/edit/${d.id}`} style={{ marginRight: 20 }}>
            <Icon style={{ color: "#5D78FF", fontSize: 16 }} type="edit" />
          </Link>
          <Popconfirm
            title="Are you sure you want to delete this User?"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={() => this.deleteAdmin(d.id)}
          >
            <Icon style={{ color: '#5D78FF', fontSize: 16 }} type="delete" />
          </Popconfirm>

          <Popconfirm
            // style={{}}
            title={
              d.is_active
                ? "Are you sure you want to DEACTIVATE this User"
                : "Are you sure you want to ACTIVATE this User"
            }
            icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
            onConfirm={() => this.userActivation(d.id, d.is_active)}
          >
            <Switch
              style={{
                backgroundColor: d.is_active ? "#5d78ff" : "#fd397a",
                marginLeft: 15,
              }}
              checked={d.is_active}
              onChange={(val) => console.log(val)}
            />
          </Popconfirm>
        </div>
      );
    } else {
      return (
        <div>
          <Icon
            style={{ color: "gray", fontSize: 16, marginRight: 20 }}
            type="edit"
          />
          {/* <Icon style={{ color: 'gray', fontSize: 16 }} type="delete" /> */}
          <Popconfirm
            // style={{}}
            title={
              d.is_active
                ? "Are you sure you want to DEACTIVATE this user"
                : "Are you sure you want to ACTIVATE this user"
            }
            icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
            onConfirm={() => this.userActivation(d.id, d.is_active)}
          >
            <Switch
              style={{
                backgroundColor: d.is_active ? "#5d78ff" : "#fd397a",
                marginLeft: 15,
              }}
              checked={d.is_active}
              onChange={(val) => console.log(val)}
            />
          </Popconfirm>
        </div>
      );
    }
  }

  downloadUsers() {
    let it = this;
    let { startDate, endDate } = this.state;
    let params = {
      from: startDate,
      to: endDate,
    };
    infoToaster("Please Wait! File will be Downloaded shortly");
    UserModel.getInstance().downloadUsers(
      params,
      function success(data) {
        infoToaster(data);
        // it.exportCSV(data.users)
      },
      function failure(error) {
        errorNotification(error);
        console.log("error: ", error);
      }
    );
  }

  exportCSV(data) {
    let { startDate, endDate } = this.state;
    let newArr = [];
    data.forEach((log) => {
      let newObj = {};
      newObj["Name"] = log.full_name;
      newObj["Email"] = log.email;
      newObj["Phone"] = ` \"${log.mobile}\"`;
      newObj["Roles"] = log.role;
      newObj["Partners"] = log.partners
        .map((partner) => {
          return partner.name;
        })
        .join(" | ");
      newObj["Merchants"] = log.merchants
        .map((merchant) => {
          return merchant.name;
        })
        .join(" | ");
      newObj["Apps"] = log.apps
        .map((app) => {
          return app;
        })
        .join(" | ");
      newObj["Created At"] = log.created_at
        ? moment(log.created_at).format("DD/MMM/YYYY - hh:mm A")
        : "N/A";
      newArr.push(newObj);
    });
    downloadCSV(
      { filename: "Users from " + startDate + " to " + endDate + ".csv" },
      newArr
    );
  }

  disabledDate(current) {
    return current > moment().endOf("day");
  }

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            {gridHeaderWithButton(
              "Users",
              "la la-plus",
              "Add User",
              "user/add"
            )}
          </div>
          <div className="kt-portlet__body">
            <Spin spinning={this.state.loading}>
              <div className="form-group row">
                <label className="col-3 col-form-label">
                  Start Date ~ End Date <b>*</b>
                </label>
                <div className="col-9">
                  <RangePicker
                    disabledDate={this.disabledDate}
                    className="form-control"
                    defaultValue={[moment().subtract(7, "days"), moment()]}
                    style={{
                      padding: "0px",
                      border: "0px solid black",
                      width: "35%",
                    }}
                    showTime={false}
                    onChange={(value, string) =>
                      this.setState({
                        startDate: string[0],
                        endDate: string[1],
                      })
                    }
                    format="YYYY-MM-DD"
                  />
                </div>
              </div>

              <div
                style={{ marginLeft: "-10px" }}
                className="col-3 col-form-label"
              >
                <div className="btn-group">
                  <button
                    onClick={() => this.downloadUsers()}
                    style={{ marginLeft: "10px" }}
                    type="button"
                    className="btn btn-brand"
                  >
                    <i className="la la-download"></i>
                    <span className="kt-hidden-mobile">Export</span>
                  </button>
                </div>
              </div>

              {/* <!--begin: Datatable --> */}
              {/* <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
										<thead>
											<tr>
												<th>Record ID</th>
												<th>Order ID</th>
												<th>Country</th>
												<th>Ship City</th>
												<th>Company Agent</th>
												<th>Ship Date</th>
												<th>Status</th>
												<th>Type</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tfoot>
											<tr>
												<th>Record ID</th>
												<th>Order ID</th>
												<th>Country</th>
												<th>Ship City</th>
												<th>Company Agent</th>
												<th>Ship Date</th>
												<th>Status</th>
												<th>Type</th>
												<th>Actions</th>
											</tr>
										</tfoot>
									</table> */}

              {/* <!--end: Datatable --> */}

              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                pageSize={10}
                // showPageJump={false}
                showPageSizeOptions={false}
                manual
                page={this.state.currentPage - 1}
                pages={this.state.totalPage}
                filtered={this.state.filters}
                // onFetchData={(state) => {
                // 	// console.log(state.filtered)
                // 	if (this.state.currentPage !== state.page + 1) {
                // 		this.setState({ currentPage: state.page + 1 })
                // 		setTimeout(() => {
                // 			this.getMerchant()
                // 		}, 50);
                // 	}
                // }}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getUsersList();
                  }, 50);
                }}
                onFilteredChange={(filters) => {
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                columns={[
                  // {
                  // Header: "Name",
                  // columns: [
                  {
                    Header: <p style={tableHeaderStyles()}>Full Name</p>,
                    id: "full_name",
                    accessor: (d) => d.full_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["full_name"] }),
                    filterAll: true,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Email</p>,
                    id: "email",
                    accessor: (d) => d.email,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["email"] }),
                    filterAll: true,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Phone Number</p>,
                    id: "mobile",
                    accessor: (d) => d.mobile,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["mobile"] }),
                    filterAll: true,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Roles</p>,
                    id: "user_roles.name",
                    accessor: (d) =>
                      d.roles.map((role, key) => (
                        <p
                          className="btn btn-label-info btn-pill"
                          style={{
                            float: "left",
                            margin: "2px",
                            padding: "5px 10px",
                            fontSize: "11px",
                          }}
                          key={key}
                        >
                          {role.name}
                        </p>
                      )),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["role"] }),
                    filterAll: true,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Partner</p>,
                    id: "partners.name",
                    accessor: (d) =>
                      d.partners.map((partner, key) => (
                        <p
                          className="btn btn-label-info btn-pill"
                          style={{
                            float: "left",
                            margin: "2px",
                            padding: "5px 10px",
                            fontSize: "11px",
                          }}
                          key={key}
                        >
                          {partner.name}
                        </p>
                      )),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["partners"] }),
                    filterAll: true,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Merchant</p>,
                    id: "merchants.name",
                    accessor: (d) =>
                      d.merchants.map((merchant, key) => (
                        <p
                          className="btn btn-label-info btn-pill"
                          style={{
                            float: "left",
                            margin: "2px",
                            padding: "5px 10px",
                            fontSize: "11px",
                          }}
                          key={key}
                        >
                          {merchant.name}
                        </p>
                      )),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["merchants"] }),
                    filterAll: true,
                    sortable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Apps</p>,
                    id: "apps.name",
                    accessor: (d) =>
                      d.apps.map((app, key) => (
                        <p
                          className="btn btn-label-info btn-pill"
                          style={{
                            float: "left",
                            margin: "2px",
                            padding: "5px 10px",
                            fontSize: "11px",
                          }}
                          key={key}
                        >
                          {app}
                        </p>
                      )),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["role"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Programs</p>,
                    id: "program.name",
                    accessor: (d) =>
                      d.programs.map((program, key) => (
                        <p
                          className="btn btn-label-info btn-pill"
                          style={{
                            float: "left",
                            margin: "2px",
                            padding: "5px 10px",
                            fontSize: "11px",
                          }}
                          key={key}
                        >
                          {program}
                        </p>
                      )),
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["role"] }),
                    filterAll: true,
                    sortable: false,
                    filterable: false,
                  },
                  // {
                  // 	Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Type</p>,
                  // 	id: "user_roles.name",
                  // 	accessor: d => d.role,
                  // 	filterMethod: (filter, rows) =>
                  // 		matchSorter(rows, filter.value, { keys: ['role'] }),
                  // 	filterAll: true
                  // },
                  {
                    Header: <p style={tableHeaderStyles()}>Created By</p>,
                    id: "created_by_user",
                    accessor: (d) =>
                      d.created_by_user ? d.created_by_user.full_name : "N/A",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: [(item) => item.created_by_user],
                      }),
                    filterAll: true,
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Created At</p>,
                    id: "created_at",
                    accessor: (d) =>
                      d.created_at
                        ? moment(d.created_at).format("hh:mm A - DD/MMM/YYYY")
                        : "N/A",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: [(item) => item.created_at],
                      }),
                    filterAll: true,
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "users.is_active",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      console.log("VALUE: ", filter.value);
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value == "true") {
                        return row[filter.id] == true;
                      }
                      return row[filter.id] == false;
                    },
                    Filter: ({ filter, onChange }) =>
                      console.log("VALUE: ", filter) || (
                        <Select
                          onChange={(value) => onChange(value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <Option value="all">Show all</Option>
                          <Option value="true">Active</Option>
                          <Option value="false">De-Activate</Option>
                        </Select>
                      ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Actions</p>,
                    id: "actions",
                    width: 150,
                    accessor: (d) => this.renderActionView(d),
                    filterable: false,
                    sortable: false,
                  },

                  // ]
                  // },
                  // {
                  // Header: "Info",
                  // columns: [
                  // {
                  // Header: "Gender",
                  // accessor: "age"
                  // },
                  // {
                  // Header: "Over 21",
                  // accessor: "age",
                  // id: "over",
                  // Cell: ({ value }) => (value >= 21 ? "Yes" : "No"),
                  // filterMethod: (filter, row) => {
                  // 	if (filter.value === "all") {
                  // 	return true;
                  // 	}
                  // 	if (filter.value === "true") {
                  // 	return row[filter.id] >= 21;
                  // 	}
                  // 	return row[filter.id] < 21;
                  // },
                  // Filter: ({ filter, onChange }) =>
                  // 	<select
                  // 	onChange={event => onChange(event.target.value)}
                  // 	style={{ width: "100%" }}
                  // 	value={filter ? filter.value : "all"}
                  // 	>
                  // 	<option value="all">Show All</option>
                  // 	<option value="true">Can Drink</option>
                  // 	<option value="false">Can't Drink</option>
                  // 	</select>
                  // }
                ]}
                // ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}
