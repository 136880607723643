import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel'



export default class ValueTypeModel {
    static myInstance = null;
    static getInstance() {
        if (ValueTypeModel.myInstance === null) {
           ValueTypeModel.myInstance = new ValueTypeModel();
        }
        return this.myInstance;
    }

    static ValueTypeList = [];
    static TypeList = [];
    static ProgramValueTypeList = [];


// value type
    getValueType( successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getValueType(token, 
            function resSuccess(data) {
                ValueTypeModel.getInstance().ValueTypeList = data.data
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    searchValueType(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().searchValueType(token, params,
            function resSuccess(data) {
                ValueTypeModel.getInstance().ValueTypeList = data.data
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    addValueType(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addValueType(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    getValueTypeDetail(valueTypeId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getValueTypeDetail(token, valueTypeId, 
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateValueType(valueTypeId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateValueType(token, valueTypeId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    removeValueType(valueTypeId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeValueType(token, valueTypeId,
            function resSuccess() {
                ValueTypeModel.getInstance().ValueTypeList.forEach((value, index) => {
                    if (value.id === valueTypeId) {
                        ValueTypeModel.getInstance().ValueTypeList.splice(index, 1);
                        successTrigger()
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    getType( successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getType(token, 
            function resSuccess(data) {
                ValueTypeModel.getInstance().TypeList = data.data
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    getProgramValueType(programTypeId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getProgramValueType(token, programTypeId, 
            function resSuccess(data) {
                ValueTypeModel.getInstance().ProgramValueTypeList = data.program
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


}