import React from "react";
import {
  Select,
  Spin,
  // Icon,
  // Input,
  Checkbox,
  DatePicker,
} from "antd";

import {
  errorNotification,
  errorToaster,
  successNotification,
} from "../Shared/Notifications";
import ValueTypeModel from "../../Models/ValueTypeModel";
import CardModel from "../../Models/CardModel";
// import CouponModel from '../../Models/CouponModel';
import {
  validateNumber,
  validateNumberLimit,
} from "../../Helpers/Shared/Validators";
import FileUploader from "../Shared/FileUploader";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class AddCardSet extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      name: "",
      nameError: false,
      description: "",
      descriptionError: false,
      program: null,
      programList: [],
      programError: false,
      limitedUses: false,
      maxUses: "",
      maxUsesError: false,
      reloadable: false,
      expiry: null,
      expiryList: [
        {
          name: "Predefined Period",
          id: 1,
        },
        {
          name: "Expiry from first use",
          id: 2,
        },
        {
          name: "No Expiry",
          id: 3,
        },
      ],
      expiryError: false,
      startDate: "",
      startDateError: false,
      endDate: "",
      endDateError: false,
      days: "",
      dayError: false,
      card: null,
      cardList: [
        {
          name: "Single",
          id: 1,
        },
        {
          name: "Multiple",
          id: 2,
        },
      ],
      cardError: false,
      uploadType: null,
      uploadTypeList: [
        {
          name: "Manual",
          id: 1,
        },
        {
          name: "Automatic",
          id: 2,
        },
      ],
      uploadTypeError: false,
      startRange: "",
      startRangeError: false,
      endRange: "",
      endRangeError: false,
      fileName: null,
      fileCode: [],
      cards: "1",
      cardsError: false,
      prefix: "",
      prefixError: false,
      card_number: [],
      card_numberError: false,
      rangeCard: "1",
      rangeCardError: false,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }
  componentDidMount() {
    let it = this;
    ValueTypeModel.getInstance().getProgramValueType(
      3,
      function success() {
        it.setState({
          programList: ValueTypeModel.getInstance().ProgramValueTypeList,
        });
      },
      function failure(error) {
        console.log(error);
        it.setState({ loading: false });
      }
    );
  }

  generate_random_number(number_length) {
    let num_low = 1;
    let num_high = 9;
    let random_number = null;
    let string = "";

    for (let i = 0; i < number_length; i++) {
      random_number = Math.floor(
        Math.random() * (num_high - num_low) + num_low
      );
      var rand = [random_number, random_number];
      var random = rand[Math.floor(Math.random() * 2)];
      string += random;
    }
    return string;
  }

  generate_random_number_array() {
    let length = Number(this.state.rangeCard);
    let arr = [];
    let i = 0;
    while (i < length) {
      let string = this.generate_random_number(5);
      if (!arr.includes(string)) {
        arr.push(string);
        i++;
      }
    }
    this.setState({ card_number: arr.join(",") });
  }

  getcard_number(data, fileName) {
    if (data.length < 1) {
      errorNotification("Invalid file Please Upload correct file");
    } else {
      this.getFilesCode(data, fileName);
    }
  }

  getFilesCode(data, fileName) {
    let card_number = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].length != 5) {
        errorToaster(`Invalid data at line ${i + 2}`);
        break;
      } else {
        card_number.push(data[i].card_number);
      }
    }
  }

  generateCard() {
    let { uploadType, startRange, endRange } = this.state;
    let it = this;

    if (this.state.card) {
      if (uploadType == 2) {
        if (!validateNumberLimit(startRange)) {
          this.setState({ startRangeError: true });
          return;
        }

        if (!validateNumberLimit(endRange)) {
          this.setState({ endRangeError: true });
          return;
        }
      }
      it.generate_random_number_array();
      return;
    }
  }

  disabledDate(current) {
    return current < moment().startOf("day");
  }

  sendData() {
    const {
      name,
      description,
      program,
      limitedUses,
      maxUses,
      reloadable,
      expiry,
      startDate,
      endDate,
      days,
      card,
      //   cardNo,
      uploadType,
      startRange,
      endRange,
      card_number,
      rangeCard,
    } = this.state;
    let it = this;
    let params = {};
    this.setState({
      nameError: false,
      descriptionError: false,
      programError: false,
      maxUsesError: false,
      expiryError: false,
      startDateError: false,
      endDateError: false,
      dayError: false,
      cardError: false,
      cardNoError: false,
      uploadTypeError: false,
      startRangeError: false,
      endRangeError: false,
    });

    if (name.length < 1) {
      this.setState({ nameError: true });
      errorToaster("Please enter name");
      return;
    }
    params.name = name;

    params.description = description;

    if (!program) {
      this.setState({ programError: true });
      errorToaster("Please select program");
      return;
    }
    params.program_id = program;

    params.use_limit = limitedUses;

    if (limitedUses) {
      if (!validateNumber(maxUses)) {
        this.setState({ maxUsesError: true });
        errorToaster("Please enter max uses with only numbers");
        return;
      } else {
        if (Number(maxUses < 1)) {
          this.setState({ maxUsesError: true });
          errorToaster("Please enter max uses greater than zero");
          return;
        }
      }
      params.max_use = parseInt(maxUses);
    }

    params.reloadable = reloadable;

    if (!expiry) {
      this.setState({ expiryError: true });
      errorToaster("Please select expiry");
      return;
    }
    params.expiry = expiry;

    if (expiry == 1) {
      if (startDate.length < 1) {
        this.setState({ startDateError: true });
        errorToaster("Please select start date");
        return;
      }
      params.start_date = startDate;
      if (endDate.length < 1) {
        this.setState({ endDateError: true });
        errorToaster("Please select end date");
        return;
      }
      params.end_date = endDate;
    }

    if (expiry == 2) {
      if (!validateNumber(days)) {
        this.setState({ dayError: true });
        errorToaster("Please enter no. of days with only numbers");
        return;
      } else {
        if (Number(days < 1)) {
          this.setState({ dayError: true });
          errorToaster("Please enter no. of days greater than zero");
          return;
        }
      }
      params.number_days = days;
    }

    if (!card) {
      this.setState({ cardError: true });
      errorToaster("Please select no. of cards");
      return;
    }
    params.card_number_type = card;

    if (card == 2) {
      if (!validateNumber(rangeCard)) {
        this.setState({ rangeCardError: true });
        errorToaster("Please enter card range for multiple");
        return;
      } else {
        if (Number(rangeCard) < 2) {
          this.setState({ rangeCardError: true });
          errorToaster("Please enter card range must be greater than 1");
          return;
        }
      }
      params.cards = parseInt(rangeCard);

      if (!uploadType) {
        this.setState({ uploadTypeError: true });
        errorToaster("Please enter upload type");
        return;
      }

      params.upload_type = uploadType;
    }

    if (uploadType == 2) {
      if (!validateNumberLimit(startRange)) {
        this.setState({ startRangeError: true });
        errorToaster(
          "Please enter start range atleast 5 and at most 16 digits"
        );
        return;
      }
      params.start_range = startRange;

      if (!validateNumberLimit(endRange)) {
        this.setState({ endRangeError: true });
        errorToaster("Please enter end range atleast 5 and at most 16");
        return;
      }
      params.end_range = endRange;
    }

    params.card_number = card_number.split(",");

    it.setState({ loading: true });
    CardModel.getInstance().addCardSet(
      params,
      function success(data) {
        successNotification("Card Set Created Successfully");
        setTimeout(() => {
          window.location.href = "/card-set";
        }, 1000);
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  render() {
    const {
      nameError,
      descriptionError,
      //   maxUses,
      maxUsesError,
      programList,
      programError,
      //   reloadable,
      expiryList,
      expiryError,
      dayError,
      //   cardError,
      //   cardNoError,
      uploadTypeError,
      startRangeError,
      endRangeError,
      //   prefixError,
      //   cardsError,
      card_numberError,
    } = this.state;
    console.log("card", this.state.cards);
    console.log("card numbers", this.state.card_number);
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <Spin spinning={this.state.loading}>
          <div className="kt-content  kt-grid__item kt-grid__item--fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">Add Card Set</h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/card-set"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        <div className="col-xl-2"></div>
                        <div className="col-xl-8">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Name <b>*</b>
                                </label>
                                <div className="col-9">
                                  <input
                                    onChange={(event) =>
                                      this.setState({
                                        name: event.target.value,
                                      })
                                    }
                                    maxLength="100"
                                    className="form-control"
                                    type="text"
                                  />
                                  <span className="form-text text-muted">
                                    <p
                                      style={{ color: nameError ? "red" : "" }}
                                    >
                                      Please enter Name{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  className="col-3 col-form-label"
                                  htmlFor="exampleTextarea"
                                >
                                  Description{" "}
                                </label>
                                <div className="col-9">
                                  <div className="input-group">
                                    <textarea
                                      maxLength="150"
                                      onChange={(event) =>
                                        this.setState({
                                          description: event.target.value,
                                        })
                                      }
                                      className="form-control"
                                      rows="3"
                                    ></textarea>
                                  </div>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: descriptionError ? "red" : "",
                                      }}
                                    >
                                      Please enter description{" "}
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Program <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    value={this.state.program}
                                    onChange={(program) => {
                                      this.setState({ program });
                                    }}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {programList.map((program) => (
                                      <Option
                                        key={program.id}
                                        value={program.id}
                                      >
                                        {program.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: programError ? "red" : "",
                                      }}
                                    >
                                      Please Select Program
                                    </p>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Limited Uses
                                </label>
                                <div className="col-9">
                                  <Checkbox
                                    checked={this.state.limitedUses}
                                    onChange={(event) =>
                                      this.setState({
                                        limitedUses: event.target.checked,
                                      })
                                    }
                                    className="form-control"
                                    style={{ border: "0px" }}
                                  />
                                  <span className="form-text text-muted">
                                    Please check if limited uses
                                  </span>
                                </div>
                              </div>

                              {this.state.limitedUses ? (
                                <div className="form-group row">
                                  <label className="col-3 col-form-label">
                                    Max Uses <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          maxUses: event.target.value,
                                        })
                                      }
                                      maxLength="100"
                                      className="form-control"
                                      type="text"
                                    />
                                    <span className="form-text text-muted">
                                      <p
                                        style={{
                                          color: maxUsesError ? "red" : "",
                                        }}
                                      >
                                        Please enter Max Uses{" "}
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              ) : null}

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Reloadable{" "}
                                </label>
                                <div className="col-9">
                                  <Checkbox
                                    checked={this.state.reloadable}
                                    onChange={(event) =>
                                      this.setState({
                                        reloadable: event.target.checked,
                                      })
                                    }
                                    className="form-control"
                                    style={{ border: "0px" }}
                                  />
                                  <span className="form-text text-muted">
                                    Please check if reloadable
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  Expiry <b>*</b>
                                </label>
                                <div className="col-9">
                                  <Select
                                    showSearch
                                    style={{ margin: "10px 0px 10px 0px" }}
                                    // value={this.state.expiry}
                                    onChange={(value) =>
                                      this.setState({ expiry: value })
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {expiryList.map((exp) => (
                                      <Option key={exp.id} value={exp.id}>
                                        {exp.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  <span className="form-text text-muted">
                                    <p
                                      style={{
                                        color: expiryError ? "red" : "",
                                      }}
                                    >
                                      Please Select Expiry
                                    </p>
                                  </span>
                                </div>
                              </div>

                              {this.state.expiry == 1 ? (
                                <div className="form-group row">
                                  <label className="col-3 col-form-label">
                                    Start Date ~ End Date <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <RangePicker
                                      disabledDate={this.disabledDate}
                                      className="form-control"
                                      style={{
                                        padding: "0px",
                                        border: "0px solid black",
                                      }}
                                      showTime={false}
                                      onChange={(value, string) =>
                                        this.setState({
                                          startDate: string[0],
                                          endDate: string[1],
                                        })
                                      }
                                      format="YYYY-MM-DD HH:mm:ss"
                                    />
                                  </div>
                                </div>
                              ) : null}

                              {this.state.expiry == 2 ? (
                                <div className="form-group row">
                                  <label className="col-3 col-form-label">
                                    No. of days <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <input
                                      onChange={(event) =>
                                        this.setState({
                                          days: event.target.value,
                                        })
                                      }
                                      maxLength="100"
                                      className="form-control"
                                      type="text"
                                    />
                                    <span className="form-text text-muted">
                                      <p
                                        style={{ color: dayError ? "red" : "" }}
                                      >
                                        Please enter No. of days{" "}
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              ) : null}

                              <div className="form-group row">
                                <label className="col-3 col-form-label">
                                  No. of cards <b>*</b>
                                </label>
                                <div className="col-9">
                                  <div className="row">
                                    <Select
                                      showSearch
                                      style={{
                                        margin: "10px 0px 10px 0px",
                                        width: "30%",
                                        marginRight: "5%",
                                        marginLeft: "2%",
                                      }}
                                      // style={{ margin: '10px 0px 10px 0px' }}
                                      value={this.state.card}
                                      onChange={(value) =>
                                        this.setState({
                                          card: value,
                                          uploadType: null,
                                          rangeCard: "1",
                                          card_number: [],
                                        })
                                      }
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {this.state.cardList.map((card) => (
                                        <Option key={card.id} value={card.id}>
                                          {card.name}
                                        </Option>
                                      ))}
                                    </Select>
                                    <input
                                      style={{
                                        margin: "10px 0px 10px 0px",
                                        width: "30%",
                                        marginRight: "5%",
                                        marginLeft: "2%",
                                      }}
                                      disabled={
                                        this.state.fileName
                                          ? true
                                          : this.state.card == 1
                                          ? true
                                          : false
                                      }
                                      value={this.state.rangeCard}
                                      maxLength="20"
                                      className="form-control"
                                      min="1"
                                      type="number"
                                      onChange={(event) => {
                                        if (event.target.value > 0) {
                                          this.setState({
                                            rangeCard:
                                              event.target.value.replace(
                                                ".",
                                                ""
                                              ),
                                            rangeCardError: false,
                                            card_number: [],
                                          });
                                        } else
                                          this.setState({
                                            number_of_coupons:
                                              event.target.value.replace(
                                                ".",
                                                ""
                                              ),
                                            number_of_couponsError: true,
                                          });
                                      }}
                                    />
                                    {/* <span className="form-text text-muted"><p style={{ color: cardError ? 'red' : '' }}>Please Select No. of cards</p></span> */}
                                  </div>
                                </div>
                              </div>

                              {this.state.card == 2 ? (
                                <div className="form-group row">
                                  <label className="col-3 col-form-label">
                                    Upload Type <b>*</b>
                                  </label>
                                  <div className="col-9">
                                    <Select
                                      showSearch
                                      style={{ margin: "10px 0px 10px 0px" }}
                                      value={this.state.uploadType}
                                      onChange={(value) =>
                                        this.setState({ uploadType: value })
                                      }
                                      filterOption={(input, option) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {this.state.uploadTypeList.map((type) => (
                                        <Option key={type.id} value={type.id}>
                                          {type.name}
                                        </Option>
                                      ))}
                                    </Select>
                                    <span className="form-text text-muted">
                                      <p
                                        style={{
                                          color: uploadTypeError ? "red" : "",
                                        }}
                                      >
                                        Please Select Upload Type
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              ) : null}

                              {this.state.card ? (
                                Number(this.state.cards) > 1 ? (
                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Card Numbers <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <textarea
                                        disabled
                                        value={this.state.card_number}
                                        className="form-control"
                                        type="text"
                                        onChange={(event) =>
                                          this.setState({
                                            card_number: event.target.value,
                                          })
                                        }
                                      ></textarea>
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: card_numberError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please Enter card_number Must be comma
                                          separated Value
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                ) : this.state.card == 1 ? (
                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Card Number <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <textarea
                                        disabled
                                        value={this.state.card_number}
                                        className="form-control"
                                        type="text"
                                        onChange={(event) =>
                                          this.setState({
                                            card_number:
                                              event.target.value.toUpperCase(),
                                          })
                                        }
                                      />
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: card_numberError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please Enter Code (Must be 4 digit
                                          AlphaNumeric Value)
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Card Number <b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <textarea
                                        disabled
                                        value={this.state.card_number}
                                        className="form-control"
                                        type="text"
                                        onChange={(event) =>
                                          this.setState({
                                            card_number:
                                              event.target.value.toUpperCase(),
                                          })
                                        }
                                      />
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: card_numberError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please Enter Code (Must be 7 digit
                                          AlphaNumeric Value)
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                )
                              ) : null}

                              {this.state.uploadType == 2 ? (
                                <div>
                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      Start Range<b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            startRange: event.target.value,
                                          })
                                        }
                                        maxLength="100"
                                        className="form-control"
                                        type="text"
                                      />
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: startRangeError ? "red" : "",
                                          }}
                                        >
                                          Please enter start range{" "}
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      End Range<b>*</b>
                                    </label>
                                    <div className="col-9">
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            endRange: event.target.value,
                                          })
                                        }
                                        maxLength="100"
                                        className="form-control"
                                        type="text"
                                      />
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: endRangeError ? "red" : "",
                                          }}
                                        >
                                          Please enter end range
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {this.state.uploadType == 1 ? (
                                this.state.fileName ? (
                                  <div className="form-group row">
                                    <label className="col-3 col-form-label">
                                      File Uploaded
                                    </label>
                                    <div className="col-9">
                                      <button
                                        onClick={() =>
                                          this.setState({
                                            fileName: null,
                                            cards: 1,
                                            card_number: [],
                                          })
                                        }
                                        type="button"
                                        className="btn btn-brand"
                                      >
                                        <span className="kt-hidden-mobile">
                                          Remove File
                                        </span>
                                      </button>
                                      <span className="form-text text-muted">
                                        {this.state.fileName}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div style={{ marginBottom: "20px" }}>
                                    <FileUploader
                                      dataType={"cardNumber"}
                                      getData={(data, fileName) =>
                                        this.getcard_number(data, fileName)
                                      }
                                    />
                                  </div>
                                )
                              ) : null}

                              {this.state.card ? (
                                <div className="form-group row">
                                  <div className="col-9">
                                    <button
                                      onClick={() => this.generateCard()}
                                      type="button"
                                      className="btn btn-brand"
                                    >
                                      <span className="kt-hidden-mobile">
                                        Generate{" "}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
