/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import {
    Input,
    Spin,
    Select,
} from 'antd';

import {
    successNotification,
    errorNotification,
    errorToaster
} from '../Shared/Notifications';


import { validateProductPriceParams } from './helperFunctions';
import SharedModel from "../../Models/SharedModel";
import AppPartnerProductPricingModel from "../../Models/AppPartnerProductPricingModel";

const { Option } = Select;


export default function ProductPricingDetailData({ props, is_edit }) {
    const { match: { params } } = props;
    const
        [loading, setLoading] = useState(false),
        [name, set_name] = useState(''),
        [description, set_description] = useState(''),
        [app_id, set_app_id] = useState(is_edit ? undefined : []),
        [product_booking_id, set_product_booking_id] = useState(undefined),
        [product_online_deal_id, set_product_online_deal_id] = useState(undefined),
        [item_id, set_item_id] = useState(undefined),
        [list_price, set_list_price] = useState(null),
        [discounted_price, set_discounted_price] = useState(null),
        [app_list, set_app_list] = useState([]);


    useEffect(() => {
        getAllApps();
        if (is_edit) getProductBookingConfigDetail(); // CALL API
        else set_product_booking_id(params.id)
    }, []);

    function getProductBookingConfigDetail() {
        const { id } = params;
        setLoading(true);
        AppPartnerProductPricingModel.getInstance().getPartnerProductPricingDetail(id,
            function success(data) {
                set_name(data.name);
                set_description(data.description);
                set_app_id(data.apps?.id);
                set_product_online_deal_id(data.product_online_deals?.id);
                set_item_id(data.items?.id);
                set_list_price(data.list_price);
                set_discounted_price(data.discounted_price);
                set_product_booking_id(data.product_bookings?.id);
                setLoading(false);
            },
            function failure(error) {
                errorNotification(error)
                setLoading(false)
            }
        )
    }



    const saveConfig = () => {
        const payload = {
            name,
            description,
            product_booking_id,
            app_id,
            product_online_deal_id,
            item_id,
            list_price,
            discounted_price
        };
        const validation = validateProductPriceParams(payload, is_edit);
        if (!validation.status) {
            errorToaster(validation.message)
        } else {
            checkAppPricingAlreadyAdded(is_edit, payload,
                function success(data) {
                    if (data.status === true) {
                        if (is_edit) updateConfigAPI(payload);
                        else createConfigAPI(payload);
                    } else {
                        errorNotification(data.message)
                    }

                }
            )
        }
    }

    function checkAppPricingAlreadyAdded(is_edit, payload, callBack) {
        const search = {
            product_booking_id: payload.product_booking_id,
            app_id: payload.app_id,
            is_active: true
        }
        AppPartnerProductPricingModel.getInstance().getPartnerProductPricings(search,
            function success(data) {
                if (!is_edit && data.count > 0) callBack({ status: false, message: "There Should be only one Price for each Product Booking" });
                else if (is_edit) {
                    const { id } = params;
                    if (!data.records.some(record => record.id == id) && data.count > 0) callBack({ status: false, message: "There Should be only one Price for each Product Booking" });
                    else callBack({ status: true });
                }
                else callBack({ status: true });
            }, function failure(error) {
                callBack({ status: false, message: error })
            }
        )
    }


    function createConfigAPI(payload) {
        const request_payload = app_id.map(app => (
            { ...payload, app_id: app }
        ))
        AppPartnerProductPricingModel.getInstance().addPartnerProductPricing(
            request_payload,
            function success(data) {
                successNotification('Product Pricing Saved');
                setTimeout(() => {
                    props.history.goBack();
                    setLoading(false);
                }, 1000);
            },
            function failure(error) {
                setLoading(false);
                errorNotification(JSON.stringify(error))
            }
        )
    }
    function updateConfigAPI(payload) {
        const { id } = params;
        AppPartnerProductPricingModel.getInstance().updatePartnerProductPricing(id,
            payload,
            function success(data) {
                successNotification('Product Pricing Saved');
                setTimeout(() => {
                    props.history.goBack();
                    setLoading(false);
                }, 1000);
            },
            function failure(error) {
                setLoading(false);
                errorNotification(JSON.stringify(error))
            }
        )
    }

    function getAllApps() {
        SharedModel.getInstance().getAllAppIds(
            function success(data) {
                setLoading(false)
                set_app_list(data)
            },
            function failure(error) {
                setLoading(false)
                errorToaster("Request information not found")
            }
        )
    }


    return (
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                <Spin spinning={loading} >

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                <div className="kt-portlet__head kt-portlet__head--lg">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">{is_edit ? 'Edit' : 'Add'} Product Pricing</h3>
                                    </div>
                                    <div className="kt-portlet__head-toolbar">
                                        <div onClick={() => props.history.goBack()} className="btn btn-clean kt-margin-r-10">
                                            <i className="la la-arrow-left"></i>
                                            <span className="kt-hidden-mobile">Back</span>
                                        </div>
                                        <div className="btn-group">
                                            <button onClick={() => saveConfig()} type="button" className="btn btn-brand">
                                                <i className="la la-check"></i>
                                                <span className="kt-hidden-mobile">Save</span>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                                <div className="kt-portlet__body">
                                    <form className="kt-form" id="kt_form">
                                        <div className="row">
                                            <div className="col-xl-2"></div>
                                            <div className="col-xl-8">
                                                <div className="kt-section kt-section--first">
                                                    <div className="kt-section__body">

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Name</label>
                                                            <div className="col-9">
                                                                <Input maxLength={100} value={name} onChange={(event) => set_name(event.target.value)} className="form-control" />
                                                                <span className="form-text text-muted"><p>Please Enter Section Name</p></span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Description</label>
                                                            <div className="col-9">
                                                                <Input.TextArea maxLength={100} value={description} onChange={(event) => set_description(event.target.value)} className="form-control" />
                                                                <span className="form-text text-muted"><p>Please Enter Description</p></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">App</label>
                                                            <div className="col-9">
                                                                <Select
                                                                    allowClear
                                                                    showSearch
                                                                    mode={is_edit ? "default" : "multiple"}
                                                                    style={{ margin: '10px 0px 10px 0px', width: '100%' }}
                                                                    value={app_id}
                                                                    onChange={(app) => {
                                                                        set_app_id(app)
                                                                    }}
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                                    {app_list.map((app) => (
                                                                        <Option key={app.id} value={app.id}>{app.name}</Option>
                                                                    ))}
                                                                </Select>
                                                                <span className="form-text text-muted">Please Select App</span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">List Price</label>
                                                            <div className="col-9">
                                                                <Input maxLength={4} value={list_price} onChange={(event) => set_list_price(event.target.value)} className="form-control" type="number" />
                                                                <span className="form-text text-muted"><p>Please Enter List Price</p></span>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-3 col-form-label">Discounted Price</label>
                                                            <div className="col-9">
                                                                <Input maxLength={4} value={discounted_price} onChange={(event) => set_discounted_price(event.target.value)} className="form-control" type="number" />
                                                                <span className="form-text text-muted"><p>Please Enter Discounted Price</p></span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div >
        </div >
    )
}