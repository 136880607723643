import React from "react";
import { Input, Select, Spin, Icon, Tabs } from "antd";
import { getCompressedImage } from "Helpers/Shared/utils";

import CategoryMerchantOutlet from "./MerchantOutletAssociation";

import UserModel from "../../Models/UserModel";

import ColorPicker from "../Shared/ColorPicker";
import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";
import backImage from "../../../src/assets/media/users/default.jpg";
import SharedModel from "../../Models/SharedModel";
import MATERIAL_ICONS from "../Shared/MaterialIcons.json";
import MATERIAL_COMMUNITY_ICONS from "../Shared/MaterialCommunityIcons.json";
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

const { Option } = Select;
const { TabPane } = Tabs;
const IconLibraryList = [
  {
    value: "materialIcons",
  },
  {
    value: "materialCommunityIcons",
  }
]
export default class EditCategory extends React.Component {
  constructor() {
    super();
    this.state = {
      logoImage: null,
      logoImageError: false,
      logoImageSrc: null,
      serverLogoSrc: null,
      name: "",
      nameError: false,
      arb_name: "",
      arb_nameError: false,
      bannerImage: null,
      bannerImageError: false,
      bannerImageName: null,
      description: "",
      descriptionError: false,
      arb_description: "",
      arb_descriptionError: false,
      categoryId: null,
      backColor: "#ffffff",
      category: null,
      categoryList: [],
      loading: false,
      productVisibilityList: [],
      productVisibility: null,
      tabKey: '1',
      icon_library: null,
      icon_name: null,
      icon_color: "#000000",
      icon_background: "#ffffff"
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    let it = this;
    const {
      match: { params },
    } = this.props;
    this.setState({ loading: true });
    this.setState({ categoryId: params.categoryId });
    UserModel.getInstance().getCategoryDetail(
      params.categoryId,
      function success(data) {
        it.setState({
          name: data.name,
          arb_name: data.arabic_name ? data.arabic_name : "",
          description: data.description,
          arb_description: data.arabic_description
            ? data.arabic_description
            : "",
          serverLogoSrc: data.icon,
          backColor: data.color,
          category: data.parent_id,
          productVisibility: data.product_visibility_id,
          icon_library: data.icon_library,
          icon_name: data.icon_name,
          icon_color: data.icon_color ? data.icon_color : it.state.icon_color,
          icon_background: data.background_color ? data.background_color : it.state.icon_background,
          loading: false,
        });
        if (data.product_visibility_id)
          it.getAllProductVisibilities(data.product_visibility_id);
        it.getCategories(data.parent_name);
      },
      function failure(error) {
        it.setState({ loading: false });
        console.error(error);
      }
    );
  }

  saveLogo(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ loading: true });
    getCompressedImage(file)
      .then((compressedImage) => {
        if (compressedImage) {
          this.setState({
            loading: false,
            logoImage: compressedImage,
            logoImageSrc: URL.createObjectURL(compressedImage),
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        errorNotification(e);
      });
  }
  saveBanner(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ loading: true });
    getCompressedImage(file)
      .then((compressedImage) => {
        if (compressedImage) {
          this.setState({
            loading: false,
            bannerImage: compressedImage,
            bannerImageName: compressedImage.name,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        errorNotification(e);
      });
  }

  sendData() {
    let {
      categoryId,
      name,
      arb_name,
      description,
      arb_description,
      backColor,
      category,
      logoImage,
      productVisibility,
      icon_library, icon_name, icon_background, icon_color,
    } = this.state;
    let it = this;
    const text = "Validation Error! Please check fields";
    this.setState({
      nameError: false,
      arb_nameError: false,
      descriptionError: false,
      arb_descriptionError: false,
    });

    if (name.length < 1) {
      this.setState({ nameError: true, activeKey: '1' });
      errorToaster(text);
      return;
    }
    if (!productVisibility) {
      this.setState({ activeKey: '1' });
      errorToaster("Please Select Product visibility");
      return;
    }
    if (description.length < 1) {
      this.setState({ descriptionError: true, activeKey: '1' });
      errorToaster(text);
      return;
    }

    let params = {
      name,
      arabic_name: arb_name,
      description,
      arabic_description: arb_description,
      color: backColor,
      product_visibility_id: productVisibility,
      icon_library,
      icon_name,
      icon_color,
      background_color: icon_background
    };

    if (category) {
      params.parent_id = category;
    } else {
      params.parent_id = null;
    }
    this.setState({ loading: true });
    UserModel.getInstance().updateCategory(
      categoryId,
      params,
      function success(data) {
        it.addMedia(logoImage, data.category.id);
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.error(error);
      }
    );
  }

  addMedia(logoImage, id) {
    const { history } = this.props;
    let it = this;
    let params = new FormData();
    if (logoImage) {
      params.append("icon", logoImage);
      // if () {
      // }
      // if (bannerImage) {
      //     params.append('background', bannerImage);
      // }

      SharedModel.getInstance().addMediaInstance(
        id,
        "category",
        params,
        function success() {
          successNotification("Category Updated Successfully");
          setTimeout(() => {
            history.push("/category");
          }, 1000);
        },
        function failure(error) {
          it.setState({ loading: false });
          errorNotification(error);
          console.error(error);
        }
      );
    } else {
      successNotification("Category Updated Successfully");
      setTimeout(() => {
        history.push("/category");
      }, 1000);
    }
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (value, it) {
    it.getCategories(value);
  }, 1000);

  getCategories(value) {
    if (value?.length > 0) {
      let it = this;
      let params = {
        query: encodeURIComponent(value),
      };
      this.setState({ loading: true });
      UserModel.getInstance().getCategory(
        params,
        function success() {
          it.setState({
            loading: false,
            categoryList: UserModel.getInstance().CategoryList,
          });
          console.info(UserModel.getInstance().CategoryList);
        },
        function failure(error) {
          console.error(error);
          it.setState({ loading: false });
          errorToaster(error);
        }
      );
    }
  }

  getSearchProducVisibility = this.debounce(function (value, it) {
    if (value.length > 0) {
      it.getAllProductVisibilities(value);
    }
  }, 1000);

  async getAllProductVisibilities(value) {
    const it = this;
    const params =
      typeof value === "string"
        ? { name: encodeURIComponent(value) }
        : { id: value };
    SharedModel.getInstance().getAllProductVisibilities(
      params,
      function success() {
        it.setState({
          productVisibilityList: SharedModel.ProductVisiblityList,
        });
      },
      function failure(error) {
        errorNotification(error);
      }
    );
  }

  render() {
    const {
      serverLogoSrc,
      logoImageSrc,
      categoryList,
      name,
      nameError,
      arb_name,
      arb_nameError,
      description,
      descriptionError,
      arb_description,
      arb_descriptionError,
      logoImageError,
      loading,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <Spin spinning={loading}>
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">Edit Category</h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/category"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        {/* <div className="col-xl-2"></div> */}
                        <div className="col-xl-12">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">

                              <Tabs onChange={(tabKey) => this.setState({ tabKey })} activeKey={this.state.tabKey} defaultActiveKey="1" >
                                <TabPane tab="Overview" key="1">

                                  <div className="form-group row">
                                    <label className="col-xl-2 col-lg-3 col-form-label">
                                      Upload Logo <b>*</b>
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                      <div
                                        className="kt-avatar kt-avatar--outline kt-avatar--circle"
                                        id="kt_apps_user_add_avatar"
                                      >
                                        <div
                                          className="kt-avatar__holder"
                                          style={{
                                            backgroundImage: `url(${logoImageSrc
                                              ? logoImageSrc
                                              : serverLogoSrc
                                                ? serverLogoSrc
                                                : backImage
                                              })`,
                                          }}
                                        ></div>

                                        <label
                                          className="kt-avatar__upload"
                                          data-toggle="kt-tooltip"
                                          title=""
                                          data-original-title="Change avatar"
                                        >
                                          <i className="fa fa-pen"></i>
                                          <input
                                            onChange={(event) =>
                                              this.saveLogo(event)
                                            }
                                            type="file"
                                            name="kt_apps_user_add_user_avatar"
                                          />
                                        </label>
                                        <span
                                          className="kt-avatar__cancel"
                                          data-toggle="kt-tooltip"
                                          title=""
                                          data-original-title="Cancel avatar"
                                        >
                                          <i className="fa fa-times"></i>
                                        </span>
                                        <p
                                          style={{
                                            color: "red",
                                            display: logoImageError
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          Please Upload Icon Image
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Name <b>*</b>
                                    </label>
                                    <div className="col-10">
                                      <Input
                                        maxLength={70}
                                        onChange={(event) =>
                                          this.setState({
                                            name: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                        type="text"
                                        value={name}
                                      />
                                      <span
                                        style={{ color: nameError ? "red" : "" }}
                                        className="form-text text-muted"
                                      >
                                        <p
                                          style={{ color: nameError ? "red" : "" }}
                                        >
                                          Please enter name in English
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Arabic Name
                                    </label>
                                    <div className="col-10">
                                      <Input
                                        maxLength={100}
                                        onChange={(event) =>
                                          this.setState({
                                            arb_name: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                        type="text"
                                        value={arb_name}
                                      />
                                      <span
                                        style={{
                                          color: arb_nameError ? "red" : "",
                                        }}
                                        className="form-text text-muted"
                                      >
                                        <p
                                          style={{
                                            color: arb_nameError ? "red" : "",
                                          }}
                                        >
                                          Please enter name in Arabic
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Visibility Setting
                                    </label>

                                    <div className="col-10">
                                      <Select
                                        allowClear
                                        showSearch
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        value={this.state.productVisibility}
                                        notFoundContent={
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Icon
                                              style={{
                                                fontSize: 16,
                                                marginRight: "10px",
                                                marginBottom: "5px",
                                              }}
                                              type="search"
                                            />
                                            <p style={{ fontSize: 14 }}>
                                              Please search product visibility (Full
                                              text search)
                                            </p>
                                          </div>
                                        }
                                        onSearch={(val) =>
                                          this.getSearchProducVisibility(val, this)
                                        }
                                        onChange={(productVisibility) =>
                                          this.setState({ productVisibility })
                                        }
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {this.state.productVisibilityList.map(
                                          (visibility) => (
                                            <Option
                                              key={visibility.id}
                                              value={visibility.id}
                                            >
                                              {visibility.name}
                                            </Option>
                                          )
                                        )}
                                      </Select>
                                      <span className="form-text text-muted">
                                        Please Select Visibility Setting
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Category
                                    </label>
                                    <div className="col-10">
                                      <Select
                                        showSearch
                                        allowClear
                                        onSearch={(val) =>
                                          this.searchQuery(val, this)
                                        }
                                        value={this.state.category}
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        placeholder="Select a category"
                                        onChange={(category) =>
                                          this.setState({ category })
                                        }
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {categoryList.map((item) => (
                                          <Option key={item.id} value={item.id}>
                                            {item.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <span className="form-text text-muted">
                                        Please select main category
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label">
                                      Choose background Color <b>*</b>
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                      <div
                                        className="kt-avatar kt-avatar--outline kt-avatar--circle"
                                        id="kt_apps_user_add_avatar"
                                      >
                                        <ColorPicker
                                          backColor={(color) =>
                                            this.setState({ backColor: color })
                                          }
                                          defaultColor={this.state.backColor}
                                        />
                                        <label
                                          onClick={() => this.togglePicker()}
                                          className="kt-avatar__upload"
                                        >
                                          <i className="fa fa-pen"></i>
                                        </label>
                                        <span
                                          className="kt-avatar__cancel"
                                          data-toggle="kt-tooltip"
                                          title=""
                                          data-original-title="Cancel avatar"
                                        >
                                          <i className="fa fa-times"></i>
                                        </span>
                                        <span className="form-text text-muted">
                                          Please Choose background-color
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-2 col-form-label"
                                      htmlFor="exampleTextarea"
                                    >
                                      Description <b>*</b>
                                    </label>
                                    <div className="col-10">
                                      <div className="input-group">
                                        <textarea
                                          maxLength={250}
                                          onChange={(event) =>
                                            this.setState({
                                              description: event.target.value,
                                            })
                                          }
                                          value={description}
                                          className="form-control"
                                          rows="3"
                                        ></textarea>
                                      </div>
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: descriptionError ? "red" : "",
                                          }}
                                        >
                                          Please enter Description in English
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      className="col-2 col-form-label"
                                      htmlFor="exampleTextarea"
                                    >
                                      Description in Arabic
                                    </label>
                                    <div className="col-10">
                                      <div className="input-group">
                                        <textarea
                                          maxLength={250}
                                          onChange={(event) =>
                                            this.setState({
                                              arb_description: event.target.value,
                                            })
                                          }
                                          value={arb_description}
                                          className="form-control"
                                          rows="3"
                                        ></textarea>
                                      </div>
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: arb_descriptionError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please enter Description in Arabic
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                </TabPane>

                                <TabPane tab="Icon" key="2">
                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">Icon Library <b>*</b></label>
                                    <div className="col-10">

                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={(icon_library) => {
                                          this.setState({
                                            icon_name: undefined,
                                            icon_library
                                          })
                                        }}
                                        value={this.state.icon_library}
                                        filterOption={(input, option) =>
                                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {IconLibraryList.map(lib => (
                                          <Option key={lib.value} value={lib.value} >{lib.value}</Option>
                                        ))}
                                      </Select>
                                      <span className="form-text text-muted"><p style={{}}>Please select a Library</p></span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">Icon Name <b>*</b></label>
                                    <div className="col-10">

                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={(icon_name) => this.setState({ icon_name })}
                                        value={this.state.icon_name}
                                        filterOption={(input, option) =>
                                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {
                                          this.state.icon_library === "materialIcons" ?
                                            MATERIAL_ICONS.map(_icon => (
                                              <Option key={_icon} value={_icon} >{_icon}</Option>
                                            ))
                                            :
                                            this.state.icon_library === "materialCommunityIcons" ?
                                              MATERIAL_COMMUNITY_ICONS.map(_icon => (
                                                <Option key={_icon} value={_icon} >{_icon}</Option>
                                              ))
                                              : null
                                        }
                                      </Select>
                                      <span className="form-text text-muted"><p style={{}}>Please select an Icon</p></span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">Choose Icon Background Color <b>*</b></label>
                                    <div className="col-10">
                                      <ColorPicker
                                        backColor={(icon_background) => this.setState({ icon_background })}
                                        defaultColor={this.state.icon_background}
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">Choose Icon Color <b>*</b></label>
                                    <div className="col-6">
                                      <ColorPicker
                                        backColor={(icon_color) => this.setState({ icon_color })}
                                        defaultColor={this.state.icon_color}
                                      />
                                    </div>
                                    {
                                      this.state.icon_library === "materialIcons" && this.state.icon_name ?
                                        <div className="col-3">
                                          <div style={{ backgroundColor: this.state.icon_background, textAlign: 'center', padding: '10px 0px', borderRadius: '5px' }}>
                                            <span style={{ color: this.state.icon_color, fontSize: 50 }} className="material-icons">{this.state.icon_name}</span>
                                          </div>
                                        </div> :
                                        this.state.icon_library === "materialCommunityIcons" && this.state.icon_name ?
                                          <div className="col-3">
                                            <div style={{ backgroundColor: this.state.icon_background, textAlign: 'center', padding: '10px 0px', borderRadius: '5px' }}>
                                              <MaterialCommunityIcons name={this.state.icon_name} size={50} color={this.state.icon_color} />
                                            </div>
                                          </div> : null
                                    }

                                  </div>
                                </TabPane>

                                <TabPane tab="Association" key="association">
                                  <CategoryMerchantOutlet category_id={this.state.categoryId} />
                                </TabPane>

                              </Tabs>



                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
