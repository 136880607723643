import React from 'react';
import {
    Spin,
} from 'antd';
import { Link } from 'react-router-dom'
import matchSorter from 'match-sorter';

import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { errorNotification } from '../Shared/Notifications';
import UserModel from '../../Models/UserModel';



export default class DeliveryReports extends React.Component {

    constructor() {
        super();
        this.state = {
            data: [],
            loading: false,
            currentPage: 1,
            totalPage: 1,
            perPage: 20,
            filters: [],

        }
    }

    componentDidMount() {
        this.getDeliveryReport();
        this.setState({
            currentClass: window.location.pathname
        })
    }

    getDeliveryReport() {
        const it = this;
        let params = {
            offset: this.state.currentPage
        }
        this.state.filters.forEach(filter => {
            params[filter.id] = filter.value.toLowerCase()
        });

        it.setState({ loading: true })
        UserModel.getInstance().searchDeliveryReport(params,
            function success(data) {
                it.setState({
                    loading: false,
                    data: UserModel.getInstance().DeliveryReportList,
                    totalPage: data.orders.last_page,
                    perPage: data.orders.per_page,
                });
            },
            function failure(error) {
                it.setState({ loading: false, data: [] })
                errorNotification("Request information not found");
            }
        )
    }

    debounce(callback, wait) {
        let timeout;
        return (...args) => {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => callback.apply(context, args), wait);
        };
    }

    searchQuery = this.debounce(function (it) {
        it.getDeliveryReport()
    }, 1000);

    render() {
        const { data } = this.state;
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <div className="kt-content  kt-grid__item kt-grid__item--fluid" >
                    <Spin spinning={this.state.loading}>
                        <div className="kt-portlet kt-portlet--mobile">
                            <div className="kt-portlet__head kt-portlet__head--lg">
                                <div className="kt-portlet__head-label">
                                    <span className="kt-portlet__head-icon">
                                        <i className="kt-font-brand flaticon2-line-chart"></i>
                                    </span>
                                    <h3 className="kt-portlet__head-title">
                                        Pickup Request
										</h3>

                                </div>

                                <div className="kt-portlet__head-toolbar">
                                    <div className="kt-portlet__head-wrapper">
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__body">

                                {
                                    <ReactTable
                                        data={data}
                                        filterable
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value

                                        }
                                        pageSize={20}
                                        showPageSizeOptions={false}
                                        manual
                                        page={this.state.currentPage - 1}
                                        pages={this.state.totalPage}
                                        filtered={this.state.filters}
                                        onPageChange={(pageIndex) => {
                                            this.setState({ currentPage: pageIndex + 1 })
                                            setTimeout(() => {
                                                this.getDeliveryReport()
                                            }, 50);
                                        }}
                                        onFilteredChange={(filters) => {
                                            this.setState({ currentPage: 1, filters })
                                            this.searchQuery(this)
                                        }}

                                        columns={[
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Order No.</p>,
                                                id: "orders.id",
                                                accessor: d => <Link
                                                    to={`order/${d.id}`}
                                                    style={{ marginLeft: 15 }}
                                                ><u>{d.order_number}</u></Link>,
                                                style: { whiteSpace: 'unset', color: '#5D78FF', paddingTop: 15 },
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["order_number"] }),
                                                filterAll: true,
                                                sortable: false,
                                                filterable: true,
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Job Id</p>,
                                                id: "delivery_logs.job_id",
                                                accessor: d => (
                                                    <div style={{ marginLeft: '10px', paddingTop: 10 }}>{d.job_id}</div>
                                                ),
                                                style: { whiteSpace: 'unset' },
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["job_id"] }),
                                                filterAll: true,
                                                sortable: false,
                                                filterable: true,
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Delivery Partner Name</p>,
                                                id: "delivery_partners.name",
                                                accessor: d => (
                                                    <div style={{ marginLeft: '10px', paddingTop: 10 }}>{d.partner_name}</div>
                                                ),
                                                style: { whiteSpace: 'unset' },
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["partner_name"] }),
                                                filterAll: true,
                                                sortable: false,
                                                filterable: true,
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Delivery Status Code</p>,
                                                id: "delivery_logs.order_status_code",
                                                accessor: d => (
                                                    <div style={{ marginLeft: '10px', paddingTop: 10 }}>{d.order_status_code}</div>
                                                ),
                                                style: { whiteSpace: 'unset' },
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["order_status_code"] }),
                                                filterAll: true,
                                                sortable: false,
                                                filterable: true,
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Delivery Status</p>,
                                                id: "delivery_logs.delivery_status",
                                                accessor: d => (
                                                    <div style={{ marginLeft: '10px', paddingTop: 10 }}>{d.delivery_status}</div>
                                                ),
                                                style: { whiteSpace: 'unset' },
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["delivery_status"] }),
                                                filterAll: true,
                                                sortable: false,
                                                filterable: false,
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Delivery Message</p>,
                                                id: "delivery_logs.message",
                                                accessor: d => (
                                                    <div style={{ marginLeft: '10px', paddingTop: 10 }}>{d.message}</div>
                                                ),
                                                style: { whiteSpace: 'unset' },
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["message"] }),
                                                filterAll: true,
                                                sortable: false,
                                                filterable: true,
                                            },
                                            {
                                                Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Order time</p>,
                                                id: "orders.created_at",
                                                accessor: d => d.order_time,
                                                style: { whiteSpace: 'unset' },
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, { keys: ["order_time"] }),
                                                filterAll: true,
                                                sortable: false,
                                                filterable: true,
                                            }

                                        ]
                                        }
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                    />
                                }


                            </div>

                        </div>

                    </Spin>
                </div>
            </div >
        )
    }
}