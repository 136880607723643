import React from "react";
import { Icon, Spin, Popconfirm, Select, Switch } from "antd";
import { Link, withRouter } from "react-router-dom";
import matchSorter from "match-sorter";
import { errorToaster } from "../Shared/Notifications";
import ReactTable from "react-table";
import "react-table/react-table.css";
import SharedModel from "../../Models/SharedModel";
import OfferModel from "../../Models/OfferModel";
import MerchantModel from "../../Models/MerchantModel";
import {
  infoNotification,
  errorNotification,
  infoToaster,
} from "../Shared/Notifications";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

class Content extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = props;
    this.state = {
      data: [],
      loading: false,
      currentPage: params.page || 1,
      totalPages: 0,
      merchantList: [],
      merchant: "",
      merchantError: false,
      filters: [],
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  componentDidMount() {
    this.getOffers();
    this.getMerchantList(null);
  }

  getMerchantList(query) {
    let it = this;

    let params = query ? { query } : null;
    MerchantModel.getInstance().getMerchant(
      params,
      function success(data) {
        it.setState({
          merchantList: data.data,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  getOffers() {
    const it = this;
    let { filters } = this.state;
    it.setState({ loading: true });
    let params = {
      offset: this.state.currentPage,
      offer_type: 1
    };
    filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });
    if (params["is_special"] === "all") {
      delete params["is_special"];
    }
    if (params["offers.is_active"] === "all") {
      delete params["offers.is_active"];
    }
    if (this.props.is_outlet) {
      params["outlets.id"] = this.props.outlet_id;
    }
    OfferModel.getInstance().getSearchOffer(
      params,
      function success(data) {
        it.setState({
          data: OfferModel.getInstance().OfferList,
          loading: false,
          totalPages: Number((data.total / data.per_page).toFixed(0)),
        });
      },
      function failure(error) {
        it.setState({ loading: false, data: [] });
        errorNotification("Request information not found");
        console.log(error);
      }
    );
  }

  deleteOffer(offerId) {
    const it = this;
    it.setState({ loading: true });
    OfferModel.getInstance().removeOffer(
      offerId,
      function success() {
        it.setState({
          data: OfferModel.getInstance().OfferList,
          loading: false,
        });
        infoNotification("Offer Deleted Successfully");
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
        console.log(error);
      }
    );
  }

  offerActivation(offerId, is_active) {
    const it = this;
    it.setState({ loading: true });
    let activation = !is_active;
    let params = {
      is_active: activation,
    };
    OfferModel.getInstance().offerActivation(
      offerId,
      params,
      function success() {
        it.setState({
          data: OfferModel.getInstance().OfferList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  exportData() {
    let it = this;
    let { filters, merchant } = this.state;
    this.setState({
      merchantError: false,
    });
    let params = {
      type: "offer",
    };
    filters.forEach((filter) => {
      params[filter.id] = filter.value.toLowerCase();
    });

    params.merchant_id = merchant;
    if (!merchant) {
      this.setState({ merchantError: true });
      errorToaster("Please select merchant before downloading");
      return;
    }
    infoToaster("Please Wait! File will be Downloaded shortly");
    SharedModel.getInstance().downloadReport(
      params,
      function success(data) {
        it.setState({
          loading: false,
        });
        infoToaster(data);
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQueryMerchant = this.debounce(function (value, it) {
    it.getMerchantList(value);
  }, 1000);

  searchQuery = this.debounce(function (it) {
    it.getOffers();
  }, 1000);

  render() {
    const { data, merchantList } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <Spin spinning={this.state.loading}>
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-portlet__head kt-portlet__head--lg">
                {gridHeaderWithButton(
                  "Offers",
                  "la la-plus",
                  "Add New Offer",
                  this.props.is_outlet ? `/offer/1/add?outlet_id=${this.props.outlet_id}` : "/offer/1/add"
                )}
              </div>
              <div className="kt-portlet__body">
                {!this.props.is_outlet &&
                  <div className="form-group row">
                    <label className="col-3 col-form-label" htmlFor="marchantGroup">
                      Merchants
                    </label>
                    <div className="col-9">
                      <Select
                        showSearch
                        style={{ margin: "10px 0px 10px 0px", width: "35%" }}
                        onChange={(merchant) => this.setState({ merchant })}
                        notFoundContent={
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon
                              style={{
                                fontSize: 16,
                                marginRight: "10px",
                                marginBottom: "5px",
                              }}
                              type="search"
                            />
                            <p style={{ fontSize: 14 }}>
                              Please Search Merchant by Name
                            </p>
                          </div>
                        }
                        onSearch={(val) => this.searchQueryMerchant(val, this)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {merchantList.map((merchant) => (
                          <Option key={merchant.id} value={merchant.id}>
                            {merchant.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                }

                {!this.props.is_outlet &&
                  <div
                    style={{ marginLeft: "10px" }}
                    className="col-3 col-form-label"
                  >
                    <div className="btn-group">
                      <button
                        onClick={() => this.exportData()}
                        style={{ marginLeft: "10px" }}
                        type="button"
                        className="btn btn-brand"
                      >
                        <i className="la la-download"></i>
                        <span className="kt-hidden-mobile">Export</span>
                      </button>
                    </div>
                  </div>
                }


                {/* react-table */}
                <ReactTable
                  data={data}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  pageSize={20}
                  showPageSizeOptions={false}
                  manual
                  page={this.state.currentPage - 1}
                  pages={this.state.totalPages}
                  filtered={this.state.filters}
                  onPageChange={(pageIndex) => {
                    this.setState({ currentPage: pageIndex + 1 });
                    setTimeout(() => {
                      this.getOffers();
                    }, 50);
                  }}
                  onFilteredChange={(filters) => {
                    this.setState({ currentPage: 1, filters });
                    this.searchQuery(this);
                  }}
                  columns={[
                    {
                      Header: <p style={tableHeaderStyles()}>Name</p>,
                      id: "offers.name",
                      accessor: "name",
                      style: { whiteSpace: "unset" },
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["name"] }),
                      filterAll: true,
                      sortable: false,
                      filterable: true,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Arabic name</p>,
                      accessor: "arabic_name",
                      style: { whiteSpace: "unset" },
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: ["arabic_name"],
                        }),
                      filterAll: true,
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Merchant</p>,
                      id: "merchants.name",
                      accessor: (d) => d.merchant.merchant_name,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: [(item) => item.merchant.merchant_name],
                        }),
                      filterAll: true,
                      sortable: false,
                      filterable: !this.props.is_outlet,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Programs</p>,
                      id: "programs.name",
                      accessor: (d) =>
                        d.programs.map((program, key) => (
                          <p
                            className="btn btn-label-info btn-pill"
                            style={{
                              float: "left",
                              margin: "2px",
                              padding: "5px 10px",
                              fontSize: "11px",
                            }}
                            key={key}
                          >
                            {program.name}
                          </p>
                        )),
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: [
                            (item) =>
                              item.programs.map(
                                (program) => program.props.children
                              ),
                          ],
                        }),
                      filterAll: true,
                      sortable: false,
                      filterable: true,
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Special Offer</p>,
                      width: 120,
                      id: "is_special",
                      accessor: "is_special",
                      Cell: ({ value }) =>
                        value ? (
                          <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                            Special
                          </span>
                        ) : (
                          <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                            Regular
                          </span>
                        ),
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true;
                        }
                        if (filter.value == "true") {
                          return row[filter.id] == true;
                        }
                        return row[filter.id] == false;
                      },
                      Filter: ({ filter, onChange }) =>
                      (
                        <Select
                          onChange={(value) => onChange(value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <Option value="all">Show all</Option>
                          <Option value="true">Special</Option>
                          <Option value="false">Regular</Option>
                        </Select>
                      ),
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Validity</p>,
                      width: 200,
                      id: "validity",
                      accessor: (d) =>
                        Array.isArray(d.special_validity)
                          ? d.special_validity.map((validity, key) => (
                            <p
                              className="btn btn-label-info btn-pill"
                              style={{
                                float: "left",
                                margin: "2px",
                                padding: "5px 10px",
                                fontSize: "11px",
                              }}
                              key={key}
                            >
                              {validity.start_date}~{validity.end_date}
                            </p>
                          ))
                          : null,
                      filterAll: true,
                      // sortable: false,
                      filterable: false,
                      sortable: false,
                      filterable: false,
                    },
                    // {
                    //     Header: <p style={{ fontWeight: '500', borderBottomWidth: '1px', paddingTop: '1rem', paddingBottom: '1rem', fontSize: 15 }} >Tag</p>,
                    //     id: "featured",
                    //     accessor: "featured",
                    //     accessor: d => (
                    //         <Select
                    //             // onChange={value => onChange(value)}
                    //             value="none"
                    //             style={{ width: "75%" }}
                    //         // defaultValue="none"
                    //         >
                    //             <Option value="none">None</Option>
                    //             <Option value="featured">Featured</Option>
                    //             <Option value="popular">Popular</Option>
                    //         </Select>
                    //     ),
                    //     filterMethod: (filter, row) => {
                    //         if (filter.value === "all") {
                    //             return true;
                    //         }
                    //         if (filter.value === 'true') {

                    //             return row[filter.id] === true;
                    //         }
                    //         return row[filter.id] === false;
                    //     },
                    //     Filter: ({ filter, onChange }) =>
                    //         <Select
                    //             onChange={value => onChange(value)}
                    //             // value="none"
                    //             style={{ width: "100%" }}
                    //             defaultValue="all"
                    //         >
                    //             <Option value="all">Show All</Option>
                    //             <Option value="none">None</Option>
                    //             <Option value="featured">Featured</Option>
                    //             <Option value="popular">Popular</Option>
                    //         </Select>,
                    // },
                    {
                      Header: <p style={tableHeaderStyles()}>Status</p>,
                      id: "offers.is_active",
                      accessor: "is_active",
                      Cell: ({ value }) =>
                        value ? (
                          <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                            Active
                          </span>
                        ) : (
                          <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                            De-Activate
                          </span>
                        ),
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true;
                        }
                        if (filter.value == "true") {
                          return row[filter.id] == true;
                        }
                        return row[filter.id] == false;
                      },
                      Filter: ({ filter, onChange }) =>
                      (
                        <Select
                          onChange={(value) => onChange(value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <Option value="all">Show all</Option>
                          <Option value="true">Active</Option>
                          <Option value="false">De-Activate</Option>
                        </Select>
                      ),
                    },
                    {
                      Header: <p style={tableHeaderStyles()}>Actions</p>,
                      id: "actions",
                      width: 150,
                      Filter: (
                        <span
                          onClick={() => {
                            if (this.state.filters.length === 0) {
                            } else {
                              this.setState({
                                filters: [],
                                currentPage: 1,
                              });
                              setTimeout(() => {
                                this.getOffers();
                              }, 100);
                            }
                          }}
                          className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill"
                        >
                          Reset-Filters
                        </span>
                      ),
                      accessor: (d) => (
                        <div>
                          <Link
                            to={`/offer/${this.state.currentPage}/edit/${d.id}`}
                            style={{ marginRight: 20 }}
                          >
                            <Icon
                              style={{ color: "#5D78FF", fontSize: 16 }}
                              type="edit"
                            />
                          </Link>
                          <Popconfirm
                            title="Are you sure you want to delete this offer?"
                            icon={
                              <Icon
                                type="question-circle-o"
                                style={{ color: "red" }}
                              />
                            }
                            onConfirm={() => this.deleteOffer(d.id)}
                          >
                            <Icon
                              style={{ color: "#5D78FF", fontSize: 16 }}
                              type="delete"
                            />
                          </Popconfirm>
                          <Popconfirm
                            title={
                              d.is_active
                                ? "Are you sure you want to DEACTIVATE this offer"
                                : "Are you sure you want to ACTIVATE this offer"
                            }
                            icon={
                              <Icon
                                type="question-circle-o"
                                style={{ color: "red" }}
                              />
                            }
                            onConfirm={() =>
                              this.offerActivation(d.id, d.is_active)
                            }
                          >
                            <Switch
                              style={{
                                backgroundColor: d.is_active
                                  ? "#5d78ff"
                                  : "#fd397a",
                                marginLeft: 15,
                              }}
                              checked={d.is_active}
                              onChange={(val) => console.log(val)}
                            />
                          </Popconfirm>
                        </div>
                      ),
                      sortable: false,
                      // filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                />
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
export default withRouter(Content);
