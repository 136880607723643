import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel'



export default class OfferModel {
    static myInstance = null;
    static getInstance() {
        if (OfferModel.myInstance === null) {
            OfferModel.myInstance = new OfferModel();
        }
        return this.myInstance;
    }

    static OfferList = [];
    static OfferTypeList = [];
    static OfferCriteriaList = [];



    getOffer(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOffer(token, params,
            function resSuccess(data) {
                OfferModel.getInstance().OfferList = data.offers.data;
                successTrigger(data.offers);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }
    getB1G1Offers(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getB1G1Offers(token, params,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    getSearchOffer(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getSearchOffer(token, params,
            function resSuccess(data) {
                OfferModel.getInstance().OfferList = data.data;
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    addOffer(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addOffer(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    getOfferCriteria(successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOfferCriteria(token,
            function resSuccess(data) {
                OfferModel.getInstance().OfferCriteriaList = data.criteria
                successTrigger(data.criteria)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    getOfferDetail(offerId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOfferDetail(token, offerId,
            function resSuccess(data) {
                successTrigger(data.offer);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateOffer(offerId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateOffer(token, offerId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    removeOffer(offerId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeOffer(token, offerId,
            function resSuccess() {
                OfferModel.getInstance().OfferList.forEach((offer, index) => {
                    if (offer.id === offerId) {
                        OfferModel.getInstance().OfferList.splice(index, 1);
                        successTrigger()
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    offerActivation(offerId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().offerActivation(token, offerId, params,
            function resSuccess(data) {
                OfferModel.getInstance().OfferList.forEach((offer, index) => {
                    if (offer.id === offerId) {
                        offer.is_active = params.is_active;
                        successTrigger(data)
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    activateOffer(offerId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        let params = {};
        params.id = offerId;
        ApiManager.getInstance().activateOffer(token, params,
            function resSuccess(data) {
                OfferModel.getInstance().OfferList.forEach((offer, index) => {
                    if (offer.id === offerId) {
                        offer.is_active = true;
                        successTrigger(data)
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }
    deactivateOffer(offerId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        let params = {};
        params.id = offerId;
        ApiManager.getInstance().deactivateOffer(token, params,
            function resSuccess(data) {
                OfferModel.getInstance().OfferList.forEach((offer, index) => {
                    if (offer.id === offerId) {
                        offer.is_active = false;
                        successTrigger(data)
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    getOfferType(successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getOfferType(token,
            function resSuccess(data) {
                OfferModel.getInstance().OfferTypeList = data.types
                successTrigger(data.types)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

}