import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel'



export default class AddonModel {
    static myInstance = null;
    static getInstance() {
        if (AddonModel.myInstance === null) {
            AddonModel.myInstance = new AddonModel();
        }
        return this.myInstance;
    }

    static AddonList = [];
    static AddonSetList = [];


//  Addon set
    getAddonSet( successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getAddonSet(token, 
            function resSuccess(data) {
                AddonModel.getInstance().AddonSetList = data.data
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    searchAddonSet(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().searchAddonSet(token, params,
            function resSuccess(data) {
                AddonModel.getInstance().AddonSetList = data.data;
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    addAddonSet(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addAddonSet(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    getAddonSetDetail(addonSetId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getAddonSetDetail(token, addonSetId,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateAddonSet(addonSetId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateAddonSet(token, addonSetId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    addonSetActivation(addonSetId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addonSetActivation(token, addonSetId, params,
            function resSuccess(data) {
                AddonModel.getInstance().AddonSetList.forEach((set, index) => {
                    if (set.id === addonSetId) {
                        set.is_active = params.is_active;
                        successTrigger(data)
                    }
                });
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    removeAddonSet(addonSetId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeAddonSet(token, addonSetId,
            function resSuccess() {
                AddonModel.getInstance().AddonSetList.forEach((set, index) => {
                    if (set.id === addonSetId) {
                        AddonModel.getInstance().AddonSetList.splice(index, 1);
                        successTrigger()
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


//    Addons
   

    getAddon(successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getAddon(token, 
            function resSuccess(data) {
                AddonModel.getInstance().AddonList = data.data
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    searchAddon(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().searchAddon(token, params,
            function resSuccess(data) {
                AddonModel.getInstance().AddonList = data.data;
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    addAddon(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addAddon(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }


    getAddonDetail(addonId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getAddonDetail(token, addonId,
            function resSuccess(data) {
                successTrigger(data);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateAddon(addonId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateAddon(token, addonId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    addonActivation(addonId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addonActivation(token, addonId, params,
            function resSuccess(data) {
                AddonModel.getInstance().AddonList.forEach((addon, index) => {
                    if (addon.id === addonId) {
                        addon.is_active = params.is_active;
                        successTrigger(data)
                    }
                });
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    removeAddon(addonId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().removeAddon(token, addonId,
            function resSuccess() {
                AddonModel.getInstance().AddonList.forEach((addon, index) => {
                    if (addon.id === addonId) {
                        AddonModel.getInstance().AddonList.splice(index, 1);
                        successTrigger()
                    }
                });
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

     //  addon set according to outlet
     getOutletAddon(params, successTrigger, failureTrigger) {
       let token = UserModel.getInstance().getToken();
       ApiManager.getInstance().getOutletAddon(token, params,
           function resSuccess(data) {
               successTrigger(data)
           },
           function resFailed(msg) {
               failureTrigger(msg)
           }
       )
   }

}