import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel';



export default class AppProgramPaymentModel {
    static myInstance = null;
    static getInstance() {
        if (AppProgramPaymentModel.myInstance === null) {
            AppProgramPaymentModel.myInstance = new AppProgramPaymentModel();
        }
        return this.myInstance;
    }
    static AppProgramPaymentList = [];


    addAppProgramPayment(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addAppProgramPayment(token, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    getAppProgramPayment(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getAppProgramPayment(token, params,
            function successCallback(data) {
                AppProgramPaymentModel.AppProgramPaymentList = data.records
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    getAppProgramPaymentDetail(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getAppProgramPaymentDetail(token, id,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    updateAppProgramPayment(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateAppProgramPayment(token, id, params,
            function successCallback(data) {
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    activateAppProgramPayment(id, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateAppProgramPayment(token, id, params,
            function successCallback(data) {
                for (let i = 0; i < AppProgramPaymentModel.AppProgramPaymentList.length; i++) {
                    if (AppProgramPaymentModel.AppProgramPaymentList[i].id === id) {
                        AppProgramPaymentModel.AppProgramPaymentList[i].is_active = params.is_active;
                        break;
                    }
                }
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }
    deleteAppProgramPayment(id, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().deleteAppProgramPayment(token, id,
            function successCallback(data) {
                AppProgramPaymentModel.AppProgramPaymentList.forEach((openingHour, index) => {
                    if (openingHour.id === id) {
                        AppProgramPaymentModel.AppProgramPaymentList.splice(index, 1);
                        successTrigger(data)
                    }
                });
                successTrigger(data)
            },
            function failureCallback(err) {
                failureTrigger(err)
            }
        )
    }

}