/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";

import { Input, Spin, Select, Icon, Checkbox } from "antd";

import _ from "lodash";

import {
  successNotification,
  errorNotification,
  errorToaster,
} from "../Shared/Notifications";

import SharedModel from "../../Models/SharedModel";

import { validateBookingConfigParams } from "./helperFunctions";
import ProgramModel from "../../Models/ProgramModel";
import UserModel from "../../Models/UserModel";
import ProductBookingModel from "../../Models/ProductBookingModel";

const { Option } = Select;

export default function ProductBookingConfigData({ props, is_edit }) {
  const {
    match: { params },
  } = props;
  const [loading, setLoading] = useState(false),
    [is_default, set_is_default] = useState(false),
    [section_title, set_section_title] = useState(""),
    [section_arabic_title, set_section_arabic_title] = useState(""),
    [description, set_description] = useState(""),
    [arabic_description, set_arabic_description] = useState(""),
    [product_booking_id, set_product_booking_id] = useState(undefined),
    [product_visibility_id, set_product_visibility_id] = useState(undefined),
    [product_visibility_list, set_product_visibility_list] = useState([]),
    [category_id, set_category_id] = useState(undefined),
    [category_list, set_category_list] = useState([]),
    [program_id, set_program_id] = useState([]),
    [program_list, set_program_list] = useState([]),
    [is_required, set_is_required] = useState(false),
    [multi_select, set_multi_select] = useState(false),
    [section_order, set_section_order] = useState(null),
    [minimum_selection, set_minimum_selection] = useState(null),
    [maximum_selection, set_maximum_selection] = useState(null);

  useEffect(() => {
    if (is_edit) getProductBookingConfigDetail();
    else {
      set_product_booking_id(params.id);
      getDefaultProductVisibility();
    }
  }, []);

  function getProductBookingConfigDetail() {
    const { id } = params;
    setLoading(true);
    ProductBookingModel.getInstance().getProductBookingConfigDetail(
      id,
      function success(data) {
        set_is_default(data.is_default)
        set_section_title(data.section_title);
        set_section_arabic_title(data.section_arabic_title);
        set_description(data.description);
        set_arabic_description(data.arabic_description);
        set_product_booking_id(data.product_booking_id);
        set_product_visibility_id(data.product_visibilities?.id);
        set_product_visibility_list(
          data.product_visibilities ? [data.product_visibilities] : []
        );
        set_category_id(data.categories?.id);
        set_category_list(data.categories ? [data.categories] : []);
        set_program_id(data.programs ? data.programs.map(_p => (_p.id)) : []);
        set_program_list(data.programs);
        set_is_required(data.is_required);
        set_multi_select(data.multi_select);
        set_section_order(data.section_order);
        set_minimum_selection(data.minimum_selection);
        set_maximum_selection(data.maximum_selection);
        setLoading(false);
      },
      function failure(error) {
        errorNotification(error);
        setLoading(false);
      }
    );
  }

  async function getDefaultProductVisibility() {
    setLoading(true)
    SharedModel.getInstance().getDefaultProductVisibility(
      function success(data) {
        set_product_visibility_list([data])
        set_product_visibility_id(data.id)
        setLoading(false)
      },
      function failure(error) {
        errorNotification(error)
        setLoading(false)

      }
    )
  }

  const getSearchProducVisibility = useCallback(
    _.debounce((value) => getAllProductVisibilities(value), 500),
    []
  );
  async function getAllProductVisibilities(value) {
    if (value.length > 0) {
      const payload = {
        name: encodeURIComponent(value),
      };
      SharedModel.getInstance().getAllProductVisibilities(
        payload,
        function success() {
          set_product_visibility_list(SharedModel.ProductVisiblityList);
          setLoading(false);
        },
        function failure(error) {
          errorNotification(error);
          setLoading(false);
        }
      );
    }
  }

  const getSearchCategories = useCallback(
    _.debounce((value) => getAllCategories(value), 500),
    []
  );
  async function getAllCategories(value) {
    if (value.length > 0) {
      const payload = {
        "categories.name": encodeURIComponent(value),
      };
      UserModel.getInstance().searchCategoryByName(
        payload,
        function success(data) {
          set_category_list(data.data);
          setLoading(false);
        },
        function failure(error) {
          errorNotification(error);
          setLoading(false);
        }
      );
    }
  }

  const getSearchPrograms = useCallback(
    _.debounce((value) => getAllPrograms(value), 500),
    []
  );
  async function getAllPrograms(value) {
    if (value.length > 0) {
      const payload = {
        "programs.name": encodeURIComponent(value),
      };
      ProgramModel.getInstance().searchProgram(
        payload,
        function success(data) {
          set_program_list(data.data);
          setLoading(false);
        },
        function failure(error) {
          errorNotification(error);
          setLoading(false);
        }
      );
    }
  }

  const saveConfig = () => {
    const payload = {
      section_title,
      section_arabic_title,
      description,
      arabic_description,
      product_booking_id,
      category_id,
      product_visibility_id,
      program_id,
      is_required,
      multi_select,
      section_order,
      minimum_selection,
      maximum_selection,
      is_default,
    };
    const validation = validateBookingConfigParams(payload);
    if (!validation.status) {
      errorToaster(validation.message);
    } else {
      if (is_edit) updateConfigAPI(payload);
      else createConfigAPI(payload);
    }
  };

  function createConfigAPI(payload) {
    ProductBookingModel.getInstance().addProductBookingConfig(
      payload,
      function success(data) {
        successNotification("Product Booking Section Saved");
        setTimeout(() => {
          props.history.goBack();
          setLoading(false);
        }, 1000);
      },
      function failure(error) {
        setLoading(false);
        errorNotification(JSON.stringify(error));
      }
    );
  }
  function updateConfigAPI(payload) {
    const { id } = params;
    ProductBookingModel.getInstance().updateProductBookingConfig(
      id,
      payload,
      function success(data) {
        successNotification("Product Booking Section Saved");
        setTimeout(() => {
          props.history.goBack();
          setLoading(false);
        }, 1000);
      },
      function failure(error) {
        setLoading(false);
        errorNotification(JSON.stringify(error));
      }
    );
  }

  return (
    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <Spin spinning={loading}>
          <div className="row">
            <div className="col-lg-12">
              <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                <div className="kt-portlet__head kt-portlet__head--lg">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">
                      {is_edit ? "Edit" : "Add"} Product Booking Section
                    </h3>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                    <div
                      onClick={() => props.history.goBack()}
                      className="btn btn-clean kt-margin-r-10"
                    >
                      <i className="la la-arrow-left"></i>
                      <span className="kt-hidden-mobile">Back</span>
                    </div>
                    <div className="btn-group">
                      <button
                        onClick={() => saveConfig()}
                        type="button"
                        className="btn btn-brand"
                      >
                        <i className="la la-check"></i>
                        <span className="kt-hidden-mobile">Save</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="kt-portlet__body">
                  <form className="kt-form" id="kt_form">
                    <div className="row">
                      <div className="col-xl-2"></div>
                      <div className="col-xl-8">
                        <div className="kt-section kt-section--first">
                          <div className="kt-section__body">
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Section Title
                              </label>
                              <div className="col-9">
                                <Input
                                  maxLength={100}
                                  value={section_title}
                                  onChange={(event) =>
                                    set_section_title(event.target.value)
                                  }
                                  className="form-control" /*type="number"*/
                                />
                                <span className="form-text text-muted">
                                  <p>Please Enter Section Title</p>
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Section Arabic Title
                              </label>
                              <div className="col-9">
                                <Input
                                  maxLength={100}
                                  value={section_arabic_title}
                                  onChange={(event) =>
                                    set_section_arabic_title(event.target.value)
                                  }
                                  className="form-control" /*type="number"*/
                                />
                                <span className="form-text text-muted">
                                  <p>Please Enter Section Arabic Title</p>
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Description
                              </label>
                              <div className="col-9">
                                <Input.TextArea
                                  maxLength={100}
                                  value={description}
                                  onChange={(event) =>
                                    set_description(event.target.value)
                                  }
                                  className="form-control" /*type="number"*/
                                />
                                <span className="form-text text-muted">
                                  <p>Please Enter Description</p>
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Arabic Description
                              </label>
                              <div className="col-9">
                                <Input.TextArea
                                  maxLength={100}
                                  value={arabic_description}
                                  onChange={(event) =>
                                    set_arabic_description(event.target.value)
                                  }
                                  className="form-control" /*type="number"*/
                                />
                                <span className="form-text text-muted">
                                  <p>Please Enter Arabic Description</p>
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Visibility Setting
                              </label>
                              <div className="col-9">
                                <Select
                                  allowClear
                                  showSearch
                                  style={{
                                    margin: "10px 0px 10px 0px",
                                    width: "100%",
                                  }}
                                  value={product_visibility_id}
                                  notFoundContent={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Icon
                                        style={{
                                          fontSize: 16,
                                          marginRight: "10px",
                                          marginBottom: "5px",
                                        }}
                                        type="search"
                                      />
                                      <p style={{ fontSize: 14 }}>
                                        Please search product visibility (Full
                                        text search)
                                      </p>
                                    </div>
                                  }
                                  onSearch={(val) =>
                                    getSearchProducVisibility(val)
                                  }
                                  onChange={(vis) =>
                                    set_product_visibility_id(vis)
                                  }
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {product_visibility_list.map((visibility) => (
                                    <Option
                                      key={visibility.id}
                                      value={visibility.id}
                                    >
                                      {visibility.name}
                                    </Option>
                                  ))}
                                </Select>
                                <span className="form-text text-muted">
                                  Please Select Visibility Setting
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Category
                              </label>
                              <div className="col-9">
                                <Select
                                  allowClear
                                  showSearch
                                  style={{
                                    margin: "10px 0px 10px 0px",
                                    width: "100%",
                                  }}
                                  value={category_id}
                                  notFoundContent={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Icon
                                        style={{
                                          fontSize: 16,
                                          marginRight: "10px",
                                          marginBottom: "5px",
                                        }}
                                        type="search"
                                      />
                                      <p style={{ fontSize: 14 }}>
                                        Please search product Category
                                      </p>
                                    </div>
                                  }
                                  onSearch={(val) => getSearchCategories(val)}
                                  onChange={(cat) => set_category_id(cat)}
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {category_list.map((category) => (
                                    <Option
                                      key={category.id}
                                      value={category.id}
                                    >
                                      {category.name}
                                    </Option>
                                  ))}
                                </Select>
                                <span className="form-text text-muted">
                                  Please Select Category
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Program
                              </label>
                              <div className="col-9">
                                <Select
                                  mode="multiple"
                                  allowClear
                                  showSearch
                                  style={{
                                    margin: "10px 0px 10px 0px",
                                    width: "100%",
                                  }}
                                  value={program_id}
                                  notFoundContent={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Icon
                                        style={{
                                          fontSize: 16,
                                          marginRight: "10px",
                                          marginBottom: "5px",
                                        }}
                                        type="search"
                                      />
                                      <p style={{ fontSize: 14 }}>
                                        Please search Program
                                      </p>
                                    </div>
                                  }
                                  onSearch={(val) => getSearchPrograms(val)}
                                  onChange={(prog) => set_program_id(prog)}
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {program_list.map((program) => (
                                    <Option key={program.id} value={program.id}>
                                      {program.name}
                                    </Option>
                                  ))}
                                </Select>
                                <span className="form-text text-muted">
                                  Please Select program
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Required
                              </label>
                              <div className="col-9">
                                <Checkbox
                                  checked={is_required}
                                  onChange={(event) =>
                                    set_is_required(event.target.checked)
                                  }
                                  className="form-control"
                                  style={{ border: "0px" }}
                                />
                                <span className="form-text text-muted">
                                  Please check if selection is required
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Multiple Selection
                              </label>
                              <div className="col-9">
                                <Checkbox
                                  checked={multi_select}
                                  onChange={(event) =>
                                    set_multi_select(event.target.checked)
                                  }
                                  className="form-control"
                                  style={{ border: "0px" }}
                                />
                                <span className="form-text text-muted">
                                  Please check if selection is required
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Section Order
                              </label>
                              <div className="col-9">
                                <Input
                                  maxLength={3}
                                  value={section_order}
                                  onChange={(event) =>
                                    set_section_order(event.target.value)
                                  }
                                  className="form-control"
                                  type="number"
                                />
                                <span className="form-text text-muted">
                                  <p>Please Enter Section Order</p>
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Minimum Selection
                              </label>
                              <div className="col-9">
                                <Input
                                  maxLength={3}
                                  value={minimum_selection}
                                  onChange={(event) =>
                                    set_minimum_selection(event.target.value)
                                  }
                                  className="form-control"
                                  type="number"
                                />
                                <span className="form-text text-muted">
                                  <p>Please Enter Minimum Selection</p>
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-3 col-form-label">
                                Maximum Selection
                              </label>
                              <div className="col-9">
                                <Input
                                  maxLength={3}
                                  value={maximum_selection}
                                  onChange={(event) =>
                                    set_maximum_selection(event.target.value)
                                  }
                                  className="form-control"
                                  type="number"
                                />
                                <span className="form-text text-muted">
                                  <p>Please Enter Maximum Selection</p>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div >
    </div >
  );
}
