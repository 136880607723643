import React from 'react';

import Content from './Content';
// import '../../assets/js/demo1/pages/crud/datatables/search-options/column-search.js';


export default class Category extends React.Component {



    render() {
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <Content />
            </div>
        )
    }
}