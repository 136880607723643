import React from 'react';
import SideMenu from '../Shared/SideMenu';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

import Content from './Content';
// import '../../assets/js/demo1/pages/crud/datatables/search-options/column-search.js';


export default class MerchantTagInfo extends React.Component {



    render() {
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <Content />
            </div>
        )
    }
}