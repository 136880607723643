import React from 'react';

import Content from './Content'



export default class Dashboard extends React.Component {





    render() {


        return (
            <div>
                <Content />
            </div>
        )
    }
}