import React from 'react';
import {
    Select,
    Spin,
    Icon,
    Input,
} from 'antd'

import { errorNotification, errorToaster, successNotification } from '../Shared/Notifications'

import MerchantModel from '../../Models/MerchantModel';
import AddonModel from '../../Models/AddonModel';
import ItemModel from '../../Models/ItemModel';
import { validateNumber } from '../../Helpers/Shared/Validators';

const { Option } = Select;

export default class EditAddonSet extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: false,
            merchant: null,
            merchantError: false,
            merchantList: [],
            outlets: [],
            outletList: [],
            outletsError: false,
            name: '',
            nameError: false,
            arabic_name: '',
            arabic_nameError: false,
            english_description: '',
            englishdescriptionError: false,
            arabic_description: '',
            arabic_descriptionError: false,
            position: '',
            positionError: false,
            min_selection: '',
            minSelectionError: false,
            max_selection: '',
            maxSelectionError: false,
        }
    }

    componentWillMount(){
        const role_id = localStorage.getItem("user_role")
        switch (Number(role_id)) {
            case 1:
                break;
             case 5:
                 window.location.href = '/order-report'
                 break;
             default:
                window.location.href = '/login'
                break;
        }
     }

    componentDidMount() {
        let it = this;
        const { match: { params } } = this.props;
        this.setState({ addonSetId: params.addonSetId })
        AddonModel.getInstance().getAddonSetDetail(params.addonSetId,
            async function success(data) {
                it.setState({
                    name: data.name,
                    arabic_name: data.arabic_name,
                    outlets: data.outlets.map(outlet => { return outlet.id }),
                    english_description : data.description,
                    arabic_description : data.arabic_description,
                    position : data.position,
                    min_selection : data.min,
                    max_selection : data.max,

                })
                if (data.merchants[0]) {
                    it.getMerchants(data.merchants[0].name)
                    it.setState({ merchant: data.merchants[0].id })
                    it.getOutlets(data.merchants[0].id)
                }
            },
            function failure(error) {
                console.error(error)
            }
        )
    }
    debounce(callback, wait) {
        let timeout;
        return (...args) => {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => callback.apply(context, args), wait);
        };
    }

    searchQueryMerchant = this.debounce(function (value, it) {
        it.getMerchants(value);
    }, 1000);


    getMerchants(value) {
        if (value.length > 0) {
            let it = this;
            let params = {
                query: encodeURIComponent(value)
            }
            this.setState({ loading: true })
            MerchantModel.getInstance().getMerchant(params,
                function success(data) {
                    it.setState({
                        loading: false,
                        merchantList: data.data
                    })
                },
                function failure(error) {
                    errorNotification(error)
                    it.setState({ loading: false })

                }
            )
        }

    }
    getOutlets(merchants) {
        this.setState({
            loading: true,
            merchant: merchants
        })
        let it = this;
        MerchantModel.getInstance().getMerchantOutlet(merchants,
            function success(data) {
                it.setState({
                    outletList: data,
                    loading: false
                })

            },
            function failure(error) {
                it.setState({ loading: false })
                errorNotification(error)
                console.log(error)
            }
        )
    }

    sendData() {
        const { addonSetId, merchant, outlets, name, arabic_name, english_description, arabic_description, position, max_selection, min_selection  } = this.state;
        let it = this;
        let params = {};
        const text = 'Validation Error! Please check fields';
        this.setState({
            merchantError: false,
            outletsError: false,
            nameError: false,
            arabic_nameError: false,
            english_descriptionError: false,
            positionError: false,
            minSelectionError: false,
            maxSelectionError: false,
            itemError : false

        })

        
        if (name.length < 1) {
            this.setState({ nameError: true });
            errorToaster("Please enter name");
            return;
        }
        params.name = name;
        params.arabic_name = arabic_name;

        if (english_description.length < 1) {
            this.setState({ english_descriptionError: true });
            errorToaster("Please enter description");
            return;
        }
        params.description = english_description;

        params.arabic_description = arabic_description;


        if (!merchant) {
            this.setState({ merchantError: true });
            errorToaster("Please select merchant");
            return;
        }
        params.merchant_id = [merchant];

        if (outlets.length < 1) {
            this.setState({ outletsError: true });
            errorToaster("Please select outlets");
            return;
        }
        params.outlet_id = outlets;

        if (!validateNumber(position)) {
            this.setState({ positionError: true });
            errorToaster("Enter position with only numbers");
            return;
        }
        else {
            if (Number(position < 1)) {
                this.setState({ positionError: true });
                errorToaster("Enter position must be greater than zero");
                return;
            }
        }
        params.position = position;

        if (!validateNumber(max_selection)) {
            this.setState({ maxSelectionError: true });
            errorToaster(text);
            return;
        }
        params.max = max_selection;

        if (!validateNumber(min_selection)) {
            this.setState({ minSelectionError: true });
            errorToaster(text);
            return;
        }
        params.min = min_selection;



        it.setState({ loading: true })
        AddonModel.getInstance().updateAddonSet(addonSetId,params,
            function success(data) {
                successNotification('Addon Sets updated Successfully')
                setTimeout(() => {
                    window.location.href = "/addon-set"
                }, 1000);

            },
            function failure(error) {
                it.setState({ loading: false })
                errorNotification(error)
            }
        )

    }


    render() {
        const { merchantList, merchantError, outletList, outletsError, nameError, arabic_nameError, english_descriptionError, arabic_descriptionError, positionError, minSelectionError, maxSelectionError } = this.state
        return (
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <Spin spinning={this.state.loading}>
                    <div className="kt-content  kt-grid__item kt-grid__item--fluid" >

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile" >
                                    <div className="kt-portlet__head kt-portlet__head--lg">
                                        <div className="kt-portlet__head-label">
                                            <h3 className="kt-portlet__head-title">Edit Addon Sets </h3>
                                        </div>
                                        <div className="kt-portlet__head-toolbar">
                                            <a href="/addon-set" className="btn btn-clean kt-margin-r-10">
                                                <i className="la la-arrow-left"></i>
                                                <span className="kt-hidden-mobile">Back</span>
                                            </a>
                                            <div className="btn-group">
                                                <button onClick={() => this.sendData()} type="button" className="btn btn-brand">
                                                    <i className="la la-check"></i>
                                                    <span className="kt-hidden-mobile">Save</span>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                    {/* end header */}
                                    <div className="kt-portlet__body">
                                        <form className="kt-form" id="kt_form">
                                            <div className="row">
                                                <div className="col-xl-2"></div>
                                                <div className="col-xl-8">

                                                    <div className="kt-section kt-section--first">

                                                        <div className="kt-section__body">

                                                            
                                                        <div className="form-group row">
                                                                <label className="col-3 col-form-label">Name <b>*</b></label>
                                                                <div className="col-9">
                                                                    <input value = {this.state.name} onChange={(event) => this.setState({ name: event.target.value })} maxLength="100" className="form-control" type="text" />
                                                                    <span className="form-text text-muted"><p style={{ color: nameError ? 'red' : '' }} >Please enter Name </p></span>

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Arabic Name</label>
                                                                <div className="col-9">
                                                                    <input value = {this.state.arabic_name} onChange={(event) => this.setState({ arabic_name: event.target.value })} maxLength="100" className="form-control" type="text" />
                                                                    <span className="form-text text-muted"><p style={{ color: arabic_nameError ? 'red' : '' }} >Please enter name in Arabic</p></span>

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label" htmlFor="exampleTextarea">Description in English <b>*</b> </label>
                                                                <div className="col-9">
                                                                    <div className="input-group">
                                                                        <textarea value = {this.state.english_description} maxLength="150" onChange={(event) => this.setState({ english_description: event.target.value })} className="form-control" rows="3"></textarea>
                                                                    </div>
                                                                    <span className="form-text text-muted"><p style={{ color: english_descriptionError ? 'red' : '' }} >Please enter description </p></span>
                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label" htmlFor="exampleTextarea">Description in Arabic </label>
                                                                <div className="col-9">
                                                                    <div className="input-group">
                                                                        <textarea value = {this.state.arabic_description} maxLength="150" onChange={(event) => this.setState({ arabic_description: event.target.value })} className="form-control" rows="3"></textarea>
                                                                    </div>
                                                                    <span className="form-text text-muted"><p style={{ color: arabic_descriptionError ? 'red' : '' }} >Please enter description </p></span>
                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Merchant <b>*</b></label>
                                                                <div className="col-9">
                                                                    <Select
                                                                        showSearch
                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                        value = {this.state.merchant}
                                                                        notFoundContent={
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Icon style={{ fontSize: 16, marginRight: '10px', marginBottom: '5px' }} type="search" />
                                                                                <p style={{ fontSize: 14 }}>Please Search Merchant</p>
                                                                            </div>
                                                                        }
                                                                        onSearch={(val) => this.searchQueryMerchant(val, this)}
                                                                        onChange={(merchant) => {
                                                                            this.getOutlets(merchant)
                                                                            this.setState({ outlets : [], item : [] })
                                                                        }}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {merchantList.map((merchant) => (
                                                                            <Option key={merchant.id} value={merchant.id} >{merchant.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                    <span className="form-text text-muted"><p style={{ color: merchantError ? 'red' : '' }}>Please Select Merchant</p></span>

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Outlets <b>*</b></label>
                                                                <div className="col-9">
                                                                    <Select
                                                                        showSearch
                                                                        mode="multiple"
                                                                        style={{ margin: '10px 0px 10px 0px' }}
                                                                        value={this.state.outlets}
                                                                        onChange={(outlets) => {
                                                                            this.setState({ outlets  })
                                                                        }}
                                                                        filterOption={(input, option) =>
                                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {outletList.map((outlet) => (
                                                                            <Option key={outlet.id} value={outlet.id} >{outlet.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                    <span className="form-text text-muted"><p style={{ color: outletsError ? 'red' : '' }}>Please Select Outlets</p></span>

                                                                </div>
                                                            </div>


                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Position <b>*</b></label>
                                                                <div className="col-9">
                                                                    <input value = {this.state.position} onChange={(event) => this.setState({ position: event.target.value })} min="1" className="form-control" type="number" />
                                                                    <span className="form-text text-muted"><p style={{ color: positionError ? 'red' : '' }} >Please enter Position</p></span>

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Minimum Selection <b>*</b></label>
                                                                <div className="col-9">
                                                                    <input value = {this.state.min_selection} onChange={(event) => this.setState({ min_selection: event.target.value })} min="0" className="form-control" type="number" />
                                                                    <span className="form-text text-muted"><p style={{ color: minSelectionError ? 'red' : '' }} >Please enter minimum selection</p></span>

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label className="col-3 col-form-label">Maximum Selection <b>*</b></label>
                                                                <div className="col-9">
                                                                    <input value = {this.state.max_selection} onChange={(event) => this.setState({ max_selection: event.target.value })} min="0" className="form-control" type="number" />
                                                                    <span className="form-text text-muted"><p style={{ color: maxSelectionError ? 'red' : '' }} >Please enter maximum selection</p></span>

                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2"></div>

                                            </div>
                                        </form>
                                    </div>
                                    {/* end body */}
                                </div>
                            </div>
                        </div>

                    </div>
                </Spin>
            </div>

        );
    }
}