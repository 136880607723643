import React from "react";
import { Link } from "react-router-dom";
import { Icon, Popconfirm, Switch, Select, Spin } from "antd";
import _ from "lodash";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { errorNotification, errorToaster } from "../Shared/Notifications";

import CouponModel from "../../Models/CouponModel";
import { tableHeaderStyles } from "styles/tableStyles";
import { gridHeaderWithButton } from "styles/gridHeader";

const { Option } = Select;

export default class Content extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      countryList: [],
      loading: false,
      filters: [],
      pageCount: 10,
      offset: 0,
      total_pages: 1,
      currentPage: 1,
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  createPagination(total_count) {
    this.setState({
      total_pages: Math.ceil(total_count / this.state.pageCount),
    });
  }

  componentDidMount() {
    this.getBankBins();
  }

  getBankBins() {
    const it = this;
    it.setState({ loading: true });
    console.log("filters:", this.state.filters);
    let params = {
      offset: (this.state.currentPage - 1) * this.state.pageCount,
      limit: this.state.pageCount,
    };
    this.state.filters.forEach((filter, i) => {
      params[filter.id] = filter.value.toLowerCase();
    });
    switch (params["is_active"]) {
      case "all":
        delete params["is_active"];
        break;

      case "true":
        params["is_active"] = true;
        break;

      case "false":
        params["is_active"] = false;
        break;

      default:
        break;
    }
    CouponModel.getInstance().getBankBins(
      params,
      function success(data) {
        it.setState({
          data: CouponModel.BankBinList,
          loading: false,
        });
        it.createPagination(data.count);
      },
      function Failure(error) {
        it.setState({ loading: false });
        errorToaster("Request information not found");
      }
    );
  }

  updateBankBin(bankBinId, is_active) {
    const it = this;
    it.setState({ loading: true });
    const params = {
      is_active: !is_active,
    };
    CouponModel.getInstance().updateBankBins(
      bankBinId,
      params,
      function success() {
        it.setState({
          data: CouponModel.BankBinList,
          loading: false,
        });
      },
      function failure(error) {
        errorNotification(error);
        it.setState({ loading: false });
        console.log(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  searchQuery = this.debounce(function (it) {
    it.getBankBins();
  }, 1000);

  render() {
    const { data } = this.state;
    return (
      <div className="kt-content  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head kt-portlet__head--lg">
            {gridHeaderWithButton(
              "Bank-bins",
              "la la-plus",
              "Add New Bank-bin",
              "bank-bin/add"
            )}
          </div>
          <div className="kt-portlet__body">
            <Spin spinning={this.state.loading}>
              {/* react-table */}
              <ReactTable
                data={data}
                filterable
                pageSize={this.state.pageCount}
                showPageSizeOptions={false}
                manual
                page={this.state.currentPage - 1}
                pages={this.state.total_pages}
                filtered={this.state.filters}
                onPageChange={(pageIndex) => {
                  this.setState({ currentPage: pageIndex + 1 });
                  setTimeout(() => {
                    this.getBankBins();
                  }, 50);
                }}
                onFilteredChange={(filters) => {
                  this.setState({ currentPage: 1, filters });
                  this.searchQuery(this);
                }}
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={[
                  {
                    Header: <p style={tableHeaderStyles()}>Bank Name</p>,
                    id: "bank_name",
                    accessor: (d) => d.bank_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["name"] }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>BIN</p>,
                    id: "bin",
                    accessor: (d) => d.bin,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["arabic_name"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Product Name</p>,
                    id: "product_name",
                    accessor: (d) => d.product_name,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["product_name"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Product Type</p>,
                    id: "product_type",
                    accessor: (d) => d.product_type,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["product_type"],
                      }),
                    filterAll: true,
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Status</p>,
                    id: "is_active",
                    accessor: "is_active",
                    Cell: ({ value }) =>
                      value ? (
                        <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill">
                          Active
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          De-Activate
                        </span>
                      ),
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "true") {
                        return row[filter.id] === true;
                      }
                      return row[filter.id] === false;
                    },
                    Filter: ({ filter, onChange }) => (
                      <Select
                        onChange={(value) => onChange(value)}
                        style={{ width: "100%" }}
                        defaultValue="all"
                        value={filter ? filter.value : "all"}
                      >
                        <Option value="all">Show All</Option>
                        <Option value="true">Active</Option>
                        <Option value="false">De-Activate</Option>
                      </Select>
                    ),
                  },
                  {
                    Header: <p style={tableHeaderStyles()}>Action</p>,
                    id: "actions",
                    width: 150,
                    accessor: (d) => (
                      <div>
                        {/* <Link
                                                    to={`city/edit/${d.id}`}
                                                    style={{ marginRight: 20 }}
                                                >
                                                    <Icon style={{ color: '#5D78FF', fontSize: 16 }} type="edit" />
                                                </Link> */}
                        <Popconfirm
                          title={
                            d.is_active
                              ? "Are you sure you want to DEACTIVATE this Bin"
                              : "Are you sure you want to ACTIVATE this Bin"
                          }
                          icon={
                            <Icon
                              type="question-circle-o"
                              style={{ color: "red" }}
                            />
                          }
                          onConfirm={() =>
                            this.updateBankBin(d.id, d.is_active)
                          }
                        >
                          <Switch
                            style={{
                              backgroundColor: d.is_active
                                ? "#5d78ff"
                                : "#fd397a",
                              marginLeft: 15,
                            }}
                            checked={d.is_active}
                            onChange={(val) => console.log(val)}
                          />
                        </Popconfirm>
                      </div>
                    ),
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}
