import React from "react";
import { Select, Spin, Icon, DatePicker, Button, Popconfirm, Tabs } from "antd";

import {
  errorNotification,
  successNotification,
  errorToaster,
} from "../Shared/Notifications";
import { getCompressedImage } from "Helpers/Shared/utils";
import MultipleImages from "./MultipleImages";

import backImage from "../../../src/assets/media/users/default.jpg";
import UserModel from "../../Models/UserModel";
import MerchantModel from "../../Models/MerchantModel";
import { validatePhone, validateURL } from "../../Helpers/Shared/Validators";
import moment from "moment";
import ProductOfferingModel from "../../Models/ProductOfferingModel";
import ContactPocView from "../Shared/ContactPocView";
import ProductOnlineDeals from "../ProductOnlineDeals";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const contract_type = {
  contract_type: null,
  start_date: undefined,
  end_date: undefined,
  status: true,
};
const contract_type_list = [
  {
    name: "Free",
    value: "free",
  },
  {
    name: "Sponsored",
    value: "sponsored",
  },
  {
    name: "Paid",
    value: "paid",
  },
];

export default class EditMerchant extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      merchantId: null,
      name: "",
      nameError: false,
      arabic_name: "",
      arabic_nameError: false,
      category: null,
      categoryError: false,
      sub_category: [],
      sub_categoryError: false,
      logoImage: null,
      logoImageSrc: null,
      logoImageError: false,
      short_description: "",
      short_descriptionError: false,
      arabic_short_description: "",
      arabic_short_descriptionError: false,
      long_description: "",
      long_descriptionError: false,
      arabic_long_description: "",
      arabic_long_descriptionError: false,
      code: "",
      merchant_group: null,
      categoryList: [],
      sub_categoryList: [],
      merchant_groupList: [],
      merchantTypeList: [],
      businessType: null,
      personName: "",
      personPhone: "",
      cuisinesList: [],
      infoTagList: [],
      paymentList: [],
      menusList: [],
      imageList: [],
      backImageLoading: false,
      menuImageLoading: false,
      infoTag: [],
      payment: [],
      cuisine: [],
      averageCost: "",
      search_keyword: "",
      merchantTagList: [],
      merchantTag: null,
      merchantTagError: false,
      merchant_contracts: [],
      merchant_contractsError: false,
      deleted_contracts: [],
      merchant_url: '',

      activeKey: "1",
    };
  }

  componentWillMount() {
    const role_id = localStorage.getItem("user_role");
    switch (Number(role_id)) {
      case 1:
        break;
      case 5:
        window.location.href = "/order-report";
        break;
      default:
        window.location.href = "/login";
        break;
    }
  }

  async componentDidMount() {
    const it = this;
    const {
      match: { params },
    } = this.props;
    this.getProductOfferingPocsByMerchant(params.merchantId);
    this.setState({
      merchantId: params.merchantId,
      loading: true,
    });

    await MerchantModel.getInstance().getMerchantDetail(
      params.merchantId,
      function success(data) {
        it.setState({
          name: data.name,
          code: data.code,
          arabic_name: data.arabic_name ? data.arabic_name : "",
          merchant_url: data.merchant_url ? data.merchant_url : "",
          long_description: data.description,
          arabic_long_description: data.arabic_description
            ? data.arabic_description
            : "",
          short_description: data.short_description
            ? data.short_description
            : "",
          arabic_short_description: data.arabic_short_description
            ? data.arabic_short_description
            : "",
          serverLogoSrc: data.logo,
          merchant_group: data.groups.length > 0 ? data.groups[0].id : null,
          businessType: data.type_id ? data.type_id : null,
          menusList: data.menus
            ? data.menus.map((img) => {
              return { uid: img, url: img };
            })
            : [],
          imageList: data.media
            ? data.media.map((img) => {
              return { uid: img, url: img };
            })
            : [],
          cuisine: data.cuisines.map((cuis) => {
            return cuis.id;
          }),
          infoTag: data.info_tags.map((tag) => {
            return tag.id;
          }),
          personName: data.primary_contact_person,
          personPhone: data.primary_contact_number,
          payment: data.payment_modes.map((mode) => {
            return mode.id;
          }),
        });
        if (data.merchant_contracts && data.merchant_contracts.length > 0) {
          const merchant_contracts = data.merchant_contracts.map((cont) => {
            return {
              id: cont.id,
              contract_type: cont.contract_type,
              start_date: moment(cont.start_date),
              end_date: moment(cont.end_date),
              status: false,
            };
          });
          it.setState({ merchant_contracts });
        } else {
          it.addContract();
        }
        if (data.groups.length > 0) {
          it.getMerchantGroup(data.groups[0].name);
        }
        if (data.tags.length > 0) {
          it.getMerchantTag(data.tags[0].name);
          it.setState({ merchantTag: data.tags[0].id });
        }
        let sub_category = [];
        data.categories.forEach((cate) => {
          if (!cate.parent_id) {
            it.setState({ category: cate.id });
            it.getSubCategory(cate.id);
          } else {
            sub_category.push(cate.id);
          }
        });
        it.setState({ sub_category });
      },
      function failure(error) {
        console.error(error);
      }
    );

    UserModel.getInstance().getCategory(
      { parent: true },
      function success(data) {
        console.info(data);
        it.setState({
          categoryList: data.data,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );

    await UserModel.getInstance().getPayment(
      null,
      function success() {
        it.setState({
          paymentList: UserModel.getInstance().PaymentList,
          // loading: false
        });
      },
      function failure(msg) {
        console.error(msg);
        // it.setState({ loading: false })
      }
    );

    await UserModel.getInstance().getCuisine(
      function success() {
        it.setState({
          cuisinesList: UserModel.getInstance().CuisinesList,
          loading: false,
        });
      },
      function failure(msg) {
        console.error(msg);
        it.setState({ loading: false });
      }
    );

    this.getMerchantType();
    this.getMerchantInfoTag();
  }

  getProductOfferingPocsByMerchant(merchant_id) {
    const it = this;
    ProductOfferingModel.getInstance().getProductOfferingPocsByMerchant(
      merchant_id,
      function success(data) {
        it.setState({ productOfferingList: data.records });
      },
      function failure(err) {
        console.error(err);
      }
    );
  }

  getMerchantInfoTag() {
    let it = this;
    UserModel.getInstance().getMerchantInfoTag(
      null,
      function success() {
        it.setState({
          infoTagList: UserModel.getInstance().MerchantInfoTagList,
          // loading: false
        });
      },
      function failure(msg) {
        console.error(msg);
        errorToaster(msg);
        // it.setState({ loading: false })
      }
    );
  }

  searchQueryMerchantGroup = this.debounce(function (value, it) {
    it.getMerchantGroup(value);
  }, 1000);

  getMerchantGroup(query) {
    const it = this;
    let params = {
      query: encodeURIComponent(query),
    };
    UserModel.getInstance().getMerchantGroup(
      params,
      function success() {
        it.setState({
          merchant_groupList: UserModel.getInstance().MerchantGroupList,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorToaster(error);
      }
    );
  }
  getMerchantType() {
    const it = this;
    MerchantModel.getInstance().getMerchantType(
      function success() {
        it.setState({
          merchantTypeList: MerchantModel.getInstance().MerchantTypeList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }
  getSubCategory(category) {
    this.setState({ loading: true });
    const it = this;
    let params = {
      parent: true,
      id: category,
    };
    UserModel.getInstance().getCategory(
      params,
      function success(data) {
        it.setState({
          sub_categoryList: data.data,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  searchQueryMerchantTag = this.debounce(function (value, it) {
    it.getMerchantTag(value);
  }, 1000);

  getMerchantTag(query) {
    this.setState({ loading: true });
    const it = this;
    let params = {
      query: encodeURIComponent(query),
    };
    UserModel.getInstance().getMerchantTag(
      params,
      function success() {
        it.setState({
          merchantTagList: UserModel.getInstance().MerchantTagList,
          loading: false,
        });
      },
      function failure(error) {
        it.setState({ loading: false });
        errorToaster(error);
      }
    );
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }

  saveLogo(event) {
    let it = this;
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ loading: true });
    getCompressedImage(file)
      .then((compressedImage) => {
        if (compressedImage) {
          UserModel.getInstance().addMedia(
            compressedImage,
            function success(image) {
              it.setState({
                logoImage: image,
                logoImageSrc: image,
                loading: false,
                serverLogoSrc: image,
              });
            },
            function failure(error) {
              it.setState({ loading: false });
              errorNotification(error);
            }
          );
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
        errorNotification(e);
      });
  }

  sendBackImages(image) {
    const it = this;
    it.setState({ backImageLoading: true });
    let arr = this.state.imageList;
    UserModel.getInstance().addMedia(
      image,
      function success(url) {
        let img = {
          uid: url,
          url: url,
        };
        arr.push(img);
        it.setState({
          imageList: arr,
          backImageLoading: false,
        });
      },
      function failure() {
        it.setState({ backImageLoading: false });
      }
    );
  }

  removeBackImage(image) {
    const it = this;
    it.setState({ backImageLoading: true });
    let arr = this.state.imageList;
    let { url } = image;
    UserModel.getInstance().removeMedia(
      url,
      async function success() {
        it.setState({ backImageLoading: false });
        await arr.forEach((img, key) => {
          if (img.uid === image.uid) {
            arr.splice(key, 1);
          }
        });
        await it.setState({ imageList: arr });
      },
      function failure(error) {
        console.error(error);
        it.setState({ backImageLoading: false });
      }
    );
  }

  sendMenuImages(image) {
    const it = this;
    it.setState({ menuImageLoading: true });
    let arr = this.state.menusList;
    UserModel.getInstance().addMedia(
      image,
      function success(url) {
        let img = {
          uid: url,
          url: url,
        };
        arr.push(img);
        it.setState({
          menusList: arr,
          menuImageLoading: false,
        });
      },
      function failure() {
        it.setState({ menuImageLoading: false });
      }
    );
  }

  removeMenuImage(image) {
    const it = this;
    it.setState({ menuImageLoading: true });
    let arr = this.state.menusList;
    let { url } = image;
    UserModel.getInstance().removeMedia(
      url,
      async function success() {
        it.setState({ menuImageLoading: false });
        await arr.forEach((img, key) => {
          if (img.uid === image.uid) {
            arr.splice(key, 1);
          }
        });
        await it.setState({ menusList: arr });
      },
      function failure(error) {
        console.error(error);
        it.setState({ menuImageLoading: false });
      }
    );
  }

  sendData() {
    let {
      merchantId,
      serverLogoSrc,
      name,
      arabic_name,
      category,
      sub_category,
      merchant_group,
      short_description,
      arabic_short_description,
      long_description,
      arabic_long_description,
      businessType,
      personName,
      personPhone,
      search_keyword,
      imageList,
      infoTag,
      payment,
      cuisine,
      merchant_url,
      menusList,
      merchantTag,
    } = this.state;
    let it = this;
    const text = "Validation Error! Please check fields";
    let keywords = [];
    let tempArr = [];
    search_keyword &&
      (search_keyword.includes(",")
        ? (keywords = search_keyword.split(","))
        : (keywords = [search_keyword]));
    keywords.forEach((string, index) => {
      let tempstring = string.trim();
      if (tempstring !== "") {
        tempArr.push(tempstring);
      }
    });
    keywords = tempArr;
    if (name.length < 1) {
      this.setState({ nameError: true, activeKey: "1" });
      errorToaster(text);
      return;
    }
    if (!validateURL(merchant_url)) {
      errorToaster('Please Enter Valid URL');
      return;
    }
    if (arabic_name.length < 1) {
      arabic_name = " ";
    }
    if (!category) {
      this.setState({ categoryError: true, activeKey: "1" });
      errorToaster(text);
      return;
    }
    if (!sub_category) {
      this.setState({ sub_categoryError: true, activeKey: "1" });
      errorToaster(text);
      return;
    }
    let _break = false;
    for (
      let _index = 0;
      _index < this.state.merchant_contracts.length;
      _index++
    ) {
      const cont = this.state.merchant_contracts[_index];
      if (
        !cont.contract_type ||
        !cont.start_date ||
        !cont.start_date._isAMomentObject ||
        !cont.end_date ||
        !cont.end_date._isAMomentObject
      ) {
        _break = true;
        this.setState({ merchant_contractsError: true, activeKey: "1" });
        errorToaster(text);
        break;
      }
    }
    if (_break) {
      return;
    }
    if (long_description.length < 1) {
      this.setState({ long_descriptionError: true, activeKey: "1" });
      errorToaster(text);
      return;
    }
    if (personPhone) {
      if (!validatePhone(personPhone)) {
        this.setState({ personPhoneError: true, activeKey: "1" });
        errorToaster("Please Enter Valid Phone Number");
        return;
      }
    }

    let menus = [];
    let media = [];
    menusList.forEach((image) => {
      menus.push(image.url);
    });
    imageList.forEach((image) => {
      media.push(image.url);
    });
    let category_id = [];
    category_id.push(category);
    category_id = category_id.concat(sub_category);

    const merchant_contracts = this.state.merchant_contracts.map((cont) => {
      return {
        ...cont,
        start_date: moment(cont.start_date)
          .startOf("day")
          .format("YYYY-MM-DD hh:mm:ss"),
        end_date: moment(cont.end_date)
          .endOf("day")
          .format("YYYY-MM-DD hh:mm:ss"),
      };
    });

    let params = {
      name,
      arabic_name,
      merchant_url,
      arabic_description: arabic_long_description,
      category_id,
      group_id: merchant_group ? [merchant_group] : [],
      type_id: businessType,
      tag_id: merchantTag ? [merchantTag] : [],
      short_description,
      arabic_short_description,
      description: long_description,
      primary_contact_person: personName,
      primary_contact_number: personPhone,
      logo: serverLogoSrc,
      menus,
      media,
      payment_mode_id: payment,
      cuisines_id: cuisine,
      info_tag_id: infoTag,
      merchant_contracts,
      deleted_contracts: this.state.deleted_contracts,
    };
    it.setState({ loading: true });
    it.updateMerchant(merchantId, params);
  }

  updateMerchant(merchantId, params) {
    let it = this;
    MerchantModel.getInstance().updateMerchant(
      merchantId,
      params,
      function success(data) {
        successNotification("Merchant Updated Successfully");
        setTimeout(() => {
          window.location.href = "/merchant";
        }, 1000);
      },
      function failure(error) {
        it.setState({ loading: false });
        errorNotification(error);
      }
    );
  }

  changeContractType(value, index) {
    let { merchant_contracts } = this.state;
    merchant_contracts[index].status = true;
    merchant_contracts[index].contract_type = value;
    this.setState({ merchant_contracts });
  }

  updateContractDates(start_date, end_date, index) {
    let { merchant_contracts } = this.state;
    merchant_contracts[index].status = true;
    merchant_contracts[index].start_date = start_date;
    merchant_contracts[index].end_date = end_date;
    this.setState({ merchant_contracts });
  }

  addContract() {
    let { merchant_contracts } = this.state;
    merchant_contracts.push({ ...contract_type });
    this.setState({ merchant_contracts });
  }
  removeContract(index, contract_id) {
    let { merchant_contracts, deleted_contracts } = this.state;
    merchant_contracts.splice(index, 1);
    if (contract_id) {
      deleted_contracts.push(contract_id);
    }
    this.setState({ merchant_contracts });
  }

  disabledDate(current, index) {
    const disabledDates = [...this.state.merchant_contracts];
    let isDisabled = false;
    disabledDates.splice(index, 1);
    for (let i = 0; i < disabledDates.length; i++) {
      if (
        moment(current).isBetween(
          disabledDates[i].start_date,
          moment(disabledDates[i].end_date).endOf("day")
        )
      ) {
        isDisabled = true;
        break;
      }
    }
    return isDisabled;
  }

  render() {
    const {
      match: { params },
    } = this.props;
    const {
      logoImageError,
      serverLogoSrc,
      codeError,
      nameError,
      arabic_nameError,
      categoryError,
      sub_categoryError,
      short_descriptionError,
      arabic_short_descriptionError,
      long_descriptionError,
      arabic_long_descriptionError,
      loading,
      categoryList,
      sub_categoryList,
      merchant_groupList,
      infoTagList,
      paymentList,
      cuisinesList,
      merchantTagList,
      merchantTagError,
    } = this.state;
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content  kt-grid__item kt-grid__item--fluid">
          <Spin spinning={loading}>
            <div className="row">
              <div className="col-lg-12">
                <div className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile">
                  <div className="kt-portlet__head kt-portlet__head--lg">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">Edit Merchant</h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                      <a
                        href="/merchant"
                        className="btn btn-clean kt-margin-r-10"
                      >
                        <i className="la la-arrow-left"></i>
                        <span className="kt-hidden-mobile">Back</span>
                      </a>
                      <div className="btn-group">
                        <button
                          onClick={() => this.sendData()}
                          type="button"
                          className="btn btn-brand"
                        >
                          <i className="la la-check"></i>
                          <span className="kt-hidden-mobile">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end header */}
                  <div className="kt-portlet__body">
                    <form className="kt-form" id="kt_form">
                      <div className="row">
                        {/* <div className="col-xl-2"></div> */}
                        <div className="col-xl-12">
                          <div className="kt-section kt-section--first">
                            <div className="kt-section__body">
                              <Tabs
                                onChange={(activeKey) =>
                                  this.setState({ activeKey })
                                }
                                activeKey={this.state.activeKey}
                                defaultActiveKey="1"
                              >
                                <TabPane tab="Overview" key="1">
                                  <div className="form-group row">
                                    <label className="col-xl-2 col-lg-3 col-form-label">
                                      Upload Logo <b>*</b>
                                    </label>
                                    <div className="col-lg-9 col-xl-6">
                                      <div
                                        className="kt-avatar kt-avatar--outline kt-avatar--circle"
                                        id="kt_apps_user_add_avatar"
                                      >
                                        <div
                                          className="kt-avatar__holder"
                                          style={{
                                            backgroundImage: `url(${this.state.logoImageSrc
                                              ? this.state.logoImageSrc
                                              : serverLogoSrc
                                                ? serverLogoSrc
                                                : backImage
                                              })`,
                                          }}
                                        ></div>
                                        <label
                                          className="kt-avatar__upload"
                                          data-toggle="kt-tooltip"
                                          title=""
                                          data-original-title="Change avatar"
                                        >
                                          <i className="fa fa-pen"></i>
                                          <input
                                            type="file"
                                            name="kt_apps_user_add_user_avatar"
                                            onChange={(event) =>
                                              this.saveLogo(event)
                                            }
                                          />
                                        </label>
                                        <span
                                          className="kt-avatar__cancel"
                                          data-toggle="kt-tooltip"
                                          title=""
                                          data-original-title="Cancel avatar"
                                        >
                                          <i className="fa fa-times"></i>
                                        </span>
                                        <p
                                          style={{
                                            color: "red",
                                            display: logoImageError
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          Please Upload Icon Image
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Code<b>*</b>
                                    </label>
                                    <div className="col-10">
                                      <input
                                        disabled
                                        onChange={(event) =>
                                          this.setState({
                                            code: event.target.value,
                                          })
                                        }
                                        value={this.state.code}
                                        className="form-control"
                                        type="text"
                                      />
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: codeError ? "red" : "",
                                          }}
                                        >
                                          Please enter your Code
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Name <b>*</b>
                                    </label>
                                    <div className="col-10">
                                      <input
                                        maxLength="70"
                                        onChange={(event) =>
                                          this.setState({
                                            name: event.target.value,
                                          })
                                        }
                                        value={this.state.name}
                                        className="form-control"
                                        type="text"
                                      />
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: nameError ? "red" : "",
                                          }}
                                        >
                                          Please enter name in English
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Arabic Name
                                    </label>
                                    <div className="col-10">
                                      <input
                                        maxLength="100"
                                        onChange={(event) =>
                                          this.setState({
                                            arabic_name: event.target.value,
                                          })
                                        }
                                        value={this.state.arabic_name}
                                        className="form-control"
                                        type="text"
                                      />
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: arabic_nameError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please enter name in Arabic
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">Merchant Url <b>*</b></label>
                                    <div className="col-10">
                                      <input value={this.state.merchant_url} maxLength="255" onChange={(event) => this.setState({ merchant_url: event.target.value, })} className="form-control" type="text" />
                                      <span className="form-text text-muted">
                                        <p style={{ color: arabic_nameError ? "red" : "", }}>Please enter merchant url</p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-2 col-form-label"
                                      htmlFor="marchantCategory"
                                    >
                                      Category <b>*</b>
                                    </label>
                                    <div className="col-10">
                                      <Select
                                        showSearch
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        // placeholder="Select a category"
                                        value={this.state.category}
                                        onChange={(value) => {
                                          this.setState({
                                            category: value,
                                            sub_category: null,
                                          });
                                          this.getSubCategory(value);
                                        }}
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {categoryList.map((item) => (
                                          <Option key={item.id} value={item.id}>
                                            {item.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: categoryError ? "red" : "",
                                          }}
                                        >
                                          Please choose category
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-2 col-form-label"
                                      htmlFor="marchantSubCategory"
                                    >
                                      Sub-category <b>*</b>
                                    </label>
                                    <div className="col-10">
                                      <Select
                                        showSearch
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        // placeholder="Select a sub-category"
                                        value={this.state.sub_category}
                                        onChange={(value) =>
                                          this.setState({ sub_category: value })
                                        }
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {sub_categoryList.map((item) => (
                                          <Option key={item.id} value={item.id}>
                                            {item.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: sub_categoryError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please choose sub-category
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-2 col-form-label"
                                      htmlFor="marchantSubCategory"
                                    >
                                      Merchant Contracts <b>*</b>
                                    </label>
                                    <div className="col-10">
                                      {this.state.merchant_contracts.map(
                                        (contract, i) => (
                                          <div
                                            key={i}
                                            style={{ display: "flex" }}
                                          >
                                            <Select
                                              showSearch
                                              value={contract.contract_type}
                                              placeholder={
                                                "Please Select Contract type"
                                              }
                                              style={{
                                                margin: "0px 10px 0px 0px",
                                              }}
                                              onChange={(value) =>
                                                this.changeContractType(
                                                  value,
                                                  i
                                                )
                                              }
                                              filterOption={(input, option) =>
                                                option.props.children
                                                  .toLowerCase()
                                                  .indexOf(
                                                    input.toLowerCase()
                                                  ) >= 0
                                              }
                                            >
                                              {contract_type_list.map((con) => (
                                                <Option
                                                  key={con.value}
                                                  value={con.value}
                                                >
                                                  {con.name}
                                                </Option>
                                              ))}
                                            </Select>

                                            <RangePicker
                                              className="form-control"
                                              disabledDate={(current) =>
                                                this.disabledDate(current, i)
                                              }
                                              value={[
                                                contract.start_date,
                                                contract.end_date,
                                              ]}
                                              style={{
                                                padding: "0px",
                                                border: "0px solid black",
                                                margin: "0px 10px 0px 0px",
                                              }}
                                              showTime={false}
                                              onChange={(value) =>
                                                this.updateContractDates(
                                                  value[0],
                                                  value[1],
                                                  i
                                                )
                                              }
                                              format="YYYY-MM-DD"
                                            />
                                            {this.state.merchant_contracts
                                              .length > 1 ? (
                                              <Popconfirm
                                                title={
                                                  contract.id
                                                    ? "Are you sure delete this contract? (This process is irreversible)"
                                                    : "Are you sure delete this contract?"
                                                }
                                                onConfirm={() =>
                                                  this.removeContract(
                                                    i,
                                                    contract.id
                                                  )
                                                }
                                                okText="Yes"
                                                cancelText="No"
                                              >
                                                <Icon
                                                  style={{
                                                    color: "red",
                                                    fontSize: 20,
                                                    marginTop: 5,
                                                  }}
                                                  type="minus-circle"
                                                  theme="filled"
                                                />
                                              </Popconfirm>
                                            ) : null}
                                          </div>
                                        )
                                      )}
                                      <Button
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        onClick={() => this.addContract()}
                                        type="primary"
                                      >
                                        Add Contract
                                      </Button>
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: this.state
                                              .merchant_contractsError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please choose merchant contracts
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-2 col-form-label"
                                      htmlFor="marchantGroup"
                                    >
                                      Merchant Group
                                    </label>
                                    <div className="col-10">
                                      <Select
                                        showSearch
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        // placeholder="Select a merchant group"
                                        value={this.state.merchant_group}
                                        onChange={(value) =>
                                          this.setState({
                                            merchant_group: value,
                                          })
                                        }
                                        notFoundContent={
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Icon
                                              style={{
                                                fontSize: 16,
                                                marginRight: "10px",
                                                marginBottom: "5px",
                                              }}
                                              type="search"
                                            />
                                            <p style={{ fontSize: 14 }}>
                                              Please Search Merchant Group
                                            </p>
                                          </div>
                                        }
                                        onSearch={(val) =>
                                          this.searchQueryMerchantGroup(
                                            val,
                                            this
                                          )
                                        }
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {merchant_groupList.map((item) => (
                                          <Option key={item.id} value={item.id}>
                                            {item.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <span className="form-text text-muted">
                                        Please choose merchant group
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-2 col-form-label"
                                      htmlFor="marchantGroup"
                                    >
                                      Merchant Tag
                                    </label>
                                    <div className="col-10">
                                      <Select
                                        showSearch
                                        // mode="multiple"
                                        value={this.state.merchantTag}
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        allowClear
                                        // placeholder="Select a merchant group"
                                        onChange={(value) =>
                                          this.setState({ merchantTag: value })
                                        }
                                        notFoundContent={
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Icon
                                              style={{
                                                fontSize: 16,
                                                marginRight: "10px",
                                                marginBottom: "5px",
                                              }}
                                              type="search"
                                            />
                                            <p style={{ fontSize: 14 }}>
                                              Please Search Merchant Tag
                                            </p>
                                          </div>
                                        }
                                        onSearch={(val) =>
                                          this.searchQueryMerchantTag(val, this)
                                        }
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {merchantTagList.map((tag) => (
                                          <Option
                                            disabled={!tag.is_active}
                                            key={tag.id}
                                            value={tag.id}
                                          >
                                            {tag.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: merchantTagError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please choose Merchant Tag
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-2 col-form-label"
                                      htmlFor="marchantGroup"
                                    >
                                      Business Type
                                    </label>
                                    <div className="col-10">
                                      <Select
                                        showSearch
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        // placeholder="Select a merchant group"
                                        value={this.state.businessType}
                                        onChange={(value) =>
                                          this.setState({ businessType: value })
                                        }
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {this.state.merchantTypeList.map(
                                          (type) => (
                                            <Option
                                              key={type.id}
                                              value={type.id}
                                            >
                                              {type.name}
                                            </Option>
                                          )
                                        )}
                                      </Select>
                                      <span className="form-text text-muted">
                                        Please choose business type
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Images
                                    </label>
                                    <div className="col-10">
                                      <MultipleImages
                                        multiple={true}
                                        getImages={(image) =>
                                          this.sendBackImages(image)
                                        }
                                        removeImage={(image) =>
                                          this.removeBackImage(image)
                                        }
                                        loading={this.state.backImageLoading}
                                        imageList={this.state.imageList}
                                        imageLimit={10}
                                      />
                                      <span className="form-text text-muted">
                                        Please Upload background Images
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-2 col-form-label"
                                      htmlFor="marchantGroup"
                                    >
                                      Info Tag
                                    </label>
                                    <div className="col-10">
                                      <Select
                                        showSearch
                                        mode="multiple"
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        value={this.state.infoTag}
                                        // placeholder="Select a merchant group"
                                        onChange={(value) =>
                                          this.setState({ infoTag: value })
                                        }
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {infoTagList.map((tag) => (
                                          <Option key={tag.id} value={tag.id}>
                                            {tag.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <span className="form-text text-muted">
                                        Please choose Info Tag
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-2 col-form-label"
                                      htmlFor="marchantGroup"
                                    >
                                      Payment Modes
                                    </label>
                                    <div className="col-10">
                                      <Select
                                        showSearch
                                        mode="multiple"
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        value={this.state.payment}
                                        // placeholder="Select a merchant group"
                                        onChange={(value) =>
                                          this.setState({ payment: value })
                                        }
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {paymentList.map((payment) => (
                                          <Option
                                            key={payment.id}
                                            value={payment.id}
                                          >
                                            {payment.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <span className="form-text text-muted">
                                        Please choose payment modes
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-2 col-form-label"
                                      htmlFor="marchantGroup"
                                    >
                                      Cuisine
                                    </label>
                                    <div className="col-10">
                                      <Select
                                        showSearch
                                        mode="multiple"
                                        style={{ margin: "10px 0px 10px 0px" }}
                                        value={this.state.cuisine}
                                        // placeholder="Select a merchant group"
                                        onChange={(value) =>
                                          this.setState({ cuisine: value })
                                        }
                                        filterOption={(input, option) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {cuisinesList.map((cuisine) => (
                                          <Option
                                            key={cuisine.id}
                                            value={cuisine.id}
                                          >
                                            {cuisine.name}
                                          </Option>
                                        ))}
                                      </Select>
                                      <span className="form-text text-muted">
                                        Please choose Cuisine
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Average Cost for two
                                    </label>
                                    <div className="col-10">
                                      <input
                                        value={this.state.averageCost}
                                        maxLength="20"
                                        onChange={(event) =>
                                          this.setState({
                                            averageCost: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                        type="number"
                                      />
                                      <span className="form-text text-muted">
                                        Please enter average cost for two
                                        persons
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Menu Item
                                    </label>
                                    <div className="col-10">
                                      <MultipleImages
                                        multiple={true}
                                        getImages={(image) =>
                                          this.sendMenuImages(image)
                                        }
                                        removeImage={(image) =>
                                          this.removeMenuImage(image)
                                        }
                                        loading={this.state.menuImageLoading}
                                        imageList={this.state.menusList}
                                        imageLimit={15}
                                      />
                                      <span className="form-text text-muted">
                                        Please Upload Menu Images
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Short Description in English
                                    </label>
                                    <div className="col-10">
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            short_description:
                                              event.target.value,
                                          })
                                        }
                                        value={this.state.short_description}
                                        className="form-control"
                                        type="text"
                                      />
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: short_descriptionError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please enter short description in
                                          English
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Short Description in Arabic
                                    </label>
                                    <div className="col-10">
                                      <input
                                        onChange={(event) =>
                                          this.setState({
                                            arabic_short_description:
                                              event.target.value,
                                          })
                                        }
                                        value={
                                          this.state.arabic_short_description
                                        }
                                        className="form-control"
                                        type="text"
                                      />
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: arabic_short_descriptionError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please enter short description in
                                          Arabic
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label
                                      className="col-2 col-form-label"
                                      htmlFor="exampleTextarea"
                                    >
                                      Long description in English <b>*</b>
                                    </label>
                                    <div className="col-10">
                                      <div className="input-group">
                                        <textarea
                                          onChange={(event) =>
                                            this.setState({
                                              long_description:
                                                event.target.value,
                                            })
                                          }
                                          value={this.state.long_description}
                                          className="form-control"
                                          rows="3"
                                        ></textarea>
                                      </div>
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: long_descriptionError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please enter long description in
                                          English
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label
                                      className="col-2 col-form-label"
                                      htmlFor="exampleTextarea"
                                    >
                                      Long description in Arabic
                                    </label>
                                    <div className="col-10">
                                      <div className="input-group">
                                        <textarea
                                          onChange={(event) =>
                                            this.setState({
                                              arabic_long_description:
                                                event.target.value,
                                            })
                                          }
                                          value={
                                            this.state.arabic_long_description
                                          }
                                          className="form-control"
                                          rows="3"
                                        ></textarea>
                                      </div>
                                      <span className="form-text text-muted">
                                        <p
                                          style={{
                                            color: arabic_long_descriptionError
                                              ? "red"
                                              : "",
                                          }}
                                        >
                                          Please enter long description in
                                          Arabic
                                        </p>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Primary Contact Person
                                    </label>
                                    <div className="col-10">
                                      <input
                                        maxLength="20"
                                        value={this.state.personName}
                                        onChange={(event) =>
                                          this.setState({
                                            personName: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                        type="text"
                                      />
                                      <span className="form-text text-muted">
                                        Please enter Contact Person Name
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-2 col-form-label">
                                      Primary Contact Number
                                    </label>
                                    <div className="col-10">
                                      <input
                                        maxLength="20"
                                        value={this.state.personPhone}
                                        onChange={(event) =>
                                          this.setState({
                                            personPhone: event.target.value,
                                          })
                                        }
                                        className="form-control"
                                        type="number"
                                      />
                                      <span className="form-text text-muted">
                                        Please enter Contact Person Number
                                      </span>
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane tab="Merchant Contact" key="2">
                                  <ContactPocView
                                    is_outlet={false}
                                    id={params.merchantId}
                                  />
                                </TabPane>
                                <TabPane tab="Online Deals" key="3">
                                  <ProductOnlineDeals
                                    is_merchant={true}
                                    merchant_id={params.merchantId}
                                  />
                                </TabPane>
                              </Tabs>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2"></div>
                      </div>
                    </form>
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
