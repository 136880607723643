import ApiManager from '../Helpers/Network/Api';
import UserModel from './UserModel'



export default class CouponModel {
    static myInstance = null;
    static getInstance() {
        if (CouponModel.myInstance === null) {
            CouponModel.myInstance = new CouponModel();
        }
        return this.myInstance;
    }

    static CouponList = [];
    static BankBinList = [];



    getCoupon(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getCoupon(token, params,
            function resSuccess(data) {
                CouponModel.getInstance().CouponList = data.coupons.data;
                successTrigger(data.coupons);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    addCoupon(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        ApiManager.getInstance().addCoupon(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    getCodes(couponId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        ApiManager.getInstance().getCodes(token, couponId,
            function resSuccess(data) {
                successTrigger(data.coupon)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    checkPrefix(prefix, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        let params = {};
        params.prefix = prefix;
        ApiManager.getInstance().checkPrefix(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }


    getCouponDetail(couponId, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getCouponDetail(token, couponId,
            function resSuccess(data) {
                successTrigger(data.coupon);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    updateCoupon(couponId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();

        ApiManager.getInstance().updateCoupon(token, couponId, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    searchCoupon(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().searchCoupon(token, params,
            function resSuccess(data) {
                CouponModel.getInstance().CouponList = data.coupons.data;
                successTrigger(data.coupons);
            },
            function resFailed(error) {
                failureTrigger(error)
            }
        )
    }

    couponActivation(couponId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().couponActivation(token, params, couponId,
            function resSuccess(data) {
                CouponModel.getInstance().CouponList.forEach((coupon, index) => {
                    if (coupon.id === couponId) {
                        coupon.is_active = params.is_active;
                        successTrigger(data)
                    }
                });
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    getBankBins(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().getBankBins(token, params,
            function resSuccess(data) {
                CouponModel.BankBinList = data.records
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }

    addBankBins(params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().addBankBins(token, params,
            function resSuccess(data) {
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }
    updateBankBins(bankBinId, params, successTrigger, failureTrigger) {
        let token = UserModel.getInstance().getToken();
        ApiManager.getInstance().updateBankBins(token, bankBinId, params,
            function resSuccess(data) {
                for (let i = 0; i < CouponModel.BankBinList.length; i++) {
                    if (CouponModel.BankBinList[i].id === bankBinId) {
                        CouponModel.BankBinList[i].is_active = params.is_active;
                        break;
                    }
                }
                successTrigger(data)
            },
            function resFailed(msg) {
                failureTrigger(msg)
            }
        )
    }
}